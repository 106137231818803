import { Inject, Injectable } from '@angular/core';
import {
  CasePublicDataResponse,
  GroupPublicDataResponse,
  ObjectRequestAccessStatus,
  ObjectRequestAccessType,
  ProfilePublicDataResponse
} from '../../yeti-protocol/join-myao';
import { JoinMyAOObjectCaseService } from '../join-myao-object-x-service/join-myao-object-case.service';
import { JoinMyAOObjectGroupService } from '../join-myao-object-x-service/join-myao-object-group.service';
import { JoinMyAOObjectProfileService } from '../join-myao-object-x-service/join-myao-object-profile.service';
import {
  JOIN_MYAO_OBJECT_CASE_SERVICE,
  JOIN_MYAO_OBJECT_GROUP_SERVICE,
  JOIN_MYAO_OBJECT_PROFILE_SERVICE
} from '../join-myao-object-x-service/join-myao-object-x.service.interface';
import { JoinMYAOObjectTypes } from '../join-myao.model';
import { JoinMyAOObjectServiceInterface } from './join-myao-object.service.interface';

@Injectable({
  providedIn: 'root'
})
export class JoinMyAOObjectService implements JoinMyAOObjectServiceInterface {

  objectType: JoinMYAOObjectTypes;
  private objectService: JoinMyAOObjectCaseService | JoinMyAOObjectGroupService | JoinMyAOObjectProfileService;

  constructor(
    @Inject(JOIN_MYAO_OBJECT_CASE_SERVICE) private joinMyAOObjectCaseService: JoinMyAOObjectCaseService,
    @Inject(JOIN_MYAO_OBJECT_GROUP_SERVICE) private joinMyAOObjectGroupService: JoinMyAOObjectGroupService,
    @Inject(JOIN_MYAO_OBJECT_PROFILE_SERVICE) private joinMyAOObjectProfileService: JoinMyAOObjectProfileService,
  ) { }

  setObjectType(objectType: JoinMYAOObjectTypes): void {
    this.objectType = objectType;
    this.setObjectServiceBasedOnObjectType();
  }

  getObjectPublicData(objectId: string): Promise<CasePublicDataResponse | GroupPublicDataResponse | ProfilePublicDataResponse> {
    return this.objectService.getObjectPublicData(objectId);
  }

  getObjectRequestAccessStatus(objectId: string, type?: ObjectRequestAccessType): Promise<ObjectRequestAccessStatus> {
    return this.objectService.getObjectRequestAccessStatus(objectId, type);
  }

  requestAccess(objectId: string): Promise<any> {
    return this.objectService.requestAccess(objectId);
  }

  private setObjectServiceBasedOnObjectType(): void {
    switch (this.objectType) {
      case JoinMYAOObjectTypes.CASE:
        this.objectService = this.joinMyAOObjectCaseService;
        break;
      case JoinMYAOObjectTypes.GROUP:
        this.objectService = this.joinMyAOObjectGroupService;
        break;
      case JoinMYAOObjectTypes.USER:
        this.objectService = this.joinMyAOObjectProfileService;
        break;
    }
  }


}
