import { Component, Input } from '@angular/core';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';

export enum CounterPosition {
  RIGHT = 'right',
  BELLOW = 'bellow'
}

@Component({
  selector: 'app-text-field-limit-counter-wrapper',
  templateUrl: './text-field-limit-counter-wrapper.component.html',
  styleUrls: ['./text-field-limit-counter-wrapper.component.scss'],
})
export class TextFieldLimitCounterWrapperComponent {

  CounterPosition = CounterPosition;

  @Input() inputText = '';
  @Input() limit: number;
  @Input() isFocused = true;
  @Input() hideLimitCounterIfNotFocused = false;
  @Input() counterPosition = CounterPosition.BELLOW;
  @Input() stripHtmlTags = false;

  get text(): string {

    if (!this.inputText?.length) {
      return '';
    }

    if (this.stripHtmlTags) {
      return stripHtmlTagsFromString(this.inputText);
    }

    return this.inputText;
  }
}
