import { Component, Input, OnInit, TemplateRef, Inject } from '@angular/core';
import { OrcIdAuthService } from '../../services/auth/orcid/orcid-auth.service';
import { VerificationService } from '../../services/verification.service';
import { VerificationStatus } from '../../services/verification.model';
import { AuthenticationOrcIdConfig } from '../../../config/config.model';
import appConfig from 'src/config/config';
import { ActionSource } from '../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';

interface OrcidButtonComponentConfig {
  authenticationOrcId: AuthenticationOrcIdConfig;
}

@Component({
  selector: 'app-orcid-button',
  templateUrl: './orcid-button.component.html',
  styleUrls: ['./orcid-button.component.scss'],
})
export class OrcidButtonComponent implements OnInit {

  @Input() orcIdButtonTemplate: TemplateRef<any>;
  @Input() source: ActionSource;

  config: OrcidButtonComponentConfig = appConfig;

  constructor(
    private orcIdAuthService: OrcIdAuthService,
    private verificationService: VerificationService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) { }

  ngOnInit(): void { }

  async linkOrcId(): Promise<void> {

    const userVerificationStatus = await this.verificationService.verify();

    if (userVerificationStatus === VerificationStatus.VERIFIED) {
      this.trackLinkOrcIdClicked().finally(() => {
        this.orcIdAuthService.signIn();
      });
    }
  }

  trackLinkOrcIdClicked(): Promise<void> {

    const objId = 'linkOrcid';
    const objType = 'orcid';
    const objectTitle = 'linkOrcidCTA';

    return this.trackingService.trackGenericClickedAction(objId, objType, objectTitle,
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error('Could not track link orcid click action.');
      });
  }

}
