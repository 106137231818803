import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Countries, CountriesResponse } from './yeti-protocol/countries';
import { SchemaValidatorService } from './schema-validator.service';

import appConfig from 'src/config/config';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countriesSubject: BehaviorSubject<Countries> = new BehaviorSubject<Countries>([]);
  private initPromise: Promise<void> = null;

  constructor(
    private httpClient: HttpClient,
    private schemaValidator: SchemaValidatorService
  ) { }

  get countries(): Observable<Countries>{
    return from(this.init()).pipe(mergeMap(() => {
      return this.countriesSubject.asObservable();
    }));
  }

  private init(): Promise<void>{
    if(!this.initPromise){
      this.initPromise = this.fetchCountries();
    }
    return this.initPromise;
  }

  private fetchCountries(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get<Countries>(appConfig.backendUrl + 'countries')
        .pipe(
          this.schemaValidator.isValidOperator<CountriesResponse>('CountriesResponse'),
        ).subscribe({
          next: res => {
            this.countriesSubject.next(res as Countries);
            resolve();
          },
          error: err => {
            reject(err);
          }
        });
    });
  }
}
