import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { AOEvent } from '../yeti-protocol/event';
import {
  EditRecommendationsBookmarksResponse, RecommendationBookmarkRequestParameter,
  RecommendationsBookmarksResponsePayload,
} from '../yeti-protocol/recommendations-bookmarks';
import { Group } from '../yeti-protocol/chatter-api';
import { UserProfile } from '../yeti-protocol/auth/mi';
import { Article, Video } from '../yeti-protocol/article';
import { VerificationStatus } from '../verification.model';

import { AuthService } from '../auth/auth.service';
import { ToastService } from '../toast.service';
import { VerificationService } from '../verification.service';
import appConfig from 'src/config/config';
import { ArticleService } from '../article/article.service';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';

type RecommendedItem = Group | Article | Video | AOEvent;

export enum ItemTypes {
  BOOKMARK = 'bookmarks',
  RECOMMENDATIONS = 'recommendations'
}

export enum RecommendationsFilter {
  ALL = 'all',
  VIDEO = 'video',
  ARTICLE = 'article',
  GROUP = 'group'
}

export interface RecommendationsBookmarksServiceConfig {
  serverUrl: string;
}

function getItemType(item: RecommendedItem, type?: string): string {
  let itemType = type;
  if (!itemType) {
    if ('type' in item) {
      itemType = item.type;
    }
  }
  if (!itemType) {
    itemType = 'group';
  }
  return itemType;
}

@Injectable({
  providedIn: 'root'
})
export class RecommendationsBookmarksService {
  config: RecommendationsBookmarksServiceConfig = {
    serverUrl: `${appConfig.backendUrlIonic}campaignItems`,
  }
  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
    private toast: ToastService,
    private verificationService: VerificationService,
    private articleService: ArticleService
  ) { }

  async recommendClicked(item: Group | Article | Video, user: UserProfile, awsPersonalisationId?: string): Promise<void> {
    const userVerificationStatus = await this.verificationService.verify();

    if (userVerificationStatus !== VerificationStatus.VERIFIED) {
      return;
    }
    if (item.hasRecommended === true) {
      this.unrecommendItem(item, user, null, awsPersonalisationId);
    } else {
      this.recommendItem(item, user, null, awsPersonalisationId);
    }
  }

  async recommendClickedForAOEvent(item: AOEvent, user: UserProfile): Promise<void> {

    const type = 'event';
    const userVerificationStatus = await this.verificationService.verify();

    if (userVerificationStatus !== VerificationStatus.VERIFIED) {
      return;
    }
    if (item.hasRecommended === true) {
      this.unrecommendItem(item, user, type);
    } else {
      this.recommendItem(item, user, type);
    }
  }

  recommendItem(item: RecommendedItem, user: UserProfile, type?: string, awsPersonalisationId?: string): void {

    const itemType = getItemType(item, type);
    this.addRecommendationBookmarkItem(itemType, ItemTypes.RECOMMENDATIONS, item._id, awsPersonalisationId)
      .then(async response => {
        if (response && response.success) {
          item.hasRecommended = true;
          if (!(item as Group)?.owner) { // make sure it is article or video
            this.articleService.emitArticleUpdated(item as any);
          }
          this.toast.show('app.common.item-recommended');
          this.modifyRecommendsArray(item, item.hasRecommended, user);
        }
      })
  }

  unrecommendItem(item: RecommendedItem, user: UserProfile, type?: string, awsPersonalisationId?: string): void {

    const itemType = getItemType(item, type);
    this.removeRecommendationBookmarkItem(itemType, ItemTypes.RECOMMENDATIONS, item._id, awsPersonalisationId)
      .then(async response => {
        if (response && response.success) {
          item.hasRecommended = false;
          if (!(item as Group)?.owner) { // make sure it is article or video
            this.articleService.emitArticleUpdated(item as any);
          }
          this.toast.show('app.common.item-un-recommended');
          this.modifyRecommendsArray(item, item.hasRecommended, user);
        }
      })
  }

  modifyRecommendsArray(item: RecommendedItem, recommended: boolean, user?: UserProfile): void {

    if (!item.recommends) {
      item.recommends = [];
    }

    if (recommended) {
      item.recommends.push({
        profileImageUrl: user?.profileImageUrl,
        userId: user?.id
      });
      if ('totalNumberOfRecommends' in item) {
        item.totalNumberOfRecommends += 1;
      }
    } else {
      item.recommends = item.recommends.filter(recommend => recommend.userId !== user?.id);
      if ('totalNumberOfRecommends' in item) {
        item.totalNumberOfRecommends -= 1;
      }
    }
  }

  getItemsList(
    itemType: ItemTypes,
    start: number,
    count: number,
    userId?: string,
    filter?: RecommendationsFilter): Promise<RecommendationsBookmarksResponsePayload> {

    return this.authService.asserIsSignedIn().then(() => {

      let getRequestUrl: string;

      if (userId) {
        getRequestUrl = `${this.config.serverUrl}/${itemType}/${userId}/list?appId=${this._appId}&start=${start}&count=${count}`;
      } else {
        getRequestUrl = `${this.config.serverUrl}/${itemType}/list?appId=${this._appId}&start=${start}&count=${count}`;
      }

      if (filter) {
        getRequestUrl = `${getRequestUrl}&filterBy=${filter}`;
      }

      return firstValueFrom(this.authService.secureGet<RecommendationsBookmarksResponsePayload>(getRequestUrl));
    });
  }

  addRecommendationBookmarkItem(addedItemType: string, itemType: ItemTypes, itemId: string, awsPersonalisationId?: string):
    Promise<EditRecommendationsBookmarksResponse> {
    return this.authService.asserIsSignedIn().then(() => {

      const requestParams: RecommendationBookmarkRequestParameter = {
        itemType: addedItemType,
        itemId: itemId
      };
      if (awsPersonalisationId) {
        requestParams.awsPersonalisationId = awsPersonalisationId;
      }

      const getRequestUrl = `${this.config.serverUrl}/${itemType}/add?appId=${this._appId}`;

      return firstValueFrom(this.authService.securePost<any, EditRecommendationsBookmarksResponse>(getRequestUrl, requestParams));
    });

  }

  removeRecommendationBookmarkItem(deletedItemType: string, itemType: ItemTypes, itemId: string, awsPersonalisationId?: string):
    Promise<EditRecommendationsBookmarksResponse> {
    return this.authService.asserIsSignedIn().then(() => {

      const requestParams: RecommendationBookmarkRequestParameter = {
        itemType: deletedItemType,
        itemId: itemId
      };
      if (awsPersonalisationId) {
        requestParams.awsPersonalisationId = awsPersonalisationId;
      }

      const getRequestUrl = `${this.config.serverUrl}/${itemType}/remove?appId=${this._appId}`;

      return firstValueFrom(this.authService.securePost<any, EditRecommendationsBookmarksResponse>(getRequestUrl, requestParams));
    });
  }

  get _appId(): string {
    return this.contextService.currentContext.key;
  }
}


