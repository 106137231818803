import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { MY_KNOWLEDGE_STREAM_STATE_KEY } from '../state-constants';
import { MyStreamService } from 'src/app/services/my-stream.service';
import {
  MyStreamSuccessResponse,
  ResponseStreamItem,
  StreamFilter
} from 'src/app/services/yeti-protocol/my-stream';
import { StreamStateType } from 'src/app/services/yeti-protocol/utils/enums';
import { firstValueFrom } from 'rxjs';
import { MyKnowledgeStreamStateModel } from './my-knowledge-stream.model';
import { MyKnowledgeStream } from './my-knowledge-stream.actions';

const MY_KNOWLEDGE_STREAM_STATE_TOKEN = new StateToken<MyKnowledgeStreamStateModel>(
  MY_KNOWLEDGE_STREAM_STATE_KEY
);

@State({
  name: MY_KNOWLEDGE_STREAM_STATE_TOKEN,
  defaults: {
    streamItems: [],
    streamFilters: [],
    loading: false,
    lastFetchLength: 0,
    awsPersonalisationId: ''
  },
})
@Injectable()
export class MyKnowledgeStreamState {

  constructor(private myStreamService: MyStreamService) { }

  @Selector()
  static state(state: MyKnowledgeStreamStateModel): MyKnowledgeStreamStateModel {
    return state;
  }

  @Selector()
  static streamItems(state: MyKnowledgeStreamStateModel): Array<ResponseStreamItem> {
    return state.streamItems;
  }

  @Selector()
  static streamFilters(state: MyKnowledgeStreamStateModel): Array<StreamFilter> {
    return state.streamFilters;
  }

  @Selector()
  static loading(state: MyKnowledgeStreamStateModel): boolean {
    return state.loading;
  }

  @Action(MyKnowledgeStream.FetchMyKnowledgeStreamItems)
  async fetchMyKnowledgeStreamItems(
    ctx: StateContext<MyKnowledgeStreamStateModel>,
    payload: MyKnowledgeStream.FetchMyKnowledgeStreamItems
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      const myKnowledgeStreamRes = (await firstValueFrom(this.myStreamService.fetchStream(
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize,
        StreamStateType.knowledge,
        payload?.payloadParams?.published || false,
        payload?.payloadParams?.filter,
        payload?.payloadParams?.loadSmartStream || false
      ))) as MyStreamSuccessResponse;


      ctx.dispatch(
        new MyKnowledgeStream.FetchMyKnowledgeStreamItemsSuccess(
          payload?.payloadParams,
          myKnowledgeStreamRes
        )
      );
    } catch (err) {

      ctx.dispatch(
        new MyKnowledgeStream.FetchMyKnowledgeStreamItemsFailed()
      );

      throw err;
    }
  }

  @Action(MyKnowledgeStream.FetchMyKnowledgeStreamItemsSuccess)
  fetchMyKnowledgeStreamItemsSuccess(
    ctx: StateContext<MyKnowledgeStreamStateModel>,
    action: MyKnowledgeStream.FetchMyKnowledgeStreamItemsSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        streamItems: action?.response?.result,
        streamFilters: action?.response?.meta?.filters,
        lastFetchLength: action?.response?.result?.length,
        loading: false,
        awsPersonalisationId: action?.response?.meta?.awsPersonalisationId
      });
    } else {
      ctx.patchState({
        streamItems: [...state?.streamItems, ...action?.response?.result],
        streamFilters: action?.response?.meta?.filters,
        lastFetchLength: action?.response?.result?.length,
        loading: false,
        awsPersonalisationId: action?.response?.meta?.awsPersonalisationId
      });
    }
  }

  @Action(MyKnowledgeStream.FetchMyKnowledgeStreamItemsFailed)
  fetchMyKnowledgeStreamItemsFailed(
    ctx: StateContext<MyKnowledgeStreamStateModel>
  ): void {
    ctx.patchState({
      loading: false,
    });
  }

  @Action(MyKnowledgeStream.InsertMyKnowledgeStreamItemBeforeIndex)
  insertMyKnowledgeStreamItemBeforeIndex(
    ctx: StateContext<MyKnowledgeStreamStateModel>,
    action: MyKnowledgeStream.InsertMyKnowledgeStreamItemBeforeIndex
  ): void {

    ctx.setState(
      patch<MyKnowledgeStreamStateModel>({
        streamItems: insertItem<ResponseStreamItem>(action.item, action.index)
      })
    );
  }

  @Action(MyKnowledgeStream.RemoveMyKnowledgeStreamItem)
  removeMyKnowledgeStreamItem(
    ctx: StateContext<MyKnowledgeStreamStateModel>,
    action: MyKnowledgeStream.RemoveMyKnowledgeStreamItem
  ): void {

    const state = ctx.getState();

    const itemIndex = state.streamItems.findIndex(item =>
      (item?.streamItem as any)?._id === (action?.item?.streamItem as any)?._id);

    if (itemIndex === -1) {
      return;
    }

    ctx.setState(
      patch<MyKnowledgeStreamStateModel>({
        streamItems: removeItem<ResponseStreamItem>(itemIndex)
      })
    );
  }

  @Action(MyKnowledgeStream.UpdateMyKnowledgeStreamItem)
  updateMyKnowledgeStreamItem(
    ctx: StateContext<MyKnowledgeStreamStateModel>,
    action: MyKnowledgeStream.UpdateMyKnowledgeStreamItem
  ): void {

    const state = ctx.getState();

    const itemIndex = state.streamItems.findIndex(item =>
      (item?.streamItem as any)?._id === action?.item?._id);

    if (itemIndex === -1) {
      return;
    }

    const updatedStreamItems = [...state.streamItems];

    updatedStreamItems[itemIndex].streamItem = action.item as any;

    ctx.setState(
      patch<MyKnowledgeStreamStateModel>({
        streamItems: updatedStreamItems
      })
    );
  }
}
