import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UsersModule } from 'src/app/modules/users/users.module';
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { MultilineTextWithEllipsisModule } from '../multiline-text-with-ellipsis/multiline-text-with-ellipsis.module';
import { IconButtonModule } from 'src/app/modules/icon-button/icon-button.module';
import { ActionSource, ActionTracked } from 'src/app/services/yeti-protocol/tracking';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';

@Component({
  selector: 'app-person-hlist-card',
  templateUrl: './person-hlist-card.component.html',
  styleUrls: ['./person-hlist-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    UsersModule,
    MultilineTextWithEllipsisModule,
    IconButtonModule
  ]
})
export class PersonHlistCardComponent implements OnInit {

  @Input() person: PublicProfile;
  @Input() buttonTemplate: TemplateRef<any>;
  @Input() showXButton: boolean;
  @Input() source: ActionSource;
  @Input() clickTrackingObjId: string;

  @Output() xClicked: EventEmitter<PublicProfile> = new EventEmitter();

  constructor(
    private appNavController: AppNavController,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  ngOnInit(): void { }

  openPublicProfile(): void {
    this.trackGenericClickedAction(ActionTracked.publicProfileOpened);
    this.appNavController.openPublicUserProfile(this.person?.userId, this.source);
  }

  trackGenericClickedAction(objType: string): Promise<void> {

    if (!this.clickTrackingObjId) {
      return;
    }

    const objId = this.clickTrackingObjId;
    const objectTitle = this.clickTrackingObjId;

    return this.trackingService.trackGenericClickedAction(objId, objType, objectTitle,
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error(`Could not track: ${objType} click action.`);
      });
  }

}
