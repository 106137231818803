import { Inject, Injectable } from '@angular/core';
import { LocationService } from '../location.service';
import { SharingAbstractService } from './sharing.abstract.service';
import { ObjectForSharing, ObjectForSharingType, SocialSharingApp } from './sharing.model';
import { SharingService } from './sharing.service.interface';
import { UrlUtilsService } from '../utils/url-utils.service';
import { ContextService, CONTEXT_SERVICE } from '../context/context.model';

@Injectable({
  providedIn: 'root'
})
export class SharingBrowserService extends SharingAbstractService implements SharingService {
  constructor(
    private locationService: LocationService,
    @Inject(CONTEXT_SERVICE) contextService: ContextService,
    urlUtilsService: UrlUtilsService
  ){
    super(contextService, urlUtilsService);
  }

  share(target: SocialSharingApp, objectForSharing: ObjectForSharing): Promise<boolean> {
    const url = this._buildSharingUrl(target, objectForSharing);
    this._share(target, url);
    return Promise.resolve(true);
  }

  _buildSharingUrl(target: SocialSharingApp, objectForSharing: ObjectForSharing): string {
    let res;
    let url;
    const baseParams = `?source=${target}&campaign=shared${objectForSharing?.type}`;

    if (objectForSharing?.type === ObjectForSharingType.SURGERY_REFERENCE || objectForSharing?.type === ObjectForSharingType.EVENT) {
      url = objectForSharing?.url;
    } else if (objectForSharing?.type === ObjectForSharingType.PROFILE) {
      url = `${objectForSharing?.wrapperUrl}${baseParams}_${objectForSharing?._id}`;
    } else {
      url = `${objectForSharing?.wrapperUrl}${baseParams}_${target}`;
    }

    const titleWithUrlQueryParameter = encodeURIComponent([objectForSharing.title, url].join(' '));

    switch (target) {
      case SocialSharingApp.FACEBOOK:
        res = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case SocialSharingApp.TWITTER:
        res = `https://twitter.com/intent/tweet?text=${titleWithUrlQueryParameter}`;
        break;
      case SocialSharingApp.LINKEDIN:
        res = `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`
              + `&text=${encodeURIComponent(objectForSharing.title)}`;
        break;
      case SocialSharingApp.WHATSAPP:
        res = `https://api.whatsapp.com/send?text=${titleWithUrlQueryParameter}`;
        break;
      case SocialSharingApp.EMAIL:
        res = 'mailto:?subject=' + encodeURIComponent(objectForSharing.title) +
          '&body=' + encodeURIComponent(url);
        break;
      default:
        // TODO: error reporting
        res = {
          error: 'not recognized sharing target: ' + target
        };
        break;
    }
    return res;
  }

  _share(target: SocialSharingApp, url: string): void {
    if (target === SocialSharingApp.EMAIL) {
      // prevent opening browser tab
      this.locationService.href = url;
    } else {
      // TODO: switch at using link opener (the code should work and for cordova app)
      // this.linkOpenerService.open(this.locationService.href, false);
      window.open(url, '_system', 'width=600,height=600');
    }
  }
}
