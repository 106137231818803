import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE_SERVICE, StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LastEmailService {
  private EMAIL_KEY = 'last-email';

  constructor(
    @Inject(LOCAL_STORAGE_SERVICE) private storage: StorageService
  ) { }

  getEmail(): Promise<string> {
    return this.storage.get(this.EMAIL_KEY)
      .then(value => {
        return value || '';
      })
      .catch(() => {
        return Promise.resolve('');
      });
  }

  setEmail(value: string): Promise<void> {
    return this.storage.set(this.EMAIL_KEY, value)
      .catch(() => {
        return Promise.resolve(); // ignore
      });
  }
}
