/**
 * caching a value for predefined duration time
 */
export class TimeoutCache<T> {
  _value: T = null;
  _timer: ReturnType<typeof setTimeout>;
  constructor(public cachingDurationMs = 500){}

  get value(): T {
    return this._value;
  }

  set value(val: T) {
    this._value = val;
    this._clearAfterTimeout();
  }

  _clearAfterTimeout(): void {
    if(this._timer){
      clearTimeout(this._timer);
    }
    this._timer = setTimeout(() => {
      this._timer = null;
      this._value = null;
    }, this.cachingDurationMs);
  }
}
