// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --edit-case-images-padding-top: 0px;
  --edit-case-images-padding-bottom: 0px;
  --edit-case-images-padding-start: 20px;
  --edit-case-images-padding-end: 20px;
  --edit-case-images-background-color: var(--mi-white-color);
  --edit-case-images-section-bottom-padding: 20px;
}

.edit-case-images-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
}
.edit-case-images-dialog-container .top-bar-container {
  grid-row: 1;
  grid-column: 1;
}
.edit-case-images-dialog-container .top-bar-container app-top-bar {
  --top-bar-padding-start: var(--edit-case-images-padding-start);
  --top-bar-padding-end: var(--edit-case-images-padding-end);
  --top-bar-column-gap: 20px;
  --top-bar-title-text-align: center;
}
.edit-case-images-dialog-container .top-bar-container .save-case-images-button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-case-images-dialog-container .top-bar-container .save-case-images-button .button-text {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-primary-color);
}
.edit-case-images-dialog-container .top-bar-container .save-case-images-button.disabled .button-text {
  color: var(--mi-color-text-lite);
}
.edit-case-images-dialog-container ion-content {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.edit-case-images-dialog-container ion-content .images-edit-container {
  height: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
