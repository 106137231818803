// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --image-size: 50px;
}

.share-selected-recipient-card-container {
  width: 70px;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
}
.share-selected-recipient-card-container .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.share-selected-recipient-card-container .image-container app-group-image {
  --group-image-size: var(--image-size);
  --group-image-border-radius: 50%;
}
.share-selected-recipient-card-container .image-container app-user-profile-image {
  --user-image-size: var(--image-size);
}
.share-selected-recipient-card-container .image-container app-event-image {
  --event-image-size: var(--image-size);
  --event-image-border-radius: 50%;
}
.share-selected-recipient-card-container .image-container app-icon-button {
  position: absolute;
  left: 48px;
  top: 0px;
  --icon-button-background-width: 17px;
  --icon-button-background-height: 17px;
  --icon-button-background-color: var(--mi-color-text-lite);
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 50%;
  --icon-button-icon-size: 15px;
  --icon-button-icon-color: var(--mi-white-color);
}
.share-selected-recipient-card-container .text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-selected-recipient-card-container .text-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 36px;
}
.share-selected-recipient-card-container .text-container app-multiline-text-with-ellipsis ::ng-deep {
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  line-height: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}
.share-selected-recipient-card-container .text-container app-multiline-text-with-ellipsis ::ng-deep .multiline-text-with-ellipsis-container .multiline-text-container .text {
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
