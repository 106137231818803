import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import appConfig from 'src/config/config';

// services
import { QRCodeService } from 'src/app/services/qr-code/qr-code.service';
import { LOCAL_STORAGE_SERVICE, StorageService } from 'src/app/services/storage/storage.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { Subscription, filter } from 'rxjs';
import { NavigationStart, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-qr-code-widget',
  templateUrl: './qr-code-widget.component.html',
  styleUrls: ['./qr-code-widget.component.scss'],
})
export class QrCodeWidgetComponent implements OnInit, OnDestroy {
  qrCodeUrl: string;
  storageVal: string;
  closeQRCode = false;

  private QR_CODE_KEY = 'qr-code-display';
  private routerEventsSubscription: Subscription;

  constructor(
    private qrCodeService: QRCodeService,
    private router: Router,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    @Inject(LOCAL_STORAGE_SERVICE) private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.qrCodeUrl = appConfig.link.qrCodePopup;
    this.checkStorage();

    this.routerEventsSubscription = this.router.events.pipe(
      filter((event: Event | RouterEvent | any) => event instanceof NavigationStart)
    ).subscribe(() => {
      this.onRouteChange();
    });
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }

  close(): void {
    this.closeQRCode = true;
    this.storage.set(this.QR_CODE_KEY, 'hidden');
    this.trackClosingWidget();
  }

  get hideQRCode(): boolean {
    return (this.storageVal && this.storageVal === 'hidden') || !this.qrCodeService.getDisplayQRCode || this.closeQRCode;
  }

  getStorageValue(): Promise<string> {
    return this.storage.get(this.QR_CODE_KEY)
      .then(value => {
        return value || '';
      })
      .catch(err => {
        console.error(err);
        return '';
      });
  }

  onRouteChange(): void {
    this.checkStorage();
  }

  checkStorage(): void {
    this.getStorageValue().then(val => {
      this.storageVal = val;
    });
  }

  trackClosingWidget(): void {
    this.trackingService.trackGenericClickedAction('download-app-qr', 'close-download-app-qr', 'close-download-app-qr')
      .catch(_err => {
        console.error('Could not track close-download-app-qr clicked action.');
      });
  }
}
