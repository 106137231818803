// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --page-side-padding: 15px;
}
ion-content .group-participants-header {
  display: none;
}
@media only screen and (max-width: 600px) {
  ion-content .group-participants-header {
    display: block;
  }
}
@media all and (min-width: 1090px) {
  ion-content ion-searchbar::ng-deep .searchbar-input-container {
    padding: 10px 20px;
    --box-shadow: none !important;
    --background: var(--mi-inputs-color);
  }
  ion-content ion-searchbar::ng-deep .searchbar-input-container .searchbar-search-icon {
    left: 30px;
    top: 20px;
  }
  ion-content ion-searchbar::ng-deep .searchbar-input-container .searchbar-clear-icon {
    padding-right: 10px;
  }
}
ion-content ion-searchbar::ng-deep .searchbar-input-container {
  --box-shadow: none;
  --background: var(--mi-inputs-color);
}
ion-content ion-card::ng-deep app-icon.crown-user {
  --icon-size: 13px;
  --margin-bottom: 5px;
}
ion-content .title {
  color: var(--mi-secondary-color-2);
  letter-spacing: 0;
  line-height: 24px;
  font-weight: var(--font-weight-medium);
}
ion-content .icon-size-24 {
  height: 24px;
  width: 24px;
}
ion-content .toolbar {
  --background: var(--mi-white-color);
  padding-top: 5px;
}
ion-content .toolbar .back-button {
  background-color: var(--mi-white-color);
}
ion-content .toolbar .add-user-button {
  background-color: var(--mi-white-color);
}
ion-content .search-toolbar .searchbar-input {
  background: var(--mi-inputs-color);
}
ion-content .list-info-container {
  width: 100%;
  padding: 10px var(--page-side-padding);
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: min-content;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
ion-content .list-info-container .title-container {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: start;
}
ion-content .list-info-container .title-container .title {
  margin: 0;
}
ion-content .list-info-container .action-container {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: end;
}
ion-content .container {
  background-color: var(--mi-grey-background);
  padding-top: 2px;
}
ion-content .container .info-card {
  margin: 8px 0 0 0;
  border-radius: 0;
  box-shadow: none;
}
ion-content .container .info-card .info-card-header {
  display: flex;
  justify-content: space-between;
}
ion-content .container .info-card .info-card-header .main-text {
  font-weight: var(--font-weight-medium);
  font-size: var(--mi-font-size-small);
  color: var(--mi-secondary-color-1);
}
ion-content .container .info-card .owner-card-content {
  padding-bottom: 15px;
}
ion-content .container .info-card .group-moderators-container {
  display: grid;
  row-gap: 15px;
  padding-bottom: 15px;
}
ion-content .container .info-card .group-members-container {
  display: grid;
  row-gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
ion-content .container .info-card .group-members-container app-group-member-row-item ::ng-deep .group-member-container {
  grid-template-rows: 70px;
}
ion-content .container .info-card .group-members-container app-group-member-row-item ::ng-deep .group-member-container .user-image-container {
  height: 100%;
  align-items: self-start;
}
ion-content .container .info-card .group-members-container app-group-member-row-item ::ng-deep .group-member-container .user-info-container {
  align-items: self-start;
}
ion-content .container .info-card .moderators-card-content {
  padding-top: unset;
  padding-bottom: unset;
}
ion-content .container .info-card .empty-state-container {
  margin-top: 50px !important;
}
ion-content .container .info-card .group-additional-info {
  display: flex;
  margin: 5px 0;
  justify-content: space-between;
}
ion-content .container .info-card .group-additional-info .action-sheet-trigger-button {
  justify-self: end;
}
ion-content .container .info-card .group-additional-info .additional-info-item {
  font-size: var(--mi-font-size-smaller);
  background-color: var(--ion-color-secondary-light-blue);
  color: var(--ion-color-secondary-bright-blue);
  border-radius: 12px;
  margin: 5px 5px 5px 0;
}
ion-content .container .info-card .group-additional-info .display-flex {
  display: flex;
}
ion-content .container .invite-participants-button {
  --background: var(--mi-primary-color);
  --border-radius: 4px;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  margin: 12px 10px 12px 10px;
  display: flex;
  text-transform: none;
  box-shadow: none;
}
ion-content app-user-full-name {
  --name-text-font-size: var(--font-size-regular);
  --name-text-font-weight: var(--font-weight-medium);
  --name-text-color: var(--ion-color-light-contrast);
  --country-text-color: var(--mi-color-text-lite);
  --country-text-font-size: var(--font-size-small);
  --country-text-font-weight: var(--font-weight-regular);
}
ion-content app-user-profile-image {
  --user-image-size: 40px;
  margin-right: 10px;
}

.participants-search-button {
  position: absolute;
  left: 77%;
  top: 28%;
  height: 30px;
  --color: var(--mi-hover-color);
  --background: var(--mi-white-color);
  font-size: var(--font-size-small);
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .participants-search-button {
    top: 22%;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
