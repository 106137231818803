import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { VisibilityTrackerTargetDirective } from './visibility-tracker-target.directive';
import { VisibilityTrackerRootDirective } from './visibility-tracker-root.directive';
import { VisibilityTrackerService } from './visibility-tracker.service';

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [
    VisibilityTrackerTargetDirective,
    VisibilityTrackerRootDirective
  ],
  exports: [
    VisibilityTrackerTargetDirective,
    VisibilityTrackerRootDirective
  ]
})
export class VisibilityTrackerModule {
  static forRoot(): ModuleWithProviders<VisibilityTrackerModule> {
    return {
      ngModule: VisibilityTrackerModule,
      providers: [VisibilityTrackerService]
    }
  }
}
