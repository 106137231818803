import { Inject, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LOCAL_STORAGE_SERVICE, StorageService } from './storage/storage.service';

interface InstallPromptEvent {
  preventDefault(): void;
  prompt(): void;
  userChoice: Promise<string>;
}

const STORAGE_KEY = 'app-install-suspend-date';

@Injectable()
export class AppInstallPromptService {

  promptEvent: InstallPromptEvent = null;
  @Output() installPromptSubj: Subject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(LOCAL_STORAGE_SERVICE) protected storage: StorageService,
  ){}

  initPwaPrompt(): void {
    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      this.promptEvent = event;
      this.installPromptSubj.next(true);
    });
  }

  // generates true on install prompt event arriving
  get installPrompt(): Observable<boolean> {
    return this.installPromptSubj.asObservable();
  }

  isPromptAvailable(): boolean {
    return !!this.promptEvent;
  }

  openPrompt(): Promise<void> {
    if(!this.isPromptAvailable){
      return Promise.reject('app install prompt is not available');
    }
    this.promptEvent.prompt();
    return this.promptEvent.userChoice.then(choice => {
      console.log('app install choice: ' + choice);
      this.promptEvent = null;
    });
  }

  isSuspended(): Promise<boolean> {
    return this.storage.get(STORAGE_KEY).then(value => {
      return value !== this._today;
    });
  }

  suspendPrompt(): void {
    this.storage.set(STORAGE_KEY, this._today);
  }

  get _today(): string {
    return (new Date()).toJSON().substring(0, 10);
  }
}
