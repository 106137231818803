// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --max-main-column-width: 560px;
  --max-width: 1090px;
  --side-col-width: 265px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
:host.mode-main-column-only .main-column {
  width: var(--max-main-column-width);
}
:host.mode-large-screen {
  width: var(--max-width);
  margin: 0 auto;
  padding-top: 10px;
}
:host.mode-large-screen .main-column {
  max-width: var(--max-main-column-width);
  flex-grow: 1;
}

.left-column,
.right-column {
  width: var(--side-col-width);
}

.main-column {
  width: 100%;
  max-width: var(--max-main-column-width);
}

.left-column,
.main-column,
.right-column {
  align-self: stretch;
  overflow: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
