import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { PublicProfile } from '../../services/yeti-protocol/public-profile';
import { SwiperLogic } from '../../services/utils/swiper-logic';
import { ResponsiveUtilsService } from '../../services/utils/responsive-utils.service';
import { GroupsService } from '../../services/groups/groups.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import { SwiperOptions } from 'swiper';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';
import { ActionSource, ActionTracked } from '../../services/yeti-protocol/tracking';
import { ClinicalExpertsService } from '../../services/clinical-experts.service';
import { Posts } from '../../state/posts/posts.actions';
import { Store } from '@ngxs/store';
import { ModalController } from '@ionic/angular';
import {
  GroupTopContributorsDialogComponent
} from '../../dialogs/group-top-contributors-dialog/group-top-contributors-dialog.component';

@Component({
  selector: 'app-recommended-top-contributors-hlist',
  templateUrl: './recommended-top-contributors-hlist.component.html',
  styleUrls: ['./recommended-top-contributors-hlist.component.scss'],
})
export class RecommendedTopContributorsHlistComponent implements OnInit, AfterViewInit {

  @Input() parentSource = ActionSource.unspecified;
  @Input() source: ActionSource | string;

  @Output() hideList: EventEmitter<void> = new EventEmitter<void>();

  topContributorsList: PublicProfile[];
  topContributorsLoading = true;
  swiperLogic: SwiperLogic;

  private readonly start = 0;
  private readonly count = 9;

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef,
    private groupsService: GroupsService,
    private toast: ToastService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private clinicalExpertService: ClinicalExpertsService,
    private store: Store,
    private modalController: ModalController
  ) {
    const swiperDefaultSlidesPerView = this.responsiveUtilsService.isDesktop ? 3 : 2.2;
    this.swiperLogic = new SwiperLogic(173, this.responsiveUtilsService, this.el, swiperDefaultSlidesPerView);
  }

  ngOnInit(): void {
    this.source = this.source || this._source;
    this.loadTopContributors();
  }

  loadTopContributors(): void {
    this.topContributorsLoading = true;
    this.getRecommendedTopContributors(this.start, this.count)
      .then(response => {
        this.topContributorsList = response;
        if (!response.length) {
          this.hideList.emit();
        }
      })
      .catch(err => {
        this.showError(err);
      }).finally(() => {
        this.topContributorsLoading = false;
      });
  }

  async getRecommendedTopContributors(start: number, count: number): Promise<Array<PublicProfile>> {
    try {
      const topContributorsResponse = await this.groupsService.getRecommendedTopContributors(start, count);
      if (topContributorsResponse) {
        const topContributorsFiltered = topContributorsResponse.result.filter(({ isFollower }) => !isFollower)
        return Promise.resolve(topContributorsFiltered);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  ngAfterViewInit(): void {
    this.swiperLogic?.afterViewInit();
  }

  onTopContributorFollow(topContributor: PublicProfile): void {
    this.trackAction(topContributor.userId, 'user', topContributor.isFollower ? ActionTracked.userUnfollow : ActionTracked.userFollow)
    this.clinicalExpertService.updateClinicalExpertFollow(
      topContributor.userId,
      !topContributor.isFollower,
      this.source as ActionSource).then(async response => {
        if (response) {
          topContributor.isFollower = !topContributor.isFollower;
          const topContributorIndex = this.topContributorsList.findIndex(contributor => contributor.userId === topContributor.userId);
          this.topContributorsList[topContributorIndex] = topContributor;
          this.store.dispatch(new Posts.UpdatePostsOwnerFollowingStatus(topContributor.userId, topContributor.isFollower));
          const msgKey = topContributor.isFollower ? 'app.PublicProfilePage.followed' : 'app.PublicProfilePage.unfollowed';
          this.toast.show(msgKey);
        }
      });
  }

  async onSeeAllTopContributors(): Promise<void> {
    this.trackAction('see-all', 'see-all', ActionTracked.clicked);
    const dialog = await this.modalController.create({
      component: GroupTopContributorsDialogComponent,
      cssClass: 'top-contributors-dialog',
    });
    dialog.onDidDismiss().then(({ data }) => {
      Object.keys(data).forEach((userId) => {
        const topContributorIndex = this.topContributorsList.findIndex(topContributor => topContributor.userId === userId);
        this.topContributorsList[topContributorIndex] = data[userId];
      })
    })
    this.modalController.dismiss();
    dialog.present();
  }

  get swiperConfig(): SwiperOptions {
    return this.swiperLogic.config;
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }


  trackAction(objectId: string, objectType: string, objectTitle: string): void {
    this.trackingService.trackGenericClickedAction(objectId, objectType, objectTitle,
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error('Could not track click action.');
      });
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get _source(): string {
    const componentSource = ActionSource.recommendedTopContributorsHlist;

    return this.parentSource && this.parentSource !== ActionSource.unspecified ?
      `${this.parentSource}-${componentSource}` : componentSource;
  }

}
