import { Injectable } from '@angular/core';
import { Case, Post, PostType } from '../../services/yeti-protocol/chatter-api';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { Posts } from 'src/app/state/posts/posts.actions';

export interface PostActionContent {
  action: PostsActions,
  post: Post | Case
}

export enum PostsActions {
  NEW_POST_ADDED = 'new-post-added',
  POST_EDITED = 'post-edited',
  POST_DELETED = 'post-deleted',
}

export interface GroupServiceConfig {
  context: string;
  serverUrl: string;
  imageProxyServerUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class PostsDataService {

  constructor(
    private store: Store
  ) { }

  private postsChanged: Subject<PostActionContent> = new Subject();

  triggerNewPostAddedAction(post: Post | Case): void {

    this.store.dispatch(new Posts.AddNewPost(post));

    this.postsChanged.next({
      action: PostsActions.NEW_POST_ADDED,
      post: post
    });
  }

  triggerPostEditedAction(post: Post | Case): void {

    this.store.dispatch(new Posts.UpdatePost(post));

    this.postsChanged.next({
      action: PostsActions.POST_EDITED,
      post: post
    });
  }

  triggerPostDeletedAction(post: Post): void {

    this.store.dispatch(new Posts.DeletePost(post));

    this.postsChanged.next({
      action: PostsActions.POST_DELETED,
      post: post
    });
  }

  getPostsChangesStream(): Observable<PostActionContent> {
    return this.postsChanged.asObservable();
  }

  getGroupsFeedStreamItemType(item: Post | Case): string {

    if (item?.type === PostType.case) {
      return PostType.case;
    }

    return PostType.post;
  }
}
