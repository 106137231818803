import { ReadyKeyValueStorage } from './storage.model';

export class BrowserStorage implements ReadyKeyValueStorage {

  constructor(private storage: Storage) {}

  ready(): Promise<any> {
    return Promise.resolve();
  }

  get(key: string): Promise<any> {
    let value = this.storage.getItem(key);
    if (value !== undefined) {
      try {
        value = JSON.parse(value);
      } catch (err) {
        value = undefined;
      }
    }
    return Promise.resolve(value);
  }

  set<T>(key: string, value: T): Promise<any> {
    return Promise.resolve(this.storage.setItem(key, JSON.stringify(value)));
  }

  remove(key: string): Promise<any> {
    return Promise.resolve(this.storage.removeItem(key));
  }

  removaAll(): Promise<any> {
    this.storage.clear();
    return Promise.resolve();
  }

  size(): Promise<number> {
    return Promise.resolve(this.storage.length);
  }

  key(idx: number): Promise<string> {
    return Promise.resolve(this.storage.key(idx));

  }
}
