// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.response-state-container {
  display: flex;
  flex-direction: column;
  padding: 23px 56px;
  align-items: center;
}
.response-state-container .title {
  width: 450px;
  font-size: var(--font-size-extra-large);
  font-weight: var(--font-weight-semi-bold);
  text-align: center;
  margin-bottom: 60px;
}
.response-state-container .stepper-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
}
.response-state-container .stepper-container .vertical-connection {
  height: 50px;
  width: 35px;
  border-right: 1px dashed var(--mi-grey-color-24);
}
.response-state-container .stepper-container .step-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
}
.response-state-container .stepper-container .step-container.step-1 .icon-container {
  background-color: var(--mi-overlays-color);
}
.response-state-container .stepper-container .step-container.step-2 .icon-container {
  background-color: var(--mi-thumbnails-background-color);
}
.response-state-container .stepper-container .step-container .icon-container {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.response-state-container .stepper-container .step-container .icon-container app-icon {
  --icon-size: 30px;
  color: var(--mi-white-color);
}
.response-state-container .stepper-container .step-container .step {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-large);
  gap: 8px;
  width: 75%;
}
.response-state-container .stepper-container .step-container .step .step-level {
  font-weight: var(--font-weight-semi-bold);
}
.response-state-container ion-button {
  --background: var(--mi-primary-color);
  --box-shadow: none;
  border-radius: 4px;
  width: 100%;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  margin: 5px 0 5px 0;
  display: flex;
  text-transform: none;
}
.response-state-container .additional-info-container {
  display: flex;
  padding: 8px 10px;
  background-color: var(--mi-blue-color-19);
  border-radius: 8px;
  font-size: var(--font-size-small);
  color: var(--mi-grey-color-13);
  width: 100%;
  margin-top: 20px;
}
.response-state-container .additional-info-container app-icon {
  color: var(--mi-blue-color-20);
  margin-right: 5px;
}
.response-state-container app-image {
  width: 134px;
  margin-bottom: 20px;
}
.response-state-container app-image ::ng-deep div ion-img {
  background-color: var(--mi-white-color);
}
.response-state-container .error-title {
  font-size: var(--font-size-extra-large);
  font-weight: var(--font-weight-semi-bold);
  text-align: center;
  margin: 0 20px;
}
.response-state-container .error-text {
  font-size: var(--font-size-large);
  text-align: center;
  width: 320px;
  margin-bottom: 50px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
