// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --video-image-size: 55px;
  --video-image-border-radius: 5px;
}

.video-image {
  width: 100%;
  height: 100%;
  position: relative;
}
.video-image ion-thumbnail {
  width: var(--video-image-size);
  height: var(--video-image-size);
  --border-radius: var(--video-image-border-radius);
}
.video-image ion-thumbnail ion-img {
  width: var(--video-image-size);
  height: var(--video-image-size);
}
.video-image .image-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-image .image-overlay app-icon ::ng-deep {
  --icon-size: 28px;
  color: var(--mi-white-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
