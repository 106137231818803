import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { ActionSource } from '../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';

@Component({
  selector: 'app-become-ao-member',
  templateUrl: './become-ao-member.component.html',
  styleUrls: ['./become-ao-member.component.scss'],
})
export class BecomeAoMemberComponent implements OnInit {

  @Input() source: ActionSource;

  @Input() profile: UserProfile;
  isAOApplication: boolean;
  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.isAOApplication = this.uiUtilsService.isAOApplication;
  }

  onBecomeAOMember(): void {
    this.uiUtilsService.onBecomeAOMember(this.contextService?.currentContext?.aoMembershipUrl);
    this.trackBecomeAOMemberClicked();
  }

  trackBecomeAOMemberClicked(): void {
    this.trackingService.trackGenericClickedAction(
      'becomeAoMember',
      'becomeAoMember',
      'becomeAoMember',
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error('Could not track becomeAoMember clicked action.');
      });
  }
}
