import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StreamFiltersDialogComponent } from 'src/app/dialogs/stream-filters-dialog/stream-filters-dialog.component';
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
/* eslint-disable */
import { StreamFilter } from 'src/app/services/yeti-protocol/my-stream';
/* eslint-enable */

@Component({
  selector: 'app-stream-filters',
  templateUrl: './stream-filters.component.html',
  styleUrls: ['./stream-filters.component.scss'],
})
export class StreamFiltersComponent implements OnInit {

  @Input() filters: Array<StreamFilter>;
  @Input() streamType: string;

  @Output() selectedFilterRemoved: EventEmitter<void> = new EventEmitter();

  infoSheetId = 'stream-filters-info-sheet';

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private infoSheetService: InfoSheetService,
    private modalController: ModalController
  ) { }

  ngOnInit(): void {

    this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  showFilters(): void {

    if (this.isDesktop) {
      this.showFiltersDialog();
    } else {
      this.infoSheetService.open(this.infoSheetId);
    }
  }

  async showFiltersDialog(): Promise<void> {

    const dialog = await this.modalController.create({
      component: StreamFiltersDialogComponent,
      cssClass: 'stream-filters-dialog',
      componentProps: {
        filters: this.filters,
        streamType: this.streamType
      }
    });

    await dialog.present();

    dialog.onDidDismiss().then(res => {
      if (res && res?.data) {
        this.selectedFilterRemoved.emit();
      }
    });
  }

  emitSelectedFilterRemoved(): void {
    this.selectedFilterRemoved.emit();
  }

}
