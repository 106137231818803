import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[app-load-indicator]',
})
export class LoadIndicatorDirective implements AfterViewInit {

  @Output() loadingFinished: EventEmitter<void> = new EventEmitter();

  ngAfterViewInit(): void {
    this.loadingFinished.emit();
  }
}
