import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import * as ClassicEditorCustomBuild from 'ckeditor5-custom-build';
import { VerificationStatus } from 'src/app/services/verification.model';

// components
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

// services
import { LinkOpenerService } from 'src/app/services/link-opener.service';
import { UI_UTILS_SERVICE, UIUtilsServiceInterface } from 'src/app/services/utils/ui-utils.service.interface';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { Router } from '@angular/router';
import {
  ATTRIBUTE_WHITESPACES,
  RichTextDocumentEditorUiService
} from '../../services/rich-text-document-editor-ui-service.service';
import { DeepLinksRecognizerService } from '../../../../services/deep-links/deep-links-recognizer.service';
import { UserMentionItemComponent } from '../../../../components/user-mention-item/user-mention-item.component';
import { GetMentions, Mention } from '../../rich-text-document-editor.model';

@Component({
  selector: 'app-rich-text-document-editor',
  templateUrl: './rich-text-document-editor.component.html',
  styleUrls: ['./rich-text-document-editor.component.scss'],
})
export class RichTextDocumentEditorComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild('richTextDocumentEditor') richTextDocumentEditor: CKEditorComponent;

  @Input() content = '';
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() hideToolbar = true;
  @Input() limitText: boolean;
  @Input() preventShowingPendingStateExplainerDialog: boolean;

  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() inputBlur: EventEmitter<any> = new EventEmitter();
  @Output() inputFocus: EventEmitter<any> = new EventEmitter();
  @Output() ready: EventEmitter<any> = new EventEmitter();
  @Output() getMentions: EventEmitter<GetMentions> = new EventEmitter();


  classicRichTextDocumentEditor = ClassicEditorCustomBuild;
  editorConfig = null;
  overflow: boolean;
  user: UserProfile;
  contentPrevValue = '';
  preventLinkChecking: boolean;

  private richTextDocumentResizeObserver: any;
  private richTextDocumentResizeObservedEl: HTMLElement;
  private userSubscription: Subscription;

  constructor(
    private linkOpenerService: LinkOpenerService,
    private el: ElementRef,
    private zone: NgZone,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private router: Router,
    private richTextDocumentEditorUiService: RichTextDocumentEditorUiService,
    private changeDetectorRef: ChangeDetectorRef,
    private deepLinkRecogniserService: DeepLinksRecognizerService
  ) { }

  ngOnInit(): void {
    this.userSubscription = this.authService.userProfileAsObservable.subscribe((user: UserProfile) => {
      this.user = user;
    });
  }

  ngAfterViewInit(): void {

    const disabledPluginsArray = ['MediaEmbed'];

    setTimeout(() => {

      const getMentions = this.getMentions;
      const el = (this.el.nativeElement as HTMLElement);
      let disableMentionDropdown = false;
      const viewContainerRef = this.viewContainerRef;
      const loggedInUser = this.user;
      let mentionedUserIds = [];

      if (loggedInUser?.userId) {
        mentionedUserIds.push(loggedInUser?.userId);
      }

      this.editorConfig = {
        placeholder: this.placeholder,
        toolbar: ['bold', 'italic', '|', 'bulletedList', 'numberedList'],
        plugin: ['Mention'],
        removePlugins: disabledPluginsArray,
        link: {
          defaultProtocol: 'https://'
        },
        mention: {
          feeds: [
            {
              marker: '@',
              feed: function (queryText: string) {

                if (queryText === '') {
                  disableMentionDropdown = false;
                }

                if (queryText === ' ') {
                  disableMentionDropdown = true;
                  return [];
                }

                if (disableMentionDropdown) {
                  return [];
                }

                const mentions = el.querySelectorAll('.mention');

                if (mentions?.length) {

                  if (loggedInUser?.userId) {
                    mentionedUserIds = [loggedInUser?.userId];
                  } else {
                    mentionedUserIds = [];
                  }

                  mentions.forEach(mention => {
                    const mentionedUserId = mention.attributes['data-user-id']?.nodeValue;

                    if (mentionedUserId) {
                      mentionedUserIds.push(mentionedUserId);
                    }
                  });

                  const lastMention = mentions[mentions.length - 1];
                  const lastMentionDataMentionAttribute = lastMention.attributes['data-mention'];

                  if (lastMentionDataMentionAttribute) {
                    const lastMentionQueryText = lastMention.attributes['data-mention']?.nodeValue;
                    const lastMentionQueryTextWithSpace = `${lastMentionQueryText.replace('@', '')} `;

                    if (queryText === lastMentionQueryTextWithSpace) {
                      disableMentionDropdown = true;
                      return [];
                    } else if (queryText.startsWith(lastMentionQueryTextWithSpace)) {
                      disableMentionDropdown = true;
                      return [];
                    }
                  }
                }

                const getMentionsPromise = new Promise<Array<Mention>>((resolve, reject) => {

                  getMentions.emit({
                    excludedUserIds: mentionedUserIds,
                    queryText: queryText,
                    resolve: resolve,
                    reject: reject
                  })
                });

                return getMentionsPromise;
              },
              itemRenderer: function (item: Mention) {
                const optionItemRef = viewContainerRef.createComponent(UserMentionItemComponent)
                optionItemRef.instance.userMention = item;
                optionItemRef.changeDetectorRef.detectChanges();
                return optionItemRef.location.nativeElement;
              }
            }
          ]
        }
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.terminateResizeObserver();
    this.userSubscription?.unsubscribe();
  }

  public setEditorHeight(maxHeight?: string, height?: string, minHeight?: string): void {

    const editor = this.richTextDocumentEditor?.editorInstance;
    const editingView = editor?.editing.view;

    if (maxHeight) {
      editingView?.change(writer => {
        writer.setStyle('max-height', `${maxHeight}`, editor.editing.view.document.getRoot());
      });
    }

    if (height) {
      editingView?.change(writer => {
        writer.setStyle('height', `${height}`, editor.editing.view.document.getRoot());
      });
    }

    if (minHeight) {
      editingView?.change(writer => {
        writer.setStyle('min-height', `${minHeight}`, editor.editing.view.document.getRoot());
      });
    }

  }

  limitEditorHeight(): void {

    const maxHeightValue = 90;
    const editorContainerEl = (this.el.nativeElement as HTMLElement).querySelector('.ck-content');

    let totalChildHeight = 0;
    const childArray = Array.from(editorContainerEl?.children || []);

    childArray.forEach(child => {
      totalChildHeight += child.clientHeight;
    });

    if (maxHeightValue < totalChildHeight) {
      this.overflow = true;
      this.setEditorHeight(null, `${maxHeightValue}px`);
    }
  }

  contentChanged(): void {

    if (!this.preventLinkChecking && this.contentPrevValue.localeCompare(this.content) !== 0) {
      this.contentPrevValue = `${this.content}`; // copy by value
      this.change.emit(this.content);
      this._convertToLink(false);
    }
  }

  _convertToLink(onPaste: boolean): void {

    const editor = this.richTextDocumentEditor?.editorInstance;
    const model = editor.model;
    const document = model.document;
    const selection = document.selection;

    editor.plugins.get('AutoLink').isEnabled = false;

    // Do nothing if selection is not collapsed.
    if (!selection.isCollapsed) {
      return;
    }

    if (onPaste) {
      this._replacePlainUrlTextIntoLinkElementOnPaste(model, selection);

      model.enqueueChange(writer => {
        model.insertContent(writer.createText('\u200b')); // Zero character width space to get focus out
      });
    } else {

      const rangeBeforeSelection = model.createRange(model.createPositionAt(selection.focus.parent, 0), selection.focus);
      const items = [];

      for (const value of rangeBeforeSelection.getWalker()) {
        items.push(value);
      }

      const nodesMerged = this.richTextDocumentEditorUiService.mergeNodes(items);

      if (!(nodesMerged && nodesMerged.length)) {
        return;
      }

      const dataArr = nodesMerged.split(' ');
      const url = dataArr[dataArr.length - 1];

      if (!this.richTextDocumentEditorUiService.isUrlEmail(url)) {
        return;
      }

      this._replacePlainUrlTextIntoLinkElement(nodesMerged, url);
    }

    const probe = model.createSelection(model.document.selection);

    probe.setAttribute('direction', 'forward');
    probe.setAttribute('unit', 'character');

    model.modifySelection(probe);

    const content = editor.data.get();

    if (content !== this.content) {
      this.change.emit(this.content);
    }
  }

  /* eslint-disable */
  _replacePlainUrlTextIntoLinkElementOnPaste(model: any, selection: any): void {
    /* eslint-enable */

    // eslint-disable-next-line max-len
    const urlRegex = /(\b(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))/ig;

    const nodesData = this.richTextDocumentEditorUiService.getNodesData(model, selection);

    for (const nodeData of nodesData) {

      let nodeContent = nodeData.content;

      const urls = [...new Set(nodeContent.match(urlRegex)?.map(url => url?.replace('\u200b', '')
        ?.replace('%E2%80%8B', '')
        ?.replace(ATTRIBUTE_WHITESPACES, '')))]; // to remove duplicates

      if (urls && urls?.length) {
        this.preventLinkChecking = true;

        for (const url of urls) {

          const replaceIndexesForUrl: Array<number> = [];
          let contentCopy = `${nodeContent}`;

          while (contentCopy.indexOf(url) > -1) {
            replaceIndexesForUrl.unshift(contentCopy.indexOf(url));
            const maskedUrl = new Array(url.length + 1).join('*');
            contentCopy = contentCopy.replace(url, maskedUrl);
          }

          if (replaceIndexesForUrl?.length) {
            for (const replaceIndex of replaceIndexesForUrl) {
              const replacePosition = model.createPositionAt(nodeData.node, replaceIndex);
              const replaceRange = model.createRange(replacePosition, replacePosition.getShiftedBy(url.length));
              this.richTextDocumentEditorUiService.createLinkElement(model, url, replaceRange);
            }
          }
        }
      }

      nodeContent = nodeContent.replace(ATTRIBUTE_WHITESPACES, '');
      nodeContent += '\u200b';
    }

    this.preventLinkChecking = false;
  }

  _replacePlainUrlTextIntoLinkElement(nodesMerged: string, url: string): void {

    const editor = this.richTextDocumentEditor?.editorInstance;
    const model = editor.model;
    const selectionParent = editor.model.document.selection.focus.parent;

    let replaceIndex = -1;
    let contentCopy = `${nodesMerged}`;
    url = url?.replace('\u200b', '')?.replace('%E2%80%8B', '')?.replace(ATTRIBUTE_WHITESPACES, '');

    while (contentCopy.indexOf(url) > -1) {
      replaceIndex = contentCopy.indexOf(url);
      const maskedUrl = new Array(url.length + 1).join('*');
      contentCopy = contentCopy.replace(url, maskedUrl);
    }

    const replacePosition = model.createPositionAt(selectionParent, replaceIndex);
    const replaceRange = model.createRange(replacePosition, replacePosition.getShiftedBy(url.length));

    this.richTextDocumentEditorUiService.createLinkElement(model, url, replaceRange);
  }

  disable(): void {
    // NYI
  }

  richTextEditorClicked(event: Event): void {

    if (!this.preventShowingPendingStateExplainerDialog && this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog();
      return;
    }

    if ((event as any)?.target.nodeName.toLowerCase() === 'a') {

      this.uiUtilsService.stopEventPropagation(event);

      if (!this.disabled) {
        return;
      }

      const href = (event as any).target.getAttribute('href');

      if (href) {
        const classes = (event as any)?.target.className;
        const mentionClassIndex = classes.indexOf('mention');

        const isDeepLink = this.deepLinkRecogniserService.isDeepLink(`/${href}`);

        if (mentionClassIndex > -1 || isDeepLink) {

          if (!this.disabled) {
            return;
          }

          this.router.navigateByUrl(href);
          return;
        }

        this.linkOpenerService.open(href);
      }
    }
  }

  blur(): void {
    this.inputBlur.emit();
  }

  focus(): void {
    this.inputFocus.emit();
  }

  focusRichTextField(): void {
    const editor = this.richTextDocumentEditor?.editorInstance;
    editor?.focus();
  }

  onReady(event: Event): void {
    this.ready.emit(event);

    if (this.limitText) {
      setTimeout(() => {
        this.setResizeObserver();
      }, 0);
    }

    setTimeout(() => {
      this.configMentionElementData();
      this.ckEditorEnterBreaks();
    }, 0);
  }

  ckEditorEnterBreaks(): void {

    const editor = this.richTextDocumentEditor?.editorInstance;

    editor?.editing?.view?.document?.on('enter', (evt: any, data: any) => {
      editor.execute('shiftEnter');
      data.preventDefault();
      evt.stop();
    });

    editor?.editing?.view?.document?.on('space', (evt: any, data: any) => {
      if (!this.content?.length) {
        editor.execute('input', {
          text: ' '
        });

        data.preventDefault();
        evt.stop();
      }
    });

    editor?.keystrokes?.set('space', (key, stop) => {

      if (!this.content?.length) {
        editor.execute('input', {
          text: ' '
        });

        stop();
      }
    });

    editor?.editing?.view?.document?.on('keydown', (evt, data) => {

      if (data.keyCode === 229) {
        if (!this.content?.length) {
          editor.execute('input', {
            text: ' '
          });

          data.preventDefault();
          evt.stop();
        }
      }
    });

    editor?.editing?.view?.document?.on('paste', () => {

      this.preventLinkChecking = true;
      this.changeDetectorRef.detectChanges();

      setTimeout(() => {
        this._convertToLink(true);
        // editor.execute('shiftEnter');
      }, 100);
    });
  }

  configMentionElementData(): void {

    const editor = this.richTextDocumentEditor?.editorInstance;
    const contextKey = this.contextService?.currentContext?.key;

    if (!editor) {
      return;
    }

    editor.conversion.for('downcast').attributeToElement({
      model: 'mention',
      view: (modelAttributeValue, { writer }) => {
        // Do not convert empty attributes (lack of value means no mention).
        if (!modelAttributeValue || !modelAttributeValue._id) {
          return;
        }

        return writer.createAttributeElement('a', {
          class: 'mention',
          'data-mention': modelAttributeValue.id,
          'data-user-id': modelAttributeValue._id,
          href: this.getPublicProfileUrl(modelAttributeValue._id, contextKey)
        }, {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid
        });
      },
      converterPriority: 'high'
    });

    editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'a',
        key: 'data-mention',
        classes: 'mention',
        attributes: {
          'data-user-id': true,
          href: true
        }
      },
      model: {
        key: 'mention',
        value: viewItem => {
          const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
            _id: viewItem.getAttribute('data-user-id')
          });

          return mentionAttribute;
        }
      },
      converterPriority: 'high'
    });

    this.richTextDocumentEditor.editorInstance.data.set(this.content || '');
  }

  setResizeObserver(): void {
    this.richTextDocumentResizeObservedEl = this.el.nativeElement.querySelector('.ck-content');

    if (!this.richTextDocumentResizeObserver) {
      this.richTextDocumentResizeObserver = new ResizeObserver(() => {
        this.zone.run(() => {
          setTimeout(() => {
            this.limitEditorHeight();
          }, 0);
        });
      });
    }
    if (this.richTextDocumentResizeObservedEl instanceof HTMLElement) {
      this.richTextDocumentResizeObserver?.observe(this.richTextDocumentResizeObservedEl);
    }
  }

  private terminateResizeObserver(): void {
    this.richTextDocumentResizeObserver?.unobserve(this.richTextDocumentResizeObservedEl);
    this.richTextDocumentResizeObserver?.disconnect();
  }

  private getPublicProfileUrl(userId: string, contextKey: string): string {
    return `${contextKey}/public/profile/${userId}`;
  }
}
