import { Component, HostBinding, Input } from '@angular/core';
import { ConnectedUser } from 'src/app/services/yeti-protocol/public-profile';

@Component({
  selector: 'app-message-card-sender-line',
  templateUrl: './message-card-sender-line.component.html',
  styleUrls: ['./message-card-sender-line.component.scss'],
})
export class MessageCardSenderLineComponent {
  @Input() sender: ConnectedUser;
  @Input() date: string;
  @Input() userId: string;
  @Input() showSenderInfo = true;

  @HostBinding('class') get viewMode(): string {
    if(this.sender.userId === this.userId){
      return 'chat-initiator';
    }
    return '';
  }
}
