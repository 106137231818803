import { AppPlatformConfigInterface, NOT_USED, Platform } from '../config.model';
import EnvironmentConfig from './myao.prod.config';

class PlatformConfig extends EnvironmentConfig implements AppPlatformConfigInterface {
  platform: Platform = Platform.PWA;
  appUpdateVersionFieldName = NOT_USED;
  appUpdateContentUrlFieldName = NOT_USED;
  appUpdateDisabled = true;
  cookieConsentDomain = 'www.myao.app';
}

const appConfig = new PlatformConfig();
export default appConfig;
