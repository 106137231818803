import { Inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { mapToImageObjects } from 'src/app/modules/clinical-case/clinical-case-utils';

// components

// models
import {
  ArticleSearch,
  EventSearch,
  GroupSearch,
  SurgeryReferenceSearch
} from '../yeti-protocol/search';
import { PublicProfile } from '../yeti-protocol/public-profile';
import { AOVideo, Article, OrcIdArticle, Video } from '../yeti-protocol/article';
import { Group } from '../groups/group.model';
import { ObjectForSharing } from './sharing.model';
import { ClinicalCase } from '../yeti-protocol/clinical-case';
import { AOEvent } from '../yeti-protocol/event';
import { ItemType } from '../yeti-protocol/utils/enums';
import { ImageObject } from 'src/app/components/image-attachments-container/image-attachments-container.model';

// services
import { SHARE_DIALOG_COMPONENT_TOKEN } from '../../dialogs/share-dialog/share-dialog-token';
import { SHARING_SERVICE, SharingService } from './sharing.service.interface';
import { AppTranslationService } from '../app-translation.service';
import { ActionSource } from '../yeti-protocol/tracking';

type Object2Share = Article | Video | AOVideo | ArticleSearch | Group | GroupSearch
  | SurgeryReferenceSearch | ClinicalCase | PublicProfile | OrcIdArticle | string;

@Injectable({
  providedIn: 'root'
})
export class SharingUIService {

  constructor(
    private modalController: ModalController,
    private appTranslationService: AppTranslationService,
    @Inject(SHARING_SERVICE) private sharingService: SharingService,
    /* eslint-disable */
    @Inject(SHARE_DIALOG_COMPONENT_TOKEN) private shareComponent: any
    /* eslint-enaable */
  ) { }

  getArticleSharingObject(article: Article | ArticleSearch): ObjectForSharing {
    return this.sharingService.getArticleSharingObject(article);
  }

  getOrcIdArticleSharingObject(orcIdArticle: OrcIdArticle): ObjectForSharing {
    return this.sharingService.getOrcIdArticleSharingObject(orcIdArticle);
  }

  getGroupSharingObject(group: Group | GroupSearch): ObjectForSharing {
    return this.sharingService.getGroupSharingObject(group);
  }

  getSurgeryReferenceSharingObject(surgeryReference: SurgeryReferenceSearch): ObjectForSharing {
    return this.sharingService.getSurgeryReferenceSharingObject(surgeryReference);
  }

  getEventSharingObject(event: AOEvent | EventSearch): ObjectForSharing {
    return this.sharingService.getEventSharingObject(event);
  }

  getImageSharingObject(imageDataUrl: string): ObjectForSharing {
    return this.getImageSharingObject(imageDataUrl);
  }

  getCaseSharingObject(clinicalCase: ClinicalCase): ObjectForSharing {
    return this.sharingService.getCaseSharingObject(clinicalCase);
  }

  getProfileSharingObject(profile: PublicProfile): ObjectForSharing {
    return this.sharingService.getProfileSharingObject(profile);
  }

  async showShareDialog(
    objectForSharing: ObjectForSharing,
    title: string,
    overlayImgNumber?: number,
    awsPersonalisationId?: string,
    source?: ActionSource | string): Promise<any> {

    try {
      let dlg = await this.modalController.create({
        component: this.shareComponent,
        componentProps: {
          objectForSharing: objectForSharing,
          shareToAppsTitle: title,
          overlayImgNumber: overlayImgNumber,
          awsPersonalisationId: awsPersonalisationId,
          source: source
        },
        cssClass: 'share-dialog'
      });

      dlg.present();
      return dlg.onDidDismiss();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  caseImages(clinicalCase: ClinicalCase): Array<ImageObject> {
    if (clinicalCase) {
      return mapToImageObjects([
        ...clinicalCase.preOpDocuments || [],
        ...clinicalCase.intraOpDocuments || [],
        ...clinicalCase.postOpDocuments || [],
        ...clinicalCase.clinicalFollowUpDocuments || []
      ]);
    }
    return [];
  }

  async showShareDialogForObject(
    object2Share: Object2Share,
    awsPersonalisationId?: string,
    source?: ActionSource | string): Promise<any> {
    let obj;
    let title = await this.appTranslationService.get('app.dialogs.SocialSharing.share-to-apps');
    if ((object2Share as Article).type === ItemType.article) {
      obj = this.getArticleSharingObject(object2Share as Article);
    } else if ((object2Share as OrcIdArticle).type === ItemType.orcid) {
      obj = this.getOrcIdArticleSharingObject(object2Share as OrcIdArticle);
    } else if ((object2Share as Video).type === ItemType.video) {
      obj = this.getArticleSharingObject(object2Share as Article);
    } else if ((object2Share as AOVideo).type === ItemType.aoVideo) {
      obj = this.getArticleSharingObject(object2Share as Article);
    } else if ((object2Share as SurgeryReferenceSearch).module) {
      obj = this.getSurgeryReferenceSharingObject(object2Share as SurgeryReferenceSearch);
    } else if (typeof object2Share === 'string') {
      obj = this.getImageSharingObject(object2Share);
    } else if (this.isClinicalCase(object2Share)) {
      obj = this.getCaseSharingObject(object2Share as ClinicalCase);
      title = await this.appTranslationService.get('app.dialogs.SocialSharing.share-to-apps');
      let images = this.caseImages((object2Share as ClinicalCase));
      let overlayImgNumber = images && images.length ? images.length : 0;
      return this.showShareDialog(obj, title, overlayImgNumber, awsPersonalisationId);
    } else if ((object2Share as PublicProfile).firstName && (object2Share as PublicProfile).lastName) {
      obj = this.getProfileSharingObject(object2Share as PublicProfile);
      title = await this.appTranslationService.get('app.dialogs.SocialSharing.share-to-apps');
    } else {
      obj = this.getGroupSharingObject(object2Share as Group);
    }
    return this.showShareDialog(obj, title, 0, awsPersonalisationId, source);
  }

  private isClinicalCase(object2Share: Object2Share): boolean {
    return (object2Share as ClinicalCase)?.preOpDocuments ||
      (object2Share as ClinicalCase)?.intraOpDocuments ||
      (object2Share as ClinicalCase)?.postOpDocuments ||
      (object2Share as ClinicalCase)?.clinicalFollowUpDocuments ? true : false;
  }
}
