export enum STORAGE_MODE {
  localstorage = 'localstorage',
  sessionstorage = 'sessionstorage'
}

export interface KeyValueStorage {

  /** Get data by key
   *   @returns found item or undefined
   */
  get(key: string): Promise<any>;

  /** set data for key
   */
  set<T>(key: string, value: T): Promise<any>;

  /** remove data with key
   */
  remove(key: string): Promise<any>;

  /** remove all data from the storage
   */
  removaAll(): Promise<any>;

  /** Count amount of data items in the storage
   */
  size(): Promise<number>;

  /** Key of an item by index
   */
  key(idx: number): Promise<string>;
}

export interface ReadyKeyValueStorage extends KeyValueStorage {

  /** Resolved when service is ready to be used
   */
  ready(): Promise<any>;
}
