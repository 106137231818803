import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AcceptRejectGroupInvitationResponse,
  GroupListItem,
  MemberPendingInvitationsResponse,
  MemberPendingInvitationsSuccessResponse
} from '../../services/yeti-protocol/chatter-api';
import { AcceptRejectItemData } from '../../components/accept-reject-list/accept-reject-list.component';
import { GroupsService } from '../../services/groups/groups.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import { IonRefresher, ModalController } from '@ionic/angular';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { GroupsDataService } from 'src/app/services/groups/groups-data.service';
import { Subscription } from 'rxjs';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

@Component({
  selector: 'app-group-member-invitations-dialog',
  templateUrl: './group-member-invitations-dialog.component.html',
  styleUrls: ['./group-member-invitations-dialog.component.scss'],
})
export class GroupMemberInvitationsDialogComponent implements OnInit, OnDestroy {

  memberPendingInvitationsList: Array<GroupListItem>;
  memberPendingInvitationsListLoading: boolean;
  totalMemberPendingInvitations: number;
  acceptRejectInvitationsItems: Array<AcceptRejectItemData>;
  respondingToInvitation: boolean;

  readonly start = 0;
  readonly count = 20;

  private userProfileSubscription: Subscription;
  private userProfile: UserProfile;

  constructor(
    private modalController: ModalController,
    private groupsService: GroupsService,
    private toast: ToastService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private groupsDataService: GroupsDataService,
    private appNavController: AppNavController,
  ) { }

  ngOnInit(): void {

    this.userProfileSubscription = this.authService.userProfileAsObservable
      .subscribe((user: UserProfile) => {
        this.userProfile = user;
      });

    this.getMemberInvitations().then(res => {
      this.totalMemberPendingInvitations = res?.total || 0;
      this.memberPendingInvitationsList = res?.invitations || [];
      this.acceptRejectInvitationsItems = this.adaptmemberInvitationsData();
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  getMemberInvitations(start: number = this.start): Promise<{ total: number, invitations: Array<GroupListItem> }> {

    if (!this.groupsService) {
      return null;
    }

    this.memberPendingInvitationsListLoading = true;

    return this.groupsService.getMemberPendingInvitations(start, this.count)
      .then((response: MemberPendingInvitationsResponse) => {
        if (response as MemberPendingInvitationsSuccessResponse) {
          return {
            total: (response as MemberPendingInvitationsSuccessResponse).totalItemsCount,
            invitations: (response as MemberPendingInvitationsSuccessResponse).result
          }
        }
        return null;
      }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showError(err?.error?.error?.message?.errfor?.message);
        }
        return null;
      }).finally(() => {
        this.memberPendingInvitationsListLoading = false;
      });
  }

  async refreshMemberInvitations(refresher: IonRefresher): Promise<void> {
    this.getMemberInvitations().then(res => {
      this.totalMemberPendingInvitations = res?.total || 0;
      this.memberPendingInvitationsList = res?.invitations || [];
      this.acceptRejectInvitationsItems = this.adaptmemberInvitationsData();
    }).finally(() => {
      refresher.complete();
    });
  }

  async loadMoreMemberInvitations(verticalListLoadMoreData: VerticalListLoadMoreData): Promise<void> {

    const start = Math.floor(this.memberPendingInvitationsList.length / this.count);

    this.getMemberInvitations(start).then(res => {
      this.totalMemberPendingInvitations = res?.total || 0;
      this.memberPendingInvitationsList = [...this.memberPendingInvitationsList, ...res?.invitations || []];
      this.acceptRejectInvitationsItems = this.adaptmemberInvitationsData();
    }).finally(() => {
      verticalListLoadMoreData.infiniteScroll.complete();
    });
  }

  private adaptmemberInvitationsData(): Array<AcceptRejectItemData> {

    if (!this.memberPendingInvitationsList?.length) {
      return [];
    }

    return this.memberPendingInvitationsList.map(invitation => {
      return {
        id: invitation?._id || '',
        imageUrl: invitation?.owner?.profileImageUrl || '',
        userId: invitation?.owner?.userId || '',
        isAOMember: invitation?.owner?.isAOMember || '',
        firstName: invitation?.owner?.firstName || '',
        lastName: invitation?.owner?.lastName || '',
        isVerified: invitation?.owner?.isVerified || '',
        entityTitle: invitation?.title
      } as AcceptRejectItemData;
    })
  }

  onAccepted(data: { id: string, userId: string }): void {

    const invitation = this.memberPendingInvitationsList.find(inv => inv._id === data.id);

    if (!invitation) {
      return;
    }

    this.acceptRejectGroupInvitation(true, invitation);
  }

  onRejected(data: { id: string, userId: string }): void {

    const invitation = this.memberPendingInvitationsList.find(inv => inv._id === data.id);

    if (!invitation) {
      return;
    }

    this.presentRejectGroupInvitationConfirm(false, invitation);
  }

  private async acceptRejectGroupInvitation(status: boolean, invitation: GroupListItem): Promise<void> {

    if (this.respondingToInvitation) {
      return;
    }

    if (status) {
      const canAccess = await this.uiUtilsService.checkAndPromptCanAccessAoMembersOnlyGroup(
        invitation?.AOMembersOnly,
        this.userProfile?.isAOMember,
        this.contextService?.currentContext?.aoMembershipUrl
      );

      if (!canAccess) {
        return;
      }
    }

    this.respondingToInvitation = true;

    this.groupsService.acceptRejectGroupInvitation(invitation?._id, status, invitation?.memberRequestId, this.userProfile?.id)
      .then((response: AcceptRejectGroupInvitationResponse) => {
        if (response.success) {
          if (status) {
            this.toast.show('app.components.GroupsFeed.invites.accepted', 'app.common.success', ToastMode.SUCCESS);
            this.groupsDataService.triggerNewGroupCreatedAction(invitation);
            this.modalController.dismiss();
            this.appNavController.openGroup(invitation._id);
          } else {
            this.toast.show('app.components.GroupsFeed.invites.rejected', 'app.common.success', ToastMode.SUCCESS);
          }

          this.groupsService.triggerGroupsInvitationsChanged();

          this.getMemberInvitations().then(res => {
            this.totalMemberPendingInvitations = res?.total || 0;
            this.memberPendingInvitationsList = res?.invitations || [];
            this.acceptRejectInvitationsItems = this.adaptmemberInvitationsData();
          })
        }
      }).catch(err => {
        console.log(err);
        if (err?.error?.error?.message?.errfor?.message) {
          this.showError(err?.error?.error?.message?.errfor?.message);
        }
      }).finally(() => {
        this.respondingToInvitation = false;
      })
  }

  private async presentRejectGroupInvitationConfirm(status: boolean, invitation: GroupListItem): Promise<void> {
    await this.groupsService.presentRejectGroupInvitationConfirm()
      .then((confirm: boolean) => {
        if (confirm) {
          this.acceptRejectGroupInvitation(status, invitation);
        }
      });
  }

  onImageClicked(userId: string): void {
    this.appNavController.openPublicUserProfile(userId);
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  async onBack(): Promise<void> {
    this.modalController.dismiss();
  }

}
