import { AuthRequestParams } from './auth-logic.service.interface';

/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */

export function toAuthRequestParams(obj: any): AuthRequestParams {
  const res: AuthRequestParams = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      res[key.toString()] = obj[key].toString();
    }
  }
  return res;
}
