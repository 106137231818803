import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {UserFollowingResponse, UserFollowersResponse} from '../yeti-protocol/user-following';
import {toAuthRequestParams} from '../auth/logic/auth-logic.utils';
import appConfig from 'src/config/config';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';

export interface UserFollowingServiceConfig {
  // context: string;
  serverUrl: string;
  // imageProxyServerUrl: string;
}

// export const USER_FOLLOWING_SERVICE_CONFIG = new InjectionToken<UserFollowingServiceConfig>('user-following.service.config');

@Injectable({
  providedIn: 'root'
})
export class UserFollowingService {
  config: UserFollowingServiceConfig = {
    serverUrl: `${appConfig.serverUrlIonic}following/`
  }
  private defaultStart = 0;
  private defaultCount = 15;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
  ) { }

  followUser(userId: string, followUser: boolean): Promise<UserFollowingResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const params = {
        appId: this._appId,
      };
      const postRequestUrl = `${this.config.serverUrl}follow/${userId}/${followUser}`;

      return firstValueFrom(this.authService.securePost(postRequestUrl, params));
    });
  }

  getUserFollowers(userId: string, start: number = this.defaultStart, count: number = this.defaultCount): Promise<UserFollowersResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const params = {
        appId: this._appId,
        start,
        count
      };
      const requestUrl = `${this.config.serverUrl}followers/${userId}`;

      return firstValueFrom(this.authService.secureGet<UserFollowersResponse>(requestUrl, {params: toAuthRequestParams(params)}));
    });
  }

  getUserFollowings(userId: string, start: number = this.defaultStart, count: number = this.defaultCount): Promise<UserFollowersResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const params = {
        appId: this._appId,
        start,
        count
      };
      const requestUrl = `${this.config.serverUrl}followings/${userId}`;

      return firstValueFrom(this.authService.secureGet<UserFollowersResponse>(requestUrl, {params: toAuthRequestParams(params)}));
    });
  }

  get _appId(): string {
    return this.contextService.currentContext.key;
  }
}
