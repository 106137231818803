import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

export enum PopupActionType {
  button,
  link
}

export interface PopupAction {
  key: string;
  label: string;
  type: PopupActionType;
}

export type PopupActions = Array<PopupAction>;

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss'],
})
export class PopupDialogComponent {
  @Input() header = 'Info';
  @Input() title = '';
  @Input() message = '';
  @Input() actions: PopupActions = [];
  PopupActionType = PopupActionType;

  constructor(
    private modalController: ModalController
  ) { }

  onClose(): void {
    this.modalController.dismiss('close');
  }

  onAction(actionKey: string): void{
    this.modalController.dismiss(actionKey);
  }
}
