import { Component, EventEmitter, Output } from '@angular/core';

// models
import { VerificationDocumentType } from 'src/app/services/verification.model';

@Component({
  selector: 'app-verification-step-one',
  templateUrl: './verification-step-one.component.html',
  styleUrls: ['./verification-step-one.component.scss'],
})
export class VerificationStepOneComponent {

  @Output() nextStep: EventEmitter<VerificationDocumentType> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  VerificationDocumentType = VerificationDocumentType;

  verificationDocumentSelected(documentType: VerificationDocumentType): void {
    this.nextStep.emit(documentType);
  }

  closeButtonClicked(): void {
    this.close.emit();
  }

}
