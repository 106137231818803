import { Injectable, NgZone, Optional } from '@angular/core';
import { DeeplinkMatch, Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

import appConfig from 'src/config/config';
import { DeepLinksRecognizerService } from './deep-links-recognizer.service';
import { BehaviorSubject, NEVER, Observable } from 'rxjs';
import { DeepLinksConfig } from './deep-links-config.interface';
import { LinkOpenerService } from '../link-opener.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeepLinksService {
  appConfig: DeepLinksConfig = appConfig; // for testing
  deepLinkSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  cachedLink: string = null;
  _enabled = false;

  constructor(
    @Optional() private deepLinks: Deeplinks,
    private deepLinksRecognizer: DeepLinksRecognizerService,
    private ngZone: NgZone,
    private linkOpenerService: LinkOpenerService
  ){}

  get deepLink(): Observable<string> {
    return this.deepLinkSubject.asObservable();
  }

  init(): void {
    if (this.deepLinks) {
      const routes = this.deepLinksRecognizer.buildRoutesConfig(this.appConfig);
      this.deepLinks.route(routes).pipe(
        // if app is opened with a link that is not in deeplinks array,
        // plugin throws error and subscription is broken, this means that
        // if app is not manually killed, deep link matching wont work anymore,
        // this error catching prevents this behaviour
        catchError(_noMatch => {

          // nomatch.$link - the full link data
          const url = _noMatch?.$link?.url;
          if (url?.indexOf('(dialog:') > -1) {
            this.linkOpenerService.openIfDeepLinkOnly(url);
          }
          return NEVER;
        })
      )
        .subscribe({
          next: match => {
          // match.$route - the route we matched, which is the matched entry from the arguments to route()
          // match.$args - the args passed in the link
          // match.$link - the full link data
            this.onDeepLink(match);
          },
          error: err => {
            console.log(err);
          }
        });
    }
  }

  onDeepLink(deepLink: DeeplinkMatch): void {
    this.ngZone.run(() => {
      const link = `${deepLink.$link.path}${deepLink.$link.queryString ? '?' + deepLink.$link.queryString : ''}`;
      const translatedLink = this.deepLinksRecognizer.translateAlias(link, appConfig);
      if (this.enabled) {
        this.deepLinkSubject.next(translatedLink);
      } else {
        this.cachedLink = translatedLink;
      }
    });
  }

  set enabled(value: boolean) {
    this._enabled = value;
    if (value && this.cachedLink) {
      this.deepLinkSubject.next(this.cachedLink);
      this.cachedLink = null;
    }
  }

  get enabled(): boolean {
    return this._enabled;
  }
}
