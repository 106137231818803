// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-mi-card ::ng-deep ion-card {
  height: auto;
}
app-mi-card ::ng-deep ion-card .data-container {
  width: 100%;
  height: 100%;
  padding: 20px;
}
app-mi-card ::ng-deep ion-card .data-container .header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
app-mi-card ::ng-deep ion-card .data-container .header-container .surgery-ref-data .surgery-ref-title {
  color: var(--mi-secondary-color-2);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
}
app-mi-card ::ng-deep ion-card .data-container .header-container .image-container app-surgery-reference-image {
  --surgery-reference-image-size: 55px;
}
app-mi-card ::ng-deep ion-card .data-container app-case-classification-chip ::ng-deep {
  --case-classification-chip-width: fit-content;
  --case-classification-chip-padding-top: 3px;
  --case-classification-chip-padding-bottom: 3px;
  --case-classification-chip-padding-start: 7px;
  --case-classification-chip-padding-end: 7px;
}
app-mi-card ::ng-deep ion-card .data-container app-case-classification-chip ::ng-deep .case-classification-chip-container {
  width: -moz-fit-content;
  width: fit-content;
}
app-mi-card ::ng-deep ion-card .data-container .module-container,
app-mi-card ::ng-deep ion-card .data-container .diagnosis-container {
  color: var(--mi-grey-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  line-height: var(--font-line-height-regular);
}
app-mi-card ::ng-deep ion-card .data-container .module-container .bold,
app-mi-card ::ng-deep ion-card .data-container .diagnosis-container .bold {
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}
app-mi-card ::ng-deep ion-card .data-container .module-container {
  margin: 20px 0px 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
