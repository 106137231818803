import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PublicProfile } from './yeti-protocol/public-profile';

export interface ClinicalExpertActionContent {
  action: PeopleActions,
  clinicalExpert: PublicProfile
}

export enum PeopleActions {
  FOLLOW_UNFOLLOW = 'follow-unfollow',
  CONNECT = 'connect'
}

@Injectable({
  providedIn: 'root'
})
export class ClinicalExpertsDataService {

  private expertsChanged: Subject<ClinicalExpertActionContent> = new Subject();

  triggerFollowUnfollowClinicalExpertAction(expert: PublicProfile): void {
    this.expertsChanged.next({
      action: PeopleActions.FOLLOW_UNFOLLOW,
      clinicalExpert: expert
    });
  }

  triggerConnectUserProfileAction(profile: PublicProfile): void {
    this.expertsChanged.next({
      action: PeopleActions.CONNECT,
      clinicalExpert: profile
    })
  }

  getClinicalExpertsChangesStream(): Observable<ClinicalExpertActionContent> {
    return this.expertsChanged.asObservable();
  }
}
