import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root'
})
export class NavControllerService {

  private _history: Array<string> = [];

  constructor(
    private router: Router,
    private navController: NavController,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private locationService: LocationService
  ) { }


  startHistoryTracking(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._history.push(event.urlAfterRedirects);
      }
    });
  }

  get history(): Array<string> {
    return [...this._history];
  }

  get previousUrl(): string {
    if (this._history.length > 0) {
      return this._history[this._history.length - 2];
    }
    return null;
  }

  back(forceHomeReload: boolean = false): void {
    this._history.pop();
    if (this._history.length > 0) {
      this.navController.back();
    } else {
      const rootUrl = this.contextService.currentContext.homePath;
      this.navigateRoot(rootUrl);
      if (forceHomeReload) {
        setTimeout(() => {
          this.locationService.reload();
        }, 10)
      }
    }
  }

  navigateRoot(url: string, options?: NavigationOptions): Promise<boolean> {
    return this.navController.navigateRoot(url, options);
  }

  navigateForward(url: string): Promise<boolean> {
    return this.navController.navigateForward(url);
  }

  navigateBack(url: string): Promise<boolean> {
    return this.navController.navigateBack(url);
  }
}
