import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Connection } from '../yeti-protocol/connections';
import { Store } from '@ngxs/store';
import { PendingConnectionRequests } from 'src/app/state/pending-connection-requests/pending-connection-requests.actions';
import { PendingConnectionRequestsStateModel } from 'src/app/state/pending-connection-requests/pending-connection-requests.model';
import { PendingConnectionRequestsState } from 'src/app/state/pending-connection-requests/pending-connection-requests.state';
import { StateOverwrite } from 'ngxs-reset-plugin';
import { PENDING_CONNECTION_REQUESTS_STATE_KEY } from 'src/app/state/state-constants';
import { ActiveConversations } from 'src/app/state/active-conversations/active-conversations.actions';


@Injectable({
  providedIn: 'root'
})
export class ConnectionRequestsService {

  private connectionRequestAcceptedSubject: Subject<Connection> = new Subject();

  constructor(
    private store: Store
  ) { }

  init(): void {
    const fetchPendingConnectionRequestsPagingPayload: PendingConnectionRequests.FetchPendingConnectionRequestsPagingPayload = {
      pageIndex: 0
    }

    this.store.dispatch(new PendingConnectionRequests.FetchIncomingPendingConnectionRequests(fetchPendingConnectionRequestsPagingPayload));
  }

  get connectionRequestAcceptedObservable(): Observable<Connection> {
    return this.connectionRequestAcceptedSubject.asObservable();
  }

  propagateConnectionRequestUpdate(connection: Connection): void {
    if (!connection?.status) {
      return;
    }

    if (connection?.status === 'rejected' || connection?.status === 'connected') {
      this.removeConnectionRequestFromList(connection);
    }

    if (connection?.status === 'connected') {
      this.store.dispatch(new ActiveConversations.UpdateActiveConversationBasedOnConnectionChange(connection));
      this.connectionRequestAcceptedSubject.next(connection);
    }
  }

  setIncomingPendingConnectionStateToDefault(): void {

    const newStateValue: PendingConnectionRequestsStateModel = {
      incomingPendingConnectionRequests: {
        pendingConnectionRequests: [],
        totalCount: 0,
        loading: this.store.snapshot()?.[PENDING_CONNECTION_REQUESTS_STATE_KEY]?.incomingPendingConnectionRequests?.loading
      },
      sentPendingConnectionRequests: this.store.snapshot()?.[PENDING_CONNECTION_REQUESTS_STATE_KEY]?.sentPendingConnectionRequests
    }

    this.store.dispatch(
      new StateOverwrite([PendingConnectionRequestsState, newStateValue])
    );
  }

  private removeConnectionRequestFromList(connection: Connection): void {

    let connectionRequests =
      this.store.snapshot()[PENDING_CONNECTION_REQUESTS_STATE_KEY]?.incomingPendingConnectionRequests?.pendingConnectionRequests;
    const index = connectionRequests.findIndex(connectionRequest => connectionRequest._id === connection._id);

    if (index > -1) {

      this.store.dispatch(new PendingConnectionRequests.RemoveIncomingPendingConnectionRequest(index));

      connectionRequests =
        this.store.snapshot()?.[PENDING_CONNECTION_REQUESTS_STATE_KEY]?.incomingPendingConnectionRequests?.pendingConnectionRequests;
      const totalConnectionRequestsCount =
        this.store.snapshot()?.[PENDING_CONNECTION_REQUESTS_STATE_KEY]?.incomingPendingConnectionRequests?.totalCount;

      if (totalConnectionRequestsCount > 0 && connectionRequests?.length === 0) {

        const fetchClinicalCasesAccessRequestsPayload: PendingConnectionRequests.FetchPendingConnectionRequestsPagingPayload = {
          pageIndex: 0
        }

        this.store.dispatch(new PendingConnectionRequests.FetchIncomingPendingConnectionRequests(fetchClinicalCasesAccessRequestsPayload));
        this.setIncomingPendingConnectionStateToDefault();
      }
    }
  }
}
