import { Component, Input } from '@angular/core';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-watermark-button',
  templateUrl: './watermark-button.component.html',
  styleUrls: ['./watermark-button.component.scss'],
})
export class WatermarkButtonComponent extends BaseButtonComponent<Event> {

  @Input() iconOnly: boolean;
  @Input() disabled: boolean;
  @Input() active: boolean;

  constructor(
    private appTranslationService: AppTranslationService
  ) {
    super();
  }

  get tooltip(): string {
    if (this.active) {
      return this.appTranslationService.instant('app.watermark.remove-watermark-tooltip');
    } else {
      return this.appTranslationService.instant('app.watermark.watermark-images-tooltip');
    }
  }
}
