import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { cardType } from 'src/app/modules/surgery-reference/surgery-reference.utils';
import { ContentType, Fracture, FractureAndTreatment, Treatment } from 'src/app/services/yeti-protocol/surgery-reference-schema';
import { SurgeryReferenceModule } from '../surgery-reference.module';

@Component({
  selector: 'app-surgery-reference-fracture-review-wrap',
  templateUrl: './surgery-reference-fracture-review-wrap.component.html',
  styleUrls: ['./surgery-reference-fracture-review-wrap.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    SurgeryReferenceModule
  ]
})

export class SurgeryReferenceFractureReviewWrapComponent {
  @Input() fracture: Fracture;
  @Input() treatment: Treatment;
  @Input() allowRemoveSelection: boolean;


  @Output() removeSelection: EventEmitter<Fracture | Treatment | FractureAndTreatment> = new EventEmitter();
  @Output() openExternalLink: EventEmitter<Fracture | Treatment> = new EventEmitter();

  getCardType(type: ContentType): string {
    return cardType(type);
  }

  onRemoveBtnClick(item: Fracture | Treatment | FractureAndTreatment): void {
    this.removeSelection.emit(item);
  }

  contentClicked(item: Fracture | Treatment): void {
    this.openExternalLink.emit(item);
  }
}
