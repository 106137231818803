import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import appConfig from 'src/config/config';
import { AuthService } from '../../auth/auth.service';
import { toAuthRequestParams } from '../../auth/logic/auth-logic.utils';
import { ContextService, CONTEXT_SERVICE } from '../../context/context.model';
import { SchemaValidatorService } from '../../schema-validator.service';
import {
  CasePublicDataRequestParams,
  CasePublicDataResponse,
  ObjectRequestAccessStatus,
  ObjectRequestAccessStatusRequestParams,
  ObjectRequestAccessStatusResponse,
  ObjectRequestAccessType
} from '../../yeti-protocol/join-myao';
import { JoinMyAOObjectXServiceInterface } from './join-myao-object-x.service.interface';

interface ServiceConfig {
  chatterUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class JoinMyAOObjectCaseService implements JoinMyAOObjectXServiceInterface {

  config: ServiceConfig = appConfig;

  constructor(
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  getObjectPublicData(caseId: string): Promise<CasePublicDataResponse> {

    const requestUrl = `${this.config.chatterUrl}cases/public/${caseId}`;

    const params: CasePublicDataRequestParams = {
      appId: this.contextService.currentContext.key,
    }

    return firstValueFrom(this.authService.secureGet(requestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<CasePublicDataResponse>('CasePublicDataResponse')
    ));
  }

  getObjectRequestAccessStatus(
    caseId: string,
    type: ObjectRequestAccessType = ObjectRequestAccessType.ALL): Promise<ObjectRequestAccessStatus> {

    const requestUrl = `${this.config.chatterUrl}cases/${caseId}/accessRights`;

    const params: ObjectRequestAccessStatusRequestParams = {
      appId: this.contextService.currentContext.key,
      type: type
    }

    return firstValueFrom(this.authService.secureGet(requestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<ObjectRequestAccessStatusResponse>('ObjectRequestAccessStatusResponse'),
      map(res => {
        console.log(res);
        return (res as any).result as ObjectRequestAccessStatus;
      })
    ));
  }

  requestAccess(caseId: string): Promise<any> {

    const params: CasePublicDataRequestParams = {
      appId: this.contextService.currentContext.key,
    }

    const requestUrl = `${this.config.chatterUrl}cases/${caseId}/request`;
    return firstValueFrom(this.authService.securePut(requestUrl, {}, { params: toAuthRequestParams(params) }));
  }


}
