import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-context-button',
  templateUrl: './context-button.component.html',
  styleUrls: ['./context-button.component.scss'],
})
export class ContextButtonComponent extends BaseButtonComponent<string> {
  @Input() key: string;
  @Input() title: string;
  @Input() icon: string = null;
  @Input() iconAtEnd: string = null;
  @Input() selected = false;

  getEventData(): string {
      return this.key;
  }
}
