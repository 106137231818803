export const NUMBER_USER_IMG_BUBBLES_CARDS = 2;
export const NUMBER_USER_IMG_BUBBLES_PROFILE_PAGE = 5;
export const NUMBER_USER_IMG_BUBBLES_GROUP_PAGE = 5;
export const NUMBER_USER_IMG_BUBBLES_EVENT_PAGE = 5;
export const NUMBER_USER_IMG_BUBBLES_DETAIL_PAGES = 9;

export interface BaseUserProfile {
  userId?: string,
  profileImageUrl?: string
}
