import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IonInfiniteScroll, IonRefresher } from '@ionic/angular';
import { CaseAccessRequest } from 'src/app/services/yeti-protocol/clinical-case';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import * as _ from 'lodash';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AcceptRejectItemData } from '../../accept-reject-list/accept-reject-list.component';
import { Store } from '@ngxs/store';
import { ClinicalCasesAccessRequestsState } from 'src/app/state/clinical-cases-access-requests/clinical-cases-access-requests.state';
import { ClinicalCasesAccessRequestsStateModel } from 'src/app/state/clinical-cases-access-requests/clinical-cases-access-requests.model';
import { StateReset } from 'ngxs-reset-plugin';
import { ClinicalCasesAccessRequests } from 'src/app/state/clinical-cases-access-requests/clinical-cases-access-requests.actions';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-clinical-case-requests-list',
  templateUrl: './clinical-case-requests-list.component.html',
  styleUrls: ['./clinical-case-requests-list.component.scss'],
})
export class ClinicalCaseRequestsListComponent implements OnInit, OnDestroy {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonRefresher) listRefresher: IonRefresher;

  @Input() showMaxCaseRequestItems: number;
  @Input() withPaging: boolean;
  @Input() title: string;
  @Input() count: number;
  @Input() showSeeAll: boolean;
  @Input() parentSource = ActionSource.unspecified;
  @Input() source: ActionSource | string;

  @Output() profileImageClicked: EventEmitter<any> = new EventEmitter();
  @Output() seeAllClicked: EventEmitter<void> = new EventEmitter();

  clinicalCasesAccessRequestsState$: Observable<ClinicalCasesAccessRequestsStateModel>;

  caseRequestArray: Array<CaseAccessRequest>;
  caseAccessRequestsLoading = false;
  totalCaseAccessRequestsCount: number;
  acceptRejectRequestsItems: Array<AcceptRejectItemData>;

  private readonly start = 0;
  private readonly itemsPerPage = 10;
  private clinicalCasesAccessRequestsStateSubscription: Subscription;
  private refreshing: boolean;

  constructor(
    private clinicalCaseService: ClinicalCaseService,
    private toastService: ToastService,
    private appTranslationService: AppTranslationService,
    private store: Store
  ) {
    this.clinicalCasesAccessRequestsState$ = this.store.select(ClinicalCasesAccessRequestsState.state);
  }

  ngOnInit(): void {

    this.source = this.source || this._source;

    this.clinicalCasesAccessRequestsStateSubscription = this.clinicalCasesAccessRequestsState$.subscribe(state => {

      this.caseRequestArray = state.clinicalCasesAccessRequests;
      this.caseAccessRequestsLoading = state.loading;
      this.totalCaseAccessRequestsCount = state.totalCount;

      this.acceptRejectRequestsItems = this.adaptRequestsData() || [];

      if (!state.loading) {
        if (this.infiniteScroll) {
          this.infiniteScroll?.complete();
        }

        if (this.listRefresher && this.refreshing) {
          this.listRefresher?.complete();
          this.refreshing = false;
        }

        if (this.caseRequestArray.length < this.totalCaseAccessRequestsCount) {
          this.disableInfiniteScroll(false);
        } else {
          this.disableInfiniteScroll(true);
        }
      }
    });

    setTimeout(() => {
      if (this.withPaging) {
        this.getCaseAccessRequests(0, true);
      } else if (!this.withPaging && (_.isUndefined(this.caseRequestArray) || _.isNull(this.caseRequestArray))) {
        this.getCaseAccessRequests(0);
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.clinicalCasesAccessRequestsStateSubscription?.unsubscribe();
  }

  getCaseAccessRequests(start: number, refresh?: boolean): void {
    if (!this.caseAccessRequestsLoading) {

      const fetchClinicalCasesAccessRequestsPayload: ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequestsPayload = {
        pageIndex: start
      }

      if (refresh) {
        this.refreshing = true;
      }

      this.store.dispatch(new ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequests(fetchClinicalCasesAccessRequestsPayload));

      if (refresh) {
        this.store.dispatch(
          new StateReset(ClinicalCasesAccessRequestsState)
        );
      }
    }
  }

  loadMoreRequests(): void {
    if (!this.canLoadMore) {
      this.disableInfiniteScroll(true);
      return;
    }

    const start = Math.floor(this.caseRequestArray.length / this.itemsPerPage);
    return this.getCaseAccessRequests(start);
  }

  refreshList(): void {
    this.getCaseAccessRequests(this.start, true);
  }

  get canLoadMore(): boolean {
    return this.withPaging && this.caseRequestArray?.length < this.totalCaseAccessRequestsCount;
  }

  onReload(): void {
    this.getCaseAccessRequests(this.start, true);
  }

  grantCaseAccessRequest(data: { id: string, userId: string }): void {

    const caseRequest = this.caseRequestArray.find(request => request?._id === data.id);

    if (!caseRequest) {
      return;
    }

    this.clinicalCaseService.grantAccessCaseRequest(caseRequest?.case?._id, data.userId)
      .then((success: boolean) => {
        if (success) {
          this.clinicalCaseService.removeCaseRequestFromList(caseRequest);
          this.onReload();
          const msg =
            caseRequest.requester.firstName +
            this.appTranslationService.instant('app.pages.CaseLibraryPage.grantedAccess');
          this.showNotification(msg);
        } else {
          this.showNotificationById('app.common.something-went-wrong', ToastMode.ERROR);
        }
      }).catch(err => {
        console.error(err);
        this.showNotificationById('app.common.something-went-wrong', ToastMode.ERROR);
      });
  }

  denyCaseAccessRequest(data: { id: string, userId: string }): void {

    const caseRequest = this.caseRequestArray.find(request => request?._id === data.id);

    if (!caseRequest) {
      return;
    }

    this.clinicalCaseService.denyAccessCaseRequest(caseRequest?.case?._id, data.userId)
      .then((success: boolean) => {
        if (success) {
          this.clinicalCaseService.removeCaseRequestFromList(caseRequest);
          this.onReload();
          const msg =
            this.appTranslationService.instant('app.pages.CaseLibraryPage.deniedAccess1') +
            caseRequest.requester.firstName +
            this.appTranslationService.instant('app.pages.CaseLibraryPage.deniedAccess2');
          this.showNotification(msg, ToastMode.ERROR);
        } else {
          this.showNotificationById('app.common.something-went-wrong', ToastMode.ERROR);
        }
      }).catch(err => {
        console.error(err);
        this.showNotificationById('app.common.something-went-wrong', ToastMode.ERROR);
      });
  }

  onProfileImageClicked(userId: string): void {
    this.profileImageClicked.emit(userId);
  }

  showSeeAllCaseRequestList(): void {
    this.seeAllClicked.emit();
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  private adaptRequestsData(): Array<AcceptRejectItemData> {

    if (!this.caseRequestArray?.length) {
      return [];
    }

    return this.caseRequestArray.map(request => {
      return {
        id: request?._id || '',
        imageUrl: request?.requester?.profileImageUrl || '',
        userId: request?.requester?.userId || '',
        isAOMember: request?.requester?.isAOMember || '',
        firstName: request?.requester?.firstName || '',
        lastName: request?.requester?.lastName || '',
        isVerified: request?.requester?.isVerified || '',
        entityTitle: request?.case?.title
      } as AcceptRejectItemData;
    })?.slice(0, this.showMaxCaseRequestItems);
  }

  private showNotificationById(messageId: string, mode?: ToastMode): void {
    this.toastService.show(messageId, null, mode);
  }

  private showNotification(message: string, mode?: ToastMode): void {
    this.toastService.showWithMessage(message, null, mode);
  }

  get _source(): string {
    const componentSource = ActionSource.clinicalCaseRequestsList;

    return this.parentSource && this.parentSource !== ActionSource.unspecified ?
      `${this.parentSource}-${componentSource}` : componentSource;
  }
}
