// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --search-dialog-padding-top: 20px;
  --search-dialog-padding-bottom: 20px;
  --search-dialog-padding-start: 20px;
  --search-dialog-padding-end: 20px;
}

.search-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
  row-gap: 5px;
  padding-top: var(--ion-safe-area-top);
}
.search-dialog-container ion-header {
  grid-row: 1;
  grid-column: 1;
}
.search-dialog-container ion-header .header-wrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
  padding-top: var(--search-dialog-padding-top);
  padding-bottom: var(--search-dialog-padding-bottom);
  padding-left: var(--search-dialog-padding-start);
  padding-right: var(--search-dialog-padding-end);
}
.search-dialog-container ion-header .header-wrapper app-back-button {
  width: 100%;
  height: 24px;
  grid-row: 1;
  grid-column: 1;
  --icon-font-size: 24px;
  --icon-color: var(--mi-secondary-color-2);
}
.search-dialog-container ion-header .header-wrapper app-global-search {
  grid-row: 1;
  grid-column: 2;
}
@media only screen and (max-width: 600px) {
  .search-dialog-container ion-header .header-wrapper {
    -moz-column-gap: 10px;
         column-gap: 10px;
  }
  .search-dialog-container ion-header .header-wrapper app-back-button {
    height: 60px;
  }
  .search-dialog-container ion-header .header-wrapper app-back-button ::ng-deep button {
    padding-top: 16px;
  }
}
.search-dialog-container ion-content {
  grid-row: 2;
  grid-column: 1;
  --padding-start: var(--search-dialog-padding-start);
  --padding-end: var(--search-dialog-padding-end);
}
.search-dialog-container .hidden {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
