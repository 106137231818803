// modules
import { AfterViewInit, Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { FormControl, FormGroup } from '@angular/forms';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject, firstValueFrom } from 'rxjs';

import { AuthenticationAOConfig, Platform } from 'src/config/config.model';
import { DeleteProfileSuccessResponse, UpdateUserProfileData, UserProfile } from '../../services/yeti-protocol/auth/mi';
import { Interest } from '../../services/interests.model';
import { Translator, TranslatorLanguage } from '../../services/translation.model';

// services

import { AuthService } from '../../services/auth/auth.service';
import { InterestsService } from '../../services/interests.service';
import { TranslationService } from '../../services/translation.service';
import { PushNotificationsService } from 'src/app/services/deep-links/push-notifications.service';
import { ContextService, CONTEXT_SERVICE } from 'src/app/services/context/context.model';
import { ContextSwitchService, CONTEXT_SWITCH_SERVICE } from 'src/app/services/context/context-switch.model';
import { ContextAddUIService } from 'src/app/services/context/context-add-ui.service';
import { TrackingService, TRACKING_SERVICE } from 'src/app/services/tracking/tracking.model';

import appConfig from 'src/config/config';
import { NativeSettings, NativeSettingsService } from 'src/app/services/deep-links/native-settings.service';
import { LinkOpenerService, TargetOfTheLink } from 'src/app/services/link-opener.service';
import { ActionSource } from '../../services/yeti-protocol/tracking';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI, ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { isMobilePlatform } from 'src/app/services/utils/utils';
import { Profession, ProfessionsService } from 'src/app/services/professions.service';

interface InterestListItem {
  interest: Interest,
  checked: boolean
}

type UserData = [
  profile: UserProfile,
  interests: Array<InterestListItem>
];

interface UserProfileSettingsComponentConfig {
  platform: Platform;
  authenticationAO: AuthenticationAOConfig;
  link: any
}

interface ProfessionInfo extends Profession {
  selected: boolean;
}

@Component({
  selector: 'app-user-profile-settings',
  templateUrl: './user-profile-settings.component.html',
  styleUrls: ['./user-profile-settings.component.scss'],
})
export class UserProfileSettingsComponent implements OnInit, AfterViewInit {

  config: UserProfileSettingsComponentConfig = appConfig;

  profile: any;
  translationLanguages: Array<TranslatorLanguage>;
  userSelectedLanguage: string;
  interests: Array<InterestListItem> = [];
  pushNotificationsAllowed = false;
  isDataLoaded = false;
  ActionSource = ActionSource;
  professions: Array<ProfessionInfo> = [];

  userForm = new FormGroup({
    translationLanguage: new FormControl(''),
    profession: new FormControl('')
  });

  private unsubscribe = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private interestsService: InterestsService,
    private translationService: TranslationService,
    private modalController: ModalController,
    @Inject(CONTEXT_SWITCH_SERVICE) private contextSwitchService: ContextSwitchService,
    private contextAddUIService: ContextAddUIService,
    @Optional() private pushNotificationsService: PushNotificationsService,
    @Optional() private nativeSettings: NativeSettingsService,
    @Inject(CONTEXT_DIALOGS_UI) private contextDialogs: ContextDialogsUI,
    private dialogs: DialogsUIService,
    private linkOpenerService: LinkOpenerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private professionsService: ProfessionsService,
  ) { }

  ngOnInit(): void {

    this.getProfessions().then(professions => {
      this.professions = professions;
      this.isDataLoaded = true;
    })

    this.userForm.valueChanges.pipe(
      switchMap(async (formValue) => {
        if (!this.isDataLoaded) {
          return;
        }
        if (this.profile.translationLanguage === formValue.translationLanguage
          && this.profile.profession === formValue.profession) {
          return
        }
        if (this.profile.translationLanguage !== formValue.translationLanguage) {
          this.onSaveTranslationLanguage();
        } else if (this.profile.profession !== formValue.profession) {
          this.onSaveProfession();
        } else {
          return;
        }
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(() => console.log('Saved'));
  }

  ngAfterViewInit(): void {
    this._loadData().then(data => {
      const [profile, interests] = data;
      this.profile = profile;
      this.interests = interests;
      this.translationLanguages = this.translationService.getLanguages(Translator.google);
      this.userSelectedLanguage = this.getUserSelectedLanguageName(profile.translationLanguage);

      this.setFormValues(profile);
      return this.updateNotificationButtonsState();
    }).then(() => {
      this.isDataLoaded = true;
    });
  }

  updateNotificationButtonsState(): Promise<void> {
    if (this.pushNotificationsService) {
      return this.pushNotificationsService.areNotificationsAllowed()
        .then(isAllowed => {
          this.pushNotificationsAllowed = isAllowed;
        });
    }
    return Promise.resolve();
  }

  _loadData(): Promise<UserData> {
    // make sure to reload profile when we open Settings for edit
    // to ensure we are editing with on the latest database values
    return this.authService.reloadProfile(this.contextService.currentContext.key)
      .then(profile => {
        return Promise.all([
          profile,
          this.getInterests(profile?.interests),
        ]);
      });
  }

  setFormValues(profile: UserProfile): void {
    this.userForm.patchValue({
      translationLanguage: profile?.translationLanguage,
      profession: profile.profession
    });
  }

  getInterests(profileInterests: Array<string>): Promise<Array<InterestListItem>> {
    return this.interestsService.getInterests(this.contextService.currentContext.key)
      .then((interests: Array<Interest>) => {
        return interests.map((interest: Interest) => {
          return {
            interest,
            checked: profileInterests.indexOf(interest.id) > -1 || false
          }
        })
      });
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  onLogout(): void {
    this.trackLogoutClicked();
    this.modalController.dismiss({ signOut: true });
  }

  onSaveTranslationLanguage(): void {
    const data = {
      translationLanguage: ''
    };
    data.translationLanguage = this.userForm.value.translationLanguage;
    this._updateProfile(data).then(profile => {
      this.userSelectedLanguage = this.getUserSelectedLanguageName(profile.translationLanguage);
      this.setFormValues(profile);
    });
  }

  onSaveProfession(): void {
    const data = {
      profession: ''
    };
    data.profession = this.userForm.value.profession;
    this._updateProfile(data).then(profile => {
      this.setFormValues(profile);
    });
  }

  _updateProfile(data: UpdateUserProfileData): Promise<UserProfile> {
    return this.authService.updateProfile(this.contextService.currentContext.key, data,
      false, ActionSource.userProfileSettings)
      .then(profile => {
        this.profile = profile;
        return profile;
      });
  }

  seeMyActions(): void {
    this.onClose();
    this.router.navigateByUrl([this.contextService.currentContext.homePath, 'user-activities'].join('/'));
  }

  toggleActionsTracking(): void {
    if (this.profile.allowTracking === true) {
      this.profile.allowTracking = false;
      this.updateTracking(this.profile.allowTracking);
    } else {
      this.profile.allowTracking = true;
      this.updateTracking(this.profile.allowTracking);
    }
  }

  toggleOnlyWithAoMembers(): void {
    const onlyWithAoMembersUpdate = {
      connectOnlyWithAoMembers: !this.profile.connectOnlyWithAoMembers
    };
    this._updateProfile(onlyWithAoMembersUpdate)
      .catch(err => {
        console.error(err);
      });
  }

  updateTracking(allowTracking: boolean): void {
    const trackingUpdate = { allowTracking };
    this._updateProfile(trackingUpdate)
      .catch(err => {
        console.error(err);
      });
  }

  compareInterests(arr1: Array<string>, arr2: Array<string>): boolean {
    return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index])
  }

  getUserSelectedLanguageName(profileTranslationLanguage: string): string {
    return this.translationLanguages.filter(language => language.key === profileTranslationLanguage)
      .map(language => language.name)
      .toString();
  }

  onOpenAbout(): void {
    this.onClose();
    this.router.navigateByUrl('/about');
  }

  get isMobile(): boolean {
    return isMobilePlatform(this.config.platform);
  }

  onOpenAOLink(): void {
    if (!this.config.authenticationAO) {
      return;
    }
    const AOLink = this.config.authenticationAO.updateEmailAddressAOLink;
    if (AOLink) {
      this.authService.openAoLink(AOLink);
    }
  }

  async showLinkedAOProfileDialog(): Promise<any> {
    const editActionKey = 'edit';

    const linkedAOProfileDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.userProfile.Settings.linkedAOProfileDialogTitle'
        },
        message: {
          translationKey: 'app.userProfile.Settings.linkedAOProfileDialogMessage'
        },
        actions: [
          {
            key: editActionKey,
            label: {
              translationKey: 'app.userProfile.Settings.linkedAOProfileDialogEditButton'
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(linkedAOProfileDialogData, false, true);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return;
      }

      if (res?.data?.actionKey === editActionKey) {
        this.onOpenAOLink();
      } else {
        return;
      }

    } catch (err) {
      console.log(err);
      return;
    }
  }

  async showDeleteAccountDialog(): Promise<void> {
    const confirmAction = 'confirm';
    const cancelAction = 'cancel';

    const deleteAccountConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.userProfile.Settings.deleteMyAccountTitle'
        },
        message: {
          translationKey: 'app.userProfile.Settings.deleteMyAccountMessage'
        },
        actions: [
          {
            key: confirmAction,
            label: {
              translationKey: 'app.userProfile.Settings.deleteMyAccountDeleteBtn'
            },
            className: 'primary'
          },
          {
            key: cancelAction,
            label: {
              translationKey: 'app.userProfile.Settings.deleteMyAccountCancelBtn'
            },
            className: 'secondary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(deleteAccountConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return;
      }

      if (res?.data?.actionKey === confirmAction) {
        this.showDoubleCheckDeleteActionDialog();
      } else {
        return;
      }

    } catch (err) {
      console.log(err);
      return;
    }

  }

  async showDoubleCheckDeleteActionDialog(): Promise<void> {
    const confirmDblCheckAction = 'confirm';
    const cancelDblCheckAction = 'cancel';

    const deleteAccountDoubleCheckConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.userProfile.Settings.deleteMyAccountDoubleCheckTitle'
        },
        message: {
          translationKey: ''
        },
        displayConfirmInput: true,
        actions: [
          {
            key: confirmDblCheckAction,
            label: {
              translationKey: 'app.userProfile.Settings.deleteMyAccountDoubleCheckDeleteBtn'
            },
            className: 'primary'
          },
          {
            key: cancelDblCheckAction,
            label: {
              translationKey: 'app.userProfile.Settings.deleteMyAccountDoubleCheckCancelBtn'
            },
            className: 'secondary'
          }
        ]
      }
    }

    const confirmModalDoubleCheck = await this.dialogs.createConfirmDialog(
      deleteAccountDoubleCheckConfirmDialogData);
    confirmModalDoubleCheck.present();

    try {
      const res = await confirmModalDoubleCheck.onDidDismiss();

      if (!res?.data?.actionKey) {
        return;
      }

      if (res?.data?.actionKey === confirmDblCheckAction) {
        const confirmation = res?.data?.confirmInput ? res?.data?.confirmInput.toLowerCase() : '';
        if (confirmation === 'delete') {
          this.authService.deleteProfile(this.contextService.currentContext.key)
            .then((response) => {
              const success = (response as DeleteProfileSuccessResponse).success;
              if (success) {
                this.showDeleteSuccessActionDialog();
              }
            }).catch(err => {
              console.log(err);
            });
        }
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }

  async showDeleteSuccessActionDialog(): Promise<void> {
    const confirmAction = 'confirm';

    const deleteSuccessConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.userProfile.Settings.deleteMyAccountSuccessTitle'
        },
        message: {
          translationKey: 'app.userProfile.Settings.deleteMyAccountSuccessText'
        },
        actions: [
          {
            key: confirmAction,
            label: {
              translationKey: 'app.userProfile.Settings.deleteMyAccountDoubleCheckDeleteBtn'
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModalSuccess = await this.dialogs.createConfirmDialog(
      deleteSuccessConfirmDialogData);
    confirmModalSuccess.present();

    try {
      const res = await confirmModalSuccess.onDidDismiss();

      if (!res?.data?.actionKey) {
        return;
      }

      if (res?.data?.actionKey === confirmAction) {
        this.onLogout();
      } else {
        return;
      }

    } catch (err) {
      console.log(err);
      return;
    }
  }

  async interestsPicker(): Promise<void> {
    const contexts = await this.contextSwitchService.getUserContexts(this.profile);

    this.contextAddUIService.addContext(
      this.contextService.currentContext,
      contexts.availableForAddingContexts,
      true,
      this.profile.interests
    ).then(() => {
      return this.authService.reloadProfile(this.contextService.currentContext.key).then(profile => {
        this.profile = profile;
        this.setFormValues(profile);
      })
    });
  }

  get pushNotificationsAvailable(): boolean {
    return this.isMobile;
  }

  togglePushNotificationsAllowed(): void {
    let promise;

    this.trackPushNotificationSwitchClicked();

    if (this.pushNotificationsAllowed) {
      promise = this.openNotificationsSettings();
    } else {
      promise = this.pushNotificationsService.promptForNotifications();
    }
    promise.then(() => {
      this.updateNotificationButtonsState();
    })
      .catch(err => {
        console.log(err);
        this.updateNotificationButtonsState();
      });
  }

  openNotificationsSettings(): Promise<void> {
    return this.nativeSettings.open(NativeSettings.PUSH_NOTIFICATIONS);
  }

  openHelpCenter(): void {
    this.linkOpenerService.open(appConfig.link.helpCenter);
  }

  openAOLink(): void {
    if (!this.config.authenticationAO) {
      return;
    }
    const AOLink = this.config.authenticationAO.updateUserDataAoLink;
    if (AOLink) {
      this.authService.openAoLink(AOLink);
    }
  }

  get workDataText(): string {
    let result = '';
    if (this.showWorkData) {
      result += this.profile.country ? this.profile.country : '';
      result += this.profile.country && this.profile.organization ? ', ' : '';
      result += this.profile.organization ? this.profile.organization : '';
      result += this.profile.organization && this.profile.department ? ', ' : '';
      result += this.profile.department ? this.profile.department : '';

    }
    return result;
  }

  get showWorkData(): boolean {
    return this.profile.country || this.profile.organization || this.profile.department;
  }

  get shouldShowHelpCenterButton(): boolean {
    return appConfig.name === 'MyAO';
  }

  trackLogoutClicked(): void {
    this.trackingService.trackGenericClickedAction('logout', 'logout', 'logoutClicked')
      .catch(_err => {
        console.error('Could not track logout button clicked/tapped action.');
      });
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  openLink(link: string): void {
    this.linkOpenerService.open(link);
  }

  openCommunityGuidelines(): void {
    const url = `${appConfig?.webUrl}${this.contextService.currentContext.homePath}/community-guidelines`;
    this.linkOpenerService.open(url, TargetOfTheLink.SystemBrowser);
  }

  onEditProfile(_user: UserProfile): void {
    this.contextDialogs.editCurrentUserProfile()
      .then(data => {
        if (data) {
          this.profile = data;
        }
      });
  }

  trackPushNotificationSwitchClicked(): void {
    this.trackingService.trackGenericClickedAction('pushNotification', JSON.stringify(this.pushNotificationsAllowed), 'pushNotification')
      .catch(_err => {
        console.error('Could not track push notifications switch clicked/tapped action.');
      });
  }

  getProfessions(): Promise<Array<ProfessionInfo>> {
    return firstValueFrom(this.professionsService.professions)
      .then(
        professions => {
          return professions.map(profession => {
            const { key, label, icon } = profession;
            return { key, label, icon, selected: false };
          });
        }
      );
  }

  get showLinkOrcIdButton(): boolean {
    return !this.profile?.orcid?.length;
  }
}

