import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-post-image',
  templateUrl: './post-image.component.html',
  styleUrls: ['./post-image.component.scss'],
})
export class PostImageComponent {

  @Input() bannerText = 'Post'

  constructor() { }

}
