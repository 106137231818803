import { Component, EventEmitter, Input, Output } from '@angular/core';

// models
import { ShortPublicCLinicalCase } from 'src/app/services/yeti-protocol/clinical-case';

@Component({
  selector: 'app-clinical-case-sharing-card',
  templateUrl: './clinical-case-sharing-card.component.html',
  styleUrls: ['./clinical-case-sharing-card.component.scss'],
})
export class ClinicalCaseSharingCardComponent {
  @Input() clinicalCase: ShortPublicCLinicalCase;
  @Output() openCase: EventEmitter<string> = new EventEmitter();

  onOpenCase(): void {
    this.openCase.emit(this.clinicalCase?._id);
  }

  get overlayImgText(): string {
    return '+ ' + this.clinicalCase?.numberOfDocuments;
  }
}
