// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blue-card {
  margin: 0 16px !important;
  --background: var(--mi-light-blue-color);
  box-shadow: none;
  border-radius: 5px;
}
.blue-card .mobile-invite-button {
  width: 100% !important;
}
.blue-card .invite-button {
  --background: var(--mi-primary-color);
  --border-radius: 4px;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  margin-top: 15px;
  display: flex;
  text-transform: none;
}
.blue-card .blue-card-content .blue-card-text-container {
  color: var(--mi-secondary-color-1);
  padding: 15px 25px;
}
.blue-card .blue-card-content .blue-card-text-container h3 {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}
.blue-card .blue-card-content .blue-card-text-container p {
  margin-top: 15px;
  color: var(--mi-secondary-color-1);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
