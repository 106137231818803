import {Component, Inject, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TRACKING_SERVICE, TrackingService} from '../../services/tracking/tracking.model';

@Component({
  selector: 'app-create-case-decision-dialog',
  templateUrl: './create-case-decision-dialog.component.html',
  styleUrls: ['./create-case-decision-dialog.component.scss'],
})
export class CreateCaseDecisionDialogComponent implements OnInit {

  @Input() status: 'ready' | 'inProgress' | 'failed';
  @Input() numberOfCredits: number;
  @Input() usedCredits: number;

  constructor(
    private modalController: ModalController,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) {
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.trackDecisionModalActionClicks('closeCreateCaseSelector', 'case', 'caseCreateSelector');
    this.modalController.dismiss(null, 'cancel');
  }

  proceedWithManualCreation(): void {
    this.trackDecisionModalActionClicks('createContent_manual', 'case', 'caseCreateSelector');
    this.modalController.dismiss(null, 'manual');
  }

  proceedWithAiCaseConvert(): void {
    if (this.status !== 'ready') {
      this.modalController.dismiss(null, 'caseImporterNotReady');
    } else if (this.usedCredits >= this.numberOfCredits) {
      this.modalController.dismiss(null, 'insufficientConversionCredits');
    } else {
      this.trackDecisionModalActionClicks('createContent_ppt2Case', 'case', 'caseCreateSelector');
      this.modalController.dismiss(null, 'aiConversion');
    }
  }

  trackDecisionModalActionClicks(objectId: string, objectType: string, objectTitle: string): void {
    this.trackingService
      .trackGenericClickedAction(objectId, objectType, objectTitle, {
        source: 'caseFolioPage_createCaseSelector',
      })
      .catch(_err => {
        console.error('Could not track content created click action.');
      });
  }

}
