// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --image-attachments-container-padding-top: 10px;
  --image-attachments-container-padding-bottom: 0px;
  --image-attachments-container-padding-start: 0px;
  --image-attachments-container-padding-end: 0px;
  --image-attachments-container-width: 100%;
  --image-attachments-container-height: 100%;
  --image-attachments-container-image-width: 100%;
  --image-attachments-container-image-height: 100%;
  --image-attachments-first-image-height: 150px;
  --image-attachments-column-gap: 10px;
  --image-attachments-row-gap: 10px;
  --image-attachments-images-border-radius: 5px;
  --image-attachments-images-action-button-size: 22px;
}

.image-attachments-container {
  display: grid;
  padding-top: var(--image-attachments-container-padding-top);
  padding-bottom: var(--image-attachments-container-padding-bottom);
  padding-left: var(--image-attachments-container-padding-start);
  padding-right: var(--image-attachments-container-padding-end);
}
.image-attachments-container.show-action-button {
  display: grid;
  grid-template-columns: auto 7px;
  grid-template-rows: 7px auto;
}
.image-attachments-container .images-container {
  grid-row: 2/3;
  grid-column: 1/2;
  width: var(--image-attachments-container-width);
  height: var(--image-attachments-container-height);
  display: grid;
}
.image-attachments-container .images-container app-image-attachment {
  --image-attachment-width: var(--image-attachments-container-image-width);
  --image-attachment-height: var(--image-attachments-container-image-height);
  --image-attachment-border-radius: var(--image-attachments-images-border-radius);
  width: 100%;
}
.image-attachments-container .images-container.one-image {
  grid-template-rows: 253px;
}
.image-attachments-container .images-container.one-image app-image-attachment :nth-child(1) {
  height: 253px;
  grid-template-rows: 253px;
}
.image-attachments-container .images-container.one-image.prevent-one-image-increased-size {
  grid-template-rows: var(--image-attachments-first-image-height);
}
.image-attachments-container .images-container.one-image.prevent-one-image-increased-size app-image-attachment :nth-child(1) {
  height: var(--image-attachments-container-height);
  grid-template-rows: var(--image-attachments-first-image-height);
}
.image-attachments-container .images-container.two-images {
  height: var(--image-attachments-container-height);
  grid-template-rows: var(--image-attachments-first-image-height);
  grid-template-columns: calc(50% - var(--image-attachments-column-gap) / 2) calc(50% - var(--image-attachments-column-gap) / 2);
  justify-items: center;
  justify-content: center;
  -moz-column-gap: var(--image-attachments-column-gap);
       column-gap: var(--image-attachments-column-gap);
}
.image-attachments-container .images-container.two-images :nth-child(1) {
  grid-row: 1;
  grid-column: 1;
}
.image-attachments-container .images-container.two-images :nth-child(2) {
  grid-row: 1;
  grid-column: 2;
}
.image-attachments-container .images-container.three-images {
  height: var(--image-attachments-container-height);
  --first-image-size: var(--image-attachments-first-image-height);
  grid-template-rows: calc(var(--first-image-size) / 2 - var(--image-attachments-row-gap) / 2) calc(var(--first-image-size) / 2 - var(--image-attachments-row-gap) / 2);
  grid-template-columns: calc(60% - var(--image-attachments-column-gap) / 2) calc(40% - var(--image-attachments-column-gap) / 2);
  justify-items: center;
  justify-content: center;
  row-gap: var(--image-attachments-row-gap);
  -moz-column-gap: var(--image-attachments-column-gap);
       column-gap: var(--image-attachments-column-gap);
}
.image-attachments-container .images-container.three-images :nth-child(1) {
  grid-row: 1/3;
  grid-column: 1;
}
.image-attachments-container .images-container.three-images :nth-child(2) {
  grid-row: 1;
  grid-column: 2;
}
.image-attachments-container .images-container.three-images :nth-child(3) {
  grid-row: 2;
  grid-column: 2;
}
.image-attachments-container .images-container.three-images :nth-child(2) ::ng-deep .play,
.image-attachments-container .images-container.three-images :nth-child(3) ::ng-deep .play {
  width: 34px;
  height: 34px;
}
.image-attachments-container .images-container.four-images {
  height: var(--image-attachments-container-height);
  --first-image-size: var(--image-attachments-first-image-height);
  grid-template-rows: calc(var(--first-image-size) / 2 - var(--image-attachments-row-gap) / 2) calc(var(--first-image-size) / 2 - var(--image-attachments-row-gap) / 2);
  grid-template-columns: calc(50% - var(--image-attachments-column-gap)) calc(25% - var(--image-attachments-column-gap) / 2) calc(25% - var(--image-attachments-column-gap) / 2);
  justify-items: center;
  justify-content: center;
  row-gap: var(--image-attachments-row-gap);
  -moz-column-gap: var(--image-attachments-column-gap);
       column-gap: var(--image-attachments-column-gap);
}
.image-attachments-container .images-container.four-images :nth-child(1) {
  grid-row: 1/3;
  grid-column: 1;
}
.image-attachments-container .images-container.four-images :nth-child(2) {
  grid-row: 1;
  grid-column: 2;
}
.image-attachments-container .images-container.four-images :nth-child(3) {
  grid-row: 2;
  grid-column: 2;
}
.image-attachments-container .images-container.four-images :nth-child(4) {
  grid-row: 1;
  grid-column: 3;
}
.image-attachments-container .images-container.four-images ::ng-deep .play {
  width: 34px;
  height: 34px;
}
.image-attachments-container .images-container.five-images, .image-attachments-container .images-container.more-then-five-images {
  height: var(--image-attachments-container-height);
  --first-image-size: var(--image-attachments-first-image-height);
  grid-template-rows: calc(var(--first-image-size) / 2 - var(--image-attachments-row-gap) / 2) calc(var(--first-image-size) / 2 - var(--image-attachments-row-gap) / 2);
  grid-template-columns: calc(50% - var(--image-attachments-column-gap)) calc(25% - var(--image-attachments-column-gap) / 2) calc(25% - var(--image-attachments-column-gap) / 2);
  justify-items: center;
  justify-content: center;
  row-gap: var(--image-attachments-row-gap);
  -moz-column-gap: var(--image-attachments-column-gap);
       column-gap: var(--image-attachments-column-gap);
}
.image-attachments-container .images-container.five-images :nth-child(1), .image-attachments-container .images-container.more-then-five-images :nth-child(1) {
  grid-row: 1/3;
  grid-column: 1;
}
.image-attachments-container .images-container.five-images :nth-child(2), .image-attachments-container .images-container.more-then-five-images :nth-child(2) {
  grid-row: 1;
  grid-column: 2;
}
.image-attachments-container .images-container.five-images :nth-child(3), .image-attachments-container .images-container.more-then-five-images :nth-child(3) {
  grid-row: 2;
  grid-column: 2;
}
.image-attachments-container .images-container.five-images :nth-child(4), .image-attachments-container .images-container.more-then-five-images :nth-child(4) {
  grid-row: 1;
  grid-column: 3;
}
.image-attachments-container .images-container.five-images :nth-child(5), .image-attachments-container .images-container.more-then-five-images :nth-child(5) {
  grid-row: 2;
  grid-column: 3;
}
.image-attachments-container .images-container.five-images :nth-child(2) ::ng-deep .play,
.image-attachments-container .images-container.five-images :nth-child(3) ::ng-deep .play,
.image-attachments-container .images-container.five-images :nth-child(4) ::ng-deep .play,
.image-attachments-container .images-container.five-images :nth-child(5) ::ng-deep .play, .image-attachments-container .images-container.more-then-five-images :nth-child(2) ::ng-deep .play,
.image-attachments-container .images-container.more-then-five-images :nth-child(3) ::ng-deep .play,
.image-attachments-container .images-container.more-then-five-images :nth-child(4) ::ng-deep .play,
.image-attachments-container .images-container.more-then-five-images :nth-child(5) ::ng-deep .play {
  width: 34px;
  height: 34px;
}
.image-attachments-container .images-container.more-then-five-images .more-then-five-images-container {
  grid-row: 2;
  grid-column: 3;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-attachments-container .images-container.more-then-five-images .more-then-five-images-container p {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  color: var(--mi-white-color);
  margin: 0;
  z-index: 3;
}
.image-attachments-container .images-container.more-then-five-images .background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--mi-overlays-color);
  z-index: 2;
  opacity: 0.85;
  border-radius: var(--image-attachments-images-border-radius);
}
.image-attachments-container .single-image-container {
  width: var(--image-attachments-container-width);
  height: var(--image-attachments-container-height);
  grid-row: 2;
  grid-column: 1;
  position: relative;
}
.image-attachments-container .single-image-container app-image-attachment {
  position: absolute;
  --image-attachment-height: var(--image-attachments-container-height);
  width: 100%;
}
.image-attachments-container .single-image-container app-image-attachment ::ng-deep .play {
  top: -80%;
  width: 54px;
  height: 54px;
}
.image-attachments-container .single-image-container .more-then-one-image-container {
  grid-row: 2;
  grid-column: 3;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-attachments-container .single-image-container .more-then-one-image-container p {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  color: var(--mi-white-color);
  margin: 0;
  z-index: 3;
}
.image-attachments-container .single-image-container .background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--mi-overlays-color);
  z-index: 2;
  opacity: 0.85;
  border-radius: var(--image-attachments-images-border-radius);
}
.image-attachments-container .single-image-container .count-badge {
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-color: var(--mi-secondary-card-background-05);
  z-index: 5;
  color: var(--mi-white-color);
  padding: 3px 6px 0;
  border-radius: 8px;
}
.image-attachments-container .button-container {
  grid-row: 1/-1;
  grid-column: 1/-1;
  justify-self: end;
  align-self: start;
  z-index: 2;
}
.image-attachments-container .button-container app-icon-button {
  --icon-button-background-width: var(--image-attachments-images-action-button-size);
  --icon-button-background-height: var(--image-attachments-images-action-button-size);
  --icon-button-background-color: var(--mi-color-text-lite);
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 50%;
  --icon-button-icon-size: 10px;
  --icon-button-icon-color: var(--mi-white-color);
}
.image-attachments-container .count-badge .count,
.image-attachments-container .counts-for-more .count {
  display: inline-block;
  line-height: 14px;
  font-size: 14px;
  vertical-align: top;
}
.image-attachments-container .count-badge .count-images,
.image-attachments-container .counts-for-more .count-images {
  margin: 0 4px 0 2px;
}
.image-attachments-container .count-badge .count-videos,
.image-attachments-container .counts-for-more .count-videos {
  margin-left: 3px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
