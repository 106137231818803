import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CustomTab } from '../custom-tabs/custom-tabs.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-card-with-tabs',
  templateUrl: './card-with-tabs.component.html',
  styleUrls: ['./card-with-tabs.component.scss'],
})
export class CardWithTabsComponent {

  @Input() title: string;
  @Input() showActionButton: boolean;
  @Input() actionButtonText = this.appTranslationService.instant('app.common.see-all');
  @Input() customTabs: Array<CustomTab> = [];

  @Output() actionButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() tabClicked: EventEmitter<string> = new EventEmitter();

  constructor(
    private appTranslationService: AppTranslationService
  ) { }

  actionButtonClick(): void {
    this.actionButtonClicked.emit();
  }

  tabClick(tabId: string): void {
    this.tabClicked.emit(tabId);
  }

}
