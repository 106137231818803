// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
  position: relative;
  padding-top: 20px;
}
.notification .message {
  border-radius: var(--mi-standard-padding);
  padding: var(--mi-standard-padding);
  color: var(--mi-color-text-lite);
  border: solid 0.5px var(--mi-color-text-lite);
  margin: 10px 30px;
  text-align: center;
  font-weight: var(--font-weight-light);
  font-style: normal;
  font-size: var(--font-size-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
