import { InjectionToken } from '@angular/core';
import { ContextConfigInterface } from 'src/config/config.model';

export interface ContextService {

  get currentContext(): ContextConfigInterface;
  getContext(contextKey: string): ContextConfigInterface;
  get defaultContext(): ContextConfigInterface;
  saveCurrentContextKey(): Promise<void>;
  getLastSavedContextKey(): Promise<string>;
  get isSingleContextApp(): boolean;
  getCurrentContext(): Promise<ContextConfigInterface>;
  get currentPathContext(): ContextConfigInterface;
}

export const CONTEXT_SERVICE = new InjectionToken<ContextService>('context.service');
