import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-applaud-button',
  templateUrl: './applaud-button.component.html',
  styleUrls: ['./applaud-button.component.scss'],
})
export class ApplaudButtonComponent extends BaseButtonComponent<boolean> {

  @Input() applauded: boolean;

  getEventData(): boolean{
    return !this.applauded;
  }
}
