// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.latest-comment-container {
  width: 100%;
}
.latest-comment-container .latest-comment {
  width: 100%;
  padding: 13px var(--post-card-padding-end) 5px var(--post-card-padding-start);
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
}
.latest-comment-container .latest-comment .owner-info-container {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 7px;
       column-gap: 7px;
}
.latest-comment-container .latest-comment .owner-info-container .user-profile-image-container {
  grid-row: 1;
  grid-column: 1;
}
.latest-comment-container .latest-comment .owner-info-container .user-profile-image-container app-user-profile-image {
  --user-image-size: 35px;
}
.latest-comment-container .latest-comment .owner-info-container .owner-text-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.latest-comment-container .latest-comment .owner-info-container .owner-text-container .fullname-container {
  overflow: hidden;
  width: 100%;
}
.latest-comment-container .latest-comment .owner-info-container .owner-text-container .country-container {
  overflow: hidden;
  width: 100%;
}
.latest-comment-container .latest-comment .owner-info-container .owner-text-container .country-container .country {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.latest-comment-container .latest-comment .comment-content-container app-rich-text-document-editor {
  --rich-text-document-editor-padding-top: 0px;
  --rich-text-document-editor-padding-bottom: 0px;
  --rich-text-document-editor-padding-start: 0px;
  --rich-text-document-editor-padding-end: 0px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
