import { Inject, Injectable } from '@angular/core';

// models
import { AdvertismentField, UserProfile } from './yeti-protocol/auth/mi';

// services
import { AuthService } from './auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';

@Injectable({
  providedIn: 'root'
})
export class AdvertisingStatusService {

  user: UserProfile;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService
  ) {
    this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.user = userProfile;
    });
  }

  async getInfo(key: string, forceFetchUserProfile?: boolean): Promise<AdvertismentField> {

    let userProfile = this.user;

    if (forceFetchUserProfile) {
      userProfile = await this.getUserProfile();
    }

    if (!userProfile || !userProfile?.advertising) {
      return null;
    }

    return userProfile?.advertising[key];
  }

  setInfo(advertismentField: AdvertismentField): Promise<any> {
    return this.authService.updateProfile(this.contextService?.currentContext?.key, { advertising: advertismentField });
  }

  private async getUserProfile(): Promise<UserProfile> {

    let userProfile;

    try {
      userProfile = await this.authService.getProfile(this.contextService?.currentContext?.key);
    } catch {
      userProfile = null;
    }

    return userProfile;
  }

}
