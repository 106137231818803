// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect-with-ao-event-co-participants-card-container .connect-with-co-participants-card {
  width: 100%;
  padding: 20px 30px 20px 20px;
  background-color: var(--mi-light-purple-color);
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.connect-with-ao-event-co-participants-card-container .connect-with-co-participants-card .content {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  row-gap: 15px;
}
.connect-with-ao-event-co-participants-card-container .connect-with-co-participants-card .content .label-container .label {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  text-align: left;
  color: var(--mi-secondary-color-2);
  margin: 0;
}
.connect-with-ao-event-co-participants-card-container .connect-with-co-participants-card .button-container {
  grid-row: 1;
  grid-column: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
