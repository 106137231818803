import { Inject, Injectable } from '@angular/core';
import { OrcIdAuthDeviceServiceInterface } from './orcid-auth-device.service.interface';
import appConfig from 'src/config/config';
import { InAppBrowser, InAppBrowserEvent } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { InAppBrowserColors, Platform } from 'src/config/config.model';
import { AppTranslationService } from '../../app-translation.service';
import { TRACKING_SERVICE, TrackingService } from '../../tracking/tracking.model';
import { ActionTracked, LinkOrcIdUrlOpenedTrackingParam, TrackingRequest } from '../../yeti-protocol/tracking';

export interface OrcIdAuthDeviceIOSServiceConfig {
  inAppBrowserColors: InAppBrowserColors;
}

@Injectable()
export class OrcIdAuthDeviceIOSService implements OrcIdAuthDeviceServiceInterface {

  config: OrcIdAuthDeviceIOSServiceConfig = appConfig;

  constructor(
    protected inAppBrowser: InAppBrowser,
    protected appTranslationService: AppTranslationService,
    @Inject(TRACKING_SERVICE) protected trackingService: TrackingService
  ) { }

  signIn(orcIdOAuthUrl: string): Promise<any> {

    const browser = this.inAppBrowser.create(orcIdOAuthUrl, '_system', {
      location: 'yes',
      toolbarcolor: this.config.inAppBrowserColors.toolbarcolor,
      closebuttoncaption: this.appTranslationService.instant('app.common.close'),
      closebuttoncolor: this.config.inAppBrowserColors.closebuttoncolor,
      hideurlbar: 'yes',
      zoom: 'no'
    });
    browser.on('loaderror').subscribe(evt => {
      console.error(evt);
      this._trackErrorOpeningOrcIdLink(evt);
    });
    return Promise.resolve();
  }

  _trackErrorOpeningOrcIdLink(error: InAppBrowserEvent): void {
    const paramsToTrack: LinkOrcIdUrlOpenedTrackingParam = {
      objectId: 'linkOrcIdUrlOpened',
      objectType: 'linkOrcIdUrlOpened',
      objectTitle: 'Error',
      error: JSON.stringify(error),
      platform: Platform.IOS
    };
    const trackData: TrackingRequest = {
      action: ActionTracked.linkOrcIdUrlOpened,
      params: paramsToTrack
    };

    this.trackingService.track(trackData);
  }
}

