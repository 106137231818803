// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
}

.title {
  font-size: var(--font-size-extra-large);
  color: var(--mi-secondary-color-1);
  text-align: center;
  margin-bottom: 10px;
}

.message {
  font-size: var(--font-size-regular);
  color: var(--mi-color-text-lite);
  margin-bottom: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
