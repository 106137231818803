import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface TargetImpression {
  rootKey: string;
  targetKey: string;
}

interface TargetInfo {
  rootKey: string;
  targetKey: string;
  viewStartTime: number;
  notified: boolean;
}

interface VisibilityTrackerServiceConfig {
  minimalImpressionDurationMsec: number;
}

function buildKey(rootKey: string, targetKey: string): string {
  return [rootKey, targetKey].join(':');
}

@Injectable()
export class VisibilityTrackerService {

  config: VisibilityTrackerServiceConfig = {
    minimalImpressionDurationMsec: 1000
  };

  intersectionObservers: Record<string, IntersectionObserver> = {};
  trackingStorage: Record<string, TargetInfo> = {};
  savedTrackingInfoForRootKey: Record<string, Array<string>> = {}; // <rootKey, [targetKey]>

  private impressionsSubject: Subject<TargetImpression> = new Subject();

  get impressionsAsObservable(): Observable<TargetImpression> {
    return this.impressionsSubject.asObservable();
  }

  isRegistered(observerKey: string): boolean {
    return !!this.intersectionObservers[observerKey];
  }

  registerRoot(observerKey: string, rootNode: HTMLElement): void {
    if (this.intersectionObservers[observerKey]) {
      throw new Error(`observerKey is already registered: ${observerKey}`);
    }
    this.intersectionObservers[observerKey] =
      new IntersectionObserver((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        this.observerCallback(observerKey, entries, observer);
      }, {
        root: rootNode,
        rootMargin: '0px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
      });
    console.log(`created observer: ${observerKey}`);
  }

  unregisterRoot(rootKey: string): void {
    const observer = this.intersectionObservers[rootKey];
    if (observer) {
      delete this.intersectionObservers[rootKey];
      observer.disconnect();

      const targetKeysForRootKey = this.savedTrackingInfoForRootKey?.[rootKey];

      targetKeysForRootKey?.forEach(targetKey => {
        this._removeTrackingInfo(rootKey, targetKey);
      })
    }
  }

  registerTarget(rootKey: string, targetNode: HTMLElement): void {
    const observer = this.intersectionObservers[rootKey];
    if (!observer) {
      throw new Error(`observerKey is not registered: ${rootKey}`);
    }
    observer.observe(targetNode);
  }

  observerCallback(rootKey: string, entries: IntersectionObserverEntry[], _observer: IntersectionObserver): void {
    let entriesAdded = false;
    entries.forEach(entry => {
      const targetKey = entry.target.getAttribute('id');

      if (entry.intersectionRatio >= 0.5) {
        const info = this._getTrackingInfo(rootKey, targetKey);
        if (info) {
          if (info.notified) {
            return; // already notified
          }
          if (entry.time - info.viewStartTime > this.config.minimalImpressionDurationMsec) {
            this.impressionsSubject.next({
              rootKey: rootKey,
              targetKey: targetKey
            });
            info.notified = true;
          }
        } else {
          this._saveTrackingInfo({
            rootKey: rootKey,
            targetKey: targetKey,
            viewStartTime: entry.time,
            notified: false
          });
          entriesAdded = true;
        }
        return;
      }
      if (entry.intersectionRatio < 1) {
        const info = this._getTrackingInfo(rootKey, targetKey);
        if (info && !info.notified) {
          if (entry.time - info.viewStartTime > this.config.minimalImpressionDurationMsec) {
            this.impressionsSubject.next({
              rootKey: rootKey,
              targetKey: targetKey
            });
            info.notified = true;
          } else {
            this._removeTrackingInfo(rootKey, targetKey);
          }
        }
      }
    });
    if (entriesAdded) {
      this._trackingCleanup();
    }
  }

  _saveTrackingInfo(info: TargetInfo): void {
    const key = buildKey(info.rootKey, info.targetKey);
    this.trackingStorage[key] = info;
    this.savedTrackingInfoForRootKey[info.rootKey] = this.savedTrackingInfoForRootKey[info.rootKey]?.length ?
      [...this.savedTrackingInfoForRootKey[info.rootKey], info.targetKey] : [info.targetKey];
  }

  _getTrackingInfo(rootKey: string, targetKey: string): TargetInfo {
    const key = buildKey(rootKey, targetKey);
    return this.trackingStorage[key];
  }

  _removeTrackingInfo(rootKey: string, targetKey: string): void {
    const key = buildKey(rootKey, targetKey);
    if (this.trackingStorage[key]) {
      delete this.trackingStorage[key];
    }
  }

  _trackingCleanup(): void {
    setTimeout(() => {
      for (const key in this.trackingStorage) {
        if (this.trackingStorage.hasOwnProperty(key)) {
          const info = this.trackingStorage[key];
          if (info.notified) {
            continue;
          }
          this.impressionsSubject.next({
            rootKey: info.rootKey,
            targetKey: info.targetKey
          });
          info.notified = true;
        }
      }
    }, this.config.minimalImpressionDurationMsec);
  }
}
