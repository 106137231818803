import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

// services
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-group-image',
  templateUrl: './group-image.component.html',
  styleUrls: ['./group-image.component.scss'],
})
export class GroupImageComponent {

  @Input() groupImageUrl: string;
  @Input() groupId: string;
  @Input() bannerText?: string;
  @Input() disableGroupOpen: boolean;
  @Input() aoMembersOnly: boolean;
  @Input() source: ActionSource;

  @Output() imageClick: EventEmitter<any> = new EventEmitter();

  showDefaultGroupImage: boolean;

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  onClick(event: Event): void {
    if (this.disableGroupOpen) {
      this.imageClick.emit(event);
    } else {
      this.openGroupPage();
    }

    event.stopPropagation();
    return null;
  }

  openGroupPage(): void {

    if (!this.groupId) {
      return;
    }

    let url = `${this.contextService.currentContext.homePath}/group/${this.groupId}`;

    if (this.source && this.source !== ActionSource.unspecified) {
      url = `${url}?source=${this.source}`;
    }

    this.router.navigateByUrl(url);
  }
}
