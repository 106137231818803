import {
  Group,
  Post as EventPostChatterApi,
  Case as EventCaseChatterApi
 } from './chatter-api';
import { ConnectionStatus } from './connection';
import { ContextRequest } from './context-request';
import { ErrorResponse } from './error';
import { PaginationRequest } from './pagination-request';
import { ShortPublicProfile, UserProfileImage } from './public-profile';

export interface MyAOEvent {
  id?: number;
  country?: string;
  description?: string;
  endDate?: string;
  eventLevel?: string;
  eventType?: string;
  eventVenue?: string;
  geo?: { lng?: number, lat?: number };
  language?: string;
  location?: string;
  region?: string;
  speciality?: string;
  interests?: string[];
  startDate: string;
  text: string;
  website?: string;
  _id?: string;
}

export interface Participant extends ShortPublicProfile {
  connectionStatus: ConnectionStatus,
  isFollower?: any,
  isAOFaculty?: any,
  connectionInfo?: AOEventParticipantConnectionInfo
}

export interface AOEventParticipant {
  _id: string,
  deleted?: boolean,
  createdDate?: string,
  participantType: AOEventParticipantType,
  participantRole: string,
  participantInfo: Participant
}

export interface AOEventParticipantConnectionInfo {
  id: string,
  status: ConnectionStatus,
  isInitiator: boolean
}

export enum AOEventParticipantType {
  PARTICIPANT = 'participant',
  CONTRIBUTOR = 'contributor'
}


export interface EventsListResponse {
  _schemaPath?: 'data-update/events.json';
  _schemaMethod?: 'get';
  result: Array<MyAOEvent>;
}

export interface ParticipantPreview {
  userId?: string;
  profileImageUrl?: string;
}

export interface ContributorPreview {
  userId?: string;
  profileImageUrl?: string;
}

export interface AOEvent {
  contributorsPreview?: Array<ContributorPreview>;
  imageUrl?: string;
  country?: string;
  createdDate?: string;
  deleted?: boolean;
  description?: string;
  endDate?: string;
  endTime?: string;
  eventType?: string;
  eventVenue?: string;
  groupInfo?: {
    appId?: string;
    createdDate?: string;
    deleted?: boolean;
    imageUrl?: string;
    title?: string;
    totalMembersCount?: number;
    visibility?: string;
    _id?: string;
  }
  aoEventId?: string;
  isAttendee?: boolean;
  language?: string;
  location?: string;
  participantsPreview?: Array<ParticipantPreview>;
  region?: string;
  speciality?: string;
  startDate?: string;
  startTime?: string;
  state?: string;
  text?: string;
  timeZone?: string;
  totalContributors?: number;
  totalParticipants?: number;
  _id?: string;
  level?: string;
  website?: string;
  programUrl?: string;
  assessmentUrl?: string;
  hasRecommended?: boolean;
  recommends?: Array<UserProfileImage>;
  isModerator?: boolean;
}

export interface AOGroupEventsRequest {
  _schemaPath?: '/api/ionic/groups/events';
  _schemaMethod?: 'get';
  appId: string; // context
  start?: number;
  count?: number;
}

export interface AOGroupEventsResponse {
  _schemaPath?: '/api/ionic/groups/events';
  _schemaMethod?: 'get';
  result: Array<Group>;
  totalItemsCount: number,
  success: boolean
}

export enum AOEventType {
  UPCOMING = 'upcomingEvent',
  PAST = 'pastEvent',
}

export interface AOEventsListHomePageRequestParams {
  _schemaPath?: '/api/ionic/events/feed';
  _schemaMethod?: 'get';
  appId: string; // context
  start?: number;
  count?: number;
}

export interface AOEventsListHomePageSuccessResponse {
  _schemaPath?: '/api/ionic/events/feed';
  _schemaMethod?: 'get';
  result: Array<AOEvent>;
  totalItemsCount: number,
  meta?: AOEventType,
  success: boolean
}

export type AOEventsListHomePageResponse = AOEventsListHomePageSuccessResponse | ErrorResponse;

export interface AOEventsListRequestParams {
  _schemaPath?: '/api/ionic/events/events';
  _schemaMethod?: 'get';
  appId: string; // context
  start?: number;
  count?: number;
  onlyForLoggedIn: boolean;
  past: boolean;
  liveNow: boolean;
}

export interface AOEventsListResponse {
  _schemaPath?: '/api/ionic/events/events';
  _schemaMethod?: 'get';
  result: Array<AOEvent>;
  totalItemsCount: number,
  success: boolean
}

export interface AOEventRequest {
  _schemaPath?: '/api/ionic/events/byId/:eventId';
  _schemaMethod?: 'get';
  appId: string; // context
}

export interface AOEventResponse {
  _schemaPath?: '/api/ionic/events/byId/:eventId';
  _schemaMethod?: 'get';
  result: AOEvent;
  success: boolean
}

export type AOEventParticipantsResponse = AOEventParticipantsSuccessResponse | ErrorResponse;

export interface AOEventParticipantsRequestParams extends PaginationRequest, ContextRequest { }

export interface AOEventParticipantsSuccessResponse {
  _schemaPath?: '/api/ionic/events/attendees/:eventId/:type?start=&count=&appId=';
  _schemaMethod?: 'get';
  meta?: {
    totalContributors: number,
    totalParticipants: number
  },
  result: Array<AOEventParticipant>;
  totalItemsCount?: number;
}

export type EventPost = EventPostChatterApi;
export type EventCase = EventCaseChatterApi;

export interface SharedEventsResponse {
  _schemaPath?: 'events/shared/';
  _schemaMethod?: 'get';
  success: boolean,
  totalItemsCount: number,
  result: Array<AOEvent>
}
