// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list-header ::ng-deep ion-label {
  color: var(--mi-secondary-color-1);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
  padding-left: 16px;
}

ion-list {
  padding-top: 0;
}

ion-item {
  height: 88px;
}

app-mi-events-list-item {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
