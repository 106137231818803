import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { isMobilePlatform } from '../utils/utils';
import { contextForClient } from 'src/app/contexts/utils';
import { Platform } from 'src/config/config.model';
import { AuthService } from './auth.service';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import appConfig from 'src/config/config';
import { GlobalSubjects, GlobalSubjectsService } from '../utils/global-subjects.service';
import { OrcIdAuthService } from './orcid/orcid-auth.service';

interface Config {
  clientId: string,
  platform: Platform;
}


export function authenticatedOrcIdPageGuard(): CanActivateFn {

  const config: Config = {
    clientId: appConfig.authentication.clientId,
    platform: appConfig.platform
  };

  const getHomeContext = (contextService: ContextService, authService: AuthService): Promise<string> => {

    let promise;
    if (contextService.isSingleContextApp) {
      return Promise.resolve(contextService.defaultContext.key);
    }
    if (isMobilePlatform(config.platform)) {
      promise = contextService.getLastSavedContextKey();
    } else {
      promise = Promise.resolve(null);
    }
    return promise.then(contextKey => {
      if (contextKey) {
        return contextKey;
      }
      return authService.getProfile(config.clientId)
        .then(profile => {
          return contextForClient(profile.homeDevision);
        });
    });
  };

  return () => {

    const orcIdAuthService: OrcIdAuthService = inject(OrcIdAuthService);
    const router: Router = inject(Router);
    const globalSubjects: GlobalSubjectsService = inject(GlobalSubjectsService);
    const contextService: ContextService = inject(CONTEXT_SERVICE);
    const authService: AuthService = inject(AuthService);

    return orcIdAuthService.isSignedIn().then((isOrcIdLinked: boolean) => {

      if (!isOrcIdLinked) {
        return true;
      }

      return getHomeContext(contextService, authService)
        .then(context => {
          // reset header/footer hiding in case it was hidden
          globalSubjects.emit(GlobalSubjects.HIDE_HEADER_AND_TABS, false);
          return router.createUrlTree([`/${context}`, 'feed']);
        });
    });
  };
}
