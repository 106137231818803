// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --verification-padding-top: 0px;
  --verification-padding-bottom: 16px;
  --verification-padding-start: 16px;
  --verification-padding-end: 16px;
  --verification-content-max-width: 100%;
}

.verification-container {
  width: 100%;
  height: 100%;
  padding-bottom: var(--ion-safe-area-bottom);
  padding-top: var(--ion-safe-area-top);
}
.verification-container app-verification-step-one {
  --verification-step-one-padding-top: var(--verification-padding-top);
  --verification-step-one-padding-bottom: var(--verification-padding-bottom);
  --verification-step-one-padding-start: var(--verification-padding-start);
  --verification-step-one-padding-end: var(--verification-padding-end);
  --content-max-width: var(--verification-content-max-width);
}
.verification-container app-verification-step-two {
  --verification-step-two-padding-top: var(--verification-padding-top);
  --verification-step-two-padding-bottom: var(--verification-padding-bottom);
  --verification-step-two-padding-start: var(--verification-padding-start);
  --verification-step-two-padding-end: var(--verification-padding-end);
  --content-max-width: var(--verification-content-max-width);
}
.verification-container app-verification-step-three {
  --verification-step-three-padding-top: var(--verification-padding-top);
  --verification-step-three-padding-bottom: var(--verification-padding-bottom);
  --verification-step-three-padding-start: var(--verification-padding-start);
  --verification-step-three-padding-end: var(--verification-padding-end);
  --content-max-width: var(--verification-content-max-width);
}
.verification-container app-verification-step-four {
  --verification-step-four-padding-top: var(--verification-padding-top);
  --verification-step-four-padding-bottom: var(--verification-padding-bottom);
  --verification-step-four-padding-start: var(--verification-padding-start);
  --verification-step-four-padding-end: var(--verification-padding-end);
  --content-max-width: var(--verification-content-max-width);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
