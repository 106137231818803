import { Component, Input } from '@angular/core';
import { Connection } from 'src/app/services/yeti-protocol/connections';

import * as moment from 'moment';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-message-card-accepted-request',
  templateUrl: './message-card-accepted-request.component.html',
  styleUrls: ['./message-card-accepted-request.component.scss'],
})
export class MessageCardAcceptedRequestComponent {
  @Input() connection: Connection;
  @Input() userId: string;

  constructor(
    private translationService: AppTranslationService
  ) { }

  get chatInitiator(): string{
    if(this.chatInitiatedByUser){
      return this.translationService.instant('app.components.MessageCardAcceptedRequestComponent.your');
    }else{
      const initiator = this.connection.initiator;
      return `${initiator.firstName} ${initiator.lastName}`;
    }
  }

  get chatPartner(): string{
    if(this.chatAcceptedByUser){
      return this.translationService.instant('app.components.MessageCardAcceptedRequestComponent.you');
    }else{
      const partner = this.connection.partner;
      return `${partner.firstName} ${partner.lastName}`;
    }
  }

  get chatInitiatedByUser(): boolean {
    return this.connection.initiator.userId === this.userId;
  }

  get chatAcceptedByUser(): boolean {
    return this.connection.partner.userId === this.userId;
  }

  get isModificationDateDifferentFromCreation(): boolean {
    if(this.connection){
      if(!this.connection?.modifiedDate){
        return false;
      }
      const createdDate = moment(this.connection.createdDate);
      return !createdDate.isSame(this.connection.modifiedDate, 'day');
    }
    return false;
  }
}
