// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-button-container {
  display: flex;
}
.comment-button-container button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: 1;
}
.comment-button-container button .container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-template-rows: auto;
  -moz-column-gap: 3px;
       column-gap: 3px;
  justify-items: start;
  align-content: center;
  align-items: center;
}
.comment-button-container button .container.label-position-bottom {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 3.5px;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.comment-button-container button .container app-icon {
  --icon-size: 22px;
  color: var(--mi-black-color);
}
.comment-button-container button .container .text {
  margin: 0;
  color: var(--mi-black-color);
  font-size: var(--font-size-tiny);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
