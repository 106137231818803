// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --create-group-case-add-images-section-container-width: 100%;
  --create-group-case-add-images-section-container-height: auto;
  --create-group-case-add-images-section-container-padding-top: 0;
  --create-group-case-add-images-section-container-padding-bottom: 0;
  --create-group-case-add-images-section-container-padding-start: 0;
  --create-group-case-add-images-section-container-padding-end: 0;
  --create-group-case-add-images-section-container-background-color: var(--mi-inputs-color);
  --create-group-case-add-images-section-container-border-radius: 8px;
}

.create-group-case-add-images-section-container {
  width: var(--create-group-case-add-images-section-container-width);
  height: var(--create-group-case-add-images-section-container-height);
  padding-top: var(--create-group-case-add-images-section-container-padding-top);
  padding-bottom: var(--create-group-case-add-images-section-container-padding-bottom);
  padding-left: var(--create-group-case-add-images-section-container-padding-start);
  padding-right: var(--create-group-case-add-images-section-container-padding-end);
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: 100%;
}
.create-group-case-add-images-section-container .section-title {
  grid-row: 1;
  grid-column: 1;
  padding-bottom: 6px;
}
.create-group-case-add-images-section-container .add-images-container {
  grid-row: 2;
  grid-column: 1;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 15px;
  padding-right: 15px;
  display: grid;
  grid-template-rows: repeat(2, min-content);
  row-gap: 11px;
  background-color: var(--create-group-case-add-images-section-container-background-color);
  border-radius: var(--create-group-case-add-images-section-container-border-radius);
}
.create-group-case-add-images-section-container .add-images-container .gallery-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
}
.create-group-case-add-images-section-container .add-images-container .gallery-container .label {
  grid-row: 1;
  grid-column: 1;
  overflow: hidden;
}
.create-group-case-add-images-section-container .add-images-container .gallery-container .label p {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  color: var(--mi-secondary-color-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.create-group-case-add-images-section-container .add-images-container .gallery-container .gallery {
  grid-row: 1;
  grid-column: 2;
  transform: scaleY(0);
  transition: transform 0.25s ease;
}
.create-group-case-add-images-section-container .add-images-container .gallery-container .gallery.show-image-thumbnails {
  transform: scaleY(1);
}
.create-group-case-add-images-section-container .add-images-container .gallery-container .gallery app-image-attachments-container {
  --image-attachments-container-padding-top: 0px;
  --image-attachments-container-padding-bottom: 0px;
  --image-attachments-container-padding-start: 0px;
  --image-attachments-container-padding-end: 0px;
  --image-attachments-container-width: 50px;
  --image-attachments-container-height: 50px;
  --image-attachments-first-image-height: 50px;
}
.create-group-case-add-images-section-container .add-images-container .add-images-button {
  grid-row: 2;
  grid-column: 1;
}
.create-group-case-add-images-section-container .add-caption-container {
  width: 100%;
  height: 100%;
  max-height: 65px;
  margin-top: 6px;
  overflow: hidden;
  transition: max-height 0.25s ease, padding 0.25s ease, margin 0.25s ease;
}
.create-group-case-add-images-section-container .add-caption-container .wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 7px;
       column-gap: 7px;
  align-items: center;
  background-color: var(--create-group-case-add-images-section-container-background-color);
  border-radius: var(--create-group-case-add-images-section-container-border-radius);
  padding: 9px 13px;
}
.create-group-case-add-images-section-container .add-caption-container .wrapper .icon {
  grid-row: 1;
  grid-column: 1;
  transition: transform 0.25s ease;
  transform: scale(1);
}
.create-group-case-add-images-section-container .add-caption-container .wrapper .icon app-icon {
  --icon-size: 20px;
  color: var(--mi-secondary-color-2);
}
.create-group-case-add-images-section-container .add-caption-container .wrapper .add-caption-input {
  grid-row: 1;
  grid-column: 2;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  outline: none;
  border: none;
  background-color: var(--create-group-case-add-images-section-container-background-color);
  transition: transform 0.25s ease;
  transform: translateX(0px);
}
.create-group-case-add-images-section-container .add-caption-container .wrapper .add-caption-input::placeholder {
  color: var(--mi-color-text-lite);
}
.create-group-case-add-images-section-container .add-caption-container.hide-icon .wrapper .icon {
  transform: scale(0);
}
.create-group-case-add-images-section-container .add-caption-container.hide-icon .wrapper .add-caption-input {
  transform: translateX(-20px);
}
.create-group-case-add-images-section-container.hide-caption-input .add-caption-container {
  max-height: 0;
  padding: 0;
  margin-top: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
