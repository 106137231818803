import { Component, Input } from '@angular/core';
import { InfoSheetActionItem } from '../../models/info-sheet-action-item.model';

@Component({
  selector: 'app-info-sheet-actions',
  templateUrl: './info-sheet-actions.component.html',
  styleUrls: ['./info-sheet-actions.component.scss'],
})
export class InfoSheetActionsComponent {
  @Input() actions: Array<InfoSheetActionItem>;
  @Input() infoSheetId: string;
}
