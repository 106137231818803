// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  box-shadow: unset;
}

.profile-card {
  margin: unset;
  border-radius: 0;
  box-shadow: 0 3px 20px var(--mi-shadow-color);
}
.profile-card .profile-image {
  border-radius: 50%;
  position: absolute;
  top: 30px;
  left: 25px;
  border: 4px solid var(--mi-white-color);
  object-fit: fill;
}
.profile-card .profile-card-header .profile-header-image-container {
  grid-row: 1/4;
  grid-column: 1;
  width: 100%;
  height: 76px;
}
.profile-card .profile-card-header .profile-header-image-container .header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-card .profile-card-header .ao-member-symbol-component {
  position: absolute;
  top: 85px;
  left: 49px;
}
.profile-card .icons-container {
  display: grid;
  grid-auto-flow: column;
  -moz-column-gap: 10px;
       column-gap: 10px;
  margin: 5px 15px 0 0;
  float: right;
}
.profile-card .profile-card-content {
  padding-bottom: 0;
  margin-top: 50px;
}
.profile-card .profile-card-content .profile-title {
  font-size: var(--mi-font-size-default);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: var(--font-weight-medium);
  color: var(--mi-black-color);
  line-height: 22px;
  margin: 5px 0;
  text-align: start;
}
.profile-card .profile-card-content .web-bottom-info-button-container {
  flex-direction: column;
}
.profile-card .profile-card-content .bottom-info-button-container {
  display: grid;
  row-gap: 10px;
}
.profile-card .profile-card-content .bottom-info-button-container .user-profile-bubbles-container {
  grid-row: 1;
}
.profile-card .profile-card-content .bottom-info-button-container .btns-container {
  display: grid;
  grid-row-gap: 10px;
}
.profile-card .profile-card-content .bottom-info-button-container .btns-container ion-button {
  display: flex;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--mi-font-size-smaller);
  height: 30px;
  width: 100%;
  --border-radius: 5px;
}
.profile-card .profile-card-content .bottom-info-button-container .btns-container .message-button {
  width: 100%;
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
}
.profile-card .profile-card-content .bottom-info-button-container .btns-container .follow-button {
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
}
.profile-card .profile-card-content .bottom-info-button-container .btns-container .accept-button {
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
}
.profile-card .profile-card-content .bottom-info-button-container .btns-container .pending-button {
  --background: var(--ion-color-dark);
  color: var(--mi-white-color);
}
.profile-card .profile-card-content .bottom-info-button-container .btns-container .ignore-button {
  --border-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary);
}
.profile-card .profile-card-content .bottom-info-button-container .web-icons-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
}
.profile-card .profile-card-content .bottom-info-button-container .web-icons-container .flex {
  display: flex;
}
.profile-card .profile-card-content .bottom-info-button-container .web-icons-container .flex.center {
  justify-content: center;
}
.profile-card .profile-card-content .bottom-info-button-container .web-icons-container .flex.end {
  justify-content: flex-end;
}
.profile-card .profile-card-content .bottom-info-button-container .web-icons-container .flex.align-center {
  align-items: center;
}
.profile-card .profile-card-content .bottom-info-button-container .web-icons-container .actions-btn {
  color: var(--mi-secondary-color-2);
}
.profile-card .profile-card-content .bottom-info-button-container .web-icons-container .actions-btn:hover {
  cursor: pointer;
}
@media all and (max-width: 600px) {
  .profile-card .profile-card-content {
    margin-top: 40px;
  }
}
.profile-card .follower-number-circle {
  background-color: var(--ion-color-secondary);
  position: absolute;
  left: 60px;
  top: 0;
  font-size: var(--mi-font-size-smallest);
  color: var(--mi-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  width: 29px;
  border-radius: 50%;
  border: 1px solid var(--mi-white-color);
  cursor: pointer;
}
.profile-card .follower-number-circle .followers-text {
  position: absolute;
  left: 30px;
  color: var(--mi-color-text-lite);
  bottom: 2px;
}

app-user-profile-image {
  --user-image-size: 79px;
  --ao-icon-size: 30px;
}

.icons-container app-share-button, .web-icons-container app-share-button {
  display: flex;
  align-items: end;
}
.icons-container app-share-button ::ng-deep button .share-button-container app-icon, .web-icons-container app-share-button ::ng-deep button .share-button-container app-icon {
  --icon-size: 18px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
