import {Component, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {FileSelectLimits, FileType, SelectedFile} from '../../../file-select/file-select.model';
import {UploadService, UploadTarget} from '../../../../services/upload.service';
import {AddUrlTimestampPipe} from '../../../../pipes/add-url-timestamp.pipe';
import {FileSelectComponent} from '../../../file-select/components/file-select/file-select.component';
import {UserImageService} from '../../../../services/user-image-service/user-image.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {CONTEXT_SERVICE, ContextService} from '../../../../services/context/context.model';
import {FileSelectScope, FileSelectService} from '../../../file-select/services/file-select.service';

@Component({
  selector: 'app-user-profile-image-upload',
  templateUrl: './user-profile-image-upload.component.html',
  styleUrls: ['./user-profile-image-upload.component.scss'],
})
export class UserProfileImageUploadComponent {

  @ViewChild('fileSelectImage') fileSelectImage: FileSelectComponent;

  @Output() imageUploaded: EventEmitter<void> = new EventEmitter<void>();

  maxImagesCount = 1;
  maxImageFileSizeMb = FileSelectLimits.imageMaxFileSizeMB;
  protected readonly FileSelectScope = FileSelectScope;
  protected readonly FileType = FileType;

  constructor(
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private uploadService: UploadService,
    private userImageService: UserImageService,
    private fileSelectService: FileSelectService
  ) { }

  get defaultImageMimeTypes(): string {
    return this.fileSelectService?.defaultImageMimeTypes.join(',');
  }

  onChangeProfileImageClick(): void {
    this.fileSelectImage.selectFile();
  }
  imagesSelected(imageFiles: Array<SelectedFile>): void {
    const selectedFile = imageFiles?.[0];

    if (!selectedFile) {
      return;
    }

    this.uploadService.upload(UploadTarget.USER_PROFILE, {
      key: 'image',
      file: selectedFile.file,
      mimeType: selectedFile.file.type,
      fileName: selectedFile.file.name
    }).subscribe({
      next: () => {
        const context = this.contextService.currentContext.key;
        this.authService.getProfile(context, true).then((userProfile) => {
          const timeStampPipe = new AddUrlTimestampPipe();
          this.userImageService.updateUserImage(timeStampPipe.transform(userProfile.profileImageUrl));
          this.imageUploaded.emit();
        })
      },
      error: err => {
        console.error(err);
      }
    });
  }
}
