import { Injectable } from '@angular/core';
// TODO: tutorial should be reworked, thats why we never show it for now
// import { isNull, isUndefined } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { AdvertisingStatusService } from './advertising-status.service';
import { AdvertismentField } from './yeti-protocol/auth/mi';

export enum TutorialForFeature {
  CASE_FOLIO = 'caseFolio'
}

export interface TutorialConfig {
  tutorialForFeature: TutorialForFeature
  totalSteps: number
}

export interface TutorialData extends AdvertismentField {
  skipped: boolean,
  finished: boolean,
  currentStep: number,
  manual?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  tutorialConfigs: Map<TutorialForFeature, TutorialConfig> = new Map();
  manualTutorialsData: Map<TutorialForFeature, TutorialData> = new Map();

  private tutorialDataChangedSubject: Subject<void> = new Subject();

  constructor(
    private advertisingStatusService: AdvertisingStatusService,
  ) {
    this.initTutorials();
  }

  initTutorials(): void {
    this.tutorialConfigs.set(TutorialForFeature.CASE_FOLIO, {
      tutorialForFeature: TutorialForFeature.CASE_FOLIO,
      totalSteps: 4
    });
  }

  get tutorialDataChangedObservable(): Observable<void> {
    return this.tutorialDataChangedSubject.asObservable();
  }

  async getTutorialData(tutorialForFeature: TutorialForFeature, forceFetch?: boolean): Promise<TutorialData> {
    try {
      let data = null;

      data = this.getManuallyStartedTutorialData(tutorialForFeature);

      if (data) { // tutorial was started manually and data is kept in this service instance
        return data;
      }

      data = await this.advertisingStatusService.getInfo(tutorialForFeature, forceFetch);
      return data as TutorialData;
    } catch (err) {
      return err;
    }
  }

  getTutorialConfig(tutorialForFeature: TutorialForFeature): TutorialConfig {

    if (!this.tutorialConfigs) {
      return null;
    }

    return this.tutorialConfigs.get(tutorialForFeature);
  }

  async manualStartTutorial(tutorialForFeature: TutorialForFeature): Promise<TutorialData> {

    this.manualTutorialsData.set(tutorialForFeature, null);

    try {
      const tutorialInfo = await this.getTutorialData(tutorialForFeature, true);

      if (!tutorialInfo) {
        await this.markAsSkipped(tutorialForFeature);
      } else {
        if (!tutorialInfo.finished) {
          await this.markAsSkipped(tutorialForFeature);
        }
      }

      this.manualTutorialsData.set(tutorialForFeature, {
        skipped: false,
        finished: false,
        currentStep: null,
        manual: true,
        version: 1,
        id: tutorialForFeature,
        isGeneral: true
      });

      return this.manualTutorialsData.get(tutorialForFeature);

    } catch (err) {
      return null;
    }

  }

  getManuallyStartedTutorialData(tutorialForFeature: TutorialForFeature): TutorialData {

    if (!this.manualTutorialsData) {
      return null;
    }

    return this.manualTutorialsData.get(tutorialForFeature);
  }

  /* eslint-disable */
  checkShouldShowStartDialog(tutorialForFeature: TutorialForFeature): Promise<boolean> {
    /* eslint-enable */
    return Promise.resolve(false);

    // TODO: tutorial should be reworked, thats why we never show it for now

    // return new Promise((resolve, reject) => {

    //   if (this.getManuallyStartedTutorialData(tutorialForFeature)) {
    //     resolve(false);
    //   }

    //   this.getTutorialData(tutorialForFeature, true).then((info: TutorialData) => {
    //     if (!info?.skipped && !info?.finished && (isNull(info?.currentStep) || isUndefined(info?.currentStep))) {
    //       return resolve(true);
    //     }

    //     return resolve(false);
    //   }).catch(err => {
    //     reject(err);
    //   });
    // });
  }

  checkShouldShowEndDialog(tutorialForFeature: TutorialForFeature): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const tutorialConfig = this.getTutorialConfig(tutorialForFeature);

      if (!tutorialConfig) {
        return reject('Config for this tutorial is not found');
      }

      this.getTutorialData(tutorialForFeature).then((info: TutorialData) => {
        if (!info?.skipped && !info?.finished && info?.currentStep === tutorialConfig.totalSteps + 1) {
          return resolve(true);
        }

        return resolve(false);
      }).catch(err => {
        reject(err);
      });
    });
  }

  async markAsSkipped(tutorialForFeature: TutorialForFeature): Promise<void> {

    const data = await this.getTutorialData(tutorialForFeature);

    const tutorialData: TutorialData = {
      version: 1,
      id: tutorialForFeature,
      isGeneral: true,
      skipped: true,
      finished: false,
      currentStep: data?.currentStep || null
    };

    return this.setTutorialData(tutorialForFeature, tutorialData);
  }

  async markAsFinished(tutorialForFeature: TutorialForFeature): Promise<void> {

    const data = await this.getTutorialData(tutorialForFeature);

    const tutorialData: TutorialData = {
      version: 1,
      id: tutorialForFeature,
      isGeneral: true,
      skipped: false,
      finished: true,
      currentStep: data?.currentStep || null
    };

    return this.setTutorialData(tutorialForFeature, tutorialData);
  }

  setCurrentStep(tutorialForFeature: TutorialForFeature, step: number): Promise<void> {

    const tutorialConfig = this.getTutorialConfig(tutorialForFeature);

    if (!tutorialConfig) {
      return Promise.reject('Config for this tutorial is not found');
    }

    if (step > tutorialConfig.totalSteps + 1) {
      return Promise.reject('step is greater than max steps for this tutorial');
    }

    const tutorialData: TutorialData = {
      version: 1,
      id: tutorialForFeature,
      isGeneral: true,
      skipped: false,
      finished: false,
      currentStep: step
    };

    return this.setTutorialData(tutorialForFeature, tutorialData);
  }

  private setTutorialData(tutorialForFeature: TutorialForFeature, tutorialData: TutorialData): Promise<void> {

    const data = this.getManuallyStartedTutorialData(tutorialForFeature);

    if (data) {
      this.manualTutorialsData.set(tutorialForFeature, tutorialData);
      return this.emitTutorialDataChanged();
    }

    return this.advertisingStatusService.setInfo(tutorialData);
  }

  private emitTutorialDataChanged(): Promise<void> {
    this.tutorialDataChangedSubject.next();
    return Promise.resolve();
  }

}
