export const professions = [
  {
    key: 'Surgeon/Physician',
    labelKey: 'surgeonPhysician',
    icon: 'mi-icon-researcher'
  },
  {
    key: 'Resident/Fellow',
    labelKey: 'residentFellow',
    icon: 'mi-icon-researcher'
  },
  {
    key: 'Researcher/University Appointment',
    labelKey: 'researcherUniversityAppointment',
    icon: 'mi-icon-researcher'
  },
  {
    key: 'Medical Student',
    labelKey: 'medicalStudent',
    icon: 'mi-icon-student'
  },
  {
    key: 'Physician Assistant/Nurse Practitioner',
    labelKey: 'physicianAssistantOrNursePractitioner',
    icon: 'mi-icon-physician'
  },
  {
    key: 'Allied Health Professional',
    labelKey: 'alliedHealthProfessional',
    icon: 'mi-icon-physician'
  },
  {
    key: 'Industry',
    labelKey: 'industry',
    icon: 'mi-icon-industry'
  },
  {
    key: 'Other',
    labelKey: 'other',
    icon: 'mi-icon-other'
  }
];
