import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DeepLinksService } from './deep-links.service';
import { PushNotificationsService } from './push-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class DeepLinksProcessorService {
  deepLinkSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private deepLinksService: DeepLinksService,
    @Optional() private pushNotificationsService: PushNotificationsService
  ) { }

  init(): void{
    this.deepLinksService.deepLink.subscribe(path => {
      this.processDeepLink(path);
    });
    this.pushNotificationsService?.pushNotification.subscribe(path => {
      this.processDeepLink(path);
    });
    this.deepLinksService.init();
    this.pushNotificationsService?.init();
  }

  get deepLink(): Observable<string> {
    return this.deepLinkSubject.asObservable();
  }

  processDeepLink(path: string): void {
    console.log(`processing deep link: ${path}`);
    if(path){
      this.deepLinkSubject.next(path);
    }
  }

  set enabled(value: boolean) {
    this.deepLinksService.enabled = value;
    if(this.pushNotificationsService){
      this.pushNotificationsService.enabled = true;
    }
  }
}
