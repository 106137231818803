import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GroupMember } from 'src/app/services/groups/group.model';

@Component({
  selector: 'app-group-member-row-item',
  templateUrl: './group-member-row-item.component.html',
  styleUrls: ['./group-member-row-item.component.scss'],
})
export class GroupMemberRowItemComponent {

  @Input() groupMember: GroupMember;
  @Input() groupOwnerOrModerator: boolean;
  @Input() isFollower: boolean;
  @Input() groupOwnerId: string;
  @Input() disableProfileOpen = false;
  @Input() showModeratorIcon = false;
  @Input() showCrown = false;
  @Input() showSettings = true;
  @Input() showFollow = false;
  @Output() settingsButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() profileImageClicked: EventEmitter<string> = new EventEmitter();
  @Output() followButtonClicked: EventEmitter<void> = new EventEmitter();

  clickSettings(): void {
    this.settingsButtonClicked.emit();
  }

  onProfileClicked(userId: string): void {
    this.profileImageClicked.emit(userId);
  }

  get isGroupOwner(): boolean {
    return this.groupOwnerId === this.groupMember?.userId;
  }
}
