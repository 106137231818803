import { Inject, Injectable } from '@angular/core';

// pipes
import { AppTranslationService } from 'src/app/services/app-translation.service';

// models
import { AttachmentMimeType, ImageAttachment } from 'src/app/services/attachments.model';

// services
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCompressService } from './image-compress.service';
import { SelectedFile } from '../file-select.model';
import { addUnitToFileSize } from 'src/app/services/utils/string-utils';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { ActionTracked, TrackingRequest, UploadMediaTrackingParam } from 'src/app/services/yeti-protocol/tracking';
import appConfig from 'src/config/config';
import { Platform } from 'src/config/config.model';

export enum FileSelectScope {
  CASE = 'case',
  CHAT = 'chat',
  POST = 'post',
  VERIFICATION = 'verification',
  PROFILE_IMAGE = 'profileImage',
  GROUP = 'group',
  COMMENT = 'comment',
  NA = 'NA'
}

@Injectable({
  providedIn: 'root'
})
export class FileSelectService {

  defaultImageMimeTypes = [AttachmentMimeType.JPEG, AttachmentMimeType.JPG, AttachmentMimeType.PNG];

  constructor(
    private appTranslationService: AppTranslationService,
    private sanitizer: DomSanitizer,
    private imageCompressService: ImageCompressService,
    private dialogs: DialogsUIService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  async checkMaxFileCountExceeded(
    selectedFiles: Array<any>,
    maxCount: number,
    scope: FileSelectScope,
    currentMaxCount?: number): Promise<Array<any>> {

    if (!maxCount && maxCount !== 0) {
      return selectedFiles;
    }

    const checkMaxCount = currentMaxCount > 0 || currentMaxCount === 0 ? currentMaxCount : maxCount;

    if (!selectedFiles?.length || selectedFiles?.length <= checkMaxCount) {
      return selectedFiles;
    } else {
      const title = await this.appTranslationService.get('app.FileSelect.max-files-count-exceeded-warning-title');

      const textPart1 = await this.appTranslationService.get('app.FileSelect.max-files-count-exceeded-warning-text-part-1');
      const textPart2 = await this.appTranslationService.get('app.FileSelect.max-files-count-exceeded-warning-text-part-2');
      const text = `${textPart1} ${maxCount} ${textPart2}`;

      const buttonText = await this.appTranslationService.get('app.FileSelect.max-files-count-exceeded-warning-button-text');

      const filesThatExceedMaxCount = [];

      for (let i = checkMaxCount; i < selectedFiles.length; ++i) {
        filesThatExceedMaxCount.push(selectedFiles[i]);
      }

      const fileName = this.getTrackingParamFileName(filesThatExceedMaxCount);
      const fileMimeType = this.getTrackingParamFileMimeType(filesThatExceedMaxCount);
      this.trackUploadLimitationsFail(fileName, fileMimeType, scope, 'Files max count exceeded');

      try {
        await this.dialogs.presentAlert(title, text, [buttonText]);
        return selectedFiles.slice(0, checkMaxCount);
      } catch (err) {
        return selectedFiles.slice(0, checkMaxCount);
      }
    }
  }

  async checkMultipleSelectionMaxCountExceeded(
    selectedFiles: Array<any>,
    multipleSelectionMaxCount: number,
    scope: FileSelectScope): Promise<Array<any>> {

    if (!multipleSelectionMaxCount && multipleSelectionMaxCount !== 0) {
      return selectedFiles;
    }

    if (!selectedFiles?.length || selectedFiles?.length <= multipleSelectionMaxCount) {
      return selectedFiles;
    } else {
      const title = await this.appTranslationService.get('app.FileSelect.multiple-selection-max-files-count-exceeded-warning-title');

      const textPart1 = await
        this.appTranslationService.get('app.FileSelect.multiple-selection-max-files-count-exceeded-warning-text-part-1');
      const textPart2 =
        await this.appTranslationService.get('app.FileSelect.multiple-selection-max-files-count-exceeded-warning-text-part-2');
      const text = `${textPart1} ${multipleSelectionMaxCount} ${textPart2}`;

      const buttonText =
        await this.appTranslationService.get('app.FileSelect.multiple-selection-max-files-count-exceeded-warning-button-text');

      const filesThatExceedMultipleSelectionMaxCount = [];

      for (let i = multipleSelectionMaxCount; i < selectedFiles.length; ++i) {
        filesThatExceedMultipleSelectionMaxCount.push(selectedFiles[i]);
      }

      const fileName = this.getTrackingParamFileName(filesThatExceedMultipleSelectionMaxCount);
      const fileMimeType = this.getTrackingParamFileMimeType(filesThatExceedMultipleSelectionMaxCount);
      this.trackUploadLimitationsFail(fileName, fileMimeType, scope, 'Files multiple selection max count exceeded');

      try {
        await this.dialogs.presentAlert(title, text, [buttonText]);
        return selectedFiles.slice(0, multipleSelectionMaxCount);
      } catch (err) {
        return selectedFiles.slice(0, multipleSelectionMaxCount);
      }
    }
  }

  async checkMaxFileSizeExceeded(
    selectedFiles: Array<SelectedFile>,
    maxFileSizeMb: number,
    scope: FileSelectScope): Promise<Array<SelectedFile>> {

    let biggerFileThenLimitSize;

    if (!maxFileSizeMb && maxFileSizeMb !== 0) {
      return selectedFiles;
    }

    let fileWithGreaterSizeFound = false;
    const maxFileSizeKb = maxFileSizeMb * 1000;
    const filesThatExceedMaxSize = [];

    selectedFiles = selectedFiles.filter((selectedFile: SelectedFile) => {
      if (selectedFile?.file?.size / 1000 > maxFileSizeKb) {
        fileWithGreaterSizeFound = true;
        filesThatExceedMaxSize.push(selectedFile);
        biggerFileThenLimitSize = selectedFile?.file?.size;
        return false;
      } else {
        return true;
      }
    });

    if (fileWithGreaterSizeFound) {

      const fileSize = addUnitToFileSize(biggerFileThenLimitSize);

      const title = `${await this.appTranslationService.get('app.FileSelect.max-file-size-exceeded-warning-title')} (${fileSize})`;
      const text = `${await this.appTranslationService.get('app.FileSelect.max-file-size-exceeded-warning-text')} ${maxFileSizeMb} MB.`;
      const buttonText = await this.appTranslationService.get('app.FileSelect.max-file-size-exceeded-warning-button-text');

      const fileName = this.getTrackingParamFileName(filesThatExceedMaxSize);
      const fileMimeType = this.getTrackingParamFileMimeType(filesThatExceedMaxSize);
      this.trackUploadLimitationsFail(fileName, fileMimeType, scope, 'Files max size exceeded');

      try {
        await this.dialogs.presentAlert(title, text, [buttonText]);
      } catch (err) {
        console.error(err);
      }
    }

    return selectedFiles;
  }

  async checkImageMinDimensions(
    selectedFiles: Array<SelectedFile>,
    minWidth: number,
    minHeight: number,
    scope: FileSelectScope): Promise<Array<SelectedFile>> {

    if (!minWidth || !minHeight) {
      return selectedFiles;
    }

    try {
      selectedFiles = await this.setImagesDimensionProperties(selectedFiles, scope);
    } catch (err) {
      return [];
    }

    if (!selectedFiles) {
      return [];
    }

    let fileWithSmallerDimensionsFound = false;
    const filesWithSmallDimensions = [];

    selectedFiles = selectedFiles.filter((selectedFile: SelectedFile) => {
      if (this._hasSmallDimentions(selectedFile, minWidth, minHeight)) {
        fileWithSmallerDimensionsFound = true;
        filesWithSmallDimensions.push(selectedFile);
      } else {
        return selectedFile;
      }
    });

    if (fileWithSmallerDimensionsFound) {

      const fileName = this.getTrackingParamFileName(filesWithSmallDimensions);
      const fileMimeType = this.getTrackingParamFileMimeType(filesWithSmallDimensions);
      this.trackUploadLimitationsFail(fileName, fileMimeType, scope, 'Files with smaller dimensions than minimal');

      await this._showSmallDimensionsAlert(minWidth, minHeight);
    }
    return selectedFiles;
  }

  _hasSmallDimentions(selectedFile: SelectedFile, minWidth: number, minHeight: number): boolean {
    let widthIsSmaller = false;
    let heightIsSmaller = false;

    if (minWidth && selectedFile?.width && minWidth > selectedFile.width) {
      widthIsSmaller = true;
    }

    if (minHeight && selectedFile?.height && minHeight > selectedFile.height) {
      heightIsSmaller = true;
    }

    return widthIsSmaller || heightIsSmaller;
  }

  async _showSmallDimensionsAlert(minWidth: number, minHeight: number): Promise<void> {
    const title = `${await this.appTranslationService.get('app.FileSelect.image-dimensions-smaller-warning-title')}`;
    const text1 =
      `${await this.appTranslationService.get('app.FileSelect.image-dimensions-smaller-text-part-1')} ${minWidth}x${minHeight}`;
    const text2 = await this.appTranslationService.get('app.FileSelect.image-dimensions-smaller-text-part-2');
    const text = `${text1} ${text2}`;
    const buttonText = await this.appTranslationService.get('app.FileSelect.image-dimensions-smaller-button-text');

    try {
      await this.dialogs.presentAlert(title, text, [buttonText]);
    } catch (err) {
      console.error(err);
    }
  }

  setImagesDimensionProperties(selectedFiles: Array<SelectedFile>, scope: FileSelectScope): Promise<Array<SelectedFile>> {

    const selectedFilesWithDimensions: Array<SelectedFile> = [];

    if (!selectedFiles?.length) {
      return Promise.resolve([]);
    }

    const selectedFilesObject = {};

    return new Promise((resolve, reject) => {

      selectedFiles.forEach(selectedImg => {

        const img = new Image();
        const _URL = window.URL || window.webkitURL;
        const objectUrl = _URL?.createObjectURL(selectedImg.file);

        img.onload = (event: Event) => {

          const loadedImg = event.target;
          const selectedImgWithDimensions = selectedFilesObject[(loadedImg as any)?.title];

          if (selectedImgWithDimensions) {
            selectedImgWithDimensions.width = (img as any)?.width;
            selectedImgWithDimensions.height = (img as any)?.height;
            selectedFilesWithDimensions.push(selectedImgWithDimensions);

            _URL?.revokeObjectURL(objectUrl);

            if (selectedFilesWithDimensions.length === selectedFiles.length) {
              return resolve(selectedFilesWithDimensions);
            }

          } else {
            return reject(null);
          }
        }

        img.onerror = () => {
          const fileName = this.getTrackingParamFileName([selectedImg]);
          const fileMimeType = this.getTrackingParamFileMimeType([selectedImg]);
          this.trackUploadLimitationsFail(fileName, fileMimeType, scope, 'Error detecting image width and height');
          console.error('error detecting image width and height');
        }

        img.src = objectUrl;
        img.title = selectedImg?.file?.name || selectedImg?.fileName;
        selectedFilesObject[img.title] = selectedImg;
      })
    });
  }

  selectedFileToImageAttachmentTypeConversion(selectedFiles: Array<SelectedFile>): Array<ImageAttachment> {
    return selectedFiles.map((selectedFile: SelectedFile) => {
      return {
        file: selectedFile.file,
        fullUrl: selectedFile.url,
        previewUrl: selectedFile.url,
        fileName: selectedFile.fileName,
      } as ImageAttachment;
    })
  }

  imageAttachmentToSelectedFileTypeConversion(imageAttachments: Array<ImageAttachment>): Array<SelectedFile> {
    return imageAttachments.map((image: ImageAttachment) => {

      const safeUrl = appConfig.platform === Platform.ANDROID ?
        this.sanitizer.bypassSecurityTrustUrl(image?.fullUrl) : null;

      return {
        file: image?.file,
        url: image?.fullUrl,
        safeUrl: safeUrl,
        fileName: image?.fileName
      }
    })
  }

  async compressImageFile(image: SelectedFile, maxFileSizeMb: number): Promise<SelectedFile> {

    // prevent gif images to be compressed because they get broken
    if (image?.file?.type === AttachmentMimeType.GIF) {
      return image;
    }

    const compressedImage = await this.imageCompressService.compressImage(image.file, maxFileSizeMb);

    if (!compressedImage) {
      console.error('Error while compressing file');
      return;
    }

    // compressed file must be read again
    // now file is normal file and can be read as it is uploaded from web
    const compressedFiles = await this.readFiles([compressedImage]);

    if (!compressedFiles || compressedFiles?.length < 1) {
      console.error('Error while reading compressed file');
      return;
    }

    image.file = compressedImage;
    image.url = compressedFiles[0].url;

    return image;
  }

  async compressImageFiles(imageFiles: Array<SelectedFile>, maxFileSizeMb: number): Promise<Array<SelectedFile>> {
    const compressedFiles: Array<SelectedFile> = [];
    if (imageFiles.length > 0) {
      for (const imageFile of imageFiles) {
        try {
          const compressedImageFile = await this.compressImageFile(imageFile, maxFileSizeMb);
          if (compressedImageFile) {
            compressedFiles.push(compressedImageFile);
          } else {
            compressedFiles.push(imageFile);
          }
        } catch (err) {
          console.log(err);
          compressedFiles.push(imageFile);
        }
      }
    }
    return compressedFiles;
  }

  async readFiles(files: Array<File>): Promise<Array<SelectedFile>> {

    if (!files || files?.length < 1) {
      return;
    }

    const promises = files.map(async file => {
      try {
        return await this.readFile(file);
      } catch (err) {
        console.error(err);
        return { file };
      }
    });

    return Promise.all(promises);
  }

  readFile(file: File): Promise<SelectedFile> {

    const selectedFile: SelectedFile = {
      file: file
    };

    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      reader.onload = e => {
        selectedFile.url = e.target.result as string;
        resolve(selectedFile);
      };

      reader.onerror = (err) => {
        reject(err);
      };
    });
  }

  sortFiles(files: Array<SelectedFile>): { imageFiles: Array<SelectedFile>, otherFiles: Array<SelectedFile> } {
    const res: { imageFiles: Array<SelectedFile>, otherFiles: Array<SelectedFile> } = {
      imageFiles: [],
      otherFiles: []
    };

    files.forEach((selectedFile: SelectedFile) => {
      if (this.uiUtilsService.isImageFile(selectedFile)) {
        res.imageFiles.push(selectedFile);
      } else {
        res.otherFiles.push(selectedFile);
      }
    });
    return res;
  }

  trackUploadLimitationsFail(fileName: string, mimeType: string, scope: FileSelectScope, reason: string): Promise<void> {
    const paramsToTrack: UploadMediaTrackingParam = {
      objectId: fileName,
      objectType: mimeType,
      scope: scope,
      uploadFailed: true, // dont think we need this ... since we send this only if upload fails
      reason: reason
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.uploaded,
      params: paramsToTrack
    };

    return this.trackingService.track(trackData).catch(_err => {
      console.error('Something went wrong on upload action: ' + _err);
    });
  }

  private getTrackingParamFileName(selectedFiles: Array<SelectedFile>): string {

    if (!selectedFiles || !selectedFiles?.length) {
      return '';
    }

    const fileNames: Array<string> = [];
    let fileName = '';

    selectedFiles.forEach((selectedFile: SelectedFile) => {
      fileNames.push(selectedFile.file?.name || selectedFile?.fileName || '');
    });

    fileName = fileNames.length > 1 ? fileNames.join(',') : fileNames[0];
    return fileName;
  }

  private getTrackingParamFileMimeType(selectedFiles: Array<SelectedFile>): string {

    if (!selectedFiles || !selectedFiles?.length) {
      return '';
    }

    const fileMimeTypes: Array<string> = [];
    let fileMimeType = '';

    selectedFiles.forEach((selectedFile: SelectedFile) => {
      fileMimeTypes.push(selectedFile?.file?.type);
    });

    fileMimeType = fileMimeTypes.length > 1 ? fileMimeTypes.join(',') : fileMimeTypes[0];
    return fileMimeType;
  }
}
