import { Injectable } from '@angular/core';

export interface QueryParams {
  [prop: string]: string
}

export interface URL {
  protocol: string,
  host: string,
  port: string,
  path: string,
  query: QueryParams,
  hash: string
}

@Injectable({
  providedIn: 'root'
})
export class UrlUtilsService {

  parse(url: string): URL {
    if(url === null || url === undefined){
      throw new Error('cannot parse null or undefined string');
    }
    const parser = document.createElement('a');
    parser.href = url; // Let the browser do the work
    return {
        protocol: parser.protocol,
        host: parser.host,
        port: parser.port,
        path: parser.pathname,
        query: this._parseQueryString(parser.search),
        hash: this._stripFirstChar(parser.hash, '#')
    };
  }

  toString(parsedUrl: URL): string{
    if(parsedUrl === null || parsedUrl === undefined){
      throw new Error('parsed url is not provided');
    }
    let url = `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.path}`;
    if(parsedUrl.hash){
        url = [url, parsedUrl.hash].join('#');
    }
    const query = this.object2queryString(parsedUrl.query);
    if(query){
        url = [url, query].join('?');
    }
    return url;
  }

  object2queryString(obj: QueryParams): string {
    return Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&');
  }

  object2formString(obj: QueryParams): string {
    const formData = new URLSearchParams();
    for( const key in obj ) {
      if(obj.hasOwnProperty(key)){
        formData.set(key, obj[key]);
      }
    }
    return formData.toString();
  }

  _parseQueryString(queryString: string): QueryParams {
    queryString = this._stripFirstChar(queryString, '?');
    if(!queryString){
        return {} as QueryParams;
    }
    const obj: any = {};
    const pairs = queryString.split('&');
    pairs.forEach(pair => {
      const split = pair.split('=');
      obj[decodeURIComponent(split[0])] = decodeURIComponent(split.slice(1).join('='));
    });
    return obj;
  }

  _stripFirstChar(value: string, char: string): string {
    if(value && value.startsWith(char)){
      return value.substring(char.length);
    }
    return value;
  }

  stripLastChar(value: string, char: string): string {
    if(value && value.endsWith(char)){
      return value.substring(0, value.length - char.length);
    }
    return value;
  }

  getQueryParamValue(url: string, param: string): string | null {
    const queryString = url.split('?')[1];
    if (!queryString) return null;

    const queryParams = new URLSearchParams(queryString);
    return queryParams.get(param);
  }
}
