import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonContent, IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-case-library-filter-selector',
  templateUrl: './case-library-filter-selector.component.html',
  styleUrls: ['./case-library-filter-selector.component.scss'],
})
export class CaseLibraryFilterSelectorComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild('ionContent') ionContent: IonContent;

  @Input() filters: Array<string>;
  @Input() selectedFilter: string;

  count = 20;
  _filters: Array<string> = [];

  @Output() selectedFilterChanged: EventEmitter<string> = new EventEmitter();

  filterByNameTerm: string;

  ngOnInit(): void {
    this.loadMoreFilters();
  }

  get filtersFilteredByName(): Array<string> {

    if (!this.filters?.length) {
      return [];
    }

    if (!this.filterByNameTerm?.length) {
      return this.filters || [];
    }

    const regex = new RegExp(this.filterByNameTerm, 'i');
    return this.filters.filter(filter => regex.test(filter));
  }

  nameTermChanged(): void {
    this._filters = [];
    this.ionContent?.scrollToTop();
    this.loadMoreFilters();
  }

  loadMoreFilters(): void {

    const filters = [...(this.filtersFilteredByName || [])];

    if (!this._filters?.length) {
      this._filters = [...filters?.slice(0, this.count)];
    } else {

      const start = this._filters?.length;
      let end = start + this.count;

      if (end > this.filtersFilteredByName?.length) {
        end = this.filtersFilteredByName?.length;
      }

      this._filters = [...this._filters, ...filters?.slice(start, end)];
    }

    this.infiniteScroll?.complete();
  }

  get canLoadMore(): boolean {
    return this._filters?.length < this.filtersFilteredByName?.length;
  }

  selectFilter(filter: string): void {

    if (!filter?.length) {
      this.selectedFilterChanged.emit('');
      return;
    }

    if (this.selectedFilter?.length > 0 &&
      this.selectedFilter === filter) {
      this.selectedFilter = '';
      this.selectedFilterChanged.emit('');
      return;
    }

    this.selectedFilter = filter;
    this.selectedFilterChanged.emit(this.selectedFilter);
  }

}
