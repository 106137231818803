// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-content-parent-select-container .selection-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.create-content-parent-select-container .selection-container .user-image-container {
  pointer-events: none;
}
.create-content-parent-select-container .selection-container .user-image-container app-user-profile-image {
  --user-image-size: 40px;
}
.create-content-parent-select-container .selection-container .text-and-icon-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.create-content-parent-select-container .selection-container .text-and-icon-wrapper.selected {
  background-color: var(--mi-blue-color-4);
  border-radius: 8px;
  padding: 6px 10px;
}
.create-content-parent-select-container .selection-container .text-and-icon-wrapper .text-container {
  cursor: pointer;
}
.create-content-parent-select-container .selection-container .text-and-icon-wrapper .text-container .text {
  width: 100%;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-primary-color);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.create-content-parent-select-container .selection-container .text-and-icon-wrapper .icon-container {
  cursor: pointer;
}
.create-content-parent-select-container .selection-container .text-and-icon-wrapper .icon-container app-icon {
  color: var(--mi-primary-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
