import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

import {
  CoContributorsParams,
  CoContributorsResponse,
  Connection,
  ConnectionResponse,
  ConnectionsRequestParams,
  ConnectionsResponse,
  ConnectionUpdateResponse,
  ConversationsRequestParams,
  PendingConnectionsParams,
  RecentlyConnectedWithParams,
  RecentlyConnectedWithResponse,
  // DeleteConnectionResponse,
  RequestConnectionResponse
} from '../yeti-protocol/connections';
import { VerificationStatus } from '../verification.model';
import { ConnectionStatus } from '../yeti-protocol/connection';
import { Conversation, ConversationResponse, ConversationsResponse } from '../yeti-protocol/conversation';

import { AuthService } from '../auth/auth.service';
import { SchemaValidatorService } from '../schema-validator.service';
import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { VerificationService } from '../verification.service';
import { AuthRequestParams } from '../auth/logic/auth-logic.service.interface';
import { ErrorResponse } from '../yeti-protocol/error';
import appConfig from 'src/config/config';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';

export interface ConnectionsApiServiceConfig {
  serverUrl: string;
}

export interface ConnectionsData {
  connections: Array<Connection>;
  total: number;
}

export interface ConversationsData {
  conversations: Array<Conversation>;
  total: number;
  totalNotRead: number;
  totalOpenConnectionRequests: number;
}

export enum ConnectionType {
  IncomingConnectionRequest,
  SentConnectionRequest,
  AllConnectionRequests
}

export const activeConversationsPerPage = 10;

@Injectable({
  providedIn: 'root'
})
export class ConnectionsApiService {
  config: ConnectionsApiServiceConfig = {
    serverUrl: `${appConfig.chatterUrl}messenger/connection`
  }
  constructor(
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private schemaValidator: SchemaValidatorService,
    private verificationService: VerificationService
  ) { }

  getConnection(connectionId: string): Promise<Connection> {
    const url = `${this.config.serverUrl}/byId/${connectionId}`;
    return firstValueFrom(this.authService.secureGet(url, {
      params: toAuthRequestParams({
        appId: this.contextService.currentContext.key
      })
    }).pipe(
      this.schemaValidator.isValidOperator<ConnectionResponse>('ConnectionResponse'),
      map(res => {
        if ('result' in res) {
          return res?.result;
        }
      })
    ));
  }

  public getConnections(start = 0, count = 9): Promise<Array<Connection>> {
    return this.authService.asserIsSignedIn().then(() => {

      const params = {
        appId: this.contextService.currentContext.key,
        start,
        count
      } as ConnectionsRequestParams;

      return firstValueFrom(this.authService.secureGet(this.config.serverUrl, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<ConnectionsResponse>('ConnectionsResponse'),
        map(res => {
          if ('result' in res) {
            return res.result;
          }
          return [];
        })
      ));
    });
  }

  // Default it returns connections with status = connected
  public getConnectionsFilteredByStatus(status: ConnectionStatus = 'connected', start = 0, count = 9): Promise<ConnectionsData> {

    return this.authService.asserIsSignedIn().then(() => {
      const params = {
        appId: this.contextService.currentContext.key, // TODO: remove: added by auth service
        start,
        count
      } as ConnectionsRequestParams;
      const url = `${this.config.serverUrl}/${status}`;
      return this._getConnections(url, toAuthRequestParams(params));
    });
  }


  getPendingConnections(connectionType: ConnectionType, start = 0, count = 9): Promise<ConnectionsResponse> {
    return this.authService.asserIsSignedIn().then(async () => {

      let currentContext = '';

      try {
        currentContext = (await this.contextService.getCurrentContext())?.key;
      } catch (err) {
        currentContext = this.contextService.currentContext.key;
      } finally {
        if (!currentContext) {
          currentContext = this.contextService.currentContext.key;
        }
      }

      const params = {
        appId: currentContext,
        start,
        count
      } as PendingConnectionsParams;

      if (connectionType === ConnectionType.IncomingConnectionRequest) {
        params.initiated = false;
      } else if (connectionType === ConnectionType.SentConnectionRequest) {
        params.initiated = true;
      }

      const url = `${this.config.serverUrl}/pending`;

      return firstValueFrom(this.authService.secureGet(url, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<ConnectionsResponse>('ConnectionsResponse')
      ));
    });
  }

  _getConnections(url: string, params: AuthRequestParams): Promise<ConnectionsData> {
    return firstValueFrom(this.authService.secureGet(url, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<ConnectionsResponse>('ConnectionsResponse'),
      map(res => {
        if ('result' in res && 'totalItemsCount' in res) {
          return {
            connections: res.result,
            total: res.totalItemsCount
          };
        }
        return null;
      })
    ));
  }

  getActiveConversation(conversationId: string): Promise<Conversation> {
    return this.authService.asserIsSignedIn().then(() => {

      const params = {
        appId: this.contextService.currentContext.key,
      };
      const url = `${this.config.serverUrl}/activities/${conversationId}`;

      return firstValueFrom(this.authService.secureGet(url, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<ConversationResponse>('ConversationResponse'),
        map(res => {
          return this._mapResult<ConversationResponse, Conversation>(res);
        })
      ));
    });
  }

  _mapResult<T extends ({ result?: S } | ErrorResponse), S>(res: T): S {
    if ('result' in res) {
      return (res as any).result; // type conversion to make compiler happy
    }
    return null;
  }

  async getActiveConversations(start = 0, count = activeConversationsPerPage): Promise<ConversationsResponse> {

    let currentContext = '';

    try {
      currentContext = (await this.contextService.getCurrentContext())?.key;
    } catch (err) {
      currentContext = this.contextService.currentContext.key;
    } finally {
      if (!currentContext) {
        currentContext = this.contextService.currentContext.key;
      }
    }

    return this.authService.asserIsSignedIn().then(() => {
      const params = {
        appId: currentContext,
        start,
        count
      } as ConversationsRequestParams;
      const url = `${this.config.serverUrl}/activities`;
      return firstValueFrom(this.authService.secureGet(url, { params: toAuthRequestParams(params) }).pipe(
        this.schemaValidator.isValidOperator<ConversationsResponse>('ConversationsResponse')
      ));
    });
  }

  async requestConnection(withUserId: string, message: string, source?: string): Promise<Connection> {
    const userVerificationStatus = await this.verificationService.verify();

    if (userVerificationStatus !== VerificationStatus.VERIFIED) {
      return Promise.reject('User is not verified');
    }

    const requestParams: any = {
      userId: withUserId,
      message
    };

    if (source) {
      requestParams.source = source;
    }

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(this.authService.securePost(this.config.serverUrl, toAuthRequestParams(requestParams)).pipe(
        this.schemaValidator.isValidOperator<RequestConnectionResponse>('RequestConnectionResponse'),
        map(res => {
          return this._mapResult<RequestConnectionResponse, Connection>(res);
        })
      ));
    });
  }

  updateConnection(connectionId: string, status: ConnectionStatus): Promise<Connection> {
    return this.authService.asserIsSignedIn().then(() => {

      const url = `${this.config.serverUrl}/${connectionId}/${status}`;

      return firstValueFrom(this.authService.securePut(url, {}).pipe(
        this.schemaValidator.isValidOperator<ConnectionUpdateResponse>('ConnectionUpdateResponse'),
        map(res => {
          return this._mapResult<ConnectionUpdateResponse, Connection>(res);
        })
      ));
    });
  }

  resendPendingConnection(connectionId: string): Promise<Connection> {
    return this.authService.asserIsSignedIn().then(() => {
      const url = `${this.config.serverUrl}/${connectionId}`;
      const requestParams = {
        appId: this.contextService.currentContext.key
      }

      return firstValueFrom(this.authService.securePut(url, {}, { params: toAuthRequestParams(requestParams) }).pipe(
        this.schemaValidator.isValidOperator<ConnectionUpdateResponse>('ConnectionUpdateResponse'),
        map(res => {
          return this._mapResult<ConnectionUpdateResponse, Connection>(res);
        })
      ));
    });
  }

  getRecentlyConnectedWith(start: number = 0, count: number = 9): Promise<RecentlyConnectedWithResponse> {

    const url = `${appConfig.chatterUrl}messenger/recentlyconnectedwith`;

    const params: RecentlyConnectedWithParams = {
      appId: this.contextService.currentContext.key,
      start: start,
      count: count
    }

    return firstValueFrom(
      this.authService.secureGet(url, {
        params: toAuthRequestParams(params)
      }).pipe(
        this.schemaValidator.isValidOperator<RecentlyConnectedWithResponse>('RecentlyConnectedWithResponse')
      )
    );
  }

  getCoContributors(start: number = 0, count: number = 20): Promise<CoContributorsResponse> {

    const url = `${this.config.serverUrl}/coContributors`;

    const params: CoContributorsParams = {
      appId: this.contextService.currentContext.key,
      start: start,
      count: count
    }

    return firstValueFrom(this.authService.secureGet(url, {
      params: toAuthRequestParams(params)
    }).pipe(
      this.schemaValidator.isValidOperator<CoContributorsResponse>('CoContributorsResponse')
    ));
  }

  bulkConnectToAllCoContributors(): Promise<any> {
    const url = `${this.config.serverUrl}/coContributors`;
    return firstValueFrom(this.authService.securePost(url, {}));
  }

}
