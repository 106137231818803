import { ContextRequest } from './context-request';
import { ErrorResponse } from './error';

export enum CaseFruitSaladaSuggestionType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export type CaseFruitSaladSuggestionsResponse = CaseFruitSaladSuggestionsSuccessResponse | ErrorResponse;

export interface CaseFruitSaladSuggestionsRequestParams extends ContextRequest {
  type: CaseFruitSaladaSuggestionType;
  term: string;
  primary?: string;
}

export interface CaseFruitSaladSuggestionsSuccessResponse {
  _schemaPath?: 'chatter/groups/posts';
  _schemaMethod?: 'get';
  result: Array<string>;
}

export type CaseFruitSaladKeywordsSuggestionsResponse = CaseFruitSaladKeywordsSuggestionsSuccessResponse | ErrorResponse;

export interface CaseFruitSaladKeywordsSuggestionsRequestParams extends ContextRequest {
  filter: string;
}

export interface CaseFruitSaladKeywordsSuggestionsSuccessResponse {
  _schemaPath?: 'chatter/cases/tags';
  _schemaMethod?: 'get';
  result: Array<string>;
}
