// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#close-dialog {
  --background: var(--mi-primary-color);
  width: 280px;
  height: 30px;
  margin-top: 10px;
  text-transform: none;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
}

ion-content .error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
ion-content .error-wrapper .error-message {
  text-align: center;
  color: var(--mi-color-text-lite);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
