// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --dedicated-search-people-padding-top: 0px;
  --dedicated-search-people-padding-bottom: 0px;
  --dedicated-search-people-padding-start: 18px;
  --dedicated-search-people-padding-end: 18px;
}

.dedicated-search-people-container {
  width: 100%;
  height: 100%;
}
.dedicated-search-people-container .content-container {
  width: 100%;
  height: 100%;
}
.dedicated-search-people-container .content-container .content {
  width: 100%;
  height: 100%;
}
.dedicated-search-people-container .content-container .content .start-state-container {
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dedicated-search-people-container .content-container .content .start-state-container .start-state {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 15px;
  justify-content: center;
  align-items: center;
  max-width: 285px;
}
.dedicated-search-people-container .content-container .content .start-state-container .start-state .icon-container {
  grid-row: 1;
  grid-column: 1;
}
.dedicated-search-people-container .content-container .content .start-state-container .start-state .icon-container app-icon {
  --icon-size: 80px;
}
.dedicated-search-people-container .content-container .content .start-state-container .start-state .text-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
}
.dedicated-search-people-container .content-container .content .start-state-container .start-state .text-container p {
  margin: 0;
  text-align: center;
}
.dedicated-search-people-container .content-container .content .start-state-container .start-state .text-container .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  color: var(--mi-black-color-2);
}
.dedicated-search-people-container .content-container .content .start-state-container .start-state .text-container .subtitle {
  font-size: var(--font-size-extra-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.dedicated-search-people-container .content-container .content .list-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 27px;
}
.dedicated-search-people-container .content-container .content .list-container .container-title {
  padding-left: var(--dedicated-search-people-padding-start);
  padding-right: var(--dedicated-search-people-padding-end);
  padding-top: 10px;
}
.dedicated-search-people-container .content-container .content .list-container .container-title .title {
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
  font-size: var(--font-size-large);
  color: var(--mi-secondary-color-1);
  margin: 0;
}
.dedicated-search-people-container .content-container .content .list-container:first-child .container-title {
  padding-top: 0;
}
.dedicated-search-people-container .content-container .content .list-container .list .item {
  padding-left: var(--dedicated-search-people-padding-start);
  padding-right: var(--dedicated-search-people-padding-end);
  padding-bottom: 18px;
  padding-top: 18px;
  border-bottom: 1.5px solid var(--mi-background-color);
}
.dedicated-search-people-container .content-container .content .list-container .list .item:first-child {
  padding-top: 0;
}
.dedicated-search-people-container .content-container .content .list-container .list .item.contact {
  cursor: pointer;
}
.dedicated-search-people-container .content-container .content .list-container.myao-users-container .list .item {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 18px;
       column-gap: 18px;
}
.dedicated-search-people-container .content-container .content .list-container.myao-users-container .list .item app-user-info {
  grid-row: 1;
  grid-column: 1;
}
.dedicated-search-people-container .content-container .content .list-container.myao-users-container .list .item .actions-container {
  grid-row: 1;
  grid-column: 2;
}
.dedicated-search-people-container .content-container .content app-skeleton-loader ::ng-deep .skeleton {
  padding: 10px var(--dedicated-search-people-padding-end) 10px var(--dedicated-search-people-padding-start);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
