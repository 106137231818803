import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { waitTime } from 'src/app/services/utils/utils';
import appConfig from 'src/config/config';
import { Platform } from 'src/config/config.model';
import * as ImageEditor from 'tui-image-editor/dist/tui-image-editor';
import { ImageEditorCommand } from './image-editor.model';

const DEFAULT_COLOR = '#ff4040';
const TOOLBAR_HEIGHT = 90;

interface TuiImageSize {
  oldWidth: number;
  oldHeight: number;
  newWidth: number;
  newHeight: number;
}

interface TuiEditorSize {
  width: number;
  height: number;
}

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements AfterViewInit, OnDestroy {

  @Input() imageFile: File;
  @Output() undoStackChanged: EventEmitter<number> = new EventEmitter();
  @Output() imageCropStateChanged: EventEmitter<void> = new EventEmitter();
  platform: Platform = appConfig.platform;
  currentMenuName: ImageEditorCommand = null;
  colorIsInverted = false;
  instance: ImageEditor;
  imageSize: TuiImageSize;
  activeObjectId: string = null;
  isCropActive = false;
  constructor(private el: ElementRef) { }

  @HostBinding('class') get viewMode(): string {
    return this.isMobile ? 'mobile-editor' : '';
  }

  @HostListener('click', ['$event.target'])
  onClick(event: {tagName: string, innerText: string; classList: { value: string; }; }): void {
    if (this.isApplyOrCancelButtonClicked(event)) {
      this.isCropActive = false;
      this.imageCropStateChanged.emit();
    }
  }

  ngAfterViewInit(): void {
    if(!this.instance){

      this.instance = new ImageEditor(this.el.nativeElement, {
        selectionStyle: {
          rotatingPointOffset: 100,
          cornerStyle: 'circle',
          cornerSize: 40,
          cornerColor: '#afa',
          cornerStrokeColor: '#afa',
          transparentCorners: false,
          lineWidth: 10,
          borderColor: '#faf'
        },
        usageStatistics: false,
        applyCropSelectionStyle: true,
        applyGroupSelectionStyle: true,
        includeUI: {
          menu: ['text', 'crop', 'draw', 'icon', 'shape'],
          uiSize: {
            width: '100%',
            height: '100%',
          },
          menuBarPosition: 'top'
        }
      });
      // adapt defaults
      this.instance.ui.text.textColor = DEFAULT_COLOR;
      this.instance.ui.icon.setIconPickerColor(DEFAULT_COLOR);
      this.instance.ui.shape.options.stroke = DEFAULT_COLOR;
      this.instance.ui.shape.options.strokeWidth = 12;
      this.instance.ui.draw.color = DEFAULT_COLOR;
      this.instance.ui.draw.width = 15;
      this.instance.ui.draw._els.drawColorPicker.picker.options.color = DEFAULT_COLOR;
      this.instance.ui.draw._els.drawColorPicker.colorElement.style.backgroundColor = DEFAULT_COLOR;
      this.instance.ui.draw._els.drawRange._inputSetValue(15);
      this.instance.ui.shape._els.strokeColorpicker.picker.options.color = DEFAULT_COLOR;
      this.instance.ui.shape._els.strokeColorpicker.colorElement.style.backgroundColor = DEFAULT_COLOR;

    }
    this.instance.on('objectActivated', obj => {
      this.activeObjectId = obj.id;
      if (obj.type === 'cropzone') {
        this.isCropActive = true;
        this.imageCropStateChanged.emit();
      }
    });

    this.instance.on('undoStackChanged', length => {
      this.undoStackChanged.emit(length);
    });

    let name = 'image';
    if(this.imageFile && this.imageFile.name){
      name = this.imageFile.name;
    }
    this.instance.loadImageFromFile(this.imageFile, name)
      .then((imageSize: TuiImageSize) => {
        this.imageSize = imageSize;
        this.instance.ui.activeMenuEvent();
        return this._resizeCanvasDimension(false);
      })
      .then(() => {
        this.instance._clearHistory();
        this.instance._invoker.fire('executeCommand', 'Load');
        this.instance.clearUndoStack();
        if(this.isMobile){
          this._doMobilePatches();
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  isApplyOrCancelButtonClicked(event: {tagName: string, innerText: string; classList: { value: string; }; }): boolean {
    return this.isCropActive && event.innerText?.trim() === 'Apply' || event.innerText?.trim() === 'Cancel'
      || (event.tagName !== 'use' && event.classList?.value === 'active use-default') || event.classList?.value === 'svg_ic-menu';
  }

  _resizeCanvasDimension(toolsPanelVisible: boolean): Promise<void> {
    return this._getEditorSize().then(editorSize => {
      if(toolsPanelVisible){
        editorSize.height -= TOOLBAR_HEIGHT;
      }
      this.instance.ui.resizeEditor({
        uiSize: editorSize,
        imageSize: this.imageSize
      });
      // NOTE: the scrolling is broken on mobile platform
      this.instance.resizeCanvasDimension(editorSize);
    });
  }

  _doMobilePatches(): void {
    this._initRangeSlider();
    this._initShapeSlider();
    this._initTextSlider();
  }

  _initRangeSlider(): void {
    const el = this.el.nativeElement as HTMLElement;
    const valueInput: HTMLIonInputElement = el.querySelector('.tui-image-editor-menu-draw .tui-image-editor-range-value');
    const newRange = this._createSlider(1, 30);
    newRange.addEventListener('change', evt => {
      const value = (evt.target as HTMLIonInputElement).value.toString();
      valueInput.value = value;
      this.instance.setBrush({width: parseInt(value, 10)});
    });
    const rangeWrap  = el.querySelector('.tui-image-editor-menu-draw .tui-image-editor-range-wrap');
    rangeWrap.insertBefore(newRange, valueInput);
    this.instance.setBrush({width: 10}); // default
    // TODO: save/use brush changes
  }

  _initShapeSlider(): void {
    const el = this.el.nativeElement as HTMLElement;
    const valueInput: HTMLIonInputElement = el.querySelector('.tui-image-editor-menu-shape .tui-image-editor-range-value');
    const newRange = this._createSlider(2, 300);
    newRange.addEventListener('change', evt => {
      if(!this.activeObjectId){
        return;
      }
      const value = (evt.target as HTMLIonInputElement).value.toString();
      valueInput.value = value;
      this.instance.changeShape(this.activeObjectId, {
        strokeWidth: parseInt(value, 10)
      });
    });
    const rangeWrap  = el.querySelector('.tui-image-editor-menu-shape .tui-image-editor-range-wrap');
    rangeWrap.insertBefore(newRange, valueInput);
  }

  _initTextSlider(): void {
    const el = this.el.nativeElement as HTMLElement;
    const valueInput: HTMLIonInputElement = el.querySelector('.tui-image-editor-menu-text .tui-image-editor-range-value');
    const newRange = this._createSlider(10, 100);
    newRange.addEventListener('change', evt => {
      if(!this.activeObjectId){
        return;
      }
      const value = (evt.target as HTMLInputElement).value.toString();
      valueInput.value = value;
      this.instance.changeTextStyle(this.activeObjectId, {
        fontSize: parseInt(value, 10),
      });
    });
    const rangeWrap  = el.querySelector('.tui-image-editor-menu-text .tui-image-editor-range-wrap');
    rangeWrap.insertBefore(newRange, valueInput);
  }

  _createSlider(min: number, max: number): HTMLInputElement {
    const newRange = document.createElement('input');
    newRange.setAttribute('type', 'range');
    newRange.setAttribute('min', min.toString());
    newRange.setAttribute('max', max.toString());
    return newRange;
  }

  _getEditorSize(): Promise<TuiEditorSize> {
    return waitTime(500).then(() => {
      return (this.el.nativeElement as HTMLElement).getBoundingClientRect();
    });
  }

  get isMobile(): boolean {
    return this.platform === Platform.IOS || this.platform === Platform.ANDROID;
  }

  destroyEditor(): void {
    if(this.instance){
      const canvas = this.instance._graphics._canvas;
      this.instance.destroy();
      canvas.dispose();
      this.instance = null;
    }
  }

  ngOnDestroy(): void {
    this.destroyEditor();
  }

  showMenu(menuName: ImageEditorCommand): void {
    if(menuName){
      if(this.currentMenuName === menuName){ // close the current menu
        this._onCloseMenu(this.currentMenuName);
        this.currentMenuName = null;
      }else{
        if(menuName !== this.currentMenuName){ // open other menu
          this._onCloseMenu(this.currentMenuName);
        }
        this.currentMenuName = menuName;
      }
      this.instance.ui.changeMenu(menuName);
    }
  }

  _onCloseMenu(menuName: ImageEditorCommand): void {
    if(!menuName){
      return;
    }
    if(menuName === 'draw' || menuName === 'shape' || menuName === 'text'){
      this.instance.stopDrawingMode();
    }
  }

  get imageDataURL(): string {
    return this.instance.toDataURL();
  }

  invertColors(): void {
    const FILTER_NAME = 'Invert';
    if(this.colorIsInverted){
      this.instance.removeFilter(FILTER_NAME)
    }else{
      this.instance.applyFilter(FILTER_NAME, null);
    }
    this.colorIsInverted = !this.colorIsInverted;
  }

  undo(): void {
    if(this.instance){
      this.instance.undo();
    }
  }

  clearUndoHistory(): void {
    if(this.instance){
      if(!this.instance.isEmptyUndoStack()){
        this.instance.clearUndoStack();
      }
      if(!this.instance.isEmptyRedoStack()){
        this.instance.clearRedoStack();
      }
    }
  }
}
