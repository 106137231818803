// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shared-object {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--mi-purple-color);
  margin: 3px;
}
.shared-object .image-container {
  grid-row: 1;
  grid-column: 1;
}
.shared-object .image-container app-user-profile-image {
  --user-image-size: 70px;
}
.shared-object .text-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
}
.shared-object .text-container app-user-full-name {
  --name-text-font-size: var(--font-size-large);
}
.shared-object .text-container .profile-data {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  line-height: var(--font-line-height-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
