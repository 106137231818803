import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { isMobilePlatform } from '../utils/utils';
import { contextForClient } from 'src/app/contexts/utils';
import { Platform } from 'src/config/config.model';

import { SignUpStatus } from './logic/auth-logic.service.interface';
import { AuthService } from './auth.service';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';

import appConfig from 'src/config/config';
import { GlobalSubjects, GlobalSubjectsService } from '../utils/global-subjects.service';

interface Config {
  clientId: string,
  platform: Platform;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedHomePageGuard {
  config: Config = {
    clientId: appConfig.authentication.clientId,
    platform: appConfig.platform
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private globalSubjects: GlobalSubjectsService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.contextService.getCurrentContext().then(context => {
      return Promise.resolve(context?.key);
    }).catch(() => {
      return Promise.resolve(this.contextService?.currentContext?.key);
    }).then(currentContext => {

      if (!currentContext) {
        currentContext = this.contextService?.currentContext?.key;
      }

      return this.authService.getSignupStatus(currentContext)
        .then(status => {
          if (status === SignUpStatus.FINISHED) {
            return this.getHomeContext()
              .then(context => {
                // reset header/footer hiding in case it was hidden
                this.globalSubjects.emit(GlobalSubjects.HIDE_HEADER_AND_TABS, false);
                return this.router.createUrlTree([`/${context}`, 'feed']);
              });
          }

          const params = window?.location?.href?.split('?')?.[1];
          let url = '/auth';

          if (params?.length) {
            url = `${url}?${params}`;
          }

          return this.router.parseUrl(url);
        });
    });
  }

  getHomeContext(): Promise<string> {
    let promise;
    if (this.contextService.isSingleContextApp) {
      return Promise.resolve(this.contextService.defaultContext.key);
    }
    if (isMobilePlatform(this.config.platform)) {
      promise = this.contextService.getLastSavedContextKey();
    } else {
      promise = Promise.resolve(null);
    }
    return promise.then(contextKey => {
      if (contextKey) {
        return contextKey;
      }
      return this.authService.getProfile(this.config.clientId)
        .then(profile => {
          return contextForClient(profile.homeDevision);
        });
    });
  }
}
