import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-case-tags',
  templateUrl: './case-tags.component.html',
  styleUrls: ['./case-tags.component.scss'],
})
export class CaseTagsComponent {

  @Input() classificationLevelOne: string;
  @Input() classificationLevelTwo: string;
  @Input() titleResourceKey = 'app.groups.case-tags';
  @Input() preventTextOverflow = true;

}
