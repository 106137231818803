import { ContextEnvironmentConfigInterface, Environment } from '../config.model';
import {ContextConfig} from './trauma-context.config';

class ContextEnvironmentConfig extends ContextConfig implements ContextEnvironmentConfigInterface {
  environment: Environment = 'prod';
  kaltura = {
    partnerId: '2396982',
		uiconfId: '42933402'
  };
  aoMembershipUrl = 'https://www.aofoundation.org/trauma/membership';
  moreEventsLink = 'https://www.aofoundation.org/trauma/education/courses-events';
}

const contextConfig = new ContextEnvironmentConfig();
export default contextConfig;
