// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-card {
  --background: var(--mi-white-color);
}
.content-card h3 {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-black-color);
}
.content-card p {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  width: 95%;
}

.blue-card {
  --background: var(--mi-light-blue-color);
  box-shadow: none;
  margin-top: 30px;
}
.blue-card .get-verified-button {
  margin: 15px 0;
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--mi-font-size-default);
  height: 47px;
  --border-radius: 5px;
}
@media all and (min-width: 760px) {
  .blue-card .get-verified-button {
    width: 234px !important;
  }
  .blue-card .blue-card-text-container {
    padding-left: 30px !important;
  }
}
.blue-card .blue-card-content {
  display: grid;
  grid-auto-columns: 3fr 1fr;
}
.blue-card .blue-card-content .verification-image-container {
  grid-column: 2;
  margin-right: 45px;
}
.blue-card .blue-card-content .verification-image-container.mobile-image-container {
  margin-right: 15px;
}
.blue-card .blue-card-content .verification-image-container ion-img {
  width: 100%;
  height: 100%;
}
.blue-card .blue-card-content .blue-card-text-container {
  grid-column: 1;
  color: var(--mi-black-color);
  padding-top: 25px;
  padding-left: 15px;
}
.blue-card .blue-card-content .blue-card-text-container h3 {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
}
.blue-card .blue-card-content .blue-card-text-container p {
  margin-top: 15px;
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
