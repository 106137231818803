import { Injectable } from '@angular/core';

declare const Ionic: any; // engine

/*
  Ionic WebView wrapper to simplify testing.
*/
@Injectable()
export class IonicWebViewWrapperService {

  getServerBasePath(): Promise<string> {
    if(Ionic.WebView){
      return new Promise( resolve => {
        Ionic.WebView.getServerBasePath( path => {
          resolve(path);
        });
      });
    }
    return Promise.resolve('/');
  }

  setServerBasePath(path: string): void {
    if(Ionic.WebView){
      Ionic.WebView.setServerBasePath(path);
    }
  }

  persistServerBasePath(): void {
    if(Ionic.WebView){
      Ionic.WebView.persistServerBasePath();
    }
  }

  convertFileSrc(path: string): string {
    if(Ionic.WebView){
      return Ionic.WebView.convertFileSrc(path);
    }
    return path;
  }
}
