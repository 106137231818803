import { Component } from '@angular/core';

export interface AuthDialogData{
  socialSignUp: {
    firstName?: string;
    lastName?: string;
    email: string;
  };
}

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
})
export class AuthDialogComponent {
  rootPage: any;
  rootParams: AuthDialogData;
}
