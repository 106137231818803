import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-case-post-toggle-action',
  templateUrl: './case-post-toggle-action.component.html',
  styleUrls: ['./case-post-toggle-action.component.scss'],
})

export class CasePostToggleActionComponent {

  @Input() title: string;
  @Input() text: string;
  @Input() disabled = false;
  @Output() toggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  disableProfileOpen = false;
  toggleValue = false;

  toggleClicked(): void {
    this.toggleValue = !this.toggleValue;
    this.toggleEvent.emit(this.toggleValue);
  }
}
