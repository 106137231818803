import {Component, Inject} from '@angular/core';
import {ModalController} from '@ionic/angular';

// services
import {CONTEXT_SERVICE, ContextService} from 'src/app/services/context/context.model';
import {UI_UTILS_SERVICE, UIUtilsServiceInterface} from 'src/app/services/utils/ui-utils.service.interface';
import {Router} from '@angular/router';
import {TRACKING_SERVICE, TrackingService} from '../../services/tracking/tracking.model';

@Component({
  selector: 'app-dialog-for-newly-enrolled-users',
  templateUrl: './dialog-for-newly-enrolled-users.component.html',
  styleUrls: ['./dialog-for-newly-enrolled-users.component.scss'],
})
export class DialogForNewlyEnrolledUsersComponent {

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private modalController: ModalController,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private router: Router,
  ) {}

  openFeedCustomization(): void {
    this.modalController.dismiss();
    const url = [
      this.contextService.currentContext.key,
      'feed/customization',
    ].join('/');
    const navigation =
      this.uiUtilsService.getNavigationValueBasedOnPlatform(url);
    this.trackOpenCustomizingYourFeedClicked();

    this.router.navigate([navigation]);
  }

  trackOpenCustomizingYourFeedClicked(): void {
    this.trackingService
      .trackGenericClickedAction('customiseFeed', 'refineInterestsModal', 'customiseFeed')
      .catch((_err) => {
        console.error('Could not track customisation of feed clicked action.');
      });
  }

  trackSkipClicked(): void {
    this.trackingService
      .trackGenericClickedAction('skipForNow', 'refineInterestsModal', 'customiseFeed')
      .catch((_err) => {
        console.error('Could not track skipForNow of clicked action.');
      });
  }

  closeModal(): void {
    this.trackSkipClicked();
    this.modalController.dismiss();
  }

}
