import { tv4 } from 'tv4';

interface SchemaCache {
  [prop: string]: any;
}

export type LoadFile = (filePath: string) => Promise<any>;

export class SchemaValidator {
  private cache: SchemaCache = {};
  private missingCache: SchemaCache = {};

  constructor(
    private schemasLocation: string,
    private loadFile: LoadFile,
    private schemaFileExtension = '.schema.json'){}

  isValid<T>(data: T, schemaName: string): Promise<T> {
    return new Promise((resolve, reject) => {
      this.getSchema(schemaName)
        .then(schema => {
          if (!schema) {
            console.log(`no validation schema provided: ${schemaName}`);
            return resolve(data);
          }
          if (!tv4.validate(data, schema)) {
            console.error(`ERROR: JSON Schema validation: ${schemaName}`)
            console.error(tv4.error);
            return reject(tv4.error);
          }
          return resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private getSchema(schemaName: string): Promise<any> {
    const schema = this.cache[schemaName];
    if (schema) {
      return Promise.resolve(schema);
    }
    if (this.missingCache[schemaName]) {
      return Promise.resolve();
    }
    return this.loadSchema(schemaName)
      .then(loadedSchema => {
        if (loadedSchema) {
          this.cache[schemaName] = loadedSchema;
        } else {
          this.missingCache[schemaName] = true;
        }
        return loadedSchema;
      });
  }

  private loadSchema(schemaName: string): Promise<any> {
    const url = [this.schemasLocation, schemaName, this.schemaFileExtension].join('');
    return new Promise(resolve => {
      this.loadFile(url).then(data => {
        resolve(data);
      }).catch(_err => {
        resolve(null);
      });
    });
  }


}
