import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';
import {Group} from '../../services/groups/group.model';
import {UserProfile} from '../../services/yeti-protocol/auth/mi';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth/auth.service';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-invite-users-prompt-card',
  templateUrl: './invite-users-prompt-card.component.html',
  styleUrls: ['./invite-users-prompt-card.component.scss'],
})
export class InviteUsersPromptCardComponent implements OnInit, OnDestroy {

  @Input()
  group: Group

  userProfile: UserProfile
  private userProfileSubscription: Subscription;

  constructor(private responsiveUtilsService: ResponsiveUtilsService,
              @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
              private authService: AuthService,
              private modalController: ModalController) { }

  ngOnInit(): void {
    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  async inviteParticipants(): Promise<void> {
    const modalController = this.modalController;
    const executeServiceMethod = (fn): void => {
      fn(modalController);
    };
    return this.dialogs.inviteGroupParticipants((fn) => {executeServiceMethod(fn); },
      this.group._id, this.group.visibility, this.userProfile);
  }

}
