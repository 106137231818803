import { Component, EventEmitter, Input, Output } from '@angular/core';

// services
import { LinkOpenerService } from 'src/app/services/link-opener.service';

@Component({
  selector: 'app-surgery-reference-image',
  templateUrl: './surgery-reference-image.component.html',
  styleUrls: ['./surgery-reference-image.component.scss'],
})
export class SurgeryReferenceImageComponent {

  @Input() surgeryReferenceImageUrl: string;
  @Input() surgeryReferenceUrl: string;
  @Input() disableSurgeryReferenceOpen: boolean;

  @Output() imageClick: EventEmitter<any> = new EventEmitter();

  showDefaultSurgeryReferenceImage: boolean;

  constructor(
    private linkOpener: LinkOpenerService
  ) { }

  onClick(event: Event): void {
    if (this.disableSurgeryReferenceOpen) {
      this.imageClick.emit(event);
    } else {
      this.openSurgeryReferenceLink();
    }

    event.stopPropagation();
    return null;
  }

  openSurgeryReferenceLink(): void {

    if (!this.surgeryReferenceUrl) {
      return;
    }

    this.linkOpener.open(this.surgeryReferenceUrl);
  }
}
