// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-faculty-lounge-container {
  background-color: var(--mi-white-color);
  padding: 18px 16px 25px 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.my-faculty-lounge-container .title {
  color: var(--mi-secondary-color-2);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-large);
}
.my-faculty-lounge-container .outlinks-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.my-faculty-lounge-container .outlinks-container app-outlink-button {
  --outlink-icon-size: 27px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
