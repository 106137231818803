import { MyStreamSuccessResponse, ResponseStreamItem } from 'src/app/services/yeti-protocol/my-stream';

export namespace MyNetworkStream {
  export interface FetchStreamItemsPayload {
    filter?: string;
    pageIndex?: number;
    pageSize?: number;
  }

  export class FetchMyNetworkStreamItems {
    static readonly type = '[My Network Stream] Fetch my network stream items';
    constructor(public payloadParams: FetchStreamItemsPayload) { }
  }

  export class FetchMyNetworkStreamItemsSuccess {
    static readonly type = '[My Network Stream] Fetch my network stream items success';
    constructor(
      public payloadParams: FetchStreamItemsPayload,
      public response: MyStreamSuccessResponse
    ) { }
  }

  export class FetchMyNetworkStreamItemsFailed {
    static readonly type = '[My Network Stream] Fetch my network stream items failed';
  }

  export class InsertMyNetworkStreamItemBeforeIndex {
    static readonly type = '[My Network Stream] Insert my network stream item before index';
    constructor(
      public item: ResponseStreamItem,
      public index: number
    ) { }
  }

  export class RemoveMyNetworkStreamItem {
    static readonly type = '[My Network Stream] Remove my network stream item at index';
    constructor(
      public item: ResponseStreamItem
    ) { }
  }

  export class UpdateMyNetworkStreamItem {
    static readonly type = '[My Network Stream] Update my network stream item';
    constructor(
      public item: ResponseStreamItem
    ) { }
  }

  export class UpdateMyNetworkStreamItemsOwnerFollowingStatus {
    static readonly type = '[My Network Stream] Update my network stream items owner following status';
    constructor(public userId: string, public isFollower: boolean) { }
  }
}
