import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  spinnerIsShowed: boolean;

  constructor(public loadingController: LoadingController) { }

  async present(): Promise<void> {

    if (this.spinnerIsShowed) {
      return;
    }

    this.spinnerIsShowed = true;

    const modal = await this.loadingController.create({
      message: 'Please wait...',
      cssClass: 'loading-spinner'
    });

    return modal.present();
  }

  async dismiss(): Promise<boolean> {
    try {
      const loading = this.loadingController.getTop();

      if (loading) {
        const dismissResult = await this.loadingController.dismiss(true);
        this.spinnerIsShowed = false;
        return Promise.resolve(dismissResult);

      } else {
        return Promise.resolve(false);
      }
    } catch (err) {
      return Promise.resolve(false);
    }
  }
}
