import {Component, Input} from '@angular/core';
import {PublicProfile} from '../../services/yeti-protocol/public-profile';

@Component({
  selector: 'app-user-profile-additional-info',
  templateUrl: './user-profile-additional-info.component.html',
  styleUrls: ['./user-profile-additional-info.component.scss'],
})
export class UserProfileAdditionalInfoComponent {

  @Input() userProfile: PublicProfile;

}
