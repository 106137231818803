// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-container {
  width: 150px;
  height: 26px;
  background-color: var(--mi-blue-color-4);
  font-size: 13px;
  line-height: 26px;
  border-radius: 8px;
  color: var(--mi-black-color);
  text-align: center;
  margin: 10px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
