import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VisibilityTrackerService } from './visibility-tracker.service';

@Directive({
  selector: '[app-visibility-tracker-target]',
  exportAs: 'visibilityTracker'
})
export class VisibilityTrackerTargetDirective implements AfterViewInit, OnChanges {

  @Input() visibilityTrackerRootKey: string;
  @Input() visibilityTrackerRegistrationDelay = 100;
  @Input() registerAfterPromise: boolean;
  @Input() promise: Promise<any>;

  constructor(
    private ref: ElementRef,
    private tracker: VisibilityTrackerService
  ) {
  }
  ngAfterViewInit(): void {

    if (!this.registerAfterPromise) {
      setTimeout(() => {
        this.tracker.registerTarget(this.visibilityTrackerRootKey, this.ref.nativeElement);
      }, this.visibilityTrackerRegistrationDelay);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.promise &&
      changes?.promise?.currentValue &&
      !changes?.promise?.previousValue) {
      this.promise.finally(() => {
        this.tracker.registerTarget(this.visibilityTrackerRootKey, this.ref.nativeElement);
      })
    }
  }
}
