import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { GroupListItem } from '../../services/yeti-protocol/chatter-api';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import {
  ActionSheetConfigGroups,
  GroupActionsVisibility,
  MiCardActionsObjectType
} from '../../services/mi-card-actions.service';
import { Router } from '@angular/router';
import { RecommendationsBookmarksService, ItemTypes, } from '../../services/recommendations-service/recommendations-bookmarks.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

// services
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import { ChatterApiService } from '../../services/chatter-api.service';

import { ToastService } from '../../services/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { GroupsDataService } from 'src/app/services/groups/groups-data.service';
import { ContextService, CONTEXT_SERVICE } from 'src/app/services/context/context.model';
interface GroupRedirectQuery {
  source?: string;
  recommendedBy?: string;
}
@Component({
  selector: 'app-recommended-groups-item',
  templateUrl: './recommended-groups-item.component.html',
  styleUrls: ['./recommended-groups-item.component.scss'],
})
export class RecommendedGroupsItemComponent implements OnInit, AfterViewInit {
  @Input() group: GroupListItem;
  // @Input() user: UserProfile;
  @Input() source: ActionSource;
  @Input() groupRedirect: boolean;
  @Input() recommendedById?: string;
  @Output() refreshGroups: EventEmitter<string> = new EventEmitter();

  recommendationObject: any;
  user: UserProfile;
  miCardActionsActionSheetConfig: ActionSheetConfigGroups;
  groupActionsVisibility: GroupActionsVisibility;
  infoSheetId = 'recommended-groups-item';

  constructor(
    private router: Router,
    private chatterApiService: ChatterApiService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private toast: ToastService,
    private authService: AuthService,
    private infoSheetService: InfoSheetService,
    private recommendationsService: RecommendationsBookmarksService,
    private sharingUIService: SharingUIService,
    private groupsDataService: GroupsDataService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  ngOnInit(): void {
    this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });

    if (this.group) {
      this.group.imageUrl = this.group.imageUrl || 'assets/images/group-circle-only.svg';
    }

    this.groupActionsVisibility = {
      bookmark: false,
      translate: false
    };

    this.miCardActionsActionSheetConfig = {
      objectType: MiCardActionsObjectType.group,
      object: this.group,
      actionsVisibilityConfig: this.groupActionsVisibility
    };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.infoSheetId += `-${this.group?._id}`;
      this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);
    }, 0);

  }

  async requestToJoinGroup(group: GroupListItem): Promise<void> {

    const canAccess = await this.uiUtilsService.checkAndPromptCanAccessAoMembersOnlyGroup(
      this.group?.AOMembersOnly,
      this.user?.isAOMember,
      this.contextService?.currentContext?.aoMembershipUrl
    );

    if (!canAccess) {
      return;
    }

    this.chatterApiService.requestToJoinGroup(group._id, this.user.email, this.user.id)
      .then(response => {
        if (response && response.success) {
          this.toast.show('app.groups.RecommendedGroups.request-was-sent');
          this.refreshGroups.emit(group._id);
        }
      });
  }

  async joinGroup(group: GroupListItem): Promise<void> {

    const canAccess = await this.uiUtilsService.checkAndPromptCanAccessAoMembersOnlyGroup(
      this.group?.AOMembersOnly,
      this.user?.isAOMember,
      this.contextService?.currentContext?.aoMembershipUrl
    );

    if (!canAccess) {
      return;
    }

    this.chatterApiService.joinGroup(group._id, this.user.email, this.user.id)
      .then(response => {
        if (response && response.success) {
          this.toast.show('app.groups.RecommendedGroups.joined-group');
          this.refreshGroups.emit(group._id);
          this.groupsDataService.triggerNewGroupCreatedAction(group);
          this.goToGroup();
        }
      });
  }

  goToGroup(): void {
    const url = '/' + [this.contextService.currentContext.key, 'group', this.group?._id].join('/');
    if (this.source || this.recommendedById) {
      const queryParams: GroupRedirectQuery = {};
      if (this.source) {
        queryParams.source = this.source;
      } else {
        queryParams.source = ActionSource.groupCard;
      }
      if (this.recommendedById) {
        queryParams.recommendedBy = this.recommendedById;
      }

      this.router.navigate([url], { queryParams: queryParams });
    } else {
      this.router.navigateByUrl(url);
    }
  }

  shareGroup(): void {
    this.sharingUIService.showShareDialogForObject(this.group, undefined, this.source);
  }

  bookmark(): void {
    this.recommendationsService.addRecommendationBookmarkItem('group', ItemTypes.BOOKMARK, this.group._id)
      .then(response => {
        if (response && response.success) {
          this.toast.show('app.common.bookmarked');
        }
      });
  }

  recommendClicked(group: GroupListItem): void {
    this.recommendationsService.recommendClicked(group, this.user)
  }
}
