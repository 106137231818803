import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CaseClassificationAction, CaseClassificationActions } from './case-classification-tags-ui-service.model';

@Injectable({
  providedIn: 'root'
})
export class CaseClassificationTagsUiService {

  private caseClassificationChanged: Subject<CaseClassificationAction> = new Subject();

  getCaseClassificationChangesStream(): Observable<CaseClassificationAction> {
    return this.caseClassificationChanged.asObservable();
  }

  selectTag(tagsGroupId: string, tagValue: string): void {

    const action: CaseClassificationAction = {
      action: CaseClassificationActions.TAG_SELECTED,
      tagsGroupId: tagsGroupId,
      tagValue: tagValue
    }

    this.caseClassificationChanged.next(action);
  }

  deselectTag(tagsGroupId: string, tagValue: string): void {
    const action: CaseClassificationAction = {
      action: CaseClassificationActions.TAG_DESELECTED,
      tagsGroupId: tagsGroupId,
      tagValue: tagValue
    }

    this.caseClassificationChanged.next(action);
  }
}
