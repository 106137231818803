// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-field-container {
  width: 100%;
  min-height: 46px;
  background-color: var(--mi-blue-color-5);
  padding: 0 10px;
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.search-field-container.initial-state {
  grid-template-columns: min-content auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.search-field-container.initial-state .input-container {
  grid-column: 1;
}
.search-field-container.initial-state .input-container .search-input-field {
  display: none;
}
.search-field-container.initial-state .placeholder-container {
  grid-column: 2;
}
.search-field-container.initial-state .action-buttons-container {
  display: none;
}
.search-field-container.focus-but-empty-search-value {
  grid-template-columns: auto min-content;
}
.search-field-container.focus-but-empty-search-value .input-container {
  grid-column: 1;
}
.search-field-container.focus-but-empty-search-value .input-container .search-icon {
  display: none;
}
.search-field-container.focus-but-empty-search-value .input-container .search-input-field {
  width: 100%;
}
.search-field-container.focus-but-empty-search-value .placeholder-container {
  display: none;
}
.search-field-container.focus-but-empty-search-value .action-buttons-container {
  grid-column: 2;
}
.search-field-container.focus-with-text {
  grid-template-columns: auto min-content;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
.search-field-container.focus-with-text .input-container {
  grid-column: 1;
}
.search-field-container.focus-with-text .input-container .search-icon {
  display: none;
}
.search-field-container.focus-with-text .input-container .search-input-field {
  width: 100%;
}
.search-field-container.focus-with-text .placeholder-container {
  display: none;
}
.search-field-container.focus-with-text .action-buttons-container {
  grid-column: 2;
}
.search-field-container .input-container {
  grid-row: 1;
  display: flex;
  align-items: center;
  height: 46px;
  margin-left: -10px;
  padding-left: 10px;
}
.search-field-container .input-container .search-icon {
  --icon-size: 24px;
  color: var(--mi-color-text-lite);
}
.search-field-container .input-container .search-input-field {
  height: 100%;
  color: var(--mi-secondary-color-2);
  border: none;
  background: transparent;
  outline: none;
  margin-left: -10px;
  padding-left: 10px;
}
.search-field-container .placeholder-container {
  grid-row: 1;
  pointer-events: none;
}
.search-field-container .placeholder-container p {
  margin: 0;
  color: var(--mi-color-text-lite);
  font-size: var(--font-size-regular);
  font-weight: var(--font-wight-regular);
  line-height: 1.65;
}
.search-field-container .action-buttons-container {
  grid-row: 1;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.search-field-container .action-buttons-container app-icon {
  --icon-size: 22px;
  color: var(--mi-color-text-lite);
  cursor: pointer;
}
.search-field-container .action-buttons-container .separator-container p {
  margin: 0;
  color: var(--mi-color-text-lite);
  font-size: 28px;
  font-weight: 300;
}
.search-field-container .action-buttons-container .search-button.disabled {
  pointer-events: none;
}
.search-field-container .action-buttons-container.activate-search-button .search-button {
  color: var(--mi-primary-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
