import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

// models
import {
  CaseClassificationActions,
  CaseClassificationAction
} from 'src/app/services/case-classification-tags-ui-service.model';

// services
import { CaseClassificationTagsUiService } from 'src/app/services/case-classification-tags-ui-service.service';

export interface CaseClassificationClickedObject {
  text: string;
  secondaryTopics: Array<string>,
  caseClassificationChipComponentRef: CaseClassificationChipComponent
}

@Component({
  selector: 'app-case-classification-chip',
  templateUrl: './case-classification-chip.component.html',
  styleUrls: ['./case-classification-chip.component.scss'],
})
export class CaseClassificationChipComponent implements OnInit, OnDestroy {

  @Input() text: string;
  @Input() iconName: string;
  @Input() secondaryTopics: Array<string> = [];
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Input() tagsGroupId: string;
  @Input() editCase = true;
  @Input() preventTextOverflow = true;

  @Output() chipClicked: EventEmitter<CaseClassificationClickedObject> = new EventEmitter();

  private caseClassificationTagsChangesSubscription: Subscription;

  constructor(
    private caseClassificationTagsUiService: CaseClassificationTagsUiService
  ) { }

  ngOnInit(): void {
    this.caseClassificationTagsChangesSubscription = this.caseClassificationTagsUiService.getCaseClassificationChangesStream()
      .subscribe(action => this.caseClassificationChanged(action));
  }

  ngOnDestroy(): void {
    this.caseClassificationTagsChangesSubscription?.unsubscribe();
  }

  caseClassificationChanged(action: CaseClassificationAction): void {

    if (!action || action?.tagsGroupId !== this.tagsGroupId) {
      return;
    }

    setTimeout(() => {
      if (action?.action === CaseClassificationActions.TAG_SELECTED && this.text === action?.tagValue) {
        this.setSelectedStatus(true);
        this.setDisabledStatus(false);
      } else if (action?.action === CaseClassificationActions.TAG_SELECTED && this.text !== action?.tagValue) {
        this.setSelectedStatus(false);
        this.setDisabledStatus(true);
      } else {
        this.setSelectedStatus(false);
        this.setDisabledStatus(false);
      }
    }, 0);
  }

  onChipClick(): void {
    if(this.editCase) {
      if (this.disabled) {
        return;
      }
      this.chipClicked.emit({ text: this.text, secondaryTopics: this.secondaryTopics, caseClassificationChipComponentRef: this });
    }
  }

  setSelectedStatus(status: boolean): void {
    this.selected = status;
  }

  setDisabledStatus(status: boolean): void {
    this.disabled = status;
  }

}
