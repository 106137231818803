// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --chips-margin: 7px;
  --rows-margin: 3.5px;
}

.chips-container {
  padding: var(--padding-top) var(--padding-end) var(--padding-bottom) var(--padding-start);
  opacity: 1;
  transition: opacity 0.1s ease;
}
.chips-container.fixing-chips-margins {
  opacity: 0 !important;
}
.chips-container ::ng-deep > * {
  display: inline-block;
  margin-right: var(--chips-margin) !important;
  margin-top: var(--rows-margin) !important;
}
.chips-container ::ng-deep > *:last-child {
  margin-right: 0;
}
.chips-container.prevent-text-overflow ::ng-deep > * {
  max-width: calc(60% - var(--chips-margin));
  overflow: hidden;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
