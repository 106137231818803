// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suggestions-list-pop-up-container {
  padding-top: 30px;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
