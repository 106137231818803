import { Injectable, Optional } from '@angular/core';
import { ArticleTagType, ArticleType } from '../yeti-protocol/article';

import { InterestsService } from '../interests.service';
import { Interest } from '../interests.model';
import { getJournalTag } from '../utils/utils';
import { ChannelsService } from '../channels.service';
import { FulltextArticleService } from './fulltext-article.service';
import { AppTranslationService } from '../app-translation.service';
import { skipDuplicates } from '../utils/string-utils';

@Injectable({
  providedIn: 'root'
})
export class ArticleTagsService {

  contextInterests: Map<string, Array<Interest>> = new Map();

  constructor(
    private channelsService: ChannelsService,
    private interestsService: InterestsService,
    @Optional() private fulltextArticleService: FulltextArticleService,
    private appTranslationService: AppTranslationService,
  ) { }

  getTags(article: ArticleType, tagTypes: Array<ArticleTagType>, contextKey: string): Promise<Array<string>> {
    const promises = tagTypes.map((tagType: ArticleTagType) => {
      if (tagType === ArticleTagType.journals) {
        return this._getJournalTags(article);
      }
      if (tagType === ArticleTagType.interests) {
        return this._getInterestTags(article, contextKey);
      }
      if (tagType === ArticleTagType.channels) {
        return this._getChannelTags(article);
      }
      if (tagType === ArticleTagType.language) {
        return this._getLanguageTags(article);
      }
      if (tagType === ArticleTagType.fullText) {
        return this._getFullAccessTags(article);
      }
    });
    return Promise.all(promises).then(results => {
      let tags: Array<string> = [];
      results.forEach(res => {
        if (res) {
          tags = tags.concat(res);
        }
      });
      return tags;
    })
  }

  _getFullAccessTags(article: ArticleType): Promise<Array<string>> {

    if (!this.fulltextArticleService) {
      return Promise.resolve([]);
    }

    if (!this.fulltextArticleService.isFullText(article)) {
      return Promise.resolve([]);
    }

    return this.appTranslationService.get('app.common.full-text-access').then(fullTextAccessTag => {
      return [fullTextAccessTag];
    });
  }

  _getLanguageTags(article: ArticleType): Promise<Array<string>> {
    const tags: Array<string> = [];
    if (article?.meta?.Language) {
      tags.push(article?.meta?.Language);
    }
    return Promise.resolve(tags);
  }

  _getJournalTags(article: ArticleType): Promise<Array<string>> {
    const tags: Array<string> = [];
    const tag = getJournalTag(article?.journal);
    if (tag) {
      tags.push(tag);
    }
    return Promise.resolve(tags);
  }

  async _getInterestTags(article: ArticleType, contextKey: string): Promise<Array<string>> {
    const tags: Array<string> = []
    if (article?.interests && article?.interests?.length > 0) {
      const interestIds = skipDuplicates(article.interests);
      interestIds.forEach(async (interestId: string) => {
        const interestTag = await this.getInterestName(interestId, contextKey);
        if (interestTag) {
          tags.push(interestTag);
        }
      });
    }
    return tags;
  }

  getInterestName(interestId: string, contextKey: string): Promise<string | undefined> {
    return this.getContextInterests(contextKey)
      .then(interests => {
        const interest = interests.find(interestItem => interestItem.id === interestId);
        if (interest) {
          return interest.name;
        }
      })
  }

  getContextInterests(contextKey: string): Promise<Array<Interest>> {
    const interests: Array<Interest> = this.contextInterests[contextKey];
    if (interests) {
      return Promise.resolve(interests);
    }
    return this.interestsService.getInterests(contextKey)
      .then(contextInterests => {
        this.contextInterests[contextKey] = contextInterests;
        return contextInterests;
      });
  }

  async _getChannelTags(article: ArticleType): Promise<Array<string>> {
    const tags: Array<string> = [];
    if (article && article?.channel) {
      const channelTag: string = await this.getArticleChannelTag(article.channel);
      if (channelTag) {
        tags.push(channelTag);
      }
    }
    return tags;
  }

  async getArticleChannelTag(channelId: string): Promise<any> {
    try {
      const channel = await this.channelsService.getChannelInfo(channelId);
      return channel.name || channel.title || '';
    } catch (err) {
      return null;
    }
  }
}
