import { Component, Inject, Input, OnInit } from '@angular/core';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { LinkOpenerService, TargetOfTheLink } from 'src/app/services/link-opener.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import appConfig from 'src/config/config';

interface FooterComponentConfig {
  webUrl: string;
  link: Record<string, string>;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input() parentSource = ActionSource.unspecified;
  @Input() source: ActionSource | string;

  currentYear: number;
  config: FooterComponentConfig = appConfig;
  constructor(
    private linkOpener: LinkOpenerService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private dialogsService: DialogsUIService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.source = this.source || this._source;
    const today = new Date();
    this.currentYear = today.getFullYear();
  }

  onGiveFeedback(): void {
    this.linkOpener.open(this.config.link.feedbackUrl);
  }

  openCommunityGuidelines(): void {
    const url = `${this.contextService.currentContext.homePath}/community-guidelines`;
    this.linkOpener.open(url, TargetOfTheLink.SystemBrowser);
  }

  onDownloadApp(): void {
    this.dialogsService.showQRCodeDialog();
    this.trackClosingWidget();
  }

  get shouldShowHelpCenterButton(): boolean {
    return appConfig.name === 'MyAO';
  }

  trackClosingWidget(): void {
    this.trackingService.trackGenericClickedAction('download-app-qr', 'button-download-app-qr', 'button-download-app-qr',
      this.source ? { source: this.source } : undefined)
      .catch(_err => {
        console.error('Could not track button-download-app-qr clicked action.');
      });
  }

  get _source(): string {
    const componentSource = ActionSource.footer;

    return this.parentSource && this.parentSource !== ActionSource.unspecified ?
      `${this.parentSource}-${componentSource}` : componentSource;
  }
}
