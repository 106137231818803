import {Component, Input} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import { InfoSheetActionItem } from '../../models/info-sheet-action-item.model';

@Component({
  selector: 'app-info-sheet-web-actions',
  templateUrl: './info-sheet-web-actions.component.html',
  styleUrls: ['./info-sheet-web-actions.component.scss'],
})
export class InfoSheetWebActionsComponent {

  @Input()
  actions: Array<InfoSheetActionItem> = [];

  constructor(private popoverController: PopoverController) {
  }

  async actionClicked(action: InfoSheetActionItem): Promise<void> {
    await this.popoverController.dismiss(action);
  }

}
