import { Component, Input } from '@angular/core';

// models
import { ShortPublicProfile } from 'src/app/services/yeti-protocol/public-profile';

@Component({
  selector: 'app-profile-sharing-card',
  templateUrl: './profile-sharing-card.component.html',
  styleUrls: ['./profile-sharing-card.component.scss'],
})
export class ProfileSharingCardComponent {
  @Input() profile: ShortPublicProfile;
}
