// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --base-user-list-padding: 16px;
}

.list {
  display: grid;
}
.list .item {
  padding: var(--base-user-list-padding);
  border-bottom: 1.5px solid var(--mi-background-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
