import { Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
/* eslint-disable */
import { SearchCluster } from 'src/app/services/yeti-protocol/search';
/* eslint-enable */

@Component({
  selector: 'app-search-filters-tabs',
  templateUrl: './search-filters-tabs.component.html',
  styleUrls: ['./search-filters-tabs.component.scss'],
})
export class SearchFiltersTabsComponent implements OnDestroy {

  @Input() filters: Map<string, SearchCluster>;

  @Output() tabClicked: EventEmitter<SearchCluster> = new EventEmitter();

  selectedFilter: SearchCluster;

  private routeQueryParamsSubscription: Subscription;

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  ngOnDestroy(): void {
    this.routeQueryParamsSubscription?.unsubscribe();
  }

  applyFilter(event: Event, filter: SearchCluster): void {

    this.uiUtilsService.stopEventPropagation(event);

    if (this.selectedFilter && this.selectedFilter.title === filter.title) {
      this.selectedFilter = null;
    } else {
      this.selectedFilter = filter;
    }

    this.tabClicked.emit(this.selectedFilter);
  }

  get filtersArray(): Array<SearchCluster> {
    return this.filters ? Array.from(this.filters.values()) : [];
  }

}
