import { Component, Input } from '@angular/core';

// models
import { GroupMember } from '../../../services/groups/group.model';

@Component({
  selector: 'app-group-participant-card',
  templateUrl: './group-participant-card.component.html',
  styleUrls: ['./group-participant-card.component.scss'],
})
export class GroupParticipantCardComponent {
  @Input() participant: GroupMember;
}
