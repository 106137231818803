import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {GroupMember} from '../../services/groups/group.model';
import {GroupsService} from '../../services/groups/groups.service';
import {IonInfiniteScroll, ModalController} from '@ionic/angular';
import {ToastMode, ToastService} from '../../services/toast.service';
import {InfoSheetService} from '../../modules/info-sheet/services/info-sheet.service';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';
import {Group, GroupByIdSuccessResponse} from '../../services/yeti-protocol/chatter-api';
import appConfig from 'src/config/config';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';

@Component({
  selector: 'app-see-all-participants',
  templateUrl: './see-all-participants.component.html',
  styleUrls: ['./see-all-participants.component.scss'],
})
export class SeeAllParticipantsComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() groupId: string;
  @Input() showActions: boolean;

  group: Group;
  groupMembersList: Array<GroupMember> = [];
  selectedMemberInOptions: GroupMember;
  loading: boolean;
  groupModerators: Array<GroupMember> = [];
  totalMembersCount: number;
  readonly infoSheetId = 'see-all-group-participants-info-card';
  private readonly start = 0;
  private readonly count = 15;
  constructor(
    private groupsService: GroupsService,
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    private toast: ToastService,
    private infoSheetService: InfoSheetService,
    private responsiveUtilsService: ResponsiveUtilsService
) { }

  ngOnInit(): void {
    if (this.groupId) {
    setTimeout(() => {
      this.loading = true;
      this.getGroupById(this.groupId);
      this.getGroupMembers(this.groupId, this.start, this.count)
        .then(response => {
          this.groupMembersList = response;
        })
        .catch(err => {
        this.showError(err);
      }).finally(() => {
        this.loading = false;
      });
    }, 0);
    }
  }

  async getGroupMembers(groupId: string, start: number, count: number): Promise<Array<GroupMember>> {
    try {
      const groupMembersResponse = await this.groupsService.getGroupMembers(groupId, start, count);
      if (groupMembersResponse) {
        this.totalMembersCount = groupMembersResponse?.totalItemsCount;
        const finalGroupMembersResult = this.groupMembersList.concat(groupMembersResponse.result);
        return Promise.resolve(finalGroupMembersResult);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  onClose(): void {
    this.modalController.dismiss(null, 'cancel');
  }

  onProfileImageClicked(userId: string): void {
    this.modalController.dismiss({
      actionKey: 'open-user-profile',
      userId}, 'cancel');
  }

  loadMoreParticipants(_event: Event): void {

    if (this.totalMembersCount <= this.groupMembersList.length) {
      this.disableInfiniteScroll(true);
      return;
    }

    const start = Math.floor(this.groupMembersList.length / this.count);
    this.getGroupMembers(this.groupId, start, this.count).then((response) => {
      this.groupMembersList = response;
    }).catch(err => {
      this.showError(err.message);
    }).finally(() => {

      // Hide Infinite List Loader on Complete
      this.infiniteScroll.complete();
    });
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  getGroupById(groupId: string): void {
    this.groupsService.getGroupById(groupId).then(response => {
      if (response) {
        this.group = (response as GroupByIdSuccessResponse).result;
        this.groupModerators = this.group?.moderators;
      }
    })
  }

  assignModerator(): void {
    if (this._canAddModerator) {
      this.updateGroupMember(this.groupId, this.selectedMemberInOptions, 'moderator')
    } else {
      this.toast.show('app.groups.SeeAllParticipants.maximum-moderators', 'app.common.error-default', ToastMode.ERROR);
    }
  }

  presentActionSheet(member: GroupMember, event: Event): void {
    this.selectedMemberInOptions = member;
    if (this.isDesktop) {
      return this._presentActionSheetWeb(member, event);
    }
    this.infoSheetService.open(this.infoSheetId);
  }

  _presentActionSheetWeb(member: GroupMember, event: Event): void {
    this.infoSheetService.openWeb(this.infoSheetActions, event)
      .then(action => {
        if (action && action.code) {
          const newRole = this._getNewRole(action.code, member.role);
          if (newRole === 'moderator' && !this._canAddModerator) {
            this.toast.show('app.groups.SeeAllParticipants.maximum-moderators', 'app.common.error-default', ToastMode.ERROR);
          } else {
            const status = action.code === 'REMOVE_FROM_GROUP' ? 'removed' : null;
            this.updateGroupMember(this.groupId, member, newRole, status);
          }
        }
      });
  }

  get _canAddModerator(): boolean {
    return !this.groupModerators || this.groupModerators.length < appConfig.groupModeratorsMaxNum;
  }

  _getNewRole(actionCode: string, defaultRole: string): string {
    if(actionCode === 'ASSIGN_MODERATOR'){
      return 'moderator';
    } else if(actionCode === 'REMOVE_MODERATOR'){
      return 'member';
    }
    return defaultRole;
  }

  updateGroupMember(groupId: string, member: GroupMember, role: string, status?: string): void {
    this.groupsService.updateGroupMember(member.userId, role, groupId, status).then(response => {
      if (response) {
        member.role = role;
        this.getGroupById(this.groupId);
      }
    })
      .catch(() => {
        this.showError('app.common.something-went-wrong');
      })
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    const actions: Array<InfoSheetActionItem> = [];
    if(this.selectedMemberInOptions){
      if (this.selectedMemberInOptions.role !== 'moderator') {
        actions.push({
          id: 'assign-moderator',
          icon: 'md-icon-crown-plus',
          textKey: 'app.groups.GroupParticipants.assign-moderator',
          code: 'ASSIGN_MODERATOR',
          handler: () => {
            this.assignModerator();
          }
        });
      }
      if (this.selectedMemberInOptions.role === 'moderator') {
        actions.push({
          id: 'unassign-moderator',
          icon: 'md-icon-crown-minus',
          textKey: 'app.groups.GroupParticipants.remove-moderator',
          code: 'REMOVE_MODERATOR',
          handler: () => {
            this.updateGroupMember(this.groupId, this.selectedMemberInOptions, 'member');
          }
        });
      }
      actions.push({
        id: 'remove-user',
        icon: 'md-icon-user-minus',
        textKey: 'app.groups.GroupParticipants.remove-from-group',
        code: 'REMOVE_FROM_GROUP',
        handler: () => {
          this.updateGroupMember(this.groupId, this.selectedMemberInOptions, this.selectedMemberInOptions.role,'removed');
        }
      });
    }
    return actions;
  }
}
