import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {UI_UTILS_SERVICE, UIUtilsServiceInterface} from '../../services/utils/ui-utils.service.interface';
import { ParentType } from '../../services/yeti-protocol/chatter-api-schema';
import {
  OtherSource,
  Post,
  PostsDetailsByPostIdsSuccessResponse,
  PostType
} from '../../services/yeti-protocol/chatter-api';
import {ChatterApiService} from '../../services/chatter-api.service';
import {ToastMode, ToastService} from '../../services/toast.service';
import {AppTranslationService} from '../../services/app-translation.service';
import {AppNavController} from '../../services/app-nav-controller.service';
import {Router} from '@angular/router';
import {CONTEXT_SERVICE, ContextService} from '../../services/context/context.model';

@Component({
  selector: 'app-also-discussed-in-card',
  templateUrl: './also-discussed-in-card.component.html',
  styleUrls: ['./also-discussed-in-card.component.scss'],
})
export class AlsoDiscussedInCardComponent implements OnInit {

  @Input() otherSources: OtherSource[];
  @Output() hideAlsoDiscussedIn: EventEmitter<void> = new EventEmitter<void>();

  posts: Post[];
  isLoading = false;

  constructor(
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface,
    private chatterApiService: ChatterApiService,
    private toast: ToastService,
    private appTranslationService: AppTranslationService,
    private appNavController: AppNavController,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) {
  }

  ngOnInit(): void {
    this.loadAdditionalSources();
  }

  loadAdditionalSources(): void {
    this.isLoading = true;
    const postIds = this.otherSources?.map(source => source.postId);
    this.chatterApiService.getPostsByPostIds(postIds).then(({result}: PostsDetailsByPostIdsSuccessResponse) => {
      this.posts = result;
      if (!this.posts.length) {
        this.hideAlsoDiscussedIn.emit();
      }
    }).catch(() => {
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    }).finally(() => {
      this.isLoading = false;
    })
  }

  goToTheOtherSource(event: Event, post: Post): void {
    this.uiUtilsService.stopEventPropagation(event);
    let url = '';
    switch (post?.parentType) {
      case ParentType.CHAT:
      case ParentType.USER:
        if (post?.type === PostType.case) {
          this.appNavController.openPrivatePublicSharedCase(post?._id);
          return;
        } else {
          this.appNavController.openPrivatePublicPost(post?._id);
          return;
        }
      default:
        url = '/' +
          [this.contextService.currentContext.key, `${post?.parentType?.toLowerCase()}/case`, post?._id].join('/');
        break;
    }

    this.router.navigateByUrl(url);
  }

  goToTheSourceParentFeed(event: Event, post: Post): void {
    this.uiUtilsService.stopEventPropagation(event);
    switch (post?.parentType) {
      case ParentType.GROUP:
        this.appNavController.openGroup(post.parentId);
        break;
      case ParentType.EVENT:
        this.appNavController.openEvent(post.parentId);
        break;
      default:
        break;
    }
  }

  getParentName(post: Post): string {
    switch (post.parentType) {
      case ParentType.USER:
        return this.appTranslationService.instant('app.common.your-public-network');
      case ParentType.CHAT:
        return this.appTranslationService.instant('app.common.your-private-network');
      default:
        return post.parentTitle;
    }
  }

}
