import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

// models
import { ChannelsContextMap, ChannelsContextObject, ChannelsMap } from './channels.model';
import { Channel, ChannelsResponse } from './yeti-protocol/channels';
import appConfig from 'src/config/config';

// services
import { LOCAL_STORAGE_SERVICE, StorageService } from './storage/storage.service';
import { SchemaValidatorService } from './schema-validator.service';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';


export interface ChannelsServiceConfig {
  serverUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {
  config: ChannelsServiceConfig = {
    serverUrl: appConfig.drywallServer,
  }

  channels = new BehaviorSubject<ChannelsMap>(null);
  private localStorageChannelsKey = 'channels';
  private loadingChannelsFromServerPromise: Promise<any>;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(LOCAL_STORAGE_SERVICE) private storageService: StorageService,
    private httpClient: HttpClient,
    private schemaValidator: SchemaValidatorService
  ) { }


  _cacheChannels(channelsObject: ChannelsContextMap, context: string): Promise<ChannelsMap> {
    // return channels object from localstorage (updated daily)
    if (channelsObject && channelsObject[context] &&
      channelsObject[context].lastUpdateDate === this.getUtcDate()) {
      const channels: ChannelsMap = channelsObject[context] ? channelsObject[context].channels : null;

      if (channels) {
        this.cacheChannelsInMemory(channels);
        return Promise.resolve(channels);
      }
    }
    return Promise.resolve(null);
  }

  getChannelInfo(channelId: string): Promise<Channel> {

    if (!channelId) {
      return Promise.reject('Channel id is required');
    }

    return this.getChannels().then((channels: ChannelsMap) => {
      const channel: Channel = channels[channelId];
      if (channel) {
        return channel;
      } else {
        return Promise.reject(`No channel info found for this channel id ${channelId}`);
      }
    });
  }

  fetchChannels(contextKey?: string): Promise<ChannelsMap> {

    const context: string = contextKey || this.getCurrentContext();

    return new Promise((resolve, reject) => {
      this.httpClient.get(`${this.config.serverUrl}insights-${context}-${appConfig.environment}/channelsMap.json`).pipe(
        this.schemaValidator.isValidOperator<ChannelsResponse>('ChannelsResponse'),
      ).subscribe((channelsResponse: ChannelsResponse) => {

        const channelsContextObject: ChannelsContextObject = {
          speciality: context,
          channels: channelsResponse.items,
          version: channelsResponse.version
        };

        return this.cacheContextChannelsInLocalStorage(channelsContextObject, context)
          .then(() => {
            this.cacheChannelsInMemory(channelsResponse.items);
            resolve(channelsResponse.items);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    });
  }

  cacheChannelsInMemory(channels: ChannelsMap): void {
    this.channels.next(channels);
  }

  cacheContextChannelsInLocalStorage(contextChannels: ChannelsContextObject, contextKey?: string): Promise<void> {

    const context: string = contextKey || this.getCurrentContext();

    return this.getChannelsObjectFromLocalStorage()
      .then((channelsObject: ChannelsContextMap) => {
        if (!channelsObject) {
          const newChannelsObject: ChannelsContextMap = {};
          newChannelsObject[context] = contextChannels;
          channelsObject = newChannelsObject;
        } else {
          channelsObject[context] = contextChannels;
        }

        channelsObject[context].lastUpdateDate = this.getUtcDate();

        return this.storageService.set(this.localStorageChannelsKey, channelsObject);
      });
  }

  getChannelsObjectFromLocalStorage(): Promise<ChannelsContextMap> {
    return this.storageService.get(this.localStorageChannelsKey);
  }

  getUtcDate(): string {
    const date = new Date();
    return `${date.getUTCDate()}-${date.getUTCMonth()}-${date.getUTCFullYear()}`;
  }

  // return channels object either from local memory, local storage or server
  getChannels(forceFetch?: boolean, contextKey?: string): Promise<ChannelsMap> {

    const context: string = contextKey || this.getCurrentContext();

    if (!context) {
      return Promise.reject('No context specified');
    }

    if (forceFetch) {
      // return channels object from server
      return this.fetchChannels(context);
    }

    if (!contextKey || contextKey === this.getCurrentContext()) {
      // return channels object for the current context from memory
      if (this.channels.value) {
        return Promise.resolve(this.channels.value);
      }
    }

    this.loadingChannelsFromServerPromise = this.getChannelsObjectFromLocalStorage()
      .then((channelsObject: ChannelsContextMap) => {
        return this._cacheChannels(channelsObject, context);
      }).then(channels => {
        if (channels) {
          return channels;
        }
        return this.fetchChannels(context);
      })
      .catch(err => {
        console.log(err);
        return Promise.reject(err);
      }).finally(() => {
        this.loadingChannelsFromServerPromise = null;
      });

    return this.loadingChannelsFromServerPromise;
  }

  private getCurrentContext(): string {
    return this.contextService.currentContext.key;
  }
}
