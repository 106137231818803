export enum ContentType {
  DIAGNOSIS = 'Diagnosis',
  PROCEDURE = 'Procedure',
  APPROACH = 'Approach',
  PREPARATION = 'Preparation'
}

export interface Approach {
  _id: string;
  aoId?: string;
  contentType: ContentType;
  description?: string;
  imageUrl: string;
  lastChecked?: string;
  memberOnly: boolean;
  primaryTopic?: string;
  secondaryTopic?: string;
  title: string;
  url: string;
}

export interface Preparation {
  _id: string;
  aoId?: string;
  contentType: ContentType;
  description?: string;
  imageUrl: string;
  lastChecked?: string;
  memberOnly: boolean;
  primaryTopic?: string;
  secondaryTopic?: string;
  title: string;
  url: string;
}

export interface Fracture {
  _id: string;
  aoId?: string;
  contentType: ContentType;
  description?: string;
  diagnosis?: string;
  fractureCode1: string;
  fractureCode2?: string;
  imageUrl: string;
  lastChecked?: string;
  memberOnly: boolean;
  originalFractureCode?: string;
  primaryTopic?: string;
  secondaryTopic?: string;
  title: string;
  url: string;
}

export interface Treatment {
  aoId?: string;
  contentType: ContentType;
  description?: string;
  diagnosis?: string;
  fractureCode1: string;
  fractureCode2?: string;
  imageUrl: string;
  lastChecked?: string;
  memberOnly: boolean;
  originalFractureCode?: string;
  parentId?: string;
  primaryTopic?: string;
  secondaryTopic?: string;
  title: string;
  url: string;
  _id: string;
}

export interface FractureAndTreatment {
  fracture: Fracture,
  treatment?: Treatment
}
