import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-case-library-filter-dialog',
  templateUrl: './case-library-filter-dialog.component.html',
  styleUrls: ['./case-library-filter-dialog.component.scss'],
})
export class CaseLibraryFilterDialogComponent {

  @Input() filters: Array<string>;
  @Input() selectedFilter: string;

  constructor(
    private modalController: ModalController
  ) { }

  selectedFilterChanged(selectedFilter: string): void {
    this.selectedFilter = selectedFilter;

    if (this.selectedFilter) {
      this.close();
    }
  }

  close(): void {
    this.modalController.dismiss(this.selectedFilter);
  }
}
