import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-outlink-label',
  templateUrl: './outlink-label.component.html',
  styleUrls: ['./outlink-label.component.scss'],
})
export class OutlinkLabelComponent {

  @Input() labelText: string;
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  labelClicked(): void {
    this.clicked.emit();
  }

}
