import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IonNav, ModalController } from '@ionic/angular';
import { FractureSelectPage } from 'src/app/dialogs/surgery-reference/fracture-select/fracture-select.page';
import { ReviewPage } from 'src/app/dialogs/surgery-reference/review/review.page';
import {
  SurgeryReferenceDialogComponent, SurgeryReferenceDialogData
} from 'src/app/dialogs/surgery-reference/surgery-reference-dialog/surgery-reference-dialog.component';
import { TreatmentSelectPage } from 'src/app/dialogs/surgery-reference/treatment-select/treatment-select.page';
import { ResponsiveUtilsService } from '../utils/responsive-utils.service';
import { TimeoutService } from '../utils/timeout.service';
import {
  SurgeryReferenceDialogPage,
  SurgeryReferenceUIServiceInterface
} from './surgery-reference-ui.service.interface';

// models
import {
  Approach,
  ContentType,
  Fracture,
  FractureAndTreatment,
  Preparation,
  Treatment
} from '../yeti-protocol/surgery-reference-schema';
import {
  DeleteFractureResponse,
  DeleteFractureSuccessResponse
} from '../yeti-protocol/surgery-reference';

// services
import { SURGERY_REFERENCE_SERVICE } from './surgery-reference.service.interface';
import { SurgeryReferenceService } from './surgery-reference.service';
import { ToastMode, ToastService } from '../toast.service';
import { Observable, Subject } from 'rxjs';
import { ClinicalCaseService } from '../case-library/clinical-case.service';

type PagesLookup = { [key: string]: any };

@Injectable({
  providedIn: 'root'
})
export class SurgeryReferenceUIService implements SurgeryReferenceUIServiceInterface {

  nav: IonNav = null;
  pagesLookup: PagesLookup;
  preventDialogOpening = false;
  private static dialogOpeningPromise: Promise<void> = null;
  private dialogClosedSubject: Subject<void> = new Subject();

  constructor(
    @Inject(SURGERY_REFERENCE_SERVICE) private surgeryReferenceService: SurgeryReferenceService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private clinicalCaseService: ClinicalCaseService,
    private modalController: ModalController,
    private timeoutService: TimeoutService,
    public router: Router,
    private toast: ToastService
  ) {
    this.pagesLookup = this.buildPagesLookup();
  }

  buildPagesLookup(): PagesLookup {
    const lookup: PagesLookup = {};
    lookup[SurgeryReferenceDialogPage.FractureSelect] = FractureSelectPage;
    lookup[SurgeryReferenceDialogPage.TreatmentSelect] = TreatmentSelectPage;
    lookup[SurgeryReferenceDialogPage.Review] = ReviewPage;
    return lookup;
  }

  openDialog(
    startPage: SurgeryReferenceDialogPage,
    rootParams: SurgeryReferenceDialogData = null): Promise<void> {

    if (this.preventDialogOpening) {
      return Promise.resolve();
    }
    const RootPage = this.pagesLookup[startPage];

    let cssClass = 'surgery-reference-dialog';

    if (!this.responsiveUtilsService?.isDesktop) {
      cssClass = `${cssClass} mobile`;
    }

    SurgeryReferenceUIService.dialogOpeningPromise = this.modalController.create({
      component: SurgeryReferenceDialogComponent,
      componentProps: {
        rootPage: RootPage,
        rootParams: rootParams
      },
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: cssClass
    }).then(modalEl => {
      return modalEl.present();
    });
    return SurgeryReferenceUIService.dialogOpeningPromise;
  }

  closeDialog(pageUrl?: string): Promise<void> {
    let promise: Promise<void>;
    if (SurgeryReferenceUIService.dialogOpeningPromise) {
      promise = SurgeryReferenceUIService.dialogOpeningPromise.then(() => {
        return this.modalController.dismiss().then(() => {
          return Promise.resolve();
        });
      }).then(() => {
        SurgeryReferenceUIService.dialogOpeningPromise = null;
      });
    } else {
      promise = Promise.resolve();
    }
    return promise.then(() => {
      this.preventDialogOpening = true;
      this.timeoutService.setTimeout(() => {
        this.preventDialogOpening = false;
      }, 1000)
      if (pageUrl) {
        return this.router.navigateByUrl(pageUrl);
      }
    })
      .then(() => {
        this.dialogClosedSubject.next();
        return Promise.resolve();
      });
  }

  navigate(page: SurgeryReferenceDialogPage): Promise<any> {
    const Page = this.pagesLookup[page];
    if (this.nav && this.nav.push && Page) {
      return this.nav.push(Page);
    }
    return Promise.reject(`Page is not found: ${page}`);
  }

  async removeModule(item: Fracture | Treatment | Approach | Preparation | FractureAndTreatment, clinicalCaseId: string): Promise<void> {

    try {
      if ((item as any)?.fracture && (item as any)?.treatment) { // Treatment but remove fracture also
        await this.surgeryReferenceService.deleteFracture(
          clinicalCaseId,(item as FractureAndTreatment)?.fracture?._id)
          .then((response: DeleteFractureResponse) => {
            this.clinicalCaseService.emitClinicalCaseUpdated((response as DeleteFractureSuccessResponse)?.result);
          });
      } else {
        switch ((item as any).contentType) {
          case ContentType.DIAGNOSIS: // Fracture
            await this.surgeryReferenceService.deleteFracture(clinicalCaseId, (item as Fracture)?._id)
            .then((response: DeleteFractureResponse) => {
              this.clinicalCaseService.emitClinicalCaseUpdated((response as DeleteFractureSuccessResponse)?.result);
            });
            break;
          case ContentType.APPROACH:
            await this.surgeryReferenceService.deleteApproach(clinicalCaseId);
            break;
          case ContentType.PREPARATION:
            await this.surgeryReferenceService.deletePreparation(clinicalCaseId);
            break;
        }
      }
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    }
  }

  get dialogClosedObservable(): Observable<void> {
    return this.dialogClosedSubject.asObservable();
  }
}
