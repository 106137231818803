// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-member-container {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 2fr auto;
}
.group-member-container app-user-info {
  grid-column: 1;
}
.group-member-container app-settings-button, .group-member-container ion-button {
  grid-column: 2;
}
.group-member-container ion-button {
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
  font-size: var(--mi-font-size-smaller);
  font-weight: var(--font-weight-semi-bold);
  --border-radius: 4px;
  width: 112px;
  height: 32px;
  align-self: center;
  margin-left: 10px;
}
.group-member-container ion-button.isFollower {
  --background: var(--mi-color-text-lite);
  --border-color: var(--mi-color-text-lite);
  --custom-background-hover-color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
