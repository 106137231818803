import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetConfigGroups, GroupActionsVisibility, MiCardActionsObjectType } from 'src/app/services/mi-card-actions.service';

// services
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import { GroupSearch } from 'src/app/services/yeti-protocol/search';
import { ItemTypes, RecommendationsBookmarksService } from 'src/app/services/recommendations-service/recommendations-bookmarks.service';
import { ToastService } from 'src/app/services/toast.service';
import { GroupListItem } from 'src/app/services/yeti-protocol/chatter-api';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-group-details-card-search',
  templateUrl: './group-details-card-search.component.html',
  styleUrls: ['./group-details-card-search.component.scss'],
})
export class GroupDetailsCardSearchComponent implements OnInit {

  @Input() group: GroupSearch;
  user: UserProfile;
  miCardActionsActionSheetConfig: ActionSheetConfigGroups;
  groupActionsVisibility: GroupActionsVisibility;
  infoSheetId = 'group-details-card-search';

  constructor(
    private router: Router,
    private authService: AuthService,
    private sharingUIService: SharingUIService,
    private recommendationsService: RecommendationsBookmarksService,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });

    if (this.group) {
      this.group.imageUrl = this.group.imageUrl || 'assets/images/default-groups-image.svg';
    }

    this.groupActionsVisibility = {
      bookmark: false,
      translate: false
    };

    this.miCardActionsActionSheetConfig = {
      objectType: MiCardActionsObjectType.group,
      object: this.group,
      actionsVisibilityConfig: this.groupActionsVisibility
    };
  }

  goToGroup(): void {
    if (this.group?.appId && this.group?._id) {
      const url = '/' + [this.group?.appId, 'group', this.group?._id].join('/');
      this.router.navigate([url], { queryParams: { source: ActionSource.searchResult } });
    }
  }

  shareGroup(): void {
    this.sharingUIService.showShareDialogForObject(this.group);
  }

  bookmark(): void {
    this.recommendationsService.addRecommendationBookmarkItem('group', ItemTypes.BOOKMARK, this.group._id)
      .then(async response => {
        if (response && response.success) {
          this.toast.show('app.common.bookmarked');
        }
      });
  }

  recommendClicked(group: GroupListItem): void {
    this.recommendationsService.recommendClicked(group, this.user)
  }
}
