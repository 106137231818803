// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-pdf-view-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
}
.mobile-pdf-view-container .top-bar-container {
  grid-row: 1;
  grid-column: 1;
}
.mobile-pdf-view-container .pdf-document-view {
  grid-row: 2;
  grid-column: 1;
  position: relative;
}
.mobile-pdf-view-container .pdf-document-view pdf-viewer {
  width: 100%;
  height: 100%;
}
.mobile-pdf-view-container .pdf-document-view .zoom-buttons-container {
  position: absolute;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-template-rows: auto;
  -moz-column-gap: 20px;
       column-gap: 20px;
  top: calc(100% - (60px + var(--ion-safe-area-bottom)));
  left: calc(100% - (115px + var(--ion-safe-area-right)));
}
.mobile-pdf-view-container .pdf-document-view .zoom-buttons-container app-icon-button {
  --icon-button-background-width: 40px;
  --icon-button-background-height: 40px;
  --icon-button-background-color: transparent;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 40px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
