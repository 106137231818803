// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --list-content-padding: 20px;
}

.clinical-case-shared-with-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  row-gap: 20px;
}
.clinical-case-shared-with-dialog-container .header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  background-color: var(--mi-white-color);
}
.clinical-case-shared-with-dialog-container .header app-top-bar {
  --top-bar-padding-top: 30px;
  --top-bar-padding-bottom: 12px;
  --top-bar-padding-start: 23px;
  --top-bar-padding-end: 23px;
}
.clinical-case-shared-with-dialog-container .header app-top-bar .confirm-participants-selected-button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clinical-case-shared-with-dialog-container .header app-top-bar .confirm-participants-selected-button span {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-primary-color);
}
.clinical-case-shared-with-dialog-container .header app-top-bar .confirm-participants-selected-button.disabled span {
  color: var(--mi-grey-color-11);
}
.clinical-case-shared-with-dialog-container .header app-top-bar ::ng-deep ion-header::after {
  bottom: -90px;
}
.clinical-case-shared-with-dialog-container .header .tabs-container {
  display: inline-block;
  margin: 0 auto;
}
.clinical-case-shared-with-dialog-container .content {
  grid-row: 2;
  grid-column: 1;
}
.clinical-case-shared-with-dialog-container .content .tab-content {
  width: 100%;
  height: 100%;
  display: none;
}
.clinical-case-shared-with-dialog-container .content .tab-content.active {
  display: block;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list {
  width: 100%;
  height: 100%;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content {
  --padding-top: var(--list-content-padding);
  --padding-bottom: var(--list-content-padding);
  --padding-start: var(--list-content-padding);
  --padding-end: var(--list-content-padding);
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 20px;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item {
  width: 100%;
  display: grid;
  grid-template-columns: min-content minmax(0, 100%) min-content;
  grid-template-rows: auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .contact-image-container {
  grid-row: 1;
  grid-column: 1;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .contact-image-container app-user-profile-image {
  --user-image-size: 40px;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .contact-info-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  align-items: center;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .contact-info-container .contact-fullname-container {
  width: 100%;
  height: 18.5px;
  position: relative;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .contact-info-container .contact-fullname-container .wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .contact-info-container .contact-fullname-container .wrapper .contact-fullname {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-black-color);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .contact-info-container .contact-country-container {
  width: 100%;
  height: 18.5px;
  position: relative;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .contact-info-container .contact-country-container .wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .contact-info-container .contact-country-container .wrapper .contact-country {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .button-container {
  grid-row: 1;
  grid-column: 3;
}
.clinical-case-shared-with-dialog-container .content .contacts-container .contacts-list ion-content .wrapper .contact-item .button-container app-icon-button {
  --icon-button-background-width: 18px;
  --icon-button-background-height: 18px;
  --icon-button-background-color: transparent;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 18px;
  --icon-button-icon-color: var(--mi-color-text-lite);
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list {
  width: 100%;
  height: 100%;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content {
  --padding-top: var(--list-content-padding);
  --padding-bottom: var(--list-content-padding);
  --padding-start: var(--list-content-padding);
  --padding-end: var(--list-content-padding);
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 20px;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper .group-item {
  width: 100%;
  display: grid;
  grid-template-columns: min-content minmax(0, 100%) min-content;
  grid-template-rows: auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper .group-item .group-image-container {
  grid-row: 1;
  grid-column: 1;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper .group-item .group-image-container app-group-image {
  --group-image-size: 40px;
  --group-image-border-radius: 7px;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper .group-item .group-info-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  align-items: center;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper .group-item .group-info-container .group-title-container {
  width: 100%;
  height: 18.5px;
  position: relative;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper .group-item .group-info-container .group-title-container .wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper .group-item .group-info-container .group-title-container .wrapper .group-title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-black-color);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper .group-item .group-info-container .group-visibility-container .group-visibility {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clinical-case-shared-with-dialog-container .content .groups-container .groups-list ion-content .wrapper .group-item .button-container {
  grid-row: 1;
  grid-column: 3;
}

app-skeleton-loader {
  padding: 20px;
}

.empty-state-container {
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-state-container .empty-state {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 15px;
  justify-content: center;
  align-items: center;
  max-width: 285px;
}
.empty-state-container .empty-state .icon-container {
  grid-row: 1;
  grid-column: 1;
}
.empty-state-container .empty-state .icon-container app-icon {
  --icon-size: 97px;
}
.empty-state-container .empty-state .text-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
}
.empty-state-container .empty-state .text-container p {
  margin: 0;
  text-align: center;
}
.empty-state-container .empty-state .text-container .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  color: var(---mi-black-color-2);
}
.empty-state-container .empty-state .text-container .text {
  font-size: var(--font-size-extra-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
