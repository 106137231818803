import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, map, throwError } from 'rxjs';
import appConfig from 'src/config/config';
import {
  AO_AUTH_SERVICE,
  AOAuthServiceInterface,
} from '../auth/ao/ao-auth.service.interface';
import { AuthService } from '../auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import { LinkOpenerService } from '../link-opener.service';
import { SchemaValidatorService } from '../schema-validator.service';
import {
  Article,
  ArticleFulltextInfo,
  ArticleType,
  GetArticleFulltextLinkResponse,
} from '../yeti-protocol/article';
import { isMobilePlatform } from '../utils/utils';
import { Platform } from 'src/config/config.model';

interface FulltextArticleServiceInterface {
  backendUrlIonic: string;
  platform: Platform;
}

@Injectable()
export class FulltextArticleService {
  config: FulltextArticleServiceInterface = appConfig;

  constructor(
    private linkOpenerService: LinkOpenerService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService,
    @Inject(AO_AUTH_SERVICE) private aoAuthService: AOAuthServiceInterface
  ) { }

  isFullText(object: ArticleType): boolean {
    return !!object?.isFullMemberAccess;
  }

  openFullTextArticle(article: Article): Promise<void> {
    if (!this.isFullText(article)) {
      return Promise.reject('not full text article');
    }
    return this._fetchFulltextLink(article._id).then(res => {
      if (res.provider === 'direct') {
        this.linkOpenerService.open(res.url);
      } else if (res.provider === 'ao') {
        if (res?.url) {
          this.authService.openAoLink(res?.url);
        }
      } else {
        console.error(`not recognised fulltext link provider: ${res.provider}`);
      }
    });
  }

  get isMobile(): boolean {
    return isMobilePlatform(this.config.platform);
  }

  _fetchFulltextLink(articleId: string): Promise<ArticleFulltextInfo> {
    const url = `${this.config.backendUrlIonic}articles/fulltext/${articleId}`;
    return firstValueFrom(
      this.authService
        .secureGet<GetArticleFulltextLinkResponse>(url, {
          params: {
            appId: this.contextService.currentContext.key,
          },
        })
        .pipe(
          this.schemaValidator.isValidOperator<GetArticleFulltextLinkResponse>(
            'GetArticleFulltextLinkResponse'
          ),
          map((res: GetArticleFulltextLinkResponse) => {
            if (res.success) {
              return res.result;
            } else {
              throwError(() => 'failed fetch fulltext link');
            }
          })
        )
    );
  }
}
