import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Inject, Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ACTIVE_CONVERSATIONS_STATE_KEY } from '../state-constants';
import { ActiveConversationsStateModel } from './active-conversations.model';
import { ConnectionsApiService } from 'src/app/services/messenger/connections-api.service';
import { Conversation, ConversationType, ConversationsSuccessResponse } from 'src/app/services/yeti-protocol/conversation';
import { ActiveConversations, ArrayLocation } from './active-conversations.actions';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';

const ACTIVE_CONVERSATIONS_STATE_TOKEN = new StateToken<ActiveConversationsStateModel>(
  ACTIVE_CONVERSATIONS_STATE_KEY
);

@State({
  name: ACTIVE_CONVERSATIONS_STATE_TOKEN,
  defaults: {
    activeConversations: [],
    totalCount: 0,
    loading: false,
    totalNotRead: 0,
    totalOpenConnectionRequests: 0
  },
})
@Injectable()
export class ActiveConversationsState {
  constructor(
    private connectionsApiService: ConnectionsApiService,
    private appTranslationService: AppTranslationService,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService) { }

  @Selector()
  static state(state: ActiveConversationsStateModel): ActiveConversationsStateModel {
    return state;
  }

  @Selector()
  static activeConversations(state: ActiveConversationsStateModel): Array<Conversation> {
    return state.activeConversations;
  }

  @Selector()
  static totalCount(state: ActiveConversationsStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static loading(state: ActiveConversationsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static currentActiveConversationId(state: ActiveConversationsStateModel): string {
    return state.currentActiveConversationId;
  }

  @Action(ActiveConversations.FetchActiveConversations)
  async fetchActiveConversations(
    ctx: StateContext<ActiveConversationsStateModel>,
    payload: ActiveConversations.FetchActiveConversations
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      const activeConversationsData = (await this.connectionsApiService.getActiveConversations(
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize,
      )) as ConversationsSuccessResponse;

      ctx.dispatch(
        new ActiveConversations.FetchActiveConversationsSuccess(
          payload?.payloadParams,
          activeConversationsData
        )
      );
    } catch (err) {
      ctx.dispatch(
        new ActiveConversations.FetchActiveConversationsFailed()
      );

      throw err;
    }
  }

  @Action(ActiveConversations.FetchActiveConversationsSuccess)
  fetchActiveConversationsSuccess(
    ctx: StateContext<ActiveConversationsStateModel>,
    action: ActiveConversations.FetchActiveConversationsSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        activeConversations: action.response.result,
        totalCount: action.response.totalItemsCount,
        totalNotRead: action.response.totalNotRead || 0,
        totalOpenConnectionRequests: action.response.totalOpenConnectionRequests || 0,
        loading: false,
      });
    } else {
      ctx.patchState({
        activeConversations: [...state.activeConversations, ...action.response.result],
        totalCount: action.response.totalItemsCount,
        totalNotRead: action.response.totalNotRead || 0,
        totalOpenConnectionRequests: action.response.totalOpenConnectionRequests || 0,
        loading: false,
      });
    }
  }

  @Action(ActiveConversations.FetchActiveConversationsFailed)
  fetchActiveConversationsFailed(
    ctx: StateContext<ActiveConversationsStateModel>
  ): void {
    ctx.patchState({
      loading: false,
    });
  }

  @Action(ActiveConversations.InsertActiveConversationBeforeIndex)
  insertActiveConversationBeforeIndex(
    ctx: StateContext<ActiveConversationsStateModel>,
    action: ActiveConversations.InsertActiveConversationBeforeIndex): void {
    ctx.setState(
      patch<ActiveConversationsStateModel>({
        activeConversations: insertItem<Conversation>(action.activeConversation, action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount + 1,
    });
  }

  @Action(ActiveConversations.RemoveActiveConversation)
  removeActiveConversation(
    ctx: StateContext<ActiveConversationsStateModel>,
    action: ActiveConversations.RemoveActiveConversation): void {
    ctx.setState(
      patch<ActiveConversationsStateModel>({
        activeConversations: removeItem<Conversation>(activeConversation => activeConversation._id === action.activeConversation._id)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount - 1,
    });
  }

  @Action(ActiveConversations.UpdateActiveConversation)
  updateActiveConversation(
    ctx: StateContext<ActiveConversationsStateModel>,
    action: ActiveConversations.UpdateActiveConversation): void {
    ctx.setState(
      patch<ActiveConversationsStateModel>({
        activeConversations: updateItem<Conversation>(ActiveConversation =>
          ActiveConversation._id === action.activeConversation._id, action.activeConversation)
      })
    );
  }

  @Action(ActiveConversations.AddNewConversationFromConversationId)
  async addNewConversationFromConversationId(
    ctx: StateContext<ActiveConversationsStateModel>,
    action: ActiveConversations.AddNewConversationFromConversationId): Promise<void> {

    const state = ctx.getState();
    const conversationIndex = state.activeConversations.findIndex(conversation => conversation?._id === action.conversationId);

    if (conversationIndex === -1) {
      try {

        const conversation = await this.connectionsApiService.getActiveConversation(action.conversationId);

        switch (action.location) {
          case ArrayLocation.START:
            ctx.setState(
              patch<ActiveConversationsStateModel>({
                activeConversations: [conversation, ...state.activeConversations]
              })
            );
            break;
          case ArrayLocation.END:
            ctx.setState(
              patch<ActiveConversationsStateModel>({
                activeConversations: [...state.activeConversations, conversation]
              })
            );
            break;
        }
      } catch (err) {
        throw err;
      }
    }
  }

  @Action(ActiveConversations.UpdateActiveConversationsBasedOnNewMessage)
  async updateActiveConversationsBasedOnNewMessage(
    ctx: StateContext<ActiveConversationsStateModel>,
    action: ActiveConversations.UpdateActiveConversationsBasedOnNewMessage): Promise<void> {

    const state = ctx.getState();

    const conversationIndex = state.activeConversations.findIndex(conversation => conversation?._id === action.message?.connectionId);

    if (conversationIndex === -1) {
      ctx.dispatch(
        new ActiveConversations.AddNewConversationFromConversationId(
          action.message?.connectionId,
          ArrayLocation.START
        )
      );

      return;
    }

    const updatedConversation = { ...state.activeConversations[conversationIndex] };
    let conversations = [...state.activeConversations];
    let unreadConversationsCount = state.totalNotRead;

    if (action.message.deleted) {
      if (stripHtmlTagsFromString(action.message.message) !== stripHtmlTagsFromString(updatedConversation.message)) {
        return; // not the last message
      }
      updatedConversation.deleted = action.message.deleted;
      updatedConversation.message = this.appTranslationService.instant('app.messenger.message-deleted');
    } else {
      updatedConversation.deleted = action.message.deleted;
      updatedConversation.message = action.message.message;
    }
    updatedConversation.type = action.message.type as ConversationType;
    updatedConversation.createdDate = action.message.createdDate;

    const contextKey = this.contextService.currentContext.key;
    const user = await this.authService.getProfile(contextKey);

    if (user?.id && action.message?.sender?.userId) {
      updatedConversation.isSender = user?.id === action.message?.sender?.userId;
    }

    if (updatedConversation._id !== state.currentActiveConversationId) {

      if (updatedConversation.read) {
        unreadConversationsCount += 1;
      }

      updatedConversation.read = false;
    }

    if (conversationIndex > 0) {

      conversations.splice(conversationIndex, 1);
      conversations.unshift(updatedConversation);
    }

    conversations = conversations.map(conversation => {

      if (conversation._id === updatedConversation._id) {
        return updatedConversation;
      } else {
        return conversation;
      }
    })

    ctx.setState(
      patch<ActiveConversationsStateModel>({
        activeConversations: conversations,
        totalNotRead: unreadConversationsCount
      })
    );
  }

  @Action(ActiveConversations.UpdateCurrentActiveConversationId)
  updateCurrentActiveConversationId(
    ctx: StateContext<ActiveConversationsStateModel>,
    action: ActiveConversations.UpdateCurrentActiveConversationId): void {

    ctx.setState(
      patch<ActiveConversationsStateModel>({
        currentActiveConversationId: action.currentActiveConversationId
      })
    );

    ctx.dispatch(new ActiveConversations.MarkActiveConversationAsRead(action.currentActiveConversationId));
  }

  @Action(ActiveConversations.MarkActiveConversationAsRead)
  markActiveConversationAsRead(
    ctx: StateContext<ActiveConversationsStateModel>,
    action: ActiveConversations.MarkActiveConversationAsRead): void {

    const state = ctx.getState();

    const conversations = [...state.activeConversations];
    const conversationIndex = conversations.findIndex(conversation => conversation._id === action.conversationId);
    let unreadConversationsCount = state.totalNotRead;

    if (conversationIndex >= 0) {
      const conversation = { ...state.activeConversations[conversationIndex] };

      if (conversation && !conversation?.read) {

        conversation.read = true;
        conversations[conversationIndex] = conversation;

        unreadConversationsCount -= 1;

        ctx.setState(
          patch<ActiveConversationsStateModel>({
            activeConversations: conversations,
            totalNotRead: unreadConversationsCount
          })
        );
      }
    }
  }

  @Action(ActiveConversations.UpdateActiveConversationBasedOnConnectionChange)
  updateActiveConversationBasedOnConnectionChange(
    ctx: StateContext<ActiveConversationsStateModel>,
    action: ActiveConversations.UpdateActiveConversationBasedOnConnectionChange): void {

    const state = ctx.getState();

    if (action.connection.status === 'disconnected') {

      const conversations = [...state.activeConversations];
      const conversationIndex = conversations.findIndex(conversation => conversation._id === action.connection?._id);

      if (conversationIndex >= 0) {
        ctx.dispatch(new ActiveConversations.RemoveActiveConversation(conversations[conversationIndex]));
      }
    } else if (action.connection.status === 'connected') {

      const conversations = [...state.activeConversations];
      const conversationIndex = conversations.findIndex(conversation => conversation._id === action.connection?._id);

      if (conversationIndex > -1) {

        const conversation = { ...conversations[conversationIndex] };
        conversations.splice(conversationIndex, 1);

        conversation.type = ConversationType.TEXT;
        conversation.message = this.appTranslationService.instant('app.messenger.active-conversations-connection-request-accepted');
        conversation.createdDate = action.connection.modifiedDate;

        conversations.unshift(conversation);

        ctx.setState(
          patch<ActiveConversationsStateModel>({
            activeConversations: conversations
          })
        );
      }
    }
  }
}
