import { Component, Inject, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { AOEvent } from 'src/app/services/yeti-protocol/event';

@Component({
  selector: 'app-top-recommended-event',
  templateUrl: './top-recommended-event.component.html',
  styleUrls: ['./top-recommended-event.component.scss'],
})
export class TopRecommendedEventComponent {

  @Input() event: AOEvent;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService
  ) { }

  openEvent(): void {

    let url;

    if (this.event?.website) {
      url = this.event?.website;
    } else {
      url = this.contextService?.currentContext?.moreEventsLink;
    }

    if (url) {
      this.authService.openAoLink(url);
    }
  }
}
