// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --attachments-upload-stataus-container-width: 100%;
  --attachments-upload-stataus-container-height: 100%;
}

.attachments-upload-stataus-container {
  width: var(--attachments-upload-stataus-container-width);
  height: vara(--attachments-upload-stataus-container-height);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
