import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
})

export class FilterButtonComponent extends BaseButtonComponent<void> {
  @Input() selectedFilter: string;
}
