import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeleteProfileResponse, UpdateUserProfileData, UserProfile } from '../yeti-protocol/auth/mi';
import { AuthProvider } from '../yeti-protocol/auth/provider';
import {
  AUTH_LOGIC_SERVICE,
  AuthLogicServiceInterface,
  AuthRequestOptions,
  RedirectResult,
  SignInData,
  SignInResult,
  SignUpData,
  SignUpStatus
} from './logic/auth-logic.service.interface';
import { ActionSource } from '../yeti-protocol/tracking';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // notifies abount sign
  signedInSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // notifies abount signout
  signedOutSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    @Inject(AUTH_LOGIC_SERVICE) private authLogic: AuthLogicServiceInterface,
  ) {
  }

  get signedIn(): Observable<boolean> {
    return this.signedInSubject.asObservable();
  }

  get signedOut(): Observable<boolean> {
    return this.signedOutSubject.asObservable();
  }

  isSignedIn(): Promise<boolean> {
    return this.authLogic.isSignedIn();
  }

  asserIsSignedIn(): Promise<void> {
    return this.isSignedIn().then(isSignedIn => {
      if (!isSignedIn) {
        return Promise.reject('user is not logged in');
      }
    });
  }

  get isSignedInAsObservable(): Observable<boolean> {
    return this.authLogic.isSignedInAsObservable;
  }

  get userProfileAsObservable(): Observable<UserProfile> {
    return this.authLogic.userProfileAsObservable;
  }

  get userAdminGroupsAsObservable(): Observable<Array<string>> {
    return this.authLogic.userAdminGroupsAsObservable;
  }

  get accessBlockedAsObservable(): Observable<boolean> {
    return this.authLogic.accessBlockedAsObservable;
  }

  signIn(provider: AuthProvider, data?: SignInData): Promise<SignInResult> {
    return this.authLogic.signIn(provider, data).then(res => {
      this.signedInSubject.next(true);
      return res;
    });
  }

  signUp(provider: AuthProvider, data: SignUpData): Promise<any> {
    return this.authLogic.signUp(provider, data).then(res => {
      this.signedInSubject.next(true);
      return res;
    });
  }

  signOut(): Promise<RedirectResult> {
    return this.authLogic.signOut().then(res => {
      this.signedOutSubject.next(true);
      return res;
    });
  }

  getSignupStatus(context: string): Promise<SignUpStatus> {
    return this.authLogic.getSignupStatus(context);
  }

  resetPassword(email: string): Promise<boolean> {
    return this.authLogic.resetPassword(email);
  }

  updateProfile(context: string, data: UpdateUserProfileData, withoutCaching = false,
    source: ActionSource = ActionSource.unspecified): Promise<UserProfile> {
    return this.authLogic.updateProfile(context, data, withoutCaching, source);
  }

  deleteProfile(context: string): Promise<DeleteProfileResponse> {
    return this.authLogic.deleteProfile(context);
  }

  getProfile(context: string, withoutCaching = false, preventReloadBellowXMs?: number): Promise<UserProfile> {
    return this.authLogic.getProfile(context, withoutCaching, preventReloadBellowXMs);
  }

  reloadProfile(context: string): Promise<UserProfile> {
    return this.authLogic.reloadProfile(context);
  }

  securePost<D, T>(url: string, postData: D, options?: AuthRequestOptions): Observable<T> {
    return this.authLogic.securePost(url, postData, options);
  }

  secureGet<T>(url: string, options?: AuthRequestOptions): Observable<T> {
    return this.authLogic.secureGet<T>(url, options);
  }

  secureDelete<D, T>(url: string, deleteData: D, options?: AuthRequestOptions): Observable<T> {
    return this.authLogic.secureDelete(url, deleteData, options);
  }

  securePut<D, T>(url: string, putData: D, options?: AuthRequestOptions): Observable<T> {
    return this.authLogic.securePut(url, putData, options);
  }

  fakeSignInExpire(): Promise<void> {
    return this.authLogic.fakeSignInExpire();
  }

  openAoLink(url: string): Promise<void> {
    return this.authLogic.openAoLink(url);
  }
}
