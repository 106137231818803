import { MyStreamSuccessResponse, ResponseStreamItem } from 'src/app/services/yeti-protocol/my-stream';

export namespace MyNetworkStreamTop {
  export interface FetchStreamItemsPayload {
    filter?: string;
    pageIndex?: number;
    pageSize?: number;
  }

  export class FetchMyNetworkStreamTopItems {
    static readonly type = '[My Network Stream Top] Fetch my network stream top items';
    constructor(public payloadParams: FetchStreamItemsPayload) { }
  }

  export class FetchMyNetworkStreamTopItemsSuccess {
    static readonly type = '[My Network Stream Top] Fetch my network stream top items success';
    constructor(
      public payloadParams: FetchStreamItemsPayload,
      public response: MyStreamSuccessResponse
    ) { }
  }

  export class FetchMyNetworkStreamTopItemsFailed {
    static readonly type = '[My Network Stream Top] Fetch my network stream top items failed';
  }

  export class InsertMyNetworkStreamTopItemBeforeIndex {
    static readonly type = '[My Network Stream Top] Insert my network stream top item before index';
    constructor(
      public item: ResponseStreamItem,
      public index: number
    ) { }
  }

  export class RemoveMyNetworkStreamTopItem {
    static readonly type = '[My Network Stream Top] Remove my network stream top item at index';
    constructor(
      public item: ResponseStreamItem
    ) { }
  }

  export class UpdateMyNetworkStreamTopItem {
    static readonly type = '[My Network Stream Top] Update my network stream top item';
    constructor(
      public item: ResponseStreamItem
    ) { }
  }

  export class UpdateMyNetworkStreamTopItemsOwnerFollowingStatus {
    static readonly type = '[My Network Stream Top] Update my network stream top items owner following status';
    constructor(public userId: string, public isFollower: boolean) { }
  }
}
