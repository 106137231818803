// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.journal-container {
  padding: 5px 0px;
}
.journal-container .journal-img {
  --border-radius: 5px;
  width: 51px;
  height: 51px;
}
.journal-container .content-container {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 4px;
}
.journal-container .content-container .journal-title {
  padding-right: 5px;
  color: var(--mi-secondary-color-2);
  max-width: calc(100% - 15px);
  font-family: Suisse;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.journal-container .content-container .btn-container {
  display: flex;
  flex-direction: row;
}
.journal-container .content-container .btn-container .follow-btn {
  min-height: 25px;
  height: 25px;
  margin-right: 20px;
  --paddding-start-end: 15px;
}
.journal-container .content-container .btn-container app-text-chip {
  --color: var(--mi-secondary-color-2);
  --background-color: var(--mi-blue-color-4);
  --font-size: var(--font-size-tiny);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
