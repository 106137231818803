import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// models
import { AttachmentMimeType, ImageAttachment } from 'src/app/services/attachments.model';
import {
  CaseFileType
} from 'src/app/services/yeti-protocol/chatter-api';

// services
import { CreateGroupContentService } from 'src/app/services/create-content/group/create-group-content-service.service';
import { CaseClassificationFields } from
  'src/app/components/case-classification-tags-selection/case-classification-tags-selection.model';
import { IonContent, ModalController, Platform } from '@ionic/angular';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { ResponsiveUtilsService } from '../../services/utils/responsive-utils.service';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import {
  UIUtilsServiceInterface,
  UI_UTILS_SERVICE,
  GroupedImagesByStageOfTreatment
} from 'src/app/services/utils/ui-utils.service.interface';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FileType } from 'src/app/modules/file-select/file-select.model';
import { InputUsage } from '../../components/generic-input/generic-input.component';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import {
  CreateClinicalCaseRequest,
  UpdateClinicalCaseRequest
} from 'src/app/services/yeti-protocol/clinical-case';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { CaseImageUploadEditComponent } from '../../components/case-image-upload-edit/case-image-upload-edit.component';
import {
  PersonalMediaGalleryDocumentWithStageOfTreatment,
  StageOfTreatmentId
} from 'src/app/components/stage-of-treatment/stage-of-treatment.model';
import { CreateEditCasePostBottomBarConfig }
  from 'src/app/components/create-edit-case-post-bottom-bar/create-edit-case-post-bottom-bar.component';
import { CreateEditCasePostBottomBarComponent }
  from '../../components/create-edit-case-post-bottom-bar/create-edit-case-post-bottom-bar.component';
import appConfig from 'src/config/config';
import { isMobilePlatform } from 'src/app/services/utils/utils';
import { TutorialData, TutorialForFeature, TutorialService } from 'src/app/services/tutorial.service';
import { FieldMaxTextLengthExceeded } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { CaseTextDetailsFields } from 'src/app/components/case-text-details/case-text-details.component';
import { CaseDocumentsUploadEditPreviewComponent } from
  'src/app/components/case-documents-upload-edit-preview/case-documents-upload-edit-preview.component';
import { PersonalMediaGalleryDocument } from 'src/app/services/yeti-protocol/personal-media-gallery';
import {
  CaseReadinessIndicatorState,
  CaseReadinessStageState
} from '../../components/case-readiness-indicator/case-readiness-indicator.component';
import { CreateContentStrategy } from 'src/app/services/create-content/create-content';
import { IconLabelPosition } from 'src/app/modules/buttons/base-button/base-button.component';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';

const TUTORIAL_KEY = TutorialForFeature.CASE_FOLIO;

@Component({
  selector: 'app-create-group-case',
  templateUrl: './create-group-case.component.html',
  styleUrls: ['./create-group-case.component.scss'],
})
export class CreateGroupCaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('caseImageUploadEditComponent') caseImageUploadEditComponent: CaseImageUploadEditComponent;
  @ViewChild('caseDocumentsUploadEditPreviewComponent') caseDocumentsUploadEditPreviewComponent: CaseDocumentsUploadEditPreviewComponent;
  @ViewChild('createEditCasePostBottomBar') createEditCasePostBottomBarComponent: CreateEditCasePostBottomBarComponent;
  @ViewChild('ionContent') ionContent: IonContent;

  @Input() createContentStrategy: CreateContentStrategy;

  CaseFileType = CaseFileType;

  FileType = FileType;
  AttachmentMimeType = AttachmentMimeType;

  user: UserProfile;

  title = '';
  classificationLevelOne: string;
  classificationLevelTwo: string;
  creatingCase: boolean;
  caseTitleMaxLength = 90;
  InputUsage = InputUsage;
  caseImages: Array<PersonalMediaGalleryDocumentWithStageOfTreatment> = [];
  caseDocuments: Array<PersonalMediaGalleryDocument> = [];
  createEditCasePostBottomBarConfig: CreateEditCasePostBottomBarConfig;
  infoSheetElement: HTMLElement;
  tutorialInfo: TutorialData = null;
  tutorialKey = TUTORIAL_KEY;
  tutorialStep2CustomOverlay = '.tutorial-step-2-custom-overlay';
  showTutorialStep2CustomOverlay: boolean;
  caseReadinessIndicatorState: CaseReadinessIndicatorState = {
    stage1: CaseReadinessStageState.STATE_1,
    stage2: CaseReadinessStageState.STATE_1,
    stage3: CaseReadinessStageState.STATE_1
  };

  caseTextDetailsFields: CaseTextDetailsFields = {
    description: '',
    complainMedicalCondition: '',
    clinicalExam: '',
    notes: '',
    privateAnnotations: '',
    attachPrivateDocuments: () => { return this.showAttachPrivateDocumentsButton }
  };

  IconLabelPosition = IconLabelPosition;
  extractedUrlPath: string;

  private userSubscription: Subscription;
  private tutorialDataChangedSubscription: Subscription;
  private hardwareBackButtonSubscription: Subscription;

  constructor(
    private router: Router,
    private createGroupContentService: CreateGroupContentService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private navController: NavControllerService,
    private modalController: ModalController,
    private appTranslationService: AppTranslationService,
    private toast: ToastService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private authService: AuthService,
    private dialogs: DialogsUIService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private clinicalCaseService: ClinicalCaseService,
    public tutorialService: TutorialService,
    private el: ElementRef,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private platform: Platform,
  ) {
  }

  ngOnInit(): void {

    this.createEditCasePostBottomBarConfig = {
      images: {
        avaliable: () => { return true },
      },
      videos: {
        avaliable: () => { return true },
      },
      documents: {
        avaliable: () => { return false },
      },
      reorder: {
        avaliable: () => { return true },
        disabled: () => { return !this.areAllImagesTagedWithStageOfTreatment() },
        withBadge: () => { return true }
      },
      watermark: {
        avaliable: () => { return true },
        disabled: () => { return this.caseImageUploadEditComponent?.disableWatermarkButton },
        active: () => { return this.caseImageUploadEditComponent?.watermarked }
      },
      settings: {
        avaliable: (): boolean => { return false }
      },
    }

    this.userSubscription = this.authService?.userProfileAsObservable?.subscribe(userProfile => {
      this.user = userProfile;
      this.getTutorialInfo();
    });

    this.tutorialDataChangedSubscription = this.tutorialService.tutorialDataChangedObservable.subscribe(() => {
      this.getTutorialInfo();
    });

    const pathName = window.location.pathname;
    this.extractedUrlPath = pathName.split('/').slice(2).join('/');

    setTimeout(() => {
      document.addEventListener('mousedown', this.onBackdropClick);
    }, 0);

    this.hardwareBackButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, async () => {
      this.onBack();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initEditingCase();
      this.addImagesOrShowOptions();

      setTimeout(() => {
        this.infoSheetElement = document.querySelector('.info-sheet');
      }, 650);
    }, 0);
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.tutorialDataChangedSubscription?.unsubscribe();
    this.closeSettings();
    document.removeEventListener('mousedown', this.onBackdropClick);
    this.hardwareBackButtonSubscription?.unsubscribe();
  }

  async onBack(): Promise<void> {

    const shouldProceedWithBack = await this.continueIfStateIsNotSaved();

    if (shouldProceedWithBack?.role === 'confirm' || shouldProceedWithBack === true) {
      this.closeSettings();
      this.navController.back();
    }
  }

  initEditingCase(): void {

    if (this.createContentStrategy?.clinicalCase) {
      this.title = this.createContentStrategy?.clinicalCase?.title || '';
      this.classificationLevelOne = this.createContentStrategy?.clinicalCase?.classificationLevelOne;
      this.classificationLevelTwo = this.createContentStrategy?.clinicalCase?.classificationLevelTwo;

      if (!this.caseImages) {
        this.caseImages = [];
      }

      this.caseImages = [
        ...this.createContentStrategy?.clinicalCase.preOpDocuments || [],
        ...this.createContentStrategy?.clinicalCase.intraOpDocuments || [],
        ...this.createContentStrategy?.clinicalCase.postOpDocuments || [],
        ...this.createContentStrategy?.clinicalCase.clinicalFollowUpDocuments || []];
    }
  }

  addImagesOrShowOptions(): void {
    if (!this.isMobile) {
      this.addImages();
    } else {
      this.showSettings();
    }
  }

  // navigateToGroupPage(): void {
  //   this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/group/${this.group._id}`, { replaceUrl: true });
  // }

  onSave(): void {

    if (this.postSaveButtonDisabled) {
      this.showCaseCreateError();
    } else {
      this.createCase();
    }
  }

  showCaseCreateError(): void {

    const errors = [];
    const areAllImagesTagedWithStageOfTreatment = this.areAllImagesTagedWithStageOfTreatment();

    if (!this.title?.trim()?.length) {
      errors.push(this.appTranslationService.instant('app.groups.CreateGroupCase.error-missing-case-title'));
    }

    if (!this.classificationLevelOne?.trim()?.length) {
      errors.push(this.appTranslationService.instant('app.groups.CreateGroupCase.error-topic-not-selected'));
    }

    if (!this.classificationLevelTwo?.trim()?.length) {
      errors.push(this.appTranslationService.instant('app.groups.CreateGroupCase.error-topic-not-selected'));
    }

    if (!areAllImagesTagedWithStageOfTreatment) {
      errors.push(this.appTranslationService.instant('app.groups.CreateGroupCase.error-missing-treatment-tag'));
    }

    if (errors?.length === 1) {
      this.showError(errors[0], true);
    } else {
      this.showError(this.appTranslationService.instant('app.groups.CreateGroupCase.error-complete-required-actions'), true);
    }
  }

  async createCase(): Promise<void> {

    const textFieldsLengthNotGood = await this.checkTextFieldsMaxLengthExceeded();

    if (this.creatingCase || textFieldsLengthNotGood) {
      return;
    }

    if ((this.createContentStrategy?.parent as any)?.AOMembersOnly && !this.user?.isAOMember) {
      this.uiUtilsService.showErrorDialogOnCreatePostInAoOnlyGroup();
      return;
    }

    this.closeSettings();

    const caseData = this.createCaseRequestObject();

    const parentTypes = [];

    this.createContentStrategy?.parentsData?.forEach(parentsData => {
      parentTypes.push(parentsData.parentsType);
    });

    caseData.marketingCampaign = parentTypes?.join('_');

    if (this.extractedUrlPath) {
      caseData.source = this.trackingService.mapPathToActionSource(this.extractedUrlPath);
    }

    this.creatingCase = true;

    this.clinicalCaseService.createCase(caseData)
      .then(clinicalCase => {

        return Promise.all([
          clinicalCase,
          this.modalController?.dismiss({ customClose: true })
        ])
      })
      .then(results => {
        const [createdCase] = results;
        if (this.createContentStrategy?.shareCaseInPost) {
          this.createContentStrategy.openCreatePost(null, null, createdCase, true);
        } else {
          const context = this.contextService.currentContext.key;
          const route = `/${context}/case/${createdCase._id}`;
          this.router.navigateByUrl(route, { replaceUrl: true, state: { addSurgeryReference: true } });
        }
      })
      .catch(err => {
        this.showError(err?.message);
      })
      .finally(() => {
        this.creatingCase = false;
      });
  }

  createCaseRequestObject(): CreateClinicalCaseRequest {
    return this._buildCaseRequestObject() as CreateClinicalCaseRequest;
  }

  updateGroupPostRequestObject(): UpdateClinicalCaseRequest {
    return this._buildCaseRequestObject() as UpdateClinicalCaseRequest;
  }

  _buildCaseRequestObject(): CreateClinicalCaseRequest | UpdateClinicalCaseRequest {
    return {
      title: this.title,
      classificationLevelOne: this.classificationLevelOne,
      classificationLevelTwo: this.classificationLevelTwo,
      preOpDocuments: this.getCaseDocumentIdsByStageOfTreatment(StageOfTreatmentId.PRE_OP),
      intraOpDocuments: this.getCaseDocumentIdsByStageOfTreatment(StageOfTreatmentId.INTRA_OP),
      postOpDocuments: this.getCaseDocumentIdsByStageOfTreatment(StageOfTreatmentId.POST_OP),
      clinicalFollowUpDocuments: this.getCaseDocumentIdsByStageOfTreatment(StageOfTreatmentId.FOLLOW_UP),
      watermarked: this.caseImageUploadEditComponent?.watermarked,
      ...this.caseTextDetailsFields,
      attachments: this.caseDocuments?.length ? this.caseDocuments.map(doc => doc._id) : []
    };
  }

  isCreateUpdateCaseDataValid(): boolean {

    const areImageFilesChanged = this.areImageFilesChanged();
    const clinicalCaseTextFieldsChanged = this.areClinicalCaseTextFieldsChanged();
    const newClinicalCaseTextFieldsChanged = this.areNewClinicalCaseTextFieldsChanged();
    const areAllImagesTagedWithStageOfTreatment = this.areAllImagesTagedWithStageOfTreatment();

    if (
      (areImageFilesChanged || clinicalCaseTextFieldsChanged || newClinicalCaseTextFieldsChanged) &&
      this.caseImages?.filter(image => image?._id?.length)?.length > 0 &&
      this.title?.trim()?.length &&
      this.classificationLevelOne?.trim()?.length &&
      this.classificationLevelTwo?.trim()?.length &&
      areAllImagesTagedWithStageOfTreatment &&
      this.caseImageUploadEditComponent?.caseImagesUploading < 1 &&
      this.caseImageUploadEditComponent?.caseImagesProcessing < 1) {
      return true;
    } else {
      return false;
    }
  }

  onImageFilesChanged(fieldName: string, imageFiles: Array<ImageAttachment>): void {
    this[fieldName] = imageFiles;
  }

  async continueIfStateIsNotSaved(): Promise<any> {

    const areImageFilesChanged = this.areImageFilesChanged();
    const areImageFilesStageOfTreatmentTagsChanged = this.areImageFilesStageOfTreatmentTagsChanged();
    const clinicalCaseTextFieldsChanged = this.areClinicalCaseTextFieldsChanged();
    const newClinicalCaseTextFieldsChanged = this.areNewClinicalCaseTextFieldsChanged();

    if (
      areImageFilesChanged ||
      clinicalCaseTextFieldsChanged ||
      newClinicalCaseTextFieldsChanged ||
      areImageFilesStageOfTreatmentTagsChanged) {
      return this.createGroupContentService.promptUserAboutNotSavedState();
    } else {
      return true;
    }
  }

  areImageFilesChanged(): boolean {

    let imageFilesChanged = false;

    const caseOriginalImageFiles = [
      ...(this.createContentStrategy?.clinicalCase?.preOpDocuments || []),
      ...(this.createContentStrategy?.clinicalCase?.intraOpDocuments || []),
      ...(this.createContentStrategy?.clinicalCase?.postOpDocuments || []),
      ...(this.createContentStrategy?.clinicalCase?.clinicalFollowUpDocuments || [])
    ];

    if (caseOriginalImageFiles?.length < this.caseImages?.length) {
      return true;
    }

    this.caseImages.forEach(image => {
      const index = caseOriginalImageFiles.findIndex(originalImage => originalImage?._id === image?._id);

      if (index === -1) {
        imageFilesChanged = true;
      }
    });

    return imageFilesChanged;
  }

  areImageFilesStageOfTreatmentTagsChanged(): boolean {

    let imageFilesStageOfTreatmentTagsChanged = false;
    let images = [];

    this.caseImages.forEach(image => {

      if (image.stageOfTreatmentId) {
        switch (image.stageOfTreatmentId) {
          case StageOfTreatmentId.PRE_OP:
            images = this.createContentStrategy?.clinicalCase?.preOpDocuments;
            break;
          case StageOfTreatmentId.INTRA_OP:
            images = this.createContentStrategy?.clinicalCase?.intraOpDocuments;
            break;
          case StageOfTreatmentId.POST_OP:
            images = this.createContentStrategy?.clinicalCase?.postOpDocuments;
            break;
          case StageOfTreatmentId.FOLLOW_UP:
            images = this.createContentStrategy?.clinicalCase?.clinicalFollowUpDocuments;
            break;
        }

        if (!images || images.length === 0) {
          imageFilesStageOfTreatmentTagsChanged = true;
        } else {
          if (images.findIndex(originalImage => originalImage?._id === image?._id) === -1) {
            imageFilesStageOfTreatmentTagsChanged = true;
          }
        }
      }
    });

    return imageFilesStageOfTreatmentTagsChanged;
  }

  areAllImagesTagedWithStageOfTreatment(): boolean {

    if (!this.caseImages || this.caseImages?.length === 0) {
      return false;
    }

    let allImagesTaged = true;

    this.caseImages.forEach(image => {
      if (!image.stageOfTreatmentId) {
        allImagesTaged = false;
      }
    });

    return allImagesTaged;

  }

  // create group case
  areNewClinicalCaseTextFieldsChanged(): boolean {

    if (this.createContentStrategy?.clinicalCase) {
      return false;
    }

    if (
      this.title?.trim()?.length ||
      this.classificationLevelOne?.trim()?.length ||
      this.classificationLevelTwo?.trim()?.length) {
      return true;
    } else {
      return false;
    }
  }

  // edit group case
  areClinicalCaseTextFieldsChanged(): boolean {
    if (!this.createContentStrategy?.clinicalCase) {
      return false;
    }

    if (this.title !== this.createContentStrategy?.clinicalCase?.title ||
      this.classificationLevelOne !== this.createContentStrategy?.clinicalCase?.classificationLevelOne ||
      this.classificationLevelTwo !== this.createContentStrategy?.clinicalCase?.classificationLevelTwo
    ) {
      return true;
    } else {
      return false;
    }
  }

  onTitleChange(): void {
    this.updateCaseReadinessState();
  }

  caseClassificationTagsChanged(caseClassificationFields: CaseClassificationFields): void {
    this.classificationLevelOne = caseClassificationFields.classificationLevelOne;
    this.classificationLevelTwo = caseClassificationFields.classificationLevelTwo;
    this.showTutorialStep2CustomOverlay = false;
    this.updateCaseReadinessState();
  }

  updateCaseReadinessState(): void {

    // stage1: title and topics
    if (!this.title?.length && (!this.classificationLevelOne?.length || !this.classificationLevelTwo?.length)) {
      this.caseReadinessIndicatorState.stage1 = CaseReadinessStageState.STATE_1;
    } else if (this.title?.length && this.classificationLevelOne?.length && this.classificationLevelTwo?.length) {
      this.caseReadinessIndicatorState.stage1 = CaseReadinessStageState.STATE_3;
    } else {
      this.caseReadinessIndicatorState.stage1 = CaseReadinessStageState.STATE_2;
    }

    // stage2 images
    const areAllImagesTagedWithStageOfTreatment = this.areAllImagesTagedWithStageOfTreatment();

    if (!this.caseImages?.length) {
      this.caseReadinessIndicatorState.stage2 = CaseReadinessStageState.STATE_1;
    } else if (this.caseImages?.length && areAllImagesTagedWithStageOfTreatment) {
      this.caseReadinessIndicatorState.stage2 = CaseReadinessStageState.STATE_3;
    } else {
      this.caseReadinessIndicatorState.stage2 = CaseReadinessStageState.STATE_2;
    }

    // stage3 details
    if (!this.caseTextDetailsFields?.description?.length &&
      !this.caseTextDetailsFields?.complainMedicalCondition?.length &&
      !this.caseTextDetailsFields?.clinicalExam?.length &&
      !this.caseTextDetailsFields?.notes?.length) {
      this.caseReadinessIndicatorState.stage3 = CaseReadinessStageState.STATE_1;
    } else if (this.caseTextDetailsFields?.description?.length ||
      this.caseTextDetailsFields?.complainMedicalCondition?.length ||
      this.caseTextDetailsFields?.clinicalExam?.length ||
      this.caseTextDetailsFields?.notes?.length) {
      this.caseReadinessIndicatorState.stage3 = CaseReadinessStageState.STATE_3;
    }
  }

  get postSaveButtonDisabled(): boolean {
    return !this.isCreateUpdateCaseDataValid();
  }

  async checkTextFieldsMaxLengthExceeded(): Promise<boolean> {

    const fieldsMaxLengthExceeded: Array<FieldMaxTextLengthExceeded> = [];
    let notValidField = false;

    if (this.title?.length > this.caseTitleMaxLength) {

      const fieldName = await this.appTranslationService.get('app.groups.CreateGroupCase.case-title');

      fieldsMaxLengthExceeded.push({
        fieldName: fieldName,
        maxTextLength: this.caseTitleMaxLength
      });

      notValidField = true;
    }

    if (notValidField) {
      this.dialogs.showFieldsMaxTextLengthExceeded(fieldsMaxLengthExceeded);
    }

    return notValidField;
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  addImages(): void {

    if (this.caseImageUploadEditComponent && this.caseImageUploadEditComponent.addImages) {
      this.caseImageUploadEditComponent?.addImages();
    }
  }

  addVideos(): void {
    if (this.caseImageUploadEditComponent && this.caseImageUploadEditComponent.addVideos) {
      this.caseImageUploadEditComponent?.addVideos();
    }
  }

  addDocuments(): void {
    if (this.caseDocumentsUploadEditPreviewComponent && this.caseDocumentsUploadEditPreviewComponent.addDocuments) {
      this.caseDocumentsUploadEditPreviewComponent?.addDocuments();
    }
  }

  toggleWatermark(): void {
    if (this.caseImageUploadEditComponent && this.caseImageUploadEditComponent.toggleWatermark) {
      this.caseImageUploadEditComponent?.toggleWatermark();
    }
  }

  get isCaseTitleValid(): boolean {
    return this.title?.length > 0 && this.title?.length <= this.caseTitleMaxLength;
  }

  get isCaseTopicValid(): boolean {

    return this.classificationLevelOne &&
      this.classificationLevelOne?.length > 0 &&
      this.classificationLevelTwo &&
      this.classificationLevelTwo?.length > 0;
  }

  getCaseDocumentIdsByStageOfTreatment(id: StageOfTreatmentId): Array<string> {

    if (!this.caseImages || this.caseImages?.length === 0) {
      return [];
    }

    return this.caseImages.filter(img => img.stageOfTreatmentId === id).map(doc => doc._id);
  }

  setCaseImages(caseImages: Array<PersonalMediaGalleryDocumentWithStageOfTreatment>): void {
    this.caseImages = caseImages;
    this.updateCaseReadinessState();
  }

  setCaseDocuments(caseDocuments: Array<PersonalMediaGalleryDocument>): void {
    this.caseDocuments = caseDocuments;
  }

  closeSettings(): void {
    if (this.createEditCasePostBottomBarComponent?.closeSettings) {
      this.createEditCasePostBottomBarComponent?.closeSettings();
    }
  }

  showSettings(): void {
    if (this.createEditCasePostBottomBarComponent?.showPageSettings) {
      this.createEditCasePostBottomBarComponent?.showPageSettings();
    }
  }

  get isMobile(): boolean {
    return isMobilePlatform(appConfig.platform);
  }

  get showTutorialStep0Bubble(): boolean {
    return this.showTutorialStepXBubble(0);
  }

  get showTutorialStep1Bubble(): boolean {
    return (this.showTutorialStepXBubble(0) || this.showTutorialStepXBubble(1)) && this.areImagesSelected;
  }

  get showTutorialStep2Bubble(): boolean {
    return this.showTutorialStepXBubble(2) && this.areImagesSelected;
  }

  get areImagesSelected(): boolean {
    return this.caseImages && this.caseImages?.length ? true : false;
  }

  async selectTopic(): Promise<void> {
    await this.tutorialService.setCurrentStep(TUTORIAL_KEY, 3);
    this.showTutorialStep2CustomOverlay = true;
    const proTipElement = this.el.nativeElement.querySelector('.topic-select-container');
    const top = proTipElement?.getBoundingClientRect().top + proTipElement?.clientHeight;

    setTimeout(() => {
      this.el.nativeElement.querySelector(this.tutorialStep2CustomOverlay).setAttribute('style', `top: ${top}px`);
    }, 100);
  }

  get showStep2CustomOverlay(): boolean {
    return this.showTutorialStep2CustomOverlay && !this.classificationLevelOne;
  }

  scrollToBottom(): void {
    if (this.ionContent) {
      this.ionContent.scrollToBottom();
    }
  }

  onBackdropClick = (ev: MouseEvent): any => {
    this.uiUtilsService.emitAppClickEvent(ev);
  };

  /* eslint-disable */
  private showTutorialStepXBubble(step: number): boolean {

    return false; // tutorial should be reworked, thats why we never show it for now

    // if (
    //   !this.tutorialInfo ||
    //   this.tutorialInfo?.finished ||
    //   this.tutorialInfo?.skipped ||
    //   this.tutorialInfo.currentStep !== step) {
    //   return false;
    // }

    // return true;
  }
  /* eslint-enable */

  private showError(msg: string, withoutHeader?: boolean): void {
    const toastHeaderError = withoutHeader ? null : 'app.common.error-default';
    this.toast.showWithMessage(msg, toastHeaderError, ToastMode.ERROR);
  }

  private async getTutorialInfo(): Promise<void> {
    return; // tutorial should be reworked, thats why we never show it for now
    // try {
    //   this.tutorialInfo = await this.tutorialService.getTutorialData(TUTORIAL_KEY);
    // } catch (err) {
    //   console.log(err);
    //   this.tutorialInfo = null;
    // }
  }

  async reorderMedia(): Promise<void> {

    if (!this.areAllImagesTagedWithStageOfTreatment()) {
      this.showError(this.appTranslationService.
        instant('app.dialogs.ReorderCaseMediaDialog.reorder-not-tagged-images-error-message'));
      return;
    }

    const preOpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.preOp'),
      id: StageOfTreatmentId.PRE_OP,
      items: this.caseImages?.filter(image => image?.stageOfTreatmentId === StageOfTreatmentId.PRE_OP) || []
    };

    const intraOpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.intraOp'),
      id: StageOfTreatmentId.INTRA_OP,
      items: this.caseImages?.filter(image => image?.stageOfTreatmentId === StageOfTreatmentId.INTRA_OP) || []
    };

    const postOpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.postOp'),
      id: StageOfTreatmentId.POST_OP,
      items: this.caseImages?.filter(image => image?.stageOfTreatmentId === StageOfTreatmentId.POST_OP) || []
    };

    const clinicalFollowUpGroup: GroupedImagesByStageOfTreatment = {
      title: this.appTranslationService.instant('app.dialogs.ReorderCaseMediaDialog.followUp'),
      id: StageOfTreatmentId.FOLLOW_UP,
      items: this.caseImages?.filter(image => image?.stageOfTreatmentId === StageOfTreatmentId.FOLLOW_UP) || []
    };

    try {
      const reorderedImages = await this.uiUtilsService.showCaseMediaReorderDialog([preOpGroup,
        intraOpGroup,
        postOpGroup,
        clinicalFollowUpGroup], 'caseCreate', this.caseImageUploadEditComponent?.watermarked);

      if (!reorderedImages?.length) {
        return;
      }

      this.caseImages = [];

      reorderedImages.forEach(group => {
        this.caseImages = [...this.caseImages, ...group.items || []];
      })

    } catch (err) {
      console.error(err);
      if (err?.error?.error?.message?.errfor?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message);
      }
    }
  }

  get showAttachPrivateDocumentsButton(): boolean {
    return !this.caseDocuments?.length;
  }
}
