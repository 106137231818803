import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SwiperVisibilityTrackerService } from './swiper-visibility-tracker.service';

@Directive({
  selector: '[app-swiper-visibility-tracker-target]',
  exportAs: 'swiperVisibilityTracker'
})
export class SwiperVisibilityTrackerTargetDirective implements AfterViewInit, OnChanges {

  @Input() visibilityTrackerRootKey: string;
  @Input() visibilityTrackerRegistrationDelay = 100;
  @Input() registerAfterPromise: boolean;
  @Input() promise: Promise<any>;

  constructor(
    private ref: ElementRef,
    private swiperVisibilityTrackerService: SwiperVisibilityTrackerService
  ) {
  }
  ngAfterViewInit(): void {

    if (!this.registerAfterPromise) {
      setTimeout(() => {
        if (this.visibilityTrackerRootKey) {
          this.swiperVisibilityTrackerService.registerTarget(this.visibilityTrackerRootKey, this.ref.nativeElement);
        }
      }, this.visibilityTrackerRegistrationDelay);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.promise &&
      changes?.promise?.currentValue &&
      !changes?.promise?.previousValue) {
      this.promise.finally(() => {
        if (this.visibilityTrackerRootKey) {
          this.swiperVisibilityTrackerService.registerTarget(this.visibilityTrackerRootKey, this.ref.nativeElement);
        }
      })
    }
  }
}
