import { Component, Inject, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { DedicatedSearch } from 'src/app/services/dedicated-search/dedicated-search.interface';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { MessengerService } from 'src/app/services/messenger/messenger.service';
import { SearchUsersStatus, User } from 'src/app/services/yeti-protocol/messenger';
import { ConnectedUser } from 'src/app/services/yeti-protocol/public-profile';
import { VerificationService } from 'src/app/services/verification.service';
import { VerificationStatus } from 'src/app/services/verification.model';

const PAGE_SIZE = 20;

interface FoundUsersInfo {
  connected: Array<User>;
  pending: Array<User>;
  ao: Array<User>;
  total: number;
}

@Component({
  selector: 'app-dedicated-search-people',
  templateUrl: './dedicated-search-people.component.html',
  styleUrls: ['./dedicated-search-people.component.scss'],
})
export class DedicatedSearchPeopleComponent implements DedicatedSearch {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  contacts: Array<User | ConnectedUser> = [];
  pendingContacts: Array<User> = [];
  myAoUsers: Array<User> = [];
  searchTerm: string;
  searching: boolean;
  searchTotalCount: number;
  disableConnectButtonsForUsers = {};

  constructor(
    private messengerService: MessengerService,
    private modalController: ModalController,
    private appNavController: AppNavController,
    private verificationService: VerificationService,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI
  ) { }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.searchUsers(searchTerm, 0);
  }

  searchUsers(searchTerm: string, start: number): void {
    this.searching = true;
    this._searchUsers(searchTerm, start)
      .then(info => {
        if (start === 0) {
          this._resetUsersList();
        }
        this._updateUsers(info);
        this.searchTotalCount = info.total;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.searching = false;
        this.completeInfiniteScroll();
      });
  }

  _searchUsers(term: string, pageIndex: number, status: SearchUsersStatus = null): Promise<FoundUsersInfo> {
    return this.messengerService.searchUsers(term, status, pageIndex, PAGE_SIZE)
      .then(found => {
        const users = found.users;
        return {
          connected: users.filter(user => user.connectionStatus === 'connected'),
          pending: users.filter(user => user.connectionStatus === 'pending'),
          ao: users.filter(user => {
            return user.connectionStatus === 'none' ||
              user.connectionStatus === 'disconnected' ||
              user.connectionStatus === 'rejected';
          }),
          total: found.total
        };
      })
  }

  _updateUsers(info: FoundUsersInfo): void {
    if (info.connected.length > 0) {
      this.contacts = [...this.contacts, ...info.connected];
    }

    if (info.pending.length > 0) {
      this.pendingContacts = [...this.pendingContacts, ...info.pending];
    }

    if (info.ao.length > 0) {
      this.myAoUsers = [...this.myAoUsers, ...info.ao];
    }
  }

  _resetUsersList(): void {
    this.contacts = [];
    this.pendingContacts = [];
    this.myAoUsers = [];
    this.searchTotalCount = 0;
  }

  completeInfiniteScroll(): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.complete();
    }
  }

  get showEmptyState(): boolean {
    return !this.searching && this.searchTotalCount === 0;
  }

  get totalLoadedContacts(): number {
    return this.contacts.length + this.pendingContacts.length + this.myAoUsers.length;
  }

  get canLoadMore(): boolean {

    if (this.totalLoadedContacts < this.searchTotalCount) {
      return true;
    }

    return false;
  }

  get hasContacts(): boolean {
    return this.contacts.length > 0;
  }

  get hasPendingContacts(): boolean {
    return this.pendingContacts.length > 0;
  }

  get hasMyAoUsers(): boolean {
    return this.myAoUsers.length > 0;
  }

  onOpenConversation(conversationId: string): void {
    if (!conversationId) {
      return;
    }
    this.modalController.dismiss();
    this.appNavController.openMessenger(conversationId);
  }

  async showSendNewConnectionRequestDialog(user: User): Promise<void> {
    const userVerificationStatus = await this.verificationService.verify();
    if (userVerificationStatus !== VerificationStatus.VERIFIED) {
      return;
    }
    return this.dialogs.showSendNewConnectionRequestDialog(user)
      .then(sent => {
        if (sent && user?.userId) {
          this.disableConnectButtonsForUsers[user?.userId] = true;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  loadMore(): void {

    if (!this.canLoadMore) {
      this.disableInfiniteScroll(true);
      return;
    }

    const totalLoadedItems = this.contacts?.length + this.pendingContacts?.length + this.myAoUsers?.length;

    const pageIndex = Math.floor(totalLoadedItems / PAGE_SIZE);
    this.searchUsers(this.searchTerm, pageIndex);
  }

  get showStartState(): boolean {
    return !this.searchTerm;
  }

  openPublicUserProfile(userId: string): void {
    this.modalController.dismiss();
    this.appNavController.openPublicUserProfile(userId);
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

}
