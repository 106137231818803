import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Journal } from 'src/app/services/yeti-protocol/journal';

@Component({
  selector: 'app-my-journals-list',
  templateUrl: './my-journals-list.component.html',
  styleUrls: ['./my-journals-list.component.scss'],
})
export class MyJournalsListComponent {
  @Input() followingJournals: Array<Journal> = new Array<Journal>();
  @Output() itemChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() openJournal: EventEmitter<string> = new EventEmitter();
}
