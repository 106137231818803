// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --side-padding: 18px;
}

.dedicated-search-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 20px;
  background-color: var(--mi-white-color);
}
.dedicated-search-dialog-container .header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  background-color: var(--mi-white-color);
  padding-bottom: 13px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
}
.dedicated-search-dialog-container .header app-top-bar {
  --top-bar-padding-top: 30px;
  --top-bar-padding-bottom: 20px;
  --top-bar-padding-start: var(--side-padding);
  --top-bar-padding-end: var(--side-padding);
}
.dedicated-search-dialog-container .header app-top-bar ::ng-deep ion-header::after {
  display: none;
}
.dedicated-search-dialog-container .header .search-bar-container {
  padding: 0 var(--side-padding);
}
@media all and (max-width: 759px) {
  .dedicated-search-dialog-container .header {
    padding: 0;
  }
  .dedicated-search-dialog-container .header app-top-bar {
    --top-bar-padding-top: 28px;
    --top-bar-padding-bottom: 28px;
  }
  .dedicated-search-dialog-container .header .search-bar-container {
    display: none;
  }
}
.dedicated-search-dialog-container .search-content-container {
  grid-row: 2;
  grid-column: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
