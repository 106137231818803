import {InjectionToken} from '@angular/core';

export const UNKNOWN_VERSION = null;
export interface VersionInfo {
  version: string;
  isActive: boolean;
}

export interface ClientConfigServiceConfig {
  timeout: number;
  clientConfigUrl: string;
}

export interface ClientConfigurationInfo {
  version: number;
  showFeedSmartSwitch?: boolean | false;
  showVideoTranscriptSummary?:  boolean | false;
}

export const DEFAULT_DISABLED: ClientConfigurationInfo = {
  version: UNKNOWN_VERSION,
  showFeedSmartSwitch: false,
  showVideoTranscriptSummary: false
}

export interface ClientConfigServiceInterface {
  getRemoteVersion(): Promise<string>;
  getClientConfiguration(): Promise<ClientConfigurationInfo>;
}

export const CLIENT_CONFIG_SERVICE = new InjectionToken<ClientConfigServiceInterface>('client-config.service');
