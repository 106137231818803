import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { CONTEXT_SERVICE, ContextService } from '../../context/context.model';
import { AppTranslationService } from '../../app-translation.service';
import { DialogsUIService } from '../../dialogs/dialogs.ui.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from '../../utils/ui-utils.service.interface';
import { GroupsService } from '../../groups/groups.service';
import { ClinicalCase } from '../../yeti-protocol/clinical-case';
import { LastActiveGroupsSuccessResponse } from '../../yeti-protocol/chatter-api';
import { ConfirmDialogData } from '../../dialogs/dialogs.ui.interface';
import { CreatePostAction } from '../create-content';

export enum Status {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root'
})
export class CreateGroupContentService {

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private appTranslationService: AppTranslationService,
    private alertController: AlertController,
    private dialogs: DialogsUIService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private groupsService: GroupsService,
  ) { }

  async promptUserAboutNotSavedState(): Promise<any> {
    const title = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-title');
    const text = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-text');
    const confirmButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-confirm-button-text');
    const rejectButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-reject-button-text');

    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [{
        text: confirmButtonText,
        role: 'confirm'
      },
      {
        text: rejectButtonText,
        role: 'cancel',
        cssClass: 'secondary'
      }]
    });

    alert.present();
    return alert.onDidDismiss();
  }

  async openCreateGroupPostPage(
    groupId?: string,
    postId?: string,
    action?: CreatePostAction,
    postContentText?: string,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean
  ): Promise<void> {

    if (postId && !groupId) {
      console.error('if postId is present, groupId is mandatory');
      return;
    }

    if (!groupId) {

      if (!this.groupsService || !this.groupsService?.getLastActiveGroups) {
        return;
      }

      const lastActiveGroupsSuccessResponse = (await this.groupsService.getLastActiveGroups(0, 5) as LastActiveGroupsSuccessResponse);

      if (!lastActiveGroupsSuccessResponse?.totalItemsCount || lastActiveGroupsSuccessResponse?.totalItemsCount < 1) {
        this.showNoGroupsConfirm();
        return;
      }
    }

    const groupIdUrlPart = groupId ? `${groupId}/` : '';
    const postIdUrlPart = postId ? `/${postId}` : '';
    const url = [this.contextService.currentContext.key, `group/${groupIdUrlPart}create/post${postIdUrlPart}`].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {
        action: action,
        postContentText: postContentText,
        clinicalCase: clinicalCase,
      },
      replaceUrl: replaceUrl ? true : false
    });
  }

  openCreateGroupCasePage(groupId?: string, caseId?: string, replaceUrl?: boolean, shareCaseInPost?: boolean): void {

    if (caseId && !groupId) {
      console.error('if caseId is present, groupId is mandatory');
      return;
    }

    const groupIdUrlPart = groupId ? `${groupId}/` : '';
    const caseIdUrlPart = caseId ? `/${caseId}` : '';
    const url = [this.contextService.currentContext.key, `group/${groupIdUrlPart}create/case${caseIdUrlPart}`].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {
        shareCaseInPost: shareCaseInPost
      },
      replaceUrl: replaceUrl ? true : false
    });
  }

  private async showNoGroupsConfirm(): Promise<boolean> {

    const gotItActionKey = 'got-it';

    const changeProfessionConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.confirm.create-a-post-no-groups-title'
        },
        message: {
          translationKey: 'app.dialogs.confirm.create-a-post-no-groups-message'
        },
        actions: [
          {
            key: gotItActionKey,
            label: {
              translationKey: 'app.dialogs.confirm.create-a-post-no-groups-button-1',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(changeProfessionConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === gotItActionKey) {
        this.router.navigateByUrl(`${this.contextService.currentContext.key}/network`);
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
