import { Injectable } from '@angular/core';

Injectable()
export class ExitService {
  exit(): void {
    if((window as any).cordova && (window as any).cordova.plugins && (window as any).cordova.plugins.exit){
      (window as any).cordova.plugins.exit();
    }
  }
}
