import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SignInParams } from './ao-auth.model';
import { AOAuthServiceInterface } from './ao-auth.service.interface';

import { MIAOUtilsService } from './mi-ao-utils.service.service';
import { UrlUtilsService } from '../../utils/url-utils.service';
import { SchemaValidatorService } from '../../schema-validator.service';
import { LocationService } from '../../location.service';
import { LOCAL_STORAGE_SERVICE, SESSION_STORAGE_SERVICE, StorageService } from '../../storage/storage.service';

import appConfig from 'src/config/config';
import { AOAuthDeviceBase } from './ao-auth.device.base';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AppTranslationService } from '../../app-translation.service';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { firstValueFrom } from 'rxjs';

interface ShowViewResult {
  event: 'opened' | 'loaded' | 'closed';
}

@Injectable()
export class AOAuthDeviceIOSService extends AOAuthDeviceBase implements AOAuthServiceInterface {

  constructor(
    protected miaoUtilsService: MIAOUtilsService,
    protected urlUtilsService: UrlUtilsService,
    @Inject(SESSION_STORAGE_SERVICE) protected sessionStorageService: StorageService,
    protected schemaValidator: SchemaValidatorService,
    @Inject(LOCAL_STORAGE_SERVICE) protected storage: StorageService,
    protected httpClient: HttpClient,
    protected locationService: LocationService,
    protected inAppBrowser: InAppBrowser,
    protected appTranslationService: AppTranslationService,
    private safariViewController: SafariViewController
  ) {
    super();
  }

  signUp(): Promise<any> {

    const signUpUrl = this._getSignUpUrl(this.getSignupRedirectUrl());

    this.safariViewController.show({
      url: signUpUrl,
      hidden: false,
      animated: false,
      transition: 'curl',
      enterReaderModeIfAvailable: false,
      barColor: appConfig.inAppBrowserColors.toolbarcolor
    })
      .subscribe({
        next: (_result: ShowViewResult) => {/* the result is never used */ },
        error: (error: any) => {
          console.error(error);
        }
      });

    return Promise.resolve(null);
  }

  _signIn(signInParams: SignInParams, storage: StorageService): Promise<any> {
    return this._saveSignInParams(signInParams, storage)
      .then(params => {

        this.safariViewController.show({
          url: params.authenticationUrl,
          hidden: false,
          animated: false,
          transition: 'curl',
          enterReaderModeIfAvailable: false,
          barColor: appConfig.inAppBrowserColors.toolbarcolor
        })
          .subscribe({
            next: (_result: ShowViewResult) => {/* the result is never used */ },
            error: (error: any) => {
              console.error(error);
            }
          });

        return Promise.resolve(null);
      });
  }

  _signOut(): Promise<any> {

    const logoutUrl = this._getSignOutUrl(this.getLogoutRedirectUrl());

    if (!logoutUrl) {
      return Promise.reject('no logout url');
    }

    this.safariViewController.show({
      url: logoutUrl,
      hidden: false,
      animated: false,
      transition: 'curl',
      enterReaderModeIfAvailable: false,
      barColor: appConfig.inAppBrowserColors.toolbarcolor
    })
      .subscribe({
        next: (_result: ShowViewResult) => {/* the result is never used */ },
        error: (error: any) => {
          console.error(error);
        }
      });

    return Promise.resolve(null);
  }

  signOut(): Promise<any> {
    return Promise.all([
      this.miaoUtilsService.signOut(),
      this.storage.remove(this.TOKENS_STORAGE_KEY)
    ]).then(() => {
      setTimeout(() => {
        this._signOut();
      }, 500);
    });
  }

  getRedirectUrl(): string {
    return this.config.authenticationAO.redirectUri;
  }

  getSignupRedirectUrl(): string {
    return this.config.authenticationAO.signUpRedirectUriIOS;
  }

  getLogoutRedirectUrl(): string {
    return `${this.config.authenticationAO.logoutRedirectUri}?state=ios`;
  }

  openAoLink(url: string): Promise<void> {
    if (url && url.length > 0) {
      return firstValueFrom(this.safariViewController.show({
        url: url,
        hidden: false,
        animated: false,
        transition: 'curl',
        enterReaderModeIfAvailable: false,
        barColor: appConfig.inAppBrowserColors.toolbarcolor
      }));
    }

    return Promise.reject();
  }

}

