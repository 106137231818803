import { Component, Input, OnInit } from '@angular/core';
import { IonInfiniteScroll, IonRefresher, ModalController } from '@ionic/angular';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

// models
import { CustomTab } from 'src/app/components/custom-tabs/custom-tabs.component';
import { GroupListItem, LastActiveGroupsSuccessResponse, SharedGroupsResponse } from 'src/app/services/yeti-protocol/chatter-api';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { TabIds } from 'src/app/components/groups-section/groups-section.model';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

// services
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { PublicProfileService } from 'src/app/services/public-profile.service';

@Component({
  selector: 'app-groups-section-dialog',
  templateUrl: './groups-section-dialog.component.html',
  styleUrls: ['./groups-section-dialog.component.scss'],
})
export class GroupsSectionDialogComponent  implements OnInit {
  @Input() userId: string;
  @Input() source: ActionSource;

  customTabs: Array<CustomTab> = [];
  activeTabId: string;
  userAllGroups: Array<GroupListItem> = [];
  userSharedGroups: Array<GroupListItem> = [];
  totalItemsCountAll = 0;
  totalItemsCountShared = 0;
  loading: boolean;
  TabIds = TabIds;
  start = 0;

  readonly count = 10;

  constructor(
    private modalController: ModalController,
    private appTranslationService: AppTranslationService,
    private groupsService: GroupsService,
    private publicProfileService: PublicProfileService,
    private appNavController: AppNavController
  ) { }

  ngOnInit(): void {
    Promise.all([
      this.getUserSharedGroups(this.start),
      this.getUserAllGroups(this.start)
    ]).then(() => {
      const activeTab = this.isTabSharedDisabled ? TabIds.all : TabIds.shared;
      this.activeTabId = activeTab;
      this.generateTabs();
    });
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  get isTabSharedActive(): boolean {
    return this.activeTabId === TabIds.shared ? true : false;
  }

  get isTabSharedDisabled(): boolean {
    if(this.loading) {
      return false;
    }

    return !this.loading && this.userSharedGroups?.length === 0;
  }

  get isTabAllActive(): boolean {
    return this.activeTabId === TabIds.all ? true : false;
  }

  get isTabAllDisabled(): boolean {
    if(this.loading) {
      return false;
    }

    return !this.loading && this.userAllGroups?.length === 0;
  }

  setActiveTab(tabId: TabIds): void {

    if (!tabId) {
      return;
    }

    this.activeTabId = tabId;
    this.start = 0;

    switch (tabId) {
      case TabIds.all:
        this.userAllGroups = [];
        this.totalItemsCountAll = 0;
        this.getUserAllGroups(this.start);
        break;
      case TabIds.shared:
        this.userSharedGroups = [];
        this.totalItemsCountShared = 0;
        this.getUserSharedGroups(this.start);
        break;
    }
  }

  loadMoreGroups(verticalListLoadMoreData: VerticalListLoadMoreData): void {
    switch(this.activeTabId) {
      case TabIds.shared:
        this.start = Math.floor(this.userSharedGroups?.length / this.count);
        this.getUserSharedGroups(this.start, null, verticalListLoadMoreData?.infiniteScroll);
        break;
      case TabIds.all:
        this.start = Math.floor(this.userAllGroups?.length / this.count);
        this.getUserAllGroups(this.start, null, verticalListLoadMoreData?.infiniteScroll);
        break;
    }
  }

  refreshList(refresher: IonRefresher): void {
    this.start = 0;

    switch (this.activeTabId) {
      case TabIds.all:
        this.userAllGroups = [];
        this.totalItemsCountAll = 0;
        this.getUserAllGroups(this.start, refresher);
        break;
      case TabIds.shared:
        this.userSharedGroups = [];
        this.totalItemsCountShared = 0;
        this.getUserSharedGroups(this.start, refresher);
        break;
    }
  }

  openGroup(groupId: string): void {
    this.modalController.dismiss();
    this.appNavController.openGroup(groupId, this.source);
  }

  private generateTabs(): void {
    this.customTabs = [{
      id: TabIds.shared,
      text: this.appTranslationService.instant('app.common.shared'),
      active: this.isTabSharedActive,
      disabled: this.isTabSharedDisabled
    },
    {
      id: TabIds.all,
      text: this.appTranslationService.instant('app.common.all'),
      active: this.isTabAllActive,
      disabled: this.isTabAllDisabled
    }];
  }

  private getUserAllGroups(start: number, refresher?: IonRefresher, infiniteScroll?: IonInfiniteScroll): Promise<void> {
    this.loading = true;
    return this.groupsService.getLastActiveGroupsForUser(this.userId, start, this.count, true)
      .then((response: LastActiveGroupsSuccessResponse) => {
        if(response) {
          this.userAllGroups = [...this.userAllGroups, ...response?.result];
          this.totalItemsCountAll = response?.totalItemsCount;
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
        refresher?.complete();
        infiniteScroll?.complete();
      });
  }

  private getUserSharedGroups(start: number, refresher?: IonRefresher, infiniteScroll?: IonInfiniteScroll): Promise<void> {
    this.loading = true;
    return this.publicProfileService.getSharedGroups(this.userId, start, this.count)
      .then((response: SharedGroupsResponse) => {
        if(response) {
          this.userSharedGroups = [...this.userSharedGroups, ...response?.result];
          this.totalItemsCountShared = response?.totalItemsCount;
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
        refresher?.complete();
        infiniteScroll?.complete();
      })
  }
}
