// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clinical-case-list-item-container {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  cursor: pointer;
}
.clinical-case-list-item-container .image-container {
  grid-row: 1;
  grid-column: 1;
  width: 70px;
  height: 70px;
}
.clinical-case-list-item-container .image-container app-image-attachments-container {
  --image-attachments-container-padding-top: 0px;
  --image-attachments-container-padding-bottom: 0px;
  --image-attachments-container-padding-start: 0px;
  --image-attachments-container-padding-end: 0px;
  --image-attachments-container-width: 70px;
  --image-attachments-container-height: 70px;
  --image-attachments-first-image-height: 70px;
}
.clinical-case-list-item-container .details-container {
  grid-row: 1;
  grid-column: 2;
}
.clinical-case-list-item-container .details-container .title-container {
  height: 42px;
}
.clinical-case-list-item-container .details-container .title-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 44px;
}
.clinical-case-list-item-container .details-container .title-container app-multiline-text-with-ellipsis ::ng-deep .multiline-text-container .text {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-black-color-2);
}
.clinical-case-list-item-container .details-container .topics-container app-case-tags ::ng-deep .case-tags-container {
  display: block;
  padding: 0;
}
.clinical-case-list-item-container .details-container .topics-container app-case-tags ::ng-deep .case-tags-container .title-container {
  display: none;
}
.clinical-case-list-item-container .details-container .topics-container app-case-tags ::ng-deep .case-tags-container app-chips ::ng-deep .chips-container app-case-classification-chip {
  --rows-margin: 4px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
