// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --border-radius: 10px;
}

.tutorial-dialog-container {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #358df8, #9efbce);
  padding: 5px;
  border-radius: var(--border-radius);
}
.tutorial-dialog-container .content-container {
  width: 100%;
  height: 100%;
  padding: 10px 15px 15px 15px;
  border-radius: var(--border-radius);
  background-color: var(--mi-white-color);
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
}
.tutorial-dialog-container .content-container .icon-container {
  padding-bottom: 15px;
}
.tutorial-dialog-container .content-container .icon-container app-icon {
  --icon-size: 80px;
  color: var(--mi-light-purple-4-color);
}
.tutorial-dialog-container .content-container .title-container {
  padding-bottom: 11px;
}
.tutorial-dialog-container .content-container .title-container .title {
  margin: 0;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  color: var(--mi-secondary-color-2);
  text-align: center;
}
.tutorial-dialog-container .content-container .subtitle-container {
  padding-bottom: 17px;
}
.tutorial-dialog-container .content-container .subtitle-container .subtitle {
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
  text-align: center;
}
.tutorial-dialog-container .content-container .buttons-container {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  justify-items: center;
  align-items: center;
  -moz-column-gap: 30px;
       column-gap: 30px;
}
.tutorial-dialog-container .content-container .buttons-container .cancel-button-container button {
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
}
.tutorial-dialog-container .content-container .buttons-container .cancel-button-container button span {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  text-align: center;
  white-space: nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
