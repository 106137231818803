import { Component, EventEmitter, Input, Output } from '@angular/core';

// services
import { ShortPublicProfile } from 'src/app/services/yeti-protocol/public-profile';

@Component({
  selector: 'app-base-user-profile-list',
  templateUrl: './base-user-profile-list.component.html',
  styleUrls: ['./base-user-profile-list.component.scss'],
})
export class BaseUserProfileListComponent {

  @Input() userProfiles: Array<ShortPublicProfile>;
  @Input() shouldShowOrcIdIcon: boolean;
  @Output() userProfileClick: EventEmitter<ShortPublicProfile> = new EventEmitter<ShortPublicProfile>();

  goToUserProfile(user: ShortPublicProfile): void {
    this.userProfileClick.emit(user);
  }
}
