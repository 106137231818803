// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-mi-card {
  --mi-card-padding-top: 16px;
  --mi-card-padding-start: 10px;
  --mi-card-padding-end: 14px;
  --mi-action-bar-padding-start: 18px;
  --mi-action-bar-padding-end: 30px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
}
app-mi-card ::ng-deep ion-card {
  padding: 0;
}
app-mi-card .orcid-article-header-container {
  grid-row: 1;
  grid-column: 1;
  padding: var(--mi-card-padding-top) var(--mi-card-padding-end) 0 var(--mi-card-padding-start);
  cursor: pointer;
}
app-mi-card .orcid-article-content-container {
  grid-row: 2;
  grid-column: 1;
  padding: 35px var(--mi-card-padding-end) 0 var(--mi-card-padding-start);
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  cursor: pointer;
}
app-mi-card .orcid-article-content-container .open-orcid-profile-btn {
  width: 100%;
  border-radius: 10px;
}
app-mi-card .orcid-article-content-container .open-orcid-profile-btn .btn-wrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto;
  -moz-column-gap: 6px;
       column-gap: 6px;
  align-items: center;
  padding: 0;
  background-color: var(--mi-white-color);
  border-radius: 10px;
}
app-mi-card .orcid-article-content-container .open-orcid-profile-btn .btn-wrapper .start-icon-container {
  grid-row: 1;
  grid-column: 1;
}
app-mi-card .orcid-article-content-container .open-orcid-profile-btn .btn-wrapper .start-icon-container .start-icon {
  --icon-size: 24px;
}
app-mi-card .orcid-article-content-container .open-orcid-profile-btn .btn-wrapper .btn-text-container {
  grid-row: 1;
  grid-column: 2;
  position: relative;
  width: 100%;
  height: 14px;
  overflow: hidden;
}
app-mi-card .orcid-article-content-container .open-orcid-profile-btn .btn-wrapper .btn-text-container .btn-text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
app-mi-card .orcid-article-content-container .open-orcid-profile-btn .btn-wrapper .btn-text-container .btn-text-wrapper .btn-text {
  text-align: left;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-mi-card .orcid-article-content-container .title-container {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  display: grid;
  grid-column-gap: 10px;
  justify-content: space-between;
}
app-mi-card .orcid-article-content-container .title-container .title {
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  color: var(--mi-secondary-color-2);
  margin: 0;
}
app-mi-card .orcid-article-content-container .authors-container {
  padding-bottom: 14px;
}
app-mi-card .orcid-article-content-container .authors-container .authors {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  text-align: left;
  color: var(--mi-grey-color);
  margin: 0;
}
app-mi-card .orcid-article-content-container .journal-and-pubdate-container {
  padding-bottom: 25px;
}
app-mi-card .orcid-article-content-container .journal-and-pubdate-container .journal-and-pubdate {
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: left;
  color: var(--mi-color-text-lite);
  margin: 0;
}
app-mi-card .orcid-article-content-container .applauds-container {
  padding-bottom: 25px;
}
app-mi-card .orcid-article-content-container .applauds-container app-user-profile-image-bubbles {
  --bubbles-padding-top: 10px;
}
app-mi-card .orcid-article-actions-container {
  grid-row: 3;
  grid-column: 1;
}
app-mi-card .orcid-article-actions-container app-mi-card-actions {
  --mi-card-actions-padding-top: 0;
  --mi-card-actions-padding-bottom: 0;
  --mi-card-actions-padding-start: var(--mi-action-bar-padding-start);
  --mi-card-actions-padding-end: var(--mi-action-bar-padding-end);
  width: 100%;
  height: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
