import { Inject, Injectable } from '@angular/core';

import OneSignal from 'onesignal-cordova-plugin';
import { OSNotification } from 'onesignal-cordova-plugin/dist/OSNotification';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import appConfig from 'src/config/config';
import { DeepLinksRecognizerService } from './deep-links-recognizer.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { ActionSource, GenericTrackingParam, TrackingRequest } from '../yeti-protocol/tracking';
import { NotificationClickEvent } from 'onesignal-cordova-plugin/dist/models/NotificationClicked';
import { Platform } from 'src/config/config.model';
import {UrlUtilsService} from '../utils/url-utils.service';

interface PushNotificationsConfig {
  appId: string;
  platform: Platform;
  firebaseProjectNumber: string;
  triggerKey: string;
  triggerValue: string;
}

@Injectable()
export class PushNotificationsService {

  isInitialized = false;
  _enabled = false;
  cachedLink: string | null = null;
  _userId = '';
  config: PushNotificationsConfig = {
    ...appConfig.oneSignalPushNotification,
    platform: appConfig.platform
  };
  private pushNotificationPathSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private notificationsEnabledStatusChangedSubject: Subject<void> = new Subject<void>();


  constructor(
    private deepLinksRecognizer: DeepLinksRecognizerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private urlUtilsService: UrlUtilsService
  ) { }

  get pushNotification(): Observable<string> {
    return this.pushNotificationPathSubject.asObservable();
  }

  get notificationsEnabledStatusChanged(): Observable<void> {
    return this.notificationsEnabledStatusChangedSubject.asObservable();
  }

  areNotificationsAllowed(): Promise<boolean> {
    return this._assertOneSignalAvailable()
      .then(() => {
        return Promise.resolve(OneSignal.Notifications.getPermissionAsync());
      });
  }

  promptForNotifications(): Promise<any> {
    return this._assertOneSignalAvailable()
      .then(() => {
        return new Promise((resolve) => {
          this._trackEvent('requestPermission', ActionSource.promptForNotificationsPermission);
          OneSignal.Notifications.requestPermission(true).then(accepted => {
            this.emitNotificationsEnabledStatusChanged();
            resolve(accepted);
          })
        });
      });
  }

  onLogin(userId: string): void {
    this._userId = userId;
    OneSignal.login(userId);
    this._trackEvent('login', ActionSource.doNotificationsLogin);
  }

  onLogout(): void {
    OneSignal.logout();
    this._trackEvent('logout', ActionSource.doNotificationsLogout);
    this._userId = '';
  }

  // must be called after platform.ready() resolves
  async init(): Promise<any> {
    if (this.isInitialized) {
      return Promise.resolve();
    }
    if (!this.config.appId) {
      return Promise.reject('onesignal appId is not available');
    }
    try {
      OneSignal.initialize(this.config.appId);
      OneSignal.Notifications.addEventListener('click', evt => {
        const data = this._extractNotificationData(evt);
        if (data) {
          const notificationData = JSON.stringify(data);
          console.log('notificationOpenedCallback: ' + notificationData);
          const additionalData =
            (data.additionalData || (data as unknown as NotificationClickEvent).notification.additionalData) || {};
          const url = (additionalData as {url: string}).url || '';
          const sourceValue = this.urlUtilsService.getQueryParamValue(url, 'source') || ActionSource.pushNotification;
          this._trackEvent('open-notification', sourceValue);
          this._notificationOpenedCallback(data);
        }
      });
      const promptNotifications = (await OneSignal.User.pushSubscription.getOptedInAsync()) ? 'false' : 'true';
      OneSignal.InAppMessages.addTrigger('prompt_notifications', promptNotifications)
      this.isInitialized = true;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  _extractNotificationData(evt: NotificationClickEvent): OSNotification {
    let res: OSNotification = null;
    console.log('_extractNotificationData: ' + JSON.stringify(evt));
    switch (this.config.platform) {
      case Platform.ANDROID:
        res = evt as undefined as OSNotification;
        break;
      case Platform.IOS:
        res = evt.notification;
        break;
      default:
        break;
    }
    return res;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(value: boolean) {
    this._enabled = value;
    if (value && this.cachedLink) {
      this._processDeepLink(this.cachedLink);
      this.cachedLink = null;
    }
  }

  _processDeepLink(path: string): void {
    if (this.deepLinksRecognizer.isDeepLink(path)) {
      const translatedPath = this.deepLinksRecognizer.translateAlias(path, appConfig)
      this.pushNotificationPathSubject.next(translatedPath);
    } else {
      console.log(`push-notifications:not-recognised-path: ${path}`);
    }
  }

  _notificationOpenedCallback(notification: OSNotification): void {
    console.log(`push-notification:callback: ${JSON.stringify(notification)}`);
    /* eslint-disable */
    const path = notification?.additionalData?.['url'] ||
      (notification as any)?.notification?.additionalData?.['url'] || null;
    /* eslint-enable */
    if (this.enabled) {
      this._processDeepLink(path);
    } else {
      this.cachedLink = path;
    }
  }

  _assertOneSignalAvailable(): Promise<void> {
    if (this.config.appId) {
      return Promise.resolve();
    }
    return Promise.reject('not supported platform');
  }

  _trackEvent(action: string, actionSource: string | ActionSource): void {
    const paramsToTrack: GenericTrackingParam = {
      objectId: this._userId,
      objectType: action,
      source: actionSource
    };

    const trackData: TrackingRequest = {
      action: 'onesignal',
      params: paramsToTrack
    };

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track onesignal action');
    });
  }

  private emitNotificationsEnabledStatusChanged(): void {
    this.notificationsEnabledStatusChangedSubject.next();
  }
}
