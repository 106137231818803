import { Component, EventEmitter, HostBinding, Inject, Input, Output } from '@angular/core';
import { LinkOpenerService } from 'src/app/services/link-opener.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { Message } from 'src/app/services/yeti-protocol/message';
import { MessageCardBase } from '../message-card-base';
import { MessagePressedEvent } from '../messenger-list.model';

@Component({
  selector: 'app-message-card-link',
  templateUrl: './message-card-link.component.html',
  styleUrls: ['./message-card-link.component.scss'],
})
export class MessageCardLinkComponent extends MessageCardBase {
  @Input() message: Message;
  @Input() userId: string;
  @Output() showCommands: EventEmitter<MessagePressedEvent> = new EventEmitter<MessagePressedEvent>()

  constructor(
    respomsoveUtilsService: ResponsiveUtilsService,
    private linkOpener: LinkOpenerService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) {
      super(respomsoveUtilsService);
  }

  @HostBinding('class') get viewMode(): string {
    if(this.message.sender.userId === this.userId){
      return 'chat-initiator';
    }
    return '';
  }

  onOpenLink(link: string): void {
    this.linkOpener.open(link);
  }

  messageClicked(event: Event): void {
    if ((event as any)?.target.nodeName.toLowerCase() === 'a') {
      this.uiUtilsService.stopEventPropagation(event);
      const href = (event as any).target.getAttribute('href');

      if (href) {
        this.linkOpener.open(href);
      }
    } else if (this.message?.url) {
      this.linkOpener.open(this.message?.url);
    }
  }
}
