import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ShareToObject } from 'src/app/services/sharing/sharing.model';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';

// import Swiper core and required modules
import SwiperCore, { Navigation, SwiperOptions, Thumbs } from 'swiper';
// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

@Component({
  selector: 'app-share-selected-recipients-slider',
  templateUrl: './share-selected-recipients-slider.component.html',
  styleUrls: ['./share-selected-recipients-slider.component.scss'],
})
export class ShareSelectedRecipientsSliderComponent implements OnInit, OnDestroy {

  @Input() recipients: Array<ShareToObject> = [];
  @Input() hideRemoveAndAddMore = false;

  @Output() removeSelectedRecipient: EventEmitter<ShareToObject> = new EventEmitter();
  @Output() clickedSelectedRecipient: EventEmitter<ShareToObject> = new EventEmitter();
  @Output() openRecipientsDialog: EventEmitter<void> = new EventEmitter();

  showSlider = false;
  slidesContainer: HTMLElement;
  thumbsSwiper: any;

  slideOpts: SwiperOptions = {
    slidesPerView: 1.8,
    spaceBetween: 10,
    navigation: this.isDesktop
  };

  private slidesResizeObserver: any;
  private slidesContainerWidth = 0;

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.slidesContainer = this.el.nativeElement.querySelector('.selected-recipients-cards');
    this.setSlidesContainerElementResizeObserver();
  }

  ngOnDestroy(): void {
    this.terminateResizeObserver();
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  setSlidesContainerElementResizeObserver(): void {

    if (!this.slidesResizeObserver) {
      this.slidesResizeObserver = new ResizeObserver(() => {
        this.zone.run(() => {
          if (this.slidesContainer?.clientWidth > 0 &&
            this.slidesContainer.clientWidth !== this.slidesContainerWidth &&
            this.recipients?.length) {
            this.slidesContainerWidth = this.slidesContainer.clientWidth;
            this.updateSlidesPerView();
          }
        });
      });
    }

    this.slidesResizeObserver?.observe(this.slidesContainer);
  }

  updateSlidesPerView(): void {
    this.slideOpts.slidesPerView = this.calculateSlidesPerView();

    this.showSlider = false;

    setTimeout(() => {
      this.showSlider = true;
    }, 100);
  }

  calculateSlidesPerView(): number {
    return this.slidesContainer?.clientWidth / (70 + this.slideOpts.spaceBetween); // card width: 65px
  }

  triggerRemoveSelectedRecipient(recipient: ShareToObject): void {
    this.removeSelectedRecipient.emit(recipient);
  }

  triggerClickedSelectedRecipient(recipient: ShareToObject): void {
    this.clickedSelectedRecipient.emit(recipient);
  }

  editSelectedRecipients(): void {
    this.openRecipientsDialog.emit();
  }

  private terminateResizeObserver(): void {
    this.slidesResizeObserver?.unobserve(this.slidesContainer);
    this.slidesResizeObserver?.disconnect();
  }

}
