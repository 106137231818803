import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { Article, Video } from 'src/app/services/yeti-protocol/article';
import { ActionSource } from '../../services/yeti-protocol/tracking';
import { TopRecommendationType } from 'src/app/services/yeti-protocol/utils/enums';

@Component({
  selector: 'app-top-recommended-article-video',
  templateUrl: './top-recommended-article-video.component.html',
  styleUrls: ['./top-recommended-article-video.component.scss'],
})
export class TopRecommendedArticleVideoComponent {

  @Input() item: Article | Video;
  @Input() recommendedBy: string;
  @Input() source: ActionSource | string;

  @Output() articleOpenedEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  openArticleVideoPage(): void {
    if (!this.item._id) {
      return;
    }
    let linkToRedirect = '';
    linkToRedirect = [this.contextService.currentContext.homePath, 'article', this.item._id].join('/');

    const source = this.source || ActionSource.recommendations;
    linkToRedirect = linkToRedirect + '?source=' + source;

    if (this.recommendedBy) {
      linkToRedirect = linkToRedirect + '&recommendedBy=' + this.recommendedBy;
    }
    this.router.navigateByUrl('/' + linkToRedirect);
    this.articleOpenedEvent.emit();
  }

  get type(): string {
    return this.item?.type as any === TopRecommendationType.aoVideo || this.item?.type as any === TopRecommendationType.video
      ? 'Video' : this?.item?.type;
  }

}
