import { Component, HostBinding } from '@angular/core';
import { Responsive3ColumnsMode } from 'src/app/services/utils/responsive-3-column-mode.enum';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';

@Component({
  selector: 'app-two-column-layout',
  templateUrl: './two-column-layout.component.html',
  styleUrls: ['./two-column-layout.component.scss'],
})
export class TwoColumnLayoutComponent {

  constructor(public responsiveUtilsService: ResponsiveUtilsService) { }

  @HostBinding('class') get viewMode(): Responsive3ColumnsMode {
    return this.mode;
  }

  get isLargeScreen(): boolean {
    return this.mode === Responsive3ColumnsMode.LargeScreen;
  }

  private get mode(): Responsive3ColumnsMode{
    return this.responsiveUtilsService.threeColumnsMode;
  }
}
