// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --image-size: 60px;
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty-state-container .image ion-img {
  width: var(--image-size);
  height: var(--image-size);
}
.empty-state-container .icon app-icon {
  font-size: var(--image-size);
  height: var(--image-size);
}
.empty-state-container .title {
  margin-top: 10px;
  color: var(--mi-black-color-2);
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  font-weight: bold;
  font-style: normal;
  text-align: center;
}
.empty-state-container .message {
  width: 60%;
  text-align: center;
  margin-top: 5px;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
