// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --side-padding: 20px;
}

.my-groups-container {
  width: 100%;
  height: 100%;
}
.my-groups-container.grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
}
.my-groups-container .create-group-button-container {
  grid-row: 1;
  grid-column: 1;
  background-color: var(--mi-white-color);
  padding: 10px;
}
.my-groups-container .create-group-button-container app-create-group-button ::ng-deep button {
  margin-top: 0;
}
.my-groups-container app-vertical-list {
  width: 100%;
  height: 100%;
  grid-row: 2;
  grid-column: 1;
}
.my-groups-container app-vertical-list .list-container {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 20px;
}
.my-groups-container app-vertical-list .list-container app-groups-row-list-item {
  --side-padding: 10px;
}
.my-groups-container .empty-state-wrapper .empty-state-without-scroll {
  display: block;
}
.my-groups-container .empty-state-wrapper .empty-state-with-scroll {
  width: 100%;
  height: 100%;
  display: none;
}
.my-groups-container .empty-state-wrapper .empty-state-with-scroll ion-content {
  --background-color: var(--mi-white-color);
}
@media all and (max-height: 800px) {
  .my-groups-container .empty-state-wrapper {
    width: 100%;
    height: 100%;
  }
  .my-groups-container .empty-state-wrapper .empty-state-without-scroll {
    display: none;
  }
  .my-groups-container .empty-state-wrapper .empty-state-with-scroll {
    display: block;
  }
}
.my-groups-container .empty-state-container {
  grid-row: 2;
  grid-column: 1;
  background-color: var(--mi-white-color);
  padding-top: 60px;
  padding-bottom: 105px;
}
.my-groups-container .empty-state-container .empty-state-wrapper {
  max-width: 340px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 25px;
}
.my-groups-container .empty-state-container .empty-state-wrapper app-generic-empty-state {
  --image-size: 180px;
}
.my-groups-container .empty-state-container .empty-state-wrapper app-generic-empty-state ::ng-deep .empty-state-container .icon {
  padding-bottom: 25px;
}
.my-groups-container .empty-state-container .empty-state-wrapper app-generic-empty-state ::ng-deep .empty-state-container .icon app-icon {
  --icon-size: 180px;
  height: 46px;
}
.my-groups-container .empty-state-container .empty-state-wrapper app-generic-empty-state ::ng-deep .empty-state-container .message {
  width: 90%;
}
.my-groups-container .empty-state-container .empty-state-wrapper .button-container ion-button {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
