import { Component, Input, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActionSource, ActionTracked, OrcidOriginOpenedTrackingParam, TrackingRequest } from '../../services/yeti-protocol/tracking';
import { AuthenticationOrcIdConfig } from '../../../config/config.model';
import appConfig from 'src/config/config';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';
import { LinkOpenerService } from '../../services/link-opener.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

interface OrcidProfileLinkComponentConfig {
  authenticationOrcId: AuthenticationOrcIdConfig;
}

@Component({
  selector: 'app-orcid-profile-link',
  templateUrl: './orcid-profile-link.component.html',
  styleUrls: ['./orcid-profile-link.component.scss'],
})
export class OrcidProfileLinkComponent implements OnInit {

  @Input() orcIdProfileLinkTemplate: TemplateRef<any>;
  @Input() source: ActionSource;
  @Input() userId!: string;
  @Input() orcId!: string;

  config: OrcidProfileLinkComponentConfig = appConfig;

  constructor(
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private linkOpenerService: LinkOpenerService,
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface,
  ) { }

  ngOnInit(): void { }

  openOrcIdProfile(event: Event): void {

    if (event) {
      this.uiUtilsService.stopEventPropagation(event);
    }

    const serverUrl = this.config.authenticationOrcId.serverUrl;

    if (!serverUrl?.length && !this.orcId?.length) {
      return;
    }

    const url = `${serverUrl}/${this.orcId}`;
    this.trackOpenOrcIdProfile(url)

    this.linkOpenerService.open(url);
  }

  trackOpenOrcIdProfile(orcIdProfileUrl: string): void {

    const paramsToTrack: OrcidOriginOpenedTrackingParam = {
      source: this.source || ActionSource.unspecified,
      reference: orcIdProfileUrl,
      userId: this.userId || '',
      orcid: this.orcId || '',
      objectType: 'profile'
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.orcidOriginOpened,
      params: paramsToTrack
    };

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track orcid profile opened action');
    });
  }

}
