import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import appConfig from 'src/config/config';

import { SchemaValidatorService } from '../schema-validator.service';
import { AuthService } from '../auth/auth.service';
import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { UploadService } from '../upload.service';
import {
  ExternalArticle,
  FetchArticleRequest,
  FetchArticleResponse,
  FindIdForArticleResponse,
  FindIdForArticleRequest
} from '../yeti-protocol/external-article';
import { firstValueFrom } from 'rxjs';
import { Base64String, UploadFile } from '../yeti-protocol/upload';

interface ExternalArticleServiceConfig {
  backendUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ExternalArticleService {

  config: ExternalArticleServiceConfig = appConfig;

  constructor(
    private schemaValidator: SchemaValidatorService,
    private authService: AuthService,
    private uploadService: UploadService) { }

    /**
		*   Search article into article store ba external article url.
		*   If article is not in the store, the article metadata is requested
		*		by checking Opend Graph (OG) data by external article url.
		*		Content of OG fields depends on the presence OG metadata on the article url
		*/
		fetchArticle(externalArticleUrl: string): Promise<ExternalArticle>{
      const url = `${this.config.backendUrl}articles/ogmeta`;
      const requestParams: FetchArticleRequest = {
        url: externalArticleUrl
      }
      return firstValueFrom(this.authService.secureGet<FetchArticleResponse>(url, {
        params: toAuthRequestParams(requestParams)
      }).pipe(
        this.schemaValidator.isValidOperator<FetchArticleResponse>('FetchArticleResponse')
      ));
		}


    /**
		*   Search id for article by article object.
		*   Puts article into articles store if it is not there.
		*
		*  Artilce is searched based on the field 'url'.
    *
    *  @return article id
		*/
		findIdForArticle(article: ExternalArticle, contextKey: string): Promise<string>{
      const url = `${this.config.backendUrl}articles/check`;
      return firstValueFrom(this.authService.securePost<FindIdForArticleRequest, FindIdForArticleResponse>(url, article, {
        params: toAuthRequestParams({appId: contextKey})
      }).pipe(
          this.schemaValidator.isValidOperator<FindIdForArticleResponse>('FindIdForArticleResponse'),
          map(res => {
            return res.articleId;
          })
        ));
		}

		// Uploads PDF file and creates new pdf article in DB from it. Returns id of the created article.
		addPdfArticle(article: ExternalArticle, pdfFile: File | Base64String, contextKey: string): Promise<string>{
			if(!this.uploadService){
        return Promise.reject('no upload service available');
			}
      const uploadFile: UploadFile = {
        key: 'file',
        fileName: 'article.pdf',
        file: pdfFile,
        mimeType: 'application/pdf'
      };
      return this.uploadService.uploadPdfArticle(uploadFile , article, contextKey);
		}

}
