import { Component, OnInit, Input, Output, ElementRef, NgZone, OnDestroy, EventEmitter } from '@angular/core';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { SharedWith } from 'src/app/services/yeti-protocol/clinical-case';

// import Swiper core and required modules
import SwiperCore, { Navigation, SwiperOptions, Thumbs } from 'swiper';
import {ActionSource} from '../../services/yeti-protocol/tracking';
// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

@Component({
  selector: 'app-clinical-case-shared-with-slider',
  templateUrl: './clinical-case-shared-with-slider.component.html',
  styleUrls: ['./clinical-case-shared-with-slider.component.scss'],
})
export class ClinicalCaseSharedWithSliderComponent implements OnInit, OnDestroy {

  @Input() clinicalCaseSharedWith: Array<SharedWith>;
  @Input() hideSeeAll: boolean;
  @Input() source: ActionSource;

  @Output() addMoreSharedWith: EventEmitter<void> = new EventEmitter();
  @Output() seeAllSharedWith: EventEmitter<void> = new EventEmitter();

  showSlider = false;
  slidesContainer: HTMLElement;
  thumbsSwiper: any;

  slideOpts: SwiperOptions = {
    slidesPerView: 1.8,
    spaceBetween: 10,
    navigation: this.isDesktop
  };

  private slidesResizeObserver: any;
  private slidesContainerWidth = 0;

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.slidesContainer = this.el.nativeElement.querySelector('.slider-container');
    this.setSlidesContainerElementResizeObserver();
  }

  ngOnDestroy(): void {
    this.terminateResizeObserver();
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  setSlidesContainerElementResizeObserver(): void {

    if (!this.slidesResizeObserver) {
      this.slidesResizeObserver = new ResizeObserver(() => {
        this.zone.run(() => {
          if (this.slidesContainer?.clientWidth > 0 &&
            this.slidesContainer.clientWidth !== this.slidesContainerWidth &&
            this.clinicalCaseSharedWith?.length) {
            this.slidesContainerWidth = this.slidesContainer.clientWidth;
            this.updateSlidesPerView();
          }
        });
      });
    }

    this.slidesResizeObserver?.observe(this.slidesContainer);
  }

  updateSlidesPerView(): void {
    this.slideOpts.slidesPerView = this.calculateSlidesPerView();

    this.showSlider = false;

    setTimeout(() => {
      this.showSlider = true;
    }, 100);
  }

  calculateSlidesPerView(): number {
    return this.slidesContainer?.clientWidth / (70 + this.slideOpts.spaceBetween); // card width: 65px
  }

  addMore(): void {
    this.addMoreSharedWith.emit();
  }

  seeAll(): void {
    this.seeAllSharedWith.emit();
  }

  private terminateResizeObserver(): void {
    this.slidesResizeObserver?.unobserve(this.slidesContainer);
    this.slidesResizeObserver?.disconnect();
  }

}
