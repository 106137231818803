import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-create-group-case-section-title',
  templateUrl: './create-group-case-section-title.component.html',
  styleUrls: ['./create-group-case-section-title.component.scss'],
})
export class CreateGroupCaseSectionTitleComponent {

  @Input() title: string;

}
