import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {

  @Input() src: string;
  @Input() blurred = false;
  @Input() alt = 'image';

  @Output() ionImgWillLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() ionImgDidLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() ionError: EventEmitter<void> = new EventEmitter<void>();
  @Output() click: EventEmitter<void> = new EventEmitter<void>();

  imageLoaded = false;

  constructor() { }

  ngOnInit(): void {}

  onClick(): void {
    this.click.emit();
  }

  onIonImgWillLoad(): void {
    this.ionImgWillLoad.emit();
  }

  onIonImgDidLoad(): void {
    this.imageLoaded = true
    this.ionImgDidLoad.emit();
  }

  onIonError(): void {
    this.ionError.emit();
  }

}
