import { InjectionToken } from '@angular/core';
import { Article } from '../my-stream.model';
import { Group } from '../yeti-protocol/chatter-api';
import { ClinicalCase } from '../yeti-protocol/clinical-case';
import { AOEvent } from '../yeti-protocol/event';
import { ArticleSearch, EventSearch, GroupSearch, SurgeryReferenceSearch } from '../yeti-protocol/search';
import { PublicProfile } from '../yeti-protocol/public-profile';

import { ObjectForSharing, SocialSharingApp } from './sharing.model';
import { OrcIdArticle } from '../yeti-protocol/article';

export interface SharingService {
  share(target: SocialSharingApp, objectForSharing: ObjectForSharing): Promise<boolean>;
  getArticleSharingObject(article: Article | ArticleSearch): ObjectForSharing;
  getOrcIdArticleSharingObject(article: OrcIdArticle): ObjectForSharing;
  getGroupSharingObject(group: Group | GroupSearch): ObjectForSharing;
  getSurgeryReferenceSharingObject(surgeryReference: SurgeryReferenceSearch): ObjectForSharing;
  getEventSharingObject(event: AOEvent | EventSearch): ObjectForSharing;
  getCaseSharingObject(clinicalCase: ClinicalCase): ObjectForSharing;
  getProfileSharingObject(profile: PublicProfile): ObjectForSharing;
}

export const SHARING_SERVICE = new InjectionToken<SharingService>('sharing.service');
