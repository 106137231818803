import { ImageObject, ImageType } from 'src/app/components/image-attachments-container/image-attachments-container.model';
import { isVideo } from 'src/app/services/attachments.model';
import { PersonalMediaGalleryDocument } from '../../services/yeti-protocol/personal-media-gallery';

function getImageType(image: PersonalMediaGalleryDocument): ImageType {
  if(isVideo(image.mimeType)){
    return ImageType.VIDEO;
  }
  return ImageType.IMAGE;
}

export function mapToImageObjects(
  images: Array<PersonalMediaGalleryDocument>,
  useFullImageUrl = false,
  watermarked = false): Array<ImageObject> {
  return images.map(image => {

    let url = '';

    if (useFullImageUrl) {
      if (watermarked) {
        url = image.fullWatermarkUrl;
      } else {
        url = image.fullUrl;
      }
    } else {
      if (watermarked) {
        url = image.watermarkedPreviewUrl;
      } else {
        url = image.previewUrl;
      }
    }

    return {
      _id: image._id,
      url: url,
      type: getImageType(image)
    };
  });
}
