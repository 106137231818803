import { Injectable, Optional } from '@angular/core';
import { Routes } from '@angular/router';
import { Observable, Subject } from 'rxjs';

// models
import { ContextConfigInterface } from '../../../config/config.model';
import { ContextSwitchService, OpenCloseContextSwitchMenu, UserContexts } from './context-switch.model';
import { UserProfile } from '../yeti-protocol/auth/mi';

import { contextForClient } from '../../contexts/utils';
import { TimeoutCache } from '../utils/timeout-cache';

// services
import { MIAOUtilsService } from '../auth/ao/mi-ao-utils.service.service';
import { AuthService } from '../auth/auth.service';
import { contextRoutesDict } from 'src/app/contexts/context-routes';

import appConfig from 'src/config/config';
import { PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF } from '../auth/mi/mi-auth.service';

@Injectable()
export class ContextSwitchServiceImpl implements ContextSwitchService {
  appConfig = appConfig;
  upcomingContext: ContextConfigInterface = null;
  userContextsUpdated = new Subject<void>();
  _cachedContexts: TimeoutCache<UserContexts> = new TimeoutCache<UserContexts>(1000);
  _cachedContextsUserId: string = null;
  _contextsPromise: Promise<UserContexts> = null;
  private openCloseContextSwitchMenuCommandDispatch: Subject<OpenCloseContextSwitchMenu> = new Subject();

  constructor(
    private authService: AuthService,
    @Optional() private miAoUtilsService: MIAOUtilsService
  ) {}

  onOpenCloseContextSwitchMenuCommand(): Observable<OpenCloseContextSwitchMenu> {
    return this.openCloseContextSwitchMenuCommandDispatch.asObservable();
  }

  openContextSwitchMenu(): void {
    this.openCloseContextSwitchMenuCommandDispatch.next(OpenCloseContextSwitchMenu.open);
  }

  closeContextSwitchMenu(): void {
    this.openCloseContextSwitchMenuCommandDispatch.next(OpenCloseContextSwitchMenu.close);
  }

  getUserContexts(userProfile: UserProfile): Promise<UserContexts>{
    // timed caching of the contexts
    const contexts = this._cachedContexts.value;
    if(this._cachedContextsUserId === userProfile.id){
      if(contexts){
        return Promise.resolve(contexts);
      }
      if(this._contextsPromise){
        return this._contextsPromise;
      }
    }
    this._cachedContextsUserId = userProfile.id;
    this._contextsPromise = this._getUserContexts(userProfile).then(userContexts => {
      this._cachedContexts.value = userContexts;
      setTimeout(() => {
        this._contextsPromise = null;
      }, 1);
      return userContexts;
    });
    return this._contextsPromise;
  }

  async _getUserContexts(userProfile: UserProfile): Promise<UserContexts> {
    if(!this.miAoUtilsService){
      // TODO: logic for not AO user contexts
      return null;
    }
    return this.miAoUtilsService.getUserRights(null, true)
      .catch(() => {
        return null;
      })
      .then(res => {
        let addedContextsKeys = res?.specialties;
        const availableContextsForApp = this.appConfig.contexts;

        if (addedContextsKeys && addedContextsKeys.length > 0) {
          addedContextsKeys = addedContextsKeys.map(ctxKey => {
            return contextForClient(ctxKey);
          });

          const addedContexts: Array<ContextConfigInterface> = this.mapContextKeysToAvailableAppContexts(addedContextsKeys);

          return {
            mainContext: addedContexts.find(ctx => ctx.key === userProfile.homeDevision),
            addedContexts,
            availableForAddingContexts: availableContextsForApp.filter((ctx: ContextConfigInterface) => {
              if(!addedContextsKeys.find(addedContextKey => addedContextKey === ctx.key)) {
                return ctx;
              }
            })
          }
        }

        return null;
      });

  }

  contextHasInterests(contextKey: string): Promise<boolean> {
    return this.authService.getProfile(contextKey, true, PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF)
      .then((profile: UserProfile) => {
        return profile?.interests?.length > 0;
      });
  }

  isContextBelongsToUser(contextKey: string): Promise<boolean> {
    return this.authService.getProfile(contextKey)
      .then(profile => {
        return this.getUserContexts(profile);
      })
      .then(userContexts => {

        if(userContexts.mainContext.key === contextKey){
          return true;
        }
        const foundContext = userContexts.addedContexts.find(ctx => {
          return ctx.key === contextKey;
        });
        return foundContext && foundContext.key === contextKey;
      });
  }

  getContextTabRoutes(contextKey: string): Routes {
    return contextRoutesDict[contextKey].tabRoutes;
  }

  getContextRootRoutes(contextKey: string): Routes {
    return contextRoutesDict[contextKey].rootRoutes;
  }

  private mapContextKeysToAvailableAppContexts(contextKeys: Array<string>): Array<ContextConfigInterface> {
    const availableContextsForApp = this.appConfig.contexts;
    const contexts: Array<ContextConfigInterface> = [];

    contextKeys.forEach((ctxKey: string) => {
      const context = availableContextsForApp.find(ctx => ctx.key === ctxKey);

      if (context) {
        contexts.push(context);
      }
    });

    return contexts;
  }
}
