// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --open-graph-info-padding-top: 7px;
  --open-graph-info-padding-bottom: 20px;
  --open-graph-info-padding-start: 13px;
  --open-graph-info-padding-end: 13px;
  --open-graph-info-padding-width: 100%;
  --open-graph-info-image-height: 142px;
  --open-graph-info-row-gap: 5px;
  --open-graph-info-background-color: var(--mi-light-purple-1-color);
  --open-graph-info-image-border-radius: 10px;
}

.open-graph-info-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  row-gap: var(--open-graph-info-row-gap);
  background-color: var(--open-graph-info-background-color);
  padding-top: var(--open-graph-info-padding-top);
  padding-bottom: var(--open-graph-info-padding-bottom);
  padding-left: var(--open-graph-info-padding-start);
  padding-right: var(--open-graph-info-padding-end);
}
.open-graph-info-container .image-container ion-thumbnail {
  width: var(--open-graph-info-image-width);
  height: var(--open-graph-info-image-height);
  --border-radius: var(--open-graph-info-image-border-radius);
}
.open-graph-info-container .image-container ion-thumbnail ion-img {
  width: var(--open-graph-info-image-width);
  height: var(--open-graph-info-image-height);
}
.open-graph-info-container .link-title-container {
  overflow: hidden;
}
.open-graph-info-container .link-title-container .text {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.open-graph-info-container .link-description-container {
  max-height: 40px;
  overflow: hidden;
}
.open-graph-info-container .link-description-container .text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-regular);
  color: var(--mi-grey-color);
  margin: 0;
}
.open-graph-info-container .website-link-container {
  overflow: hidden;
  margin-top: 10px;
}
.open-graph-info-container .website-link-container .text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-regular);
  color: var(--mi-grey-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
