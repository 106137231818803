import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { UserProfile } from '../services/yeti-protocol/auth/mi';

@Injectable({
  providedIn: 'root'
})
export class VerifyPageGuard  {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  async canActivate(
    _route: ActivatedRouteSnapshot
  ): Promise<boolean> {

    const context = await this.getLoggedInUserDefaultContext();

    if (!context) {
      return this.router.navigateByUrl('/auth', { state: { preventDeepLinkCheck: true } });
    } else {
      return this.router.navigateByUrl(`/${context}/verify`);
    }
  }

  getLoggedInUserDefaultContext(): Promise<string> {
    return new Promise((resolve) => {
      this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {

        if (!userProfile) {
          resolve(null);
        }

        resolve(userProfile?.homeDevision);
      });
    });
  }
}
