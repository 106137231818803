// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --group-public-padding-top: 15px;
  --group-public-padding-bottom: 15px;
  --group-public-padding-start: 15px;
  --group-public-padding-end: 15px;
  --group-public-background-color: var(--mi-background-color);
  --group-public-cards-background-color: var(--mi-white-color);
}

.group-public-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  background-color: var(--group-public-background-color);
  padding-bottom: var(--ion-safe-area-bottom);
}
.group-public-container .top-bar-container {
  width: 100%;
  height: 100%;
  grid-row: 1;
  grid-column: 1;
}
.group-public-container ion-content {
  --background: var(--mi-white-color);
  width: 100%;
  height: 100%;
  grid-row: 2;
  grid-column: 1;
}
.group-public-container ion-content .container {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 8px;
  background: var(--group-public-background-color);
}
.group-public-container ion-content .container .card-title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-1);
  margin: 0;
  text-align: left;
}
.group-public-container ion-content .container .group-info-container group-info-card {
  --group-info-card-padding-top: var(--group-public-padding-top);
  --group-info-card-padding-bottom: 18px;
  --group-info-card-padding-start: var(--group-public-padding-start);
  --group-info-card-padding-end: var(--group-public-padding-end);
  --group-info-card-background-color: var(--group-public-cards-background-color);
}
.group-public-container ion-content .container .about-this-group-container .about-this-group-card {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  background-color: var(--group-public-cards-background-color);
  padding: 20px var(--group-public-padding-end) 25px var(--group-public-padding-start);
}
.group-public-container ion-content .container .about-this-group-container .about-this-group-card .about-text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: left;
}
.group-public-container ion-content .container .ao-members-only-container .ao-members-only-card {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  background-color: var(--group-public-cards-background-color);
  padding: 20px var(--group-public-padding-end) 25px var(--group-public-padding-start);
}
.group-public-container ion-content .container .ao-members-only-container .ao-members-only-card .ao-members-only-text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: left;
}
.group-public-container ion-content .container .owner-container .owner-card {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
  background-color: var(--group-public-cards-background-color);
  padding: 20px var(--group-public-padding-end) 25px var(--group-public-padding-start);
}
.group-public-container ion-content .container .owner-container .owner-card .owner {
  width: 100%;
  height: 100%;
}
.group-public-container ion-content .container .moderators-container .moderators-card {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  background-color: var(--group-public-cards-background-color);
  padding: 20px var(--group-public-padding-end) 25px var(--group-public-padding-start);
}
.group-public-container ion-content .container .moderators-container .moderators-card .moderator {
  width: 100%;
  height: 100%;
}
.group-public-container ion-content .container .top-contributors-container .top-contributors-card {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  background-color: var(--group-public-cards-background-color);
  padding: 20px var(--group-public-padding-end) 25px var(--group-public-padding-start);
}
.group-public-container ion-content .container .top-contributors-container .top-contributors-card .top-contributors-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.group-public-container ion-content .container .top-contributors-container .top-contributors-card .top-contributors-header .see-all-text {
  color: var(--mi-grey-color-13);
}
.group-public-container ion-content .container .top-contributors-container .top-contributors-card .items-list app-top-contributors-list {
  height: 100%;
}
.group-public-container ion-content .container .interests-container .interests-card {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
  background-color: var(--group-public-cards-background-color);
  padding: 20px var(--group-public-padding-end) 25px var(--group-public-padding-start);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
