import { GroupsStreamItem, GroupsStreamSuccessResponse } from 'src/app/services/yeti-protocol/chatter-api';

export namespace GroupsFeed {
  export interface FetchGroupsFeedPayload {
    pageIndex?: number;
    pageSize?: number;
  }

  export class FetchGroupsFeed {
    static readonly type = '[Groups Feed] Fetch groups feed';
    constructor(public payloadParams: FetchGroupsFeedPayload) { }
  }

  export class FetchGroupsFeedSuccess {
    static readonly type = '[Groups Feed] Fetch groups feed success';
    constructor(
      public payloadParams: FetchGroupsFeedPayload,
      public response: GroupsStreamSuccessResponse
    ) { }
  }

  export class FetchGroupsFeedFailed {
    static readonly type = '[Groups Feed] Fetch groups feed failed';
  }

  export class InsertGroupsFeedItemBeforeIndex {
    static readonly type = '[Groups Feed] Insert groups feed item before index';
    constructor(
      public item: GroupsStreamItem,
      public index: number
    ) { }
  }

  export class RemoveGroupsFeedItem {
    static readonly type = '[Groups Feed] Remove groups feed item at index';
    constructor(
      public item: GroupsStreamItem,
    ) { }
  }

  export class UpdateGroupsFeedItem {
    static readonly type = '[Groups Feed] Update groups feed item';
    constructor(
      public item: GroupsStreamItem
    ) { }
  }

  export class UpdateGroupsFeedItemsOwnerFollowingStatus {
    static readonly type = '[Groups Feed] Update groups feed items owner following status';
    constructor(public userId: string, public isFollower: boolean) { }
  }
}
