// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --confirm-dialog-padding-top: 25px;
  --confirm-dialog-padding-bottom: 20px;
  --confirm-dialog-padding-start: 20px;
  --confirm-dialog-padding-end: 20px;
}

.confirm-dialog-container {
  width: 100%;
  height: 100%;
  padding-top: var(--confirm-dialog-padding-top);
  padding-bottom: var(--confirm-dialog-padding-bottom);
  display: flex;
  flex-direction: column;
}
.confirm-dialog-container .content-container {
  overflow-y: hidden;
}
.confirm-dialog-container .content-container .input-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.confirm-dialog-container .image-container {
  padding-bottom: 10px;
}
.confirm-dialog-container .image-container app-icon {
  --icon-size: 62px;
}
.confirm-dialog-container .title-container {
  padding-left: var(--confirm-dialog-padding-start);
  padding-right: var(--confirm-dialog-padding-end);
  margin-bottom: 11px;
}
.confirm-dialog-container .title-container .title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-black-color);
  margin: 0;
  text-align: center;
}
.confirm-dialog-container ion-content {
  --padding-start: var(--confirm-dialog-padding-start);
  --padding-end: var(--confirm-dialog-padding-end);
}
.confirm-dialog-container ion-content .message {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  text-align: center;
  white-space: pre-line;
}
.confirm-dialog-container .actions-container {
  margin-top: 26px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
