import { ContextConfigInterface } from '../config.model';

export class ContextConfig implements ContextConfigInterface {
  key = 'cmf';
  label = 'Craniomaxillofacial Surgery';
  homePath = '/cmf';
  logoImageName = 'ao-cmf.svg';
  iconName = 'spec-cmf';
  aoMembershipUrl = 'https://www.aofoundation.org/cmf/membership';
  moreEventsLink = 'https://www.aofoundation.org/cmf/education/courses-events';
}
