import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserProfile } from '../yeti-protocol/auth/mi';
import appConfig from 'src/config/config';
import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { DeletePersonalMediaGalleryDocumentRequestParams } from '../yeti-protocol/personal-media-gallery';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';


@Injectable({
  providedIn: 'root'
})
export class PersonalMediaGalleryService {

  userProfile: UserProfile;
  userProfileSub: Subscription;

  constructor(
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) {
    this.userProfileSub = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.userProfile = userProfile;
    });
  }

  deleteDocument(documentId: string): Promise<any> {

    const appId = this.contextService.currentContext.key;
    const deleteUrl = `${appConfig.chatterUrl}documents/${documentId}`;
    const requestParams: DeletePersonalMediaGalleryDocumentRequestParams = {
      appId: appId
    };

    if (!documentId || documentId.length === 0) {
      return Promise.reject('Document id is missing');
    }

    const options = {
      params: toAuthRequestParams(requestParams)
    };

    return firstValueFrom(this.authService.secureDelete<any, any>(deleteUrl, null, options));
  }

  downloadDocumentByUrl(url: string): Promise<File> {

    if (!url || url.length === 0) {
      return Promise.reject({ message: 'Url missing' });
    }

    return new Promise((resolve, reject) => {

      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.responseType = 'blob';

      xhr.onload = async () => {
        resolve(xhr.response);
      };

      xhr.onerror = (err) => {
        reject(err);
      }

      xhr.send();
    });
  }
}
