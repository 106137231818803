import { ContextConfigInterface } from '../config.model';

export class ContextConfig implements ContextConfigInterface {
  key = 'trauma';
  label = 'Orthopedic Trauma and Reconstruction';
  homePath = '/trauma';
  homePathAliases = [
    '/orthopedics'
  ];
  logoImageName = 'ao-ort.svg';
  iconName = 'spec-ort';
  aoMembershipUrl = 'https://www.aofoundation.org/trauma/membership';
  moreEventsLink = 'https://www.aofoundation.org/trauma/education/courses-events';
}
