import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { ResponsiveUtilsService } from '../services/utils/responsive-utils.service';
import { ResponsiveMode } from '../services/utils/responsive-mode.enum';
import { IonContent } from '@ionic/angular';

@Directive({
  selector: '[app-scrolling-tracker]',
  exportAs: 'scrollingTracker'
})
export class ScrollingTrackerDirective implements AfterViewInit, OnDestroy {

  @Input() topScrollThreshold = 0;
  @Input() mobileOnly = true;
  @Input() ionContentElement: IonContent;
  @Input() disabled: boolean;

  @Output() scrolled: EventEmitter<boolean> = new EventEmitter();

  private destroy = new Subject<void>();
  private destroy$ = this.destroy.asObservable();
  private scrollTop = 0;
  private scrollPercentage = 0;

  constructor(
    private elementRef: ElementRef,
    private responsiveUtilsService: ResponsiveUtilsService
  ) {
  }

  ngAfterViewInit(): void {

    if (this.ionContentElement) {
      this.ionContentElement?.ionScroll?.pipe(
        takeUntil(this.destroy$),
      ).subscribe(e => {
        this.setScrollPercentageIonContentElement(e);
        this.checkAndEmitTopScroll(this.getYPositionIonContentElement(e));
      });
    } else {
      fromEvent(this.elementRef.nativeElement, 'scroll').pipe(takeUntil(this.destroy$))
        .subscribe((e: Event) => {
          this.checkAndEmitTopScroll(this.getYPositionBasicElement(e));
        });
    }
  }

  async setScrollPercentageIonContentElement(e: Event): Promise<void> {

    const scrollElement = await this.ionContentElement.getScrollElement();

    const scrollPosition = (e as any).detail.scrollTop;
    const totalContentHeight = scrollElement.scrollHeight;
    const viewportHeight = this.elementRef.nativeElement.offsetHeight;

    this.scrollPercentage = scrollPosition / (totalContentHeight - viewportHeight);
  }

  checkAndEmitTopScroll(scrollTop: number): void {

    if (this.disabled) {
      return;
    }

    if (this.scrollPercentage > 0.99 || this.scrollPercentage < 0) {
      return;
    }

    if (this.mobileOnly && this.responsiveUtilsService.mode !== ResponsiveMode.Mobile) {
      this.scrolled.emit(false);
      this.scrollTop = scrollTop;
      return;
    }

    if (scrollTop >= 0 &&
      scrollTop >= this.scrollTop) {
      this.scrolled.emit(true);
      this.scrollTop = scrollTop;

      this.waitUntilScrollIsStable();

      return;
    }

    this.scrollTop = scrollTop;
    this.scrolled.emit(false);
    this.waitUntilScrollIsStable();
  }

  private waitUntilScrollIsStable(): void {
    this.disabled = true;
    setTimeout(() => {
      this.disabled = false;
    }, 50);
  }

  getYPositionBasicElement(e: Event): number {
    return (e?.target as Element)?.scrollTop;
  }

  getYPositionIonContentElement(e: Event): number {
    return (e as any)?.detail?.scrollTop;
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
