// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --create-group-content-group-select-width: 100%;
  --create-group-content-group-select-height: 100%;
  --create-group-content-group-select-padding-top: 20px;
  --create-group-content-group-select-padding-bottom: 20px;
  --create-group-content-group-select-padding-start: 15px;
  --create-group-content-group-select-padding-end: 15px;
  --create-group-content-group-select-margin-top: 0px;
  --create-group-content-group-select-margin-bottom: 0px;
  --create-group-content-group-select-margin-start: 0px;
  --create-group-content-group-select-margin-end: 0px;
  --create-group-content-group-select-background-color: var(--mi-white-color);
  --create-group-content-group-select-text-color: var(--mi-black-color);
  --create-group-content-group-select-text-color-active: var(
    --mi-primary-color
  );
  --create-group-content-group-select-icon-color: var(--mi-color-text-lite);
  --create-group-content-group-select-icon-color-active: var(
    --mi-primary-color
  );
  --create-group-content-group-select-text-background-active: var(
    --mi-light-blue-color
  );
}

.create-group-content-group-select-container {
  display: grid;
  width: var(--create-group-content-group-select-width);
  height: var(--create-group-content-group-select-height);
  padding: var(--create-group-content-group-select-padding-top) var(--create-group-content-group-select-padding-end) var(--create-group-content-group-select-padding-bottom) var(--create-group-content-group-select-padding-start);
  margin: var(--create-group-content-group-select-margin-top) var(--create-group-content-group-select-margin-end) var(--create-group-content-group-select-margin-bottom) var(--create-group-content-group-select-margin-start);
  background-color: var(--create-group-content-group-select-background-color);
  border-bottom: 1.5px solid var(--mi-background-color);
}
.create-group-content-group-select-container .groups-select {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
.create-group-content-group-select-container.group-selected {
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  -moz-column-gap: 25px;
       column-gap: 25px;
  align-items: center;
}
.create-group-content-group-select-container.group-selected .select-button .icon {
  color: var(--create-group-content-group-select-icon-color-active);
}
.create-group-content-group-select-container.group-selected .select-button .label-container {
  display: flex;
  position: relative;
}
.create-group-content-group-select-container.group-selected .select-button .label-container .label {
  background-color: var(--create-group-content-group-select-text-background-active);
  border-radius: 8px;
  padding: 0 10px;
  color: var(--create-group-content-group-select-text-color-active);
}
.create-group-content-group-select-container:not(.group-selected) .select-button .icon {
  color: var(--create-group-content-group-select-icon-color);
}
.create-group-content-group-select-container:not(.group-selected) .select-button .label-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.create-group-content-group-select-container:not(.group-selected) .select-button .label-container .label {
  color: var(--create-group-content-group-select-text-color);
}
.create-group-content-group-select-container:not(.group-selected) .select-button .label-container .icon {
  grid-column: unset;
  grid-row: unset;
  color: var(--mi-black-color);
}
.create-group-content-group-select-container .select-button {
  display: grid;
  grid-template-columns: min-content auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  grid-row: 1;
  grid-column: 1;
}
.create-group-content-group-select-container .select-button .icon {
  grid-row: 1;
  grid-column: 1;
  font-size: 24px;
}
.create-group-content-group-select-container .select-button .label-container {
  grid-row: 1;
  grid-column: 2;
  overflow: hidden;
  align-items: center;
  position: relative;
}
.create-group-content-group-select-container .select-button .label-container .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.create-group-content-group-select-container .select-button .label-container .icon {
  --icon-size: 12px;
  color: var(--mi-secondary-color-2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.create-group-content-group-select-container .select-button .label-container ion-spinner {
  width: 15px;
}
.create-group-content-group-select-container .group-image-container {
  grid-row: 1;
  grid-column: 2;
  height: 30px;
}
.create-group-content-group-select-container .group-image-container app-group-image {
  --group-image-size: 30px;
}
.create-group-content-group-select-container .label {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-black-color);
  margin: 0;
}

app-info-sheet .content ion-list ion-radio-group ion-item {
  --padding-start: 0;
  --padding-end: 0;
  margin-bottom: 13px;
}
app-info-sheet .content ion-list ion-radio-group ion-item:last-of-type {
  margin-bottom: 0;
}
app-info-sheet .content ion-list ion-radio-group ion-item .group-item {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto min-content;
  -moz-column-gap: 13px;
       column-gap: 13px;
}
app-info-sheet .content ion-list ion-radio-group ion-item .group-item .group-image-container {
  grid-row: 1;
  grid-column: 1;
}
app-info-sheet .content ion-list ion-radio-group ion-item .group-item .group-image-container app-group-image {
  --group-image-size: 68px;
  --group-image-border-radius: 5px;
}
app-info-sheet .content ion-list ion-radio-group ion-item .group-item .group-info-container {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
app-info-sheet .content ion-list ion-radio-group ion-item .group-item .group-info-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 47px;
}
app-info-sheet .content ion-list ion-radio-group ion-item .group-item .group-info-container app-multiline-text-with-ellipsis ::ng-deep {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-black-color);
}
app-info-sheet .content ion-list ion-radio-group ion-item .group-item .group-info-container .members-count {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
}
app-info-sheet .content ion-list ion-radio-group ion-item .group-item .radio-button-container {
  grid-row: 1;
  grid-column: 3;
  align-self: center;
}
app-info-sheet .content ion-list ion-radio-group ion-item .group-item .radio-button-container ion-radio {
  --color: var(--mi-color-text-lite);
  --color-checked: var(--mi-primary-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
