import { Component, Input } from '@angular/core';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { AOEventParticipant } from 'src/app/services/yeti-protocol/event';

@Component({
  selector: 'app-event-contributor-card',
  templateUrl: './event-contributor-card.component.html',
  styleUrls: ['./event-contributor-card.component.scss'],
})
export class EventContributorCardComponent {

  @Input() aoEventContributor: AOEventParticipant;

  constructor(
    private appNavController: AppNavController
  ){}

  onSendMessage(connectionId: string): void {
    this.appNavController.openMessenger(connectionId);
  }
}
