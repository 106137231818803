import { NgForm } from '@angular/forms';

export function isFieldValid(form: NgForm, fieldName: string): boolean {
  if(form){
    const control = form.controls[fieldName];
    if(control && control.touched && control.dirty){
      const strippedValue = control.value?.trim() || '';
      return strippedValue.length > 0 && control.valid;
    }
  }
}
