import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent {

  @Input() customBackButtonIcon: string;
  @Input() title: string;
  @Input() showActions: boolean;
  @Input() showCustomContainer: boolean;
  @Input() backIsCustomAction: boolean;
  @Input() noBorder: boolean;
  @Input() forceBackArrowIcon = false;

  @Output() back: EventEmitter<any> = new EventEmitter();

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private navContoller: NavControllerService) { }

  onBack(): void {
    if(this.backIsCustomAction){
      return this.back.emit();
    }
    this.navContoller.back();
  }

  get shouldShowActionsContainer(): boolean {
    return this.showActions && !this.showCustomContainer;
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

}
