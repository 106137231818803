import { Component, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';

// services
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

// models
import { OpenGraph } from 'src/app/services/yeti-protocol/open-graph';

@Component({
  selector: 'app-open-graph-info',
  templateUrl: './open-graph-info.component.html',
  styleUrls: ['./open-graph-info.component.scss'],
})
export class OpenGraphInfoComponent {

  @Input() openGraph: OpenGraph;
  @Output() openLink: EventEmitter<string> = new EventEmitter();

  imageLoadingFailed: boolean;

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  @HostListener('click', ['$event'])
  onLinkClick(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.openLink.emit(this.openGraph?.ogUrl);
  }

}
