import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StreamFilter } from 'src/app/services/yeti-protocol/my-stream';

@Component({
  selector: 'app-stream-filters-dialog',
  templateUrl: './stream-filters-dialog.component.html',
  styleUrls: ['./stream-filters-dialog.component.scss'],
})
export class StreamFiltersDialogComponent {

  filters: Array<StreamFilter> = [];
  streamType: string;
  isSelectedFilterRemoved: boolean;

  constructor(
    private modalController: ModalController
  ) { }

  close(): void {
    this.modalController.dismiss(this.isSelectedFilterRemoved);
  }

  selectedFilterRemoved(): void {
    this.isSelectedFilterRemoved = true;
  }

}
