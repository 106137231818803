import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonInfiniteScroll, IonRefresher } from '@ionic/angular';

export interface VerticalListLoadMoreData {
  page: number;
  infiniteScroll: IonInfiniteScroll
}

@Component({
  selector: 'app-vertical-list',
  templateUrl: './vertical-list.component.html',
  styleUrls: ['./vertical-list.component.scss'],
})
export class VerticalListComponent {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonRefresher) listRefresher: IonRefresher;

  @Input() loading: boolean;
  @Input() itemsCount: number;
  @Input() totalItemsCount: number;
  @Input() pageItemsCount: number;
  @Input() visibilityRootKey = '';
  @Input() topScrollThreshold = 0;
  @Input() disableScrollTracker: boolean;

  @Output() loadMore: EventEmitter<VerticalListLoadMoreData> = new EventEmitter();
  @Output() refresh: EventEmitter<IonRefresher> = new EventEmitter();
  @Output() scrolled: EventEmitter<boolean> = new EventEmitter();

  loadMoreItems(): void {

    if (this.itemsCount >= this.totalItemsCount) {
      this.disableInfiniteScroll(true);
      return;
    }

    const page = Math.floor(this.itemsCount / this.pageItemsCount);
    this.loadMore.emit({ page: page, infiniteScroll: this.infiniteScroll });
  }

  refreshList(): void {
    this.refresh.emit(this.listRefresher);
    this.disableInfiniteScroll(false);
  }

  onScrolled(scrolled: boolean): void {
    this.scrolled.emit(scrolled);
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

}
