// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --mi-card-actions-height: 74px;
  --mi-card-actions-padding-top: 10px;
  --mi-card-actions-padding-bottom: 10px;
  --mi-card-actions-padding-start: 10px;
  --mi-card-actions-padding-end: 10px;
  --mi-card-actions-background-color: var(--mi-light-purple-1-color);
}

.mi-card-actions-container {
  width: 100%;
  height: var(--mi-card-actions-height);
  background-color: var(--mi-light-purple-1-color);
  display: flex;
  align-items: center;
  padding-top: var(--mi-card-actions-padding-top);
  padding-bottom: var(--mi-card-actions-padding-bottom);
  padding-left: var(--mi-card-actions-padding-start);
  padding-right: var(--mi-card-actions-padding-end);
}
.mi-card-actions-container .action-sheet-trigger-button {
  justify-self: end;
}
.mi-card-actions-container .custom-button-actions ::ng-deep {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
}
.mi-card-actions-container .custom-button-actions.show-settings-btn ::ng-deep {
  grid-template-columns: auto min-content;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
