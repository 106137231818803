import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Journal } from '../yeti-protocol/journal';

export interface JournalActionContent {
  journal: Journal
}

@Injectable({
  providedIn: 'root'
})
export class JournalsDataService {

  private journalsChanged: Subject<JournalActionContent> = new Subject();

  triggerFollowUnfollowJournalAction(journal: Journal): void {
    this.journalsChanged.next({
      journal: journal
    });
  }

  getJournalChangesStream(): Observable<JournalActionContent> {
    return this.journalsChanged.asObservable();
  }
}
