// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interest-item-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.interest-item-container .interest-name {
  font-family: Suisse;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
}
.interest-item-container ion-checkbox {
  --background-checked: var(--mi-blue-color-4);
  --border-color: var(--mi-color-text-lite);
  --border-color-checked: var(--mi-blue-color-4);
  --border-radius: 3px;
  --checkmark-color: var(--mi-primary-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
