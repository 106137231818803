import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// components
import { GroupPublicComponent } from '../group-public/group-public.component';
// models
import { Group, GroupUserPermissions, GroupVisibility } from '../../services/groups/group.model';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import {
  AcceptRejectGroupInvitationResponse,
  JoinGroupResponse,
  LeaveGroupMemberStatus,
  RequestToJoinGroupResponse
} from 'src/app/services/yeti-protocol/chatter-api';
import { BaseUserProfile, NUMBER_USER_IMG_BUBBLES_GROUP_PAGE } from 'src/app/modules/users/users.model';
import { VerificationStatus } from 'src/app/services/verification.model';

// services
import { GroupsUIService } from '../../services/groups/groups-ui.service';
import { AuthService } from '../../services/auth/auth.service';
import { GroupsService } from '../../services/groups/groups.service';
import { InfoSheetService } from '../../modules/info-sheet/services/info-sheet.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { GroupParticipantsComponent } from '../../dialogs/group-participants/group-participants.component';
import { VerificationService } from 'src/app/services/verification.service';
import { ResponsiveUtilsService } from '../../services/utils/responsive-utils.service';
import { ItemTypes, RecommendationsBookmarksService } from 'src/app/services/recommendations-service/recommendations-bookmarks.service';
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import { CreateGroupService } from 'src/app/services/create-content/group/create-group.service';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import { UI_UTILS_SERVICE, UIUtilsServiceInterface } from 'src/app/services/utils/ui-utils.service.interface';
import { LinkOpenerService, TargetOfTheLink } from 'src/app/services/link-opener.service';
import appConfig from 'src/config/config';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { GroupsDataService } from 'src/app/services/groups/groups-data.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';

enum GroupActions {
  GROUP_PARTICIPANTS = 'GROUP_PARTICIPANTS',
  GROUP_DETAILS = 'GROUP_DETAILS',
  INVITE_PARTICIPANTS = 'INVITE_PARTICIPANTS',
  EDIT_GROUP = 'EDIT_GROUP',
  REPORT_GROUP = 'REPORT_GROUP',
  LEAVE_GROUP = 'LEAVE_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  SHARE_GROUP = 'SHARE_GROUP',
  RECOMMEND = 'RECOMMEND'
}

@Component({
  selector: 'app-group-info-card',
  templateUrl: './group-info-card.component.html',
  styleUrls: ['./group-info-card.component.scss'],
})
export class GroupInfoCardComponent implements OnInit, OnDestroy, OnChanges {

  @Input() group: Group;
  @Input() hideSettingsButton: boolean;

  @Output() refreshGroup: EventEmitter<any> = new EventEmitter();

  infoSheetActions: Array<InfoSheetActionItem> = new Array<InfoSheetActionItem>();
  showDefaultGroupImage: boolean;
  groupsUiService: GroupsUIService;
  groupUserPermissions: GroupUserPermissions;
  GroupVisibility = GroupVisibility;
  infoSheetId = 'group-info-card-info-sheet';
  GroupActions = GroupActions;
  requestingToJoin: boolean;
  joiningGroup: boolean;
  respondingToInvitation: boolean;
  private userProfile: UserProfile;
  private groupUserPermissionsSubscription: Subscription;
  private userProfileSubscription: Subscription;

  constructor(
    private groupsService: GroupsService,
    private authService: AuthService,
    private toast: ToastService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private infoSheetService: InfoSheetService,
    private modalController: ModalController,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
    private verificationService: VerificationService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private recommendationsService: RecommendationsBookmarksService,
    private sharingUIService: SharingUIService,
    private createGroupService: CreateGroupService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private linkOpener: LinkOpenerService,
    private groupsDataService: GroupsDataService
  ) {
    this.groupsUiService = new GroupsUIService();
  }

  ngOnInit(): void {

    this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);

    this.groupUserPermissionsSubscription = this.groupsUiService.groupUserPermissions.subscribe((permissions: GroupUserPermissions) => {
      this.groupUserPermissions = permissions;
    });

    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });

    setTimeout(() => {
      this.generateInfoSheetActions();
    }, 0);
  }

  ngOnDestroy(): void {
    this.groupUserPermissionsSubscription?.unsubscribe();
    this.userProfileSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group) {
      this.groupsUiService.setGroup(changes.group.currentValue);
    }
  }

  navigateToGroupPage(): void {
    if (this.isDesktop) {
      this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/group/${this.group._id}`, { replaceUrl: true });
    }
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  showSettings(event: Event): void {
    this.generateInfoSheetActions();
    if (this.isDesktop) {
      this.infoSheetService.openWeb(this.infoSheetActions, event)
        .then(action => {
          if (action && action.code) {
            action.handler();
          }
        });
    } else {
      this.infoSheetService.open(this.infoSheetId);
    }
  }

  closeSettings(): void {
    this.infoSheetService.close(this.infoSheetId);
  }

  async joinGroup(): Promise<void> {

    if (this.joiningGroup) {
      return;
    }

    this.joiningGroup = true;

    const canAccess = await this.uiUtilsService.checkAndPromptCanAccessAoMembersOnlyGroup(
      this.group?.AOMembersOnly,
      this.userProfile?.isAOMember,
      this.contextService?.currentContext?.aoMembershipUrl
    );

    if (!canAccess) {
      this.joiningGroup = false;
      return;
    }

    this.groupsService.joinGroup(this.group?._id, this.userProfile?.email, this.userProfile?.id)
      .then((_res: JoinGroupResponse) => {
        this.emitRefreshGroup();
        this.groupsService.triggerGroupChanges(this.group);
      }).catch(err => {
        console.log(err);
        if (err?.error?.error?.message?.errfor?.message) {
          this.showErrorToast(err?.error?.error?.message?.errfor?.message);
        }
      }).finally(() => {
        this.joiningGroup = false;
      });
  }

  presentShareDialog(group: Group): void {
    this.sharingUIService.showShareDialogForObject(group);
  }

  get groupMembersUsers(): Array<BaseUserProfile> {
    if (!this.group || !this.group?.members?.length) {
      return [];
    }

    return this.group?.members.slice(0, NUMBER_USER_IMG_BUBBLES_GROUP_PAGE).map(member => {
      return {
        userId: member.userId,
        profileImageUrl: member.profileImageUrl
      }
    });
  }

  get displayProfileImageBubbles(): boolean {
    return this.group?.totalMembersCount && this.group?.totalMembersCount > 0
  }

  async requestToJoin(): Promise<void> {

    if (this.requestingToJoin) {
      return;
    }

    this.requestingToJoin = true;

    const canAccess = await this.uiUtilsService.checkAndPromptCanAccessAoMembersOnlyGroup(
      this.group?.AOMembersOnly,
      this.userProfile?.isAOMember,
      this.contextService?.currentContext?.aoMembershipUrl
    );

    if (!canAccess) {
      this.requestingToJoin = false;
      return;
    }

    this.groupsService.requestToJoinGroup(this.group?._id, this.userProfile?.email, this.userProfile?.id)
      .then((_res: RequestToJoinGroupResponse) => {
        this.emitRefreshGroup();
        this.groupsService.triggerGroupChanges(this.group);
      }).catch(err => {
        console.log(err);
        if (err?.error?.error?.message?.errfor?.message) {
          this.showErrorToast(err?.error?.error?.message?.errfor?.message);
        }
      }).finally(() => {
        this.requestingToJoin = false;
      });
  }

  async acceptRejectGroupInvitation(status: boolean): Promise<void> {

    if (this.respondingToInvitation) {
      return;
    }

    this.respondingToInvitation = true;

    if (status) {
      const canAccess = await this.uiUtilsService.checkAndPromptCanAccessAoMembersOnlyGroup(
        this.group?.AOMembersOnly,
        this.userProfile?.isAOMember,
        this.contextService?.currentContext?.aoMembershipUrl
      );

      if (!canAccess) {
        this.respondingToInvitation = false;
        return;
      }
    }

    this.groupsService.acceptRejectGroupInvitation(this.group?._id, status, this.group?.memberRequestId, this.userProfile?.id)
      .then((_res: AcceptRejectGroupInvitationResponse) => {
        this.emitRefreshGroup();
      }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showErrorToast(err?.error?.error?.message?.errfor?.message);
        }
      }).finally(() => {
        this.respondingToInvitation = false;
      });
  }

  seeAllGroupParticipants(): void {
    if (this.groupUserPermissions?.seeParticipantsList) {
      this.openParticipantsList();
    }
  }

  openCommunityGuidelines(): void {
    const url = `${appConfig?.webUrl}${this.contextService.currentContext.homePath}/community-guidelines`;
    this.linkOpener.open(url, TargetOfTheLink.SystemBrowser);
  }

  protected recommendGroup(): void {
    this.recommendationsService.addRecommendationBookmarkItem('group', ItemTypes.RECOMMENDATIONS, this.group?._id)
      .then(response => {
        if (response && response.success) {
          this.group.hasRecommended = true;
          this.toast.show('app.common.item-recommended');
        }
      })
  }

  protected unRecommendGroup(): void {
    this.recommendationsService.removeRecommendationBookmarkItem('group', ItemTypes.RECOMMENDATIONS, this.group?._id)
      .then(response => {
        if (response && response.success) {
          this.group.hasRecommended = false;
          this.toast.show('app.common.item-un-recommended');
        }
      })
  }

  private emitRefreshGroup(): void {
    this.refreshGroup.emit();
  }

  private leaveGroup(): void {
    this.groupsService.leaveGroup(this.group?._id, this.userProfile?.id, LeaveGroupMemberStatus.LEFT).then(() => {
      this.groupsDataService.triggerGroupLeftAction(this.group);
      this.router.navigateByUrl(this.contextService.currentContext.homePath);
    }).catch(err => {
      if (err?.error?.error?.message?.errfor?.message) {
        this.showErrorToast(err?.error?.error?.message?.errfor?.message);
      }
    });
  }

  private showErrorToast(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  private async presentLeaveGroupConfirm(): Promise<void> {
    await this.groupsService.presentLeaveGroupConfirm()
      .then((confirm: boolean) => {
        if (confirm) {
          this.leaveGroup();
        }
      });
  }

  private deleteGroup(): void {
    this.groupsService.deleteGroup(this.group?._id).then(() => {
      this.groupsDataService.triggerGroupDeletedAction(this.group);
      this.router.navigateByUrl(this.contextService.currentContext.homePath);
    }).catch(err => {
      if (err?.error?.error?.message?.errfor?.message) {
        this.showErrorToast(err?.error?.error?.message?.errfor?.message);
      }
    });
  }

  private async presentDeleteGroupConfirm(): Promise<void> {
    await this.groupsService.presentDeleteGroupConfirm()
      .then((confirm: boolean) => {
        if (confirm) {
          this.deleteGroup();
        }
      });
  }

  private async openParticipantsList(): Promise<void> {
    const context = this.contextService.currentContext.key;
    const groupParticipants = await this.modalController.create({
      component: GroupParticipantsComponent,
      cssClass: 'group-participants-dialog',
      componentProps: {
        context,
        groupId: this.group._id
      }
    });
    groupParticipants.present();
    groupParticipants.onDidDismiss().then(res => {
      if (res && res?.data?.actionKey === 'open-user-profile') {
        const userId = res?.data.userId;
        const source = res?.data.source;
        let linkToRedirect = [this.contextService.currentContext.homePath, 'public', 'profile', userId].join('/');
        if (source) {
          linkToRedirect = linkToRedirect + '?source=' + source;
        }
        this.router.navigateByUrl('/' + linkToRedirect);
      }
    });
  }

  async inviteParticipants(): Promise<void> {
    const modalController = this.modalController;
    const executeServiceMethod = (fn): void => {
      fn(modalController);
    };
    return this.dialogs.inviteGroupParticipants((fn) => { executeServiceMethod(fn); },
      this.group._id, this.group.visibility, this.userProfile);
  }

  private async openGroupDetails(): Promise<void> {

    const publicGroup = await this.modalController.create({
      component: GroupPublicComponent,
      componentProps: {
        group: this.group,
        showedAsDialog: true,
      },
      cssClass: 'group-public-modal'
    });

    publicGroup.present();
  }

  private async editGroup(): Promise<void> {
    const userVerificationStatus = await this.verificationService.verify();
    if (userVerificationStatus === VerificationStatus.VERIFIED)
      this.createGroupService.showCreateEditGroupDialog(this.group);
  }

  private onToggleGroupRecommend(): void {
    if (this.group?.hasRecommended) {
      this.unRecommendGroup();
    } else {
      this.recommendGroup();
    }
  }

  private get recommendActionIcon(): string {
    return this.group?.hasRecommended ? 'md-icon-recommend-filled' : 'md-icon-recommend';
  }

  generateInfoSheetActions(): void {
    const actions: Array<InfoSheetActionItem> = [];
    if (this.groupUserPermissions.recommend) {
      actions.push({
        id: 'recommend_group_btn',
        icon: this.recommendActionIcon,
        textKey: this.group?.hasRecommended ? 'app.groups.recommended' : 'app.groups.recommend',
        code: GroupActions.RECOMMEND,
        handler: () => {
          this.onToggleGroupRecommend();
        }
      });
    }
    if (this.groupUserPermissions.seeDetails) {
      actions.push({
        id: 'open_group_details_btn',
        icon: 'md-icon-details',
        textKey: 'app.groups.group-details',
        code: GroupActions.GROUP_DETAILS,
        handler: () => {
          this.openGroupDetails();
        }
      });
    }
    if (this.groupUserPermissions.seeParticipantsList) {
      const actionInfo: InfoSheetActionItem = {
        id: 'open_group_participants_btn',
        icon: 'md-icon-participants',
        textKey: 'app.groups.group-participants',
        code: GroupActions.GROUP_PARTICIPANTS,
        handler: () => {
          this.openParticipantsList();
        },
      };
      if (this.group?.totalRequestsCount > 0 && this.groupsUiService.showGroupPendingRequestsNotification()) {
        actionInfo.notificationIcon = 'md-icon-notification-filled';
      }
      actions.push(actionInfo);
    }
    if (this.groupUserPermissions.invite) {
      actions.push({
        id: 'invite_group_participants_btn',
        icon: 'md-icon-user-plus',
        textKey: 'app.groups.invite-participants',
        code: GroupActions.INVITE_PARTICIPANTS,
        handler: () => {
          this.inviteParticipants();
        }
      });
    }
    if (this.groupUserPermissions.editGroup) {
      actions.push({
        id: 'edit_group_btn',
        icon: 'md-icon-pencil',
        textKey: 'app.groups.edit-group',
        code: GroupActions.EDIT_GROUP,
        handler: () => {
          this.editGroup();
        }
      });
    }
    if (this.groupUserPermissions.share) {
      actions.push({
        id: 'share_group_btn',
        icon: 'md-icon-share',
        textKey: 'app.groups.share-group',
        code: GroupActions.SHARE_GROUP,
        handler: () => {
          this.presentShareDialog(this.group);
        }
      })
    }
    if (this.groupUserPermissions.leaveGroup) {
      actions.push({
        id: 'leave_group_btn',
        icon: 'md-icon-leave',
        textKey: 'app.groups.leave-group',
        code: GroupActions.LEAVE_GROUP,
        handler: () => {
          this.presentLeaveGroupConfirm();
        }
      })
    }
    if (this.groupUserPermissions.reportGroup) {
      actions.push({
        id: 'report_group_btn',
        icon: 'md-icon-alert',
        textKey: 'app.common.flag-irrelevant',
        code: GroupActions.REPORT_GROUP,
        handler: () => {
          this.reportGroup();
        }
      });
    }
    if (this.groupUserPermissions.deleteGroup) {
      actions.push({
        id: 'ist_item_delete_group_btn',
        icon: 'md-icon-bin',
        textKey: 'app.groups.delete-group',
        code: GroupActions.DELETE_GROUP,
        handler: () => {
          this.presentDeleteGroupConfirm();
        }
      })
    }
    this.infoSheetActions = actions;
  }

  private reportGroup() {
    this.groupsService.reportGroup(this.group._id)
      .then(response => {
        if (response) {
          this.toast.show('app.groups.reported-group')
        }
      })
  }
}
