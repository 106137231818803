import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-post-to-your-network-button',
  templateUrl: './post-to-your-network-button.component.html',
  styleUrls: ['./post-to-your-network-button.component.scss']
})
export class PostToYourNetworkButtonComponent  implements OnInit {

  @Input() profileImageUrl: string;
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {}

  buttonClicked(): void {
    this.clicked.emit();
  }

}
