import { Observable } from 'rxjs';
import { ImageAttachment } from 'src/app/services/attachments.model';
import { FileSelectScope } from './file-select.service';
import { ImageGalleryStrategy } from 'src/app/services/image-gallery/image-gallery';

export interface ActionContent {
  action: ImageGalleryActions,
  index: number,
  _id: string,
  galleryId?: string
}

export enum ImageGalleryActions {
  REMOVE_IMAGE = 'remove-image'
}

export interface GalleryConfig {
  images: Array<ImageAttachment>;
  galleryId?: string;
  allowImageRemove?: boolean;
  reviewMode?: boolean;
  allowImageEdit?: boolean;
  scope: FileSelectScope,
  imageGalleryStrategy: ImageGalleryStrategy
}

export interface ReviewGalleryModeData {
  images: Array<ImageAttachment>
}

export interface ImageGallery {
  get imagesStream(): Observable<Array<ImageAttachment>>;
  get imageUpdated(): Observable<ImageAttachment>;
  removeImage(index: number, imageId: string, galleryId: string): void;
  triggerImageChanged(image: ImageAttachment): void
}
