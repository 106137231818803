import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { InfoSheetWebActionsComponent } from '../components/info-sheet-web-actions/info-sheet-web-actions.component';

import { InfoSheetActionType } from '../models/info-sheet-action-type.model';
import { InfoSheetAction } from '../models/info-sheet-action.model';
import { InfoSheetActionItem } from '../models/info-sheet-action-item.model';
import { InfoSheet } from '../models/info-sheet.model';


@Injectable({
  providedIn: 'root'
})
export class InfoSheetService {
  infoSheetActions: Subject<InfoSheetAction> = new Subject();
  private infoSheets: Array<InfoSheet> = [];

  constructor(
    private popoverController: PopoverController
  ) { }

  add(infoSheet: InfoSheet): void {
    // add infoSheet to array of active infoSheets

    if (infoSheet) {
      this.infoSheets.push(infoSheet);
    }
  }

  remove(id: string): void {
    // remove infoSheet from array of active infoSheets
    const infoSheetIndex = this.infoSheets.findIndex(infoSheet => infoSheet.id === id);

    if (infoSheetIndex !== -1) {
      this.infoSheets.splice(infoSheetIndex, 1);
      this.infoSheetActions.next(new InfoSheetAction(InfoSheetActionType.remove, id));
    }
  }

  open(id: string): void {
    // open infoSheet specified by id
    const infoSheet = this.infoSheets.find(x => x.id === id);
    if (infoSheet) {
      infoSheet.open();
      this.infoSheetActions.next(new InfoSheetAction(InfoSheetActionType.open, id));
    } else {
      console.log(`error: cannot open infosheet with id "${id}": not found`);
    }
  }

  close(id: string): void {
    // close infoSheet specified by id
    const infoSheet = this.infoSheets.find(x => x.id === id);

    if (infoSheet) {
      infoSheet.close();
      this.infoSheetActions.next(new InfoSheetAction(InfoSheetActionType.close, id));
    }
  }

  generateNewInfoSheetIdIfDuplicate(currentId: string): string {
    const infoSheetIds = this.getInfoSheetIdsObject();
    return infoSheetIds[currentId] ? `${currentId}-${new Date().getTime()}` : currentId;
  }

  actionClicked(id: string): void {
    const infoSheet = this.infoSheets.find(x => x.id === id);

    if (infoSheet?.getCloseOnActionClick()) {
      this.close(id);
    }
  }

  openWeb(actions: Array<InfoSheetActionItem>, event: Event): Promise<InfoSheetActionItem> {
    return new Promise((resolve, reject) => {
      this.popoverController.create({
        component: InfoSheetWebActionsComponent,
        componentProps: { actions },
        event: event,
        translucent: true
      })
        .then(popover => {
          popover.onDidDismiss().then((data: any) => {
            if (data && data.data && data.data.code) {
              return resolve(data.data as InfoSheetActionItem)
            }
            resolve(null);
          });
          return popover.present();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private getInfoSheetIdsObject(): any {
    const infoSheetsIds = {};

    for (const infoSheet of this.infoSheets) {
      infoSheetsIds[infoSheet.id] = true;
    }

    return infoSheetsIds;
  }
}
