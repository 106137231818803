// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --side-padding: 20px;
  --bottom-padding: 20px;
  --list-items-spacing: 20px;
}

.events-section-dialog-container {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
}
.events-section-dialog-container .header-container {
  grid-row: 1;
  grid-column: 1;
  background-color: var(--mi-white-color);
}
.events-section-dialog-container .header-container app-top-bar {
  --top-bar-padding-top: 30px;
  --top-bar-padding-bottom: 20px;
  --top-bar-padding-start: var(--side-padding);
  --top-bar-padding-end: var(--side-padding);
}
.events-section-dialog-container .header-container app-top-bar ::ng-deep ion-header::after {
  display: none;
}
.events-section-dialog-container .content-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 40px;
}
.events-section-dialog-container .content-container .tabs-container {
  grid-row: 1;
  grid-column: 1;
  background-color: var(--mi-white-color);
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
}
.events-section-dialog-container .content-container .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container {
  padding: 0;
}
.events-section-dialog-container .content-container .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container .tabs-wrapper {
  justify-content: unset;
}
.events-section-dialog-container .content-container .tab-content-container {
  grid-row: 2;
  grid-column: 1;
  background-color: var(--mi-white-color);
  padding-bottom: var(--bottom-padding);
}
.events-section-dialog-container .content-container .tab-content-container .list {
  height: 100%;
}
.events-section-dialog-container .content-container .tab-content-container .list app-vertical-list ::ng-deep .vertical-list-component-container ion-content {
  --padding-start: var(--side-padding);
  --padding-end: var(--side-padding);
}
.events-section-dialog-container .content-container .tab-content-container .list .list-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: var(--list-items-spacing);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
