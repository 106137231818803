import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddContextComponent, AddContextComponentInterface } from 'src/app/dialogs/add-context/add-context.component';
import { ContextConfigInterface } from 'src/config/config.model';
import { AuthService } from '../auth/auth.service';
import { ToastMode, ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root'
})
export class ContextAddUIService {
  constructor(
    private modalController: ModalController,
    private toast: ToastService,
    private authService: AuthService
  ) { }

  addContext(
    context: ContextConfigInterface,
    availableForAddingContexts: Array<ContextConfigInterface>,
    interestsSelectionOnly: boolean = false,
    selectedInterests: Array<string> = []
  ): Promise<boolean> {
    return new Promise(resolve => {

      this.modalController.create({
        component: AddContextComponent,
        componentProps: {
          availableForAddingContexts: availableForAddingContexts,
          selectedContext: context,
          interestsSelectionOnly,
          selectedInterestsInput: selectedInterests,
          addContextNotifications: {
            addContext: (contextKey: string, interests: Array<string>, component: AddContextComponentInterface): void => {
              this.authService.updateProfile(contextKey, {
                interests
              }, true)
                .then(() => {
                  this.toast.show('app.common.interests-updated', 'app.common.success', ToastMode.SUCCESS);
                  component.contextAdded(true);
                  this.modalController.dismiss();
                  resolve(true);
                })
                .catch(err => {
                  this.toast.showWithMessage(err, 'app.common.error-default', ToastMode.ERROR);
                  component.contextAdded(false);
                });
            },
            showError: (errorKey: string): void => {
              this.toast.showWithMessage(errorKey, null, ToastMode.ERROR);
            },
            close: (): void => {
              this.modalController.dismiss();
              resolve(false);
            }
          }
        }
      }).then(async (modalEl: HTMLIonModalElement) => {
        modalEl.present();

        try {
          const res = await modalEl.onDidDismiss();

          // if dialog is closed by clicking on backdrop or pressing esc button on keyboard
          if (res.role === 'backdrop') {
            resolve(false);
          }

        } catch (err) {
          console.error(err);
          resolve(false);
        }
      });
    })
  }
}
