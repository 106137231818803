import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-video-image',
  templateUrl: './video-image.component.html',
  styleUrls: ['./video-image.component.scss'],
})
export class VideoImageComponent {

  @Input() videoImageUrl: string;
  @Input() videoId: string;

  @Output() clicked: EventEmitter<VideoImageComponent> = new EventEmitter();

  showDefaultVideoImage: boolean;

  onClick(): void {
    this.clicked.emit(this);
  }
}
