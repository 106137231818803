import { Injectable } from '@angular/core';

import * as imageCompression from 'browser-image-compression';
import { FileSelectLimits } from '../file-select.model';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressService {

  async compressImage(
    image: File,
    maxFileSizeMb: number = FileSelectLimits.imageMaxFileSizeMB): Promise<File> {

    const options = {
      maxSizeMB: maxFileSizeMb,
      // maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    return imageCompression.default(image, options);
  }
}
