// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --add-button-icon-color: var(--mi-grey-color-11);
  --add-button-icon-font-size: 26px;
  --add-button-icon-padding: 13.5px;
}

.add-button {
  outline: none;
  background: var(--mi-blue-color-4);
  color: var(--mi-grey-color-13);
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  border-radius: 8px;
  padding: var(--add-button-icon-padding);
}
.add-button .add-button-wrap {
  display: grid;
}
.add-button .add-button-wrap app-icon {
  grid-row: 1;
  grid-column: 1;
  --icon-size: var(--add-button-icon-font-size);
}
.add-button .add-button-wrap .text {
  grid-row: 2;
  grid-column: 1;
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
