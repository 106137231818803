// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --margin-top: 15px;
}

.empty-state-container .message {
  color: var(--mi-grey-color-13);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
.empty-state-container .button-wrap {
  margin-top: var(--margin-top);
}
.empty-state-container .button-wrap ion-button {
  width: 100%;
  text-transform: none;
  --background: var(--mi-primary-color);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
