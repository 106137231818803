import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { isMobilePlatform } from '../services/utils/utils';
import appConfig from 'src/config/config-app/myao.dev.browser.config';

@Directive({
  /* eslint-disable */
  selector: '[tooltip]'
  /* eslint-enable */
})
export class TooltipDirective implements OnDestroy{

  @Input() tooltip = ''; // The text for the tooltip to display or html markup
  @Input() showDelay? = 190; // Optional delay input, in ms
  @Input() hideDelay? = 5000; // Remove tooltip after 5 seconds
  platform = appConfig.platform;
  private myPopup: HTMLElement;
  private showTimer: any;
  private hideTimer: any;

  constructor(private el: ElementRef) { }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.myPopup || this.showTimer || this.tooltip === '' || isMobilePlatform(this.platform)) {
      return; // already visible or empty string or mobile
    }
    this.showTimer = setTimeout(() => {
      this.showTimer = null;
      const pos = this.getTooltipPos();
      this.createTooltipPopup(pos.x, pos.y);
    }, this.showDelay);
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.showTimer) {
      clearTimeout(this.showTimer);
      this.showTimer = null;
    }
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
      this.hideTimer = null;
    }
    this.removePopup();
  }

  @HostListener('mousedown') onMouseDown(): void {
    if (isMobilePlatform(this.platform)) {
      this.removePopup();
    }
  }

  ngOnDestroy(): void {
    this.removePopup();
  }

  private getTooltipPos(): {x: number, y: number} {
    // Get the middle of the element
    const x = this.el.nativeElement.getBoundingClientRect().left + this.el.nativeElement.offsetWidth / 2;
    // Get the bottom of the element, plus a little extra
    const y = this.el.nativeElement.getBoundingClientRect().top + this.el.nativeElement.offsetHeight + 6;
    return {x, y};
  }

  private createTooltipPopup(x: number, y: number): void {
    const popup = document.createElement('div');
    popup.innerHTML = this.tooltip;
    popup.setAttribute('class', 'tooltip-container');
    popup.style.top = `${y}px`;
    popup.style.left = `${x}px`;
    document.body.appendChild(popup);
    this.myPopup = popup;
    this.hideTimer = setTimeout(() => {
      this.hideTimer = null;
      this.removePopup();
    }, this.hideDelay);
  }

  private removePopup(): void {
    if (this.myPopup){
      this.myPopup.remove();
      this.myPopup = null;
    }
  }
}
