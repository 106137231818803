import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Journal } from 'src/app/services/yeti-protocol/journal';

@Component({
  selector: 'app-journals-list',
  templateUrl: './journals-list.component.html',
  styleUrls: ['./journals-list.component.scss'],
})
export class JournalsListComponent {
  @Input() journals: Array<Journal> = new Array<Journal>();
  @Output() openJournal: EventEmitter<string> = new EventEmitter();
  @Output() toggleJournalFollowing: EventEmitter<Journal> = new EventEmitter();

  onOpenJournal(journalId: string): void {
    this.openJournal.emit(journalId);
  }

  onToggleJournalFollowing(journal: Journal): void {
    this.toggleJournalFollowing.emit(journal);
  }
}
