import { Inject, Injectable, OnDestroy } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { BrowserTab } from '@awesome-cordova-plugins/browser-tab/ngx';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { Platform } from 'src/config/config.model';
import appConfig from 'src/config/config';
import { ORCID_AUTH_DEVICE_SERVICE, OrcIdAuthDeviceServiceInterface } from './orcid-auth-device.service.interface';
import { OrcIdAuthDeviceIOSService } from './orcid-auth.device-ios.service';
import { OrcIdAuthDeviceAndroidService } from './orcid-auth.device-android.service';
import { OrcIdAuthServiceInterface } from './orcid-auth.service.interface';
import { OrcIdAuthBrowserService } from './orcid-auth.browser.service';
import { AuthenticationOrcIdConfig } from '../../../../config/config.model';
import { AuthorizeOrcidRequest, AuthorizeOrcidResponse, UnlinkOrcidResponse } from '../../yeti-protocol/auth/orcid';
import { Observable, Subject, Subscription, firstValueFrom } from 'rxjs';
import { AuthService } from '../auth.service';
import { CONTEXT_SERVICE, ContextService } from '../../context/context.model';
import { UserProfile } from '../../yeti-protocol/auth/mi';

interface OrcIdAuthServiceConfig {
  platform: Platform;
  authenticationOrcId: AuthenticationOrcIdConfig;
  serverUrlIonic: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrcIdAuthService implements OrcIdAuthServiceInterface, OnDestroy {

  readonly config: OrcIdAuthServiceConfig = appConfig;

  private orcIdAuthStatusChangedSubject: Subject<boolean> = new Subject();
  private userProfileSubscription: Subscription;

  constructor(
    @Inject(ORCID_AUTH_DEVICE_SERVICE) private orcIdAuthDeviceService: OrcIdAuthDeviceServiceInterface,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
  ) {
    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe(async (userProfile: UserProfile) => {

      try {
        const context = await this.contextService.getCurrentContext();

        if (!context) {
          this.orcIdAuthStatusChangedSubject.next(this._isSignedIn(userProfile));
        } else if (context?.key === this.contextService?.currentContext?.key) {
          this.orcIdAuthStatusChangedSubject.next(this._isSignedIn(userProfile));
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  static get requiredAuthServices(): any[] {
    const services: any[] = [];
    switch (appConfig.platform) {
      case Platform.IOS:
        services.push(InAppBrowser);
        services.push(SafariViewController);
        services.push({ provide: ORCID_AUTH_DEVICE_SERVICE, useClass: OrcIdAuthDeviceIOSService });
        break;
      case Platform.ANDROID:
        services.push(InAppBrowser);
        services.push(BrowserTab);
        services.push({ provide: ORCID_AUTH_DEVICE_SERVICE, useClass: OrcIdAuthDeviceAndroidService });
        break;
      default:
        services.push({ provide: ORCID_AUTH_DEVICE_SERVICE, useClass: OrcIdAuthBrowserService });
    }
    return services;
  }

  get orcIdAuthStatusChangedObs(): Observable<boolean> {
    return this.orcIdAuthStatusChangedSubject.asObservable();
  }

  async isSignedIn(): Promise<boolean> {
    try {
      const userProfile = await this.getProfile();
      return this._isSignedIn(userProfile);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  signIn(): Promise<any> {
    return this.orcIdAuthDeviceService.signIn(this._getOrcIdOAuthUrl());
  }

  authorizeOrcid(code: string): Promise<AuthorizeOrcidResponse> {

    const serverUrl = `${this.config.serverUrlIonic}profile/authorizeOrcid`;
    const requestData: AuthorizeOrcidRequest = {
      code: code
    }

    return firstValueFrom(this.authService.securePost<any, AuthorizeOrcidResponse>(serverUrl, requestData));
  }

  unlinkOrcid(): Promise<UnlinkOrcidResponse> {
    const serverUrl = `${this.config.serverUrlIonic}profile/unlinkOrcid`;

    return firstValueFrom(this.authService.securePost<any, UnlinkOrcidResponse>(serverUrl, {}));
  }

  _getOrcIdOAuthUrl(): string {

    const serverUrl = this.config.authenticationOrcId.serverUrl;
    const clientId = this.config.authenticationOrcId.clientId;
    let redirectUri = this.config.authenticationOrcId.redirectUri;

    if (this.config.platform === Platform.BROWSER) {
      redirectUri = 'http://127.0.0.1:8000/auth-orcid-redirect';
    }

    return `${serverUrl}/oauth/authorize?client_id=${clientId}&response_type=code&scope=/authenticate&redirect_uri=${redirectUri}`;
  }

  private _isSignedIn(userProfile: UserProfile | null): boolean {
    return userProfile?.orcid && userProfile?.orcid?.length ? true : false;
  }

  private getProfile(): Promise<UserProfile | null> {
    if (this._appId) {
      return this.authService.getProfile(this._appId, false);
    }

    return Promise.resolve(null);
  }

  get _appId(): string {
    return this.contextService?.currentContext?.key;
  }
}
