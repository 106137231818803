// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --side-padding: 20px;
  --list-items-spacing: 20px;
}

.my-recommendations-dialog-container {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
}
.my-recommendations-dialog-container .header-container {
  grid-row: 1;
  grid-column: 1;
  background-color: var(--mi-white-color);
}
.my-recommendations-dialog-container .header-container app-top-bar {
  --top-bar-padding-top: 30px;
  --top-bar-padding-bottom: 20px;
  --top-bar-padding-start: var(--side-padding);
  --top-bar-padding-end: var(--side-padding);
}
.my-recommendations-dialog-container .header-container app-top-bar ::ng-deep ion-header ion-toolbar {
  --border-width: 0;
}
.my-recommendations-dialog-container .header-container app-top-bar ::ng-deep ion-header::after {
  display: none;
}
.my-recommendations-dialog-container .content-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 40px;
}
.my-recommendations-dialog-container .content-container .tabs-container {
  grid-row: 1;
  grid-column: 1;
  background-color: var(--mi-white-color);
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
}
.my-recommendations-dialog-container .content-container .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container {
  padding: 0;
}
.my-recommendations-dialog-container .content-container .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container .tabs-wrapper {
  grid-auto-columns: 1fr;
}
.my-recommendations-dialog-container .content-container .tab-content-container {
  grid-row: 2;
  grid-column: 1;
  background-color: var(--mi-white-color);
  padding-bottom: 20px;
}
.my-recommendations-dialog-container .content-container .tab-content-container .list {
  height: 100%;
}
.my-recommendations-dialog-container .content-container .tab-content-container .list .empty-state-container {
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
}
.my-recommendations-dialog-container .content-container .tab-content-container .list .empty-state-container .empty-state-message {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-13);
  margin: 0;
  text-align: left;
}
.my-recommendations-dialog-container .content-container .tab-content-container .list app-vertical-list ::ng-deep .vertical-list-component-container ion-content {
  --padding-start: var(--side-padding);
  --padding-end: var(--side-padding);
}
.my-recommendations-dialog-container .content-container .tab-content-container .list .list-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: var(--list-items-spacing);
}
.my-recommendations-dialog-container .content-container .tab-content-container .list .list-container .item app-top-recommended-article-video ::ng-deep ion-item {
  --padding-start: 0;
  margin-bottom: 0;
}
.my-recommendations-dialog-container .content-container .tab-content-container .list .list-container .item app-groups-row-list-item {
  --side-padding: 0;
}
@media all and (max-width: 767px) {
  .my-recommendations-dialog-container {
    background-color: var(--mi-grey-color-20);
    row-gap: 10px;
  }
  .my-recommendations-dialog-container .content-container {
    row-gap: 10px;
  }
  .my-recommendations-dialog-container .content-container .tabs-container {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .my-recommendations-dialog-container .content-container .tab-content-container {
    padding-top: 30px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
