// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --verification-step-one-padding-top: 0px;
  --verification-step-one-padding-bottom: 16px;
  --verification-step-one-padding-start: 16px;
  --verification-step-one-padding-end: 16px;
  --content-max-width: 100%;
}

.verification-step-one-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  justify-items: center;
}
.verification-step-one-container .header {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  padding-bottom: 22px;
}
.verification-step-one-container .content {
  width: 100%;
  max-width: var(--content-max-width);
  height: 100%;
  padding-top: var(--verification-step-one-padding-top);
  padding-bottom: var(--verification-step-one-padding-bottom);
  grid-row: 2;
  grid-column: 1;
}
.verification-step-one-container .content ion-content {
  --padding-start: var(--verification-step-one-padding-start);
  --padding-end: var(--verification-step-one-padding-end);
}
.verification-step-one-container .content ion-content .title-container {
  max-width: 315px;
  margin: 0 auto;
}
.verification-step-one-container .content ion-content .title-container .title {
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-1);
  margin: 0;
  text-align: center;
}
.verification-step-one-container .content ion-content .subtitle-container {
  max-width: 315px;
  margin: 0 auto;
}
.verification-step-one-container .content ion-content .subtitle-container .subtitle {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  text-align: center;
}
.verification-step-one-container .content ion-content .actions-container {
  padding-top: 29px;
  padding-bottom: 19px;
}
.verification-step-one-container .content ion-content .actions-container .actions-title-container {
  padding-bottom: 22px;
}
.verification-step-one-container .content ion-content .actions-container .actions-title-container .actions-title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: center;
}
.verification-step-one-container .content ion-content .actions-container .action {
  width: 100%;
  height: 100%;
  padding: 17.5px 15px;
  margin-bottom: 11px;
  background-color: var(--mi-background-color);
  border-radius: 9px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto min-content;
  align-items: center;
  -moz-column-gap: 17.5px;
       column-gap: 17.5px;
}
.verification-step-one-container .content ion-content .actions-container .action:last-of-type {
  margin-bottom: 0;
}
.verification-step-one-container .content ion-content .actions-container .action .action-type-icon-container {
  grid-row: 1;
  grid-column: 1;
  display: flex;
}
.verification-step-one-container .content ion-content .actions-container .action .action-type-icon-container app-icon {
  --icon-size: 33px;
  color: var(--mi-color-text-lite);
}
.verification-step-one-container .content ion-content .actions-container .action .action-title-container {
  grid-row: 1;
  grid-column: 2;
}
.verification-step-one-container .content ion-content .actions-container .action .action-title-container .action-title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
}
.verification-step-one-container .content ion-content .actions-container .action .action-icon-container {
  grid-row: 1;
  grid-column: 3;
  display: flex;
}
.verification-step-one-container .content ion-content .actions-container .action .action-icon-container app-icon {
  --icon-size: 14px;
  color: var(--mi-black-color);
}
.verification-step-one-container .content ion-content .security-info-message-container {
  max-width: 290px;
  margin: 0 auto;
}
.verification-step-one-container .content ion-content .security-info-message-container .security-info-message {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  color: var(--mi-color-text-lite);
  margin: 0;
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
