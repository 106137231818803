import { Injectable } from '@angular/core';
import { ResourcesUpdateService } from './resources-update.service';
import { VersionInfo } from './resources-update.service.interface';
import config from '../../../config/config';
import { ExitService } from './exit.service';
import { Platform } from '@ionic/angular';

const NO_CLEANUP_KEY = 'no-resource-update-cleanup';

@Injectable()
export class ResourcesUpdateUIService {
  disabled = config.appUpdateDisabled;

  constructor(
    private resourcesUpdateService: ResourcesUpdateService,
    private platform: Platform,
    private exitService: ExitService,
  ){}

  // returns true if a new version was downloaded
  downloadNewVersionIfAvailable(): Promise<boolean> {
    if(this.disabled){
      return Promise.resolve(false);
    }
    return this._getVersionsInfo().then(res => {
      const [remoteVersion, embeddedVersionInfo, downloadedVersionInfos] = res;
      if(remoteVersion === embeddedVersionInfo.version){
        return false;
      }
      const found = downloadedVersionInfos.find(info => {
        return info.version === remoteVersion;
      });
      if(found){
        return false;
      }
      return this.resourcesUpdateService.downloadNewVersion();
    }).catch(err => {
      console.error(err);
      return Promise.resolve(false);
    })
  }

  hasNewVersionAvailable(): Promise<boolean> {
    if(this.disabled){
      return Promise.resolve(false);
    }
    return this._getVersionsInfo().then(res => {
      const [remoteVersion, embeddedVersionInfo, downloadedVersionInfos] = res;
      if(embeddedVersionInfo.isActive){
        if(remoteVersion === embeddedVersionInfo.version){
          return false;
        }
      }
      const found = downloadedVersionInfos.find(info => {
        return info.version === remoteVersion;
      });
      if(found){
        return !found.isActive;
      }
      return false;
    });
  }

  switchAtNewVersion(): Promise<void> {
    if(this.disabled){
      return Promise.resolve();
    }
    return this._getVersionsInfo().then(res => {
      const [remoteVersion, embeddedVersionInfo, downloaddVersionInfos] = res;
      if(remoteVersion === embeddedVersionInfo.version && !embeddedVersionInfo.isActive){
        return this.resourcesUpdateService.switchAtVersion(embeddedVersionInfo.version);
      }
      const found = downloaddVersionInfos.find(info => {
        return info.version === remoteVersion;
      });
      if(found && !found.isActive){
        return this.resourcesUpdateService.switchAtVersion(found.version);
      }
    }).then(()=> {
      if(!this.platform.is('android')){
        this.exitService.exit();
      }
    });
  }

  cleanupOldVersions(): Promise<void>{
    if(this.disabled){
      return Promise.resolve();
    }
    return this.isOldVersionsCleanupEnabled()
      .then(enabled => {
        if(enabled){
          return this.resourcesUpdateService.deleteNotRequiredVersions();
        }
      });
  }

  disableOldVersionsCleanup(disable: boolean): Promise<void>{
    if(disable){
      window.localStorage.setItem(NO_CLEANUP_KEY, 'true');
    }else{
      if(window.localStorage.getItem(NO_CLEANUP_KEY)){
        window.localStorage.removeItem(NO_CLEANUP_KEY);
      }
    }
    return Promise.resolve();
  }

  isOldVersionsCleanupEnabled(): Promise<boolean>{
    const value = window.localStorage.getItem(NO_CLEANUP_KEY);
    return Promise.resolve(value !== 'true');
  }

  _getVersionsInfo(): Promise<[string, VersionInfo, Array<VersionInfo>]> {
    return this.resourcesUpdateService.getRemoteVersion()
      .then(remoteVersion => {
        return Promise.all([
          remoteVersion,
          this.resourcesUpdateService.getEmbeddedVersion()
        ]);
      }).then(res => {
        const [remoteVersion, embeddedVersionInfo] = res;
        return Promise.all([
          remoteVersion,
          embeddedVersionInfo,
          this.resourcesUpdateService.getDownloadedVersions()
        ]);
      });
  }
}
