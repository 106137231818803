import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { IonRefresher, ModalController } from '@ionic/angular';
import { CustomTab } from 'src/app/components/custom-tabs/custom-tabs.component';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import {
  ItemTypes,
  RecommendationsBookmarksService,
  RecommendationsFilter
} from 'src/app/services/recommendations-service/recommendations-bookmarks.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { Article, Video } from 'src/app/services/yeti-protocol/article';
import { Group } from 'src/app/services/yeti-protocol/chatter-api';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { RecommendationsBookmarksResponsePayload } from '../../services/yeti-protocol/recommendations-bookmarks';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { TargetImpression, VisibilityTrackerService } from '../../modules/visibility-tracker/visibility-tracker.service';
import { ActionSource, ImpressionTrackingRequest, RecommendationCardImpressionTrackingParam } from '../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';

export enum TabIds {
  videos = 'videos',
  articles = 'articles',
  groups = 'groups'
}

enum ImpressionsTrackingList {
  VIDEOS = 'recommended-videos-impressions-tracking-root',
  ARTICLES = 'recommended-articles-impressions-tracking-root'
}

@Component({
  selector: 'app-my-recommendations-dialog',
  templateUrl: './my-recommendations-dialog.component.html',
  styleUrls: ['./my-recommendations-dialog.component.scss'],
})
export class MyRecommendationsDialogComponent implements OnInit, OnDestroy {

  @Input() userId: string;

  customTabs: Array<CustomTab> = [];
  activeTabId: string;
  TabIds = TabIds;
  videos: Array<Video> = [];
  articles: Array<Article> = [];
  groups: Array<Group> = [];
  loading: boolean;
  totalItemsCount: number;
  userProfile: UserProfile;
  ImpressionsTrackingList = ImpressionsTrackingList;
  readonly count = 10;
  private userProfileSubscription: Subscription;
  private itemsTrackingSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private appTranslationService: AppTranslationService,
    private recommendationsService: RecommendationsBookmarksService,
    private toast: ToastService,
    private appNavController: AppNavController,
    private authService: AuthService,
    private visibilityTrackerService: VisibilityTrackerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private navController: NavControllerService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
  ) { }

  ngOnInit(): void {
    this.setActiveTab(TabIds.videos);
    this.generateTabs();

    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });

    this.itemsTrackingSubscription = this.visibilityTrackerService.impressionsAsObservable.subscribe(targetImression => {
      this.trackListItemImpression(targetImression);
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
    this.itemsTrackingSubscription?.unsubscribe();
  }

  getRecommendationItems(filter: RecommendationsFilter, start = 0): Promise<RecommendationsBookmarksResponsePayload> {

    this.loading = true;

    const promise = (this.userId
      ? this.recommendationsService.getItemsList(ItemTypes.RECOMMENDATIONS, start, this.count, this.userId, filter)
      : this.recommendationsService.getItemsList(ItemTypes.RECOMMENDATIONS, start, this.count, undefined, filter));

    promise.then(response => {
      if (response && response.success) {

        this.totalItemsCount = response.totalItemsCount || 0;

        const recommendations = response?.result || [];

        switch (filter) {
          case RecommendationsFilter.VIDEO:

            if (!this.videos) {
              this.videos = [];
            }

            this.videos = [...this.videos, ...recommendations.map(res => res?.item as Video)];
            break;
          case RecommendationsFilter.ARTICLE:

            if (!this.articles) {
              this.articles = [];
            }

            this.articles = [...this.articles, ...recommendations.map(res => res?.item as Article)];
            break;
          case RecommendationsFilter.GROUP:

            if (!this.groups) {
              this.groups = [];
            }

            this.groups = [...this.groups, ...recommendations.map(res => res?.item as Group)];
            break;
        }
      }
    }).catch(err => {
      console.error(err);
      this.showError(err?.error?.error?.message?.errfor?.message);
    }).finally(() => {
      this.loading = false;
    });

    return promise;
  }

  async refreshRecommendations(refresher: IonRefresher): Promise<void> {

    this.videos = [];
    this.articles = [];
    this.groups = [];
    this.totalItemsCount = 0;

    let promise;

    switch (this.activeTabId) {
      case TabIds.videos:
        promise = this.getRecommendationItems(RecommendationsFilter.VIDEO);
        break;
      case TabIds.articles:
        promise = this.getRecommendationItems(RecommendationsFilter.ARTICLE);
        break;
      case TabIds.groups:
        promise = this.getRecommendationItems(RecommendationsFilter.GROUP);
        break;
    }

    promise?.finally(() => {
      refresher.complete();
    });
  }

  async loadMoreRecommendations(verticalListLoadMoreData: VerticalListLoadMoreData): Promise<void> {

    let start = 0;
    let promise;

    switch (this.activeTabId) {
      case TabIds.videos:
        start = Math.floor(this.videos?.length / this.count);
        promise = this.getRecommendationItems(RecommendationsFilter.VIDEO, start);
        break;
      case TabIds.articles:
        start = Math.floor(this.articles?.length / this.count);
        promise = this.getRecommendationItems(RecommendationsFilter.ARTICLE, start);
        break;
      case TabIds.groups:
        start = Math.floor(this.groups?.length / this.count);
        promise = this.getRecommendationItems(RecommendationsFilter.GROUP, start);
        break;
    }

    promise?.finally(() => {
      verticalListLoadMoreData.infiniteScroll.complete();
    });
  }

  setActiveTab(tabId: TabIds): void {

    if (!tabId) {
      return;
    }

    this.activeTabId = tabId;

    switch (tabId) {
      case TabIds.videos:
        this.videos = [];
        this.totalItemsCount = 0;
        this.getRecommendationItems(RecommendationsFilter.VIDEO);
        break;
      case TabIds.articles:
        this.articles = [];
        this.totalItemsCount = 0;
        this.getRecommendationItems(RecommendationsFilter.ARTICLE);
        break;
      case TabIds.groups:
        this.groups = [];
        this.totalItemsCount = 0;
        this.getRecommendationItems(RecommendationsFilter.GROUP);
        break;
    }
  }

  private generateTabs(): void {
    this.customTabs = [{
      id: TabIds.videos,
      text: this.appTranslationService.instant('app.common.videos'),
      active: this.isTabVideosActive
    },
    {
      id: TabIds.articles,
      text: this.appTranslationService.instant('app.common.articles'),
      active: this.isTabArticlesActive
    },
    {
      id: TabIds.groups,
      text: this.appTranslationService.instant('app.common.groups'),
      active: this.isTabGroupsActive
    }
    ];
  }

  get isTabVideosActive(): boolean {
    return this.activeTabId === TabIds.videos ? true : false
  }

  get isTabArticlesActive(): boolean {
    return this.activeTabId === TabIds.articles ? true : false
  }

  get isTabGroupsActive(): boolean {
    return this.activeTabId === TabIds.groups ? true : false
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  get showVideosEmptyState(): boolean {
    return !this.loading && !this.videos?.length;
  }

  get showArticlesEmptyState(): boolean {
    return !this.loading && !this.articles?.length;
  }

  get showGroupsEmptyState(): boolean {
    return !this.loading && !this.groups?.length;
  }

  openGroup(groupId: string): void {
    this.onClose();

    const trackingSource = this.userId ? ActionSource.userPublicProfilePage : ActionSource.userProfilePage;
    let url = `${this.contextService.currentContext.homePath}/group/${groupId}?recommendedBy=${this.recommendedBy}`;

    if (trackingSource) {
      url += '&source=' + trackingSource;
    }

    this.navController.navigateForward(url);
  }

  articleOrVideoOpened(): void {
    this.onClose();
  }

  get recommendedBy(): string {
    return this.userId || this.userProfile?.userId || '';
  }

  trackListItemImpression(targetImpression: TargetImpression): void {

    if (targetImpression.rootKey !== this.ImpressionsTrackingList.VIDEOS &&
      targetImpression.rootKey !== this.ImpressionsTrackingList.ARTICLES) {
      return;
    }

    if (!targetImpression?.targetKey?.length) {
      console.error(`missing target key for item in list: ${targetImpression.rootKey}`);
      return;
    }

    let itemObj: Video | Article = null;

    switch (targetImpression.rootKey) {
      case this.ImpressionsTrackingList.VIDEOS:
        itemObj = this.videos.find(video => video?._id === targetImpression?.targetKey);
        break;
      case this.ImpressionsTrackingList.ARTICLES:
        itemObj = this.articles.find(article => article?._id === targetImpression?.targetKey);
        break;
    }

    if (!itemObj) {
      return;
    }

    const impressionTrackingRequest: ImpressionTrackingRequest = {
      objectId: targetImpression?.targetKey,
      objectType: itemObj?.type,
    };

    impressionTrackingRequest.source = ActionSource.seeAllRecommendationsDialog;

    const objectTitle = itemObj?.title;

    if (objectTitle) {
      impressionTrackingRequest.objectTitle = objectTitle;
    }

    (impressionTrackingRequest as RecommendationCardImpressionTrackingParam).recommendedByUser =
      this.recommendedBy;

    this.trackingService.trackListItemImpression(impressionTrackingRequest);
  }

  get title(): string {
    return this.userId ? this.appTranslationService.instant('app.dialogs.MyRecommendationsDialog.title-recommendations') :
      this.appTranslationService.instant('app.dialogs.MyRecommendationsDialog.title-my-recommendations');
  }

}
