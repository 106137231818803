import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-case-text-details-button',
  templateUrl: './case-text-details-button.component.html',
  styleUrls: ['./case-text-details-button.component.scss'],
})
export class CaseTextDetailsButtonComponent {

  @Input() title: string;
  @Input() text: string;
  @Input() icon = 'md-edit-interests';
}
