// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-container {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
  cursor: pointer;
  padding: 0 16px;
}
.user-profile-container.with-x-btn {
  grid-template-columns: auto min-content min-content;
}
.user-profile-container .user-info-container {
  grid-row: 1;
  grid-column: 1;
}
.user-profile-container .user-info-container .user-info {
  width: 100%;
}
.user-profile-container ion-button {
  grid-row: 1;
  grid-column: 2;
}
.user-profile-container .pending-button {
  --background: var(--ion-color-dark);
  --border-color: var(--ion-color-dark);
  color: var(--mi-white-color);
}
.user-profile-container .x-btn-container {
  grid-row: 1;
  grid-column: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-profile-container .x-btn-container app-icon-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: none;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0%;
  --icon-button-icon-size: 18px;
  --icon-button-icon-color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
