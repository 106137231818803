import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthDialogPage, AuthUIServiceInterface, AUTH_UI_SERVICE } from 'src/app/services/auth/auth-ui.service.interface';
import { Profession, ProfessionsService } from 'src/app/services/professions.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import appConfig from 'src/config/config';
import { BaseAuthPage } from '../auth-common/base-auth-page';

interface ProfessionInfo extends Profession {
  selected: boolean;
}

@Component({
  selector: 'app-auth-signin',
  templateUrl: './auth-signup-other-roles.page.html',
  styleUrls: ['./auth-signup-other-roles.page.scss'],
})
export class AuthSignUpOtherRolesPage extends BaseAuthPage implements OnInit, OnDestroy {
  errorMessage: string;
  professions: Array<ProfessionInfo> = [];
  mainProfessionKeys = appConfig.authMainProfessionKeys;
  private sub: Subscription;

  constructor(
    nav: IonNav,
    @Inject(AUTH_UI_SERVICE) authUIService: AuthUIServiceInterface,
    private professionsService: ProfessionsService,
    private responsiveUtilsService: ResponsiveUtilsService
  ) {
    super(nav, authUIService);
  }

  ngOnInit(): void {
    this.sub = this.professionsService.professions.subscribe(professions => {
      if (this.mainProfessionKeys) {
        professions = professions.filter(profession => {
          return !this.mainProfessionKeys.includes(profession.key);
        })
      }
      this.professions = professions.map(profession => {
        const { key, label, icon } = profession;
        return { key, label, icon, selected: false };
      });
    });
  }

  get hasSelection(): boolean {
    return !!this.professions.find(profession => {
      return profession.selected;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  select(professionKey: string): void {
    this.professions.forEach(profession => {
      profession.selected = profession.key === professionKey;
    });
  }

  confirmSelection(): void {
    const selectedProfession = this.professions.find(profession => {
      return profession.selected;
    });
    if (!selectedProfession) {
      return;
    }
    this.errorMessage = '';
    if (!selectedProfession) {
      this.errorMessage = 'Fail to select profession';
      return;
    }
    this.authUIService.updateUserProfile({ profession: selectedProfession.key })
      .then(() => {
        return this.authUIService.navigate(AuthDialogPage.SignUp06Confirm)
      })
      .catch(err => {
        this.errorMessage = err.message;
      });
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }
}
