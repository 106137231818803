import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// models
import { ImageAttachment } from 'src/app/services/attachments.model';
import { Case } from 'src/app/services/groups/group.model';
import { CaseFileType, PostType, RemovePostAttachmentSuccessResponse } from 'src/app/services/yeti-protocol/chatter-api';
import { ActionContent, GalleryConfig, ImageGalleryActions } from 'src/app/modules/file-select/services/image-gallery.model';

// services
import { FileSelectScope, FileSelectService } from 'src/app/modules/file-select/services/file-select.service';
import { ImageGalleryService } from 'src/app/modules/file-select/services/image-gallery.service';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { PostsDataService } from '../../services/posts/posts-data.service';
import { ImageGalleryCaseStrategy } from 'src/app/services/image-gallery/image-gallery-case-service.service';

@Component({
  selector: 'app-create-group-case-add-images-section',
  templateUrl: './create-group-case-add-images-section.component.html',
  styleUrls: ['./create-group-case-add-images-section.component.scss'],
})
export class CreateGroupCaseAddImagesSectionComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() title: string;
  @Input() groupCase: Case = null;
  @Input() caption: string;
  @Input() imageFiles: Array<ImageAttachment> = [];
  @Input() caseFileType: CaseFileType;
  @Input() caseTotalImagesCount: number;
  @Input() galleryId: string;
  @Input() captionLimit: number;

  @Output() attachImages: EventEmitter<CreateGroupCaseAddImagesSectionComponent> = new EventEmitter();
  @Output() imageFilesChanged: EventEmitter<Array<ImageAttachment>> = new EventEmitter();
  @Output() captionChanged: EventEmitter<string> = new EventEmitter();

  maxImagesCount = 5;

  captionFocus: boolean;

  private performingImageGalleryAction: boolean;
  private imageGalleryActionSubscription: Subscription;

  constructor(
    private fileSelectService: FileSelectService,
    private imageGalleryService: ImageGalleryService,
    private groupsService: GroupsService,
    private appTranslationService: AppTranslationService,
    private alertController: AlertController,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private postsDataService: PostsDataService,
    private imageGalleryCaseStrategy: ImageGalleryCaseStrategy
  ) { }

  ngOnInit(): void {
    this.imageGalleryActionSubscription = this.imageGalleryService?.imageActionStream?.subscribe((action: ActionContent) =>
      this.executeImageGalleryAction(action));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.imageFiles = this.imageFiles || [];
    }, 0);
  }

  ngOnDestroy(): void {
    this.imageGalleryActionSubscription?.unsubscribe();
  }

  addImages(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.attachImages.emit(this);
  }

  imagesSelected(imageFiles: Array<SelectedFile>): void {

    const postAttachmentImages: Array<ImageAttachment> = [];

    imageFiles?.forEach((image: SelectedFile) => {
      const selectedFile: ImageAttachment = {
        _id: null,
        fullUrl: image?.url,
        previewUrl: image?.url,
        mimeType: image?.file?.type,
        fileName: image?.file?.name || image?.fileName,
        contentLength: image?.file?.size,
        file: image?.file
      };

      postAttachmentImages.push(selectedFile);
    });

    this.setImageFiles(postAttachmentImages);
  }

  async setImageFiles(imageFiles: Array<ImageAttachment>): Promise<any> {

    if (!imageFiles) {
      this.imageFiles = [];
      return;
    }

    this.imageFiles = await this.fileSelectService.checkMaxFileCountExceeded(
      [...this.imageFiles, ...imageFiles],
      this.maxImagesCount,
      FileSelectScope.CASE);
    this.emitImageFilesChange(this.imageFiles);
  }

  getImageFiles(): Array<ImageAttachment> {
    return this.imageFiles;
  }

  emitImageFilesChange(imageFiles: Array<ImageAttachment>): void {
    this.imageFilesChanged.emit(imageFiles);
  }

  captionModelChanged(): void {
    this.emitCaptionChange(this.caption);
  }

  emitCaptionChange(caption: string): void {
    this.captionChanged.emit(caption);
  }

  openGalleryModal(): void {

    const galleryConfig: GalleryConfig = {
      images: this.imageFiles,
      galleryId: this.galleryId,
      allowImageRemove: true,
      scope: FileSelectScope.CASE,
      imageGalleryStrategy: this.imageGalleryCaseStrategy
    }

    this.imageGalleryService.openImageGallery(galleryConfig);
  }

  async executeImageGalleryAction(action: ActionContent): Promise<any> {

    if (this.performingImageGalleryAction) {
      return;
    }

    const index = action?.index;
    const imageId = action?._id;
    const caseId = this.groupCase?._id;
    const galleryId = action.galleryId;

    this.performingImageGalleryAction = true;

    if (action.action === ImageGalleryActions.REMOVE_IMAGE) {
      try {
        await this.removeImageAttachment(caseId, index, imageId, galleryId);
      } catch (err) { /* ignore */ }
    }
    this.performingImageGalleryAction = false;
  }

  async removeImageAttachment(caseId: string, index: number, imageId: string, galleryId: string): Promise<any> {

    const imageFiles = this.getImageFiles();

    if (galleryId !== this.galleryId) {
      return;
    }

    if (!imageId && imageFiles?.[index]) {
      imageFiles.splice(index, 1);
      this.setImageFiles(null);
      this.setImageFiles(imageFiles);
      this.imageGalleryService.updateGalleryImages(imageFiles);
      return;
    }

    if (!imageId || !caseId) {
      return;
    }

    const filteredImagefiles = imageFiles?.filter(image => image._id === imageId);

    if (!filteredImagefiles?.length || filteredImagefiles?.length === 0) {
      return;
    }

    if (this.caseTotalImagesCount === 1) {
      const title = await this.appTranslationService.get('app.components.CreatePost.case-one-image-required-warning-title');
      const text = await this.appTranslationService.get('app.components.CreatePost.case-one-image-required-warning-text');
      const buttonText = await this.appTranslationService.get('app.components.CreatePost.one-image-required-warning-button-text');

      const alert = await this.alertController.create({
        header: title,
        message: text,
        buttons: [buttonText]
      });

      alert.present();
      return;
    }

    this.groupsService.removeGroupPostImage(caseId, imageId, this.caseFileType, PostType.case)
      .then((removeGroupPostRes: RemovePostAttachmentSuccessResponse) => {
        this.groupCase = removeGroupPostRes.result as Case;

        const filteredImageFiles = this.getImageFiles().filter((image: ImageAttachment) => {
          if (image._id !== imageId) {
            return image;
          }
        });

        this.setImageFiles(null);
        this.setImageFiles(filteredImageFiles);

        this.imageGalleryService.updateGalleryImages(this.imageFiles);
        this.postsDataService.triggerPostEditedAction(this.groupCase);
      }).catch(err => {
        console.error(err);
      });
  }

  captionFocused(): void {
    this.captionFocus = true;
  }

  captionBlur(): void {
    this.captionFocus = false;
  }

}
