import { Component, EventEmitter, Output } from '@angular/core';

import appConfig from '../../../config/config';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {

  @Output() logoClick:  EventEmitter<Event> = new EventEmitter();

  logoImgSrc: string = appConfig.logoImageName ? `assets/app-icons/${appConfig.logoImageName}` : null;

  onLogoClicked(): void {
    this.logoClick.emit();
  }
}
