// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --top-bar-color: var(--mi-white-color);
  --top-bar-background-color: var(--mi-black-color);
}

.video-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
}
.video-dialog-container ion-header {
  grid-row: 1;
  grid-column: 1;
}
.video-dialog-container ion-header ion-toolbar {
  --background: var(--top-bar-background-color);
  --ion-toolbar-color: var(--top-bar-color);
  --padding-start: 16px;
}
.video-dialog-container ion-header ion-toolbar .back-button {
  --icon-font-size: 24px;
  --icon-color: var(--top-bar-color);
}
.video-dialog-container ion-content {
  grid-row: 2;
  grid-column: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
