import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { FileSelectComponent } from 'src/app/modules/file-select/components/file-select/file-select.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AttachmentMimeType } from 'src/app/services/attachments.model';
import { AuthDialogPage, AuthUIServiceInterface, AUTH_UI_SERVICE } from 'src/app/services/auth/auth-ui.service.interface';
import { FileSelectLimits, FileType, SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { FileSelectService } from 'src/app/modules/file-select/services/file-select.service';
import { Profession, ProfessionsService } from 'src/app/services/professions.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { UploadService } from 'src/app/services/upload.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { VerificationRequest } from 'src/app/services/yeti-protocol/verification';
import appConfig from 'src/config/config';
import { Platform } from 'src/config/config.model';
import { BaseAuthPage } from '../auth-common/base-auth-page';
import { VerificationStatus } from 'src/app/services/verification.model';
import { FileSelectScope } from '../../../modules/file-select/services/file-select.service';

interface ProfessionInfo extends Profession {
  selected: boolean;
}

@Component({
  selector: 'app-auth-signup-main-roles',
  templateUrl: './auth-signup-main-roles.page.html',
  styleUrls: ['./auth-signup-main-roles.page.scss'],
})
export class AuthSignUpMainRolesPage extends BaseAuthPage implements OnInit {

  @ViewChild('fileSelect') fileSelectEl: FileSelectComponent;

  AttachmentMimeType = AttachmentMimeType;

  professions: Array<ProfessionInfo> = [];
  errorMessage: string = null;
  mainProfessionKeys = appConfig.authMainProfessionKeys;
  selectedProfession = 'Surgeon/Physician';
  noCredentials: boolean;
  otherProfession: boolean;
  selectedFile: SelectedFile;
  mimeType = '';
  fileType: FileType;
  maxImageFileSizeMb = FileSelectLimits.imageMaxFileSizeMB;
  verifying: boolean;
  profile: UserProfile;
  FileSelectScope = FileSelectScope;

  constructor(
    nav: IonNav,
    @Inject(AUTH_UI_SERVICE) authUIService: AuthUIServiceInterface,
    private professionsService: ProfessionsService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private appTranslationService: AppTranslationService,
    private fileSelectService: FileSelectService,
    private uploadService: UploadService,
    private toast: ToastService
  ) {
    super(nav, authUIService);
  }

  ngOnInit(): void {
    this.authUIService.nav = this.nav;
    this.initFileTypeBasedOnDevice();
    this.professionsService.getProfessions(this.mainProfessionKeys)
      .subscribe(
        professions => {
          this.professions = professions.map(profession => {

            const { key, icon } = profession;
            let { label } = profession;

            if (key === 'Physician Assistant/Nurse Practitioner') {
              label = this.appTranslationService.instant('app.dialogs.auth.AuthSignupMainRolesPage.orp-nurse-practitioner');
            }

            return { key, label, icon, selected: false };
          });
        }
      );

    this.authUIService.getUserProfile().then(profile => {
      this.profile = profile;
    });
  }

  get isPendingVerification(): boolean {
    return this.profile?.verificationStatus === VerificationStatus.PENDING;
  }

  get optionSelected(): boolean {
    return (this.selectedFile && !this.noCredentials && this.selectedProfession?.length > 0) ||
      (!this.selectedFile && this.noCredentials && this.selectedProfession?.length > 0) ||
      (!this.selectedFile && !this.noCredentials && this.isPendingVerification && this.selectedProfession?.length > 0) ||
      (!this.selectedFile && !this.noCredentials && !this.selectedProfession && !this.isPendingVerification && this.otherProfession)
      ? true : false;
  }

  onSelect(professionKey: string): void {
    this.professions.forEach(profession => {
      profession.selected = profession.key === professionKey;
    });
  }

  onContinue(): Promise<any> {

    if (this.selectedProfession && this.selectedProfession.length > 0) {

      const onError = err => {
        console.log(err);
        this.showError(err?.message);
        this.errorMessage = err?.message;
      }

      if (!this.selectedFile) {
        return this.authUIService.updateUserProfile({
          profession: this.selectedProfession
        }).then(() => {
          return this.authUIService.navigate(AuthDialogPage.SignUp06Confirm);
        })
          .catch(onError);
      } else {
        return this.submitVerification()
          .then(() => {
            return this.authUIService.updateUserProfile({
              profession: this.selectedProfession
            }).then(() => {
              return this.authUIService.navigate(AuthDialogPage.SignUp06Confirm);
            })
              .catch(onError);
          })
      }
    } else {
      return this.authUIService.navigate(AuthDialogPage.SignUp04ChooseOtherRoles);
    }
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  getProfessionLabelSplit(label: string): Array<string> {
    return label?.length > 0 ? label.split('/') : [];
  }

  get disableVerifyCredentialsRadioButton(): boolean {
    return !this.selectedProfession || this.selectedProfession?.length === 0 || this.isPendingVerification;
  }

  get disableOtherProfessionRadioButton(): boolean {
    return this.selectedFile || this.noCredentials || this.isPendingVerification ? true : false;
  }

  selectedProfessionChanged(): void {

    if (this.selectedProfession?.length > 0) {
      this.otherProfession = null;
    } else {
      this.noCredentials = null;
      this.selectedFile = null;
    }
  }

  otherProfessionChanged(): void {

    if (this.otherProfession) {
      this.selectedFile = null;
      this.noCredentials = null;
      this.selectedProfession = null;
    }
  }

  noCredentialsChanged(): void {

    if (this.noCredentials) {
      this.selectedFile = null;
    }
  }

  initFileTypeBasedOnDevice(): void {
    switch (appConfig.platform) {
      case Platform.BROWSER:
      case Platform.PWA:
        this.mimeType = [AttachmentMimeType.JPEG, AttachmentMimeType.JPG, AttachmentMimeType.PNG, AttachmentMimeType.PDF].join(',');
        this.fileType = null;
        break;
      case Platform.ANDROID:
      case Platform.IOS:
      default:
        this.mimeType = this.fileSelectService?.defaultImageMimeTypes.join(',');
        this.fileType = FileType.IMAGE;
        break;
    }
  }

  fileSelected(selectedFiles: Array<SelectedFile>): void {

    if (!selectedFiles || !selectedFiles?.length || selectedFiles?.length < 1) {
      return;
    }

    this.selectedFile = selectedFiles[0];
  }

  selectFile(): void {

    if (this.selectedFile || this.disableVerifyCredentialsRadioButton) {
      return;
    }

    this.fileSelectEl.selectFile();
  }

  changeSelectedFile(): void {

    if (this.disableVerifyCredentialsRadioButton) {
      return;
    }

    this.fileSelectEl.selectFile();
  }

  submitVerification(): Promise<void> {

    if (this.verifying) {
      return Promise.reject();
    }

    if (this.isPendingVerification) {
      return Promise.resolve();
    }

    const verificationData: VerificationRequest = {
      image: {
        key: 'image',
        file: this.selectedFile.file,
        mimeType: this.selectedFile.file.type,
        fileName: this.selectedFile.file.name
      },
      university: '',
      graduate: '',
      professional: 'true'
    }

    this.verifying = true;

    return new Promise((resolve, reject) => {
      this.uploadService.uploadVerificationFile(verificationData).then(() => {
        resolve();
      }).catch(errResponse => {
        this.showError(errResponse);
        reject();
      }).finally(() => {
        this.verifying = false;
      });
    });
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }
}
