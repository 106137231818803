import { Injectable } from '@angular/core';

import appConfig from 'src/config/config';
import { Script, Scripts } from 'src/config/config.model';

export interface ScriptWithState extends Script {
  state: 'loaded' | 'not-loaded' | 'loaded-with-error';
}

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  scripts: Record<string, ScriptWithState> = {};

  constructor() {
    this.init(appConfig.scripts);
  }

  init(scripts: Scripts): void {
    scripts.forEach((script: Script) => {
      this.scripts[script.name] = {
        name: script.name,
        state: 'not-loaded',
        src: script.src,
        dataDomainScript: script.dataDomainScript,
        enabled: script.enabled
      };
    });
  }

  load(...scripts: string[]): Promise<any[]>{
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string): Promise<ScriptWithState> {
    return new Promise((resolve, reject) => {
      const scriptState = this.scripts[name];
      if(!scriptState){
        return reject(`not configured script: ${name}`);
      }
      if(!scriptState.enabled) {
        return reject(`script: ${name} is not enabled for this environment.`);
      }
      // resolve if already loaded
      if (this.scripts[name]?.state === 'loaded') {
        return resolve(this.scripts[name]);
      }
      else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptState.src;
        // needed for cookiePro
        script.charset = 'UTF-8';
        script.setAttribute('data-domain-script', scriptState.dataDomainScript);
        if ('readyState' in script) {  // IE
          const ieScript = script as any;
          ieScript.onreadystatechange = () => {
            if (ieScript.readyState === 'loaded' || ieScript.readyState === 'complete') {
              ieScript.onreadystatechange = null;
              scriptState.state = 'loaded';
              resolve(scriptState);
            }
          };
        } else {  // others
          script.onload = () => {
            scriptState.state = 'loaded';
            resolve(scriptState);
          };
        }
        script.onerror = (_error: any) => {
          scriptState.state = 'loaded-with-error';
          resolve(scriptState);
        }
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
