import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Connection } from 'src/app/services/yeti-protocol/connections';
import { IonInfiniteScroll, IonRefresher, ModalController } from '@ionic/angular';
import { ConnectionRequestsService } from 'src/app/services/messenger/connection-requests.service';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { ConnectionsApiService } from 'src/app/services/messenger/connections-api.service';
import { ConnectionStatus } from 'src/app/services/yeti-protocol/connection';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { AcceptRejectItemData } from '../../accept-reject-list/accept-reject-list.component';
import { Store } from '@ngxs/store';
import { PendingConnectionRequestsState } from 'src/app/state/pending-connection-requests/pending-connection-requests.state';
import {
  PendingConnectionRequestsSubState
} from 'src/app/state/pending-connection-requests/pending-connection-requests.model';
import { PendingConnectionRequests } from 'src/app/state/pending-connection-requests/pending-connection-requests.actions';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-connection-requests-list',
  templateUrl: './connection-requests-list.component.html',
  styleUrls: ['./connection-requests-list.component.scss'],
})
export class ConnectionRequestsListComponent implements OnInit, OnDestroy {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonRefresher) listRefresher: IonRefresher;

  @Input() showMaxConnectionRequestsCount: number;
  @Input() withPaging: boolean;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showSeeAll: boolean;
  @Input() parentSource = ActionSource.unspecified;
  @Input() source: ActionSource | string;

  @Output() openMessenger: EventEmitter<string> = new EventEmitter();
  @Output() seeAll: EventEmitter<void> = new EventEmitter();

  pendingIncomingConnectionRequestsState$: Observable<PendingConnectionRequestsSubState>;
  connectionRequests: Array<Connection> = [];
  totalConnectionRequestsCount: number;
  connectionRequestsLoading: boolean;
  acceptRejectConnectionRequestsItems: Array<AcceptRejectItemData>;

  private readonly start = 0;
  private readonly itemsPerPage = 15;
  private pendingIncomingConnectionRequestsStateSubscription: Subscription;
  private refreshing: boolean;

  constructor(
    private connectionRequestsService: ConnectionRequestsService,
    private zone: NgZone,
    private appNavController: AppNavController,
    private connectionsApiService: ConnectionsApiService,
    private dialogs: DialogsUIService,
    private store: Store,
    private modalController: ModalController
  ) {
    this.pendingIncomingConnectionRequestsState$ = this.store.select(PendingConnectionRequestsState.incomingPendingConnectionRequests);
  }

  ngOnInit(): void {

    this.source = this.source || this._source;

    this.pendingIncomingConnectionRequestsStateSubscription = this.pendingIncomingConnectionRequestsState$.subscribe(state => {

      this.connectionRequests = state.pendingConnectionRequests;
      this.connectionRequestsLoading = state.loading;
      this.totalConnectionRequestsCount = state.totalCount;

      this.acceptRejectConnectionRequestsItems = this.adaptConnectionRequestsData() || [];

      if (this.infiniteScroll) {
        this.infiniteScroll?.complete();
      }

      if (this.listRefresher && this.refreshing) {
        this.listRefresher?.complete();
        this.refreshing = false;
      }

      if (this.connectionRequests.length < this.totalConnectionRequestsCount) {
        this.disableInfiniteScroll(false);
      } else {
        this.disableInfiniteScroll(true);
      }
    });

    if (this.withPaging) {
      this.getConnectionRequests(0, true);
    }
  }

  ngOnDestroy(): void {
    this.pendingIncomingConnectionRequestsStateSubscription?.unsubscribe();
  }

  getConnectionRequests(start: number, refresh?: boolean): void {

    const fetchPendingConnectionRequestsPagingPayload: PendingConnectionRequests.FetchPendingConnectionRequestsPagingPayload = {
      pageIndex: start
    }

    if (refresh) {
      this.refreshing = true;
    }

    this.store.dispatch(new PendingConnectionRequests.FetchIncomingPendingConnectionRequests(fetchPendingConnectionRequestsPagingPayload));

    if (refresh) {
      this.connectionRequestsService.setIncomingPendingConnectionStateToDefault();
    }
  }

  loadMoreRequests(): void {
    if (this.canLoadMore) {
      const start = Math.floor(this.totalConnectionRequestsCount / this.itemsPerPage);
      return this.getConnectionRequests(start);
    }
  }

  refreshList(): void {
    this.getConnectionRequests(this.start, true);
  }

  get canLoadMore(): boolean {
    return this.withPaging && this.connectionRequests?.length < this.totalConnectionRequestsCount;
  }

  onReload(): void {
    this.getConnectionRequests(this.start, true);
  }

  acceptConnectionRequest(data: { id: string, userId: string }): void {

    const connection = this.connectionRequests.find(con => con._id === data.id);

    if (!connection) {
      return;
    }

    this._updateConnectionStatus('connected', connection);
  }

  async ignoreConnectionRequest(data: { id: string, userId: string }): Promise<void> {

    const connection = this.connectionRequests.find(con => con._id === data.id);

    if (!connection) {
      return;
    }

    const shouldProceed = await this.showIgnoreConnectionRequestDialog();

    if (shouldProceed) {
      this._updateConnectionStatus('rejected', connection);
    }
  }

  private async showIgnoreConnectionRequestDialog(): Promise<boolean> {

    const yesActionKey = 'yes';
    const cancelActionKey = 'cancel';

    const ignoreConnectionRequestConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.messenger.ignore-connection-request-confirm-title'
        },
        message: {
          text: ''
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.messenger.ignore-connection-request-decline-button-text',
            },
            className: 'secondary'
          },
          {
            key: yesActionKey,
            label: {
              translationKey: 'app.messenger.ignore-connection-request-confirm-button-text',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(ignoreConnectionRequestConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === yesActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  onOpenMessenger(data: { id: string, userId: string }): void {
    this.openMessenger.emit(data.id);
  }

  async onProfileImageClicked(userId: string): Promise<void> {

    const modalShowing = await this.modalController.getTop();

    if (this.withPaging && modalShowing) {
      this.modalController.dismiss();
    }

    this.appNavController.openPublicUserProfile(userId);
  }

  private _updateConnectionStatus(status: ConnectionStatus, connection: Connection): void {
    this.connectionsApiService.updateConnection(connection?._id, status)
      .then((updatedConnection: Connection) => {
        this.onReload();
        this.connectionRequestsService.propagateConnectionRequestUpdate(updatedConnection);
      })
      .catch(err => {
        console.error(err);
      });
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  private adaptConnectionRequestsData(): Array<AcceptRejectItemData> {

    if (!this.connectionRequests?.length) {
      return [];
    }

    return this.connectionRequests.map(request => {
      return {
        id: request?._id || '',
        imageUrl: request?.initiator?.profileImageUrl || (request?.initiator as any)?.imageUrl || '',
        userId: request?.initiator?.userId || (request?.initiator as any)?.id || '',
        isAOMember: request?.initiator?.isAOMember || '',
        firstName: request?.initiator?.firstName || '',
        lastName: request?.initiator?.lastName || '',
        isVerified: request?.initiator?.isVerified || '',
        entityTitle: request?.initiator?.country,
        infoBubbleMessage: request?.message
      } as AcceptRejectItemData;
    })?.slice(0, this.showMaxConnectionRequestsCount);
  }

  onSeeAllClicked(): void {
    this.seeAll.emit();
  }

  get _source(): string {
    const componentSource = ActionSource.connectionRequestsList;

    return this.parentSource && this.parentSource !== ActionSource.unspecified ?
      `${this.parentSource}-${componentSource}` : componentSource;
  }

}
