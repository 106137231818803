import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GroupListItem } from 'src/app/services/groups/group.model';
import { ShareToObjType, ShareToObject } from 'src/app/services/sharing/sharing.model';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { Connection } from 'src/app/services/yeti-protocol/connections';
import { AOEvent } from 'src/app/services/yeti-protocol/event';
import { ConnectedUser } from 'src/app/services/yeti-protocol/public-profile';

@Component({
  selector: 'app-share-selected-recipient-card',
  templateUrl: './share-selected-recipient-card.component.html',
  styleUrls: ['./share-selected-recipient-card.component.scss'],
})
export class ShareSelectedRecipientCardComponent implements OnInit, OnDestroy {

  @Input() recipient: ShareToObject;
  @Input() hideRemoveAndAddMore: boolean;

  @Output() removeSelectedRecipient: EventEmitter<ShareToObject> = new EventEmitter();
  @Output() clickedSelectedRecipient: EventEmitter<ShareToObject> = new EventEmitter();

  private userProfileSubscription: Subscription;
  private userProfile: UserProfile;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  get getConnectionPartner(): ConnectedUser {
    const recipient = this.recipient?.object as Connection;

    if (recipient?.initiator?.userId !== this.userProfile?.userId) {
      return recipient?.initiator;
    } else {
      return recipient?.partner;
    }
  }

  get groupImageUrl(): string {

    const recipient = this.recipient as any;
    return recipient?.imageUrl ? recipient?.imageUrl : null;
  }

  get profileImageUrl(): string {

    const recipient = this.recipient as any;
    return recipient?.imageUrl ? null : this.getConnectionPartner?.profileImageUrl;
  }

  get eventImageUrl(): string {

    const recipient = this.recipient as any;
    return recipient?.imageUrl ? recipient?.imageUrl : null;
  }

  get groupTitleOrFullName(): string {

    if(this.isGroup) {
      return (this.recipient?.object as GroupListItem)?.title;
    } else if(this.isEvent) {
      return (this.recipient?.object as AOEvent)?.text;
    } else if (this.isContact) {
      return this.getConnectionPartner?.fullName;
    }
    return '';
  }

  get isGroup(): boolean {
    return this.recipient?.type === ShareToObjType.GROUP;
  }

  get isContact(): boolean {
    return this.recipient?.type === ShareToObjType.CONTACT;
  }

  get isEvent(): boolean {
    return this.recipient?.type === ShareToObjType.EVENT;
  }

  triggerRemoveSelectedRecipient(): void {
    this.removeSelectedRecipient.emit(this.recipient);
  }

  triggerClickedSelectedRecipient(): void {
    this.clickedSelectedRecipient.emit(this.recipient);
  }

}
