import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-web-auto-suggest-pop-up',
  templateUrl: './web-auto-suggest-pop-up.component.html',
  styleUrls: ['./web-auto-suggest-pop-up.component.scss'],
})

export class WebAutoSuggestPopUpComponent {
  @Input() suggestList: Array<string>;
}
