import { Routes } from '@angular/router';

export const rootRoutes: Routes = [
  {
    path: 'cmf',
    loadChildren: () => import('./cmf.module').then(m => m.CMFModule)
  },
  {
    path: 'cmf',
    loadChildren: () => import('./cmf-auxiliary.module').then(m => m.CMFAuxiliaryModule),
    outlet: 'dialog'
  }
];

export const navigationTabsRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./cmf-tabs.module').then(m => m.CMFTabsModule)
  }
];
