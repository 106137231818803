import { Component, Input } from '@angular/core';

import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-recommend-button',
  templateUrl: './recommend-button.component.html',
  styleUrls: ['./recommend-button.component.scss'],
})
export class RecommendButtonComponent extends BaseButtonComponent<void> {
  @Input() recommended = false;
}
