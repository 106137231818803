// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --ao-event-participants-list-padding-top: 20px;
  --ao-event-participants-list-padding-bottom: 50px;
  --ao-event-participants-list-padding-start: 20px;
  --ao-event-participants-list-padding-end: 20px;
}

.ao-events-participants-list-container {
  width: 100%;
  height: 100%;
}
.ao-events-participants-list-container ion-content {
  --background: var(--mi-background-color);
  --padding-bottom: var(--ao-event-participants-list-padding-bottom);
}
.ao-events-participants-list-container ion-content .contributors-section, .ao-events-participants-list-container ion-content .participants-section {
  padding: 10px 20px;
  margin: 15px 0px;
  background-color: var(--mi-white-color);
}
.ao-events-participants-list-container ion-content .contributors-section .section-title, .ao-events-participants-list-container ion-content .participants-section .section-title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  color: var(--mi-content-events-color-2);
  padding: 10px 0px 20px;
}
.ao-events-participants-list-container ion-content .contributors-section .section-title .totalNumber, .ao-events-participants-list-container ion-content .participants-section .section-title .totalNumber {
  padding-left: 5px;
}
.ao-events-participants-list-container ion-content .contributors-section .participants-list, .ao-events-participants-list-container ion-content .participants-section .participants-list {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
