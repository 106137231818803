import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addUrlTimestamp'
})
export class AddUrlTimestampPipe implements PipeTransform {

  transform(url: string): string {
    if(url){
      const connector = url.indexOf('?') === -1 ? '?' : '&';
      return `${url}${connector}timestamp=${Date.now()}`;
    }
    return url;
  }
}
