import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy } from '@angular/core';

/* eslint-disable */
import { AOEvent, AOEventParticipant, AOEventParticipantType } from 'src/app/services/yeti-protocol/event';
/* eslint-enable */

// components
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';
import { SwiperOptions } from 'swiper';

// services
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

@Component({
  selector: 'app-event-contributors-card',
  templateUrl: './event-contributors-card.component.html',
  styleUrls: ['./event-contributors-card.component.scss'],
})
export class EventContributorsCardComponent implements AfterViewInit, OnDestroy {

  @Input() aoEventContributors: Array<AOEventParticipant>;
  @Input() totalAoEventContributors: number;
  @Input() aoEvent: AOEvent;

  swiperLogic: SwiperLogic;

  constructor(
    private el: ElementRef,
    private responsiveUtilsService: ResponsiveUtilsService,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
    private navController: NavControllerService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private appNavController: AppNavController
  ) {
    this.swiperLogic = new SwiperLogic(227, this.responsiveUtilsService, this.el, 1);
  }

  ngAfterViewInit(): void {
    this.swiperLogic.afterViewInit();
  }

  ngOnDestroy(): void {
    this.swiperLogic.destroy();
  }

  get swiperConfig(): SwiperOptions {
    return this.swiperLogic.config;
  }

  seeAllContributors(): void {
    this.dialogs.showAoEventParticipantsDialog(this.aoEvent?._id, AOEventParticipantType.CONTRIBUTOR)
      .then(res => {
        if(res.action === 'sendMessageTo'){
          const url = `/${this.contextService.currentContext.key}/chat/${res.data}`;
          this.navController.navigateForward(url);
        }
        if(res.action === 'openPublicUserProfile'){
          this.appNavController.openPublicUserProfile(res.data);
        }
      });
  }
}
