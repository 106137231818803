// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --dialog-padding-top: 0px;
  --dialog-padding-bottom: 0px;
  --dialog-padding-start: 20px;
  --dialog-padding-end: 20px;
}

.reorder-case-media-dialog-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  height: 100%;
}
.reorder-case-media-dialog-container .top-bar-container {
  grid-row: 1;
  grid-column: 1;
}
.reorder-case-media-dialog-container .top-bar-container app-top-bar {
  --top-bar-padding-start: var(--dialog-padding-start);
  --top-bar-padding-end: var(--dialog-padding-end);
  --top-bar-column-gap: 20px;
  --top-bar-title-text-align: center;
}
.reorder-case-media-dialog-container .top-bar-container .save-btn {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reorder-case-media-dialog-container .top-bar-container .save-btn .button-text {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-primary-color);
}
.reorder-case-media-dialog-container .top-bar-container .save-btn.disabled .button-text {
  color: var(--mi-color-text-lite);
}
.reorder-case-media-dialog-container .content-container {
  grid-row: 2;
  grid-column: 1;
}
.reorder-case-media-dialog-container .content-container ion-list {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-list-header {
  padding-left: var(--dialog-padding-start);
  padding-right: var(--dialog-padding-end);
  padding-top: 20px;
  padding-bottom: 0;
  min-height: 30px;
  position: relative;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-list-header ion-label {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-semi-bold);
  color: var(--mi-grey-color-16);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --inner-padding-end: 0;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item:last-of-type {
  border-bottom: var(--mi-light-purple-5-color);
  background-color: var(--mi-light-purple-5-color);
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper {
  padding-left: var(--dialog-padding-start);
  padding-right: var(--dialog-padding-end);
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper .item-info-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper .item-info-container .image-container {
  grid-row: 1;
  grid-column: 1;
  cursor: pointer;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper .item-info-container .image-container ion-thumbnail {
  width: 45px;
  height: 45px;
  --border-radius: 4px;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper .item-info-container .image-container ion-thumbnail ion-img {
  width: 45px;
  height: 45px;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper .item-info-container .image-name-container {
  grid-row: 1;
  grid-column: 2;
  position: relative;
  height: 37px;
  display: flex;
  align-items: center;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper .item-info-container .image-name-container .image-name-wrapper {
  position: absolute;
  width: 100%;
  max-height: 37px;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper .item-info-container .image-name-container .image-name-wrapper .image-name {
  --multiline-text-with-ellipsis-max-height: 37px;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper .item-info-container .image-name-container .image-name-wrapper .image-name ::ng-deep .multiline-text-with-ellipsis-container .multiline-text-container .text {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: left;
  width: 100%;
}
.reorder-case-media-dialog-container .content-container ion-list ion-reorder-group ion-item .item-wrapper .reorder-handle-container {
  grid-row: 1;
  grid-column: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
