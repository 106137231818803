import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

/* eslint-disable */
import { StreamFilter } from 'src/app/services/yeti-protocol/my-stream';
/* eslint-enable */

// services
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';


@Component({
  selector: 'app-stream-filters-tabs',
  templateUrl: './stream-filters-tabs.component.html',
  styleUrls: ['./stream-filters-tabs.component.scss'],
})
export class StreamFiltersTabsComponent implements OnInit, OnDestroy {

  @Input() filters: Array<StreamFilter>;
  @Input() streamType: string;

  @Output() tabClicked: EventEmitter<string> = new EventEmitter();
  @Output() selectedFilterRemoved: EventEmitter<void> = new EventEmitter();

  selectedFilterId: string;

  private routeQueryParamsSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.routeQueryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.selectedFilterId = queryParams?.filter;
    });
  }

  ngOnDestroy(): void {
    this.routeQueryParamsSubscription?.unsubscribe();
  }

  applyFilter(event: Event, filterId: string): void {

    this.uiUtilsService.stopEventPropagation(event);

    let url = `${this.contextService?.currentContext?.key}/feed?filter=${filterId}`;

    if (this.selectedFilterId && this.selectedFilterId === filterId) {
      url = `${this.contextService?.currentContext?.key}/feed`;
      this.selectedFilterId = null;
      this.selectedFilterRemoved.emit();
    }

    this.tabClicked.emit(filterId);

    this.trackStreamFiltrTabClicked(filterId);

    setTimeout(() => {
      this.router.navigateByUrl(url);
    }, 250);
  }

  trackStreamFiltrTabClicked(filterId: string): void {
    if (filterId) {
      this.trackingService.trackGenericClickedAction(filterId, 'stream-filters', this.streamType);
    }
  }
}
