import { Article } from '../my-stream.model';
import { Group } from '../yeti-protocol/chatter-api';
import { ArticleSearch, EventSearch, GroupSearch, SurgeryReferenceSearch } from '../yeti-protocol/search';
import { ObjectForSharing, ObjectForSharingMeta, ObjectForSharingType } from './sharing.model';
import { ClinicalCase } from '../yeti-protocol/clinical-case';

import appConfig from 'src/config/config';
import { AOEvent } from '../yeti-protocol/event';
import { UrlUtilsService } from '../utils/url-utils.service';
import { ContextService, CONTEXT_SERVICE } from '../context/context.model';
import { Inject } from '@angular/core';
import { PublicProfile } from '../yeti-protocol/public-profile';
import { OrcIdArticle } from '../yeti-protocol/article';

export abstract class SharingAbstractService {
  config: { webUrl: string } = appConfig;
  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private urlUtilsService: UrlUtilsService
  ) { }

  // This is needed as we get 2 forward slashes after webUrl and before context which is causing shared link not to open targeted resource
  // on mobile apps (mobile app opens on home, and that's it).
  get currentContextUrl(): string {
    const baseUrlNoForwardSlash = this.urlUtilsService.stripLastChar(this.config.webUrl, '/');
    return baseUrlNoForwardSlash + `${this.contextService.currentContext.homePath}`;
  }

  getArticleSharingObject(article: Article | ArticleSearch): ObjectForSharing {
    return {
      _id: article?._id,
      title: article?.title,
      image: article?.previewImageUrl,
      defaultImage: `${this.config.webUrl}assets/images/default-articles-image.svg`,
      url: `${this.currentContextUrl}/article/${article?._id}`,
      type: ObjectForSharingType.ARTICLE,
      wrapperUrl: `${this.currentContextUrl}/wrapper/article/${article?._id}`
    };
  }

  getOrcIdArticleSharingObject(orcIdArticle: OrcIdArticle): ObjectForSharing {
    return {
      _id: orcIdArticle?._id || orcIdArticle?.id || orcIdArticle?.origin || '',
      title: orcIdArticle?.title,
      defaultImage: `${this.config.webUrl}assets/images/default-articles-image.svg`,
      url: orcIdArticle?.origin || '',
      type: ObjectForSharingType.ORCID_ARTICLE,
      wrapperUrl: orcIdArticle?.origin || '',
    };
  }

  getGroupSharingObject(group: Group | GroupSearch): ObjectForSharing {
    return {
      _id: group?._id,
      title: group?.title,
      image: group?.imageUrl,
      defaultImage: `${this.config.webUrl}assets/images/default-groups-image.svg`,
      url: `${this.currentContextUrl}/group/${group?._id}`,
      type: ObjectForSharingType.GROUP,
      wrapperUrl: `${this.currentContextUrl}/wrapper/group/${group?._id}`
    };
  }

  getSurgeryReferenceSharingObject(surgeryReference: SurgeryReferenceSearch): ObjectForSharing {
    return {
      _id: surgeryReference?.id,
      title: surgeryReference?.title,
      image: surgeryReference?.previewImageUrl,
      defaultImage: null,
      url: surgeryReference?.url,
      type: ObjectForSharingType.SURGERY_REFERENCE,
      wrapperUrl: surgeryReference?.url,
    };
  }

  getEventSharingObject(event: AOEvent | EventSearch): ObjectForSharing {
    let urlToShare = '';
    if (event?.website) {
      urlToShare = event?.website;
    } else {
      urlToShare = this.contextService?.currentContext?.moreEventsLink;
    }
    return {
      _id: event?._id,
      title: event?.text,
      image: event?.imageUrl,
      defaultImage: `${this.config.webUrl}assets/images/default-events-image.png`,
      url: urlToShare,
      type: ObjectForSharingType.EVENT,
      wrapperUrl: ''
    };
  }

  getCaseSharingObject(clinicalCase: ClinicalCase): ObjectForSharing {
    const meta: ObjectForSharingMeta = {
      numberOfGroupsSharedTo: clinicalCase?.numberOfGroupsSharedTo,
      groupsSharedTo: clinicalCase?.groupsSharedTo,
      numberOfEventsSharedTo: clinicalCase?.numberOfEventsSharedTo,
      eventsSharedTo: clinicalCase?.eventsSharedTo
    }

    return {
      _id: clinicalCase?._id,
      title: clinicalCase?.title,
      image: clinicalCase?.blurredUrl,
      defaultImage: null,
      url: `${this.currentContextUrl}/case/${clinicalCase?._id}`,
      type: ObjectForSharingType.CASE,
      meta: meta,
      wrapperUrl: `${this.currentContextUrl}/wrapper/joinmyao/case/${clinicalCase?._id}`
    }
  }

  getProfileSharingObject(profile: PublicProfile): ObjectForSharing {
    const meta: ObjectForSharingMeta = {
      profileFirstName: profile.firstName,
      profileLastName: profile.lastName,
      profileIsVerified: profile?.isVerified,
      profileOrganization: profile?.organization,
      profileDepartment: profile?.department,
      profileCountry: profile?.country
    }

    return {
      _id: profile?.userId,
      title: profile?.fullName,
      image: profile?.profileImageUrl,
      url: `${this.currentContextUrl}/public/profile/${profile?.userId}`,
      type: ObjectForSharingType.PROFILE,
      meta: meta,
      wrapperUrl: `${this.currentContextUrl}/wrapper/public/profile/${profile?.userId}`,
    }
  }
}
