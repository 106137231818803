import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  Approach,
  ContentType,
  Fracture,
  FractureAndTreatment,
  Preparation,
  Treatment
} from 'src/app/services/yeti-protocol/surgery-reference-schema';
import { SurgeryReferenceDialogData } from 'src/app/dialogs/surgery-reference/surgery-reference-dialog/surgery-reference-dialog.component';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';

// services
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MIComponentsModule } from 'src/app/components/components.module';
import { SurgeryReferenceFractureReviewWrapComponent }
  from '../surgery-reference-fracture-review-wrap/surgery-reference-fracture-review-wrap.component';
import { SurgeryReferenceModule } from '../surgery-reference.module';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'app-surgery-reference-review-container',
  templateUrl: './surgery-reference-review-container.component.html',
  styleUrls: ['./surgery-reference-review-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    MIComponentsModule,
    SurgeryReferenceFractureReviewWrapComponent,
    SurgeryReferenceModule,
    IconComponent
  ]
})
export class SurgeryReferenceReviewContainerComponent implements OnChanges {

  @Input() surgeryRefData: SurgeryReferenceDialogData;
  @Input() isCaseOwner = true;
  @Input() allowAdd: boolean;

  @Output() addClicked: EventEmitter<void> = new EventEmitter();
  @Output() removeClicked: EventEmitter<Fracture | Treatment | Approach | Preparation | FractureAndTreatment> = new EventEmitter();

  ContentType = ContentType;

  constructor(
    private dialogs: DialogsUIService,
    private authService: AuthService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.surgeryRefData) {
      this.surgeryRefData = changes.surgeryRefData.currentValue;
    }
  }

  async onRemoveBtnClick(item: Fracture | Treatment | Approach | Preparation | FractureAndTreatment): Promise<void> {

    try {
      const shouldRemove = await this.showShouldRemoveModuleDialog();

      if (shouldRemove) {
        this.removeClicked.emit(item);
      }
    } catch (err) {
      console.error(err);
    }
  }

  contentClicked(item: Fracture | Treatment | Approach | Preparation): void {
    this.authService.openAoLink(item?.url);
  }

  add(): void {
    this.addClicked.emit();
  }

  private async showShouldRemoveModuleDialog(): Promise<boolean> {

    const removeActionKey = 'remove';
    const cancelActionKey = 'cancel';

    const showShouldRemoveModuleDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.components.SurgeryReferenceReviewContainerComponent.delete-module-title'
        },
        message: {
          translationKey: 'app.components.SurgeryReferenceReviewContainerComponent.delete-module-warning-text'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.cancel',
            },
            className: 'secondary'
          },
          {
            key: removeActionKey,
            label: {
              translationKey: 'app.common.remove',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(showShouldRemoveModuleDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === removeActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
