import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import appConfig from 'src/config/config';
import { HttpClient } from '@angular/common/http';
import { LOCAL_STORAGE_SERVICE, StorageService } from './storage/storage.service';
import * as moment from 'moment';
import { ClientConfig, ClientConfigSuccessResponse } from './yeti-protocol/client-config';

export interface ClientConfigServiceConfig {
    backendIonicUrl: string;
}

export const CLIENT_CONFIG_STORAGE_KEY = 'client-config';

@Injectable({
    providedIn: 'root'
})
export class ClientConfigService {

    config: ClientConfigServiceConfig = {
        backendIonicUrl: appConfig.backendUrlIonic
    }

    constructor(
        private http: HttpClient,
        @Inject(LOCAL_STORAGE_SERVICE) private storage: StorageService
    ) { }

    getClientConfig(refresh = false): Promise<ClientConfig> {

        const getRequestUrl = `${this.config.backendIonicUrl}clientConfig`;

        if (refresh) {
            return firstValueFrom(this.http.get(getRequestUrl, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })).then((clientConfigRes: ClientConfigSuccessResponse) => {

                const config: ClientConfig = clientConfigRes?.clientConfig;

                this.saveClientConfig(config);
                return config;
            });
        } else {
            return this.getSavedClientConfig();
        }
    }

    saveClientConfig(config: ClientConfig): Promise<any> {
        return this.storage.set(CLIENT_CONFIG_STORAGE_KEY, {
            config,
            date: new Date()
        })
    }

    async getSavedClientConfig(): Promise<ClientConfig> {
        try {

            const data = await this.storage.get(CLIENT_CONFIG_STORAGE_KEY);

            if (!data?.config) {
                return this.getClientConfig(true);
            }

            if (!data?.date) {
                return this.getClientConfig(true);
            }

            const diffInMinutes = moment.duration(moment().diff(moment(data?.date))).asMinutes();

            if (diffInMinutes > 24 * 60) {
                return this.getClientConfig(true);
            }

            return data?.config;
        } catch (err) {
            return err;
        }
    }
}
