import { Injectable } from '@angular/core';

export interface EditorNodeData {
  node: any;
  range: any;
  content: string;
}

export const ATTRIBUTE_WHITESPACES = /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205f\u3000]/g; // eslint-disable-line no-control-regex

// eslint-disable-next-line max-len
export const IS_URL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/i;
export const IS_EMAIL = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

@Injectable({
  providedIn: 'root'
})
export class RichTextDocumentEditorUiService {

  extractFirstLinkFromContent(content: string = ''): string {
    return content.split('<a href="')?.[1]?.split('">')?.[0];
  }

  findFirstLinkFromText(text: string): string {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    const m = text.match(urlRegex);
    if (m) {
      return m[0];
    }
    return '';
  }

  isUrlEmail(url: string): Array<string> {
    const normalizedUrl = this._normalizeUrl(url);
    return normalizedUrl.match(IS_EMAIL) || normalizedUrl.match(IS_URL);
  }

  /* eslint-disable */
  createLinkElement(model: any, url: string, replaceRange: any): void {
    /* eslint-enable */
    model.enqueueChange(writer => {
      // let linkHref = encodeURI(this._parseUrl(url));
      let linkHref = this._parseUrl(url);
      linkHref = linkHref?.replace('\u200b', '')?.replace('%E2%80%8B', '')?.replace(ATTRIBUTE_WHITESPACES, '');
      const urlElement = writer.createText(linkHref, { linkHref: linkHref });
      model.insertContent(urlElement, replaceRange);
      // model.insertContent(writer.createText('\u200b')); // Zero character width space to get focus out
    });
  }

  mergeNodes(nodeList: Array<any>): string {
    const _nodeList = Array.isArray(nodeList) ? nodeList : Array.from(nodeList);
    return _nodeList.reduce((rangeText, node) => {

      if (node.item.is('softBreak')) {
        return ' ';
      }

      const data = node?.data && node?.data?.length ? node.data :
        node?.item?.data && node?.item?.data?.length ? node?.item?.data : ' ';
      const newRangeText = rangeText + data;

      return newRangeText;
    }, '');
  }

  /* eslint-disable */
  getNodesData(model: any, selection: any): Array<EditorNodeData> {
    /* eslint-enable */

    const nodes = [selection.focus.parent];
    const editorNodesWithData: Array<EditorNodeData> = [];

    while (nodes[nodes.length - 1]?.previousSibling) {
      nodes.push(nodes[nodes.length - 1]?.previousSibling);
    }

    if (nodes?.length) {
      for (const node of nodes) {
        const range = model.createRange(model.createPositionAt(node, 0), model.createPositionAt(node, 'end'));
        const nodeList = range.getItems();

        const _nodeList = Array.isArray(nodeList) ? nodeList : Array.from(nodeList);
        const content = _nodeList.reduce((rangeText, element) => {

          if (element.is('softBreak')) {
            return ' ';
          }

          const data = element?.data && element?.data?.length ? element.data : ' ';
          const newRangeText = rangeText + data;

          return newRangeText;
        }, '');

        editorNodesWithData.push({
          node: node,
          range: range,
          content: content
        });
      }
    }

    return editorNodesWithData;
  }

  _parseUrl(url: string): string {
    const normalizedUrl = this._normalizeUrl(url);
    return normalizedUrl.match(IS_EMAIL) ? `mailto:${url}` : url;
  }

  _normalizeUrl(url: string): string {
    url = String(url);
    return url.replace(ATTRIBUTE_WHITESPACES, '');
  }
}
