// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-content-parent-select-dialog-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content auto;
  padding: 20px 25px 40px 25px;
}
.create-content-parent-select-dialog-container .close-button-container {
  grid-row: 1;
  grid-column: 1;
  padding-bottom: 5px;
  display: flex;
  justify-content: end;
}
.create-content-parent-select-dialog-container .close-button-container .close-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: var(--mi-white-color);
  --icon-button-background-paddng: 0;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 24px;
  --icon-button-icon-color: var(--mi-secondary-color-2);
  outline: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 30px;
  width: 30px;
}
.create-content-parent-select-dialog-container .title-container {
  grid-row: 2;
  grid-column: 1;
  padding-bottom: 40px;
}
.create-content-parent-select-dialog-container .title-container .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: left;
  word-wrap: nowrap;
}
.create-content-parent-select-dialog-container .options-container {
  grid-row: 3;
  grid-column: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
