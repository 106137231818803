// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  color: var(--ion-color-primary);
  background-color: var(--ion-color-light-shade);
  overflow-y: auto;
  --padding: 0px;
  --panel-1-padding-top: 0px;
  --panel-1-padding-bottom: 15px;
  --panel-1-padding-start: 15px;
  --panel-1-padding-end: 0px;
  --panel-2-padding-top: 15px;
  --panel-2-padding-bottom: 15px;
  --panel-2-padding-start: 15px;
  --panel-2-padding-end: 15px;
  --panel-1-row-gap: 20px;
  --title-font-size: var(--font-size-extra-large);
  --panel-2-border-radius: 20px;
  --close-button-icon-size: 24px;
  --migration-dialog-cards-border-radius: 9px;
}
@media all and (min-width: 1000px) {
  :host {
    --padding: 50px;
    --title-font-size: var(--font-size-extra-large-2);
    --panel-1-row-gap: 30px;
    --close-button-icon-size: 30px;
    --panel-1-padding: 0px;
  }
}

.top-image {
  height: 40%;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}

.controls-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--mi-white-color);
}
.controls-area form {
  background-color: var(--mi-white-color);
}

ion-list-header {
  font-size: var(--font-size-extra-large);
  font-weight: 600;
}

app-generic-input {
  width: 100%;
  margin: 4px 0;
}

.controls-area,
ion-list,
form {
  border-top-left-radius: var(--mi-standard-margin);
  border-top-right-radius: var(--mi-standard-margin);
}

.bottom-line {
  font-size: var(--font-size-tiny);
  color: var(--mi-color-text-lite);
}
.bottom-line .line {
  width: 100%;
  text-align: center;
}
.bottom-line .link {
  text-decoration: underline;
  cursor: pointer;
}

.or-icon {
  display: block;
  margin: 0 auto;
}

.auth-button {
  height: 47px;
  margin: 4px 0;
  text-transform: none;
  box-shadow: none;
}

.migration-dialog {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  row-gap: 10px;
  background-color: var(--mi-purple-color-3);
  padding: var(--padding);
}
.migration-dialog .panel-1 {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
  row-gap: var(--panel-1-row-gap);
  padding-top: var(--panel-1-padding-top);
  padding-bottom: var(--panel-1-padding-bottom);
  padding-left: var(--panel-1-padding-start);
  padding-right: var(--panel-1-padding-end);
}
.migration-dialog .panel-1 .title {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-extra-large-2);
  color: var(--mi-grey-color-13);
}
.migration-dialog .panel-1 .title span {
  font-weight: var(--font-weight-bold);
}
.migration-dialog .panel-1 .subtitle {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-large-2);
  color: var(--mi-secondary-color-2);
}
.migration-dialog .panel-2 {
  grid-row: 2;
  grid-column: 1;
  background-color: var(--mi-white-color);
  border-radius: var(--panel-2-border-radius);
  position: relative;
}
@media all and (min-width: 1000px) {
  .migration-dialog {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    -moz-column-gap: 25px;
         column-gap: 25px;
    padding: 50px;
  }
  .migration-dialog .panel-1 {
    grid-row: 1;
    grid-column: 1;
  }
  .migration-dialog .panel-2 {
    grid-row: 1;
    grid-column: 2;
    width: 100%;
  }
}
.migration-dialog.mobile {
  --panel-1-padding-top: 25px;
  --panel-1-padding-bottom: 15px;
  --panel-1-padding-start: 15px;
  --panel-1-padding-end: 15px;
}
@media all and (max-height: 750px) {
  .migration-dialog.mobile .panel-2 {
    row-gap: 12.5px;
  }
}

app-text-field-limit-counter-wrapper {
  width: 100%;
}

app-back-button {
  --icon-font-size: 22px;
  position: absolute;
  top: var(--ion-safe-area-top);
  left: 26px;
  padding-top: 12px;
  z-index: 100;
}

.top-image {
  height: 25%;
  min-height: 1px !important;
}

.controls-area {
  height: 80%;
  background-color: var(--ion-color-primary-contrast);
}

.image-container {
  display: flex;
  justify-content: center;
}
.image-container ion-avatar {
  width: 148px;
  height: 148px;
  border: 5px solid var(--mi-white-color);
  margin-top: -74px;
  background-color: var(--mi-inputs-color);
}
.image-container ion-avatar ion-img {
  cursor: pointer;
}
.image-container ion-avatar input {
  display: none;
}
.image-container ion-avatar .icon-camera {
  width: 26px;
  height: 26px;
  position: relative;
  top: 35px;
  left: 42%;
  color: var(--mi-color-text-lite);
}
.image-container ion-avatar .image-title {
  position: relative;
  width: 55%;
  top: 30%;
  left: 24%;
  text-align: center;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}

ion-list .title-container app-generic-input ::ng-deep .generic-input-container ion-item input {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
}
ion-list .desc-container ion-textarea {
  padding: 0px 15px;
  border-radius: 3px;
  --background: var(--mi-inputs-color);
  color: var(--mi-color-text-lite);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
}
ion-list .section-title {
  margin-bottom: 0px;
  color: var(--mi-secondary-color-1);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
ion-list .visibility-container {
  --background: var(--mi-inputs-color);
  margin: 0 var(--mi-standard-padding) 0;
  border-bottom: 1px solid var(--mi-background-color);
}
ion-list .visibility-container .rtj-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
ion-list .visibility-container .visibility-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
ion-list .visibility-container .visibility-title {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
ion-list .visibility-container .visibility-text {
  white-space: normal;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  line-height: var(--font-line-height-small);
}
ion-list .visibility-container .visibility-radiobtn {
  --color: var(--mi-color-text-lite);
  --color-checked: var(--mi-primary-color);
}
ion-list .visibility-container.public {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
ion-list .visibility-container.rtj {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: none;
}
ion-list .visibility-container.private {
  margin-top: 20px;
  border: none;
  border-radius: 3px;
}
ion-list ion-button[expand=block] {
  flex-grow: 1;
}

.auth-button {
  --box-shadow: none;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
  margin-top: 30px;
}

ion-item.community-guidelines .text {
  text-align: center;
  margin-top: 0px;
  color: var(--mi-color-text-lite);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
}
ion-item.community-guidelines .text span {
  color: var(--mi-primary-color);
  cursor: pointer;
}

ion-toggle {
  --background-checked: var(--mi-primary-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
