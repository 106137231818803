import { InjectionToken } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { ContextConfigInterface } from 'src/config/config.model';
import { UpdateUserProfileData, UserProfile } from '../yeti-protocol/auth/mi';

import { AuthProvider } from '../yeti-protocol/auth/provider';
import { SignInData, SignUpData } from './logic/auth-logic.service.interface';

export enum AuthDialogPage {
  SignIn,
  Welcome,
  SignUp02ChooseContext,
  SignUp03ChooseMainRoles,
  SignUp04ChooseOtherRoles,
  SignUp06Confirm,
}

export interface SignupDetailsData {
  mainContext?: string;
  profession?: string;
  country?: string;
  countryISO2?: string;
  countryConfirmed?: boolean;
}

export interface AuthUIServiceInterface {
  nav: IonNav; // to be set by first dialog page
  readonly availableProviders: Array<AuthProvider>;
  showAccessBlockedDialog(): Promise<void>;
  showDialogForNewlyEnrolledUsers(): Promise<void>;
  openDialog(startPage: AuthDialogPage): Promise<void>;
  closeDialog(pageUrl?: string): Promise<void>;
  finishSignUpIfRequired(): Promise<boolean>; // true if signup master is started
  navigate(page: AuthDialogPage): Promise<any>;
  signIn(provider: AuthProvider, data?: SignInData): Promise<void>;
  signUpConfirmed(data?: UpdateUserProfileData): Promise<any>;
  getUserProfile(): Promise<UserProfile>;
  reloadUserProfile(): Promise<UserProfile>;
  updateUserProfile(data: UpdateUserProfileData): Promise<void>;
  addMissingContext(contexToAdd: ContextConfigInterface, deepLink?: string): Promise<void>;
  navigateToHome(contextKey: string): Promise<boolean>;
  getSignUpContexts(): Promise<Array<ContextConfigInterface>>;
  signUp(provider: AuthProvider, data: SignUpData): Promise<boolean>;
}

export const AUTH_UI_SERVICE = new InjectionToken<AuthUIServiceInterface>('auth-ui.service');
