// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ao-event-participants-dialog-container {
  width: 100%;
  height: 100%;
  background-color: var(--mi-background-color);
}
.ao-event-participants-dialog-container .content-wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  height: 100%;
  margin-top: 15px;
}
.ao-event-participants-dialog-container .content-wrapper ion-toolbar {
  grid-column: 1;
  grid-row: 1;
  padding: 10px 10px;
  --background: var(--mi-white-color);
  position: relative;
}
.ao-event-participants-dialog-container .content-wrapper .attendees-section {
  grid-column: 1;
  grid-row: 2;
}
.ao-event-participants-dialog-container .content-wrapper .attendees-section .participants-list {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
  padding: 10px 20px;
  margin: 15px 0px;
  background-color: var(--mi-white-color);
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .ao-event-participants-dialog-container .content-wrapper {
    margin-top: 0px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
