import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { Message } from 'src/app/services/yeti-protocol/message';
import { MessagePressedEvent } from '../messenger-list.model';
import { MessageCardBase } from '../message-card-base';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';

@Component({
  selector: 'app-message-card-text',
  templateUrl: './message-card-text.component.html',
  styleUrls: ['./message-card-text.component.scss'],
})
export class MessageCardTextComponent extends MessageCardBase {
  @Input() message: Message;
  @Input() userId: string;

  @Output() showCommands: EventEmitter<MessagePressedEvent> = new EventEmitter<MessagePressedEvent>()

  constructor(responsiveUtilsService: ResponsiveUtilsService) {
    super(responsiveUtilsService);
  }

  stripHtmlTagsFromString(content: string): string {
    return stripHtmlTagsFromString(content || '');
  }

  @HostBinding('class') get viewMode(): string {
    if(this.isOwnMessage){
      return 'chat-initiator';
    }
    return '';
  }
}
