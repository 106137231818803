// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  font-size: var(--font-size-extra-tiny);
  padding: 1px 4px;
  border-radius: 4px;
  vertical-align: middle;
}
:host.light {
  color: var(--mi-primary-color);
  background-color: var(--mi-white-color);
}
:host.dark {
  background-color: var(--mi-primary-color);
  color: var(--mi-white-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
