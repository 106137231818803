// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-line {
  display: flex;
  flex-direction: row;
}
.date-line .line-separator {
  flex-grow: 1;
  border-bottom: solid 2px var(--mi-grey-color-23);
  height: calc(var(--font-line-height-small) / 2);
}
.date-line .date-value {
  flex-grow: 0;
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  margin: 0 20px;
}

.notification {
  position: relative;
  padding-top: 20px;
}
.notification .message {
  border-radius: var(--mi-standard-padding);
  padding: var(--mi-standard-padding);
  color: var(--mi-color-text-lite);
  border: solid 0.5px var(--mi-color-text-lite);
  margin: 10px 30px;
  text-align: center;
  font-weight: var(--font-weight-light);
  font-style: normal;
  font-size: var(--font-size-regular);
}
.notification .message .center-place {
  position: absolute;
  top: 0;
  left: 50%;
}
.notification .message .center-place ::ng-deep app-user-profile-image {
  display: block;
  position: relative;
  top: 16px;
  left: -25px;
  width: 50px;
}

.start-message {
  padding: 16px;
}
.start-message .info {
  display: flex;
  flex-direction: row;
  line-height: 30px;
}
.start-message .info .user {
  font-weight: bold;
}
.start-message .info .dot {
  display: inline-block;
  margin: 0 4px;
  font-weight: bold;
}
.start-message .info app-user-profile-image {
  margin-right: 10px;
}
.start-message .message {
  background-color: var(--mi-background-color);
  color: var(--mi-color-text-lite);
  border-radius: var(--mi-standard-padding);
  border-top-left-radius: 0;
  padding: var(--mi-standard-padding);
}
.start-message.chat-initiator .info {
  flex-direction: row-reverse;
}
.start-message.chat-initiator .info app-user-profile-image {
  margin-right: 0;
  margin-left: 10px;
}
.start-message.chat-initiator .info .time {
  order: 2;
}
.start-message.chat-initiator .info .dot {
  order: 3;
}
.start-message.chat-initiator .info .user {
  order: 4;
}
.start-message.chat-initiator .message {
  background-color: var(--mi-primary-color);
  color: var(--mi-white-color);
  border-top-left-radius: var(--mi-standard-padding);
  border-top-right-radius: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
