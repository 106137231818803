import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-empty-state-message-and-button',
  templateUrl: './empty-state-message-and-button.component.html',
  styleUrls: ['./empty-state-message-and-button.component.scss'],
})

export class EmptyStateMessageAndButtonComponent  implements OnInit {
  @Input() message: string;
  @Input() buttonText: string;
  @Output() actionButtonClicked: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

  buttonClickedEmit(): void {
    this.actionButtonClicked.emit();
  }

}
