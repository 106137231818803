// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.global-search-container {
  width: 100%;
  min-height: 46px;
  background-color: var(--mi-blue-color-5);
  padding: 0 10px;
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.global-search-container.initial-state {
  grid-template-columns: min-content auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.global-search-container.initial-state .input-container {
  grid-column: 1;
}
.global-search-container.initial-state .input-container .search-input-field {
  display: none;
}
.global-search-container.initial-state .animated-placeholder-container {
  grid-column: 2;
}
.global-search-container.initial-state .action-buttons-container {
  display: none;
}
.global-search-container.focus-but-empty-search-value {
  grid-template-columns: auto min-content;
}
.global-search-container.focus-but-empty-search-value .input-container {
  grid-column: 1;
}
.global-search-container.focus-but-empty-search-value .input-container .search-icon {
  display: none;
}
.global-search-container.focus-but-empty-search-value .input-container .search-input-field {
  width: 100%;
}
.global-search-container.focus-but-empty-search-value .animated-placeholder-container {
  display: none;
}
.global-search-container.focus-but-empty-search-value .action-buttons-container {
  grid-column: 2;
}
.global-search-container.focus-with-text {
  grid-template-columns: auto min-content;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
.global-search-container.focus-with-text .input-container {
  grid-column: 1;
}
.global-search-container.focus-with-text .input-container .search-icon {
  display: none;
}
.global-search-container.focus-with-text .input-container .search-input-field {
  width: 100%;
}
.global-search-container.focus-with-text .animated-placeholder-container {
  display: none;
}
.global-search-container.focus-with-text .action-buttons-container {
  grid-column: 2;
}
.global-search-container .input-container {
  grid-row: 1;
  display: flex;
  align-items: center;
  height: 46px;
  margin-left: -10px;
  padding-left: 10px;
}
.global-search-container .input-container .search-icon {
  --icon-size: 24px;
  color: var(--mi-color-text-lite);
}
.global-search-container .input-container .search-input-field {
  height: 100%;
  color: var(--mi-secondary-color-2);
  border: none;
  background: transparent;
  outline: none;
  margin-left: -10px;
  padding-left: 10px;
}
.global-search-container .animated-placeholder-container {
  grid-row: 1;
  pointer-events: none;
}
.global-search-container .animated-placeholder-container p {
  margin: 0;
  color: var(--mi-color-text-lite);
  font-size: var(--font-size-regular);
  font-weight: var(--font-wight-regular);
  line-height: 1.65;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.global-search-container .animated-placeholder-container p .fixed-part {
  grid-row: 1;
  grid-column: 1;
  white-space: nowrap;
}
.global-search-container .animated-placeholder-container p .dynamic-part {
  grid-row: 1;
  grid-column: 2;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.global-search-container .animated-placeholder-container p .dynamic-part .dynamic-part-1,
.global-search-container .animated-placeholder-container p .dynamic-part .dynamic-part-2,
.global-search-container .animated-placeholder-container p .dynamic-part .dynamic-part-3,
.global-search-container .animated-placeholder-container p .dynamic-part .dynamic-part-4 {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}
.global-search-container .animated-placeholder-container p .dynamic-part .dynamic-part-1 {
  animation: animated-placeholder-part-1 8s linear infinite;
}
.global-search-container .animated-placeholder-container p .dynamic-part .dynamic-part-2 {
  animation: animated-placeholder-part-2 8s linear infinite;
}
.global-search-container .animated-placeholder-container p .dynamic-part .dynamic-part-3 {
  animation: animated-placeholder-part-3 8s linear infinite;
}
.global-search-container .animated-placeholder-container p .dynamic-part .dynamic-part-4 {
  animation: animated-placeholder-part-4 8s linear infinite;
}
.global-search-container .action-buttons-container {
  grid-row: 1;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.global-search-container .action-buttons-container app-icon {
  --icon-size: 22px;
  color: var(--mi-color-text-lite);
  cursor: pointer;
}
.global-search-container .action-buttons-container .separator-container p {
  margin: 0;
  color: var(--mi-color-text-lite);
  font-size: 28px;
  font-weight: 300;
}
.global-search-container .action-buttons-container.activate-search-button .search-button {
  color: var(--mi-primary-color);
}

.mobile-search app-search-bar::ng-deep ion-searchbar::ng-deep {
  --background: var(--mi-white-color);
  border-bottom: 1px solid var(--mi-grey-color-3);
  height: 60px;
}
.mobile-search app-search-bar::ng-deep ion-searchbar::ng-deep .searchbar-input-container::ng-deep .searchbar-input {
  padding-left: 20px;
}

@keyframes animated-placeholder-part-1 {
  0% {
    visibility: hidden;
  }
  12.25% {
    visibility: visible;
  }
  25% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes animated-placeholder-part-2 {
  0% {
    visibility: hidden;
  }
  25% {
    visibility: hidden;
  }
  27.75% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes animated-placeholder-part-3 {
  0% {
    visibility: hidden;
  }
  25% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  62.25% {
    visibility: visible;
  }
  75% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes animated-placeholder-part-4 {
  0% {
    visibility: hidden;
  }
  25% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  75% {
    visibility: hidden;
  }
  87.25% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
