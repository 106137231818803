// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skill-container {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-primary-color);
  border: 1px solid var(--mi-primary-color);
  border-radius: 10px;
  padding: 2px 10px 2px 10px;
  display: flex;
  align-items: center;
}
.skill-container .skill-chip-text {
  width: 100%;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.skill-container .close-icon {
  height: 20px;
  width: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
