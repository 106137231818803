export const UNKNOWN_VERSION = null;

export interface VersionInfo {
  version: string;
  isActive: boolean;
}

export interface ResourcesUpdateServiceConfig {
  serverUrl: string;
  timeout: number;
  versionFieldName: string;
  contentUrlFieldName: string;
}

export interface ResourcesUpdateServiceInterface {
  // version status queries
  getEmbeddedVersion(): Promise<VersionInfo>;
  getDownloadedVersions(): Promise<Array<VersionInfo>>;
  getRemoteVersion(): Promise<string>;

  // returns success of the downloading and unzipping
  downloadNewVersion(): Promise<boolean>;
  deleteDownloadedVersion(version: string): Promise<boolean>;

  switchAtVersion(version: string): Promise<void>;
  // removes not current downloaded versions which are not equal to remote version
  deleteNotRequiredVersions(): Promise<void>;
}
