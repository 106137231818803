import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

// services
import { InterestsService } from '../../services/interests.service';

// models
import { Interest } from '../../services/interests.model';
import { ContextConfigInterface } from '../../../config/config.model';

interface InterestListItem {
  interest: Interest,
  checked: boolean
}

@Component({
  selector: 'app-interests-list',
  templateUrl: './interests-list.component.html',
  styleUrls: ['./interests-list.component.scss'],
})
export class InterestsListComponent implements OnChanges, AfterViewInit {

  @Input() selectedInterestsIds: Array<string> = []; // always equal to the list in the db on the server
  @Input() context: ContextConfigInterface;
  @Output() interestSelectionChange: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  @Output() interestsListFormValidStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('f', { static: false }) interestsForm: NgForm;

  interests: Array<InterestListItem> = [];

  constructor(
    private interestsService: InterestsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes.selectedInterestsIds && !changes.selectedInterestsIds.firstChange) {
        this.interestsListFormValidStatusChange.emit(this.formValidStatus);
      }
    }, 0);
  }

  ngAfterViewInit(): void {
    this.interestsService.getInterests(this.context ? this.context.key : null).then((interests: Array<Interest>) => {
      this.interests = interests.map((interest: Interest) => {
        return {
          interest,
          checked: this.selectedInterestsIds.indexOf(interest.id) > -1
        }
      });
    });
  }

  onSelectionChanged(_event: Event): void {
    this.interestSelectionChange.emit(this.currentlySelectedInterestsIds);
    this.interestsListFormValidStatusChange.emit(this.formValidStatus);
  }

  get currentlySelectedInterestsIds(): Array<string> {
    return this.interests.filter((interestItem: InterestListItem) => {
      if (interestItem.checked) {
        return interestItem
      }
    }).map((interestItem: InterestListItem) => {
      return interestItem.interest.id;
    })
  }

  get formValidStatus(): boolean {
    return this.interestsForm.valid && !this.currentlySelectedInterestsEqualToInitial;
  }

  get currentlySelectedInterestsEqualToInitial(): boolean {
    const currentlySelected = this.currentlySelectedInterestsIds;
    currentlySelected.sort();
    const selectedInterestsFromComponentInput = this.selectedInterestsIds;
    selectedInterestsFromComponentInput.sort();

    return currentlySelected.toString() === selectedInterestsFromComponentInput.toString();
  }

  selectAllInterests(): void {
    this.interests.forEach((interest: InterestListItem) => {
      interest.checked = true;
    });
  }

}
