import { Injectable, Optional } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Zip } from '@awesome-cordova-plugins/zip/ngx';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';

import { IonicWebViewWrapperService } from './ionic-webview-wrapper.service';
import {
  VersionInfo,
  ResourcesUpdateServiceInterface,
  ResourcesUpdateServiceConfig,
  UNKNOWN_VERSION
} from './resources-update.service.interface';

import config from '../../../config/config';
import { version as currentVersion } from '../../version';
const SNAPSHOTS_DIR = 'ionic_built_snapshots';

const DOWNLOADED_FILE = 'downloaded.zip';

interface ResourcesUpdateInfo {
  version: string;
  url: string;
}

@Injectable()
export class ResourcesUpdateService implements ResourcesUpdateServiceInterface {
  config: ResourcesUpdateServiceConfig = {
    serverUrl: config.appUpdateUrl,
    timeout: config.requestTimeout,
    versionFieldName: config.appUpdateVersionFieldName,
    contentUrlFieldName: config.appUpdateContentUrlFieldName
  };

  constructor(
    private platform: Platform,
    private http: HTTP,
    private file: File,
    @Optional() private zip: Zip,
    private webView: IonicWebViewWrapperService
  ) {
  }

  getEmbeddedVersion(): Promise<VersionInfo> {
    return this.file.readAsText(`${this.file.applicationDirectory}www/assets`, 'version.txt')
      .then(embeddedVersion => {
        return {
          version: embeddedVersion,
          isActive: currentVersion === embeddedVersion
        };
      });
  }

  getRemoteVersion(): Promise<string> {
    return this._fetchUpdateInfo().then(info => {
      return info.version;
    });
  }

  getDownloadedVersions(): Promise<Array<VersionInfo>> {
    return this._dirExists(this.file.dataDirectory, SNAPSHOTS_DIR)
      .then(exists => {
        if(!exists){
          return [];
        }
        return this.file.listDir(this.file.dataDirectory, SNAPSHOTS_DIR);
      })
      .then(entries => {
        const res: Array<VersionInfo> = [];
        entries.forEach(entry => {
          if(entry.isDirectory){
            res.push({
              version: entry.name,
              isActive: entry.name === currentVersion
            });
          }
        });
        return res;
      });
  }

  // returns success of the downloading and unzipping
  downloadNewVersion(): Promise<boolean> {
    return this._fetchUpdateInfo().then(info => {
        return Promise.all([
          info,
          this._createDir(this.file.dataDirectory, SNAPSHOTS_DIR)
        ]);
      }).then(res => {
        const [info] = res;
        if(!info.url){
          return Promise.reject('update info is not available');
        }
        const filePath = `${this._snapshotsDirPath}${DOWNLOADED_FILE}`;
        return Promise.all([
          info.version,
          this.http.downloadFile(info.url, {}, {}, filePath)
        ]);
      })
      .then(res => {
        const [version, fileEntry] = res
        const destUrl = `${this._snapshotsDirPath}${version}`;
        return this.zip.unzip((fileEntry as FileEntry).nativeURL, destUrl, () => {});
      })
      .then(unzipRes => {
        if(unzipRes !== 0){
          return Promise.reject(`resource update unzip failed with result: ${unzipRes}`);
        }
        return this.file.removeFile(this._snapshotsDirPath, DOWNLOADED_FILE);
      })
      .then(() => {
        return Promise.resolve(true);
      })
      .catch(err => {
        console.log(err);
        return Promise.resolve(false);
      });
  }

  deleteDownloadedVersion(version: string): Promise<boolean> {
    return this.file.removeRecursively(this._snapshotsDirPath, version)
      .then(res => {
        return !!res.fileRemoved;
      });
  }

  switchAtVersion(version: string): Promise<void> {
    return Promise.all([
      this.getEmbeddedVersion(),
      this.getDownloadedVersions()
    ]).then(res => {
      const [embeddedVersionInfo, downloadedVersionInfos] = res;
      if(embeddedVersionInfo.version === version){
        if(embeddedVersionInfo.isActive){
          console.log(`already on embedded version ${version}`);
          return;
        }
        return this._switchAtEmbeddedVersion();
      }
      const downloadedVersionInfo = downloadedVersionInfos.find(info => {
        return info.version === version;
      });
      if(!downloadedVersionInfo){
        return Promise.reject(`version ${version} is not available`);
      }
      if(downloadedVersionInfo.isActive){
        console.log(`already on downloaded version ${version}`);
        return;
      }
      return this._switchAtDownloadedVersion(downloadedVersionInfo.version);
    });
  }

  // removes not current downloaded versions which are not equal to remote version
  deleteNotRequiredVersions(): Promise<void> {
    return this.getDownloadedVersions()
      .then(infos => {
        return Promise.all([
          infos,
          this.getRemoteVersion()
        ]);
      })
      .then(res => {
        const [downloaddVersionInfos, remoteVersion] = res;
        const versionsToDelete = downloaddVersionInfos.filter(versionInfo => {
          return !versionInfo.isActive && versionInfo.version !== remoteVersion;
        });
        versionsToDelete.forEach(async versionInfo => {
          await this.deleteDownloadedVersion(versionInfo.version);
        });
      });
  }

  _getBasePathForLocation(location:string): Promise<string>{
    const path =  `${this._snapshotsDirPath}${location}`;
    if(this.platform.is('android')){
      return this.file.resolveDirectoryUrl(path)
        .then(dirEntry => {
          return this._toAndroidNativePath(dirEntry.nativeURL);
        });
    }
    return Promise.resolve(path);
  }

  _toAndroidNativePath(nativeUrl: string): string {
    let path = nativeUrl.replace('file://', '');
    if(path[path.length - 1] === '/'){
      path = path.substr(0, path.length - 1);
    }
    return path;
  }

  _switchAtEmbeddedVersion(): void {
    // NOTE: for Android uses patched IonicWebViewEngine.java
    this.webView.setServerBasePath('');
    this.webView.persistServerBasePath();
  }

  _switchAtDownloadedVersion(version: string): Promise<void> {
    return this._getBasePathForLocation(version)
      .then(basePath => {
        this.webView.setServerBasePath(basePath);
        this.webView.persistServerBasePath();
      })
      .catch(err => {
        console.error(err);
      });
  }

  _fetchUpdateInfo(): Promise<ResourcesUpdateInfo> {
    return this.http.sendRequest(this.config.serverUrl, {
        method: 'get',
        responseType: 'json',
        timeout: this.config.timeout
      }).then(response => {
        const version = response.data[this.config.versionFieldName];
        const url = response.data[this.config.contentUrlFieldName];
        return Promise.resolve({
          version: version ? version : UNKNOWN_VERSION,
          url
        });
      }).catch(err => {
        console.log(err);
        return Promise.resolve({
          version: UNKNOWN_VERSION,
          url: null
        });
      });
  }

  get _snapshotsDirPath(): string {
    return `${this.file.dataDirectory}${SNAPSHOTS_DIR}/`;
  }

  // returns true if folder was created
  _createDir(parentDirPath: string, dirName: string): Promise<boolean> {
    return this._dirExists(parentDirPath, dirName)
      .then(exists => {
        if(exists){
          return Promise.resolve(false);
        }
        return this.file.createDir(parentDirPath, dirName, true)
          .then(() => {
            return Promise.resolve(true);
          });
        });
  }

  // always resolve in true/false
  _dirExists(parentDirPath: string, dirName: string): Promise<boolean> {
    return this.file.checkDir(parentDirPath, dirName)
    .then(exists => {
      return Promise.resolve(exists);
    })
    .catch(() => {
      return Promise.resolve(false);
    });
  }

  _fileExists(parentDirPath: string, fileName: string): Promise<boolean> {
    return this.file.checkFile(parentDirPath, fileName)
    .then(exists => {
      return Promise.resolve(exists);
    })
    .catch(() => {
      return Promise.resolve(false);
    });
  }
}
