import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { ActionSource } from '../../services/yeti-protocol/tracking';

@Component({
  selector: 'app-clinical-experts-list',
  templateUrl: './clinical-experts-list.component.html',
  styleUrls: ['./clinical-experts-list.component.scss'],
})
export class ClinicalExpertsListComponent{
  @Input() clinicalExperts: PublicProfile[];
  @Input() source: ActionSource = ActionSource.unspecified;
  @Output() openPublicUserProfile: EventEmitter<string> = new EventEmitter();

  onOpenPublicUserProfile(userId: string): void {
    this.openPublicUserProfile.emit(userId);
  }
}
