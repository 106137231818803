import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import { MIPipesModule } from 'src/app/pipes/pipes.module';

import { EventsListComponent } from './events-list/events-list.component';
import { EventsListItemComponent } from './events-list/events-list-item/events-list-item.component';
import { EventsSearchInputComponent } from './events-search-input/events-search-input.component';
import { EventsListSkeletonComponent } from './events-list/events-list-skeleton/events-list-skeleton.component';
import { MIComponentsModule } from 'src/app/components/components.module';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  declarations: [
    EventsListComponent,
    EventsListItemComponent,
    EventsSearchInputComponent,
    EventsListSkeletonComponent
  ],
  exports: [
    EventsListComponent,
    EventsListItemComponent,
    EventsSearchInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MIPipesModule,
    MIComponentsModule,
    TranslateModule,
    IconComponent
  ]
})
export class EventsModule { }
