// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --bubbles-width: 100%;
  --bubbles-height: 100%;
  --bubbles-padding-top: 0;
  --bubbles-padding-bottom: 0;
  --bubbles-padding-start: 0;
  --bubbles-padding-end: 0;
  --bubbles-image-size: 29px;
  --bubbles-image-overlap: 10px;
  --bubbles-image-border-color: var(--mi-white-color);
  --bubbles-image-border-size: 1px;
  --bubbles-circle-background-color: var(--mi-primary-color);
  --bubbles-circle-text-color: var(--mi-white-color);
  --bubbles-circle-label-color: var(--mi-color-text-lite);
}

.bubbles-container {
  width: var(--bubbles-width);
  height: var(--bubbles-height);
  padding-top: var(--bubbles-padding-top);
  padding-bottom: var(--bubbles-padding-bottom);
  padding-left: var(--bubbles-padding-start);
  padding-right: var(--bubbles-padding-end);
}
.bubbles-container.with-label {
  display: grid;
  grid-row: auto;
  grid-template-columns: min-content auto;
  -moz-column-gap: 6px;
       column-gap: 6px;
  align-items: center;
}
.bubbles-container .circles-container {
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: calc(var(--bubbles-image-size) - var(--bubbles-image-overlap));
  justify-items: start;
  align-items: flex-start;
  margin-right: var(--bubbles-image-overlap);
}
.bubbles-container .circles-container app-user-profile-image {
  --user-image-size: var(--bubbles-image-size);
  border: var(--bubbles-image-border-size) solid var(--bubbles-image-border-color);
  border-radius: 50%;
}
.bubbles-container .circles-container .total-count-container {
  width: var(--bubbles-image-size);
  height: var(--bubbles-image-size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bubbles-circle-background-color);
  border: var(--bubbles-image-border-size) solid var(--bubbles-image-border-color);
  z-index: 1;
  box-sizing: content-box;
  cursor: pointer;
}
.bubbles-container .circles-container .total-count-container .count {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  color: var(--bubbles-circle-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.bubbles-container .label-container {
  grid-column: 2;
  grid-row: 1;
  overflow: hidden;
}
.bubbles-container .label-container .label {
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  color: var(--bubbles-circle-label-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
