import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ActivitiesStateModel } from './activities.model';
import { ACTIVITIES_STATE_KEY } from '../state-constants';
import { UpdatesService } from 'src/app/services/updates.service';
import { Activities } from './activities.actions';
import { Notification, NotificationStatus, NotificationsSuccessResponse } from 'src/app/services/yeti-protocol/notifications';

const ACTIVITIES_STATE_TOKEN = new StateToken<ActivitiesStateModel>(
    ACTIVITIES_STATE_KEY
);

@State({
    name: ACTIVITIES_STATE_TOKEN,
    defaults: {
        activities: [],
        totalCount: 0,
        loading: false,
        totalNotRead: 0,
        markActivitiesAsReadLoading: false
    },
})
@Injectable()
export class ActivitiesState {
    constructor(private updatesService: UpdatesService) { }

    @Selector()
    static state(state: ActivitiesStateModel): ActivitiesStateModel {
        return state;
    }

    @Selector()
    static activities(state: ActivitiesStateModel): Array<Notification> {
        return state.activities;
    }

    @Selector()
    static totalCount(state: ActivitiesStateModel): number {
        return state.totalCount;
    }

    @Selector()
    static totalNotRead(state: ActivitiesStateModel): number {
        return state.totalNotRead;
    }

    @Selector()
    static loading(state: ActivitiesStateModel): boolean {
        return state.loading;
    }

    @Action(Activities.FetchActivities)
    async fetchActivities(
        ctx: StateContext<ActivitiesStateModel>,
        payload: Activities.FetchActivities
    ): Promise<void> {
        try {
            ctx.patchState({
                loading: true,
            });

            const updatesRes = (await this.updatesService.getUpdates(
                payload?.payloadParams?.pageIndex,
                payload?.payloadParams?.pageSize,
            )) as NotificationsSuccessResponse;

            ctx.dispatch(
                new Activities.FetchActivitiesSuccess(
                    payload?.payloadParams,
                    updatesRes
                )
            );
        } catch (err) {
            ctx.dispatch(
                new Activities.FetchActivitiesFailed()
            );

            throw err;
        }
    }

    @Action(Activities.FetchActivitiesSuccess)
    fetchActivitiesSuccess(
        ctx: StateContext<ActivitiesStateModel>,
        action: Activities.FetchActivitiesSuccess
    ): void {
        const state = ctx.getState();

        if (action.payloadParams.pageIndex === 0) {
            ctx.patchState({
                activities: action.response.result,
                totalCount: action.response.totalItemsCount,
                totalNotRead: action.response.totalNotRead || 0,
                loading: false,
            });
        } else {
            ctx.patchState({
                activities: [...state.activities, ...action.response.result],
                totalCount: action.response.totalItemsCount,
                totalNotRead: action.response.totalNotRead || 0,
                loading: false,
            });
        }
    }

    @Action(Activities.FetchActivitiesFailed)
    fetchActivitiesFailed(
        ctx: StateContext<ActivitiesStateModel>
    ): void {
        ctx.patchState({
            loading: false,
        });
    }

    @Action(Activities.InsertActivityBeforeIndex)
    insertActivityBeforeIndex(ctx: StateContext<ActivitiesStateModel>, action: Activities.InsertActivityBeforeIndex): void {
        ctx.setState(
            patch<ActivitiesStateModel>({
                activities: insertItem<Notification>(action.activity, action.index)
            })
        );

        const state = ctx.getState();

        if (action.activity.status === NotificationStatus.unread) {
            ctx.patchState({
                totalCount: state.totalCount + 1,
                totalNotRead: state.totalNotRead + 1
            });
        } else {
            ctx.patchState({
                totalCount: state.totalCount + 1,
            });
        }
    }

    @Action(Activities.RemoveActivity)
    removeActivity(ctx: StateContext<ActivitiesStateModel>, action: Activities.RemoveActivity): void {

        const state = ctx.getState();

        const itemIndex = state.activities.findIndex(item =>
            item._id === action?.activity?._id);

        if (itemIndex === -1) {
            return;
        }

        ctx.setState(
            patch<ActivitiesStateModel>({
                activities: removeItem<Notification>(itemIndex)
            })
        );

        if (action.activity.status === NotificationStatus.unread) {
            ctx.patchState({
                totalCount: state.totalCount - 1,
                totalNotRead: state.totalNotRead - 1
            });
        } else {
            ctx.patchState({
                totalCount: state.totalCount - 1,
            });
        }
    }

    @Action(Activities.RemoveActivities)
    removeActivities(ctx: StateContext<ActivitiesStateModel>, action: Activities.RemoveActivities): void {

        const state = ctx.getState();
        const activities = [...state.activities];
        const removedActivities = [];

        action.ids.forEach(_id => {

            const index = activities.findIndex(activity => activity._id === _id);

            if (index > -1) {
                removedActivities.push(activities[index]);
                activities.splice(index, 1);
            }
        });

        const removedActivitiesCount = removedActivities?.length || 0;
        const removedNotReadActivitiesCount =
            removedActivities.filter(removedActivity => removedActivity.status === NotificationStatus.unread)?.length || 0;

        ctx.setState(
            patch<ActivitiesStateModel>({
                activities: activities,
                totalCount: state.totalCount - removedActivitiesCount >= 0 ? state.totalCount - removedActivitiesCount : 0,
                totalNotRead: state.totalNotRead - removedNotReadActivitiesCount >= 0 ?
                    state.totalNotRead - removedNotReadActivitiesCount : 0
            })
        );
    }

    @Action(Activities.UpdateActivity)
    updateActivity(ctx: StateContext<ActivitiesStateModel>, action: Activities.UpdateActivity): void {
        ctx.setState(
            patch<ActivitiesStateModel>({
                activities: updateItem<Notification>(item =>
                    item._id === action?.activity?._id,
                    action.activity)
            })
        );
    }

    @Action(Activities.MarkActivitiesAsRead)
    async markActivitiesAsRead(ctx: StateContext<ActivitiesStateModel>, payload: Activities.MarkActivitiesAsRead): Promise<void> {
        try {
            ctx.patchState({
                markActivitiesAsReadLoading: true,
            });

            const markActivitiesAsReadRes = (await this.updatesService.markUpdatesAsRead(
                payload?.payloadParams?.ids,
                payload?.payloadParams?.pageIndex,
                payload?.payloadParams?.pageSize,
                payload?.payloadParams?.delete
            )) as NotificationsSuccessResponse;

            ctx.dispatch(
                new Activities.MarkActivitiesAsReadSuccess(
                    payload?.payloadParams,
                    markActivitiesAsReadRes
                )
            );
        } catch (err) {
            ctx.dispatch(
                new Activities.FetchActivitiesFailed()
            );

            throw err;
        }
    }

    @Action(Activities.MarkActivitiesAsReadSuccess)
    markActivitiesAsReadSuccess(
        ctx: StateContext<ActivitiesStateModel>,
        action: Activities.MarkActivitiesAsReadSuccess): void {

        const state = ctx.getState();

        if (action.payloadParams.pageIndex === 0) {
            ctx.patchState({
                activities: action.response.result,
                totalCount: action.response.totalItemsCount,
                totalNotRead: action.response.totalNotRead || 0,
                markActivitiesAsReadLoading: false,
            });
        } else {
            ctx.patchState({
                activities: [...state.activities, ...action.response.result],
                totalCount: action.response.totalItemsCount,
                totalNotRead: action.response.totalNotRead || 0,
                markActivitiesAsReadLoading: false,
            });
        }

        if (action.payloadParams.delete) {
            ctx.dispatch(new Activities.RemoveActivities(action.payloadParams.ids));
        } else {

            const activities = [...state.activities];
            const markedAsReadActivities = [];

            action?.payloadParams?.ids?.forEach(_id => {
                const index = activities.findIndex(activity => activity._id === _id);

                if (index > -1) {

                    markedAsReadActivities.push(activities[index]);

                    const activity = { ...activities[index] };
                    activity.status = NotificationStatus.read;
                    activities[index] = activity;
                }
            });

            ctx.patchState({
                activities: activities,
                totalNotRead: state.totalNotRead - markedAsReadActivities?.length >= 0 ?
                    state.totalNotRead - markedAsReadActivities?.length : 0
            });

        }
    }

    @Action(Activities.MarkActivitiesAsReadFailed)
    markActivitiesAsReadFailed(ctx: StateContext<ActivitiesStateModel>): void {
        ctx.patchState({
            markActivitiesAsReadLoading: false,
        });
    }
}
