import { Injectable } from '@angular/core';

import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';

export enum NativeSettings{
  PUSH_NOTIFICATIONS = 'notification_id'
}

@Injectable()
export class NativeSettingsService {

  constructor(private openNativeSettings: OpenNativeSettings) { }

  open(settings: NativeSettings): Promise<void>{
    return this.openNativeSettings.open(settings);
  }
}
