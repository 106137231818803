import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpEvent
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { LoadingService } from '../loadingService/loadingService.service';
import { TimeoutService } from '../utils/timeout.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  private timerForLoadingSpinner: number;
  private readonly WAIT_TIMEOUT = 100; // msc
  private readonly DELAY_DISPLAY = 1500; // msc <- show loading Please wait only if request takes more than 1.5s
  private dismissTries = 0;

  constructor(
    public loadingService: LoadingService,
    private timeoutService: TimeoutService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.processRequest(request))
      .pipe(
        switchMap(() => {
          return next.handle(request);
        }),
        finalize(() => {
          this.requestFinished();
        })
      )
  }

  async processRequest(request: HttpRequest<any>): Promise<void> {
    const haveToPresent = this.totalRequests === 0;
    this.totalRequests++;

    if (haveToPresent) {
      if (this.timerForLoadingSpinner) {
        this.timeoutService.clearTimeout(this.timerForLoadingSpinner);
      }
      this.timerForLoadingSpinner = this.timeoutService.setTimeout(async () => {

        if (this.isRequestWithoutSpinner(request)) {
          if (this.loadingService.spinnerIsShowed) {
            this.loadingService.dismiss();
          }
        } else {

          try {
            await this.loadingService.present();
          } catch (err) {
            console.error(err);
          }
        }
      }, this.DELAY_DISPLAY);
    }
  }

  private isRequestWithoutSpinner(request: HttpRequest<any>): boolean {
    return request.url.indexOf('search/autosuggest') > -1 ||
      (request.url.indexOf('/documents') > -1 && request.method === 'POST') ||
      (request.url.indexOf('/comment') > -1 && (request.method === 'PUT' || request.method === 'GET')) ||
      request.url.indexOf('/CommentByIdResponse.schema.json') > -1 ||
      request.url.indexOf('/UploadCommentAttachmentResponse.schema.json') > -1;
  }

  private async requestFinished(): Promise<void> {

    if (this.timerForLoadingSpinner) {
      this.timeoutService.clearTimeout(this.timerForLoadingSpinner);
      this.timerForLoadingSpinner = null;
    }

    this.totalRequests--;

    if (this.totalRequests === 0 && this.loadingService.spinnerIsShowed) {

      let dismissResult = false;

      try {
        dismissResult = await this.loadingService.dismiss();
      } catch (err) {
        console.error(err);
      }

      if (dismissResult === true) {
        this.dismissTries = 0;
        return;
      }

      if (dismissResult === false && this.dismissTries < 20) {

        this.totalRequests++;
        this.dismissTries++;
        this.loadingService.spinnerIsShowed = true;

        this.timeoutService.setTimeout(() => {
          this.requestFinished();
        }, this.WAIT_TIMEOUT);

      } else {
        this.dismissTries = 0;
      }
    }
  }
}
