import { Routes } from '@angular/router';

import {
  rootRoutes as cmfRootRoutes,
  navigationTabsRoutes as cmfNavigationTabsRoutes
} from 'src/app/contexts/cmf/cmf-context-routing';
import {
  rootRoutes as reconRootRoutes,
  navigationTabsRoutes as reconNavigationTabsRoutes
} from 'src/app/contexts/recon/recon-context-routing';
import {
  rootRoutes as spineRootRoutes,
  navigationTabsRoutes as spineNavigationTabsRoutes
} from 'src/app/contexts/spine/spine-context-routing';
import {
  rootRoutes as traumaRootRoutes,
  navigationTabsRoutes as traumaNavigationTabsRoutes
} from 'src/app/contexts/trauma/trauma-context-routing';
import {
  rootRoutes as vetRootRoutes,
  navigationTabsRoutes as vetNavigationTabsRoutes
} from 'src/app/contexts/vet/vet-context-routing';

export const contextRoutesDict = {
  cmf: {
    rootRoutes: cmfRootRoutes,
    tabRoutes: cmfNavigationTabsRoutes
  },
  recon: {
    rootRoutes: reconRootRoutes,
    tabRoutes: reconNavigationTabsRoutes
  },
  spine: {
    rootRoutes: spineRootRoutes,
    tabRoutes: spineNavigationTabsRoutes
  },
  trauma: {
    rootRoutes: traumaRootRoutes,
    tabRoutes: traumaNavigationTabsRoutes
  },
  vet: {
    rootRoutes: vetRootRoutes,
    tabRoutes: vetNavigationTabsRoutes
  }
}

export const contextRootRoutes: Routes = [
  ...cmfRootRoutes,
  ...reconRootRoutes,
  ...spineRootRoutes,
  ...traumaRootRoutes,
  ...vetRootRoutes
]
