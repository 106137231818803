import { NgModule } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import appConfig from 'src/config/config';
import { Platform } from 'src/config/config.model';
import { version } from 'src/app/version';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  let timestamp =  '';
  if(appConfig.platform === Platform.PWA || appConfig.platform === Platform.BROWSER){
    timestamp = `?version=${version}`;
  }
  return new TranslateHttpLoader(http, 'assets/i18n/', `.json${timestamp}`);
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

  ],
  exports: [TranslateModule]
})
export class AppTranslationModule {}
