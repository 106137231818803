import { ContextRequest } from './context-request';
import { ErrorResponse } from './error';
import { PaginationRequest } from './pagination-request';

export interface Notification {
  _id: string;
  age: string;
  appId: string;
  content: string;
  createdDate: string;
  header: string;
  lastModified?: string;
  status: NotificationStatus;
  url: string;
  userId: string;
  deleted: boolean;
  initiatedBy?: {
    userId: string,
    profileImageUrl: string
  }
}

export enum NotificationStatus {
  read = 'read',
  unread = 'unread'
}

export type NotificationsRequestParams = PaginationRequest & ContextRequest;

export interface NotificationsRequest {
  _schemaPath?: 'backedn/notifications';
  _schemaMethod?: 'get';
  _schemaGetParams?: NotificationsRequestParams;
}

export interface NotificationsMarkAsReadRequest {
  _schemaPath?: 'backedn/notifications';
  _schemaMethod?: 'post';
  _schemaPostParams?: NotificationsRequestParams;
  ids: Array<string>;
  delete?: boolean;
}

export type NotificationsResponse = NotificationsSuccessResponse | ErrorResponse;

export type NotificationsSuccessResponse = {
  totalItemsCount: number,
  totalNotRead?: number,
  result: Array<Notification>
};
