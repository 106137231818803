export type ConnectionStatus = 'na' | 'none' | 'pending' | 'connected' | 'rejected' | 'disconnected' | 'blocked' | 'deleted';

export enum ConnectionType {
  CONNECTION = 'connection',
  EVENT = 'event'
}

export interface ConnectionInfo {
  id: string;
  status: ConnectionStatus;
  isInitiator: boolean
}
