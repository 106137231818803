import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

// models
import {
  UserFollowersResponse,
  UserFollowersSuccessResponse
} from '../../services/yeti-protocol/user-following';
import { ShortPublicProfile } from 'src/app/services/yeti-protocol/public-profile';

// services
import { UserFollowingService } from '../../services/followingService/user-following.service';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

@Component({
  selector: 'app-user-followers-dialog',
  templateUrl: './user-followers-dialog.component.html',
  styleUrls: ['./user-followers-dialog.component.scss'],
})
export class UserFollowersDialogComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() currentUserId: string;

  userFollowers: Array<ShortPublicProfile> = new Array<ShortPublicProfile>();
  private readonly start = 0;
  private readonly count = 20;
  private lastFetchLength: number;

  constructor(
    private appNavController: AppNavController,
    private modalController: ModalController,
    private userFollowingService: UserFollowingService,
  ) {}

  ngOnInit(): void {
    this.getUserFollowers(this.start, false);
  }

  async goToUserProfile(follower: ShortPublicProfile): Promise<void> {
    this.modalController.dismiss();
    this.appNavController.openPublicUserProfile(follower.userId);
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  loadMoreFollowers(_event: Event): void {
    if(this.lastFetchLength < this.count) {
      this.disableInfiniteScroll(true);
      return;
    }
    const start = Math.floor(this.userFollowers.length / this.count);
    this.getUserFollowers(start, true);
  }

  private getUserFollowers(start: number, hideInfiniteScroll: boolean): void {
    this.userFollowingService.getUserFollowers(this.currentUserId, start, this.count)
    .then((response: UserFollowersResponse) => {
      if (response) {
        const list = (response as UserFollowersSuccessResponse).result;
        this.lastFetchLength = list.length;
        this.userFollowers = [...this.userFollowers, ...list];
        if(hideInfiniteScroll) {
          this.infiniteScroll.complete();
        }
      }
    });
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }
}
