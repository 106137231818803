// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ao-membership-container {
  background-color: var(--mi-white-color);
  padding: 18px 16px 25px 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ao-membership-container .title {
  color: var(--mi-secondary-color-2);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-large);
}
.ao-membership-container .all-ao-memberships {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.ao-membership-container .all-ao-memberships .ao-membership .ao-membership-title {
  color: var(--mi-black-color);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
}
.ao-membership-container .all-ao-memberships .ao-membership .ao-membership-details {
  color: var(--mi-black-color);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
  display: flex;
  gap: 3px;
}
.ao-membership-container .all-ao-memberships .ao-membership .ao-membership-details .expired-membership {
  color: var(--mi-red-color-4);
}
.ao-membership-container .all-ao-memberships .ao-membership .ao-membership-details .active-membership {
  color: var(--mi-green-color-6);
}
.ao-membership-container .description {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-small);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
