import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminStateService {
  private allowArticlePublish: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get currentPublishState(): Observable<boolean> {
    return this.allowArticlePublish.asObservable();
  }

  changePublishState(state: boolean): void {
    this.allowArticlePublish.next(state);
  }
}
