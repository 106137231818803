// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --clinical-case-shared-with-slider-padding-top: 18px;
  --clinical-case-shared-with-slider-padding-bottom: 28px;
  --clinical-case-shared-with-slider-padding-start: 15px;
  --clinical-case-shared-with-slider-padding-end: 0;
  --clinical-case-shared-with-slider-background: var(--mi-purple-color-3);
  --clinical-case-shared-with-slider-border-radius: 10px;
}

.clinical-case-shared-with-slider-container {
  width: 100%;
  padding-top: var(--clinical-case-shared-with-slider-padding-top);
  padding-bottom: var(--clinical-case-shared-with-slider-padding-bottom);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 10px;
}
.clinical-case-shared-with-slider-container .title-and-actions-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.clinical-case-shared-with-slider-container .title-and-actions-container .title-container {
  grid-row: 1;
  grid-column: 1;
}
.clinical-case-shared-with-slider-container .title-and-actions-container .title-container .wrapper {
  position: relative;
  height: 18.5px;
}
.clinical-case-shared-with-slider-container .title-and-actions-container .title-container .wrapper .title {
  position: absolute;
  top: 0;
  left: 0;
  height: 18.5px;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-purple-color-5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clinical-case-shared-with-slider-container .title-and-actions-container .actions-container {
  grid-row: 1;
  grid-column: 2;
}
.clinical-case-shared-with-slider-container .title-and-actions-container .actions-container .see-all-button {
  background: none;
  outline: none;
  cursor: pointer;
}
.clinical-case-shared-with-slider-container .title-and-actions-container .actions-container .see-all-button .button-text {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-primary-color);
  margin: 0;
  white-space: nowrap;
}
.clinical-case-shared-with-slider-container .slider-container {
  grid-row: 2;
  grid-column: 1;
  background-color: var(--clinical-case-shared-with-slider-background);
  border-radius: var(--clinical-case-shared-with-slider-border-radius);
  padding: 15px;
}
.clinical-case-shared-with-slider-container .slider-container swiper ::ng-deep .swiper-button-prev,
.clinical-case-shared-with-slider-container .slider-container swiper ::ng-deep .swiper-button-next {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-size: 100%;
  opacity: 0.6;
}
.clinical-case-shared-with-slider-container .slider-container swiper ::ng-deep .swiper-button-prev.swiper-button-disabled,
.clinical-case-shared-with-slider-container .slider-container swiper ::ng-deep .swiper-button-next.swiper-button-disabled {
  display: none;
}
.clinical-case-shared-with-slider-container .slider-container swiper ::ng-deep .swiper-button-next:after,
.clinical-case-shared-with-slider-container .slider-container swiper ::ng-deep .swiper-button-prev:after {
  content: none;
}
.clinical-case-shared-with-slider-container .slider-container swiper ::ng-deep .swiper-button-next {
  background-image: url("/svg/chevron-forward-circle.svg");
}
.clinical-case-shared-with-slider-container .slider-container swiper ::ng-deep .swiper-button-prev {
  background-image: url("/svg/chevron-back-circle.svg");
}
.clinical-case-shared-with-slider-container .slider-container swiper .add-more-card {
  width: 70px;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
}
.clinical-case-shared-with-slider-container .slider-container swiper .add-more-card .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clinical-case-shared-with-slider-container .slider-container swiper .add-more-card .icon-container .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--mi-blue-color-4);
  cursor: pointer;
}
.clinical-case-shared-with-slider-container .slider-container swiper .add-more-card .icon-container .wrapper app-icon {
  color: var(--mi-primary-color);
}
.clinical-case-shared-with-slider-container .slider-container swiper .add-more-card .text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clinical-case-shared-with-slider-container .slider-container swiper .add-more-card .text-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 26px;
}
.clinical-case-shared-with-slider-container .slider-container swiper .add-more-card .text-container app-multiline-text-with-ellipsis ::ng-deep {
  color: var(--mi-grey-color-16);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
