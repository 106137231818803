import { IgnoreRecommendedContactSuccessResponse, PeopleListSuccessResponse } from 'src/app/services/yeti-protocol/clinical-expert';
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';

export namespace PeopleYouMayKnow {
  export interface FetchPeopleYouMayKnowPayload {
    pageIndex?: number;
    pageSize?: number;
  }

  export class FetchPeopleYouMayKnow {
    static readonly type = '[People You May Know] Fetch people you may know';
    constructor(public payloadParams: FetchPeopleYouMayKnowPayload) { }
  }

  export class FetchPeopleYouMayKnowSuccess {
    static readonly type = '[People You May Know] Fetch people you may know success';
    constructor(
      public payloadParams: FetchPeopleYouMayKnowPayload,
      public response: PeopleListSuccessResponse
    ) { }
  }

  export class FetchPeopleYouMayKnowFailed {
    static readonly type = '[People You May Know] Fetch people you may know failed';
  }

  export class InsertPersonBeforeIndex {
    static readonly type = '[People You May Know] Insert person before index';
    constructor(
      public person: PublicProfile,
      public index: number
    ) { }
  }

  export class RemovePerson {
    static readonly type = '[People You May Know] Remove person';
    constructor(
      public person: PublicProfile
    ) { }
  }

  export class UpdatePerson {
    static readonly type = '[People You May Know] Update person';
    constructor(
      public person: PublicProfile
    ) { }
  }

  export class IgnorePerson {
    static readonly type = '[People You May Know] Ignore person';
    constructor(
      public person: PublicProfile,
      public source?: string
    ) { }
  }

  export class IgnorePersonSuccess {
    static readonly type = '[People You May Know] Ignore person success';
    constructor(
      public person: PublicProfile,
      public response: IgnoreRecommendedContactSuccessResponse
    ) { }
  }

  export class IgnorePersonFailed {
    static readonly type = '[People You May Know] Ignore person failed';
  }

  export class DisableConnectButtonForUser {
    static readonly type = '[People You May Know] disable connect button for user';
    constructor(
      public userId: string
    ) { }
  }
}
