import { Component, Inject, OnInit } from '@angular/core';
import { IonNav, IonRefresher } from '@ionic/angular';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import {
  SurgeryReferenceDialogPage,
  SurgeryReferenceUIServiceInterface,
  SURGERY_REFERENCE_UI_SERVICE
} from 'src/app/services/surgery-reference/surgery-reference-ui.service.interface';
import { SurgeryReferenceService } from 'src/app/services/surgery-reference/surgery-reference.service';
import { SURGERY_REFERENCE_SERVICE } from 'src/app/services/surgery-reference/surgery-reference.service.interface';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { Fracture } from 'src/app/services/yeti-protocol/surgery-reference-schema';
import {
  AddFractureResponse,
  FracturesResponse,
  FracturesSuccessResponse
} from 'src/app/services/yeti-protocol/surgery-reference';
import { BaseSurgeryReferencePage } from '../surgery-reference-common/base-surgery-reference-page';
import { SurgeryReferenceDialogData } from '../surgery-reference-dialog/surgery-reference-dialog.component';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-fracture-select',
  templateUrl: './fracture-select.page.html',
  styleUrls: ['./fracture-select.page.scss'],
})
export class FractureSelectPage extends BaseSurgeryReferencePage implements OnInit {

  surgeryReferenceDialogData: SurgeryReferenceDialogData;
  fractures: Array<Fracture> = [];
  loading: boolean;
  totalFracturesCount: number;
  count = 10;
  fractureLimit = 5;

  readonly visibilityRootKey = 'fracture-select';
  private addingFracture: boolean;

  constructor(
    nav: IonNav,
    @Inject(SURGERY_REFERENCE_UI_SERVICE) surgeryReferenceUIService: SurgeryReferenceUIServiceInterface,
    @Inject(SURGERY_REFERENCE_SERVICE) private surgeryReferenceService: SurgeryReferenceService,
    private translationService: AppTranslationService,
    private dialogsUIService: DialogsUIService,
    private clinicalCaseService: ClinicalCaseService,
    private toast: ToastService,
    private authService: AuthService
  ) {
    super(nav, surgeryReferenceUIService);
    this.surgeryReferenceDialogData = this.nav.rootParams as SurgeryReferenceDialogData;
  }

  ngOnInit(): void {
    this.getFractures(0);
  }

  async getFractures(start: number = 0, refresh: boolean = false): Promise<void> {

    if (!this.surgeryReferenceDialogData?.clinicalCase?._id) {
      return Promise.resolve();
    }

    if (!this.fractures) {
      this.fractures = [];
    }

    if (refresh) {
      this.fractures = [];
    }

    if (start === 0) {
      this.loading = true;
    }

    try {
      const fracturesResponse = await this._getFractures(start) as FracturesSuccessResponse;
      this.fractures = [...this.fractures, ...fracturesResponse.result];
      this.totalFracturesCount = fracturesResponse?.totalItemsCount;
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.loading = false;
    }
  }

  _getFractures(start: number = 0): Promise<FracturesResponse> {
    return this.surgeryReferenceService.getFractures(this.surgeryReferenceDialogData?.clinicalCase?._id, start, this.count);
  }

  async refreshFractures(refresher: IonRefresher): Promise<void> {
    await this.getFractures(0, true);
    refresher.complete();
  }

  async loadMoreFractures(verticalListLoadMoreData: VerticalListLoadMoreData): Promise<void> {
    await this.getFractures(verticalListLoadMoreData.page);
    verticalListLoadMoreData.infiniteScroll.complete();
  }

  onBackClicked(): void {
    this.surgeryReferenceUIService.closeDialog();
  }

  async addToCase(fracture: Fracture): Promise<void> {

    if (this.addingFracture) {
      return;
    }

    if (this.surgeryReferenceDialogData?.selectedFractures?.length >= this.fractureLimit) {
      this.showFractureLimitDialog();
      return;
    }

    this.addingFracture = true;

    if (!this.surgeryReferenceDialogData.selectedFractures) {
      this.surgeryReferenceDialogData.selectedFractures = [];
    }

    const caseId = this.surgeryReferenceDialogData?.clinicalCase?._id;

    try {
      await this.surgeryReferenceService.addFracture(caseId, fracture?._id)
        .then((response: AddFractureResponse) => {
          this.clinicalCaseService.emitClinicalCaseUpdated(response?.result);
        });
      this.surgeryReferenceDialogData.selectedFractures.push({ fracture: fracture });
      this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.TreatmentSelect);
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.addingFracture = false;
    }
  }

  openFractureLink(fracture: Fracture): void {
    this.authService.openAoLink(fracture?.url);
  }

  getChipText(fracture: Fracture): string {

    if (fracture?.memberOnly) {
      return this.translationService.instant('app.common.ao-member-only');
    }

    return '';
  }

  get filteredFractures(): Array<Fracture> {

    if (!this.surgeryReferenceDialogData?.selectedFractures?.length) {
      return this.fractures;
    }

    const filteredFractures = this.fractures.filter(fracture => {

      let fractureSelected = false;

      this.surgeryReferenceDialogData.selectedFractures.forEach(selectedFracture => {
        if (fracture._id === selectedFracture.fracture._id) {
          fractureSelected = true;
        }
      });

      if (!fractureSelected) {
        return fracture;
      }
    });

    return filteredFractures;
  }

  get showEmptyState(): boolean {
    return !this.loading && !this.filteredFractures?.length;
  }

  showFractureLimitDialog(): void {

    const title = this.translationService.instant('app.pages.fractureSelect.limit-reached-title');
    const text = this.translationService.instant('app.pages.fractureSelect.limit-reached-text');
    const button = this.translationService.instant('app.common.got-it');

    this.dialogsUIService.presentAlert(title, text, [button]);
  }
}
