import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription, filter } from 'rxjs';
import { SearchFieldComponent } from 'src/app/components/search-field/search-field.component';
import { DedicatedSearch } from 'src/app/services/dedicated-search/dedicated-search.interface';

@Component({
  selector: 'app-dedicated-search-dialog',
  templateUrl: './dedicated-search-dialog.component.html',
  styleUrls: ['./dedicated-search-dialog.component.scss'],
})
export class DedicatedSearchDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() searchContentComponent: DedicatedSearch;
  @Input() dialogTitle: string;
  @Input() searchFieldPlaceholder: string;
  @Input() searchTerm: string;

  @ViewChild('searchContentComponentContainer', { static: false, read: ViewContainerRef }) searchContentComponentContainer: any;
  @ViewChild('searchField') searchField: SearchFieldComponent;

  searchContentComponentInstance: DedicatedSearch;

  private routerEventsSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.routerEventsSubscription = this.router.events.pipe(
      filter((event: Event | RouterEvent | any) => event instanceof NavigationStart)
    ).subscribe(() => {
      this.onRouteChange();
    });
  }

  ngAfterViewInit(): void {

    if (this.searchField && this.searchField.setEmptySearchState) {
      this.searchField.setEmptySearchState(null);
    }

    let component: any;

    if (this.searchContentComponentContainer && this.searchContentComponentContainer.createComponent && this.searchContentComponent) {
      component = this.searchContentComponentContainer?.createComponent(this.searchContentComponent);
    }

    if (component) {
      this.searchContentComponentInstance = component?.instance;
    }

    setTimeout(() => {
      if (this.searchTerm) {
        this.search(this.searchTerm);
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }

  onBack(): void {
    this.modalController.dismiss();
  }

  search(searchTerm: string): void {
    this.searchContentComponentInstance.search(searchTerm);
  }

  onRouteChange(): void {
    this.onBack();
  }

}
