import { Inject, Injectable } from '@angular/core';
import { ContentParentType, CreateContentStrategy, CreatePostAction, PostData, PostWithCaseData, UpdatePostData } from '../create-content';
import { Participant } from 'src/app/services/yeti-protocol/event';
import { ParentType } from '../../yeti-protocol/chatter-api-schema';
import { Post, PostType } from '../../yeti-protocol/chatter-api';
import { NavControllerService } from '../../nav-controller.service';
import { ClinicalCase } from '../../yeti-protocol/clinical-case';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CONTEXT_SERVICE, ContextService } from '../../context/context.model';
import { AppTranslationService } from '../../app-translation.service';
import { CreateGeneralContentService } from './create-general-content-service.service';
import { ChatterApiService } from '../../chatter-api.service';
import { CreateGroupContentStrategy } from '../group/create-group-content-strategy';
import { CreateEventContentStrategy } from '../event/create-event-content-strategy';
import { CreateContactsFollowersContentStrategy } from '../contacts-followers/create-contacts-followers-content-strategy';
import { ToastMode, ToastService } from '../../toast.service';
import { PostsDataService } from '../../posts/posts-data.service';
import { GroupMember } from '../../groups/group.model';

@Injectable({
  providedIn: 'any',
})
export class CreateGeneralContentStrategy extends CreateContentStrategy {

  facultyOnly: boolean;

  constructor(
    private createGeneralContentService: CreateGeneralContentService,
    private createGroupContentStrategy: CreateGroupContentStrategy,
    private createEventContentStrategy: CreateEventContentStrategy,
    private createContactsFollowersContentStrategy: CreateContactsFollowersContentStrategy,
    private navController: NavControllerService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private appTranslationService: AppTranslationService,
    private alertController: AlertController,
    private chatterApiService: ChatterApiService,
    private toast: ToastService,
    private postsDataService: PostsDataService,
    private modalController: ModalController
  ) {
    super();

    this.contentParentType = ContentParentType.GENERAL;
  }

  openCreatePost(
    action?: CreatePostAction,
    postContentText?: string,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean
  ): void {
    this.createGeneralContentService.openCreateGeneralPostPage(
      this.parentsData,
      action,
      postContentText,
      clinicalCase,
      replaceUrl
    );
  }

  openCreateCase(
    /* eslint-disable */
    caseId?: string,
    /* eslint-enable */
    replaceUrl?: boolean): void {
    this.createGeneralContentService.openCreateGeneralCasePage(this.parentsData, replaceUrl, this.shareCaseInPost);
  }

  openAddImages(): void {
    this.createGeneralContentService.openCreateGeneralPostPage(
      this.parentsData,
      CreatePostAction.ADD_IMAGES
    );
  }

  openAttachDocument(): void {
    this.createGeneralContentService.openCreateGeneralPostPage(
      this.parentsData,
      CreatePostAction.ATTACH_DOCUMENT
    );
  }

  openAddVideo(): void {
    this.createGeneralContentService.openCreateGeneralPostPage(
      this.parentsData,
      CreatePostAction.ADD_VIDEO
    );
  }

  get isPostTypeAttachment(): boolean {
    return this.post?.type === PostType.attachment;
  }

  get isPostTypeLink(): boolean {
    return this.post?.type === PostType.link;
  }

  get isPostTypeText(): boolean {
    return this.post?.type === PostType.text;
  }

  createPost(postData: PostData): Promise<Post> {

    const createPostPromises: Array<Promise<any>> = [];

    if (!this.parentsData?.length) {
      return Promise.reject('No recipients selected');
    }

    this.parentsData?.forEach(parentsData => {
      switch (parentsData.parentsType) {
        case ParentType.GROUP:
          this.createGroupContentStrategy.parentsData = [parentsData];
          createPostPromises.push(this.createGroupContentStrategy.createPost(postData, true));
          break;
        case ParentType.EVENT:
          this.createEventContentStrategy.parentsData = [parentsData];
          createPostPromises.push(this.createEventContentStrategy.createPost(postData, true));
          break;
        case ParentType.CHAT:
        case ParentType.USER:
          this.createContactsFollowersContentStrategy.parentsData = [parentsData];
          createPostPromises.push(this.createContactsFollowersContentStrategy.createPost(postData, true));
          break;
      }
    });

    return Promise.all(createPostPromises).then(async createPostsRes => {
      if (createPostsRes) {

        this.toast.show('app.components.CreatePost.post-successfull');

        createPostsRes.forEach(post => {
          this.postsDataService.triggerNewPostAddedAction(post);
        })
      }

      try {
        await this.modalController?.dismiss({ customClose: true });

        const modalShowing = await this.modalController.getTop();

        if (modalShowing && modalShowing?.classList?.contains('share-dialog')) {
          await this.modalController.dismiss();
        }

      } catch (err) {
        console.log(err);
      } finally {
        if (this.parentsData?.length === 1 && this.parentsData?.[0]?.parents?.length <= 1) {
          this.post = createPostsRes[0];
          this.navigateToPost();
        } else {
          this.navController.back();
        }
      }

      this.post = null;
      return Promise.resolve(null);
    }).catch(err => {
      this.toast.showWithMessage(err.error.error.message.errfor.message, 'app.common.error-default', ToastMode.ERROR);
      return Promise.reject(err);
    });
  }

  createPostWithACase(postWithCaseData: PostWithCaseData): Promise<Post> {
    const createPostPromises: Array<Promise<any>> = [];

    if (!this.parentsData?.length) {
      return Promise.reject('No recipients selected');
    }

    this.parentsData?.forEach(parentsData => {
      switch (parentsData.parentsType) {
        case ParentType.GROUP:
          this.createGroupContentStrategy.parentsData = [parentsData];
          createPostPromises.push(this.createGroupContentStrategy.createPostWithACase(postWithCaseData, true));
          break;
        case ParentType.EVENT:
          this.createEventContentStrategy.parentsData = [parentsData];
          createPostPromises.push(this.createEventContentStrategy.createPostWithACase(postWithCaseData, true));
          break;
        case ParentType.CHAT:
        case ParentType.USER:
          this.createContactsFollowersContentStrategy.parentsData = [parentsData];
          createPostPromises.push(this.createContactsFollowersContentStrategy.createPostWithACase(postWithCaseData, true));
          break;
      }
    });

    return Promise.all(createPostPromises).then(async createPostsRes => {
      if (createPostsRes) {

        this.toast.show('app.components.CreatePost.post-successfull');

        createPostsRes.forEach(post => {
          this.postsDataService.triggerNewPostAddedAction(post);
        })
      }

      try {
        await this.modalController?.dismiss({ customClose: true });

        const modalShowing = await this.modalController.getTop();

        if (modalShowing && modalShowing?.classList?.contains('share-dialog')) {
          await this.modalController.dismiss();
        }
      } catch (err) {
        console.log(err);
      } finally {
        if (this.parentsData?.length === 1 && this.parentsData?.[0]?.parents?.length <= 1) {
          this.post = createPostsRes[0];
          this.navigateToPost();
        } else {
          this.navController.back();
        }
      }

      this.post = null;
      return Promise.resolve(null);
    }).catch(err => {
      this.toast.showWithMessage(err.error.error.message.errfor.message, 'app.common.error-default', ToastMode.ERROR);
      return Promise.reject(err);
    });
  }

  updatePost(
    /* eslint-disable */
    updatePostData: UpdatePostData
    /* eslint-enable */
  ): Promise<Post> {
    // not used, updating posts is done with a more specific strategy (groups, events, contacts / followers)
    return Promise.resolve(null);
  }

  getParentMembers(
    searchTerm: string,
    start?: number,
    count?: number,
    excludedUserIds?: Array<string>
  ): Promise<Array<Participant | GroupMember>> {

    if (this.parentsData?.length === 1 &&
      this.parentsData?.[0]?.parents?.length === 1) {
      switch (this.parentsData?.[0]?.parentsType) {
        case ParentType.GROUP:
          this.createGroupContentStrategy.parentsData = this.parentsData;
          return this.createGroupContentStrategy.getParentMembers(
            searchTerm,
            start,
            count,
            excludedUserIds
          );
        case ParentType.EVENT:
          this.createEventContentStrategy.parentsData = this.parentsData;
          return this.createEventContentStrategy.getParentMembers(
            searchTerm,
            start,
            count,
            excludedUserIds
          );
        default:
          return Promise.resolve([]);
      }
    } else {
      return Promise.resolve([]);
    }
  }

  async promptUserAboutNotSavedState(): Promise<any> {
    const title = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-title');
    const text = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-text');
    const confirmButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-confirm-button-text');
    const rejectButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-reject-button-text');

    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [{
        text: confirmButtonText,
        role: 'confirm'
      },
      {
        text: rejectButtonText,
        role: 'cancel',
        cssClass: 'secondary'
      }]
    });

    alert.present();
    return alert.onDidDismiss();
  }

  determinePostType(url: string, attachments: Array<string>): string {
    if (attachments?.length) {
      return PostType.attachment;
    } else if (url) {
      return PostType.link;
    } else {
      return PostType.text;
    }
  }

  get parentIds(): Array<string> {
    // not used, "parentIds" are needed in more specific strategy (groups, events, contacts / followers)
    return [];
  }

  get parent(): any {
    // not used, "parent" is needed in more specific strategy (groups, events, contacts / followers)
    return null;
  }

  get allowAnonymousPost(): boolean {

    if (!this.parentsData?.length) {
      return true; // it is not really important what is returned here because post won't be created anyways
    }

    let allow = true;

    this.parentsData?.forEach(parentsData => {
      switch (parentsData.parentsType) {
        case ParentType.CHAT:
        case ParentType.USER:
          allow = false;
      }
    });

    return allow;
  }

  private navigateToPost(): void {

    const postType = this.post.type === PostType.case ? 'case' : 'post';
    let postUrl;

    switch (this.parentsData?.[0]?.parentsType) {
      case ParentType.GROUP:
        postUrl = `${this.contextService.currentContext.homePath}/group/${postType}/${this.post?._id}`;
        break;
      case ParentType.EVENT:
        postUrl = `${this.contextService.currentContext.homePath}/event/${postType}/${this.post?._id}`;
        break;
      case ParentType.CHAT:
      case ParentType.USER:
        if (postType === 'post') {
          postUrl = `${this.contextService.currentContext.homePath}/post/${this.post?._id}`;
        } else {
          postUrl = `${this.contextService.currentContext.homePath}/shared/case/${this.post?._id}`;
        }
        break;
    }

    if (window.location.href.indexOf(postUrl) === -1) {
      this.router.navigateByUrl(postUrl, { replaceUrl: true });
    }
  }
}
