// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-card {
  --background: var(--mi-white-color);
  margin-top: 0 !important;
}
.content-card h3 {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.content-card p {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  width: 90%;
}

.blue-card {
  --background: var(--mi-white-color);
  box-shadow: none;
  border-radius: 12px;
}
.blue-card .become-ao-member-button {
  --background: var(--mi-light-blue-1-color);
  --border-radius: 6px;
  --box-shadow: none;
  color: var(--mi-grey-color-13);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  margin: 15px;
  display: flex;
  justify-content: space-between;
  text-transform: none;
}
.blue-card .become-ao-member-button span {
  flex-grow: 1;
  text-align: left;
}
.blue-card .become-ao-member-button app-icon {
  --icon-size: 12px;
}
.blue-card .blue-card-content .blue-card-text-container {
  color: var(--mi-black-color);
  padding-top: 20px;
  padding-left: 20px;
}
.blue-card .blue-card-content .blue-card-text-container h3 {
  font-weight: var(--font-weight-medium);
  font-size: var(--mi-font-size-default);
  color: var(--mi-black-color);
  margin-top: 0px;
  margin-bottom: 10px;
}
.blue-card .blue-card-content .blue-card-text-container p {
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}
.blue-card .blue-card-content .blue-card-text-container .ao-icon-container {
  margin: 0 15px 0 10px;
  height: 33px;
  width: 33px;
  background-color: var(--mi-secondary-color-1);
  border-radius: 4px;
  float: right;
}
.blue-card .blue-card-content .blue-card-text-container .ao-icon-container app-icon {
  --icon-size: 33px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
