import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonInfiniteScroll, ModalController} from '@ionic/angular';
import {ShortPublicProfile} from '../../services/yeti-protocol/public-profile';
import {AppNavController} from '../../services/app-nav-controller.service';
import {ChatterApiService} from '../../services/chatter-api.service';
import {GetLikesSuccessResponse} from '../../services/yeti-protocol/chatter-api';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';
import {ToastMode, ToastService} from '../../services/toast.service';

@Component({
  selector: 'app-see-all-applauds-dialog',
  templateUrl: './see-all-applauds-dialog.component.html',
  styleUrls: ['./see-all-applauds-dialog.component.scss'],
})
export class SeeAllApplaudsDialogComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() objectId: string;
  @Input() objectType: 'post' | 'case' | 'comment' | 'orcid' | 'article';

  profilesApplauded: Array<ShortPublicProfile> = new Array<ShortPublicProfile>();
  loading = false;
  private readonly start = 0;
  private readonly count = 20;
  private lastFetchLength: number;

  constructor(
    private appNavController: AppNavController,
    private modalController: ModalController,
    private chatterApiService: ChatterApiService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private toast: ToastService
  ) {
  }

  ngOnInit(): void {
    this.getProfilesThatApplauded(this.start, false);
  }

  async goToUserProfile(profile: ShortPublicProfile): Promise<void> {
    this.modalController.dismiss();
    this.appNavController.openPublicUserProfile(profile.userId);
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  loadMoreProfilesThatApplauded(_event: Event): void {
    if (this.lastFetchLength < this.count) {
      this.disableInfiniteScroll(true);
      return;
    }
    const start = Math.floor(this.profilesApplauded.length / this.count);
    this.getProfilesThatApplauded(start, true);
  }

  pullDownRefresh(): void {
    this.profilesApplauded = [];
    this.getProfilesThatApplauded(0, true);
  }

  private getProfilesThatApplauded(start: number, hideInfiniteScroll: boolean): void {
    this.loading = true;
    this.chatterApiService.getLikesBasedOnObjectType(this.objectId, this.objectType, start, this.count)
      .then((response) => {
        if (response) {
          const list = (response as GetLikesSuccessResponse).result;
          this.lastFetchLength = list.length;
          this.profilesApplauded = [...this.profilesApplauded, ...list];
          if (hideInfiniteScroll) {
            this.infiniteScroll?.complete();
          }
        }
      }).catch((err) => {
        console.log('find', err)
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    }).finally(() => this.loading = false);
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }
}
