// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --list-content-padding: 20px;
}

.share-recipients-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  row-gap: 20px;
}
.share-recipients-dialog-container .header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  background-color: var(--mi-white-color);
}
.share-recipients-dialog-container .header app-top-bar {
  --top-bar-padding-top: 30px;
  --top-bar-padding-bottom: 12px;
  --top-bar-padding-start: 23px;
  --top-bar-padding-end: 23px;
}
.share-recipients-dialog-container .header app-top-bar .confirm-participants-selected-button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-recipients-dialog-container .header app-top-bar .confirm-participants-selected-button span {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-primary-color);
}
.share-recipients-dialog-container .header app-top-bar .confirm-participants-selected-button.disabled span {
  color: var(--mi-grey-color-11);
}
.share-recipients-dialog-container .content {
  grid-row: 2;
  grid-column: 1;
}
.share-recipients-dialog-container .content .contacts-list {
  width: 100%;
  height: 100%;
}
.share-recipients-dialog-container .content .contacts-list ion-content {
  --padding-top: 0;
  --padding-bottom: var(--list-content-padding);
  --padding-start: var(--list-content-padding);
  --padding-end: var(--list-content-padding);
}
.share-recipients-dialog-container .content .contacts-list ion-content .wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 20px;
}

.empty-list-container {
  margin-top: 60px;
}

app-skeleton-loader {
  padding: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
