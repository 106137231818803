// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --dialog-for-newly-enrolled-users-image-width: 137px;
  --dialog-for-newly-enrolled-users-image-height: 118px;
  --dialog-for-newly-enrolled-users-padding-top: 24px;
  --dialog-for-newly-enrolled-users-padding-bottom: 24px;
  --dialog-for-newly-enrolled-users-padding-start: 15px;
  --dialog-for-newly-enrolled-users-padding-end: 15px;
}

.dialog-for-newly-enrolled-users-container {
  width: 100%;
  height: 100%;
  padding-top: var(--dialog-for-newly-enrolled-users-padding-top);
  padding-bottom: var(--dialog-for-newly-enrolled-users-padding-bottom);
  padding-left: var(--dialog-for-newly-enrolled-users-padding-start);
  padding-right: var(--dialog-for-newly-enrolled-users-padding-end);
  overflow: auto;
}
.dialog-for-newly-enrolled-users-container .image-container {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: var(--dialog-for-newly-enrolled-users-image-height);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
.dialog-for-newly-enrolled-users-container .image-container ion-img {
  width: var(--dialog-for-newly-enrolled-users-image-width);
  height: var(--dialog-for-newly-enrolled-users-image-height);
}
.dialog-for-newly-enrolled-users-container .content-container {
  text-align: center;
}
.dialog-for-newly-enrolled-users-container .content-container .title-container {
  margin-bottom: 12px;
}
.dialog-for-newly-enrolled-users-container .content-container .title-container .title {
  font-weight: bold;
  font-style: normal;
  font-size: var(--font-size-large);
  color: var(--mi-black-color);
  margin: 0;
}
.dialog-for-newly-enrolled-users-container .content-container .message-container {
  margin-bottom: 20px;
}
.dialog-for-newly-enrolled-users-container .content-container .message-container .message {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
}
.dialog-for-newly-enrolled-users-container .content-container .tip-container {
  margin-bottom: 27px;
}
.dialog-for-newly-enrolled-users-container .content-container .tip-container .tip {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-secondary-color-2);
}
.dialog-for-newly-enrolled-users-container .actions-container {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  gap: 25px;
  text-align: center;
}
.dialog-for-newly-enrolled-users-container .actions-container .lets-go {
  height: 47px;
}
.dialog-for-newly-enrolled-users-container .actions-container .skip {
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
