// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-searches-list-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 30px;
  justify-content: center;
  align-items: start;
}
.recent-searches-list-container.hidden {
  display: none;
}
.recent-searches-list-container ion-list {
  padding: 0;
}
.recent-searches-list-container ion-list ion-header ion-label {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-bold);
  color: var(--mi-secondary-color-2);
}
.recent-searches-list-container ion-list ion-item {
  --min-height: 16px;
  padding-top: 13px;
  cursor: pointer;
}
.recent-searches-list-container ion-list ion-item .item-text {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-black-color);
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
