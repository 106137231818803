export enum CaseClassificationActions {
  TAG_SELECTED = 'tag-selected',
  TAG_DESELECTED = 'tag-deselected'
}

export interface CaseClassificationAction {
  action: CaseClassificationActions,
  tagsGroupId: string,
  tagValue: string;
}
