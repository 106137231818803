import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmDialogTextField, ConfirmDialogAction } from 'src/app/services/dialogs/dialogs.ui.interface';

// models
/* eslint-disable */
/* eslint-enable */
interface ActionClickedData {
  actionKey: string;
  confirmInput?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})

export class ConfirmDialogComponent {

  @Input() image: string;
  @Input() title: ConfirmDialogTextField;
  @Input() message: ConfirmDialogTextField;
  @Input() displayConfirmInput = false;
  @Input() actions: Array<ConfirmDialogAction>;

  inputText: string;

  constructor(
    private modalController: ModalController
  ) { }

  actionClicked(actionKey: string): void {
    const data: ActionClickedData = {
      actionKey,
    }
    if(this.displayConfirmInput) {
      data.confirmInput = this.inputText;
    }
    this.modalController.dismiss(data);
  }

}
