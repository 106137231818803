// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orcid-button-container .link-your-orcid-btn {
  width: 100%;
  border-radius: 10px;
}
.orcid-button-container .link-your-orcid-btn .btn-wrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto min-content;
  -moz-column-gap: 6px;
       column-gap: 6px;
  align-items: center;
  padding: 20px 15px;
  background-color: var(--mi-background-color);
  border-radius: 10px;
}
.orcid-button-container .link-your-orcid-btn .btn-wrapper .start-icon-container {
  grid-row: 1;
  grid-column: 1;
}
.orcid-button-container .link-your-orcid-btn .btn-wrapper .start-icon-container .start-icon {
  --icon-size: 24px;
}
.orcid-button-container .link-your-orcid-btn .btn-wrapper .btn-text-container {
  grid-row: 1;
  grid-column: 2;
  position: relative;
  width: 100%;
  height: 14px;
  overflow: hidden;
}
.orcid-button-container .link-your-orcid-btn .btn-wrapper .btn-text-container .btn-text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.orcid-button-container .link-your-orcid-btn .btn-wrapper .btn-text-container .btn-text-wrapper .btn-text {
  text-align: left;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.orcid-button-container .link-your-orcid-btn .btn-wrapper .end-icon-container {
  grid-row: 1;
  grid-column: 3;
}
.orcid-button-container .link-your-orcid-btn .btn-wrapper .end-icon-container .end-icon {
  --icon-size: 20px;
  color: var(--mi-black-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
