import { Injectable, Optional } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { NgcCookieConsentConfig, NgcCookieConsentService } from 'ngx-cookieconsent';
import appConfig from 'src/config/config';
import { AppTranslationService } from '../app-translation.service';

const KEY_ALLOWED = 'miAllowed';

@Injectable()
export class CookieConsentService {
  static appConfig = appConfig;

  constructor(
    private cookieConsentService: NgcCookieConsentService,
    private appTranslationService: AppTranslationService,
    @Optional() public cookieService: CookieService
  ) { }

  static get cookieServices(): Array<any>{
    if(CookieConsentService.appConfig.cookieConsentDomain){
      return [
        NgcCookieConsentService,
        CookieConsentService
      ];
    }
    return [];
  }

  static get moduleConfig(): NgcCookieConsentConfig {
    if(CookieConsentService.appConfig.cookieConsentDomain){
      return {
        enabled: false,
        cookie: {
          domain: CookieConsentService.appConfig.cookieConsentDomain
        },
        position: 'bottom',
        theme: 'edgeless',
        type: 'info',
        palette: appConfig.cookieConsentPalette
      };
    }
    return null;
  }

  init(): Promise<void> {
    if(CookieConsentService.appConfig.cookieConsentDomain){
      if(this.cookieService.check(KEY_ALLOWED)){
        return Promise.resolve();
      }
      return this.appTranslationService.get([
        'app.CookieConsentService.message',
        'app.CookieConsentService.dismiss',
        'app.CookieConsentService.deny',
        'app.CookieConsentService.link',
        'app.CookieConsentService.href',
        'app.CookieConsentService.policy'
      ]).then(translations => {
        const config = this.cookieConsentService.getConfig();
        config.content = {
          message: translations['app.CookieConsentService.message'],
          dismiss: translations['app.CookieConsentService.dismiss'],
          deny: translations['app.CookieConsentService.deny'],
          link: translations['app.CookieConsentService.link'],
          href: translations['app.CookieConsentService.href'],
          policy: translations['app.CookieConsentService.policy']
        };
        config.enabled = true;
        this.cookieConsentService.destroy(); // remove previous cookie bar (with default messages)
        this.cookieConsentService.popupClose$.subscribe(() => {
          this.cookieService.set(KEY_ALLOWED, 'true', {
            path: '/',
            expires: CookieConsentService.appConfig.cookieConsentExpiresDays,
            secure: true,
            sameSite: 'None'
          });
        });
        this.cookieConsentService.init(config);
      });
    }
    return Promise.resolve();
  }
}
