import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
})
export class EmojiPickerComponent {

  @Input() additionalToggleButtonClasses: string[] = [];
  @Input() additionalEmojiPickerClasses: string[] = [];

  @Output() showEmojiPickerEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addEmojiEmitter: EventEmitter<{ emoji: any, $event: Event }> = new EventEmitter<{ emoji: any, $event: Event }>();
  @Output() keepInputFieldFocusedEmitter: EventEmitter<Event> = new EventEmitter<Event>();

  showEmojiPicker = false;

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(ev: MouseEvent): void {
    if (this.showEmojiPicker) {
      this.keepInputFieldFocused(ev);
    }

    const clickX = ev.clientX;
    const clickY = ev.clientY;

    const emojisContainerBoundingRect = document.querySelector('.emoji-mart')?.getBoundingClientRect();
    const emojisContainerLeft = emojisContainerBoundingRect?.left;
    const emojisContainerRight = emojisContainerBoundingRect?.right;
    const emojisContainerTop = emojisContainerBoundingRect?.top;
    const emojisContainerBottom = emojisContainerBoundingRect?.bottom;

    if (clickX < emojisContainerLeft ||
      clickX > emojisContainerRight ||
      clickY < emojisContainerTop ||
      clickY > emojisContainerBottom) {
      this.showEmojiPicker = false;
      this.showEmojiPickerEmitter.emit(this.showEmojiPicker);
    }
  }

  keepInputFieldFocused(event: Event): void {
    this.keepInputFieldFocusedEmitter.emit(event);
  }

  toggleEmojiPicker(): void {
    this.showEmojiPicker = !this.showEmojiPicker;
    this.showEmojiPickerEmitter.emit(this.showEmojiPicker);
  }

  addEmoji(event: { emoji: any, $event: Event }): void {
    this.keepInputFieldFocused(event?.$event);
    this.addEmojiEmitter.emit(event);
  }

}
