import { ConnectionType } from './connection';
import { ErrorResponse } from './error';
import { ConversationActor } from './public-profile';

export enum ConversationType {
  TEXT = 'text',
  IMAGE = 'image',
  ATTACHMENT = 'attachment',
  LINK = 'link',
  REUEST = 'request',
  DELETED = 'deleted',
  CONNECTION = 'connection',
  CASE = 'case'
}

export interface Conversation {
  _id: string;
  createdDate: string;
  actor: ConversationActor;
  message?: string;
  url?: string;
  type?: ConversationType;
  connectionType: ConnectionType;
  eventTitle?: string; // only for events
  age?: string;
  read?: boolean;
  deleted?: boolean;
  isSender?: boolean;
}

export interface ConversationSuccessResponse {
  _schemaPath?: '/chatter/ionic/messenger/connection/activities/:conversationId';
  _schemaMethod?: 'get';
  result: Conversation;
}

export type ConversationResponse = ConversationSuccessResponse | ErrorResponse;

export interface ConversationsSuccessResponse {
  _schemaPath?: '/chatter/ionic/messenger/connection/activities';
  _schemaMethod?: 'get';
  result: Array<Conversation>;
  totalItemsCount: number;
  totalNotRead: number;
  totalOpenConnectionRequests: number;
}

export type ConversationsResponse = ConversationsSuccessResponse | ErrorResponse;

