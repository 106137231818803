// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.case-readiness-indicator-container {
  width: 100%;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 10px;
  border-radius: 8px;
  background-color: var(--mi-light-purple-1-color);
}
.case-readiness-indicator-container .title-container {
  grid-row: 1;
  grid-column: 1;
}
.case-readiness-indicator-container .title-container .title {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  color: var(--mi-grey-color-16);
  margin: 0;
}
.case-readiness-indicator-container .readiness-indicator {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-rows: min-content;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  -moz-column-gap: 6px;
       column-gap: 6px;
}
.case-readiness-indicator-container .readiness-indicator .indicator {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content;
  row-gap: 5px;
  align-items: center;
}
.case-readiness-indicator-container .readiness-indicator .indicator .bar {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 3px;
  border-radius: 1px;
  background-color: var(--mi-color-text-lite);
}
.case-readiness-indicator-container .readiness-indicator .indicator .text-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content;
  -moz-column-gap: 6px;
       column-gap: 6px;
  align-items: center;
}
.case-readiness-indicator-container .readiness-indicator .indicator .text-container app-icon {
  --icon-size: 7px;
  color: var(--mi-color-text-lite);
}
.case-readiness-indicator-container .readiness-indicator .indicator .text-container p {
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
}
.case-readiness-indicator-container .readiness-indicator .indicator.state-2 .bar {
  background-color: var(--mi-orange-color-2);
}
.case-readiness-indicator-container .readiness-indicator .indicator.state-2 .text-container app-icon {
  color: var(--mi-orange-color-2);
}
.case-readiness-indicator-container .readiness-indicator .indicator.state-2 .text-container p {
  color: var(--mi-orange-color-2);
}
.case-readiness-indicator-container .readiness-indicator .indicator.state-3 .bar {
  background-color: var(--mi-green-color-3);
}
.case-readiness-indicator-container .readiness-indicator .indicator.state-3 .text-container app-icon {
  color: var(--mi-green-color-3);
}
.case-readiness-indicator-container .readiness-indicator .indicator.state-3 .text-container p {
  color: var(--mi-green-color-3);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
