import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ContentParentType, CreateContentStrategy, CreatePostAction, PostData, UpdatePostData } from '../create-content';
import { CreateGroupContentService } from './create-group-content-service.service';
import { ParentType } from '../../yeti-protocol/chatter-api-schema';
import {
  CreatePostRequest,
  CreatePostSuccessResponse,
  Post,
  PostType,
  UpdatePostRequest,
  UpdatePostSuccessResponse
} from '../../yeti-protocol/chatter-api';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { Group, GroupMember } from '../../groups/group.model';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from '../../utils/ui-utils.service.interface';
import { GroupsService } from '../../groups/groups.service';
import { ToastMode, ToastService } from '../../toast.service';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CONTEXT_SERVICE, ContextService } from '../../context/context.model';
import { NavControllerService } from '../../nav-controller.service';
import { AppTranslationService } from '../../app-translation.service';
import { ChatterApiService } from '../../chatter-api.service';
import { ClinicalCase } from '../../yeti-protocol/clinical-case';
import { PostsDataService } from '../../posts/posts-data.service';

@Injectable({
  providedIn: 'any',
})
export class CreateGroupContentStrategy extends CreateContentStrategy implements OnDestroy {

  private userSubscription: Subscription;

  constructor(
    private createGroupContentService: CreateGroupContentService,
    private authService: AuthService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private groupsService: GroupsService,
    private toast: ToastService,
    private modalController: ModalController,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private navController: NavControllerService,
    private appTranslationService: AppTranslationService,
    private alertController: AlertController,
    private chatterApiService: ChatterApiService,
    private postsDataService: PostsDataService
  ) {
    super();

    this.contentParentType = ContentParentType.GROUP;

    this.userSubscription = this.authService?.userProfileAsObservable?.subscribe(userProfile => {
      this.user = userProfile;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  openCreatePost(
    action?: CreatePostAction,
    postContentText?: string,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean): void {
    this.createGroupContentService.openCreateGroupPostPage(
      this.parent?._id,
      this.post?._id,
      action,
      postContentText,
      clinicalCase,
      replaceUrl);
  }

  openCreateCase(caseId?: string, replaceUrl?: boolean): void {
    this.createGroupContentService.openCreateGroupCasePage(this.parent?._id, caseId, replaceUrl, this.shareCaseInPost);
  }

  openAddImages(): void {
    this.createGroupContentService.openCreateGroupPostPage(this.parent?._id, null, CreatePostAction.ADD_IMAGES);
  }

  openAttachDocument(): void {
    this.createGroupContentService.openCreateGroupPostPage(
      this.parent?._id,
      null,
      CreatePostAction.ATTACH_DOCUMENT
    );
  }

  openAddVideo(): void {
    this.createGroupContentService.openCreateGroupPostPage(
      this.parent?._id,
      null,
      CreatePostAction.ADD_VIDEO
    );
  }

  get isPostTypeAttachment(): boolean {
    return this.post?.type === PostType.attachment;
  }

  get isPostTypeLink(): boolean {
    return this.post?.type === PostType.link;
  }

  get isPostTypeText(): boolean {
    return this.post?.type === PostType.text;
  }

  createPost(postData: PostData, preventNavigation?: boolean): Promise<Post> {
    const mutablePostData = {...postData};
    mutablePostData.parentIds = this.parentIds;
    mutablePostData.parentType = ParentType.GROUP;

    if (!mutablePostData?.parentIds?.length) {
      console.error('parentId is mandatory');
      return Promise.reject('parentId is mandatory');
    }

    if (!mutablePostData.parentType) {
      console.error('parentType is mandatory');
      return Promise.reject('parentType is mandatory');
    }

    if (this.checkAoOnlyParentError()) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {

      this.chatterApiService.createPost(mutablePostData as CreatePostRequest)
        .then(async (createGroupPostRes: CreatePostSuccessResponse) => {
          if (createGroupPostRes) {
            this.toast.show('app.components.CreatePost.post-successfull');
          }

          this.post = createGroupPostRes?.result;
          this.postsDataService.triggerNewPostAddedAction(this.post);

          try {

            await this.modalController?.dismiss({ customClose: true });

            if (!preventNavigation) {
              this.navigateToPost();
            }
          } catch (err) {
            console.log(err);
          }

          resolve(this.post);
        }).catch(err => {
          this.toast.showWithMessage(err.error.error.message.errfor.message, 'app.common.error-default', ToastMode.ERROR);
          reject(err);
        });
    });
  }

  createPostWithACase(postWithCaseData: PostData, preventNavigation?: boolean): Promise<Post> {
    const mutablePostWithCaseData = {...postWithCaseData};
    mutablePostWithCaseData.parentIds = this.parentIds;
    mutablePostWithCaseData.parentType = ParentType.GROUP;

    if (!mutablePostWithCaseData?.parentIds?.length) {
      console.error('parentIds is mandatory');
      return Promise.reject('parentIds is mandatory');
    }

    if (!mutablePostWithCaseData.parentType) {
      console.error('parentType is mandatory');
      return Promise.reject('parentType is mandatory');
    }

    if (this.checkAoOnlyParentError()) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      this.chatterApiService.createPost(mutablePostWithCaseData as CreatePostRequest)
        .then(async (createGroupPostRes: CreatePostSuccessResponse) => {
          if (createGroupPostRes) {
            this.toast.show('app.components.CreatePost.post-successfull');
          }

          this.post = createGroupPostRes?.result;

          this.postsDataService.triggerNewPostAddedAction(this.post);
          this.modalController?.dismiss({ customClose: true });

          if (!preventNavigation) {
            this.navigateToPost();
          }

          resolve(this.post);
        }).catch(err => {
          this.toast.showWithMessage(err.error.error.message.errfor.message, 'app.common.error-default', ToastMode.ERROR);
          reject(err);
        });
    });
  }

  updatePost(updatePostData: UpdatePostData): Promise<Post> {

    updatePostData.parentId = this.post.parentId;
    updatePostData.parentType = ParentType.GROUP;

    if (!updatePostData?.parentId?.length) {
      console.error('parentId is mandatory');
      return Promise.reject('parentIds is mandatory');
    }

    if (!updatePostData.parentType) {
      console.error('parentType is mandatory');
      return Promise.reject('parentType is mandatory');
    }

    return new Promise((resolve, reject) => {
      this.chatterApiService.updatePost(this.post?._id, updatePostData as UpdatePostRequest)
        .then(async (updatePostRes: UpdatePostSuccessResponse) => {

          this.post = updatePostRes?.result;
          this.postsDataService.triggerPostEditedAction(this.post);
          resolve(this.post);
        }).catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  determinePostType(url: string, attachments: Array<string>): string {
    return this.groupsService.determineGroupPostType(url, attachments);
  }

  getParentMembers(searchTerm?: string,
    start?: number,
    count?: number,
    excludedUserIds?: Array<string>): Promise<Array<GroupMember>> {

    if (!this.parent?._id) {
      return Promise.resolve([]);
    }

    let usersPromise;

    if (searchTerm === '') {
      usersPromise = this.groupsService.getGroupMembers(this.parent?._id, start, count, excludedUserIds);
    } else {
      usersPromise = this.groupsService.searchForGroupParticipant(this.parent?._id, searchTerm, start, count, excludedUserIds);
    }

    return new Promise((resolve, reject) => {
      usersPromise.then(res => {
        resolve(res.result || []);
      }).catch(err => {
        reject(err);
      })
    })
  }

  async promptUserAboutNotSavedState(): Promise<any> {
    const title = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-title');
    const text = await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-text');
    const confirmButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-confirm-button-text');
    const rejectButtonText =
      await this.appTranslationService.get('app.components.CreatePost.unsaved-changes-warning-reject-button-text');

    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [{
        text: confirmButtonText,
        role: 'confirm'
      },
      {
        text: rejectButtonText,
        role: 'cancel',
        cssClass: 'secondary'
      }]
    });

    alert.present();
    return alert.onDidDismiss();
  }

  get parentIds(): Array<string> {

    const index = this.parentsData?.findIndex(data => data?.parentsType === ParentType.GROUP);

    if (index > -1) {
      const parentsIds = this.parentsData[index].parents?.map(group => {
        return group?._id;
      })?.filter(id => id !== null && id !== undefined) || [];

      return parentsIds;
    }

    return [];
  }

  get parent(): Group {
    const index = this.parentsData?.findIndex(data => data?.parentsType === ParentType.GROUP);

    if (index > -1) {
      if (this.parentsData[index]?.parents?.length === 1) {
        return this.parentsData[index].parents?.[0] as Group;
      } else { // if there is multiple parents return null
        return null;
      }
    }

    return null;
  }

  private checkAoOnlyParentError(): boolean {
    if ((this.parent as Group)?.AOMembersOnly && !this.user?.isAOMember) {
      this.uiUtilsService.showErrorDialogOnCreatePostInAoOnlyGroup();
      return true;
    }

    return false;
  }

  get allowAnonymousPost(): boolean {
    return true;
  }


  private navigateToGroup(): void {

    this.navController.back();

    setTimeout(() => {
      const groupUrl = `${this.contextService.currentContext.homePath}/group/${this.parent?._id}`;

      if (window.location.href.indexOf(groupUrl) === -1) {
        this.router.navigateByUrl(groupUrl, { replaceUrl: true });
      }
    }, 500);
  }

  private navigateToPost(): void {
    const postType = (this.post as Post).type === PostType.case ? 'case' : 'post';
    const postUrl = `${this.contextService.currentContext.homePath}/group/${postType}/${this.post?._id}`;

    if (window.location.href.indexOf(postUrl) === -1) {
      this.router.navigateByUrl(postUrl, { replaceUrl: true });
    }
  }
}
