import { Component, Input } from '@angular/core';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { BaseButtonComponent } from '../base-button/base-button.component';
import { isMobilePlatform } from 'src/app/services/utils/utils';
import appConfig from 'src/config/config';

// services
import { ToastMode, ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent extends BaseButtonComponent<Event> {
  @Input() textToCopy: string;
  config = appConfig;

  constructor(
    private clipboard: Clipboard,
    private toast: ToastService
  ){
    super();
  }

  onClicked(): void {
    if(this.textToCopy) {

      if(isMobilePlatform(this.config.platform)) {
        this.clipboard.copy(this.textToCopy);
      } else {
        navigator.clipboard.writeText(this.textToCopy);
      }
      this.toast.show('app.common.copy-to-clipboard', null, ToastMode.SUCCESS);
    }
  }

}
