// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --feature-case-button-text-color: var(--mi-grey-color-13);
  --feature-case-button-icon-color: var(--mi-grey-color-13);
}

.feature-case-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.feature-case-button-container.disabled {
  opacity: 0.5;
}
.feature-case-button-container .feature-case-button {
  background: none;
  outline: none;
  padding: 0;
}
.feature-case-button-container .feature-case-button .button-content-container.iconOnly {
  display: flex;
  justify-content: center;
  align-items: center;
}
.feature-case-button-container .feature-case-button .button-content-container .icon {
  font-size: 22px;
  color: var(--feature-case-button-icon-color);
}
.feature-case-button-container .feature-case-button .button-content-container .button-text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  color: var(--feature-case-button-text-color);
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
