// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.separation-line {
  border-top: 1px solid var(--mi-grey-background);
  width: 100%;
}

.title {
  color: var(--mi-secondary-color-2);
  letter-spacing: 0;
  line-height: 24px;
  font-weight: var(--font-weight-medium);
  padding: 0 40px !important;
}

.icon-size-24 {
  height: 24px;
  width: 24px;
}

.toolbar {
  --background: var(--mi-white-color);
  height: 65px;
  display: flex;
}
.toolbar .back-button {
  background-color: var(--mi-white-color);
  margin-left: 10px;
}

.search-bar-container {
  width: 80%;
  margin: auto;
}
.search-bar-container .search-bar {
  --background: var(--mi-inputs-color);
  --box-shadow: none;
}
.search-bar-container .ao-search-button {
  position: absolute;
  left: 78%;
  height: 30px;
  --color: var(--mi-hover-color);
  --background: white;
  font-size: var(--font-size-small);
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .search-bar-container .ao-search-button {
    left: 73% !important;
  }
}

.grey-line {
  height: 15px;
  width: 100%;
  margin-top: 10px;
  background-color: var(--mi-grey-background);
}

ion-content .list-wrapper .ao-users-list {
  text-align: center;
}
ion-content .list-wrapper .ao-users-list .start-search-text {
  color: var(--mi-color-text-lite);
  margin-top: 50px;
}
ion-content .list-wrapper .ao-users-list .info-list-header {
  display: flex;
  justify-content: space-between;
  text-align: start;
}
ion-content .list-wrapper .ao-users-list .info-list-header .main-text {
  font-weight: var(--font-weight-medium);
  font-size: var(--mi-font-size-small);
  color: var(--mi-secondary-color-1);
  margin-left: 10px;
}
ion-content .list-wrapper .ao-users-list .user-item {
  padding-left: 10px;
}
ion-content .list-wrapper .ao-users-list .user-item .user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
}
ion-content .list-wrapper .ao-users-list .user-item .info-container {
  display: flex;
  flex-direction: column;
}
ion-content .list-wrapper .ao-users-list .user-item .info-container .user-fullname {
  color: var(--ion-color-light-contrast);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}
ion-content .list-wrapper .ao-users-list .user-item .info-container .user-organization,
ion-content .list-wrapper .ao-users-list .user-item .info-container .user-country {
  float: left;
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
  font-weight: var(--font-weight-regular);
}
ion-content .list-wrapper .ao-users-list .user-item .invite-button {
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
  font-size: var(--mi-font-size-smaller);
  font-weight: var(--font-weight-semi-bold);
  --border-radius: 4px;
  width: 80px;
  height: 30px;
  text-transform: none !important;
}
ion-content .list-wrapper .info-card {
  margin: 0 0 8px 0;
  border-radius: 0;
}
ion-content .list-wrapper .info-card .info-card-header {
  display: flex;
  justify-content: space-between;
}
ion-content .list-wrapper .info-card .info-card-header .main-text {
  font-weight: var(--font-weight-medium);
  font-size: var(--mi-font-size-small);
  color: var(--mi-secondary-color-1);
  margin-left: 10px;
}
ion-content .list-wrapper .info-card .ao-users-card-content {
  padding-top: unset;
  padding-bottom: unset;
}
ion-content .list-wrapper .info-card .user-additional-info {
  display: flex;
  margin: 5px 0;
  justify-content: space-between;
}
ion-content .list-wrapper .info-card .user-additional-info .action-sheet-trigger-button {
  justify-self: end;
  outline: none;
  border: none;
  background: none;
  font-size: 25px;
  color: var(--mi-color-text-lite);
}
ion-content .list-wrapper .info-card .user-additional-info .additional-info-item {
  font-size: var(--mi-font-size-smaller);
  background-color: var(--ion-color-secondary-light-blue);
  color: var(--ion-color-secondary-bright-blue);
  border-radius: 12px;
  padding: 2px 7px;
  margin: 5px 5px 5px 0;
}
ion-content .list-wrapper .info-card .user-additional-info .additional-info-item .user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
}
ion-content .list-wrapper .info-card .user-additional-info .additional-info-item .user-fullname {
  color: var(--ion-color-light-contrast);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
}
ion-content .list-wrapper .info-card .user-additional-info .additional-info-item .user-country {
  float: left;
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
  font-weight: var(--font-weight-regular);
}
ion-content .list-wrapper .info-card .user-additional-info .display-flex {
  display: flex;
}
ion-content .list-wrapper .info-card .invite-button {
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
  font-size: var(--mi-font-size-small);
  font-weight: var(--font-weight-semi-bold);
  --border-radius: 4px;
  width: 80px;
  height: 30px;
}

app-top-bar::ng-deep ion-header::ng-deep ion-toolbar::ng-deep {
  --border-width: 0 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
