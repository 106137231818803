// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recommendation-item {
  margin-bottom: 20px;
}
.recommendation-item .recommendation-item-image {
  --event-image-size: 68px;
  --event-image-border-radius: 5px;
}
.recommendation-item .item-label {
  margin-left: 10px;
  height: 68px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.recommendation-item .show-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.recommendation-item .recommendation-item-name {
  line-height: 16px;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.recommendation-item .recommendation-item-members {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
}
.recommendation-item .recommendation-item-type {
  position: absolute;
  bottom: 0px;
  color: var(--mi-primary-color);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  background: var(--mi-background-color-journls);
  width: 68px;
  text-align: center;
  margin: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

ion-item {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
