import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

// models
import { GetArticleRecommendsResponse, GetArticleRecommendsSuccessResponse } from 'src/app/services/yeti-protocol/article';
import { ShortPublicProfile } from 'src/app/services/yeti-protocol/public-profile';

// services
import { ArticleService } from 'src/app/services/article/article.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';

@Component({
  selector: 'app-article-recommends-list',
  templateUrl: './article-recommends-list.component.html',
  styleUrls: ['./article-recommends-list.component.scss'],
})
export class ArticleRecommendsListComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() articleId: string;

  usersThatRecommended: Array<ShortPublicProfile> = new Array<ShortPublicProfile>();
  private readonly start = 0;
  private readonly count = 15;
  private lastFetchLength: number;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private router: Router,
    private articleService: ArticleService,
    private modalController: ModalController
  ) { }

  ngOnInit(): void {
    this.getArticleRecommends(this.start, false);
  }

  async goToUserProfile(profile: ShortPublicProfile): Promise<void> {
    this.modalController.dismiss();
    await this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/public/profile/${profile.userId}`);
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  loadMoreUserProfiles(_event: Event): void {
    if(this.lastFetchLength < this.count) {
      this.disableInfiniteScroll(true);
      return;
    }
    const start = Math.floor(this.usersThatRecommended.length / this.count);
    this.getArticleRecommends(start, true);
  }

  private getArticleRecommends(start: number, hideInfiniteScroll: boolean): void {
    this.articleService.getArticleRecommends(this.articleId, start, this.count)
      .then((response: GetArticleRecommendsResponse) => {
        if(response) {
          const list = (response as GetArticleRecommendsSuccessResponse).result;
          if(list && list.length > 0) {
            this.lastFetchLength = list.length;
            this.usersThatRecommended = [...this.usersThatRecommended, ...list];
          }
          if(hideInfiniteScroll) {
            this.infiniteScroll.complete();
          }
        }
      });
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }
}
