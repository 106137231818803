import { Component, Inject, OnInit } from '@angular/core';
import { IonNav, IonRefresher } from '@ionic/angular';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import {
  SurgeryReferenceDialogPage,
  SurgeryReferenceUIServiceInterface,
  SURGERY_REFERENCE_UI_SERVICE
} from 'src/app/services/surgery-reference/surgery-reference-ui.service.interface';
import { SurgeryReferenceService } from 'src/app/services/surgery-reference/surgery-reference.service';
import { SURGERY_REFERENCE_SERVICE } from 'src/app/services/surgery-reference/surgery-reference.service.interface';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { ClinicalCase } from 'src/app/services/yeti-protocol/clinical-case';
import {
  Approach,
  ContentType,
  Fracture,
  FractureAndTreatment,
  Preparation,
  Treatment
} from 'src/app/services/yeti-protocol/surgery-reference-schema';
import {
  AddTreatmentResponse,
  DeleteFractureResponse,
  DeleteFractureSuccessResponse,
  TreatmentsResponse,
  TreatmentsSuccessResponse
} from 'src/app/services/yeti-protocol/surgery-reference';
import { BaseSurgeryReferencePage } from '../surgery-reference-common/base-surgery-reference-page';
import { SurgeryReferenceDialogData } from '../surgery-reference-dialog/surgery-reference-dialog.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-treatment-select',
  templateUrl: './treatment-select.page.html',
  styleUrls: ['./treatment-select.page.scss'],
})
export class TreatmentSelectPage extends BaseSurgeryReferencePage implements OnInit {

  surgeryReferenceDialogData: SurgeryReferenceDialogData;
  treatments: Array<Treatment> = [];
  fracture: Fracture;
  loading: boolean;
  totalTreatmentsCount: number;
  count = 10;

  readonly visibilityRootKey = 'treatment-select';
  private addingTreatment: boolean;
  private removingFracture: boolean;
  private removingModule: boolean;

  constructor(
    nav: IonNav,
    @Inject(SURGERY_REFERENCE_UI_SERVICE) surgeryReferenceUIService: SurgeryReferenceUIServiceInterface,
    @Inject(SURGERY_REFERENCE_SERVICE) private surgeryReferenceService: SurgeryReferenceService,
    private translationService: AppTranslationService,
    private clinicalCaseService: ClinicalCaseService,
    private toast: ToastService,
    private authService: AuthService
  ) {
    super(nav, surgeryReferenceUIService);
    this.surgeryReferenceDialogData = this.nav.rootParams as SurgeryReferenceDialogData;
    this.fracture = this.selectableFracture?.fracture;
  }

  ngOnInit(): void {
    this.getTreatments(0);
  }

  get selectableFracture(): FractureAndTreatment {
    if (this.surgeryReferenceDialogData && this.surgeryReferenceDialogData.selectedFractures.length) {
      const lastItemIndex = this.surgeryReferenceDialogData.selectedFractures.length - 1;
      const selectableFracture = this.surgeryReferenceDialogData.selectedFractures[lastItemIndex];
      return selectableFracture;
    }

    return null;
  }

  async getTreatments(start: number = 0, refresh: boolean = false): Promise<void> {

    if (!this.fracture?._id) {
      return Promise.resolve();
    }

    if (!this.treatments) {
      this.treatments = [];
    }

    if (refresh) {
      this.treatments = [];
    }

    if (start === 0) {
      this.loading = true;
    }

    try {
      const treatmentsResponse = await this._getTreatments(start) as TreatmentsSuccessResponse;
      this.treatments = [...this.treatments, ...treatmentsResponse.result];
      this.totalTreatmentsCount = treatmentsResponse?.totalItemsCount;
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.loading = false;
    }
  }

  _getTreatments(start: number = 0): Promise<TreatmentsResponse> {
    return this.surgeryReferenceService.getTreatments(this.fracture?._id, start, this.count);
  }

  async refreshTreatments(refresher: IonRefresher): Promise<void> {
    await this.getTreatments(0, true);
    refresher.complete();
  }

  async loadMoreTreatments(verticalListLoadMoreData: VerticalListLoadMoreData): Promise<void> {
    await this.getTreatments(verticalListLoadMoreData.page);
    verticalListLoadMoreData.infiniteScroll.complete();
  }

  async onBackClicked(): Promise<void> {

    if (this.removingFracture) {
      return;
    }

    if (!this.selectableFracture) {
      this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.FractureSelect);
      return;
    }

    this.removingFracture = true;

    const caseId = this.surgeryReferenceDialogData?.clinicalCase?._id;

    try {
      await this.surgeryReferenceService.deleteFracture(caseId, this.selectableFracture?.fracture?._id)
        .then((response: DeleteFractureResponse) => {
          this.clinicalCaseService.emitClinicalCaseUpdated((response as DeleteFractureSuccessResponse)?.result);
        });
      this.surgeryReferenceDialogData?.selectedFractures?.pop();
      this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.FractureSelect);
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.removingFracture = false;
    }
  }

  async addToCase(treatment: Treatment): Promise<void> {

    if (this.addingTreatment) {
      return;
    }

    if (!this.selectableFracture) {
      return;
    }

    this.addingTreatment = true;

    if (!this.surgeryReferenceDialogData.selectedFractures) {
      this.surgeryReferenceDialogData.selectedFractures = [];
    }

    const caseId = this.surgeryReferenceDialogData?.clinicalCase?._id;

    try {
      await this.surgeryReferenceService.addTreatment(caseId, this.selectableFracture?.fracture?._id, treatment?._id)
        .then((response: AddTreatmentResponse) => {
          this.clinicalCaseService.emitClinicalCaseUpdated(response?.result);
        });
      this.selectableFracture.treatment = treatment;
      this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.Review);
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.addingTreatment = false;
    }

  }

  openTreatmentLink(treatment: Treatment): void {
    this.authService.openAoLink(treatment?.url);
  }

  getChipText(treatment: Treatment): string {

    if (treatment?.memberOnly) {
      return this.translationService.instant('app.common.ao-member-only');
    }

    return '';
  }

  skip(): void {
    this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.Review);
  }

  async removeModule(item: Fracture | Treatment | Approach | Preparation | FractureAndTreatment): Promise<void> {

    if (this.removingModule) {
      return;
    }

    this.removingModule = true;

    this.surgeryReferenceUIService.removeModule(item, this.surgeryReferenceDialogData?.clinicalCase?._id).then(() => {

      if ((item as any)?.fracture && (item as any)?.treatment) { // Treatment but also remove fracture
        this._removeTreatment(item as FractureAndTreatment);
      } else {
        switch ((item as any).contentType) {
          case ContentType.DIAGNOSIS: // Fracture
            this._removeFracture(item as Fracture);
            break;
          case ContentType.APPROACH:
            this.surgeryReferenceDialogData.approach = null;
            (this.surgeryReferenceDialogData.clinicalCase as ClinicalCase).approach = null;
            break;
          case ContentType.PREPARATION:
            this.surgeryReferenceDialogData.preparation = null;
            (this.surgeryReferenceDialogData.clinicalCase as ClinicalCase).preparation = null;
            break;
        }
      }
    }).catch(err => {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    }).finally(() => {
      this.removingModule = false;
    });
  }

  _removeTreatment(item: FractureAndTreatment): void {
    if (this.surgeryReferenceDialogData && this.surgeryReferenceDialogData?.selectedFractures?.length) {

      if (this.selectableFracture?.fracture?._id === (item as FractureAndTreatment)?.fracture?._id &&
        this.selectableFracture?.treatment?._id === (item as FractureAndTreatment)?.treatment?._id
      ) {
        this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.FractureSelect);
      }

      const treatmentIndex = this.surgeryReferenceDialogData?.selectedFractures.findIndex((selectedFractures: FractureAndTreatment) =>
        selectedFractures?.fracture?._id === (item as FractureAndTreatment)?.fracture?._id &&
        selectedFractures?.treatment?._id === (item as FractureAndTreatment)?.treatment?._id);

      if (treatmentIndex > -1) {
        this.surgeryReferenceDialogData?.selectedFractures.splice(treatmentIndex, 1);
      }
    }
  }

  _removeFracture(item: Fracture): void {
    if (this.selectableFracture?.fracture?._id === (item as Fracture)?._id) {
      this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.FractureSelect);
    }

    if (this.surgeryReferenceDialogData && this.surgeryReferenceDialogData?.selectedFractures?.length) {
      const fractureIndex = this.surgeryReferenceDialogData?.selectedFractures
        .findIndex((fractureAndTreatment: FractureAndTreatment) =>
          fractureAndTreatment?.fracture?._id === (item as Fracture)?._id);

      if (fractureIndex > -1) {
        this.surgeryReferenceDialogData?.selectedFractures.splice(fractureIndex, 1);
      }
    }
  }

  get showEmptyState(): boolean {
    return !this.loading && !this.treatments?.length;
  }
}
