import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isNumber } from 'lodash';

// services
import { AuthService } from './auth/auth.service';
import { SchemaValidatorService } from './schema-validator.service';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';

// models
import {
  NotificationsResponse,
  NotificationsRequestParams,
  NotificationsMarkAsReadRequest,
  NotificationsSuccessResponse
} from '../services/yeti-protocol/notifications';
import { toAuthRequestParams } from './auth/logic/auth-logic.utils';

import appConfig from 'src/config/config';

export interface UpdatesServiceConfig {
  serverUrl: string;
}

export const UPDATES_PER_PAGE = 20;

@Injectable({
  providedIn: 'root'
})
export class UpdatesService {

  config: UpdatesServiceConfig = {
    serverUrl: `${appConfig.serverUrlIonic}notifications`
  }

  private readonly start = 0;
  private readonly count = UPDATES_PER_PAGE;
  private totalUnreadUpdatesCountSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService
  ) { }

  get totalUnreadUpdatesCount(): Observable<number> {
    return this.totalUnreadUpdatesCountSubject.asObservable();
  }

  async getUpdates(start: number = this.start, count: number = this.count): Promise<NotificationsResponse> {

    let currentContext = '';

    try {
      currentContext = (await this.contextService.getCurrentContext())?.key;
    } catch (err) {
      currentContext = this.contextService.currentContext.key;
    } finally {
      if (!currentContext) {
        currentContext = this.contextService.currentContext.key;
      }
    }

    const params: NotificationsRequestParams = {
      start,
      count,
      appId: currentContext
    };

    return firstValueFrom(this.authService.secureGet<NotificationsResponse>(
      this.config.serverUrl,
      { params: toAuthRequestParams(params) }
    ).pipe(
      this.schemaValidator.isValidOperator<NotificationsResponse>('NotificationsResponse'),
      tap(res => {
        this._notifyTotalUnreadUpdatesCount(res as NotificationsSuccessResponse);
      })
    ));
  }

  markUpdatesAsRead(ids: Array<string>, start: number, count: number, shouldDelete?: boolean): Promise<NotificationsResponse> {
    const params: NotificationsRequestParams = {
      start,
      count,
      appId: this.contextService.currentContext.key
    };

    const data: NotificationsMarkAsReadRequest = { ids };

    if (shouldDelete) {
      data.delete = shouldDelete;
    }

    return firstValueFrom(this.authService.securePost<NotificationsMarkAsReadRequest, NotificationsResponse>(
      this.config.serverUrl,
      data,
      { params: toAuthRequestParams(params) }
    ).pipe(
      this.schemaValidator.isValidOperator<NotificationsResponse>('NotificationsResponse'),
      tap(res => {
        this._notifyTotalUnreadUpdatesCount(res as NotificationsSuccessResponse);
      })
    ));
  }

  _notifyTotalUnreadUpdatesCount(response: NotificationsSuccessResponse): void {
    if (response && isNumber(response?.totalNotRead)) {
      this.totalUnreadUpdatesCountSubject.next(response?.totalNotRead);
    }
  }
}
