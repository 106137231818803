import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { RichTextDocumentEditorComponent } from './components/rich-text-document-editor/rich-text-document-editor.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';



@NgModule({
  declarations: [
    RichTextDocumentEditorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CKEditorModule,
    TranslateModule,
    IconComponent
  ],
  exports: [
    RichTextDocumentEditorComponent
  ]
})
export class RichTextDocumentEditorModule { }
