import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AOEventParticipant, AOEventParticipantType, AOEventParticipantsSuccessResponse } from 'src/app/services/yeti-protocol/event';

// services
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-ao-event-participants-dialog',
  templateUrl: './ao-event-participants-dialog.component.html',
  styleUrls: ['./ao-event-participants-dialog.component.scss'],
})
export class AoEventParticipantsDialogComponent {

  @Input() eventId: string;
  @Input() aoEventParticipantType: AOEventParticipantType;
  searchQuery = '';
  count = 15;
  eventAttendeesSearch: Array<AOEventParticipant> = [];
  searchingDone = false;
  showAttendeesList = true;
  totalItems: number;

  constructor(
    private modalController: ModalController,
    private eventsService: EventsService
  ) { }

  onClose(): void {
    this.modalController.dismiss();
  }

  onSendMessage(connectionId: string): void {
    this.modalController.dismiss({ sendMessageTo: connectionId });
  }

  onOpenPublicUserProfile(userId: string): void {
    this.modalController.dismiss({ openPublicUserProfile: userId });
  }

  onClickSearch(): void {
    this.showAttendeesList = false;
  }

  onSearchParticipants(query: string): void {
    this.searchQuery = query;
    this.searchingDone = false;
    if (this.searchQuery && this.searchQuery.length >= 2) {
      this._getAttendees(0);
    } else {
      this.searchingDone = true;
    }
  }

  _getAttendees(start: number): void {
    this.eventsService.searchEventAttendees(this.eventId, this.searchQuery, start, this.count)
      .then(response => {
        if (response) {
          this.totalItems = (response as AOEventParticipantsSuccessResponse)?.totalItemsCount;
          const attendeees = (response as AOEventParticipantsSuccessResponse)?.result;
          if (start === 0) {
            this.eventAttendeesSearch = attendeees || [];
          } else {
            this.eventAttendeesSearch = [...this.eventAttendeesSearch, ...attendeees];
          }
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.searchingDone = true;
      });
  }

  loadMore(): void {
    const start = Math.floor(this.eventAttendeesSearch?.length / this.count);
    this._getAttendees(start);
  }

  get showEmptySearchState(): boolean {
    return this.searchingDone && this.eventAttendeesSearch?.length === 0;
  }

  get canLoadMore(): boolean {
    return this.eventAttendeesSearch?.length < this.totalItems;
  }
}
