// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.list-container-web {
  border-radius: 10px;
  width: 95% !important;
  margin: 15px 15px;
}

.list-container {
  width: 100%;
  background-color: var(--mi-light-purple-1-color);
}
.list-container ion-list-header {
  display: flex;
  align-items: flex-start;
  padding-right: var(--mi-standard-padding);
  margin: 0px;
  padding-top: 15px;
  height: 20px;
}
.list-container ion-list-header .clinical-experts-list-title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  margin: 0px;
}
.list-container ion-list-header .see-all-text {
  color: var(--mi-primary-color);
}
.list-container .expert-cards {
  padding: 0 16px;
}
.list-container .expert-cards app-clinical-expert-card {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
