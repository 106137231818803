// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.person-hlist-card-container {
  width: 100%;
  height: 100%;
  background-color: var(--mi-white-color);
  border-radius: 8px;
  padding: 20px 30px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  row-gap: 11px;
  justify-content: center;
  position: relative;
}
.person-hlist-card-container .x-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: none;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0%;
  --icon-button-icon-size: 18px;
  --icon-button-icon-color: var(--mi-secondary-color-2);
}
.person-hlist-card-container .image-container {
  grid-row: 1;
  grid-column: 1;
}
.person-hlist-card-container .image-container app-user-profile-image {
  --user-image-size: 68px;
}
.person-hlist-card-container .full-name-container {
  grid-row: 2;
  grid-column: 1;
}
.person-hlist-card-container .full-name-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 38px;
}
.person-hlist-card-container .full-name-container app-multiline-text-with-ellipsis ::ng-deep {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  color: var(--mi-black-color);
  text-align: center;
}
.person-hlist-card-container .full-name-container app-multiline-text-with-ellipsis ::ng-deep .multiline-text-with-ellipsis-container .multiline-text-container {
  justify-content: center;
  align-items: center;
}
.person-hlist-card-container .btn-container {
  grid-row: 3;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.person-hlist-card-container .btn-container ion-button.disabled {
  pointer-events: none !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
