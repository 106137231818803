import { HttpHeaders, HttpParams } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfile, UpdateUserProfileData, DeleteProfileResponse } from '../../yeti-protocol/auth/mi';
import { AuthProvider } from '../../yeti-protocol/auth/provider';
import { ActionSource, SourceCampaignForTracking } from '../../yeti-protocol/tracking';

export type AuthRequestHeaders = HttpHeaders | {
  [header: string]: string | string[];
}

export type AuthRequestParams = HttpParams | {
  [param: string]: string | string[];
}

export interface AuthRequestOptions {
    headers?: AuthRequestHeaders;
    params?: AuthRequestParams;
    reportProgress?: boolean;
    responseType?: 'json';
}

export interface RedirectResult {
  redirectUrl: string;
}

export interface SocialSignupInfo {
  email: string;
  firstName?: string;
  lastName?: string;
}

export type NextStepData = SocialSignupInfo;

export interface ProcessRedirectResultRedirect extends RedirectResult{
  nextStep: 'redirect';
}

export interface ProcessRedirectResultSocialSignUp {
  nextStep: 'social-signup';
}

export interface ProcessRedirectResultSocialSignIn {
  nextStep: 'social-signin';
  provider: AuthProvider;
}

export interface ProcessRedirectResultDeepLink {
  nextStep: 'deep-link',
  path: string;
}

export type ProcessRedirectResult = ProcessRedirectResultRedirect
                                  | ProcessRedirectResultSocialSignUp
                                  | ProcessRedirectResultSocialSignIn
                                  | ProcessRedirectResultDeepLink;

export interface SignInData {
  username: string;
  password: string;
}

export interface SignUpData {
  username: string;
  password: string;
  firstName?: string;
  lastName?: string;
  redirectUrl?: string;
  sourceCampaign?: SourceCampaignForTracking;
}

export enum SignInNextStep {
  WORK,
  MIGRATE,
  REDIRECT,
  SOCIAL_SIGNUP
}


interface SignInResultRedirect extends RedirectResult {
  nextStep: SignInNextStep.REDIRECT;
}

interface SignInResultWork {
  nextStep: SignInNextStep.WORK;
}

interface SignInResultMigrate {
  nextStep: SignInNextStep.MIGRATE;
}

export interface SignInResultSocialSignUp {
  nextStep: SignInNextStep.SOCIAL_SIGNUP
}

export type SignInResult = SignInResultRedirect | SignInResultWork | SignInResultMigrate | SignInResultSocialSignUp;

export enum SignUpStatus {
  UNKNOWN, // no data to check the status
  NO_HOME_DEVISION,
  NO_SPECIALTY,
  NO_COUNTRY,
  NOT_CONFIRMED,
  FINISHED
}

export interface AuthLogicServiceInterface {

  readonly isSignedInAsObservable: Observable<boolean>;
  readonly userProfileAsObservable: Observable<UserProfile>;
  readonly userAdminGroupsAsObservable: Observable<Array<string>>;
  readonly accessBlockedAsObservable: Observable<boolean>;

  isSignedIn(): Promise<boolean>;
  // insights / social signin
  signIn(provider: AuthProvider, data?: SignInData): Promise<SignInResult>;
  // insights signup
  signUp(provider: AuthProvider, data: SignUpData): Promise<RedirectResult>;
  signOut(): Promise<RedirectResult>;
  getSignupStatus(context: string): Promise<SignUpStatus>;
  resetPassword(email: string): Promise<boolean>;
  getProfile(context: string, withoutCaching: boolean, preventReloadBellowXMs?: number): Promise<UserProfile>;
  updateProfile(context: string, data: UpdateUserProfileData, withoutCaching: boolean, source: ActionSource): Promise<UserProfile>;
  deleteProfile(context: string): Promise<DeleteProfileResponse>;
  reloadProfile(context: string): Promise<UserProfile>;
  securePost<D, T>(url: string, postData: D, options?: AuthRequestOptions): Observable<T>;
  secureGet<T>(url: string, options?: AuthRequestOptions): Observable<T>;
  secureDelete<D, T>(url: string, deleteData: D, options?: AuthRequestOptions): Observable<T>;
  securePut<D, T>(ursl: string, putData: D, options?: AuthRequestOptions): Observable<T>;
  fakeSignInExpire(): Promise<void>;
  openAoLink(url: string): Promise<void>;
}

export const AUTH_LOGIC_SERVICE = new InjectionToken<AuthLogicServiceInterface>('auth-logic.service');
