import { Inject, Injectable } from '@angular/core';

import { firstValueFrom, Observable, Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { SchemaValidatorService } from '../schema-validator.service';
import {
  ArticlesForPublishingParams,
  ArticlesPublishResponse,
  ArticlesResponse,
  GetArticlesByCampaignIdParams,
  GetArticlesByCampaignIdResponse,
  ArticlesSuccessResponse,
  GetArticleRecommendsResponse,
  GetArticleRecommendsRequestParams,
  GetVideosRequestParams,
  GetVideosResponse,
  Video,
  AOVideo,
  Article,
  GetVideoTranscriptSummaryResponse,
  VideoTranscriptSummary,
  GetOrcIdArticlesParams,
  GetOrcIdArticlesResponse,
  GetPublishedOrcIdArticlesResponse,
} from '../yeti-protocol/article';
import { AuthService } from '../auth/auth.service';
import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { ErrorResponse } from '../yeti-protocol/error';
import appConfig from 'src/config/config';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import { ContextRequest } from '../yeti-protocol/context-request';
import { Store } from '@ngxs/store';
import { MyKnowledgeStream } from 'src/app/state/my-knowledge-stream/my-knowledge-stream.actions';
import { SavedKnowledge } from 'src/app/state/saved-knowledge/saved-knowledge.actions';
import { ItemType, TopRecommendationType } from '../yeti-protocol/utils/enums';

export interface ArticleServiceConfig {
  serverUrlGetArticles: string;
  serverUrlGetArticlesByCampaignId: string;
  serverUrlGetSimilarArticles: string;
  serverUrlGetArticlesForPublishing: string;
  serverUrlGetVideos: string;
  imageProxyServerUrl: string;
  videoTranscriptSummaryUrl: string;
  backendUrlIonic: string;
}

export interface VideosInfo {
  articles: Array<Video>;
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  config: ArticleServiceConfig = {
    serverUrlGetArticles: `${appConfig.backendUrlIonic}articles`,
    serverUrlGetArticlesByCampaignId: `${appConfig.backendUrlIonic}articles/by/campaign`,
    serverUrlGetSimilarArticles: `${appConfig.backendUrlIonic}articles/similar`,
    serverUrlGetArticlesForPublishing: `${appConfig.backendUrlIonic}streamInsights`,
    imageProxyServerUrl: appConfig.imagesProxyServerUrl,
    serverUrlGetVideos: `${appConfig.backendUrlIonic}videos/search`,
    videoTranscriptSummaryUrl: `${appConfig.backendUrlIonic}articles/summary`,
    backendUrlIonic: appConfig.backendUrlIonic,
  };

  private defaultStart = 0;
  private defaultListCount = 15;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private schemaValidator: SchemaValidatorService,
    private authService: AuthService,
    private store: Store
  ) { }

  private articleUpdatedSubject: Subject<Article | Video | AOVideo> =
    new Subject();

  /**
   * @desc Access to an article by id
   * @param articleIds  list of article ids
   * @returns foound articles
   */
  getArticles(articleIds: string[], context: string = null): Observable<ArticlesResponse> {
    const url = [
      this.config.serverUrlGetArticles,
      'get',
      articleIds.join(','),
    ].join('/');
    return this.authService
      .secureGet<ArticlesResponse>(url, {
        params: {
          appId: context ? context : this._appId,
        },
      })
      .pipe(
        this.schemaValidator.isValidOperator<ArticlesResponse>(
          'ArticlesResponse'
        ),
        map(articlesResponse => {
          const articlesSuccessResponse =
            articlesResponse as ArticlesSuccessResponse;

          if (articlesSuccessResponse?.result) {
            articlesSuccessResponse?.result?.forEach(article => {
              // Added length check because huge links did not load
              if (
                article &&
                article.previewImageUrl &&
                article.previewImageUrl.length < 50
              ) {
                article.previewImageUrl = [
                  this.config.imageProxyServerUrl,
                  '?url=',
                  encodeURIComponent(article.previewImageUrl),
                ].join('');
              }
            });
            return articlesSuccessResponse;
          } else {
            throwError(() => articlesResponse as ErrorResponse);
          }
        })
      );
  }

  getArticlesForContext(articleIds: string[], context: string): Promise<Array<Article | Video | AOVideo>> {
    return firstValueFrom(this.getArticles(articleIds, context).pipe(
      map(articlesResponse => {
        const articles = (articlesResponse as ArticlesSuccessResponse)?.result;
        if (!articles) {
          throw new Error('article is not found'); // TODO: ui indication
        }
        return articles;
      })
    ));
  }


  getArticlesByCampaignId(
    campaignId: string,
    start: number,
    count: number
  ): Promise<GetArticlesByCampaignIdResponse> {
    const params: GetArticlesByCampaignIdParams = {
      start,
      count,
      appId: this._appId,
    };
    const requestUrl = `${this.config.serverUrlGetArticlesByCampaignId}/${campaignId}`;

    return firstValueFrom(
      this.authService
        .secureGet(requestUrl, { params: toAuthRequestParams(params) })
        .pipe(
          this.schemaValidator.isValidOperator<GetArticlesByCampaignIdResponse>(
            'GetArticlesByCampaignIdResponse'
          )
        )
    );
  }

  getArticlesForPublishing(
    start: number,
    count: number,
    publisher: boolean,
    publishedStatus: boolean,
    hiddenStatus: boolean
  ): Observable<ArticlesSuccessResponse> {
    const url = [this.config.serverUrlGetArticlesForPublishing].join('/');
    const params: ArticlesForPublishingParams = {
      appId: this._appId,
      publisher: publisher,
      published: publishedStatus,
      hidden: hiddenStatus,
      page: start,
      count: count,
    };
    return this.authService
      .secureGet<any>(url, {
        params: toAuthRequestParams(params),
      })
      .pipe(
        this.schemaValidator.isValidOperator<any>('ArticlesResponse'),
        map(articlesResponse => {
          const articlesSuccessResponse =
            articlesResponse as ArticlesSuccessResponse;

          if (articlesSuccessResponse?.result) {
            articlesSuccessResponse?.result?.forEach(article => {
              if (article && article.previewImageUrl) {
                article.previewImageUrl = [
                  this.config.imageProxyServerUrl,
                  '?url=',
                  encodeURIComponent(article.previewImageUrl),
                ].join('');
              }
            });
            return articlesSuccessResponse;
          } else {
            throwError(() => articlesResponse as ErrorResponse);
          }
        })
      );
  }

  changePublishStateOfArticle(
    articleId: string,
    publishState: boolean
  ): Promise<ArticlesPublishResponse> {
    const url = `${this.config.serverUrlGetArticles}/publish/${articleId}/${publishState}?appId=${this._appId}`;
    const params: any = {
      appId: this._appId,
    };
    return firstValueFrom(
      this.authService.securePost<any, ArticlesPublishResponse>(
        url,
        toAuthRequestParams(params)
      )
    );
  }

  changeHiddenStateOfArticle(
    articleId: string,
    hiddenState: boolean
  ): Promise<ArticlesPublishResponse> {
    const url = `${this.config.serverUrlGetArticles}/hide/${articleId}/${hiddenState}?appId=${this._appId}`;
    const params: any = {
      appId: this.contextService.currentContext.key,
    };
    return firstValueFrom(
      this.authService.securePost<any, ArticlesPublishResponse>(
        url,
        toAuthRequestParams(params)
      )
    );
  }

  getArticleRecommends(
    articleId: string,
    start: number = this.defaultStart,
    count: number = this.defaultListCount
  ): Promise<GetArticleRecommendsResponse> {
    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.serverUrlGetArticles}/recommends/${articleId}`;

      const requestParams: GetArticleRecommendsRequestParams = {
        appId: this.contextService.currentContext.key,
        start,
        count,
      };
      return firstValueFrom(
        this.authService
          .secureGet(getRequestUrl, {
            params: toAuthRequestParams(requestParams),
          })
          .pipe(
            this.schemaValidator.isValidOperator<GetArticleRecommendsResponse>(
              'GetArticleRecommendsResponse'
            )
          )
      );
    });
  }

  getVideos(
    start: number = this.defaultStart,
    count: number = this.defaultListCount,
    query?: string
  ): Promise<VideosInfo> {
    const url = this.config.serverUrlGetVideos;
    const params: GetVideosRequestParams = {
      appId: this._appId,
      start,
      count,
      query: query ?? ''
    };
    return firstValueFrom(
      this.authService
        .secureGet<GetVideosResponse>(url, {
          params: toAuthRequestParams(params),
        })
        .pipe(
          // this.schemaValidator.isValidOperator<GetVideosResponse>('GetVideosResponse'),
          map(res => {
            if ('result' in res) {
              return {
                articles: res.result,
                total: res.totalItemsCount,
              };
            }
            throwError(() => new Error('Fail fetch video articles'));
          })
        )
    );
  }

  getVideoTranscriptSummary(
    articleId: string
  ): Promise<VideoTranscriptSummary> {
    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.videoTranscriptSummaryUrl}/${articleId}`;

      const requestParams: ContextRequest = {
        appId: this.contextService.currentContext.key,
      };
      return firstValueFrom(
        this.authService
          .secureGet(getRequestUrl, {
            params: toAuthRequestParams(requestParams),
          })
          .pipe(
            this.schemaValidator.isValidOperator<GetVideoTranscriptSummaryResponse>(
              'GetVideoTranscriptSummaryResponse'
            ),
            map(res => {
              if (res.success === true) {
                if ('result' in res) {
                  return res.result;
                }
              }
              if ('errors' in res) {
                throw new Error(JSON.stringify(res.errors));
              } else {
                throw new Error('not recognised server response');
              }
            })
          )
      );
    });
  }

  getOrcIdArticles(
    userId?: string,
    start: number = 0,
    count: number = 10
  ): Promise<GetOrcIdArticlesResponse> {
    let serverUrl = `${this.config.backendUrlIonic}articles/orcid`;

    if (userId?.length) {
      serverUrl = `${serverUrl}/${userId}`;
    }

    const params: GetOrcIdArticlesParams = {
      start: start,
      count: count,
      appId: this._appId,
    };

    return firstValueFrom(
      this.authService
        .secureGet<GetOrcIdArticlesResponse>(serverUrl, {
          params: toAuthRequestParams(params),
        })
        .pipe
        // this.schemaValidator.isValidOperator<GetOrcIdArticlesResponse>('GetOrcIdArticlesResponse')
        ()
    );
  }

  getPublishedOrcIdArticles(
    start: number = 0,
    count: number = 10
  ): Promise<GetPublishedOrcIdArticlesResponse> {
    const serverUrl = `${this.config.backendUrlIonic}articles/orcid/published`;

    const params: GetOrcIdArticlesParams = {
      start: start,
      count: count,
      appId: this._appId,
    };

    return firstValueFrom(
      this.authService
        .secureGet<GetPublishedOrcIdArticlesResponse>(serverUrl, {
          params: toAuthRequestParams(params),
        })
    );
  }

  get _appId(): string {
    return this.contextService.currentContext.key;
  }

  emitArticleUpdated(article: Article | Video | AOVideo): void {
    this.articleUpdatedSubject.next(article);
    this.store.dispatch(new MyKnowledgeStream.UpdateMyKnowledgeStreamItem(article));
    this.store.dispatch(new SavedKnowledge.UpdateSavedKnowledge({
      item: article as any,
      type: article.type === ItemType.article ? TopRecommendationType.article : TopRecommendationType.video
    }));
  }

  get articleUpdatedObservable(): Observable<Article | Video | AOVideo> {
    return this.articleUpdatedSubject.asObservable();
  }
}
