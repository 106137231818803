import { Routes } from '@angular/router';

export const rootRoutes: Routes = [
  {
    path: 'trauma',
    loadChildren: () => import('./trauma.module').then(m => m.TraumaModule)
  },
  {
    path: 'trauma',
    loadChildren: () => import('./trauma-auxiliary.module').then(m => m.TraumaAuxiliaryModule),
    outlet: 'dialog'
  },
  {
    path: 'orthopedics',
    redirectTo: 'trauma'
  }
];
export const navigationTabsRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./trauma-tabs.module').then(m => m.TraumaTabsModule)
  }
];
