import { ContextEnvironmentConfigInterface, Environment } from '../config.model';
import { ContextConfig } from './recon-context.config';

class ContextEnvironmentConfig extends ContextConfig implements ContextEnvironmentConfigInterface {
  environment: Environment = 'prod';
  kaltura = {
    partnerId: '2396982',
		uiconfId: '42933402'
  };
  aoMembershipUrl = 'https://www.aofoundation.org/trauma/membership'; // TODO: when recon uses membership, update url
  moreEventsLink = 'https://www.aofoundation.org/recon/education/courses-events';
}

const contextConfig = new ContextEnvironmentConfig();
export default contextConfig;
