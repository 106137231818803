import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';

// services
import { SearchService } from 'src/app/services/search.service';
import { RecentSearchesSuccessResponse, SearchTrackingSource } from 'src/app/services/yeti-protocol/search';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';

@Component({
  selector: 'app-recent-searches-list',
  templateUrl: './recent-searches-list.component.html',
  styleUrls: ['./recent-searches-list.component.scss'],
})
export class RecentSearchesListComponent implements AfterViewInit {

  @Input() placedInDialog: boolean;
  @Input() placedInPopOver: boolean;

  recentSearches: Array<string> = [];
  topSearches: Array<string> = [];

  SearchTrackingSource = SearchTrackingSource;

  constructor(
    private searchService: SearchService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private modalController: ModalController,
    private popoverController: PopoverController
  ) { }

  ngAfterViewInit(): void {
    this.getRecentSearches();
  }

  getRecentSearches(): void {
    this.searchService.getRecentSearches().then((recentSearchesSuccessResponse: RecentSearchesSuccessResponse) => {
      this.recentSearches = recentSearchesSuccessResponse.result.recentSearches;
      this.topSearches = recentSearchesSuccessResponse.result.topSearches;
    }).catch(err => {
      console.error(err);
    });
  }

  recentSearchItemClick(term: string, source: SearchTrackingSource): void {

    const termEncoded = window.encodeURIComponent(term);

    this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/search?searchTerm=${termEncoded}`,
      { state: { id: 'source', name: source } });

    if (this.placedInDialog) {
      this.modalController.dismiss();
    }

    if (this.placedInPopOver) {
      this.popoverController.dismiss();
    }
  }

}
