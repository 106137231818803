import { Inject, Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import {
  SESSION_STORAGE_SERVICE,
  StorageService,
} from '../storage/storage.service';
import { URL, UrlUtilsService } from '../utils/url-utils.service';
import { SourceCampaignForTracking } from '../yeti-protocol/tracking';

const LAST_PATH_KEY = 'last-path';
const SOURCE_CAMPAIGN_KEY = 'source-campaign';
const MARKETING_CAMPAIGN_KEY = 'marketing-campaign'; // use to flag user registration due to marketing campaign

// responsibility: keeping deep link data until user is signed in
@Injectable({
  providedIn: 'root',
})
export class DeepLinksStorageService {
  constructor(
    private authService: AuthService,
    private urlUtils: UrlUtilsService,
    @Inject(SESSION_STORAGE_SERVICE) private sessionStorage: StorageService
  ) {}

  // return true if saved
  saveDeepLinkIfNotSignedIn(url: string): Promise<boolean> {
    return this.authService.isSignedIn().then(isSignedIn => {
      if (isSignedIn) {
        return false;
      }

      if (url?.indexOf('ao-auth-signup') === -1) {
        return this.saveDeepLinkInLocalStorage(url).then(() =>
          Promise.resolve(true)
        );
      }

      return Promise.resolve(false);
    });
  }

  saveDeepLinkInLocalStorage(url: string): Promise<boolean> {
    if (!url) {
      return Promise.resolve(false);
    }
    const parsedUrl: URL = this.urlUtils.parse(url);
    if (!parsedUrl) {
      return Promise.resolve(false);
    }

    // do not store auth-v2-ao-redirect. This is always returned (since we can only use AO for login)
    // meaning all our stored deeplinks will be overwritten on any login/signUp (MYAO-6118)
    if (
      parsedUrl &&
      (parsedUrl.path === '/auth-ao-redirect' ||
        parsedUrl.path === '/auth-v2-ao-redirect' ||
        parsedUrl.path === '/ao-auth-signup' ||
        parsedUrl.path === '/auth-orcid-redirect')
    ) {
      return Promise.resolve(false);
    }
    const link = this._buildPathWithQueryParameters(parsedUrl);
    return Promise.all([
      this.sessionStorage.set(LAST_PATH_KEY, link),
      this._saveCampaignInfoIfPresent(parsedUrl),
    ]).then(() => Promise.resolve(true));
  }

  saveCampaignInfoIfPresent(url: string): Promise<boolean> {
    return this.authService.isSignedIn().then(isSignedIn => {
      if (isSignedIn) {
        return false;
      }
      const parsedUrl: URL = this.urlUtils.parse(url);
      if (parsedUrl && parsedUrl.path === '/auth-v2-ao-redirect') {
        return false;
      }
      return this._saveCampaignInfoIfPresent(parsedUrl).then(() =>
        Promise.resolve(true)
      );
    });
  }

  getSavedDeepLink(): Promise<string> {
    return this.sessionStorage.get(LAST_PATH_KEY);
  }

  getSavedCampaignInfo(): Promise<SourceCampaignForTracking> {
    return this.sessionStorage.get(SOURCE_CAMPAIGN_KEY);
  }

  getMarketingCampaignInfo(): Promise<SourceCampaignForTracking> {
    return this.sessionStorage.get(MARKETING_CAMPAIGN_KEY);
  }

  // removes all saved data
  clear(): Promise<void> {
    return Promise.all([
      this.sessionStorage.remove(LAST_PATH_KEY),
      this.sessionStorage.remove(SOURCE_CAMPAIGN_KEY),
    ]).then(() => Promise.resolve());
  }

  _buildPathWithQueryParameters(parsedUrl: URL): string {
    let link = parsedUrl.path;
    if (Object.keys(parsedUrl.query).length > 0) {
      link = [link, this.urlUtils.object2queryString(parsedUrl.query)].join(
        '?'
      );
    }
    return link;
  }

  _saveCampaignInfoIfPresent(parsedUrl: URL): Promise<void> {
    const data: SourceCampaignForTracking = {};
    if (parsedUrl?.query?.source) {
      data.source = parsedUrl?.query?.source;
    }
    if (parsedUrl?.query?.utm_source) {
      data.source = parsedUrl?.query?.utm_source;
    }
    if (parsedUrl?.query?.campaign) {
      data.marketingCampaign = parsedUrl?.query?.campaign;
    }
    if (parsedUrl?.query?.utm_campaign) {
      data.marketingCampaign = parsedUrl?.query?.utm_campaign;
    }
    return Promise.all([
      this.sessionStorage.set(SOURCE_CAMPAIGN_KEY, data),
      this.sessionStorage.set(MARKETING_CAMPAIGN_KEY, data),
    ]).then(() => Promise.resolve());
  }
}
