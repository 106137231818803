// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-list-container {
  background-color: var(--mi-light-purple-1-color);
  padding: 14px 0;
}
.swiper-list-container.negativeXMargin {
  margin: 0 -10px;
}
.swiper-list-container .title-container {
  display: flex;
  justify-content: space-between;
  margin: 0 16px 14px 16px;
}
.swiper-list-container .title-container.hidden {
  display: none !important;
}
.swiper-list-container .title-container .title {
  color: var(--mi-grey-color-16);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
}
.swiper-list-container .title-container .see-all {
  color: var(--mi-grey-color-28);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-regular);
}
.swiper-list-container swiper {
  padding: 0 15px;
}
.swiper-list-container .top-contributor-container-card {
  height: 200px;
  background-color: var(--mi-white-color);
  border-radius: 8px;
}

.isFollower {
  --background: var(--mi-color-text-lite);
  --border-color: var(--mi-color-text-lite);
  --custom-background-hover-color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
