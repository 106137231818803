import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InfoSheetVisibilityStatusChange } from 'src/app/modules/info-sheet/components/info-sheet/info-sheet.component';
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import { IconLabelPosition } from 'src/app/modules/buttons/base-button/base-button.component';
import { Keyboard } from '@awesome-cordova-plugins/keyboard';
import { Subscription } from 'rxjs';
import { Platform as AppPlatform } from 'src/config/config.model';
import appConfig from 'src/config/config';

export interface ButtonActions {
  avaliable?(): boolean;
  disabled?(): boolean;
  active?(): boolean;
  withBadge?(): boolean;
}

export interface CreateEditCasePostBottomBarConfig {
  images?: ButtonActions;
  videos?: ButtonActions;
  documents?: ButtonActions;
  case?: ButtonActions;
  emojis?: ButtonActions;
  watermark?: ButtonActions;
  reorder?: ButtonActions;
  settings?: ButtonActions;
}

@Component({
  selector: 'app-create-edit-case-post-bottom-bar',
  templateUrl: './create-edit-case-post-bottom-bar.component.html',
  styleUrls: ['./create-edit-case-post-bottom-bar.component.scss'],
})
export class CreateEditCasePostBottomBarComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() config: CreateEditCasePostBottomBarConfig = {};
  @Input() customSettings: boolean;
  @Input() buttonsWithLabel: boolean;
  @Input() iconOnly = true;
  @Input() iconLabelPosition: IconLabelPosition;
  @Input() showPageSettingsOnInit: boolean;

  @Output() addImagesClicked: EventEmitter<void> = new EventEmitter();
  @Output() addVideosClicked: EventEmitter<void> = new EventEmitter();
  @Output() attachDocumentClicked: EventEmitter<void> = new EventEmitter();
  @Output() createCaseClicked: EventEmitter<void> = new EventEmitter();
  @Output() addEmojiClicked: EventEmitter<Event> = new EventEmitter();
  @Output() watermarkClicked: EventEmitter<void> = new EventEmitter();
  @Output() infoSheetVisibilityStatusChanged: EventEmitter<InfoSheetVisibilityStatusChange> = new EventEmitter();
  @Output() settingsClicked: EventEmitter<void> = new EventEmitter();
  @Output() reorderClicked: EventEmitter<void> = new EventEmitter();

  infoSheetId = 'create-edit-case-post-bottom-bar-info-sheet';
  keyboardShowed: boolean;

  private settingsShowed = false;
  private keyboardDidShowSub: Subscription = null;
  private keyboardDidHideSub: Subscription = null;

  constructor(
    private appTranslationService: AppTranslationService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private infoSheetService: InfoSheetService,
    private toast: ToastService
  ) { }

  ngOnInit(): void {

    this.keyboardDidShowSub = Keyboard.onKeyboardWillShow().subscribe(() => {
      this.keyboardShowed = true;
    });

    this.keyboardDidHideSub = Keyboard.onKeyboardWillHide().subscribe(() => {
      this.keyboardShowed = false;
    });
  }

  ngAfterViewInit(): void {
    if (this.showPageSettingsOnInit) {
      setTimeout(() => {
        this.showPageSettings();
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this.keyboardDidShowSub?.unsubscribe();
    this.keyboardDidHideSub?.unsubscribe();
  }

  addImages(): void {
    this.addImagesClicked.emit();
  }

  addVideos(): void {
    this.addVideosClicked.emit();
  }

  attachDocument(): void {
    this.attachDocumentClicked.emit();
  }

  createCase(): void {
    this.createCaseClicked.emit();
  }

  addEmoji(event: Event): void {
    this.addEmojiClicked.emit(event);
  }

  toggleWatermark(): void {

    this.watermarkClicked.emit();

    if (!this.config ||
      !this.config?.watermark ||
      !this.config?.watermark?.disabled ||
      !this.config?.watermark?.active) {
      return;
    }

    if (this.config.watermark.disabled()) {
      return;
    }

    let message = '';

    if (!this.config.watermark.active()) { // state was changed from not active to active with the emit from above
      message = this.appTranslationService.instant('app.watermark.watermark-removed');
      this.toast.showWithMessage(message, null, ToastMode.ERROR);
    } else {
      message = this.appTranslationService.instant('app.watermark.watermark-added');
      this.toast.showWithMessage(message, null, ToastMode.SUCCESS);
    }

  }

  reorder(): void {
    this.reorderClicked.emit();
  }

  showPageSettings(): void {

    if (this.customSettings) {
      this.settingsClicked.emit();
      return;
    }

    if (!this.shouldShowActionSheet) {
      return;
    }

    this.settingsShowed = true;
    this.infoSheetService.open(this.infoSheetId);
  }

  closeSettings(): void {

    if (!this.settingsShowed) {
      return;
    }

    this.infoSheetService.close(this.infoSheetId);
    this.settingsShowed = false;
  }

  infoSheetVisibilityStatusChange(infoSheetVisibilityStatusChange: InfoSheetVisibilityStatusChange): void {
    this.infoSheetVisibilityStatusChanged.emit(infoSheetVisibilityStatusChange);
  }

  get watermarkActionText(): string {
    return this.config?.watermark?.active() ? this.appTranslationService.instant('app.watermark.remove-image-watermark') :
      this.appTranslationService.instant('app.watermark.enable-image-watermark');
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get shouldShowActionSheet(): boolean {
    return !this.isDesktop;
  }

  get isIOS(): boolean {
    return appConfig.platform === AppPlatform.IOS;
  }

  get extraPaddingWhenKeyboardShowedOnIOS(): boolean {
    return this.isIOS && this.keyboardShowed;
  }

}
