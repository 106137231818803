import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { FileSelectComponent } from 'src/app/modules/file-select/components/file-select/file-select.component';
import { contextForClient } from 'src/app/contexts/utils';
import { AttachmentMimeType, ImageAttachment } from 'src/app/services/attachments.model';

import { AuthDialogPage, AuthUIServiceInterface, AUTH_UI_SERVICE } from 'src/app/services/auth/auth-ui.service.interface';
import { FileSelectLimits, FileType, SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { FileSelectService } from 'src/app/modules/file-select/services/file-select.service';
import { UploadFile } from 'src/app/services/yeti-protocol/upload';
import { UploadService, UploadTarget } from 'src/app/services/upload.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import appConfig from 'src/config/config';
import { BaseAuthPage } from '../auth-common/base-auth-page';
import { SourceCampaignForTracking } from '../../../services/yeti-protocol/tracking';
import { DeepLinksStorageService } from '../../../services/deep-links/deep-links-storage.service';
import { FileSelectScope } from '../../../modules/file-select/services/file-select.service';

@Component({
  selector: 'app-auth-signup-confirm',
  templateUrl: './auth-signup-confirm.page.html',
  styleUrls: ['./auth-signup-confirm.page.scss'],
})
export class AuthSignUpConfirmPage extends BaseAuthPage implements OnInit {

  @ViewChild('fileSelectImage') fileSelectImage: FileSelectComponent;

  FileType = FileType;
  AttachmentMimeType = AttachmentMimeType;

  errorMessage: string = null;
  AuthPage = AuthDialogPage;
  appConfig = appConfig;

  firstName: string;
  lastName: string;
  profession: string;
  specialty: string;
  specialtyIcon: string;
  country: string;
  imageUrl: string = null;
  checkedCommunityGuideLines = false;
  maxImageFileSizeMb = FileSelectLimits.imageMaxFileSizeMB;
  imageFile: ImageAttachment;
  followCommunityGuidelines: boolean;
  FileSelectScope = FileSelectScope;
  confirmLoading = false;

  constructor(
    nav: IonNav,
    @Inject(AUTH_UI_SERVICE) authUIService: AuthUIServiceInterface,
    private uploadService: UploadService,
    private fileSelectService: FileSelectService,
    private responsiveUtilsService: ResponsiveUtilsService,
    public deepLinkStorage: DeepLinksStorageService
  ) {
    super(nav, authUIService);
  }

  ngOnInit(): void {
    this.authUIService.getUserProfile()
      .then(data => {
        this.renderUser(data);
      });
  }

  onConfirm(): void {

    if (this.confirmLoading) {
      return;
    }

    this.confirmLoading = true;

    this.errorMessage = '';
    let data;

    this.deepLinkStorage.getMarketingCampaignInfo()
      .then((sourceCampaign: SourceCampaignForTracking) => {
        if (sourceCampaign) {
          if (sourceCampaign.source) {
            if (!data) {
              data = {};
            }
            data.source = sourceCampaign.source
          }
          if (sourceCampaign.marketingCampaign) {
            if (!data) {
              data = {};
            }
            data.marketingCampaign = sourceCampaign.marketingCampaign
          }
        }
        this.authUIService.signUpConfirmed(data)
          .catch(err => {
            this.errorMessage = err.message;
          }).finally(() => {
            this.confirmLoading = false;
          });
      })
      .catch(async err => {
        // if something went wrong getting the deepLink storage, log but still continue
        console.log(err);
        this.authUIService.signUpConfirmed(data)
          .catch(ex => {
            this.errorMessage = ex.message;
          }).finally(() => {
            this.confirmLoading = false;
          });
      });
  }

  onUploadPicture(): void {
    this.fileSelectImage.selectFile();
  }

  imageSelected(images: Array<SelectedFile>): void {
    if (images.length > 0) {
      images?.forEach((image: SelectedFile) => {
        this.imageFile = {
          _id: null,
          fullUrl: image?.url,
          mimeType: image?.file?.type,
          fileName: image?.file?.name,
          contentLength: image?.file?.size,
          file: image?.file
        }
        this.imageUrl = image?.url;
        this.onProfileImageChange();
      });
    }
  }

  onProfileImageChange(): void {
    const uploadFile: UploadFile = {
      key: 'image',
      file: this.imageFile?.file,
      fileName: this.imageFile?.fileName,
      mimeType: this.imageFile?.mimeType
    }
    this.uploadService.upload(UploadTarget.USER_PROFILE, uploadFile).subscribe({
      next: res => {
        console.log(res);
        this.authUIService.reloadUserProfile().then(user => {
          this.renderUser(user);
          this.imageUrl = user.profileImageUrl;
        });
      },
      error: err => {
        console.error(err);
      }
    });
  }

  getHomeSpecialty(contextKey: string): { label: string, icon: string } {
    const context = appConfig.contexts.find(ctx => {
      return contextKey === ctx.key;
    });
    return {
      label: context ? context.label : 'unknown',
      icon: context ? context.iconName : ''
    };
  }

  onNotifyChecked(): void {
    this.checkedCommunityGuideLines = !this.checkedCommunityGuideLines;
  }

  get defaultImageMimeTypes(): string {
    return this.fileSelectService?.defaultImageMimeTypes.join(',');
  }

  private renderUser(user: UserProfile): void {
    const specialtyContext = contextForClient(user.homeDevision);
    const specialtyInfo = this.getHomeSpecialty(specialtyContext);
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.profession = user.profession;
    this.country = user.country;
    this.imageUrl = user.profileImageUrl;
    this.specialty = specialtyInfo.label;
    this.specialtyIcon = specialtyInfo.icon
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }
}
