// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --surgery-reference-image-size: 68px;
  --surgery-reference-image-border-radius: 10px;
}

.surgery-reference-image {
  width: 100%;
  height: 100%;
}
.surgery-reference-image ion-thumbnail {
  width: var(--surgery-reference-image-size);
  height: var(--surgery-reference-image-size);
  --border-radius: var(--surgery-reference-image-border-radius);
}
.surgery-reference-image ion-thumbnail ion-img {
  width: var(--surgery-reference-image-size);
  height: var(--surgery-reference-image-size);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
