// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --card-with-tabs-padding-top: 20px;
  --card-with-tabs-padding-end: 15px;
  --card-with-tabs-padding-bottom: 20px;
  --card-with-tabs-padding-start: 15px;
  --card-with-tabs-border-radius: 12px;
  --card-with-tabs-background-color: var(--mi-white-color);
}

.card-with-tabs-container {
  width: 100%;
  padding-top: var(--card-with-tabs-padding-top);
  padding-right: var(--card-with-tabs-padding-end);
  padding-bottom: var(--card-with-tabs-padding-bottom);
  padding-left: var(--card-with-tabs-padding-start);
  border-radius: var(--card-with-tabs-border-radius);
  background-color: var(--card-with-tabs-background-color);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 35px;
}
.card-with-tabs-container .card-header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
}
.card-with-tabs-container .card-header .title-and-action-container {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.card-with-tabs-container .card-header .title-and-action-container .title-container {
  grid-row: 1;
  grid-column: 1;
}
.card-with-tabs-container .card-header .title-and-action-container .title-container .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-with-tabs-container .card-header .title-and-action-container .action-container {
  grid-row: 1;
  grid-column: 2;
}
.card-with-tabs-container .card-header .title-and-action-container .action-container .see-all-btn {
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
}
.card-with-tabs-container .card-header .title-and-action-container .action-container .see-all-btn span {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  white-space: nowrap;
}
.card-with-tabs-container .card-header .title-and-action-container.title-only {
  display: block;
}
.card-with-tabs-container .card-header .title-and-action-container.action-only {
  display: flex;
  align-items: center;
  justify-content: end;
}
.card-with-tabs-container .card-header .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container {
  background-color: transparent;
  padding: 0;
}
.card-with-tabs-container .card-header .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container .tabs-wrapper {
  background-color: transparent;
  padding: 0;
  justify-content: start;
}
.card-with-tabs-container .card-header .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container .tabs-wrapper .tab {
  box-shadow: none;
  border: 1px solid var(--mi-black-color);
  border-radius: 8px;
  padding: 10px;
  margin-left: 10px;
}
.card-with-tabs-container .card-header .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container .tabs-wrapper .tab:first-child {
  margin-left: 0;
}
.card-with-tabs-container .card-header .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container .tabs-wrapper .tab span {
  color: var(--mi-black-color);
}
.card-with-tabs-container .card-header .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container .tabs-wrapper .tab.active {
  background-color: var(--mi-primary-color);
  border: none;
}
.card-with-tabs-container .card-header .tabs-container app-custom-tabs ::ng-deep .custom-tabs-container .tabs-wrapper .tab.active span {
  color: var(--mi-white-color);
}
.card-with-tabs-container .card-content {
  grid-row: 2;
  grid-column: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
