import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, IonRefresher, ModalController } from '@ionic/angular';
import { CustomTab } from 'src/app/components/custom-tabs/custom-tabs.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { GroupVisibility } from 'src/app/services/groups/group.model';
import { ToastMode, ToastService } from 'src/app/services/toast.service';

import {
  GetSharedWithSuccessResponse,
  SharedWithContact, SharedWithGroup,
  SharedWithTypeFilter as TabIds
} from 'src/app/services/yeti-protocol/clinical-case';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

@Component({
  selector: 'app-clinical-case-shared-with-dialog',
  templateUrl: './clinical-case-shared-with-dialog.component.html',
  styleUrls: ['./clinical-case-shared-with-dialog.component.scss'],
})
export class ClinicalCaseSharedWithDialogComponent implements OnInit {

  @ViewChild('infiniteScrollSharedWithGroups') infiniteScrollSharedWithGroups: IonInfiniteScroll;
  @ViewChild('infiniteScrollSharedWithContacts') infiniteScrollSharedWithContacts: IonInfiniteScroll;
  @ViewChild('sharedWithContactsRefresher') sharedWithContactsRefresher: IonRefresher;
  @ViewChild('sharedWithGroupsRefresher') sharedWithGroupsRefresher: IonRefresher;

  @Input() caseId: string;

  customTabs: Array<CustomTab> = [];
  activeTabId: string;
  TabIds = TabIds;
  GroupVisibility = GroupVisibility;
  sharedWithGroups: Array<SharedWithGroup> = [];
  totalSharedWithGroups: number;
  sharedWithGroupsLoading = true;
  sharedWithContacts: Array<SharedWithContact> = [];
  totalSharedWithContacts: number;
  sharedWithContactsLoading = true;
  count = 10;

  constructor(
    private appTranslationService: AppTranslationService,
    private modalController: ModalController,
    private toast: ToastService,
    private clinicalCaseService: ClinicalCaseService,
    private appNavController: AppNavController
  ) { }

  ngOnInit(): void {
    this.generateTabs();

    this.getSharedWithGroups()
      .then(sharedWithGroups => {
        this.sharedWithGroups = sharedWithGroups;
      }).finally(() => {
        this.sharedWithGroupsLoading = false;
      });

    this.getSharedWithContacts()
      .then(sharedWithContacts => {
        this.sharedWithContacts = sharedWithContacts;
      }).finally(() => {
        this.sharedWithContactsLoading = false;
      });
  }

  onBack(): void {
    this.modalController.dismiss();
  }

  setActiveTab(tab: CustomTab): void {
    this.activeTabId = tab.id;
  }

  private generateTabs(): void {

    this.customTabs = [{
      id: TabIds.CONTACTS,
      text: this.appTranslationService.instant('app.dialogs.ClinicalCaseSharedWithDialogComponent.contacts'),
      active: true
    },
    {
      id: TabIds.GROUPS,
      text: this.appTranslationService.instant('app.dialogs.ClinicalCaseSharedWithDialogComponent.groups'),
      active: false
    }];

    this.activeTabId = TabIds.CONTACTS;
  }

  getSharedWithGroups(start: number = 0): Promise<Array<SharedWithGroup>> {

    return this.clinicalCaseService.getSharedWith(this.caseId, start, this.count, TabIds.GROUPS)
      .then((sharedWith: GetSharedWithSuccessResponse) => {
        this.totalSharedWithGroups = sharedWith.totalItemsCount;
        return sharedWith.result as Array<SharedWithGroup>;
      });
  }

  loadMoreSharedWithGroups(): void {

    if (this.sharedWithGroups.length >= this.totalSharedWithGroups) {
      this.disableInfiniteScrollSharedWithGroups(true);
      return;
    }

    const start = Math.floor(this.sharedWithGroups.length / this.count);

    this.getSharedWithGroups(start).then((sharedWithGroups) => {
      this.sharedWithGroups = [...this.sharedWithGroups, ...sharedWithGroups];
    }).catch(err => {
      this.showError(err.message);
    }).finally(() => {

      // Hide Infinite List Loader on Complete
      this.infiniteScrollSharedWithGroups.complete();
    });
  }

  getSharedWithContacts(start: number = 0): Promise<Array<SharedWithContact>> {

    return this.clinicalCaseService.getSharedWith(this.caseId, start, this.count, TabIds.CONTACTS)
      .then((sharedWith: GetSharedWithSuccessResponse) => {
        this.totalSharedWithContacts = sharedWith.totalItemsCount;
        return sharedWith.result as Array<SharedWithContact>;
      });
  }

  loadMoreSharedWithContacts(): void {

    if (this.sharedWithContacts.length >= this.totalSharedWithContacts) {
      this.disableInfiniteScrollSharedWithContacts(true);
      return;
    }

    const start = Math.floor(this.sharedWithContacts.length / this.count);

    this.getSharedWithContacts(start).then((sharedWithContacts) => {
      this.sharedWithContacts = [...this.sharedWithContacts, ...sharedWithContacts];
    }).catch(err => {
      this.showError(err.message);
    }).finally(() => {

      // Hide Infinite List Loader on Complete
      this.infiniteScrollSharedWithContacts.complete();
    });
  }

  openPublicProfile(userId: string): void {
    this.appNavController.openPublicUserProfile(userId);
    this.modalController.dismiss();
  }

  openGroup(groupId: string): void {
    this.appNavController.openGroup(groupId);
    this.modalController.dismiss();
  }

  openGroupCase(caseId: string): void {
    this.appNavController.openGroupCase(caseId);
    this.modalController.dismiss();
  }

  openContact(connectionId: string): void {
    this.appNavController.openMessenger(connectionId);
    this.modalController.dismiss();
  }

  refreshSharedWithContacts(): void {

    this.sharedWithContacts = [];

    this.getSharedWithContacts()
      .then(sharedWithContacts => {
        this.sharedWithContacts = sharedWithContacts;
      }).finally(() => {
        this.sharedWithContactsRefresher.complete();
        this.disableInfiniteScrollSharedWithContacts(false);
        this.sharedWithContactsLoading = false;
      });
  }

  refreshSharedWithGroups(): void {

    this.sharedWithGroups = [];

    this.getSharedWithGroups()
      .then(sharedWithGroups => {
        this.sharedWithGroups = sharedWithGroups;
      }).finally(() => {
        this.sharedWithGroupsRefresher.complete();
        this.disableInfiniteScrollSharedWithGroups(false);
        this.sharedWithGroupsLoading = false;
      });
  }

  get showSharedWithContactsEmptyState(): boolean {
    return !this.sharedWithContactsLoading && (!this.sharedWithContacts || !this.sharedWithContacts.length);
  }

  get showSharedWithGroupsEmptyState(): boolean {
    return !this.sharedWithGroupsLoading && (!this.sharedWithGroups || !this.sharedWithGroups.length);
  }

  private disableInfiniteScrollSharedWithGroups(value: boolean): void {
    if (this.infiniteScrollSharedWithGroups) {
      this.infiniteScrollSharedWithGroups.disabled = value;
    }
  }

  private disableInfiniteScrollSharedWithContacts(value: boolean): void {
    if (this.infiniteScrollSharedWithContacts) {
      this.infiniteScrollSharedWithContacts.disabled = value;
    }
  }

  private showError(msg: string, toastHeaderErrorId?: string): void {
    toastHeaderErrorId = toastHeaderErrorId ? toastHeaderErrorId : 'app.common.error-default';
    this.toast.showWithMessage(msg, toastHeaderErrorId, ToastMode.ERROR);
  }

}
