import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Group } from '../../yeti-protocol/chatter-api';
import { CreateEditGroupComponent } from 'src/app/dialogs/create-edit-group/create-edit-group.component';

@Injectable({
  providedIn: 'root'
})
export class CreateGroupService {
  constructor(
    private modalController: ModalController
  ) {}

    async showCreateEditGroupDialog(group?: Group): Promise<boolean> {
    return this.modalController.create({
      component: CreateEditGroupComponent,
      cssClass: 'create-group-dialog',
      componentProps: {editMode: group ? true : false, group}
    })
      .then(modalEl => {
        modalEl.present();
        return modalEl.onDidDismiss();
      })
      .then(resultData => {
        if (resultData.role === 'confirm') {
          return true;
        }
      })
  }
}
