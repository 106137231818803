import { Injectable } from '@angular/core';
import { OrcIdAuthDeviceServiceInterface } from './orcid-auth-device.service.interface';
import { LocationService } from '../../location.service';

@Injectable()
export class OrcIdAuthBrowserService implements OrcIdAuthDeviceServiceInterface {

  constructor(
    public locationService: LocationService,
  ) { }

  signIn(orcIdOAuthUrl: string): Promise<any> {
    this.locationService.href = orcIdOAuthUrl;
    return Promise.resolve();
  }
}
