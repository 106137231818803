import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-mi-events-search-input',
  templateUrl: './events-search-input.component.html',
  styleUrls: ['./events-search-input.component.scss'],
})
export class EventsSearchInputComponent {
  // TODO: add clear-button

  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() resetSearch: EventEmitter<string> = new EventEmitter();

  @ViewChild('f', {static: true}) f: NgForm;

  onSearch(f: NgForm): void {
    if(f.invalid){
      return;
    }
    this.search.next(f.value.keywords);
  }

  onClear(f: NgForm): void {
    f.setValue({keywords: ''});
    this.resetSearch.next('reset');
  }

  get disableSubmitButton(): boolean {
    return this.f.invalid || this.f.value?.keywords?.length < 3;
  }

}
