import { InjectionToken } from '@angular/core';
import { Routes } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ContextConfigInterface } from 'src/config/config.model';

import { UserProfile } from '../yeti-protocol/auth/mi';

export interface UserContexts {
  mainContext: ContextConfigInterface;
  addedContexts: Array<ContextConfigInterface>;
  availableForAddingContexts: Array<ContextConfigInterface>;
}

export enum OpenCloseContextSwitchMenu {
  open = 'open',
  close = 'close'
}

export interface ContextSwitchService {
  upcomingContext: ContextConfigInterface;
  userContextsUpdated: Subject<void>;
  onOpenCloseContextSwitchMenuCommand(): Observable<OpenCloseContextSwitchMenu>;

  openContextSwitchMenu(): void;

  closeContextSwitchMenu(): void;

  getUserContexts(userProfile: UserProfile): Promise<UserContexts>;

  contextHasInterests(contextKey: string): Promise<boolean>;

  isContextBelongsToUser(contextKey: string): Promise<boolean>;

  getContextTabRoutes(contextKey: string): Routes;
  getContextRootRoutes(contextKey: string): Routes;
}

export const CONTEXT_SWITCH_SERVICE = new InjectionToken<ContextSwitchService>('context-switch.service');
