import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

// a layer for isolation of DOM objects
@Injectable({
  providedIn: 'root'
})
export class DomUtilsService {

  constructor(@Inject(DOCUMENT) public document: Document){}

  querySelector<E extends Element = Element>(selectors: string): E | null {
    return this.document.querySelector(selectors);
  }

  getElementById(elementId: string): HTMLElement | null {
    return this.document.getElementById(elementId);
  }

  getElementsByClassName(classNames: string): HTMLCollectionOf<Element>{
    return this.document.getElementsByClassName(classNames);
  }
}
