// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep ion-modal.video-transcript-dialog {
  --height: 80%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px var(--mi-light-purple-color), 0 4px 6px -4px var(--mi-light-purple-color);
}

ion-toolbar {
  --ion-toolbar-background: var(--mi-white-color);
  --ion-toolbar-color: var(--mi-grey-color);
}

ion-spinner {
  scale: 3;
  display: block;
  width: 100px;
  margin: 30% auto 0;
}

ion-header::after {
  background-image: none;
}

app-copy-button {
  position: absolute;
  padding: 5px 0px 0px 3px;
}

.ai-powered {
  color: var(--mi-grey-color-2);
  font-size: var(--font-size-regular);
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
