// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --image-size: 43px;
}

app-mi-card {
  --mi-card-margin-top: 0;
  --mi-card-margin-bottom: 0;
  --mi-card-margin-start: 0;
  --mi-card-margin-end: 0;
  --mi-card-padding-top: 10px;
  --mi-card-padding-bottom: 10px;
  --mi-card-padding-start: 10px;
  --mi-card-padding-end: 10px;
}

.card-wrap {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 4fr 2fr 0.5fr;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.card-wrap .image-container app-surgery-reference-image {
  --surgery-reference-image-size: var(--image-size);
}
.card-wrap .title-container {
  margin-left: 10px;
  color: var(--mi-grey-color-13);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.card-wrap .chip-container {
  text-align: right;
}
.card-wrap .chip-container app-text-chip {
  display: inline-block;
  max-width: 100%;
  --font-size: var(--font-size-small);
}
.card-wrap .chip-container app-text-chip.fracture {
  --color: var(--mi-grey-color-18);
  --background-color: var(--mi-grey-color-19);
}
.card-wrap .chip-container app-text-chip.treatment {
  --color: var(--mi-green-color-4);
  --background-color: var(--mi-green-color-5);
}
.card-wrap .chip-container app-text-chip.approach {
  --color: var(--mi-orange-color-3);
  --background-color: var(--mi-orange-color-4);
}
.card-wrap .chip-container app-text-chip.preparation {
  --color: var(--mi-purple-color-6);
  --background-color: var(--mi-purple-color-7);
}
.card-wrap .remove-btn-container {
  justify-self: end;
  cursor: pointer;
}
.card-wrap .remove-btn-container app-icon {
  color: var(--mi-grey-color-13);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
