import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ao-ota-classification-text',
  templateUrl: './ao-ota-classification-text.component.html',
  styleUrls: ['./ao-ota-classification-text.component.scss'],
})
export class AoOtaClassificationTextComponent  implements OnInit {
  @Input() aoClassificationText: string;

  constructor() { }

  ngOnInit(): void {}

}
