import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { FeaturedCaseObject } from '../../services/yeti-protocol/my-stream';
import { AuthService } from '../../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from '../../services/utils/ui-utils.service.interface';
import { ActionSource } from '../../services/yeti-protocol/tracking';
import { ContextService, CONTEXT_SERVICE } from '../../services/context/context.model';
import { NavControllerService } from '../../services/nav-controller.service';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { ClinicalCase } from '../../services/yeti-protocol/clinical-case';
import { ClinicalCaseUIService } from 'src/app/services/case-library/clinical-case.ui.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from '../../services/dialogs/dialogs.ui.interface';
import { BaseUserProfile, NUMBER_USER_IMG_BUBBLES_CARDS } from 'src/app/modules/users/users.model';
import { VerificationStatus } from 'src/app/services/verification.model';
import { VerificationService } from 'src/app/services/verification.service';
import { LikeUnlikeSuccessResponse, PostType} from 'src/app/services/yeti-protocol/chatter-api';
import { getImagesWithCorrectUrlBasedOnWatermark } from '../../services/utils/image-attachments.utils';
import { ImageAttachment } from '../../services/attachments.model';

@Component({
  selector: 'app-featured-case-card',
  templateUrl: './featured-case-card.component.html',
  styleUrls: ['./featured-case-card.component.scss'],
})
export class FeaturedCaseCardComponent implements OnInit, OnDestroy {

  @Input() featuredCaseObject: FeaturedCaseObject;
  @Input() source: ActionSource;

  applaudStatusChanging: boolean;

  protected user: UserProfile;

  private userSubscription: Subscription;
  private clinicalCaseUpdatedSubscription: Subscription;

  constructor(
    private authService: AuthService,
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private navController: NavControllerService,
    private clinicalCaseService: ClinicalCaseService,
    private clinicalCaseUIService: ClinicalCaseUIService,
    private toast: ToastService,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
    private verificationService: VerificationService
  ) { }

  ngOnInit(): void {
    this.userSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });

    this.clinicalCaseUpdatedSubscription = this.clinicalCaseService.clinicalCaseUpdatedObservable
      .subscribe(clinicalCase => this.onClinicalCaseUpdated(clinicalCase));
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.clinicalCaseUpdatedSubscription?.unsubscribe();
  }

  get isCaseOwner(): boolean {
    return this.featuredCaseObject?.caseDiscussion?.owner?.userId === this.user?.id;
  }

  async openCase(event: Event): Promise<void> {

    if (event) {
      this.uiUtilsService.stopEventPropagation(event);
    }

    try {
      const isVerified = await this.checkVerificationStatus();

      if (!isVerified) {
        return;
      }

      const context = this.contextService.currentContext.key;

      let url = `/${context}/case/${this.featuredCaseObject?.caseDiscussion?._id}`;

      if (this.source) {
        url = `${url}?source=${this.source}`;
      }

      this.navController.navigateForward(url);
    } catch (err) {
      console.error(err);
    }
  }

  async bookmarkCase(): Promise<void> {

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.featuredCaseObject?.caseDiscussion?._id, PostType.case, this.source);
      return;
    }

    if (!this.featuredCaseObject?.caseDiscussion?.hasBookmarked) {
      this.clinicalCaseService.bookmarkCase(this.featuredCaseObject?.caseDiscussion?._id, this.source)
        .then((responseObj: ClinicalCase) => {
          if (responseObj) {
            this.updateBookmarkFields(responseObj);
          }
        })
        .catch(err => {
          console.error(err);
          this.showError(err?.error?.error?.message?.errfor?.module);
        })
    } else {
      const confirmModal = await this.clinicalCaseUIService.showRemoveBookmarkCaseConfirmDialog();
      const res = await confirmModal.onDidDismiss();

      if (res && res?.data.actionKey === 'yes') {
        this.clinicalCaseService.removeBookmarkedCase(this.featuredCaseObject?.caseDiscussion?._id, this.source)
          .then((responseObj: ClinicalCase) => {
            if (responseObj) {
              this.updateBookmarkFields(responseObj);
            }
          })
          .catch(err => {
            console.error(err);
            this.showError(err?.error?.error?.message?.errfor?.module);
          });
      }
    }
  }

  updateBookmarkFields(obj: ClinicalCase): void {
    this.featuredCaseObject.caseDiscussion.hasBookmarked = obj?.hasBookmarked;
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  get caseImages(): Array<ImageAttachment> {
    if (this.featuredCaseObject?.caseDiscussion) {
      return [
        ...getImagesWithCorrectUrlBasedOnWatermark(
          this.featuredCaseObject?.caseDiscussion?.preOpDocuments || [], this.featuredCaseObject?.caseDiscussion?.watermarked
        ),
        ...getImagesWithCorrectUrlBasedOnWatermark(
          this.featuredCaseObject?.caseDiscussion?.intraOpDocuments || [], this.featuredCaseObject?.caseDiscussion?.watermarked
        ),
        ...getImagesWithCorrectUrlBasedOnWatermark(
          this.featuredCaseObject?.caseDiscussion?.postOpDocuments || [], this.featuredCaseObject?.caseDiscussion?.watermarked
        ),
        ...getImagesWithCorrectUrlBasedOnWatermark(
          this.featuredCaseObject?.caseDiscussion?.clinicalFollowUpDocuments || [], this.featuredCaseObject?.caseDiscussion?.watermarked
        )
      ]
    }
    return [];
  }

  get displayProfileImageBubbles(): boolean {
    return this.featuredCaseObject?.caseDiscussion?.numberOfLikes > 0 &&
      (this.featuredCaseObject?.caseDiscussion?.isFeatured || this.isCaseOwner);
  }

  get isVerificationPendingUser(): boolean {
    return this.user?.verificationStatus === VerificationStatus.PENDING;
  }

  get usersThatApplauded(): Array<BaseUserProfile> {
    if (!this.featuredCaseObject?.caseDiscussion || !this.featuredCaseObject?.caseDiscussion?.usersThatApplauded?.length) {
      return [];
    }

    return this.featuredCaseObject?.caseDiscussion?.usersThatApplauded.slice(0, NUMBER_USER_IMG_BUBBLES_CARDS).map(userThatApplauded => {
      return {
        userId: userThatApplauded.userId,
        profileImageUrl: userThatApplauded.profileImageUrl
      }
    });
  }

  openApplaudsList(): void {
    this.dialogs.showApplaudsList(this.featuredCaseObject?.caseDiscussion?._id, 'case');
  }

  applaudClicked(): void {

    if (this.applaudStatusChanging) {
      return;
    }

    this.applaudStatusChanging = true;

    if (this.featuredCaseObject?.caseDiscussion?.hasLiked) {
      this.clinicalCaseService.unlikeCase(this.featuredCaseObject?.caseDiscussion?._id).then(res => {
        this.featuredCaseObject.caseDiscussion = (res as LikeUnlikeSuccessResponse).result as ClinicalCase;
        this.clinicalCaseService.emitClinicalCaseUpdated(this.featuredCaseObject.caseDiscussion);
      }).finally(() => {
        this.applaudStatusChanging = false;
      });
    } else {
      this.clinicalCaseService.likeCase(this.featuredCaseObject?.caseDiscussion?._id).then(res => {
        this.featuredCaseObject.caseDiscussion = (res as LikeUnlikeSuccessResponse).result as ClinicalCase;
        this.clinicalCaseService.emitClinicalCaseUpdated(this.featuredCaseObject?.caseDiscussion);
      }).finally(() => {
        this.applaudStatusChanging = false;
      });
    }
  }

  onClinicalCaseUpdated(updatedClinicalCase: ClinicalCase): void {
    if (this.featuredCaseObject?.caseDiscussion?._id === updatedClinicalCase?._id) {
      this.featuredCaseObject.caseDiscussion = updatedClinicalCase;
    }
  }

  async checkVerificationStatus(): Promise<boolean> {
    const userVerificationStatus = await this.verificationService.verify();

    if (userVerificationStatus === VerificationStatus.VERIFIED) {
      return true;
    }

    return false;
  }

}
