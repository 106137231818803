import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserImageService {

  userImageUrl = new Subject<string>();

  // Observable string stream
  userImageUrl$ = this.userImageUrl.asObservable();

  // Service message command
  updateUserImage(imageUrl: string): void {
    this.userImageUrl.next(imageUrl);
  }
}
