import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Case } from '../../services/yeti-protocol/chatter-api';
import { ImageAttachment } from 'src/app/services/attachments.model';
import { PersonalMediaGalleryDocument } from 'src/app/services/yeti-protocol/personal-media-gallery';
import { ClinicalCase } from '../../services/yeti-protocol/clinical-case';

@Component({
  selector: 'app-clinical-case-list-item',
  templateUrl: './clinical-case-list-item.component.html',
  styleUrls: ['./clinical-case-list-item.component.scss'],
})
export class ClinicalCaseListItemComponent implements OnInit {

  @Input() case: Case;
  @Input() clinicalCase: ClinicalCase;

  @Output() listItemClicked: EventEmitter<Case | ClinicalCase> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  get caseImages(): Array<ImageAttachment> {

    const preOpAttachments = this.getCaseImagesWithCorrectUrls(
      this.case?.caseDiscussion?.preOpAttachments || this.clinicalCase?.preOpDocuments);

    const intraOpAttachments = this.getCaseImagesWithCorrectUrls(
      this.case?.caseDiscussion?.intraOpAttachments || this.clinicalCase?.intraOpDocuments);

    const postOpAttachments = this.getCaseImagesWithCorrectUrls(
      this.case?.caseDiscussion?.postOpAttachments || this.clinicalCase?.postOpDocuments);

    const clinicalFollowUpAttachments = this.getCaseImagesWithCorrectUrls(
      this.case?.caseDiscussion?.clinicalFollowUpAttachments || this.clinicalCase?.clinicalFollowUpDocuments);

    return [
      ...preOpAttachments,
      ...intraOpAttachments,
      ...postOpAttachments,
      ...clinicalFollowUpAttachments
    ];
  }

  getCaseImagesWithCorrectUrls(images: Array<PersonalMediaGalleryDocument | PersonalMediaGalleryDocument>): Array<ImageAttachment> {

    if (!images?.length) {
      return [];
    }

    if (this.case?.watermarked || this.clinicalCase?.watermarked) {
      return images.map(img => {
        return {
          ...img,
          url: img?.watermarkedPreviewUrl,
          fullImageUrl: img?.fullWatermarkUrl
        };
      })
    } else {
      return images.map(img => {
        return {
          ...img,
          url: img?.previewUrl,
          fullImageUrl: img?.fullUrl
        };
      })
    }
  }

  caseListItemClicked(): void {
    this.listItemClicked.emit(this.case || this.clinicalCase);
  }

}
