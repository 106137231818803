import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'fromDate'
})
export class FromDatePipe implements PipeTransform {

  transform(value: string, ..._args: unknown[]): string {
    return moment(value).fromNow();
  }

}
