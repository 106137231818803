import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrcIdArticle } from '../../services/yeti-protocol/article';

@Component({
  selector: 'app-orcid-articles-list',
  templateUrl: './orcid-articles-list.component.html',
  styleUrls: ['./orcid-articles-list.component.scss'],
})
export class OrcidArticlesListComponent {

  @Input() orcIdArticles: Array<OrcIdArticle>;
  @Input() visibilityRootKey: string;

  @Output() orcIdArticleClick: EventEmitter<OrcIdArticle> = new EventEmitter();

  constructor() { }

  orcIdArticleClicked(orcIdArticle: OrcIdArticle): void {
    this.orcIdArticleClick.emit(orcIdArticle);
  }

}
