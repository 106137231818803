import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

import { FormControl, FormGroup } from '@angular/forms';

import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { ModalController } from '@ionic/angular';
import { FileSelectComponent } from 'src/app/modules/file-select/components/file-select/file-select.component';
import { FileSelectService } from 'src/app/modules/file-select/services/file-select.service';
import { AddUrlTimestampPipe } from 'src/app/pipes/add-url-timestamp.pipe';
import appConfig from 'src/config/config';
import { AuthenticationAOConfig, Platform } from 'src/config/config.model';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { Subject, switchMap, takeUntil } from 'rxjs';
import {
  UserProfileImageUploadComponent
} from '../../modules/users/components/user-profile-image-upload/user-profile-image-upload.component';
import { PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF } from 'src/app/services/auth/mi/mi-auth.service';
import {TRACKING_SERVICE, TrackingService, TrackingSourceParams} from '../../services/tracking/tracking.model';

interface UserSettingsComponentConfig {
  platform: Platform;
  authenticationAO: AuthenticationAOConfig;
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})

export class UserSettingsComponent implements OnInit {

  @ViewChild('fileSelectImage') fileSelectImage: FileSelectComponent;
  @ViewChild('userProfileImageUpload') userProfileImageUploadComponent: UserProfileImageUploadComponent;

  config: UserSettingsComponentConfig = appConfig;

  userForm = new FormGroup({
    academicTitlePre: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    academicTitlePost: new FormControl(''),
    birthdate: new FormControl(''),
    graduation: new FormControl(''),
  });

  imageUrl: string = null;

  profile: any;

  isDataLoaded = false;
  maxBirthdayDate: string;

  private unsubscribe = new Subject<void>();

  constructor(
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private modalController: ModalController,
    private fileSelectService: FileSelectService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) {
  }

  ngOnInit(): void {
    this.authService.getProfile(
      this.contextService.currentContext.key,
      true,
      PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF).then((userProfile: UserProfile) => {
        this.profile = userProfile;
        this.setFormValues();
      });

    this.userForm.valueChanges.pipe(
      switchMap(async (formValue) => {
        if (!this.isDataLoaded) {
          return;
        }
        if (this.profile.birthdate === formValue.birthdate
          && this.profile.graduation === formValue.graduation) {
          return
        }
        if (this.profile.birthdate !== formValue.birthdate) {
          this.profile.birthdate = formValue.birthdate.match('(.*)?T') ?
            formValue.birthdate.match('(.*)?T')[1] : formValue.birthdate;
        } else if (this.profile.graduation !== formValue.graduation) {
          // ensure we sent year only to backend.
          // the value of the ion-datetime field taken from the form will be a FULL DATE and not just the Year
          this.profile.graduation = this.getGraduationYear;
        } else {
          return;
        }
      }),
      takeUntil(this.unsubscribe)
    ).subscribe();

    this.maxBirthdayDate = new Date().toISOString();
    this.triggerFormValidation();
  }

  onClose(): void {
    this.modalController.dismiss(this.profile);
  }

  setFormValues(): void {
    if (this.profile) {
      this.setImageUrl();
      this.userForm.patchValue({
        academicTitlePre: this.profile.academicTitlePre,
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        academicTitlePost: this.profile.academicTitlePost,
        birthdate: this.profile.birthdate,
        graduation: this.profile.graduation
      });
    }
  }

  triggerFormValidation(): void {
    this.userForm.get('firstName').markAsTouched();
    this.userForm.get('lastName').markAsTouched();
  }

  onUploadPicture(): void {
    this.userProfileImageUploadComponent.onChangeProfileImageClick();
  }

  onImageUploaded(): void {
    this.authService.getProfile(this.contextService.currentContext.key, true).then((userProfile: UserProfile) => {
      this.profile = userProfile;
      this.setImageUrl();
    });
  }

  get getGraduationYear(): string {
    let graduationYear: string;
    graduationYear = 'N/A';
    if (this.userForm.value.graduation) {
      if (this.userForm.value.graduation.indexOf('-') > 0) {
        graduationYear = new Date(this.userForm.value.graduation).getFullYear().toString();
      } else {
        graduationYear = this.userForm.value.graduation;
      }
    }
    return graduationYear;
  }

  get defaultImageMimeTypes(): string {
    return this.fileSelectService?.defaultImageMimeTypes.join(',');
  }

  get getProfileBirthday(): string {
    return this.profile.birthdate.match('(.*)?T') ? this.profile.birthdate.match('(.*)?T')[1] : this.profile.birthdate;
  }

  setImageUrl(): void {
    const timeStampPipe = new AddUrlTimestampPipe();
    this.imageUrl = timeStampPipe.transform(this.profile.profileImageUrl);
  }

  isInputInvlaid(controlName: string): boolean {
    const control = this.userForm.get(controlName);
    return control.touched && control.invalid;
  }

  get isFormValid(): boolean {
    if (this.userForm.valid) {
      const firstName = this.userForm.get('firstName')?.value;
      if (!firstName || firstName.trim().length === 0) {
        return false;
      }
      const lastName = this.userForm.get('lastName')?.value;
      if (!lastName || lastName.trim().length === 0) {
        return false;
      }
      return true;
    }
    return false;
  }

  editPersonalDetails(): void {
    this.trackClickAction('editPersonalDetails', 'outlink', 'aoProfilePage')
    if (!this.config.authenticationAO) {
      return;
    }
    const AOLink = this.config.authenticationAO.updateUserDataAoLink;
    if (AOLink) {
      this.authService.openAoLink(AOLink);
    }

  }

  trackClickAction(objectId: string, objectType: string, objectTitle: string): void {
    const sourceParams: TrackingSourceParams = {
      source: 'editPersonalInformationModule',
    };
    this.trackingService
      .trackGenericClickedAction(
        objectId,
        objectType,
        objectTitle,
        sourceParams
      )
      .catch(_err => {
        console.error('Could not track ' + objectId + ' clicked action');
      });
  }
}
