import { Injectable } from '@angular/core';

import { AppTranslationService } from '../app-translation.service';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from '../dialogs/dialogs.ui.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClinicalCaseUIService {

  casesListChanged: Subject<void> = new Subject();

  constructor(
    private appTranslationService: AppTranslationService,
    private dialogs: DialogsUIService
  ) {
  }

  triggerCasesListChanged(): void {
    this.casesListChanged.next();
  }

  showDeleteCaseConfirmDialog(): Promise<HTMLIonModalElement> {
    const acceptActionKey = 'yes';
    const goBackActionKey = 'no';

    return this.appTranslationService.get([
      'app.pages.CaseLibraryPage.deleteDialogTitle',
      'app.pages.CaseLibraryPage.deleteDialogText',
      'app.common.yes',
      'app.common.no'
    ]).then(translation => {
      const confirmDialogData: ConfirmDialogData = {
        componentProps: {
          title: {
            translationKey: translation['app.pages.CaseLibraryPage.deleteDialogTitle']
          },
          message: {
            translationKey: translation['app.pages.CaseLibraryPage.deleteDialogText']
          },
          actions: [
            {
              key: goBackActionKey,
              label: {
                translationKey: translation['app.common.no']
              },
              className: 'secondary'
            },
            {
              key: acceptActionKey,
              label: {
                translationKey: translation['app.common.yes']
              },
              className: 'primary'
            }
          ]
        }
      };
      return this.dialogs.createConfirmDialog(confirmDialogData);
    }).then(confirmModal => {
      confirmModal.present();
      return confirmModal;
    });
  }

  async showRemoveBookmarkCaseConfirmDialog(): Promise<HTMLIonModalElement> {
    const dialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.pages.CaseLibraryPage.remove-bookmark-title'
        },
        message: {
          translationKey: 'app.pages.CaseLibraryPage.remove-bookmark-message'
        },
        actions: [
          {
            key: 'no',
            label: { translationKey: 'app.common.cancel' },
            className: 'secondary'
          },
          {
            key: 'yes',
            label: { translationKey: 'app.common.remove' },
            className: 'primary'
          },
        ]
      }
    };

    const confirmModal = await this.dialogs.createConfirmDialog(dialogData);
    confirmModal.present();
    return confirmModal;
  }


}
