import { PostListItem } from 'src/app/services/yeti-protocol/chatter-api';

export namespace Posts {

  export class AddNewPost {
    static readonly type = '[Posts] Add new post';
    constructor(public post: PostListItem) { }
  }

  export class UpdatePost {
    static readonly type = '[Posts] Update post';
    constructor(public post: PostListItem) { }
  }

  export class DeletePost {
    static readonly type = '[Posts] Delete post';
    constructor(public post: PostListItem) { }
  }

  export class UpdatePostsOwnerFollowingStatus {
    static readonly type = '[Posts] Update posts owner following status';
    constructor(public userId: string, public isFollower: boolean) { }
  }
}
