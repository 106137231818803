// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --surgery-reference-padding-start: 15px;
  --surgery-reference-padding-end: 15px;
  --surgery-reference-padding-top: 0px;
  --surgery-reference-padding-bottom: 45px;
}

.surgery-reference-dialog {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
}
.surgery-reference-dialog .header {
  grid-row: 1;
  grid-column: 1;
}
.surgery-reference-dialog .header app-top-bar .action-button {
  background: none;
  outline: none;
  cursor: pointer;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-13);
}
.surgery-reference-dialog .content {
  grid-row: 2;
  grid-column: 1;
  padding-top: var(--surgery-reference-padding-top);
}
.surgery-reference-dialog .content ion-content {
  --padding-bottom: var(--surgery-reference-padding-bottom);
}

.treatment-select-page-container .content app-vertical-list .wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 26px;
}
.treatment-select-page-container .content app-vertical-list .wrapper .added-modules-container {
  grid-row: 1;
  grid-column: 1;
  padding-top: 36px;
  padding-bottom: 23px;
  padding-left: var(--surgery-reference-padding-start);
  padding-right: var(--surgery-reference-padding-end);
  background-color: var(--mi-light-purple-1-color);
}
.treatment-select-page-container .content app-vertical-list .wrapper .main-content {
  height: 100%;
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 20px;
}
.treatment-select-page-container .content app-vertical-list .wrapper .main-content.list-rendered {
  min-height: 400px;
}
.treatment-select-page-container .content app-vertical-list .wrapper .main-content .list-title-container {
  grid-row: 1;
  grid-column: 1;
  padding-left: var(--surgery-reference-padding-start);
  padding-right: var(--surgery-reference-padding-end);
}
.treatment-select-page-container .content app-vertical-list .wrapper .main-content .list-title-container .list-title {
  font-size: var(--font-size-large-1);
  font-weight: var(--font-weight-semi-bold);
  color: var(--mi-grey-color-16);
  margin: 0;
}
.treatment-select-page-container .content app-vertical-list .wrapper .main-content .list-container {
  grid-row: 2;
  grid-column: 1;
}
.treatment-select-page-container .content app-vertical-list .wrapper .main-content .list-container .treatments-list {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  row-gap: 17px;
  padding-left: var(--surgery-reference-padding-start);
  padding-right: var(--surgery-reference-padding-end);
}
.treatment-select-page-container .content app-vertical-list .wrapper .main-content .list-container .treatments-list app-surgery-reference-select-card:last-child {
  padding-bottom: var(--surgery-reference-padding-bottom);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
