import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {SelectedFile} from '../../modules/file-select/file-select.model';

@Component({
  selector: 'app-drag-and-drop-upload',
  templateUrl: './drag-and-drop-upload.component.html',
  styleUrls: ['./drag-and-drop-upload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule
  ]
})

export class DragAndDropUploadComponent {
  @Input() isUploadAllowed = false;
  @Output() filesDropped = new EventEmitter<SelectedFile[]>();

  isDragging = false;

  constructor() {}

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    if (this.isUploadAllowed) {
      this.isDragging = true;
    }
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;

    if (this.isUploadAllowed && event.dataTransfer?.files?.length) {
      const selectedFiles: SelectedFile[] = Array.from(event.dataTransfer.files).map(file => ({
        file,
        fileName: file.name
      }));
      this.filesDropped.emit(selectedFiles);
    }
  }
}
