export enum ContextKey {
  cmf = 'cmf',
  orthopedics = 'orthopedics',
  spine = 'spine',
  vet = 'vet',
  recon = 'recon',
  dental = 'dental',
  ort = 'ort',
  trauma = 'trauma'
}

// workaround for using 'orthopedics' for server calls from 'trauma' context
export function contextForServer(contextKey: string): string {
  return contextKey === ContextKey.trauma ? ContextKey.orthopedics : contextKey;
}

export function contextForClient(contextKey: string): string {
  return contextKey === ContextKey.orthopedics ? ContextKey.trauma : contextKey;
}
