// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-content-parent-options-container ion-list ion-radio-group {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 25px;
  align-items: start;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --ripple-color: transparent;
  --background-activated: transparent;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 17px;
       column-gap: 17px;
  align-items: center;
  background-color: var(--mi-background-color);
  border-radius: 10px;
  padding: 15px 20px;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option.selected {
  background-color: var(--mi-blue-color-4);
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option .icon-container {
  grid-row: 1;
  grid-column: 1;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option .icon-container app-icon {
  --icon-size: 27px;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option .text-container {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  overflow: hidden;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option .text-container .option-text {
  width: 100%;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option .text-container .option-description {
  width: 100%;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option .action-container {
  grid-row: 1;
  grid-column: 3;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option .action-container app-icon-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: none;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0%;
  --icon-button-icon-size: 16px;
  --icon-button-icon-color: var(--mi-black-color);
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option .action-container ion-radio {
  --color-checked: var(--mi-primary-color);
  --color: var(--mi-color-text-lite);
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option.groups-and-events .action-container {
  display: flex;
}
.create-content-parent-options-container ion-list ion-radio-group ion-item .option.groups-and-events .action-container ion-radio {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
