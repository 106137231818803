import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface CustomTab {
  id: string,
  text: string,
  active: boolean,
  disabled?: boolean,
  notificationsCount?: number
}

@Component({
  selector: 'app-custom-tabs',
  templateUrl: './custom-tabs.component.html',
  styleUrls: ['./custom-tabs.component.scss'],
})
export class CustomTabsComponent {

  @Input() tabs: Array<CustomTab>;

  @Output() tabClicked: EventEmitter<CustomTab> = new EventEmitter();

  tabClick(tab: CustomTab): void {

    if (tab?.disabled) {
      return;
    }

    this.tabs.forEach(t => {
      t.active = (t.id === tab.id);
    });
    this.tabClicked.emit(tab);
  }

}
