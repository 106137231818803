import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { CaseClassificationFields } from '../case-classification-tags-selection/case-classification-tags-selection.model';
import appConfig from 'src/config/config';
import { Platform } from 'src/config/config.model';

const preventTextOverflow = false;

interface TopicSelectConfig {
  platform: Platform;
}

@Component({
  selector: 'app-topic-select',
  templateUrl: './topic-select.component.html',
  styleUrls: ['./topic-select.component.scss'],
})
export class TopicSelectComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @ViewChild('topicSelectContainer') topicSelectContainer: ElementRef;
  @ViewChild('topicDropdownContainer') topicDropdownContainer: ElementRef;
  @ViewChild('topicOneFilterInput') topicOneFilterInput: ElementRef;
  @ViewChild('topicTwoFilterInput') topicTwoFilterInput: ElementRef;
  @ViewChild('inputFieldsContainer') inputFieldsContainer: ElementRef;
  @ViewChild('separatorContainer') separatorContainer: ElementRef;

  @Input() primaryTopic: string;
  @Input() secondaryTopic: string;
  @Input() disabled = false;
  @Input() warnBeforeTopicsChanged: boolean;

  @Output() caseClassificationTagsChanged: EventEmitter<CaseClassificationFields> = new EventEmitter();

  config: TopicSelectConfig = appConfig;
  primaryTopicFilter: string;
  secondaryTopicFilter: string;
  expanded = false;
  preventTextOverflow = preventTextOverflow;

  private appClickEventSubscription: Subscription;

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private appTranslationService: AppTranslationService,
    private ref: ChangeDetectorRef,
    private dialogs: DialogsUIService,
    private toast: ToastService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.appClickEventSubscription = this.uiUtilsService.appClickEventObservable.subscribe((event: MouseEvent) => {
      this.onAppClick(event);
    });
  }

  ngAfterViewInit(): void {
    this.ref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (
      changes?.secondaryTopic &&
      changes?.secondaryTopic?.currentValue &&
      changes?.secondaryTopic?.currentValue !== changes?.secondaryTopic?.previousValue) {
      this.expanded = false;
    }

    if (
      changes?.primaryTopic &&
      changes?.primaryTopic?.currentValue &&
      changes?.primaryTopic?.currentValue !== changes?.primaryTopic?.previousValue) {
      this.checkShouldFocusSecondaryTopicFilterField();
    }

    if (
      changes?.secondaryTopic &&
      !changes?.secondaryTopic?.currentValue &&
      changes?.secondaryTopic?.previousValue) {
      setTimeout(() => {
        this.checkShouldFocusSecondaryTopicFilterField();
      }, 200);
    }
  }

  ngOnDestroy(): void {
    this.appClickEventSubscription?.unsubscribe();
  }

  checkShouldFocusSecondaryTopicFilterField(): void {
    if (this.responsiveUtilsService.isDesktop &&
      this.config.platform !== Platform.ANDROID &&
      this.config.platform !== Platform.IOS) {
      this.el?.nativeElement?.querySelector('.topic-two-filter-input')?.focus();
    }
  }

  onAppClick(ev: MouseEvent): void {

    const clickX = ev.clientX;
    const clickY = ev.clientY;

    const inputFieldContainerBoundingRect = this.topicSelectContainer?.nativeElement?.getBoundingClientRect();
    const inputFieldContainerLeft = inputFieldContainerBoundingRect.left;
    const inputFieldContainerRight = inputFieldContainerBoundingRect.right;
    const inputFieldContainerTop = inputFieldContainerBoundingRect.top;
    const inputFieldContainerBottom = inputFieldContainerBoundingRect.bottom;

    const dropdownContainerBoundingRect = this.topicDropdownContainer?.nativeElement?.getBoundingClientRect();
    const dropdownContainerLeft = dropdownContainerBoundingRect.left;
    const dropdownContainerRight = dropdownContainerBoundingRect.right;
    const dropdownContainerTop = dropdownContainerBoundingRect.top;
    const dropdownContainerBottom = dropdownContainerBoundingRect.bottom;

    let isClickWithinInputField = true;
    let isClickWithinDropDown = true;

    if (clickX < inputFieldContainerLeft ||
      clickX > inputFieldContainerRight ||
      clickY < inputFieldContainerTop ||
      clickY > inputFieldContainerBottom) {
      isClickWithinInputField = false;
    }

    if (clickX < dropdownContainerLeft ||
      clickX > dropdownContainerRight ||
      clickY < dropdownContainerTop ||
      clickY > dropdownContainerBottom) {
      isClickWithinDropDown = false;
    }

    if (!isClickWithinInputField && !isClickWithinDropDown) {
      this.expanded = false;
    }
  }

  async inputFieldClicked(event: Event): Promise<void> {

    if (this.disabled) {
      return;
    }

    if (this.warnBeforeTopicsChanged && this.secondaryTopic?.length) {
      try {
        const shouldProceed = await this.warnUserBeforeTopicsAreChanged();

        if (!shouldProceed) {
          return;
        }
      } catch (err) {
        console.error(err);
        this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
        return;
      }
    }

    if (!this.primaryTopic || !this.primaryTopic?.length) {
      this.topicOneFilterInput?.nativeElement.focus();
      this.uiUtilsService.stopEventPropagation(event);
      this.expanded = true;
    } else if (!this.secondaryTopic || !this.secondaryTopic?.length) {
      this.topicTwoFilterInput?.nativeElement.focus();
      this.uiUtilsService.stopEventPropagation(event);
      this.expanded = true;
    } else {
      this.expanded = true;
    }
  }

  emitCaseClassificationTagsChanged(caseClassificationFields: CaseClassificationFields): void {
    this.caseClassificationTagsChanged.emit(caseClassificationFields);

    if (caseClassificationFields.classificationLevelOne && caseClassificationFields.classificationLevelOne.length > 0) {
      this.primaryTopicFilter = '';
    }

    if (caseClassificationFields.classificationLevelTwo && caseClassificationFields.classificationLevelTwo.length > 0) {
      this.secondaryTopicFilter = '';
    }
  }

  get primaryTopicFilterTextLength(): number {
    return this.primaryTopicFilter && this.primaryTopicFilter?.length ? this.primaryTopicFilter?.length : 0;
  }

  get inputFieldMaxWidth(): string {
    const inputFieldsWidth = this.inputFieldsContainer?.nativeElement?.clientWidth || 0;
    const separatorWidth = this.separatorContainer?.nativeElement?.clientWidth || 0;

    if (!inputFieldsWidth || !separatorWidth) {
      return null;
    }

    return `${(inputFieldsWidth - separatorWidth) / 2}px`;

  }

  async toggleExpanded(): Promise<void> {

    if (this.disabled) {
      return;
    }

    if (this.warnBeforeTopicsChanged && this.secondaryTopic?.length && !this.expanded) {
      try {
        const shouldProceed = await this.warnUserBeforeTopicsAreChanged();

        if (!shouldProceed) {
          return;
        }
      } catch (err) {
        console.error(err);
        this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
        return;
      }
    }

    this.expanded = !this.expanded;
  }

  get secondaryTopicFilterInputPlaceholder(): string {
    return this.disabled ? '' : this.appTranslationService.instant('app.components.TopicSelectComponent.filter');
  }

  get primaryTopicSelected(): boolean {
    return this.primaryTopic && this.primaryTopic?.length > 0;
  }

  get secondaryTopicSelected(): boolean {
    return this.secondaryTopic && this.secondaryTopic?.length > 0;
  }

  async warnUserBeforeTopicsAreChanged(): Promise<boolean> {
    const okayActionKey = 'okay';
    const cancelActionKey = 'cancel';

    const warnUserBeforeTopicsAreChangedConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.components.TopicSelectComponent.warning-topic-change-title'
        },
        message: {
          translationKey: 'app.components.TopicSelectComponent.warning-topic-change-text'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.cancel',
            },
            className: 'secondary'
          },
          {
            key: okayActionKey,
            label: {
              translationKey: 'app.common.okay',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(warnUserBeforeTopicsAreChangedConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === okayActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

}
