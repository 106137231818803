export enum Translator {
  google = 'google'
}

export interface TranslatorLanguage {
  name: string,
  key: string
}

export const Translators = {
    google: {
      name: 'Google',
      langs: [
        // { name: 'Afrikaans', key : 'af'},
        // { name: 'Albanian', key : 'sq'},
        { name: 'Arabic', key: 'ar' },
        // { name: 'Azerbaijani', key : 'az'},
        // { name: 'Basque', key : 'eu'},
        // { name: 'Bengali', key : 'bn'},
        // { name: 'Belarusian', key : 'be'},
        // { name: 'Bulgarian', key : 'bg'},
        // { name: 'Catalan', key : 'ca'},
        { name: 'Chinese Simplified', key: 'zh-CN' },
        { name: 'Chinese Traditional', key: 'zh-TW' },
        // { name : 'Croatian', key : 'hr'},
        // { name : 'Czech", key : 'cs'},
        // { name : 'Danish", key : 'da'},
        // { name : 'Dutch", key : 'nl'},
        { name : 'English', key : 'en'},
        // { name : 'Esperanto", key : 'eo'},
        // { name : 'Estonian", key : 'et'},
        // { name : 'Filipino", key : 'tl'},
        // { name : 'Finnish", key : "fi"},
        { name: 'French', key: 'fr' },
        // { name : 'Galician', key : 'gl'},
        // { name : 'Georgian', key : 'ka'},
        { name: 'German', key: 'de' },
        // { name : 'Greek', key : 'el'},
        // { name : 'Gujarati', key : 'gu'},
        // { name : 'Haitian Creole', key : 'ht'},
        // { name : 'Hebrew', key : 'iw'},
        // { name : 'Hindi', key : 'hi'},
        { name : 'Hungarian', key : 'hu'},
        // { name : 'Icelandic', key : 'is'},
        // { name : 'Indonesian', key : 'id'},
        // { name : 'Irish', key : 'ga'},
        { name: 'Italian', key: 'it' },
        { name: 'Japanese', key: 'ja' },
        // { name : 'Kannada', key : 'kn'},
        { name: 'Korean', key: 'ko' },
        // { name : 'Latin', key : 'la'},
        // { name : 'Latvian', key : 'lv'},
        // { name : 'Lithuanian', key : 'lt'},
        // { name : 'Macedonian', key : 'mk'},
        // { name : 'Malay', key : 'ms'},
        // { name : 'Maltese', key : 'mt'},
        // { name : 'Norwegian', key : 'no'},
        // { name : 'Persian', key : 'fa'},
        { name : 'Polish', key : 'pl'},
        { name: 'Portuguese', key: 'pt' },
        { name : 'Romanian', key : 'ro'},
        { name: 'Russian', key: 'ru' },
        // { name : 'Serbian', key : 'sr'},
        // { name : 'Slovak', key : 'sk'},
        // { name : 'Slovenian', key : 'sl'},
        { name: 'Spanish', key: 'es' },
        // { name : 'Swahili', key : 'sw'},
        // { name : 'Swedish', key : 'sv'},
        // { name : 'Tamil', key : 'ta'},
        // { name : 'Telugu', key : 'te'},
        // { name : 'Thai', key : 'th'},
        { name: 'Turkish', key: 'tr' },
        { name : 'Ukrainian', key : 'uk'},
        // { name : 'Urdu', key : 'ur'},
        // { name : 'Vietnamese', key : 'vi'},
        // { name : 'Welsh', key : 'cy'},
        // { name : 'Yiddish', key : 'yi'}
      ]
    }
  }
