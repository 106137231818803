import { Component, Input, OnInit } from '@angular/core';
import { IonInfiniteScroll, IonRefresher, ModalController } from '@ionic/angular';

// services
import { PublicProfileService } from 'src/app/services/public-profile.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';

// models
import { AOEvent, SharedEventsResponse } from 'src/app/services/yeti-protocol/event';
import { TabIds } from 'src/app/components/events-section/events-section.model';
import { CustomTab } from 'src/app/components/custom-tabs/custom-tabs.component';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { EventsService } from 'src/app/services/events/events.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-events-section-dialog',
  templateUrl: './events-section-dialog.component.html',
  styleUrls: ['./events-section-dialog.component.scss'],
})
export class EventsSectionDialogComponent implements OnInit {
  @Input() userId: string;

  customTabs: Array<CustomTab> = [];
  activeTabId: string;
  loading: boolean;
  pastEvents: Array<AOEvent> = [];
  upcomingEvents: Array<AOEvent> = [];
  totalItemsCountPast = 0;
  totalItemsCountUpcoming = 0;
  start = 0;
  TabIds = TabIds;

  readonly count = 10;

  constructor(
    private publicProfileService: PublicProfileService,
    private appTranslationService: AppTranslationService,
    private modalController: ModalController,
    private eventsService: EventsService,
  ) { }

  ngOnInit(): void {
    Promise.all([
      this.getUserSharedEvents(this.start, true), // past
      this.getUserSharedEvents(this.start, false) // upcoming
    ]).then(() => {
      const activeTab = this.isTabPastDisabled ? TabIds.upcoming : TabIds.past;
      this.activeTabId = activeTab;
      this.generateTabs();
    });
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  get isTabPastActive(): boolean {
    return this.activeTabId === TabIds.past ? true : false;
  }

  get isTabPastDisabled(): boolean {
    if (this.loading) {
      return false;
    }

    return !this.loading && this.pastEvents?.length === 0;
  }

  get isTabUpcomingActive(): boolean {
    return this.activeTabId === TabIds.upcoming ? true : false;
  }

  get isTabUpcomingDisabled(): boolean {
    if (this.loading) {
      return false;
    }

    return !this.loading && this.upcomingEvents?.length === 0;
  }

  setActiveTab(tabId: TabIds): void {

    if (!tabId) {
      return;
    }

    this.activeTabId = tabId;
    this.start = 0;

    switch (tabId) {
      case TabIds.past:
        this.pastEvents = [];
        this.totalItemsCountPast = 0;
        this.getUserSharedEvents(this.start, true);
        break;
      case TabIds.upcoming:
        this.upcomingEvents = [];
        this.totalItemsCountUpcoming = 0;
        this.getUserSharedEvents(this.start, false);
        break;
    }
  }

  loadMoreGroups(verticalListLoadMoreData: VerticalListLoadMoreData): void {
    switch (this.activeTabId) {
      case TabIds.past:
        this.start = Math.floor(this.pastEvents?.length / this.count);
        this.getUserSharedEvents(this.start, true, verticalListLoadMoreData?.infiniteScroll);
        break;
      case TabIds.upcoming:
        this.start = Math.floor(this.upcomingEvents?.length / this.count);
        this.getUserSharedEvents(this.start, false, verticalListLoadMoreData?.infiniteScroll);
        break;
    }
  }

  refreshList(refresher: IonRefresher): void {
    this.start = 0;

    switch (this.activeTabId) {
      case TabIds.past:
        this.pastEvents = [];
        this.totalItemsCountPast = 0;
        this.getUserSharedEvents(this.start, true, null, refresher);
        break;
      case TabIds.upcoming:
        this.upcomingEvents = [];
        this.totalItemsCountUpcoming = 0;
        this.getUserSharedEvents(this.start, false, null, refresher);
        break;
    }
  }

  private generateTabs(): void {
    this.customTabs = [{
      id: TabIds.past,
      text: this.appTranslationService.instant('app.common.past'),
      active: this.isTabPastActive,
      disabled: this.isTabPastDisabled
    },
    {
      id: TabIds.upcoming,
      text: this.appTranslationService.instant('app.common.upcoming'),
      active: this.isTabUpcomingActive,
      disabled: this.isTabUpcomingDisabled
    }];
  }

  private getUserSharedEvents(start: number, past: boolean, infiniteScroll?: IonInfiniteScroll, refresher?: IonRefresher): Promise<void> {
    this.loading = true;
    return this.publicProfileService.getSharedEvents(this.userId, past, start, this.count)
      .then((eventsResponse: SharedEventsResponse) => {
        if (eventsResponse?.totalItemsCount > 0) {
          if (past) {
            this.pastEvents = [...this.pastEvents, ...eventsResponse?.result];
          } else {
            this.upcomingEvents = [...this.upcomingEvents, ...eventsResponse?.result];
          }
        }
      }).finally(() => {
        this.loading = false;
        refresher?.complete();
        infiniteScroll?.complete();
      });
  }

  async openEventClicked(event: AOEvent): Promise<void> {

    try {
      await this.eventsService.openEvent(event, this.source);
    } catch (err) {
      console.error(err);
    } finally {
      this.modalController.dismiss();
    }
  }

  get source(): ActionSource {
    return ActionSource.eventsDialog;
  }

}
