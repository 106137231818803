// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --verification-step-three-padding-top: 0px;
  --verification-step-three-padding-bottom: 16px;
  --verification-step-three-padding-start: 16px;
  --verification-step-three-padding-end: 16px;
  --content-max-width: 100%;
}

.verification-step-three-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  justify-items: center;
}
.verification-step-three-container .header {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  padding-bottom: 85px;
}
.verification-step-three-container .content {
  width: 100%;
  max-width: var(--content-max-width);
  height: 100%;
  padding-top: var(--verification-step-three-padding-top);
  padding-bottom: var(--verification-step-three-padding-bottom);
  grid-row: 2;
  grid-column: 1;
}
.verification-step-three-container .content ion-content {
  --padding-start: var(--verification-step-three-padding-start);
  --padding-end: var(--verification-step-three-padding-end);
}
.verification-step-three-container .content ion-content .title-container {
  margin-bottom: 15px;
}
.verification-step-three-container .content ion-content .title-container .title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: center;
}
.verification-step-three-container .content ion-content .image-preview-container {
  width: 255px;
  height: 210px;
  margin: 0 auto;
}
.verification-step-three-container .content ion-content .image-preview-container ion-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.verification-step-three-container .content ion-content .pdf-preview-container {
  max-width: 255px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  justify-content: center;
  row-gap: 5px;
}
.verification-step-three-container .content ion-content .pdf-preview-container app-icon {
  --icon-size: 30px;
  color: var(--mi-color-text-lite);
  justify-self: center;
}
.verification-step-three-container .content ion-content .pdf-preview-container .file-name {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: center;
}
.verification-step-three-container .content ion-content .pdf-preview-container .file-size {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  text-align: center;
}
.verification-step-three-container .content ion-content .retake-button-container {
  padding-bottom: 35px;
  padding-top: 11px;
  display: flex;
  justify-content: center;
}
.verification-step-three-container .content ion-content .retake-button-container .retake-button {
  outline: none;
  background: none;
  border: none;
}
.verification-step-three-container .content ion-content .retake-button-container .retake-button span {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
}
.verification-step-three-container .content ion-content .retake-button-container .retake-button span app-icon {
  --icon-size: 13px;
  color: var(--mi-primary-color);
}
.verification-step-three-container .content ion-content .retake-button-container .retake-button span .button-text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: 1;
  color: var(--mi-primary-color);
  margin: 0;
}
.verification-step-three-container .content ion-content .notes-container {
  padding-top: 33px;
  margin: 0 auto;
  max-width: 183px;
}
.verification-step-three-container .content ion-content .notes-container .note {
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
  margin-bottom: 25px;
}
.verification-step-three-container .content ion-content .notes-container .note:last-of-type {
  margin-bottom: 0;
}
.verification-step-three-container .content ion-content .notes-container .note .note-icon {
  --icon-size: 20px;
  color: var(--mi-primary-color);
}
.verification-step-three-container .content ion-content .notes-container .note .note-text {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: 1;
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
}
.verification-step-three-container .action-button-container {
  width: 100%;
  max-width: var(--content-max-width);
  grid-row: 3;
  grid-column: 1;
  padding-top: 25px;
  padding-bottom: 37px;
  padding-left: var(--verification-step-three-padding-start);
  padding-right: var(--verification-step-three-padding-end);
}
.verification-step-three-container .action-button-container ion-button {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
