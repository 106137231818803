import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AOEvent } from 'src/app/services/yeti-protocol/event';

export interface SelectedEventData {
  event: AOEvent,
  state: {selected: boolean}
}

@Component({
  selector: 'app-mi-events-list-item',
  templateUrl: './events-list-item.component.html',
  styleUrls: ['./events-list-item.component.scss'],
})
export class EventsListItemComponent {

  @Input() event:AOEvent = null;
  @Input() selectable = true;
  @Output() selectionChanged: EventEmitter<SelectedEventData> = new EventEmitter();

  state: {selected: boolean} = {
    selected: false
  };

  @HostListener('click', ['$event'])
  onClick(): void {
    this.state.selected = !this.state.selected;
    this.selectionChanged.next({
      event: this.event,
      state: this.state
    });
  }
}
