import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

// mocks
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';

// services
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { PublicProfileService } from 'src/app/services/public-profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClinicalExpertsDataService } from 'src/app/services/clinical-experts-data.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { VerificationService } from 'src/app/services/verification.service';
import { VerificationStatus } from 'src/app/services/verification.model';
import { CONTEXT_DIALOGS_UI, ContextDialogsUI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { ActionSource, ActionTracked } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-coparticipants-list-item',
  templateUrl: './coparticipants-list-item.component.html',
  styleUrls: ['./coparticipants-list-item.component.scss'],
})
export class CoparticipantsListItemComponent implements OnInit {

  @Input() userProfile: PublicProfile;
  @Input() showXButton: boolean;
  @Input() source: string;
  @Input() clickTrackingObjId: string;

  @Output() openPubllicUserProfile: EventEmitter<string> = new EventEmitter();
  @Output() xClicked: EventEmitter<PublicProfile> = new EventEmitter();

  currentUser: UserProfile;

  constructor(
    private dialogs: DialogsUIService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private publicProfileService: PublicProfileService,
    private authService: AuthService,
    private clinicalExpertDataService: ClinicalExpertsDataService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private verificationService: VerificationService,
    @Inject(CONTEXT_DIALOGS_UI) private contextDialogs: ContextDialogsUI,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  ngOnInit(): void {
    this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.currentUser = userProfile;
    });
  }

  get isConnectButtonVisible(): boolean {
    return this.uiUtilsService.isConnectButtonForPublicProfileVisible(this.userProfile);
  }

  getPublicUserProfile(): void {
    this.publicProfileService.getPublicProfile(this.userProfile?.userId).then(async (response) => {
      this.userProfile = await response.result;
      this.clinicalExpertDataService.triggerConnectUserProfileAction(this.userProfile);
    });
  }

  async connectAction(event: Event): Promise<void> {

    this.uiUtilsService.stopEventPropagation(event);

    this.trackGenericClickedAction(ActionTracked.connect);

    if (this.userProfile.connectOnlyWithAoMembers && !this.currentUser.isAOMember) {
      await this.openBecomeAoMemberDialog();
    } else {

      const userVerificationStatus = await this.verificationService.verify();

      if (userVerificationStatus !== VerificationStatus.VERIFIED) {
        return;
      }

      const userInfo = {
        firstName: this.userProfile?.firstName,
        lastName: this.userProfile?.lastName,
        userId: this.userProfile?.userId
      }

      this.contextDialogs.showSendNewConnectionRequestDialog(userInfo, this.source)
        .then(sent => {
          if (sent) {
            this.getPublicUserProfile();
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  async openBecomeAoMemberDialog(): Promise<void> {
    this.uiUtilsService.showBecomeAoMemberDialog().then(accepted => {
      if (accepted) {
        this.uiUtilsService.onBecomeAOMember(this.contextService?.currentContext?.aoMembershipUrl);
      }
    });
  }

  onOpenPublicProfilePage(event: Event): void {

    if (event) {
      this.uiUtilsService.stopEventPropagation(event);
    }

    this.trackGenericClickedAction(ActionTracked.publicProfileOpened);

    this.openPubllicUserProfile.emit(this.userProfile.userId);
  }

  ignorePerson(person: PublicProfile): void {
    this.xClicked.emit(person);
  }

  trackGenericClickedAction(objType: string): Promise<void> {

    if (!this.clickTrackingObjId) {
      return;
    }

    const objId = this.clickTrackingObjId;
    const objectTitle = this.clickTrackingObjId;

    return this.trackingService.trackGenericClickedAction(objId, objType, objectTitle,
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error(`Could not track: ${objType} click action.`);
      });
  }
}
