import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { EventsService } from 'src/app/services/events/events.service';
import {
  AOEventParticipant,
  AOEventParticipantsResponse,
  AOEventParticipantsSuccessResponse,
  AOEventParticipantType
} from 'src/app/services/yeti-protocol/event';

@Component({
  selector: 'app-ao-event-participants-list',
  templateUrl: './ao-event-participants-list.component.html',
  styleUrls: ['./ao-event-participants-list.component.scss'],
})
export class AoEventParticipantsListComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() eventId: string;
  @Input() showMax: number;
  @Input() aoEventParticipantType: AOEventParticipantType;

  @Output() totalParticipantsCount: EventEmitter<number> = new EventEmitter();
  @Output() sendMessage: EventEmitter<string> = new EventEmitter();
  @Output() openPublicUserProfile: EventEmitter<string> = new EventEmitter();

  count = 15;
  totalItems: number;
  aoEventAttendees: Array<AOEventParticipant> = [];
  contributors: Array<AOEventParticipant> = [];
  participants: Array<AOEventParticipant> = [];
  totalContributorItems: number;
  totalParticipantItems: number;

  constructor(
    private eventsService: EventsService,
  ) { }

  ngOnInit(): void {
    this.fetchEventParticipants(0);
  }

  fetchEventParticipants(start: number): void {

    if (this.showMax) {
      start = 0;
      this.count = this.showMax;
    }

    this.fetchEventContributorsAndParticipants(start)
    .then((response: AOEventParticipantsSuccessResponse) => {
      const eventParticipants = response?.result || [];
      this.contributors = [...this.contributors, ...eventParticipants.filter(item => item.participantType === 'contributor')];
      this.participants = [...this.participants, ...eventParticipants.filter(item => item.participantType === 'participant')];
      this.totalContributorItems = response?.meta?.totalContributors;
      this.totalParticipantItems = response?.meta?.totalParticipants;
      this.totalItems = response?.totalItemsCount;
      this.totalParticipantsCount.emit(this.totalItems);

      if (this.attendeesLength >= this.totalItems) {
        this.disableInfiniteScroll(true);
      }
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.infiniteScroll?.complete();
    });
  }

  loadMore(): void {
    if (this.showMax) {
      this.disableInfiniteScroll(true);
      return;
    }

    const start = Math.floor(this.attendeesLength / this.count);

    this.fetchEventParticipants(start);
  }

  get canLoadMore(): boolean {
    return !this.showMax && (this.attendeesLength < this.totalItems);
  }

  get attendeesLength(): number {
    return this.contributors?.length + this.participants?.length;
  }

  private fetchEventContributorsAndParticipants(start: number): Promise<AOEventParticipantsResponse> {
    return this.eventsService.getAOEventContributorsAndParticipants(this.eventId, start, this.count);
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  onSendMessage(connectionId: string): void {
    this.sendMessage.emit(connectionId);
  }

  profileImageClicked(userId: string): void {
    if (userId) {
      this.openPublicUserProfile.emit(userId);
    }
  }

}
