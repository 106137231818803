import { ContentChild, Directive, TemplateRef } from '@angular/core';
import { SwiperTemplateDirective } from './swiper-template.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'appSwiperContent',
})
export class SwiperContentDirective {
  @ContentChild(SwiperTemplateDirective, { read: TemplateRef })
  template!: TemplateRef<HTMLElement>;
}
