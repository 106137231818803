import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import appConfig from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class AppTranslationService {
  constructor(private translateService: TranslateService) {
  }

  init(): void{
    this.translateService.setDefaultLang(appConfig.defaultLanguage);
    this.translateService.use(appConfig.defaultLanguage);
  }

  // get translation
  get(key: Array<string>): Promise<Record<string, string>>
  get(key: string): Promise<string>
  get(key: string|Array<string>): Promise<string|Record<string, string>>{
    return firstValueFrom(this.translateService.get(key).pipe(
      map((value) => {
        return value || '';
      }),
      catchError(() => {
        return '';
      })
    ));
  }

  instant(key: string): string {
    return this.translateService.instant(key);
  }
}
