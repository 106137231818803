import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-surgery-reference-review-card',
  templateUrl: './surgery-reference-review-card.component.html',
  styleUrls: ['./surgery-reference-review-card.component.scss'],
})
export class SurgeryReferenceReviewCardComponent {
  @Input() itemId: string;
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() chip: string;
  @Input() cardType: string;
  @Input() allowRemoveSelection: boolean;

  @Output() removeSelection: EventEmitter<string> = new EventEmitter();
  @Output() openExternalLink: EventEmitter<string> = new EventEmitter();

  onRemoveBtnClick(): void {
    this.removeSelection.emit(this.itemId);
  }

  contentClicked(): void {
    this.openExternalLink.emit(this.itemId);
  }

}
