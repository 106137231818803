import { InjectionToken } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { Observable } from 'rxjs';
import { SurgeryReferenceDialogData } from 'src/app/dialogs/surgery-reference/surgery-reference-dialog/surgery-reference-dialog.component';
import { Approach, Fracture, FractureAndTreatment, Preparation, Treatment } from '../yeti-protocol/surgery-reference-schema';

export enum SurgeryReferenceDialogPage {
  FractureSelect,
  TreatmentSelect,
  Review
}

export interface SurgeryReferenceUIServiceInterface {
  nav: IonNav; // to be set by first dialog page
  openDialog(startPage: SurgeryReferenceDialogPage, rootParams?: SurgeryReferenceDialogData): Promise<void>;
  closeDialog(pageUrl?: string): Promise<void>;
  navigate(page: SurgeryReferenceDialogPage): Promise<any>;
  removeModule(item: Fracture | Treatment | Approach | Preparation | FractureAndTreatment, clinicalCaseId: string): Promise<void>;
  get dialogClosedObservable(): Observable<void>;
}

export const SURGERY_REFERENCE_UI_SERVICE = new InjectionToken<SurgeryReferenceUIServiceInterface>('surgery-reference-ui.service');
