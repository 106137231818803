import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-verification-dialog',
  templateUrl: './verification-dialog.component.html',
  styleUrls: ['./verification-dialog.component.scss'],
})
export class VerificationDialogComponent {

  constructor(
    private modalController: ModalController
  ) { }

  close(): void {
    this.modalController.dismiss();
  }

  finishClicked(): void {
    this.modalController.dismiss();
  }

}
