import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {
  setTimeout(callback: () => void , timeout: number): any{
    return setTimeout(callback, timeout);
  }
  clearTimeout(timer: number): void{
    clearTimeout(timer);
  }
}
