import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { TrackingService, TRACKING_SERVICE } from '../services/tracking/tracking.model';
import { UserProfile } from '../services/yeti-protocol/auth/mi';
import { ActionTracked, GenericTrackingParam, TrackingRequest } from '../services/yeti-protocol/tracking';

enum SupportedTargetPages {
  FEED = 'feed',
  CHAT = 'chat',
  CASE_FOLIO = 'casefolio',
  GROUPS = 'groups',
  CONTACTS = 'contacts',
  ACTIVITY = 'activity',
  PROFILE = 'profile',
  CO_CONTRIBUTORS = 'co-contributors',
  LATEST_PUBLICATIONS = 'latest-publications',
  ORCID_PUBLICATIONS = 'orcid-publications',
  AOVIDEO_HUB = 'video-hub',
  KNOWLEDGE = 'knowledge',
  SAVED = 'saved',
}

@Injectable({
  providedIn: 'root'
})
export class MarketingPageGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) { }

  async canActivate(
    _route: ActivatedRouteSnapshot
  ): Promise<boolean> {

    const context = await this.getLoggedInUserDefaultContext();
    const targetPlace = _route?.params?.targetPlace;
    let queryParams = '';

    let index = 0;

    for (const [key, value] of Object.entries(_route?.queryParams) || []) {

      if (index === 0) {
        queryParams = `?${key}=${value}`;
      } else {
        queryParams = `&${key}=${value}`;
      }

      ++index;
    }

    if (!context) {
      this.trackPageOpen(null, targetPlace, _route.queryParams);
      return this.router.navigateByUrl('/auth', { state: { preventDeepLinkCheck: true } });
    }

    this.trackPageOpen(context, targetPlace, _route.queryParams);

    if (!targetPlace) {
      return this.router.navigateByUrl(`/${context}/feed`);
    }

    switch (targetPlace) {
      case SupportedTargetPages.FEED:
        return this.router.navigateByUrl(`/${context}/${SupportedTargetPages.FEED}${queryParams}`);
      case SupportedTargetPages.CHAT:
        return this.router.navigateByUrl(`/${context}/${SupportedTargetPages.CHAT}${queryParams}`);
      case SupportedTargetPages.CASE_FOLIO:
        return this.router.navigateByUrl(`/${context}/${SupportedTargetPages.CASE_FOLIO}${queryParams}`);
      case SupportedTargetPages.GROUPS:
        return this.router.navigateByUrl(`/${context}/${SupportedTargetPages.GROUPS}${queryParams}`);
      case SupportedTargetPages.CONTACTS:
        return this.router.navigateByUrl(`/${context}/${SupportedTargetPages.CONTACTS}${queryParams}`);
      case SupportedTargetPages.ACTIVITY:
        return this.router.navigateByUrl(`/${context}/${SupportedTargetPages.ACTIVITY}${queryParams}`);
      case SupportedTargetPages.PROFILE:
        return this.router.navigateByUrl(`/${context}/${SupportedTargetPages.PROFILE}${queryParams}`);
      case SupportedTargetPages.CO_CONTRIBUTORS:
        return this.router.navigateByUrl(`/${context}/co-contributors${queryParams}`);
      case SupportedTargetPages.LATEST_PUBLICATIONS:
        return this.router.navigateByUrl(`/${context}/journals/latest-publications${queryParams}`);
      case SupportedTargetPages.ORCID_PUBLICATIONS:
        return this.router.navigateByUrl(`/${context}/knowledge/orcid-publications${queryParams}`);
      case SupportedTargetPages.AOVIDEO_HUB:
        return this.router.navigateByUrl(`/${context}/knowledge/video-hub${queryParams}`);
      case SupportedTargetPages.KNOWLEDGE:
        return this.router.navigateByUrl(`/${context}/${SupportedTargetPages.KNOWLEDGE}${queryParams}`);
      case SupportedTargetPages.SAVED:
        return this.router.navigateByUrl(`/${context}/knowledge/${SupportedTargetPages.SAVED}${queryParams}`);
      default:
        return this.router.navigateByUrl(`/${context}/feed`);
    }
  }

  getLoggedInUserDefaultContext(): Promise<string> {
    return new Promise((resolve) => {
      this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {

        if (!userProfile) {
          resolve(null);
        }

        resolve(userProfile?.homeDevision);
      });
    });
  }

  trackPageOpen(context: string, targetPlace: string, queryParams: any = {}): void {

    const paramsToTrack: GenericTrackingParam = {
      objectId: targetPlace,
      objectType: 'marketingLink',
      objectTitle: 'marketingLinkClicked',
      ...queryParams
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.clicked,
      params: paramsToTrack,
    };

    if (context) {
      trackData.appId = context;
    }

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track marketing page open action');
    });
  }
}
