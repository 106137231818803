// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --posts-list-padding-top: 0;
  --posts-list-padding-bottom: 0;
  --posts-list-padding-start: 0;
  --posts-list-padding-end: 0;
  --posts-list-margin-top: 0;
  --posts-list-margin-bottom: 0;
  --posts-list-margin-start: 0;
  --posts-list-margin-end: 0;
  --posts-list-background-color: var(--mi-white-color);
  --posts-list-cards-background-color: var(--mi-white-color);
}

.list-container {
  width: 100%;
  padding: var(--posts-list-padding-top) var(--posts-list-padding-end) var(--posts-list-padding-bottom) var(--posts-list-padding-start);
  margin: var(--posts-list-margin-top) var(--posts-list-margin-end) var(--posts-list-margin-bottom) var(--posts-list-margin-start);
  background-color: var(--posts-list-background-color);
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  row-gap: 30px;
  justify-items: center;
}
.list-container.no-padding {
  padding: 0;
}
.list-container.no-margin {
  margin: 0;
}
.list-container .posts {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 10px;
}
.list-container .posts .title-container {
  grid-row: 1;
  grid-column: 1;
  margin-left: var(--posts-list-margin-start);
  margin-right: var(--posts-list-margin-end);
}
.list-container .posts .title-container .list-title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
}
.list-container .posts .posts-list {
  grid-row: 2;
  grid-column: 1;
}
.list-container .spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.list-container .posts-list {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  row-gap: 10px;
  background: none;
  padding: 0;
}
.list-container .posts-list app-post-card,
.list-container .posts-list app-case-card {
  --post-card-padding-top: var(--posts-list-padding-top);
  --post-card-padding-bottom: var(--posts-list-padding-bottom);
  --post-card-padding-start: var(--posts-list-padding-start);
  --post-card-padding-end: var(--posts-list-padding-end);
  --post-card-margin-top: var(--posts-list-margin-top);
  --post-card-margin-bottom: var(--posts-list-margin-bottom);
  --post-card-margin-start: var(--posts-list-margin-start);
  --post-card-margin-end: var(--posts-list-margin-end);
  --post-card-background-color: var(--posts-list-cards-background-color);
}
.list-container app-skeleton-loader {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
