// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participant {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
}
.participant .participant-info-container {
  grid-row: 1;
  grid-column: 1;
  position: relative;
  height: 40px;
}
.participant .participant-info-container app-user-info {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.participant .participant-info-container app-user-info ::ng-deep .user-info-container .user-info-container .user-full-name-container {
  width: 100%;
  overflow: hidden;
}
.participant .participant-info-container app-user-info ::ng-deep .user-info-container .user-info-container .user-full-name-container app-user-full-name {
  width: 100%;
  overflow: hidden;
}
.participant .action-container {
  grid-row: 1;
  grid-column: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
