// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --user-image-size: 30px;
  --default-icon-font-size: 32px;
  --ao-icon-size: 22px;
  --ao-icon-overlap: calc(calc(var(--ao-icon-size) * 0.5) * -1);
}

.change-profile-image-icon-container {
  position: absolute;
  margin-left: calc(var(--user-image-size) - 24px);
}

.user-profile-image {
  cursor: pointer;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  justify-items: center;
  align-items: start;
}
.user-profile-image app-icon {
  --icon-size: var(--user-image-size);
  color: var(--mi-secondary-color-2);
}
.user-profile-image ion-avatar {
  width: var(--user-image-size);
  height: var(--user-image-size);
  background-image: url("/assets/images/image-placeholder.svg");
  background-color: var(--mi-blue-color-5);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: contain;
}
.user-profile-image .ao-member-icon-container {
  height: 0;
  margin-top: var(--ao-icon-overlap);
  z-index: 1;
}
.user-profile-image .ao-member-icon-container app-ao-member-symbol {
  --ao-member-symbol-icon-size: var(--ao-icon-size);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
