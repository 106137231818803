// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --create-group-case-text-input-section-width: 100%;
  --create-group-case-text-input-section-height: 100%;
  --create-group-case-text-input-section-padding-top: 17px;
  --create-group-case-text-input-section-padding-bottom: 17px;
  --create-group-case-text-input-section-padding-start: 17px;
  --create-group-case-text-input-section-padding-end: 17px;
  --create-group-case-text-input-section-background-color: var(--mi-inputs-color);
}

.create-group-case-text-input-section-container {
  width: var(--create-group-case-text-input-section-width);
  height: var(--create-group-case-text-input-section-height);
  padding-top: var(--create-group-case-text-input-section-padding-top);
  padding-bottom: var(--create-group-case-text-input-section-padding-bottom);
  padding-left: var(--create-group-case-text-input-section-padding-start);
  padding-right: var(--create-group-case-text-input-section-padding-end);
  background-color: var(--create-group-case-text-input-section-background-color);
}
.create-group-case-text-input-section-container app-rich-text-document-editor {
  --rich-text-document-editor-padding-top: 0px;
  --rich-text-document-editor-padding-bottom: 0px;
  --rich-text-document-editor-padding-start: 0px;
  --rich-text-document-editor-padding-end: 0px;
  --rich-text-document-editor-background-color: var(--create-group-case-text-input-section-background-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
