import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { MIComponentsModule } from 'src/app/components/components.module';
import { MIButtonsModule } from '../buttons/buttons.module';
import { ChipsModule } from '../chips/chips.module';
import { InfoSheetModule } from '../info-sheet/info-sheet.module';
import { RichTextDocumentEditorModule } from '../rich-text-document-editor/rich-text-document-editor.module';
import { ClinicalCaseListCaseCardComponent } from './clinical-case-list-case-card/clinical-case-list-case-card.component';
import { UsersModule } from '../users/users.module';
import { SwiperModule } from 'swiper/angular';
import { SwiperVisibilityTrackerModule } from '../swiper-visibility-tracker/swiper-visibility-tracker.module';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  declarations: [
    ClinicalCaseListCaseCardComponent,
  ],
  exports: [
    ClinicalCaseListCaseCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    MIComponentsModule,
    InfoSheetModule,
    MIButtonsModule,
    ChipsModule,
    RichTextDocumentEditorModule,
    UsersModule,
    SwiperModule,
    SwiperVisibilityTrackerModule.forRoot(),
    IconComponent
  ]
})
export class ClinicalCaseModule { }
