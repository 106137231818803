import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { SwiperVisibilityTrackerService } from './swiper-visibility-tracker.service';
import { SwiperVisibilityTrackerRootDirective } from './swiper-visibility-tracker-root.directive';
import { SwiperVisibilityTrackerTargetDirective } from './swiper-visibility-tracker-target.directive';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IconComponent
  ],
  declarations: [
    SwiperVisibilityTrackerRootDirective,
    SwiperVisibilityTrackerTargetDirective
  ],
  exports: [
    SwiperVisibilityTrackerRootDirective,
    SwiperVisibilityTrackerTargetDirective
  ]
})
export class SwiperVisibilityTrackerModule {
  static forRoot(): ModuleWithProviders<SwiperVisibilityTrackerModule> {
    return {
      ngModule: SwiperVisibilityTrackerModule,
      providers: [SwiperVisibilityTrackerService]
    }
  }
}
