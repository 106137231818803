// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --fruit-salad-width: 100%;
  --fruit-salad-height: 100%;
  --fruit-salad-padding-top: 29px;
  --fruit-salad-padding-bottom: 42px;
  --fruit-salad-padding-start: 21px;
  --fruit-salad-padding-end: 21px;
}

.fruit-salad-container {
  width: var(--fruit-salad-width);
  height: var(--fruit-salad-height);
  padding-top: var(--fruit-salad-padding-top);
  padding-bottom: var(--fruit-salad-padding-bottom);
  padding-left: var(--fruit-salad-padding-start);
  padding-right: var(--fruit-salad-padding-end);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
}
.fruit-salad-container .input-field-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  background-color: var(--mi-inputs-color);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 13px;
}
.fruit-salad-container .input-field-container .tag-input {
  grid-row: 1;
  grid-column: 1;
  background-color: var(--mi-inputs-color);
  outline: none;
  border: none;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-black-color);
}
.fruit-salad-container .input-field-container .chars-count {
  grid-row: 1;
  grid-column: 2;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
}
.fruit-salad-container .suggested-tags-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 12px;
}
.fruit-salad-container .suggested-tags-container .list-title-container {
  grid-row: 1;
  grid-column: 1;
  overflow: hidden;
}
.fruit-salad-container .suggested-tags-container .list-title-container .list-title {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fruit-salad-container .suggested-tags-container .list {
  grid-row: 2;
  grid-column: 1;
  margin-bottom: 27px;
}
.fruit-salad-container .suggested-tags-container .list ion-list {
  padding: 0;
}
.fruit-salad-container .suggested-tags-container .list ion-list .tag-suggestion {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  margin-bottom: 20px;
}
.fruit-salad-container .suggested-tags-container .list ion-list .tag-suggestion:last-child {
  margin-bottom: 0;
}
.fruit-salad-container .suggested-tags-container .list ion-list .tag-suggestion .suggestion-icon-container {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fruit-salad-container .suggested-tags-container .list ion-list .tag-suggestion .suggestion-icon-container app-icon {
  --icon-size: 21px;
  color: var(--mi-color-text-lite);
}
.fruit-salad-container .suggested-tags-container .list ion-list .tag-suggestion .suggestion-text {
  grid-row: 1;
  grid-column: 2;
  overflow: hidden;
}
.fruit-salad-container .suggested-tags-container .list ion-list .tag-suggestion .suggestion-text p {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fruit-salad-container .action-button-container {
  grid-row: 3;
  grid-column: 1;
}
.fruit-salad-container .action-button-container ion-button {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
