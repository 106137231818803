import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { PendingConnectionRequestsStateModel, PendingConnectionRequestsSubState } from './pending-connection-requests.model';
import { ConnectionType, ConnectionsApiService } from 'src/app/services/messenger/connections-api.service';
import { PendingConnectionRequests } from './pending-connection-requests.actions';
import { Connection, ConnectionsSuccessResponse } from 'src/app/services/yeti-protocol/connections';
import { PENDING_CONNECTION_REQUESTS_STATE_KEY } from '../state-constants';

const PENDING_CONNECTION_REQUESTS_STATE_TOKEN = new StateToken<PendingConnectionRequestsStateModel>(
    PENDING_CONNECTION_REQUESTS_STATE_KEY
);

@State({
    name: PENDING_CONNECTION_REQUESTS_STATE_TOKEN,
    defaults: {
        incomingPendingConnectionRequests: {
            pendingConnectionRequests: [],
            totalCount: 0,
            loading: false
        },
        sentPendingConnectionRequests: {
            pendingConnectionRequests: [],
            totalCount: 0,
            loading: false
        }
    },
})
@Injectable()
export class PendingConnectionRequestsState {
    constructor(private connectionsApiService: ConnectionsApiService) { }

    @Selector()
    static state(state: PendingConnectionRequestsStateModel): PendingConnectionRequestsStateModel {
        return state;
    }

    @Selector()
    static incomingPendingConnectionRequests(state: PendingConnectionRequestsStateModel): PendingConnectionRequestsSubState {
        return state.incomingPendingConnectionRequests;
    }

    @Selector()
    static sentPendingConnectionRequests(state: PendingConnectionRequestsStateModel): PendingConnectionRequestsSubState {
        return state.sentPendingConnectionRequests;
    }

    @Action(PendingConnectionRequests.FetchPendingConnectionRequests)
    async fetchPendingConnectionRequests(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        payload: PendingConnectionRequests.FetchPendingConnectionRequests
    ): Promise<void> {

        const state = ctx.getState();

        switch (payload.payloadParams.connectionType) {
            case ConnectionType.IncomingConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        incomingPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: state.incomingPendingConnectionRequests.pendingConnectionRequests,
                            totalCount: state.incomingPendingConnectionRequests.totalCount,
                            loading: true
                        })
                    }));
                break;
            case ConnectionType.SentConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        sentPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: state.sentPendingConnectionRequests.pendingConnectionRequests,
                            totalCount: state.sentPendingConnectionRequests.totalCount,
                            loading: true
                        })
                    }));

                break;
            default:
                throw new Error(`Connection type: ${payload.payloadParams.connectionType} not supported!`);
        }

        try {
            const pendingConnectionRequests = (await this.connectionsApiService.getPendingConnections(
                payload?.payloadParams?.connectionType,
                payload?.payloadParams?.pageIndex,
                payload?.payloadParams?.pageSize,
            )) as ConnectionsSuccessResponse;

            ctx.dispatch(
                new PendingConnectionRequests.FetchPendingConnectionRequestsSuccess(
                    payload?.payloadParams,
                    pendingConnectionRequests
                )
            );
        } catch (err) {
            ctx.dispatch(
                new PendingConnectionRequests.FetchPendingConnectionRequestsFailed(payload?.payloadParams)
            );

            throw err;
        }
    }

    @Action(PendingConnectionRequests.FetchPendingConnectionRequestsSuccess)
    fetchPendingConnectionRequestsSuccess(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.FetchPendingConnectionRequestsSuccess
    ): void {
        const state = ctx.getState();

        switch (action.payloadParams.connectionType) {
            case ConnectionType.IncomingConnectionRequest:

                if (action.payloadParams.pageIndex === 0) {
                    ctx.setState(
                        patch<PendingConnectionRequestsStateModel>({
                            incomingPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                                pendingConnectionRequests: action.response.result,
                                totalCount: action.response.totalItemsCount,
                                loading: false
                            })
                        }));
                } else {
                    ctx.setState(
                        patch<PendingConnectionRequestsStateModel>({
                            incomingPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                                pendingConnectionRequests:
                                    [...state.incomingPendingConnectionRequests.pendingConnectionRequests, ...action.response.result],
                                totalCount: action.response.totalItemsCount,
                                loading: false
                            })
                        }));
                }
                break;
            case ConnectionType.SentConnectionRequest:

                if (action.payloadParams.pageIndex === 0) {
                    ctx.setState(
                        patch<PendingConnectionRequestsStateModel>({
                            sentPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                                pendingConnectionRequests: action.response.result,
                                totalCount: action.response.totalItemsCount,
                                loading: false
                            })
                        }));
                } else {
                    ctx.setState(
                        patch<PendingConnectionRequestsStateModel>({
                            sentPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                                pendingConnectionRequests:
                                    [...state.sentPendingConnectionRequests.pendingConnectionRequests, ...action.response.result],
                                totalCount: action.response.totalItemsCount,
                                loading: false
                            })
                        }));
                }

                break;
            default:
                throw new Error(`Connection type: ${action.payloadParams.connectionType} not supported!`);
        }
    }

    @Action(PendingConnectionRequests.FetchPendingConnectionRequestsFailed)
    fetchPendingConnectionRequestsFailed(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.FetchPendingConnectionRequestsFailed
    ): void {

        const state = ctx.getState();

        switch (action.payloadParams.connectionType) {

            case ConnectionType.IncomingConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        incomingPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: state.incomingPendingConnectionRequests.pendingConnectionRequests,
                            totalCount: state.incomingPendingConnectionRequests.totalCount,
                            loading: false
                        })
                    }));
                break;
            case ConnectionType.SentConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        sentPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: state.sentPendingConnectionRequests.pendingConnectionRequests,
                            totalCount: state.sentPendingConnectionRequests.totalCount,
                            loading: false
                        })
                    }));

                break;
            default:
                throw new Error(`Connection type: ${action.payloadParams.connectionType} not supported!`);
        }
    }

    @Action(PendingConnectionRequests.FetchIncomingPendingConnectionRequests)
    fetchIncomingPendingConnectionRequests(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.FetchIncomingPendingConnectionRequests
    ): void {
        ctx.dispatch(
            new PendingConnectionRequests.FetchPendingConnectionRequests(
                { ...action.payloadParams, connectionType: ConnectionType.IncomingConnectionRequest }
            )
        );
    }

    @Action(PendingConnectionRequests.FetchSentPendingConnectionRequests)
    fetchSentPendingConnectionRequests(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.FetchSentPendingConnectionRequests
    ): void {
        ctx.dispatch(
            new PendingConnectionRequests.FetchPendingConnectionRequests(
                { ...action.payloadParams, connectionType: ConnectionType.SentConnectionRequest }
            )
        );
    }

    @Action(PendingConnectionRequests.InsertPendingConnectionRequestBeforeIndex)
    insertPendingConnectionRequestBeforeIndex(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.InsertPendingConnectionRequestBeforeIndex): void {

        const state = ctx.getState();

        switch (action.connectionType) {

            case ConnectionType.IncomingConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        incomingPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: insertItem<Connection>(action.pendingConnectionRequest, action.index),
                            totalCount: state.incomingPendingConnectionRequests.totalCount + 1,
                            loading: state.incomingPendingConnectionRequests.loading
                        })
                    })
                );

                break;
            case ConnectionType.SentConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        sentPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: insertItem<Connection>(action.pendingConnectionRequest, action.index),
                            totalCount: state.sentPendingConnectionRequests.totalCount + 1,
                            loading: state.sentPendingConnectionRequests.loading
                        })
                    })
                );

                break;
            default:
                throw new Error(`Connection type: ${action.connectionType} not supported!`);
        }
    }

    @Action(PendingConnectionRequests.InsertIncomingPendingConnectionRequestBeforeIndex)
    insertIncomingPendingConnectionRequestBeforeIndex(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.InsertIncomingPendingConnectionRequestBeforeIndex): void {
        ctx.dispatch(
            new PendingConnectionRequests.InsertPendingConnectionRequestBeforeIndex(
                ConnectionType.IncomingConnectionRequest,
                action.pendingConnectionRequest, action.index)
        );
    }

    @Action(PendingConnectionRequests.InsertSentPendingConnectionRequestBeforeIndex)
    insertSentPendingConnectionRequestBeforeIndex(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.InsertSentPendingConnectionRequestBeforeIndex): void {
        ctx.dispatch(
            new PendingConnectionRequests.InsertPendingConnectionRequestBeforeIndex(
                ConnectionType.SentConnectionRequest, action.pendingConnectionRequest,
                action.index)
        );
    }

    @Action(PendingConnectionRequests.RemovePendingConnectionRequest)
    removePendingConnectionRequest(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.RemovePendingConnectionRequest): void {

        const state = ctx.getState();

        switch (action.connectionType) {

            case ConnectionType.IncomingConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        incomingPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: removeItem<Connection>(action.index),
                            totalCount: state.incomingPendingConnectionRequests.totalCount - 1,
                            loading: state.incomingPendingConnectionRequests.loading
                        })
                    })
                );

                break;
            case ConnectionType.SentConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        sentPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: removeItem<Connection>(action.index),
                            totalCount: state.sentPendingConnectionRequests.totalCount - 1,
                            loading: state.sentPendingConnectionRequests.loading
                        })
                    })
                );

                break;
            default:
                throw new Error(`Connection type: ${action.connectionType} not supported!`);
        }
    }

    @Action(PendingConnectionRequests.RemoveIncomingPendingConnectionRequest)
    removeIncomingPendingConnectionRequest(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.RemoveIncomingPendingConnectionRequest): void {
        ctx.dispatch(
            new PendingConnectionRequests.RemovePendingConnectionRequest(ConnectionType.IncomingConnectionRequest, action.index)
        );
    }

    @Action(PendingConnectionRequests.RemoveSentPendingConnectionRequest)
    removeSentPendingConnectionRequest(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.RemoveSentPendingConnectionRequest): void {
        ctx.dispatch(
            new PendingConnectionRequests.RemovePendingConnectionRequest(ConnectionType.SentConnectionRequest, action.index)
        );
    }

    @Action(PendingConnectionRequests.UpdatePendingConnectionRequest)
    updateClinicalCaseAccessRequest(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.UpdatePendingConnectionRequest): void {

        const state = ctx.getState();

        switch (action.connectionType) {

            case ConnectionType.IncomingConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        incomingPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: updateItem<Connection>(
                                pendingConnectionRequest =>
                                    pendingConnectionRequest._id === action.pendingConnectionRequest._id, action.pendingConnectionRequest),
                            totalCount: state.incomingPendingConnectionRequests.totalCount,
                            loading: state.incomingPendingConnectionRequests.loading
                        })
                    })
                );

                break;
            case ConnectionType.SentConnectionRequest:

                ctx.setState(
                    patch<PendingConnectionRequestsStateModel>({
                        sentPendingConnectionRequests: patch<PendingConnectionRequestsSubState>({
                            pendingConnectionRequests: updateItem<Connection>(
                                pendingConnectionRequest =>
                                    pendingConnectionRequest._id === action.pendingConnectionRequest._id, action.pendingConnectionRequest),
                            totalCount: state.incomingPendingConnectionRequests.totalCount,
                            loading: state.incomingPendingConnectionRequests.loading
                        })
                    })
                );

                break;
            default:
                throw new Error(`Connection type: ${action.connectionType} not supported!`);
        }
    }

    @Action(PendingConnectionRequests.UpdateIncomingPendingConnectionRequest)
    updateIncomingPendingConnectionRequest(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.UpdateIncomingPendingConnectionRequest): void {
        ctx.dispatch(
            new PendingConnectionRequests.UpdatePendingConnectionRequest(
                ConnectionType.IncomingConnectionRequest,
                action.pendingConnectionRequest)
        );
    }

    @Action(PendingConnectionRequests.UpdateSentPendingConnectionRequest)
    updateSentPendingConnectionRequest(
        ctx: StateContext<PendingConnectionRequestsStateModel>,
        action: PendingConnectionRequests.UpdateSentPendingConnectionRequest): void {
        ctx.dispatch(
            new PendingConnectionRequests.UpdatePendingConnectionRequest(
                ConnectionType.SentConnectionRequest,
                action.pendingConnectionRequest)
        );
    }
}
