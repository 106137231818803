// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --horizontal-list-min-height: 100px;
}

.horizontal-list-container {
  width: 100%;
  height: 100%;
  min-height: var(--horizontal-list-min-height);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.horizontal-list-container swiper {
  width: 100%;
}
.horizontal-list-container app-skeleton-loader {
  width: 100%;
}
.horizontal-list-container swiper ::ng-deep .swiper-button-prev,
.horizontal-list-container swiper ::ng-deep .swiper-button-next {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-size: 100%;
  opacity: 0.6;
}
.horizontal-list-container swiper ::ng-deep .swiper-button-prev.swiper-button-disabled,
.horizontal-list-container swiper ::ng-deep .swiper-button-next.swiper-button-disabled {
  display: none;
}
.horizontal-list-container swiper ::ng-deep .swiper-button-next:after,
.horizontal-list-container swiper ::ng-deep .swiper-button-prev:after {
  content: none;
}
.horizontal-list-container swiper ::ng-deep .swiper-button-next {
  background-image: url("/svg/chevron-forward-circle.svg");
}
.horizontal-list-container swiper ::ng-deep .swiper-button-prev {
  background-image: url("/svg/chevron-back-circle.svg");
}
.horizontal-list-container .hidden {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
