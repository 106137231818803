// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
  padding: 10px;
  max-height: 88px;
}
:host ion-thumbnail {
  width: 73px;
  height: 73px;
  margin-left: 6px;
  flex-grow: 0;
  flex-shrink: 0;
  --border-radius: var(--mi-button-border-raius);
  position: relative;
}
:host ion-thumbnail .type {
  position: absolute;
  bottom: 0;
  height: 20px;
  width: 100%;
  background-color: var(--mi-background-color-journls);
  color: var(--mi-primary-color);
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 3px;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
:host app-event-image {
  --event-image-border-radius: var(--mi-button-border-raius);
  --event-image-size: \$thumbnail-size;
}
:host .content {
  width: 100%;
  position: relative;
}
:host .content .wrapper {
  width: 100%;
  position: absolute;
  color: var(--mi-secondary-color-2);
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  min-height: 73px;
  flex-grow: 1;
}
:host .content .wrapper .title {
  min-height: 60px;
}
:host .content .wrapper .title app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 60px;
}
:host .content .wrapper .title app-multiline-text-with-ellipsis ::ng-deep {
  font-weight: 600;
  font-size: var(--font-size-large);
  flex-grow: 1;
  line-height: var(--font-line-height-small);
  white-space: normal;
}
:host .content .wrapper .date {
  font-size: var(--font-size-small);
  flex-grow: 0;
}
:host ion-checkbox {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 23px 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
