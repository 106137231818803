// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container-web {
  width: unset !important;
  margin: 15px 15px;
  border-radius: 10px;
}

.list-container {
  width: 100%;
  background-color: var(--mi-light-purple-1-color);
}
.list-container .list-item {
  width: 100%;
}
.list-container ion-list-header {
  display: flex;
  align-items: flex-start;
  padding-right: var(--mi-standard-padding);
  margin: 0px;
  padding-top: 15px;
  height: 20px;
}
.list-container ion-list-header .header {
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  color: var(--mi-secondary-color-2);
  margin: 0px;
}
.list-container ion-list-header .label-small {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  color: var(--mi-primary-color);
}
.list-container .group-text-wrap {
  height: 20px;
}
.list-container .group-text-wrap .label-medium {
  font-family: Suisse;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  color: var(--mi-color-text-lite);
  padding-left: var(--mi-standard-padding);
}
.list-container .grid {
  display: grid;
  grid-template-columns: 300px 300px;
}
.list-container.hidden {
  display: none;
}
.list-container .groups-cards {
  padding: 0 16px;
}
.list-container app-recommended-groups-item {
  display: block;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
