import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import {
  ContextSwitchService,
  CONTEXT_SWITCH_SERVICE,
  OpenCloseContextSwitchMenu
} from 'src/app/services/context/context-switch.model';

// services

@Component({
  selector: 'app-context-switch-menu',
  templateUrl: './context-switch-menu.component.html',
  styleUrls: ['./context-switch-menu.component.scss'],
})
export class ContextSwitchMenuComponent implements OnInit, OnDestroy {

  menuId = 'context-menu';
  OpenCloseContextSwitchMenu = OpenCloseContextSwitchMenu;
  private toggleMenuSubscription: Subscription;

  constructor(
    private menuController: MenuController,
    @Inject(CONTEXT_SWITCH_SERVICE) private contextSwitchService: ContextSwitchService
  ) { }

  ngOnInit(): void {
    this.toggleMenuSubscription = this.contextSwitchService.onOpenCloseContextSwitchMenuCommand()
      .subscribe((command: OpenCloseContextSwitchMenu) => {
        this.onToggleMenu(command);
      });
  }

  openMenu(): void {
    this.menuController.enable(true, this.menuId);
    this.menuController.open(this.menuId);
  }

  closeMenu(): void {
    this.menuController.close(this.menuId);
  }

  onToggleMenu(command: OpenCloseContextSwitchMenu): void {
    switch (command) {
      case OpenCloseContextSwitchMenu.open:
        this.openMenu();
        break;
      case OpenCloseContextSwitchMenu.close:
        this.closeMenu();
        break;
    }
  }

  ngOnDestroy(): void {
    this.toggleMenuSubscription?.unsubscribe();
  }
}
