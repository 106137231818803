// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --event-card-event-image-size: 80px;
  --event-card-event-image-border-radius: 5px;
}

.event-card-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  overflow: hidden;
}
.event-card-container .image-container {
  grid-row: 1;
  grid-column: 1;
  cursor: pointer;
}
.event-card-container .image-container app-event-image {
  --event-image-size: var(--event-card-event-image-size);
  --event-image-border-radius: var(--event-card-event-image-border-radius);
}
.event-card-container .image-container app-event-image ::ng-deep .event-image .text-container span {
  text-transform: capitalize;
}
.event-card-container .info-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 3px;
  width: 100%;
  overflow: hidden;
}
.event-card-container .info-container .title-container {
  width: 100%;
  height: 45px;
  cursor: pointer;
  line-height: 1;
  position: relative;
  overflow: hidden;
}
.event-card-container .info-container .title-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 45px;
  position: absolute;
  width: 100%;
  height: 45px;
}
.event-card-container .info-container .title-container app-multiline-text-with-ellipsis ::ng-deep .multiline-text-container .text {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.event-card-container .info-container .duration-container {
  height: 14px;
}
.event-card-container .info-container .duration-container .duration {
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-16);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-card-container .info-container .location-container {
  height: 14px;
  line-height: 1.3;
}
.event-card-container .info-container .location-container .location {
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-16);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-card-container .actions-container {
  grid-row: 1;
  grid-column: 3;
}
.event-card-container .actions-container ion-checkbox {
  --background-checked: var(--mi-primary-color);
  --border-color-checked: var(--mi-primary-color);
  --checkmark-color: var(--mi-white-color);
}
.event-card-container.disable-item {
  pointer-events: none;
  opacity: 0.5;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
