import { AppPlatformConfigInterface, Platform } from '../config.model';
import EnvironmentConfig from './myao.dev.config';

class PlatformConfig extends EnvironmentConfig implements AppPlatformConfigInterface {
  platform: Platform = Platform.BROWSER;
  appUpdateVersionFieldName = 'versionAndroid';
  appUpdateContentUrlFieldName = 'urlAndroid';
  appUpdateDisabled = true;
  cookieConsentDomain = 'localhost:8000';
  clientConfigurationsUrl = 'http://localhost:8000/assets/client-config.json';

}

const appConfig = new PlatformConfig();
export default appConfig;
