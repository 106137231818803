// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --custom-tabs-background-color: var(--mi-light-purple-1-color);
  --custom-tabs-tab-background-color: transparent;
  --custom-tabs-active-tab-background-color: var(--mi-white-color);
}

.custom-tabs-container {
  background-color: var(--mi-white-color);
  padding: 8px;
}
.custom-tabs-container .tabs-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto;
  padding: 6px;
  background-color: var(--custom-tabs-background-color);
  justify-content: center;
  border-radius: 10px;
}
.custom-tabs-container .tabs-wrapper .tab {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 12px 23px;
  background-color: var(--custom-tabs-tab-background-color);
  cursor: pointer;
}
.custom-tabs-container .tabs-wrapper .tab .tab-wrapper.with-notification-count {
  display: grid;
  grid-template-rows: min-content;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
}
.custom-tabs-container .tabs-wrapper .tab .tab-wrapper .tab-text {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-regular);
  color: var(--mi-grey-color-16);
}
.custom-tabs-container .tabs-wrapper .tab .tab-wrapper .tab-notifications-count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mi-red-color-3);
  border-radius: 50%;
  margin-left: 5px;
  min-width: 18.5px;
  min-height: 18.5px;
}
.custom-tabs-container .tabs-wrapper .tab .tab-wrapper .tab-notifications-count-container .count {
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  color: var(--mi-white-color);
  margin: 0;
  padding: 3.5px 3.5px;
}
.custom-tabs-container .tabs-wrapper .tab.active {
  background-color: var(--custom-tabs-active-tab-background-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.custom-tabs-container .tabs-wrapper .tab.active .tab-wrapper .tab-text {
  font-weight: var(--font-weight-semi-bold);
  color: var(--mi-primary-color);
}
.custom-tabs-container .tabs-wrapper .tab.disabled {
  opacity: 0.5;
  cursor: none;
  pointer-events: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
