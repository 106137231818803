// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --context-button-selected-color: var(--mi-content-journals-color-9);
}

ion-item {
  padding: 4px 8px;
}
ion-item app-icon[slot=start] {
  width: 25px;
  height: 25px;
  float: left;
  margin-right: var(--font-size-regular);
}
ion-item app-icon[slot=end] {
  width: 25px;
  height: 25px;
  float: right;
  margin-left: var(--font-size-regular);
}

.context-option {
  width: 100%;
  border-radius: 8px;
  height: 63px;
  padding: var(--font-line-height-regular) var(--font-line-height-tiny);
  background-color: var(--mi-background-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.context-option ion-label {
  font-weight: 500;
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
  white-space: normal;
  width: 80%;
}

.selected .context-option {
  background-color: var(--context-button-selected-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
