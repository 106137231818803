import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  CaseImageUploadEditComponent
} from 'src/app/components/case-image-upload-edit/case-image-upload-edit.component';
import {
  ActionOnInit,
  VideoToPlay
} from 'src/app/components/case-image-upload-edit/case-image-upload-edit.model';

import { Subscription } from 'rxjs';
import {
  PersonalMediaGalleryDocumentWithStageOfTreatment,
  StageOfTreatmentId
} from 'src/app/components/stage-of-treatment/stage-of-treatment.model';
import { openVideoDialog } from 'src/app/modules/video-hub/video-hub.utils';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { ClinicalCase, ClinicalCaseUpdateData } from 'src/app/services/yeti-protocol/clinical-case';

@Component({
  selector: 'app-edit-case-images',
  templateUrl: './edit-case-images.component.html',
  styleUrls: ['./edit-case-images.component.scss'],
})
export class EditCaseImagesComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('caseImageUploadEditComponent') caseImageUploadEditComponent: CaseImageUploadEditComponent;

  @Input() clinicalCase: ClinicalCase;
  @Input() triggerActionOnInit: ActionOnInit;
  @Input() propagateClinicalCaseUpdate: (clinicalCase: ClinicalCase) => any;
  @Input() stageOfTreatment: StageOfTreatmentId;

  updatedClinicalCase: ClinicalCase;
  caseImages: Array<PersonalMediaGalleryDocumentWithStageOfTreatment> = [];

  private clinicalCaseUpdatedSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private clinicalCaseService: ClinicalCaseService,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.clinicalCaseUpdatedSubscription = this.clinicalCaseService.clinicalCaseUpdatedObservable
      .subscribe(clinicalCase => this.propagateClinicalCaseUpdate(clinicalCase));
  }

  ngAfterViewInit(): void {

    this.caseImages = [
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.PRE_OP),
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.INTRA_OP),
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.POST_OP),
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.FOLLOW_UP)
    ];

    if (this.stageOfTreatment) {
      this.caseImages.forEach(image => {
        if (image.stageOfTreatmentId !== this.stageOfTreatment) {
          image.hidden = true;
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.clinicalCaseUpdatedSubscription?.unsubscribe();
  }

  async onBack(): Promise<void> {
    this.modalController.dismiss();
  }

  updateCaseImages(caseImages: Array<PersonalMediaGalleryDocumentWithStageOfTreatment>): void {
    this.caseImages = caseImages;
  }

  get areImageFilesChanged(): boolean {

    let imageFilesChanged = false;

    const caseOriginalImageFiles = [
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.PRE_OP),
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.INTRA_OP),
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.POST_OP),
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.FOLLOW_UP)
    ];

    if (caseOriginalImageFiles?.length < this.caseImages?.length) {
      return true;
    }

    this.caseImages.forEach(image => {
      const index = caseOriginalImageFiles.findIndex(originalImage => originalImage?._id === image?._id);

      if (index === -1) {
        imageFilesChanged = true;
      }
    });

    return imageFilesChanged;
  }

  get isImageOrderChanged(): boolean {

    const caseOriginalImageFileIds = [
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.PRE_OP),
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.INTRA_OP),
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.POST_OP),
      ...this.getCaseDocumentsByStageOfTreatmentId(StageOfTreatmentId.FOLLOW_UP)
    ]?.map(image => {
      return image?._id;
    });

    const caseImageIds = this.caseImages?.map(image => {
      return image?._id;
    });

    return JSON.stringify(caseOriginalImageFileIds) !== JSON.stringify(caseImageIds);
  }

  get areImageFilesStageOfTreatmentTagsChanged(): boolean {

    let imageFilesStageOfTreatmentTagsChanged = false;
    let images = [];

    this.caseImages.forEach(image => {

      if (image.stageOfTreatmentId) {
        switch (image.stageOfTreatmentId) {
          case StageOfTreatmentId.PRE_OP:
            images = this.clinicalCase?.preOpDocuments;
            break;
          case StageOfTreatmentId.INTRA_OP:
            images = this.clinicalCase?.intraOpDocuments;
            break;
          case StageOfTreatmentId.POST_OP:
            images = this.clinicalCase?.postOpDocuments;
            break;
          case StageOfTreatmentId.FOLLOW_UP:
            images = this.clinicalCase?.clinicalFollowUpDocuments;
            break;
        }

        if (!images || images.length === 0) {
          imageFilesStageOfTreatmentTagsChanged = true;
        } else {
          if (images.findIndex(originalImage => originalImage?._id === image?._id) === -1) {
            imageFilesStageOfTreatmentTagsChanged = true;
          }
        }
      }
    });

    return imageFilesStageOfTreatmentTagsChanged;
  }

  get disableSaveButton(): boolean {
    return !this.areAllImagesTagedWithStageOfTreatment ||
      this.caseImageUploadEditComponent?.caseImagesUploading > 0 ||
      this.caseImageUploadEditComponent?.caseImagesProcessing > 0;
  }

  get areAllImagesTagedWithStageOfTreatment(): boolean {

    if (!this.caseImages || this.caseImages?.length === 0) {
      return false;
    }

    let allImagesTaged = true;

    this.caseImages.forEach(image => {
      if (!image.stageOfTreatmentId) {
        allImagesTaged = false;
      }
    });

    return allImagesTaged;
  }

  save(): void {
    if (this.areImageFilesStageOfTreatmentTagsChanged || this.areImageFilesChanged || this.isImageOrderChanged) {

      const clinicalCaseData: ClinicalCaseUpdateData = {};

      clinicalCaseData.preOpDocuments = this.getCaseDocumentIdsByStageOfTreatment(StageOfTreatmentId.PRE_OP);
      clinicalCaseData.intraOpDocuments = this.getCaseDocumentIdsByStageOfTreatment(StageOfTreatmentId.INTRA_OP);
      clinicalCaseData.postOpDocuments = this.getCaseDocumentIdsByStageOfTreatment(StageOfTreatmentId.POST_OP);
      clinicalCaseData.clinicalFollowUpDocuments = this.getCaseDocumentIdsByStageOfTreatment(StageOfTreatmentId.FOLLOW_UP);
      clinicalCaseData.watermarked = this.clinicalCase?.watermarked || false;

      this.clinicalCaseService.updateCase(this.clinicalCase._id, clinicalCaseData).then(() => {
        this.modalController.dismiss();
      }).catch(err => {
        this.showError(err?.message);
      });

    } else {
      this.modalController.dismiss();
    }
  }

  getCaseDocumentIdsByStageOfTreatment(id: StageOfTreatmentId): Array<any> {

    if (!this.caseImages || this.caseImages?.length === 0) {
      return [];
    }

    return this.caseImages.filter(img => img.stageOfTreatmentId === id)?.map(doc => doc._id);
  }

  onPlayVideo(video: VideoToPlay): void {
    openVideoDialog(video.url, video.mimeType, video.thumbnailImageUrl, this.modalController);
  }

  private getCaseDocumentsByStageOfTreatmentId(id: StageOfTreatmentId):
    Array<PersonalMediaGalleryDocumentWithStageOfTreatment> {

    let documents = [];
    let stageOfTreatmentId: StageOfTreatmentId;

    switch (id) {
      case StageOfTreatmentId.PRE_OP:
        documents = this.clinicalCase?.preOpDocuments;
        stageOfTreatmentId = StageOfTreatmentId.PRE_OP;
        break;
      case StageOfTreatmentId.INTRA_OP:
        documents = this.clinicalCase?.intraOpDocuments;
        stageOfTreatmentId = StageOfTreatmentId.INTRA_OP;
        break;
      case StageOfTreatmentId.POST_OP:
        documents = this.clinicalCase?.postOpDocuments;
        stageOfTreatmentId = StageOfTreatmentId.POST_OP;
        break;
      case StageOfTreatmentId.FOLLOW_UP:
        documents = this.clinicalCase?.clinicalFollowUpDocuments;
        stageOfTreatmentId = StageOfTreatmentId.FOLLOW_UP;
        break;
    }

    if (!documents) {
      return [];
    }

    return documents?.map((image: PersonalMediaGalleryDocumentWithStageOfTreatment) => {
      const deepCopyImageObject = { ...image };
      deepCopyImageObject.stageOfTreatmentId = stageOfTreatmentId;
      return deepCopyImageObject;
    });
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

}
