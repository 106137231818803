// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  color: var(--mi-secondary-color-2);
  letter-spacing: 0;
  line-height: 24px;
  font-weight: var(--font-weight-medium);
  padding-left: 40px;
  padding-right: 40px;
}

.icon-size-24 {
  height: 24px;
  width: 24px;
}

.toolbar {
  --background: var(--mi-white-color);
  height: 65px;
  display: flex;
}
.toolbar .back-button {
  background-color: var(--mi-white-color);
  margin-left: 10px;
}

.user-profile-settings-content {
  --background: var(--mi-background-color);
}
.user-profile-settings-content app-icon {
  height: 20px;
  width: 20px;
  color: var(--mi-color-text-lite);
}
.user-profile-settings-content .content-card {
  --background: var(--mi-white-color);
}
.user-profile-settings-content .content-card .toggle-area {
  background-color: var(--mi-inputs-color);
  border-radius: 5px;
  display: flex;
  padding: 20px;
  margin-bottom: 10px;
}
.user-profile-settings-content .content-card .toggle-area .toggle-area-text {
  width: 75%;
}
.user-profile-settings-content .content-card .toggle-area .toggle-area-text h3 {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.user-profile-settings-content .content-card .toggle-area .toggle-area-text p {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  width: 80%;
}
.user-profile-settings-content .content-card .toggle-area ion-toggle {
  --background-checked: var(--mi-primary-color);
  margin-left: 11%;
}
@media all and (min-width: 1090px) {
  .user-profile-settings-content .content-card .toggle-area ion-toggle {
    margin-left: 15%;
  }
}
.user-profile-settings-content .content-card .toggle-area .push-toggle-overlay {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 40px;
  width: 16%;
  z-index: 10;
  background-color: var(--mi-inputs-color);
  opacity: 0.001;
}
.user-profile-settings-content .content-card .toggle-area.push-toggle {
  position: relative;
}
.user-profile-settings-content .content-card .help-center-container,
.user-profile-settings-content .content-card .delete-account-container {
  width: 100%;
  padding: 20px 15px;
  background-color: var(--mi-background-color);
  border-radius: 9px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  align-items: center;
  cursor: pointer;
}
.user-profile-settings-content .content-card .help-center-container .icon-and-text-container,
.user-profile-settings-content .content-card .delete-account-container .icon-and-text-container {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.user-profile-settings-content .content-card .help-center-container .icon-and-text-container .icon,
.user-profile-settings-content .content-card .delete-account-container .icon-and-text-container .icon {
  color: var(--mi-grey-color-6);
  margin-right: 17px;
}
.user-profile-settings-content .content-card .help-center-container .icon-and-text-container .text,
.user-profile-settings-content .content-card .delete-account-container .icon-and-text-container .text {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
}
.user-profile-settings-content .content-card .help-center-container .icon-container,
.user-profile-settings-content .content-card .delete-account-container .icon-container {
  grid-row: 1;
  grid-column: 2;
}
.user-profile-settings-content .content-card .help-center-container .icon-container .icon,
.user-profile-settings-content .content-card .delete-account-container .icon-container .icon {
  color: var(--mi-black-color);
}
.user-profile-settings-content .content-card .logout-button {
  margin-top: 10px;
  display: flex;
  color: var(--ion-color-secondary);
  --background: var(--mi-white-color);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--mi-font-size-default);
  height: 47px;
  --border-radius: 5px;
  border: 1px solid var(--ion-color-secondary);
  text-transform: none;
}
.user-profile-settings-content .content-card .card-card-title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-1);
}
.user-profile-settings-content .content-card .card-content ion-button.ion-activated {
  --background-activated: transparent;
}
.user-profile-settings-content .content-card .card-content ion-label {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
}
.user-profile-settings-content .content-card .card-content .details-button {
  --background: var(--mi-background-color);
  font-weight: var(--font-weight-medium);
  --box-shadow: none;
  color: var(--mi-secondary-color-2);
  height: 63px;
  margin-bottom: 15px;
  --border-radius: 10px;
}
.user-profile-settings-content .content-card .card-content .details-button .details-button-content {
  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: center;
  width: 100%;
  height: 100%;
}
.user-profile-settings-content .content-card .card-content .details-button .details-button-content .item-icon {
  margin-right: 6px;
  color: var(--mi-color-text-lite);
}
.user-profile-settings-content .content-card .card-content .details-button .details-button-content .text-container {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  overflow: hidden;
}
.user-profile-settings-content .content-card .card-content .details-button .details-button-content .chevron {
  color: var(--mi-black-color);
}
.user-profile-settings-content .content-card .card-content .details-button .details-button-content .edit-details-text,
.user-profile-settings-content .content-card .card-content .details-button .details-button-content .profile-detail-text {
  text-transform: none;
  text-align: left;
}
.user-profile-settings-content .content-card .card-content .details-button .details-button-content .profile-detail-text {
  width: 90%;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-profile-settings-content .content-card .legal-links-and-information-container {
  display: none;
  margin-top: 38px;
}
.user-profile-settings-content .content-card .legal-links-and-information-container ion-text {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
}
.user-profile-settings-content .content-card .legal-links-and-information-container .legal-links {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 14px;
  justify-items: center;
  align-items: center;
}
.user-profile-settings-content .content-card .legal-links-and-information-container .information {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 7px;
  justify-items: center;
  align-items: center;
}
.user-profile-settings-content .content-card .about-text {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-weight: var(--font-weight-semi-bold);
  color: var(--mi-grey-color-2);
}
@media all and (max-width: 600px) {
  .user-profile-settings-content .content-card .legal-links-and-information-container {
    display: block;
  }
  .user-profile-settings-content .content-card .about-text {
    margin-top: 21px;
    font-weight: var(--font-weight-regular);
    color: var(--mi-color-text-lite);
  }
  .user-profile-settings-content .content-card .help-center-container {
    display: none;
  }
}
.user-profile-settings-content .no-side-margin {
  margin-left: unset !important;
  margin-right: unset !important;
}
.user-profile-settings-content .bottom-card {
  margin-bottom: 0 !important;
}
.user-profile-settings-content .bottom-card .card-content {
  padding-top: 15px;
}

ion-card {
  box-shadow: unset;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
