import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {

  @ViewChild('searchBar') searchBar: IonSearchbar;

  @Input() searchValue: string;
  @Input() placeholderString: string;
  @Input() searchIcon: string;
  @Input() clearIcon: string;
  @Input() searchBarId: string;
  @Output() ionSearchEventEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchValueChangedEmit: EventEmitter<string> = new EventEmitter<string>();
  searchTerm: string;

  constructor(
    private el: ElementRef
  ) { }

  onSearchEvent(_ev: Event): void {
    const target = _ev.target as HTMLTextAreaElement;
    this.searchTerm = target.value;
    this.ionSearchEventEmit.emit(this.searchTerm);
  }

  searchValueChanged(): void {
    this.searchValueChangedEmit.emit(this.searchValue);
  }

  focus(): void {
    this.searchBar.setFocus();
  }

  onKeyPress(event: Event): void {
    if ((event as any)?.key === 'Enter') {

      const target = event?.target as HTMLTextAreaElement;

      if (target && target?.value?.length >= 3) {
        this.onSearchEvent(event);
      }
    }
  }
}
