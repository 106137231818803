import { Platform } from 'src/config/config.model';

export function haveIntersection<T>(group1: Array<T>, group2: Array<T>): boolean {
  return !!group1.find(item1 => {
    return group2.includes(item1);
  });
}

export function waitTime(waitMsec: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, waitMsec);
  });
}

export function isMobilePlatform(platform: Platform): boolean {
  return platform === Platform.ANDROID || platform === Platform.IOS;
}

export function getJournalTag(journal: string): string {
  if(journal && journal === 'AO Videos') {
    return 'AO Video';
  } else if (journal && journal === 'youtube.com') {
    return 'YouTube';
  }
}
