// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-case-decision-container {
  padding: 35px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-case-decision-container .close-button {
  --icon-button-background-color: var(--mi-white-color);
  --icon-button-icon-size: 24px;
  --icon-button-icon-color: var(--mi-black-color);
  align-self: flex-end;
}
.create-case-decision-container .dialog-title {
  font-size: var(--font-size-extra-large-5);
  color: var(--mi-black-color);
  font-weight: var(--font-weight-bold);
  width: 500px;
  text-align: center;
  margin-bottom: 70px;
}
.create-case-decision-container .options-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.create-case-decision-container .options-container .option {
  width: 520px;
}
.create-case-decision-container .options-container .option .card-option {
  height: 380px;
  border-radius: 8px;
  padding: 54px 40px 35px 40px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.create-case-decision-container .options-container .option .card-option .sticker {
  border-left: 80px solid transparent;
  border-right: 80px solid var(--mi-primary-color);
  border-bottom: 80px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 0px;
  top: 0;
  border-top-right-radius: 8px;
  z-index: 2;
}
.create-case-decision-container .options-container .option .card-option .sticker-text {
  transform: translate(25%, -25%) rotate(45deg); /* Rotate the sticker */
  position: absolute;
  top: 20px;
  right: 18px;
  z-index: 2;
  text-transform: uppercase;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-large);
  color: var(--mi-white-color);
}
.create-case-decision-container .options-container .option .card-option app-icon {
  --icon-size: 33px;
  margin-bottom: 15px;
  align-self: baseline;
}
.create-case-decision-container .options-container .option .card-option .title {
  font-size: var(--font-size-extra-large);
  color: var(--mi-secondary-color-2);
  font-weight: var(--font-weight-medium);
  width: 200px;
  margin-bottom: 15px;
}
.create-case-decision-container .options-container .option .card-option .info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.create-case-decision-container .options-container .option .card-option .info-container .info {
  font-size: var(--font-size-regular);
  display: flex;
  gap: 5px;
  align-items: center;
}
.create-case-decision-container .options-container .option .card-option .info-container .info app-icon {
  --icon-size: 13px;
  margin: 0;
  background-color: var(--mi-white-color);
  color: var(--mi-blue-color-18);
  border-radius: 50%;
}
.create-case-decision-container .options-container .option .card-option .option-action {
  margin-top: auto;
}
.create-case-decision-container .options-container .option .card-option .option-action .additional-info {
  font-size: var(--font-size-regular);
  text-align: center;
}
.create-case-decision-container .options-container .option .card-option.manual {
  background-color: var(--mi-light-purple-1-color);
}
.create-case-decision-container .options-container .option .card-option.ai {
  background-color: var(--mi-purple-color-13);
}
.create-case-decision-container .options-container .option .limit {
  font-size: var(--font-size-small);
  text-align: center;
  margin-top: 20px;
}
.create-case-decision-container .options-container .option .limit .limit-count {
  background-color: var(--mi-purple-color-13);
  color: var(--mi-primary-color);
  padding: 2px 8px;
  border-radius: 20px;
  margin-right: 5px;
  font-weight: var(--font-weight-semi-bold);
}

ion-button {
  --background: var(--mi-primary-color);
  --border-radius: 4px;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  margin: 5px 0 5px 0;
  display: flex;
  text-transform: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
