import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Group } from './group.model';

export interface GroupActionContent {
  action: GroupsActions,
  group: Group
}

export enum GroupsActions {
  NEW_GROUP_ADDED = 'new-group-added',
  GROUP_EDITED = 'group-edited',
  GROUP_DELETED = 'group-deleted',
  GROUP_LEFT = 'group-left'
}

export interface GroupServiceConfig {
  context: string;
  serverUrl: string;
  imageProxyServerUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class GroupsDataService {

  private groupsChanged: Subject<GroupActionContent> = new Subject();

  triggerNewGroupCreatedAction(group: Group): void {
    this.groupsChanged.next({
      action: GroupsActions.NEW_GROUP_ADDED,
      group: group
    });
  }

  triggerGroupEditedAction(group: Group): void {
    this.groupsChanged.next({
      action: GroupsActions.GROUP_EDITED,
      group: group
    });
  }

  triggerGroupDeletedAction(group: Group): void {
    this.groupsChanged.next({
      action: GroupsActions.GROUP_DELETED,
      group: group
    });
  }

  triggerGroupLeftAction(group: Group): void {
    this.groupsChanged.next({
      action: GroupsActions.GROUP_LEFT,
      group: group
    });
  }

  getGroupChangesStream(): Observable<GroupActionContent> {
    return this.groupsChanged.asObservable();
  }
}
