// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.case-library-filter-selector-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 30px;
  justify-content: center;
}
.case-library-filter-selector-container .filter-by-name-field-container {
  grid-row: 1;
  grid-column: 1;
}
.case-library-filter-selector-container .filter-by-name-field-container .wrapper {
  display: flex;
  align-items: center;
  height: 46px;
  padding-left: 10px;
  padding-right: 5px;
  background-color: var(--mi-blue-color-5);
}
.case-library-filter-selector-container .filter-by-name-field-container .wrapper .search-icon {
  --icon-size: 24px;
  color: var(--mi-color-text-lite);
}
.case-library-filter-selector-container .filter-by-name-field-container .wrapper .filter-by-name-input-field {
  width: 100%;
  height: 100%;
  color: var(--mi-secondary-color-2);
  border: none;
  background: transparent;
  outline: none;
  margin-left: 0px;
  padding-left: 10px;
}
.case-library-filter-selector-container .filter-by-name-field-container .wrapper .filter-by-name-input-field::placeholder {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-16);
}
.case-library-filter-selector-container .filters-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 15px;
  justify-content: start;
}
.case-library-filter-selector-container .filters-container .title-container {
  grid-row: 1;
  grid-column: 1;
}
.case-library-filter-selector-container .filters-container .title-container .title {
  margin: 0;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.case-library-filter-selector-container .filters-container .filters-grid {
  grid-row: 2;
  grid-column: 1;
  position: relative;
}
.case-library-filter-selector-container .filters-container .filters-grid ion-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.case-library-filter-selector-container .filters-container .filters-grid ion-content app-chips app-text-chip {
  max-width: calc(100% - var(--chips-margin));
  --color: var(--mi-grey-color-13);
  --background-color: var(--mi-white-color);
  --font-size: var(--font-size-small);
  --font-weight: var(--font-weight-medium);
  cursor: pointer;
}
.case-library-filter-selector-container .filters-container .filters-grid ion-content app-chips app-text-chip.selected {
  --background-color: var(--mi-content-groups-color-9);
}
.case-library-filter-selector-container .filters-container .filters-grid ion-content app-chips app-text-chip ::ng-deep .text-chip-container {
  border: 1px solid var(--mi-content-groups-color-9);
}
.case-library-filter-selector-container .filters-container .filters-grid ion-content .empty-state-container {
  width: 100%;
}
.case-library-filter-selector-container .filters-container .filters-grid ion-content .empty-state-container .empty-state-text {
  margin: 0;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
