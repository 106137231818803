// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 0 16px 6px;
  position: relative;
}
:host .message-box {
  background-color: var(--mi-background-color);
  color: var(--mi-secondary-color-2);
  border-radius: var(--mi-standard-padding);
  border-top-left-radius: 0;
  padding: 10px;
}
:host .message-box .hover-button {
  position: absolute;
  right: 20px;
  top: 4px;
  cursor: pointer;
  color: var(--mi-white-color);
  display: none;
}
:host .message-box .space {
  height: 10px;
}
:host .message-box:hover .hover-button {
  display: block;
}
:host.chat-initiator .message-box {
  background-color: var(--mi-primary-color);
  color: var(--mi-white-color);
  border-top-left-radius: var(--mi-standard-padding);
  border-top-right-radius: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
