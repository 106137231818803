import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import appConfig from 'src/config/config';

// models
import { AOEvent } from '../../services/yeti-protocol/event';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { BaseUserProfile, NUMBER_USER_IMG_BUBBLES_CARDS } from 'src/app/modules/users/users.model';
import { Countries, Country } from 'src/app/services/yeti-protocol/countries';

// services
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import { ObjectForSharing } from 'src/app/services/sharing/sharing.model';
import { TranslationService } from 'src/app/services/translation.service';
import { Translator } from 'src/app/services/translation.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { LinkOpenerService } from 'src/app/services/link-opener.service';
import { ActionSource, ActionTracked, EventOriginTrackingParam, TrackingRequest } from '../../services/yeti-protocol/tracking';
import { RecommendationsBookmarksService } from 'src/app/services/recommendations-service/recommendations-bookmarks.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['./event-list-item.component.scss'],
})
export class EventListItemComponent implements OnInit, OnDestroy {

  @Input() event: AOEvent;
  @Input() countries: Countries;
  @Input() cardSource: ActionSource;
  @Output() eventClick: EventEmitter<AOEvent> = new EventEmitter();

  appConfig: { webUrl: string } = appConfig;
  translator = Translator;
  infoSheetId = 'events-list-item';
  user: UserProfile;

  private userSubscription: Subscription;

  constructor(
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private router: Router,
    private sharingUIService: SharingUIService,
    private translationService: TranslationService,
    private appTranslationService: AppTranslationService,
    private linkOpener: LinkOpenerService,
    private recommendationsService: RecommendationsBookmarksService,
    private authService: AuthService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  ngOnInit(): void {
    if (this.event) {
      this.event.imageUrl = this.event.imageUrl || 'assets/images/default-events-image.svg';
    }

    this.userSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  getDisplayDateForEvents(): string {
    if (this.event) {
      return this.event.endDate !== 'TBA' || null || '' ? `${moment(this.event.startDate).format('MMM D')} -
      ${moment(this.event.endDate).format('MMM D, YYYY')}`
        : `${moment(this.event.startDate).format('MMM D')}`;
    }
  }

  getEventPlace(): string {
    if (this.event) {
      if (this.event.eventVenue && this.event.country && this.getCountryByISO2(this.event.country)) {
        return `${this.event.eventVenue}, ${this.getCountryByISO2(this.event.country)?.name}`
      } else if ((this.event.country && this.getCountryByISO2(this.event.country)) || this.event.eventVenue) {
        return `${this.getCountryByISO2(this.event.country)?.name || this.event.eventVenue}`
      } else {
        return `${this.event.eventVenue}`
      }
    }
  }

  get eventParticipants(): Array<BaseUserProfile> {
    if (!this.event || !this.event?.participantsPreview?.length) {
      return [];
    }
    return this.event?.participantsPreview.slice(0, NUMBER_USER_IMG_BUBBLES_CARDS).map(participant => {
      return {
        userId: participant?.userId,
        profileImageUrl: participant?.profileImageUrl
      }
    });
  }

  get displayProfileImageBubbles(): boolean {
    return this.event?.participantsPreview && this.event?.participantsPreview.length > 0;
  }

  getTranslationLanguage(): string {
    const languages = this.translationService.getLanguages(this.translator.google);
    return languages.filter(lang => {
      if (lang.key === this.event?.language) {
        return lang;
      }
    })[0]?.name;
  }

  seeDetails(): void {
    if (this.event?.isAttendee) {
      // this track will be handled on event details page load
      const url = '/' + [this.contextService.currentContext.key, 'event', this.event?._id].join('/');
      this.router.navigate([url], { queryParams: { source: this.cardSource } });
    } else {
      const trackEventName = ActionTracked.aoEventViewOrigin;
      this.trackEventAction(this.event, trackEventName);
      if (this.event?.website) {
        this.linkOpener.open(this.event?.website);
      } else {
        this.linkOpener.open(this.contextService?.currentContext?.moreEventsLink);
      }
    }
  }

  get buttonText(): string {
    // Button should say See details, per Business request, regardless what the user's attendee status is.
    return this.appTranslationService.instant('app.Events.see-details');
  }

  shareEvent(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);

    const eventForSharing: ObjectForSharing = this.sharingUIService.getEventSharingObject(this.event);
    const title = this.appTranslationService.instant('app.dialogs.SocialSharing.share-to-apps');
    this.sharingUIService.showShareDialog(eventForSharing, title);
  }

  recommendClicked(): void {
    this.recommendationsService.recommendClickedForAOEvent(this.event, this.user);
  }

  private getCountryByISO2(iso2: string): Country {
    if (this.countries) {
      return this.countries.find(countryItem => {
        return countryItem.iso2 === iso2;
      });
    }
  }

  private trackEventAction(aoEvent: AOEvent, eventName: string): void {
    if (!aoEvent) {
      return;
    }
    const paramsToTrack: EventOriginTrackingParam = {
      eventId: aoEvent.aoEventId,
      myaoEventId: aoEvent._id,
      eventTitle: aoEvent.text,
      url: aoEvent.website,
      eventType: aoEvent.eventType
    };

    if (this.cardSource) {
      paramsToTrack.source = this.cardSource;
    }

    const trackData: TrackingRequest = {
      action: eventName,
      params: paramsToTrack
    };
    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track event action');
    });
  }
}
