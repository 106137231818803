// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-public-view-member-item-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
}
.group-public-view-member-item-container.without-settings-button {
  display: block;
}
.group-public-view-member-item-container .member-info {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content min-content;
  -moz-column-gap: 6px;
       column-gap: 6px;
  align-items: center;
}
.group-public-view-member-item-container .member-info .member-image-container {
  grid-row: 1/-1;
  grid-column: 1;
}
.group-public-view-member-item-container .member-info .member-image-container .member-image {
  --user-image-size: 40px;
}
.group-public-view-member-item-container .member-info .user-full-name {
  grid-row: 1;
  grid-column: 2;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
}
.group-public-view-member-item-container .member-info app-user-full-name {
  overflow: hidden;
}
.group-public-view-member-item-container .member-info app-user-full-name::ng-deep .user-full-name p {
  margin: unset !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group-public-view-member-item-container .member-info app-user-full-name::ng-deep .user-full-name .full-name {
  max-width: calc(100% - 15px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group-public-view-member-item-container .member-info .firstname-lastname {
  grid-row: 1;
  grid-column: 2;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group-public-view-member-item-container .member-info .country {
  grid-row: 2;
  grid-column: 2;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group-public-view-member-item-container .member-action-container {
  grid-row: 1;
  grid-column: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
