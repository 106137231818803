// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --reply-button-icon-size: 26px;
  --reply-button-icon-color: var(--mi-primary-color);
}

.reply-button-container {
  display: flex;
}
.reply-button-container button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: 1;
}
.reply-button-container button .container {
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: auto;
  -moz-column-gap: 3px;
       column-gap: 3px;
  justify-items: start;
  align-items: center;
  align-content: center;
}
.reply-button-container button .container app-icon {
  grid-row: 1;
  grid-column: 1;
  --icon-size: var(--reply-button-icon-size);
  color: var(--reply-button-icon-color);
}
.reply-button-container button .container .text {
  grid-row: 1;
  grid-column: 2;
  margin: 0;
  color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
