import { InjectionToken } from '@angular/core';
import { AOProfile, AOSignInResult } from './ao-auth.model';
import { RedirectResult } from '../logic/auth-logic.service.interface';

export interface AOAuthServiceInterface {

  signIn(redirectUrl?: string): Promise<AOSignInResult>;
  signUp(): Promise<RedirectResult>;
  signOut(): Promise<any>;
  getProfile(): Promise<AOProfile>;
  openAoLink(url: string): Promise<void>;
}

export const AO_AUTH_SERVICE = new InjectionToken<AOAuthServiceInterface>(
  'ao-auth.service'
);
