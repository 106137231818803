import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SearchTrackingSource } from 'src/app/services/yeti-protocol/search';

// services
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { SearchDataService, SearchTermContent } from 'src/app/services/search-data.service';
import { SearchUiService } from 'src/app/services/search-ui-service.service';

@Component({
  selector: 'app-search-suggest-list',
  templateUrl: './search-suggest-list.component.html',
  styleUrls: ['./search-suggest-list.component.scss'],
})
export class SearchSuggestListComponent implements OnInit, OnDestroy {

  @Input() placedInDialog: boolean;
  @Input() placedInPopOver: boolean;
  @Input() suggestList: Array<string>;

  private searchTermChangesSubscription: Subscription;

  constructor(
    private searchDataService: SearchDataService,
    private searchUiService: SearchUiService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private modalController: ModalController,
    private popoverController: PopoverController
  ) { }

  ngOnInit(): void {
    this.searchTermChangesSubscription = this.searchDataService.getSearchTermChanges()
      .subscribe((suggestList: SearchTermContent) => {
        this.executeSearchTermChanges(suggestList);
      })
  }

  ngOnDestroy(): void {
    this.searchTermChangesSubscription?.unsubscribe();
  }

  executeSearchTermChanges(searchTermContent: SearchTermContent): void {
    if (searchTermContent?.suggestList && searchTermContent?.suggestList.length > 0) {
      this.suggestList = searchTermContent?.suggestList;
    } else {

      if (this.placedInPopOver) {
        this.dismissDialog();
      } else {
        this.suggestList = searchTermContent?.suggestList;
      }

      this.searchUiService.triggerFinishedGetSuggestList();
    }
  }

  removeHTMLTags(suggestion: string): string {
    return suggestion.replace('<b>', '').replace('</b>', '');
  }

  searchItemClick(searchTerm: string): void {

    const searchTermEncoded = window.encodeURIComponent(searchTerm);

    this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/search?searchTerm=${searchTermEncoded}`,
      { state: { id: 'source', name: SearchTrackingSource.NEW } });

    this.dismissDialog();
  }

  dismissDialog(): void {
    if (this.placedInDialog) {
      this.modalController.dismiss();
    }

    if (this.placedInPopOver) {
      this.popoverController.dismiss();
    }
  }
}
