// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --messenger-list-container-padding-top: 20px;
  --messenger-list-container-padding-bottom: 15px;
  --messenger-list-container-padding-start: 15px;
  --messenger-list-container-padding-end: 15px;
  --messenger-list-container-background-color: var(--mi-white-color);
}

.messenger-list-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  padding-top: 0;
  padding-bottom: var(--messenger-list-container-padding-bottom);
  background-color: var(--messenger-list-container-background-color);
}
.messenger-list-container .list-header {
  display: flex;
  grid-row: 1;
  grid-column: 1;
  padding-left: var(--messenger-list-container-padding-start);
  padding-right: var(--messenger-list-container-padding-end);
}
.messenger-list-container .list-header.with-action {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  align-items: center;
}
.messenger-list-container .list-header.with-action .title-and-count-container {
  grid-row: 1;
  grid-column: 1;
}
.messenger-list-container .list-header.with-action .action-container {
  grid-row: 1;
  grid-column: 3;
  justify-self: end;
}
.messenger-list-container .list-header .title-and-count-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
  flex-grow: 1;
}
.messenger-list-container .list-header .title-and-count-container .title-container {
  margin-right: 10px;
}
.messenger-list-container .list-header .title-and-count-container .title-container .title {
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
  font-size: var(--font-size-large);
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
}
.messenger-list-container .list-header .title-and-count-container .count-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mi-red-color);
  padding: 5px;
  border-radius: 50%;
}
.messenger-list-container .list-header .title-and-count-container .count-container .count {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-white-color);
  margin: 0;
  white-space: nowrap;
}
.messenger-list-container .list-header .title-and-count-container .reload-button-container {
  margin-left: 7px;
}
.messenger-list-container .list-header .title-and-count-container .reload-button-container app-icon-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: none;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0%;
  --icon-button-icon-size: 17px;
  --icon-button-icon-color: var(--mi-color-text-lite);
}
@media all and (max-width: 550px) {
  .messenger-list-container .list-header .title-and-count-container .reload-button-container {
    display: none;
  }
}
.messenger-list-container .list-header .action-container .see-all-button {
  background: none;
  outline: none;
}
.messenger-list-container .list-header .action-container .see-all-button .button-text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-primary-color);
  margin: 0;
  white-space: nowrap;
}
.messenger-list-container .list-header .action-edit-container {
  flex-grow: 0;
}
.messenger-list-container .list-header .action-edit-container .write-message-button {
  background-color: var(--mi-white-color);
}
.messenger-list-container .list-header .action-edit-container .write-message-button ion-icon {
  width: 18px;
  height: 18px;
}
.messenger-list-container .list-content {
  grid-row: 2;
  grid-column: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
