import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import appConfig from 'src/config/config';

// components
import { RichTextDocumentEditorComponent } from
  '../../modules/rich-text-document-editor/components/rich-text-document-editor/rich-text-document-editor.component';

// models
import { GetMentions } from '../../modules/rich-text-document-editor/rich-text-document-editor.model';
import { Group } from '../../services/groups/group.model';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { Platform } from 'src/config/config.model';

// services
import { AuthService } from '../../services/auth/auth.service';
import { RichTextDocumentEditorUiService } from
  'src/app/modules/rich-text-document-editor/services/rich-text-document-editor-ui-service.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { AttachmentMimeType, ImageAttachment, isVideo } from 'src/app/services/attachments.model';
import { ModalController } from '@ionic/angular';
import { InfoSheetVisibilityStatusChange } from 'src/app/modules/info-sheet/components/info-sheet/info-sheet.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { DomUtilsService } from 'src/app/services/utils/dom-utils.service';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import { TimeoutService } from 'src/app/services/utils/timeout.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { FileType } from 'src/app/modules/file-select/file-select.model';
import { PersonalMediaGalleryDocument } from 'src/app/services/yeti-protocol/personal-media-gallery';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';
import {
  CreateEditCasePostBottomBarComponent,
  CreateEditCasePostBottomBarConfig
} from 'src/app/components/create-edit-case-post-bottom-bar/create-edit-case-post-bottom-bar.component';
import { ConfirmDialogData, FieldMaxTextLengthExceeded } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { PostAttachmentsUploadEditComponent } from 'src/app/components/post-attachments-upload-edit/post-attachments-upload-edit.component';
import {
  ContentParentType,
  CreateContentStrategy,
  CreatePostAction,
  PostData,
  PostWithCaseData,
  UpdatePostData
} from 'src/app/services/create-content/create-content';
import { getImagesWithCorrectUrlBasedOnWatermark } from '../../services/utils/image-attachments.utils';
import { ParentType } from 'src/app/services/yeti-protocol/chatter-api-schema';
import { CreateContentParentSelectComponent } from 'src/app/components/create-content-parent-select/create-content-parent-select.component';
import { Keyboard } from '@awesome-cordova-plugins/keyboard';
import { ImageGalleryService } from '../../modules/file-select/services/image-gallery.service';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
})
export class CreatePostComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('richTextDocumentEditor') richTextDocumentEditor: RichTextDocumentEditorComponent;
  @ViewChild('createEditCasePostBottomBar') createEditCasePostBottomBar: CreateEditCasePostBottomBarComponent;
  @ViewChild('postAttachmentsUploadEdit') postAttachmentsUploadEdit: PostAttachmentsUploadEditComponent;
  @ViewChild('createContentParentSelectComponent') createContentParentSelectComponent: CreateContentParentSelectComponent;
  @ViewChild('createPostContainer') createPostContainerElementRef: ElementRef;

  @Input() group: Group;
  @Input() action: CreatePostAction;
  @Input() postContentText: string;
  @Input() createContentStrategy: CreateContentStrategy;

  FileType = FileType;
  AttachmentMimeType = AttachmentMimeType;
  infoSheetId = 'create-group-post-info-sheet';
  postContentPlainText = '';
  postContentMaxLength = 2000;

  pageTitle: string;
  inputField: any;
  disabled: boolean;
  user: UserProfile;
  creatingPost: boolean;
  imageFiles: Array<PersonalMediaGalleryDocument> = [];
  documentFiles: Array<PersonalMediaGalleryDocument> = [];
  showInformationSharingWarning = true;
  isAnonymousOwner = false;
  isNeedAdvice = false;
  isHideMyIdentityToggled = false;
  watermarked = false;
  createEditCasePostBottomBarConfig: CreateEditCasePostBottomBarConfig;
  newSelectedGroup: Group;
  ContentParentType = ContentParentType;

  private userSubscription: Subscription;
  private postContent: string;
  private routerEventsSubscription: Subscription;
  private resizeSubscription: Subscription;
  private originalPostDescription: string;
  private originalPostIsAnonymous: boolean;
  private originalWatermarkedValue: boolean;
  private originalPostAttachments: Array<PersonalMediaGalleryDocument> = [];
  private createGroupPostContainerObserver: any;
  private settingsShowed: boolean;
  private imageChangedSubscription: Subscription;
  private imageEdited: boolean;

  constructor(
    private el: ElementRef,
    private authService: AuthService,
    private richTextDocumentEditorUiService: RichTextDocumentEditorUiService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private navController: NavControllerService,
    private appTranslationService: AppTranslationService,
    private modalController: ModalController,
    private responsiveUtilsService: ResponsiveUtilsService,
    private domUtils: DomUtilsService,
    private timeoutService: TimeoutService,
    private toast: ToastService,
    private dialogs: DialogsUIService,
    private imageGalleryService: ImageGalleryService
  ) {
  }

  ngOnInit(): void {

    this.createEditCasePostBottomBarConfig = {
      images: {
        avaliable: () => { return this.canAddEditImageAttachments },
      },
      videos: {
        avaliable: () => { return this.canAddEditImageAttachments },
      },
      documents: {
        avaliable: () => { return this.canAddEditDocumentAttachments },
      },
      case: {
        avaliable: () => { return !this.createContentStrategy?.post },
      },
      emojis: {
        avaliable: (): boolean => { return this.isDesktop },
      },
      watermark: {
        avaliable: () => { return true },
        disabled: () => { return this.disableWatermarkButton },
        active: () => { return this.watermarked }
      },
      settings: {
        avaliable: (): boolean => { return !this.isDesktop }
      },
    }

    this.userSubscription = this.authService?.userProfileAsObservable?.subscribe(userProfile => {
      this.user = userProfile;
    });

    this.resizeSubscription = this.responsiveUtilsService.resize.subscribe(() => this.screenSizeChanged());

    this.imageChangedSubscription = this.imageGalleryService.imageUpdated.subscribe(image => {
      this.updateImage(image);
    })
  }

  ngAfterViewInit(): void {

    this.timeoutService.setTimeout(() => { // used to put the callback function last in call stack
      this.initEditingPost();
      this.setRichTextEditorHeightValues();
      this.openSpecificAction();
      this.setcreateGroupPostContainerObserver();
      if (this.postContentText && this.postContentText.length) {
        this.richTextDocumentEditor.content = ' ' + this.postContentText;
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.routerEventsSubscription?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
    this.createGroupPostContainerObserver?.disconnect();
    this.imageChangedSubscription?.unsubscribe();
  }

  updateImage(updatedImage: ImageAttachment): void {

    if (!updatedImage) {
      return;
    }

    this.imageFiles = this.imageFiles?.map(image => {

      if (image?._id && image._id === updatedImage._id) {
        image.previewUrl = updatedImage.previewUrl;
        image.fullUrl = updatedImage.fullUrl;
        image.fullWatermarkUrl = updatedImage.fullWatermarkUrl;
        this.imageEdited = true;
      }

      return image;
    }) || [];
  }

  addEmoji(event: { emoji: any, $event: Event }): void {

    let contentWithAddedEmoji = '';
    const indexOfLastClosingTag = this.postContent?.lastIndexOf('</');

    if (indexOfLastClosingTag > 0) {
      const firstPart = this.postContent?.substring(0, indexOfLastClosingTag) || '';
      const secondPart = this.postContent?.substring(indexOfLastClosingTag, this.postContent.length) || '';

      contentWithAddedEmoji = `${firstPart}${event.emoji.native}${secondPart}`;
    } else {
      contentWithAddedEmoji = `${this.postContent}${event.emoji.native}`;
    }

    this.postContent = contentWithAddedEmoji;
    this.postContentText = this.postContent;

    if (this.createContentStrategy?.post?.description !== null && this.createContentStrategy?.post?.description !== undefined) {
      this.createContentStrategy.post.description = this.postContentText;
    }
  }

  openSpecificAction(): void {

    if (!this.action) {
      return;
    }

    switch (this.action) {
      case CreatePostAction.ADD_IMAGES:
        if (this.canAddEditImageAttachments) {
          this.addImages();
        }
        break;
      case CreatePostAction.ATTACH_DOCUMENT:
        if (this.canAddEditDocumentAttachments) {
          this.attachDocument();
        }
        break;
      case CreatePostAction.ADD_VIDEO:
        if (this.canAddEditDocumentAttachments) {
          this.addVideos();
        }
        break;
    }
  }

  setRichTextEditorHeightValues(): void {

    if (!this.richTextDocumentEditor?.setEditorHeight) {
      return;
    }

    setTimeout(() => {
      this._adaptTextEditorHeight();
    }, 750);
  }

  _adaptTextEditorHeight(): void {
    const infoSheetActionsContainer = this.domUtils.querySelector('.create-group-post-attachments-action-sheet');
    const topBarContainer = (this.el.nativeElement as HTMLElement).querySelector('.top-bar-container');
    const selectGroupContainer = (this.el.nativeElement as HTMLElement).querySelector('.select-group-container');
    const selectedParentContainer = (this.el.nativeElement as HTMLElement).querySelector('.selected-parent-container');
    const selectParentContainer = (this.el.nativeElement as HTMLElement).querySelector('.select-parent-container');
    const createGroupPostContentContainer = (this.el.nativeElement as HTMLElement).querySelector('.create-post-input-container');
    const bottomBarContainer = (this.el.nativeElement as HTMLElement).querySelector('.bottom-bar');
    const userInfoContainer = (this.el.nativeElement as HTMLElement).querySelector('.user-info-container');
    const attachmentsContainer = (this.el.nativeElement as HTMLElement).querySelector('.attachments-container');
    const contentLimitCounterContainer = (this.el.nativeElement as HTMLElement).querySelector('.limit-counter');
    const componentContainer = this.createPostContainerElementRef.nativeElement;
    const toggleActionsContainer = (this.el.nativeElement as HTMLElement).querySelector('.toggle-actions-container');
    const clinicalCaseContainer = (this.el.nativeElement as HTMLElement).querySelector('.clinical-case-container');

    // This was used to display the blue warning sign, for now it is not used,
    // we are keeping it here in order to be needed again
    // const informationSharingWarningContainer = (this.el.nativeElement as HTMLElement)
    //   .querySelector('.information-sharing-warning-container');

    createGroupPostContentContainer?.removeAttribute('style');

    const infoSheetActionsContainerHeight = this._getElementClientHeight(infoSheetActionsContainer);
    let createGroupPostContentContainerHeight = this._getElementClientHeight(createGroupPostContentContainer);
    const topBarContainerHeight = this._getElementClientHeight(topBarContainer);
    const selectGroupContainerHeight = this._getElementClientHeight(selectGroupContainer);
    const selectedParentContainerHeight = this._getElementClientHeight(selectedParentContainer);
    const selectParentContainerHeight = this._getElementClientHeight(selectParentContainer);
    const bottomBarContainerHeight = this._getElementClientHeight(bottomBarContainer);
    const componentContainerHeight = this._getElementClientHeight(componentContainer);
    const contentLimitCounterContainerHeight = contentLimitCounterContainer?.clientHeight > 0 ?
      contentLimitCounterContainer.clientHeight + 7 : 0;
    const clinicalCaseContainerHeight = this._getElementClientHeight(clinicalCaseContainer);
    const additionalKeyboardSpace = Keyboard.isVisible && clinicalCaseContainerHeight > 10 ? 57 : 0;

    const createGroupPostContentContainerCalculatedHeight = componentContainerHeight -
      (topBarContainerHeight + selectGroupContainerHeight + selectedParentContainerHeight +
        selectParentContainerHeight + bottomBarContainerHeight + additionalKeyboardSpace);

    if (infoSheetActionsContainerHeight > 0) {
      createGroupPostContentContainerHeight -= (infoSheetActionsContainerHeight - bottomBarContainerHeight);
      // -10px padding
      createGroupPostContentContainer?.setAttribute('style', `
        max-height: ${createGroupPostContentContainerHeight - 10}px;
        height: ${createGroupPostContentContainerCalculatedHeight}px
      `);
    } else {
      createGroupPostContentContainer?.setAttribute('style', `
        height: ${createGroupPostContentContainerCalculatedHeight}px
      `);
    }

    const userInfoContainerHeight = this._getElementClientHeight(userInfoContainer);
    const attachmentsContainerHeight = this._getElementClientHeight(attachmentsContainer);
    // const informationSharingWarningContainerHeight = this._getElementClientHeight(informationSharingWarningContainer);
    const toggleActionsHeight = this._getElementClientHeight(toggleActionsContainer);

    const paddingTop = parseInt(window.getComputedStyle(createGroupPostContentContainer, null).getPropertyValue('padding-top'), 10);
    const paddingBottom = parseInt(window.getComputedStyle(createGroupPostContentContainer, null).getPropertyValue('padding-bottom'), 10);
    const padding = paddingTop + paddingBottom;

    const containerElHeightSum = userInfoContainerHeight +
      attachmentsContainerHeight + padding + contentLimitCounterContainerHeight +
      toggleActionsHeight + selectGroupContainerHeight + (clinicalCaseContainerHeight + 10); // + informationSharingWarningContainerHeight

    const richTextDocumentEditorHeight = createGroupPostContentContainerHeight - containerElHeightSum;

    this.richTextDocumentEditor?.setEditorHeight(`${richTextDocumentEditorHeight}px`, null, '50px');
  }

  _getElementClientHeight(element: Element): number {
    return element?.clientHeight > 0 ? element.clientHeight : 0;
  }

  initEditingPost(): void {
    this.originalPostDescription = this.createContentStrategy?.post?.description;
    this.originalPostIsAnonymous = this.createContentStrategy?.post?.isAnonymous ? true : false;
    this.originalWatermarkedValue = this.createContentStrategy?.post?.watermarked ? true : false;
    this.watermarked = this.createContentStrategy?.post?.watermarked ? true : false;
    this.initEditingPostAttachments();
  }

  initEditingPostAttachments(): void {

    this.originalPostAttachments = [...this.createContentStrategy?.post?.attachments || []];

    if (this.createContentStrategy?.isPostTypeAttachment && this.createContentStrategy?.post?.attachments?.length > 0) {
      const attachment = this.createContentStrategy?.post?.attachments?.[0];

      if (!attachment || !attachment?.mimeType) {
        return;
      }

      if (attachment.mimeType.indexOf('image') > -1 || isVideo(attachment.mimeType)) {
        this.setImageFiles(this.createContentStrategy?.post?.attachments);
      } else {
        this.setDocumentFiles(this.createContentStrategy?.post?.attachments);
      }
    }

    this.postContent = this.createContentStrategy?.post?.description;
    this.setPostContentPlainText(this.postContent);
  }

  get canAddEditImageAttachments(): boolean {
    const attachmentMimeType = this.createContentStrategy?.post?.attachments?.length ?
      this.createContentStrategy?.post?.attachments?.[0]?.mimeType : '';
    const attachmentsAreImages = this.createContentStrategy?.post?.attachments?.length &&
      (attachmentMimeType?.indexOf('image') > -1 ||
        isVideo(attachmentMimeType));
    const postHasAttachments = this.createContentStrategy?.isPostTypeAttachment && attachmentsAreImages;

    const postTypeIsLink = this.createContentStrategy.isPostTypeLink;
    const postTypeIsText = this.createContentStrategy.isPostTypeText;

    return !this.createContentStrategy?.post ||
      postTypeIsLink ||
      postTypeIsText || postHasAttachments ? true : false;
  }

  get canAddEditDocumentAttachments(): boolean {
    const attachmentsAreDocuments = this.createContentStrategy?.post?.attachments?.length &&
      this.createContentStrategy?.post?.attachments?.[0]?.mimeType.indexOf('pdf') > -1;
    const postHasAttachments = this.createContentStrategy?.isPostTypeAttachment && attachmentsAreDocuments;

    const postTypeIsLink = this.createContentStrategy.isPostTypeLink;
    const postTypeIsText = this.createContentStrategy.isPostTypeText;

    return !this.createContentStrategy?.post || postTypeIsLink || postTypeIsText || postHasAttachments ? true : false;
  }

  postContentChanged(content: string): void {
    this.postContent = content;
    this.setPostContentPlainText(this.postContent);
  }

  showPageSettings(): void {
    if (!this.shouldShowActionSheet || this.createContentStrategy?.clinicalCase) {
      return;
    }

    if (this.createEditCasePostBottomBar?.showPageSettings) {
      this.createEditCasePostBottomBar?.showPageSettings();
      this.settingsShowed = true;
    }
  }

  closeSettings(): void {
    this.createEditCasePostBottomBar?.closeSettings();
    this.settingsShowed = false;
  }

  infoSheetVisibilityStatusChange(infoSheetVisibilityStatusChange: InfoSheetVisibilityStatusChange): void {
    if (infoSheetVisibilityStatusChange.infoSheetId === this.createEditCasePostBottomBar.infoSheetId) {
      this.setRichTextEditorHeightValues();
    }
  }

  setAnonymousOwnerValue(value: boolean): void {
    this.isAnonymousOwner = value;
    this.isHideMyIdentityToggled = true;

    if (this.isAnonymousOwner) {
      this.watermarked = false;
    }
  }

  setAdviceNeededValue(value: boolean): void {
    this.isNeedAdvice = value;
  }

  confirmCommunityGuidelines(): Promise<void> {
    this.closeSettings();
    return this.dialogs.showConfirmCommunityGuidelines()
      .then(confirmed => {
        if (confirmed) {

          if (this.creatingPost) {
            return;
          }

          this.creatingPost = true;

          if (this.createContentStrategy?.clinicalCase) {
            return this.createPostWithACase();
          } else {
            return this.createPost();
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  async createPost(): Promise<void> {

    const textFieldsLengthNotGood = await this.checkTextFieldsMaxLengthExceeded();

    if (textFieldsLengthNotGood) {
      this.creatingPost = false;
      return;
    }

    if ((await this.checkAndShowAnonymousOwnerError())) {
      this.creatingPost = false;
      return;
    }

    const imageFiles = this.getImageFiles();
    const documentFiles = this.getDocumentFiles();

    let url = '';

    if (!imageFiles?.length && !documentFiles?.length) {
      url = this.richTextDocumentEditorUiService.extractFirstLinkFromContent(this.postContent) || '';
    }

    let attachments = null;
    if (imageFiles?.length) {
      attachments = imageFiles.map(image => image._id);
    } else if (documentFiles?.length) {
      attachments = documentFiles.map(document => document._id);
    }

    const postData: PostData = {
      description: this.postContent || '',
      title: '',
      file: null,
      url: url,
      type: this.createContentStrategy?.determinePostType(url, attachments),
      isAnonymous: this.isAnonymousOwner,
      watermarked: this.watermarked,
      documents: attachments,
      parentIds: null,
      parentType: null
    }

    this.createContentStrategy.createPost(postData).finally(() => {
      this.creatingPost = false;
    })
  }

  async createPostWithACase(): Promise<void> {

    if (!this.createContentStrategy?.clinicalCase?._id) {
      this.creatingPost = false;
      return;
    }

    if ((await this.checkAndShowAnonymousOwnerError())) {
      this.creatingPost = false;
      return;
    }

    const postData: PostWithCaseData = {
      description: this.postContent || '',
      type: 'case',
      isAnonymous: this.isAnonymousOwner,
      needAdvice: this.isNeedAdvice,
      objectId: this.createContentStrategy?.clinicalCase?._id,
      parentIds: null,
      parentType: null
    }

    this.createContentStrategy.createPostWithACase(postData).finally(() => {
      this.creatingPost = false;
    })
  }

  async updatePost(): Promise<void> {

    const textFieldsLengthNotGood = await this.checkTextFieldsMaxLengthExceeded();

    if (this.creatingPost || textFieldsLengthNotGood) {
      this.creatingPost = false;
      return;
    }

    this.creatingPost = true;

    if ((await this.checkAndShowAnonymousOwnerError())) {
      this.creatingPost = false;
      return;
    }

    const imageFiles = this.getImageFiles();
    const documentFiles = this.getDocumentFiles();
    const url = this.richTextDocumentEditorUiService.extractFirstLinkFromContent(this.postContent);
    let attachments: Array<string> = [];
    if (imageFiles && imageFiles.length > 0) {
      attachments = imageFiles.map(image => image._id);
    } else if (documentFiles && documentFiles.length > 0) {
      attachments = documentFiles.map(document => document._id);
    } else {
      attachments = this.createContentStrategy?.post?.attachments?.map(attachment => attachment._id);
    }
    const type = this.createContentStrategy.determinePostType(url, attachments);
    const updatedData: UpdatePostData = {
      title: '',
      description: this.postContent,
      url: url,
      type: type,
      isAnonymous: this.isAnonymousOwner,
      watermarked: this.watermarked,
      documents: attachments,
      parentId: null,
      parentType: null
    };

    this.createContentStrategy.updatePost(updatedData).then(() => {
      this.navController.back();
    }).finally(() => {
      this.creatingPost = false;
    })
  }

  get isCreateUpdatePostDataValid(): boolean {

    if (this.postAttachmentsUploadEdit?.attachmentsUploading ||
      this.postAttachmentsUploadEdit?.attachmentsProcessing) {
      return false;
    }

    if (this._hasNewOrChangedAttachments) {
      // if new files post / update is available
      return true;
    }

    if (this.createContentStrategy?.post) { // editing post
      return this._isEditingPostDataValid;
    } else if (this.postContent?.length) {
      // if post has description create is available
      return true;
    } else if (this.createContentStrategy?.clinicalCase) {
      return true;
    }

    return false;
  }

  get _isEditingPostDataValid(): boolean {

    if (this.postContent !== this.originalPostDescription) {
      if (this.postContent?.length || // description is added and it is new update is available
        this.imageFiles?.length || // description is removed and post contains image / video attachments update is available
        this.documentFiles?.length // description is removed and post contains document attachments update is available
      ) {
        return true;
      }
    }

    this.isAnonymousOwner = this.isAnonymousOwner ? true : false;

    if (this.isAnonymousOwner !== this.originalPostIsAnonymous) {
      return true;
    }

    if (this.watermarked !== this.originalWatermarkedValue) {
      return true;
    }
    return false;
  }

  get _hasNewOrChangedAttachments(): boolean {

    if (this.createContentStrategy?.post && this.imageEdited) {
      return true;
    }

    if (!this.createContentStrategy?.post) {
      return this.imageFiles.length || this.documentFiles.length ? true : false;
    } else if (this.createContentStrategy?.post &&
      !this.createContentStrategy?.post?.attachments?.length &&
      (this.imageFiles?.length || this.documentFiles?.length)) {
      return true;
    } else {
      if (this.imageFiles?.length || this.documentFiles?.length) {

        const attachment = this.createContentStrategy?.post?.attachments[0];
        let newAttachments: Array<PersonalMediaGalleryDocument> = [];

        if (attachment.mimeType.indexOf('image') > -1 || isVideo(attachment.mimeType)) {
          newAttachments = this.imageFiles;
        } else {
          newAttachments = this.documentFiles;
        }

        if (this.originalPostAttachments?.length !== newAttachments.length) {
          return true;
        } else {

          let returnValue = false;

          newAttachments.forEach(newAttachment => {
            const index = this.originalPostAttachments?.findIndex(att => att._id === newAttachment._id);

            if (index === -1) {
              returnValue = true;
            }
          });

          return returnValue;
        }
      }

      return false;
    }
  }

  addImages(): void {
    this.closeSettings();
    this.postAttachmentsUploadEdit.addImages();
  }

  addVideos(): void {
    this.closeSettings();
    this.postAttachmentsUploadEdit.addVideos();
  }

  attachDocument(): void {
    this.closeSettings();
    this.postAttachmentsUploadEdit.attachDocument();
  }

  async createCase(): Promise<void> {

    const shouldProceedWithAction = await this.continueIfStateIsNotSaved();

    if (shouldProceedWithAction?.role === 'confirm' || shouldProceedWithAction === true) {
      this.closeSettings();
      this.modalController?.dismiss({ customClose: true });
      this.createContentStrategy.shareCaseInPost = true;
      this.createContentStrategy?.openCreateCase(null, true);
    }
  }

  getImageFiles(): Array<PersonalMediaGalleryDocument> {
    return this.imageFiles;
  }

  setImageFiles(imageFiles: Array<PersonalMediaGalleryDocument>): Promise<any> {

    if (!imageFiles) {
      this.imageFiles = [];
      return;
    }

    this.imageFiles = imageFiles;

    setTimeout(() => {
      this.setRichTextEditorHeightValues();
    }, 0);
  }

  getDocumentFiles(): Array<PersonalMediaGalleryDocument> {
    return this.documentFiles;
  }

  setDocumentFiles(documentFiles: Array<PersonalMediaGalleryDocument>): void {

    if (!documentFiles) {
      this.documentFiles = [];
      return;
    }

    this.documentFiles = documentFiles;

    setTimeout(() => {
      this.setRichTextEditorHeightValues();
    }, 0);
  }

  removeAttachmentFiles(initEditingPostAttachments?: boolean): void {

    this.setImageFiles(null);
    this.setDocumentFiles(null);

    if (this.createContentStrategy?.post && initEditingPostAttachments) {
      this.initEditingPostAttachments();
    }
  }

  async onBack(): Promise<void> {

    const shouldProceedWithBack = await this.continueIfStateIsNotSaved();

    if (shouldProceedWithBack?.role === 'confirm' || shouldProceedWithBack === true) {
      this.navController.back();
    }
  }

  async continueIfStateIsNotSaved(): Promise<any> {

    let promptUserAboutNotSavedState = false;

    if ((!this.createContentStrategy?.post ||
      !this.createContentStrategy?.post?.attachments?.length) &&
      (this.imageFiles?.length || this.documentFiles?.length)) {
      promptUserAboutNotSavedState = true;
    }

    if (this.createContentStrategy?.post?.attachments?.length) {

      const attachment = this.createContentStrategy?.post?.attachments[0];
      let newAttachments: Array<PersonalMediaGalleryDocument> = [];

      if (attachment?.mimeType.indexOf('image') > -1 || isVideo(attachment?.mimeType)) {
        newAttachments = this.imageFiles;
      } else {
        newAttachments = this.documentFiles;
      }

      if (this.createContentStrategy?.post?.attachments?.length &&
        newAttachments.length &&
        this.createContentStrategy?.post?.attachments?.length !== newAttachments.length) {
        promptUserAboutNotSavedState = true;
      } else {
        newAttachments.forEach(newAttachment => {
          const index = this.createContentStrategy?.post?.attachments?.findIndex(att => att._id === newAttachment._id);

          if (index === -1) {
            promptUserAboutNotSavedState = true;
          }
        })
      }
    }

    if (this.createContentStrategy?.post) {
      if (this.postContent !== this.originalPostDescription) {
        promptUserAboutNotSavedState = true;
      }
    } else {
      if (this.postContent) {
        promptUserAboutNotSavedState = true;
      }
    }

    if (promptUserAboutNotSavedState) {

      if (this.settingsShowed) {
        this.closeSettings();
      }

      return this.createContentStrategy.promptUserAboutNotSavedState();
    } else {
      return true;
    }
  }

  hideInformationSharingWarning(): void {
    this.showInformationSharingWarning = false;

    setTimeout(() => { // put this in the end of call stack so that ui has time for change
      this.setRichTextEditorHeightValues();
    }, 0);
  }

  inputBlur(): void {
    setTimeout(() => {
      this.setRichTextEditorHeightValues();
    }, 0);
  }

  inputFocus(): void {
    this.closeSettings();
    setTimeout(() => {
      this.setRichTextEditorHeightValues();
    }, 0);
  }

  get postSaveButtonDisabled(): boolean {
    return this.creatingPost ||
      ((!this.createContentStrategy?.parentIds?.length && !this.createContentStrategy?.parentsData?.length) ||
        !this.isCreateUpdatePostDataValid);
  }

  newGroupSelected(group: Group): void {

    this.createContentStrategy.parentsData = [{
      parents: [group],
      parentsType: ParentType.GROUP
    }];

    this.newSelectedGroup = group;

    setTimeout(() => {
      this.setRichTextEditorHeightValues();
    }, 100);
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get shouldShowActionSheet(): boolean {
    return !this.isDesktop && !this.action;
  }

  groupSelectionIsOpened(): void {
    if (!this.isDesktop) {
      this.closeSettings();
    }
  }

  async checkTextFieldsMaxLengthExceeded(): Promise<boolean> {

    const postDescription = this.postContentMaxLength;
    const fieldsMaxLengthExceeded: Array<FieldMaxTextLengthExceeded> = [];
    let notValidField = false;

    if (this.postContentPlainText?.length > postDescription) {

      const fieldName = await this.appTranslationService.get('app.components.CreatePost.description');

      fieldsMaxLengthExceeded.push({
        fieldName: fieldName,
        maxTextLength: postDescription
      });

      notValidField = true;
    }

    if (notValidField) {
      this.dialogs.showFieldsMaxTextLengthExceeded(fieldsMaxLengthExceeded);
    }

    return notValidField;
  }

  setPostContentPlainText(postContentHtml: string): void {
    this.postContentPlainText = stripHtmlTagsFromString(postContentHtml);
  }

  setcreateGroupPostContainerObserver(): void {

    const targetNode = this.el.nativeElement.querySelector('.create-post-container');
    const config = { childList: true, subtree: true };

    if (!this.createGroupPostContainerObserver) {
      this.createGroupPostContainerObserver = new (window as any).MutationObserver(() => {

        this.setRichTextEditorHeightValues();
        this.createGroupPostContainerObserver.disconnect();
      });
    }

    this.createGroupPostContainerObserver?.observe(targetNode, config);
  }

  onGetMentions(getMentions: GetMentions): void {

    if (this.isAnonymousOwner) {
      return getMentions.resolve([]);
    }

    this.createContentStrategy.getParentMembers(getMentions.queryText, 0, 3, getMentions.excludedUserIds).then(members => {

      if (!members?.length) {
        getMentions.resolve([]);
      }

      if (members?.length > 3) {
        members = members?.splice(0, 3);
      }

      const users = members?.map((member: any) => {
        return {
          _id: member?.userId,
          userId: member?.userId,
          id: `@${member?.firstName} ${member?.lastName}`,
          firstName: member?.firstName,
          lastName: member?.lastName,
          country: member?.country,
          imageUrl: member?.profileImageUrl,
          isAOMember: member?.isAOMember,
          isVerified: member?.isVerified
        }
      });

      getMentions.resolve(users);
    }).catch(err => {
      const toastMessage = err.error.error.message.errfor.message;
      this.toast.showWithMessage(toastMessage);

      getMentions.reject(err);
    });
  }

  get isPostWithMentions(): boolean {
    return this.postContent && this.postContent.indexOf('class="mention"') > -1 ? true : false;
  }

  get disableIdentityHidden(): boolean {
    const isClinicalCaseWithWatermarkedImages = this.createContentStrategy?.clinicalCase &&
      this.createContentStrategy?.clinicalCase?.watermarked ? true : false;
    return this.isPostWithMentions || isClinicalCaseWithWatermarkedImages;
  }

  get clinicalCaseImages(): Array<ImageAttachment> {
    const caseImages = [
      ...getImagesWithCorrectUrlBasedOnWatermark(
        this.createContentStrategy?.clinicalCase?.preOpDocuments || [], this.createContentStrategy?.clinicalCase.watermarked
      ),
      ...getImagesWithCorrectUrlBasedOnWatermark(
        this.createContentStrategy?.clinicalCase?.intraOpDocuments || [], this.createContentStrategy?.clinicalCase.watermarked
      ),
      ...getImagesWithCorrectUrlBasedOnWatermark(
        this.createContentStrategy?.clinicalCase?.postOpDocuments || [], this.createContentStrategy?.clinicalCase.watermarked
      ),
      ...getImagesWithCorrectUrlBasedOnWatermark(
        this.createContentStrategy?.clinicalCase?.clinicalFollowUpDocuments || [], this.createContentStrategy?.clinicalCase.watermarked
      ),
    ];

    return caseImages.length > 0 ? caseImages : [];
  }

  toggleWatermark(): void {

    if (this.isAnonymousOwner) {
      const hiddenIdentityErrorMessage = this.appTranslationService.instant('app.watermark.hidden-identity-watermark-error');
      this.toast.showWithMessage(hiddenIdentityErrorMessage, null, ToastMode.ERROR);
      return;
    }

    if (!this.imageFiles?.length) {
      const noImagesErrorMessage = this.appTranslationService.instant('app.watermark.no-images-watermark-error');
      this.toast.showWithMessage(noImagesErrorMessage, null, ToastMode.ERROR);
      return;
    }

    this.watermarked = !this.watermarked;
  }

  get disableWatermarkButton(): boolean {
    return this.isAnonymousOwner || !this.imageFiles?.length;
  }

  get disableCreateContentParentSelect(): boolean {

    const parentType = this.createContentStrategy.parentsData?.[0].parentsType || null;

    return (parentType === ParentType.CHAT ||
      parentType === ParentType.USER) && this.createContentStrategy.post ? true : false;
  }

  get showAnonymousPost(): boolean {
    return (this.createContentParentSelectComponent?.selectedParent && this.createContentStrategy?.allowAnonymousPost) ||
      (this.createContentStrategy?.parent && !this.disableIdentityHidden);
  }

  private screenSizeChanged() {
    if (this.shouldShowActionSheet && appConfig.platform === Platform.BROWSER) {
      this.showPageSettings()
    } else {
      this.closeSettings();
    }
  }

  private async checkAndShowAnonymousOwnerError(): Promise<boolean> {

    const anonymousOwnerErrorConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.groups.anonymous-owner-and-mentions-error-dialog-title'
        },
        message: {
          translationKey: 'app.groups.anonymous-owner-and-mentions-error-dialog-text'
        },
        actions: [
          {
            key: 'got-it',
            label: {
              translationKey: 'app.common.got-it',
            },
            className: 'primary'
          }
        ]
      }
    }

    if (this.isAnonymousOwner && this.isPostWithMentions) {
      const errorDialog = await this.dialogs.createConfirmDialog(anonymousOwnerErrorConfirmDialogData);
      errorDialog.present();
      return true;
    } else {
      return false;
    }
  }
}
