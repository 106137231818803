import { Component, OnDestroy, OnInit } from '@angular/core';
import { GroupsService } from '../../services/groups/groups.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import {
  GroupMemberPendingRequestsResponse,
  GroupMemberPendingRequestsSuccessResponse,
  GroupMemberRequest
} from 'src/app/services/yeti-protocol/chatter-api';
import { AcceptRejectItemData } from 'src/app/components/accept-reject-list/accept-reject-list.component';
import { Subscription } from 'rxjs';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { IonRefresher } from '@ionic/angular';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';

@Component({
  selector: 'app-group-member-requests-dialog',
  templateUrl: './group-member-requests-dialog.component.html',
  styleUrls: ['./group-member-requests-dialog.component.scss'],
})
export class GroupMemberRequestsDialogComponent implements OnInit, OnDestroy {

  memberPendingRequestsList: Array<GroupMemberRequest>;
  memberPendingRequestsListLoading: boolean;
  totalMemberPendingRequests: number;
  acceptRejectRequestsItems: Array<AcceptRejectItemData>;
  respondingToRequest: boolean;

  readonly start = 0;
  readonly count = 20;

  private userProfileSubscription: Subscription;
  private userProfile: UserProfile;

  constructor(
    private modalController: ModalController,
    private groupsService: GroupsService,
    private toast: ToastService,
    private authService: AuthService,
    private appNavController: AppNavController,
  ) { }

  ngOnInit(): void {
    this.userProfileSubscription = this.authService.userProfileAsObservable
      .subscribe((user: UserProfile) => {
        this.userProfile = user;
      });

    this.getMemberRequests().then(res => {
      this.totalMemberPendingRequests = res?.total || 0;
      this.memberPendingRequestsList = res?.requests || [];
      this.acceptRejectRequestsItems = this.adaptMemberRequestsData();
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  getMemberRequests(start: number = this.start): Promise<{ total: number, requests: Array<GroupMemberRequest> }> {

    if (!this.groupsService) {
      return null;
    }

    this.memberPendingRequestsListLoading = true;

    return this.groupsService.getGroupMemberPendingRequests(start, this.count)
      .then((response: GroupMemberPendingRequestsResponse) => {
        if (response as GroupMemberPendingRequestsSuccessResponse) {
          return {
            total: (response as GroupMemberPendingRequestsSuccessResponse).totalItemsCount,
            requests: (response as GroupMemberPendingRequestsSuccessResponse).result
          }
        }
        return null;
      }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showError(err?.error?.error?.message?.errfor?.message);
        }
        return null;
      }).finally(() => {
        this.memberPendingRequestsListLoading = false;
      });
  }

  async refreshMemberRequests(refresher: IonRefresher): Promise<void> {
    this.getMemberRequests().then(res => {
      this.totalMemberPendingRequests = res?.total || 0;
      this.memberPendingRequestsList = res?.requests || [];
      this.acceptRejectRequestsItems = this.adaptMemberRequestsData();
    }).finally(() => {
      refresher.complete();
    });
  }

  async loadMoreMemberRequests(verticalListLoadMoreData: VerticalListLoadMoreData): Promise<void> {

    const start = Math.floor(this.memberPendingRequestsList.length / this.count);

    this.getMemberRequests(start).then(res => {
      this.totalMemberPendingRequests = res?.total || 0;
      this.memberPendingRequestsList = [...this.memberPendingRequestsList, ...res?.requests || []];
      this.acceptRejectRequestsItems = this.adaptMemberRequestsData();
    }).finally(() => {
      verticalListLoadMoreData.infiniteScroll.complete();
    });
  }

  private adaptMemberRequestsData(): Array<AcceptRejectItemData> {

    if (!this.memberPendingRequestsList?.length) {
      return [];
    }

    return this.memberPendingRequestsList.map(request => {
      return {
        id: request?.memberRequestId || '',
        imageUrl: request?.requester?.profileImageUrl || '',
        userId: request?.requester?.userId || '',
        isAOMember: request?.requester?.isAOMember || '',
        firstName: request?.requester?.firstName || '',
        lastName: request?.requester?.lastName || '',
        isVerified: request?.requester?.isVerified || '',
        orcid: request?.requester?.orcid || '',
        entityTitle: request?.groupInfo?.title
      } as AcceptRejectItemData;
    })
  }

  onAccepted(data: { id: string, userId: string }): void {

    const request = this.memberPendingRequestsList.find(req => req.memberRequestId === data.id);

    if (!request) {
      return;
    }

    this.acceptOrDeclineRequest('Accepted', request);
  }

  onRejected(data: { id: string, userId: string }): void {

    const request = this.memberPendingRequestsList.find(req => req.memberRequestId === data.id);

    if (!request) {
      return;
    }

    this.acceptOrDeclineRequest('Decline', request);
  }

  private acceptOrDeclineRequest(status: string, groupMemberRequest: GroupMemberRequest): void {

    if (this.respondingToRequest) {
      return;
    }

    this.respondingToRequest = true;

    this.groupsService.updateMemberRequests(
      groupMemberRequest.memberRequestId, status, groupMemberRequest.requester.userId, groupMemberRequest.groupInfo._id
    ).then(_result => {
      if (status === 'Accepted') {
        this.toast.show('app.components.GroupsFeed.requests.accepted', 'app.common.success', ToastMode.SUCCESS);
      } else {
        this.toast.show('app.components.GroupsFeed.requests.rejected', 'app.common.success', ToastMode.SUCCESS);
      }

      this.groupsService.triggerGroupsRequestsChanged();

      this.getMemberRequests().then(res => {
        this.totalMemberPendingRequests = res?.total || 0;
        this.memberPendingRequestsList = res?.requests || [];
        this.acceptRejectRequestsItems = this.adaptMemberRequestsData();
      });
    }).catch(async err => {
      if (err?.error?.error?.message?.errfor?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message);
      }
    }).finally(() => {
      this.respondingToRequest = false;
    })
  }

  imageClicked(userId: string): void {
    this.appNavController.openPublicUserProfile(userId);
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  async onBack(): Promise<void> {
    this.modalController.dismiss();
  }

}
