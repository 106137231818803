// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --top-bar-width: 100%;
  --top-bar-height: 100%;
  --top-bar-padding-top: 12px;
  --top-bar-padding-bottom: 12px;
  --top-bar-padding-start: 15px;
  --top-bar-padding-end: 15px;
  --top-bar-margin-top: 0px;
  --top-bar-margin-bottom: 0px;
  --top-bar-margin-start: 0px;
  --top-bar-margin-end: 0px;
  --top-bar-color: var(--ion-toolbar-color);
  --top-bar-background-color: var(--mi-white-color);
  --top-bar-column-gap: 10px;
  --top-bar-title-text-align: center;
}

ion-header {
  box-shadow: none;
  --box-shadow: none;
}
ion-header ion-toolbar {
  --background: var(--top-bar-background-color);
  --ion-toolbar-color: var(--top-bar-color);
}
ion-header ion-toolbar .top-bar {
  --background: var(--top-bar-background-color);
  width: var(--top-bar-width);
  height: var(--top-bar-height);
  padding: var(--top-bar-padding-top) var(--top-bar-padding-end) var(--top-bar-padding-bottom) var(--top-bar-padding-start);
  margin: var(--top-bar-margin-top) var(--top-bar-margin-end) var(--top-bar-margin-bottom) var(--top-bar-margin-start);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto;
  align-items: center;
  align-content: center;
}
ion-header ion-toolbar .top-bar.with-actions-container {
  grid-template-columns: min-content auto min-content;
}
ion-header ion-toolbar .top-bar.show-custom-container {
  -moz-column-gap: var(--top-bar-column-gap);
       column-gap: var(--top-bar-column-gap);
}
ion-header ion-toolbar .top-bar .back-button {
  grid-row: 1;
  grid-column: 1;
  --icon-font-size: 24px;
  --icon-color: var(--mi-secondary-color-2);
}
ion-header ion-toolbar .top-bar .title-container {
  grid-row: 1;
  grid-column: 2;
  overflow: hidden;
  justify-self: center;
  width: calc(100% - 30px);
  margin: 0 auto;
  position: relative;
  height: 22px;
}
ion-header ion-toolbar .top-bar .title-container .title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-secondary-color-2);
  text-align: var(--top-bar-title-text-align);
  margin: auto;
  position: absolute;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 600px) {
  ion-header ion-toolbar .top-bar .title-container {
    margin: none;
  }
}
ion-header ion-toolbar .top-bar .actions-container {
  grid-row: 1;
  grid-column: 3;
}
ion-header ion-toolbar .top-bar .custom-container {
  grid-row: 1;
  grid-column: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
