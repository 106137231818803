// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --group-info-card-padding-top: 0;
  --group-info-card-padding-bottom: 15px;
  --group-info-card-padding-start: 15px;
  --group-info-card-padding-end: 15px;
  --group-info-card-group-header-image-height: 93px;
  --group-info-card-group-image-size: 76px;
  --group-info-card-group-image-overlap: 46px;
  --group-info-card-background-color: var(--mi-white-color);
}

.group-info-card-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: calc(var(--group-info-card-group-header-image-height) - var(--group-info-card-group-image-overlap)), var(--group-info-card-group-image-overlap) calc(var(--group-info-card-group-image-size) - var(--group-info-card-group-image-overlap)) min-content min-content;
  background-color: var(--group-info-card-background-color);
  padding-bottom: var(--group-info-card-padding-bottom);
}
.group-info-card-container .group-header-image-container {
  grid-row: 1/4;
  grid-column: 1;
  width: 100%;
  height: var(--group-info-card-group-header-image-height);
}
.group-info-card-container .group-header-image-container .header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.group-info-card-container .group-image-container {
  grid-row: 3/5;
  grid-column: 1;
  width: 100%;
  height: 100%;
  padding: 0 var(--group-info-card-padding-end) 0 var(--group-info-card-padding-start);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
}
.group-info-card-container .group-image-container app-group-image {
  grid-row: 1;
  grid-column: 1;
  --group-image-size: var(--group-info-card-group-image-size);
  --group-image-border-radius: 12px;
  --group-image-border-width: 3px;
}
.group-info-card-container .group-image-container .button-container {
  grid-row: 1;
  grid-column: 2;
  align-self: end;
}
.group-info-card-container .title-container {
  grid-row: 5;
  grid-column: 1;
  padding: 7px var(--group-info-card-padding-end) 0 var(--group-info-card-padding-start);
}
.group-info-card-container .title-container .title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-secondary-color-2);
  margin: 0;
}
.group-info-card-container .title-container .anchor-tag {
  text-decoration: underline;
  cursor: pointer;
}
.group-info-card-container .group-visibility-container {
  grid-row: 6;
  grid-column: 1;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content auto;
  -moz-column-gap: 5.5px;
       column-gap: 5.5px;
  align-items: center;
  justify-content: start;
  padding: 0 var(--group-info-card-padding-end) 0 var(--group-info-card-padding-start);
}
.group-info-card-container .group-visibility-container .icon {
  color: var(--mi-color-text-lite);
}
.group-info-card-container .group-visibility-container .group-visibility {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-large);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group-info-card-container .mobile-container {
  flex-direction: row !important;
  justify-content: space-between;
}
.group-info-card-container .mobile-container ion-button {
  width: unset !important;
}
.group-info-card-container .group-members-and-actions-container {
  display: flex;
  flex-direction: column;
  padding: 17px var(--group-info-card-padding-end) 0 var(--group-info-card-padding-start);
  gap: 10px;
}
.group-info-card-container .group-members-and-actions-container .group-action-container {
  justify-content: center;
}
.group-info-card-container .group-members-and-actions-container .group-action-container .actions-container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content min-content;
  -moz-column-gap: 10px;
       column-gap: 10px;
  justify-content: center;
}
.group-info-card-container .group-members-and-actions-container .group-action-container .actions-container ion-button {
  width: 105px;
}
.group-info-card-container .group-members-and-actions-container .group-action-container .actions-container .request {
  width: 100%;
}
.group-info-card-container div.community-guidelines {
  padding: 17px var(--group-info-card-padding-end) 0 var(--group-info-card-padding-start);
  text-align: center;
}
.group-info-card-container div.community-guidelines .text {
  color: var(--mi-secondary-color-2);
  white-space: normal;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
}
.group-info-card-container div.community-guidelines .text span {
  color: var(--mi-primary-color);
  cursor: pointer;
}

app-info-sheet {
  --info-sheet-content-grid-row-gap: 20px;
  --info-sheet-padding-bottom: 20px;
}
app-info-sheet app-info-sheet-actions-container app-info-sheet-action .notification-icon {
  color: var(--mi-danger);
  --icon-size: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
