// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --group-case-button-text-color: var(--mi-color-text-lite);
  --group-case-button-icon-color: var(--mi-grey-color-13);
}

.group-case-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.group-case-button-container .group-case-button {
  background: none;
  outline: none;
  padding: 0;
}
.group-case-button-container .group-case-button .button-content-container:not(.iconOnly) {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto;
  -moz-column-gap: 3.5px;
       column-gap: 3.5px;
  align-items: center;
}
.group-case-button-container .group-case-button .button-content-container.iconOnly {
  display: flex;
  justify-content: center;
  align-items: center;
}
.group-case-button-container .group-case-button .button-content-container .icon {
  grid-row: 1;
  grid-column: 1;
  font-size: 22px;
  color: var(--group-case-button-icon-color);
}
.group-case-button-container .group-case-button .button-content-container .button-text {
  grid-row: 1;
  grid-column: 2;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-large);
  color: var(--group-case-button-text-color);
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
