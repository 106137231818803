import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

// services
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-messenger-list-container',
  templateUrl: './messenger-list-container.component.html',
  styleUrls: ['./messenger-list-container.component.scss'],
})
export class MessengerListContainerComponent {

  @Input() title: string;
  @Input() count: number;
  @Input() showAction: boolean;
  @Input() showEditButton = false;

  @Output() actionClick: EventEmitter<void> = new EventEmitter();
  @Output() reloadClick: EventEmitter<void> = new EventEmitter();
  @Output() openNewMessage: EventEmitter<void> = new EventEmitter();

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  actionClicked(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.actionClick.emit();
  }

  reloadButtonClicked(): void {
    this.reloadClick.emit();
  }

  showNewMessageDialog(): void {
    this.openNewMessage.emit();
  }
}
