import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mi-card',
  templateUrl: './mi-card.component.html',
  styleUrls: ['./mi-card.component.scss'],
})
export class MiCardComponent {
  @Input() noPadding: boolean;
  @Input() noMargin: boolean;
}
