// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container {
  padding: 10px;
  width: 100%;
  background-color: var(--mi-pending-requests-color);
}
.list-container .list-item {
  width: 100%;
}
.list-container .pending-requests-list-label {
  margin-left: 15px;
  color: var(--ion-color-primary);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-regular) !important;
}
.list-container .header {
  font-size: 18px;
  font-weight: var(--font-weight-semi-bold);
  line-height: 42px;
}
.list-container .labels-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.list-container .label-medium {
  font-size: var(--font-size-regular);
  font-weight: var(--int-font-weight-regular);
  letter-spacing: 0;
  line-height: 42px;
}
.list-container .label-small {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  line-height: 42px;
}
.list-container .grid {
  display: grid;
  grid-template-columns: 300px 300px;
}

.participant-card {
  display: flex;
  flex-direction: column;
  min-width: 207px;
  max-width: 207px;
  max-height: 213px;
  min-height: 213px;
}
.participant-card .participant-image {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  overflow: hidden;
}
.participant-card app-user-profile-image {
  --user-image-size: 70px;
  --ao-icon-size: 30px;
}
.participant-card .participant-card-header {
  background-color: var(--mi-white-color);
  opacity: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.participant-card .participant-card-content {
  padding-bottom: 0;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
}
.participant-card .participant-card-content app-user-full-name {
  --name-text-align: center;
  --country-text-font-size: var(--font-size-small);
  --country-text-color: var(--mi-color-text-lite);
  --country-text-font-weight: var(--font-weight-regular);
  --country-text-align: center;
}
.participant-card .participant-card-content .participant-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 60px;
}
.participant-card .participant-card-content .participant-additional-info {
  margin: 5px 0;
}
.participant-card .participant-card-content .participant-additional-info .additional-info-item {
  font-size: var(--mi-font-size-smaller);
  background-color: var(--ion-color-secondary-light-blue);
  color: var(--ion-color-secondary-bright-blue);
  border-radius: 12px;
  padding: 2px 7px;
  margin: 5px 5px 5px 0;
}
.participant-card .participant-card-content .participant-additional-info .additional-info-item .owner-country {
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
  font-weight: var(--font-weight-regular);
}
.participant-card .participant-card-content .participant-additional-info .display-flex {
  display: flex;
}
.participant-card .participant-card-content .secondary-text {
  color: var(--mi-color-text-lite);
  font-size: var(--mi-font-size-smaller);
  font-weight: var(--font-weight-regular);
  display: flex;
}
.participant-card .participant-card-content .action-button-position-container .action-button-container {
  display: flex;
  justify-content: space-between;
}
.participant-card .participant-card-content .action-button-position-container .action-button-container .action-button {
  display: flex;
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--mi-font-size-smaller);
  --border-radius: 5px;
  height: 30px;
  width: 80px;
  text-transform: none;
}
.participant-card .participant-card-content .action-button-position-container .action-button-container .decline-request {
  --background: var(--mi-white-color);
  color: var(--ion-color-secondary);
  border: 1px solid var(--ion-color-secondary);
  border-radius: 5px;
}
.participant-card .participant-card-content .participant-option {
  text-align: center;
}
.participant-card .participant-card-content .bottom-info-content {
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
  font-weight: var(--font-weight-regular);
  display: flex;
  justify-content: space-between;
  margin: 10px 0 15px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
