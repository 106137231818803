import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { BehaviorSubject, firstValueFrom, from, Observable, Observer, Subject, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { LAST_URL, LOCAL_STORAGE_SERVICE, StorageService } from '../../storage/storage.service';
import { SchemaValidatorService } from '../../schema-validator.service';


import {
  DeleteProfileRequestParams,
  DeleteProfileResponse,
  LoginRequest,
  LoginResponse,
  LoginResponseWithTokens,
  RefreshTokenRequest,
  RefreshTokensResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignupRequest,
  SignupResponse,
  SocialProfile,
  SocialSigninRequest,
  SocialSigninResponse,
  SocialSignupRequest,
  SocialSignupResponse,
  UpdateUserProfileData,
  UpdateUserProfileRequest,
  UpdateUserProfileResponse,
  UserProfile,
  UserProfileResponse,
  SocialAOSigninRequest
} from '../../yeti-protocol/auth/mi';

import appConfig from '../../../../config/config';
import { AuthProvider } from '../../yeti-protocol/auth/provider';
import { AuthRequestOptions, RedirectResult, SignUpData, SignUpStatus } from '../logic/auth-logic.service.interface';
import { TimeoutService } from '../../utils/timeout.service';
import { AuthenticationAOConfig, AuthenticationConfig, ContextConfigType } from 'src/config/config.model';
import { contextForClient } from 'src/app/contexts/utils';
import { toAuthRequestParams } from '../logic/auth-logic.utils';
import { ActionSource } from '../../yeti-protocol/tracking';
import { VerificationStatus } from '../../verification.model';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CONTEXT_SERVICE, ContextService } from '../../context/context.model';

const KEY_TOKENS = 'profile-tokens';
export const KEY_PROFILE = 'profile-data';
export const TODO_WAIT_FOR_REGISTRATION = 'wait-for-registration';

const PROFILE_VERSION = '2';
const REFRESH_TOKEN_PROMISE_CLEANUP_TIMEOUT = 500;

const testMode = false;
export const PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF = 1000;

export interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export interface SocialSignInData {
  accessToken: string;
  profile: SocialProfile;
  providerData?: { [key: string]: string }; // provider specific fields
}

export interface SignInResult {
  hasToMigrate: boolean;
  aoMigrationAgreement: boolean;
  jwt: string;
}

export type AuthenticationHeaders = { [header: string]: string; };

export type RefreshExternalTokens = () => Promise<void>;

const DEFAULT_CONTEXT = 'myAO';

interface MIAuthServiceConfig {
  authentication: AuthenticationConfig;
  authenticationAO?: AuthenticationAOConfig;
  serverUrl: string;
  serverUrlIonic: string;
  contexts: Array<ContextConfigType>;
}

@Injectable({
  providedIn: 'root'
})
export class MIAuthService {
  config: MIAuthServiceConfig = appConfig; // for testing
  _tokensCache: Tokens = undefined;
  refreshOtherTokens: RefreshExternalTokens = null;
  VerificationStatus = VerificationStatus;

  private userProfileSubject: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
  private userAdminGroupsSubject: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  private isSignedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private accessBlockedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private initPromise: Promise<void> = null;
  private refreshTokensPromise: Promise<void> = null;
  private miTokensRefreshSubject: Subject<void> = new Subject();

  constructor(
    private httpClient: HttpClient,
    @Inject(LOCAL_STORAGE_SERVICE) private storage: StorageService,
    private schemaValidator: SchemaValidatorService,
    private timeoutService: TimeoutService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
  ) {
    this.init();
  }

  set refreshOtherTokensFunction(func: RefreshExternalTokens) {
    this.refreshOtherTokens = func;
  }

  get userProfileAsObservable(): Observable<UserProfile> {
    return from(this.init()).pipe(mergeMap(() => {
      return this.userProfileSubject.asObservable();
    }));
  }

  get isSignedInAsObservable(): Observable<boolean> {
    return from(this.init()).pipe(mergeMap(() => {
      return this.isSignedInSubject.asObservable();
    }));
  }

  get userAdminGroupsAsObservable(): Observable<Array<string>> {
    return from(this.init()).pipe(mergeMap(() => {
      return this.userAdminGroupsSubject.asObservable();
    }));
  }

  get accessBlockedAsObservable(): Observable<boolean> {
    return from(this.init()).pipe(mergeMap(() => {
      return this.accessBlockedSubject.asObservable();
    }));
  }

  get miTokensRefreshObservable(): Observable<void> {
    return this.miTokensRefreshSubject.asObservable();
  }

  getSignupStatus(context: string = DEFAULT_CONTEXT): Promise<SignUpStatus> {
    return this.isSignedIn()
      .then(isSignedIn => {
        if (isSignedIn) {
          return this.getProfile(context);
        }
        return null;
      })
      .then(profile => {
        if (!profile) {
          return SignUpStatus.UNKNOWN;
        }
        if (!profile.homeDevision) {
          return SignUpStatus.NO_HOME_DEVISION;
        }
        if (!this._isKnownHomeDevision(profile.homeDevision)) {
          return SignUpStatus.NO_HOME_DEVISION;
        }
        if (!profile.profession && profile?.verificationStatus !== VerificationStatus.VERIFIED) {
          return SignUpStatus.NO_SPECIALTY;
        }
        // if (!profile.countryConfirmed) {
        //   return SignUpStatus.NO_COUNTRY;
        // }
        if (profile.hasToMigrate) {
          return SignUpStatus.NOT_CONFIRMED;
        }
        return SignUpStatus.FINISHED;
      })
      .catch(() => {
        return Promise.resolve(SignUpStatus.UNKNOWN);
      });
  }

  _isKnownHomeDevision(homeDevision: string): boolean {
    const devisionKey = contextForClient(homeDevision.toLowerCase());
    const foundContext = this.config.contexts.find(context => {
      return devisionKey === context.key;
    });
    return !!foundContext;
  }

  isSignedIn(): Promise<boolean> {
    if (this._tokensCache === null) {
      return Promise.resolve(false);
    } else if (this._tokensCache !== undefined) {
      return Promise.resolve(true);
    } else {
      return this.storage.get(KEY_TOKENS)
        .then((data: any) => {
          if (data?.accessToken && data?.refreshToken) {
            this._tokensCache = data;
            return true;
          }
          return false;
        })
        .catch(() => {
          return Promise.resolve(false);
        });
    }
  }

  fakeSignInExpire(): Promise<void> {
    return this.storage.get(KEY_TOKENS)
      .then((value: any) => {
        value.accessToken = 'expired-' + value.accessToken;
        return this.storage.set(KEY_TOKENS, value);
      })
      .then(() => {
        console.log('fake token expire set');
      })
      .catch(err => {
        console.error(err);
        console.log('FAIL set fake token expire');
      });
  }


  signIn(username: string, password: string): Promise<SignInResult> {
    this.accessBlockedSubject.next(false);
    const requestData: LoginRequest = {
      username,
      password,
      client_id: this.config.authentication.clientId,
      client_secret: this.config.authentication.clientSecret,
      grant_type: 'password',
      redirect_uri: this.config.authenticationAO?.redirectUri || ''
    };
    return new Promise((resolve, reject) => {
      this.httpClient.post<LoginResponse>(this.config.authentication.serverUrl + 'access_token', requestData)
        .pipe(
          this.schemaValidator.isValidOperator<LoginResponse>('auth/mi/LoginResponse')
        ).subscribe({
          next: data => {
            this._processTokens(data)
              .then(res => {
                resolve(res);
              })
              .catch(err => reject(err));
          },
          error: err => {
            if (err.status === 403) {
              this.accessBlockedSubject.next(true);
            }
            reject(err);
          }
        });
    });
  }

  signUp(authData: SignUpData): Promise<any> {
    this.accessBlockedSubject.next(false);
    const requestData: SignupRequest = {
      appid: this.config.authentication.clientId,
      redirect_uri: 'NYI',
      email: authData.username,
      password: authData.password,
      firstname: authData.firstName,
      lastname: authData.lastName,
    };
    if (authData?.sourceCampaign && authData?.sourceCampaign?.marketingCampaign) {
      requestData.marketingCampaign = authData?.sourceCampaign?.marketingCampaign;
    }
    return new Promise((resolve, reject) => {
      this.securePost<SignupRequest, SignupResponse>(this.config.serverUrl + 'signup', requestData)
        .pipe(
          this.schemaValidator.isValidOperator<SignupResponse>('auth/mi/SignupResponse')
        ).subscribe({
          next: data => resolve(data),
          error: err => reject(err)
        });
    })
      .then((data: SignupResponse) => {
        const tokens: Tokens = {
          accessToken: data.access_token,
          refreshToken: data.refresh_token
        };
        this._tokensCache = tokens;
        return Promise.all([
          data,
          this.getProfile(this.config.authentication.clientId),
          this.storage.set(KEY_TOKENS, tokens as any)
        ]);
      })
      .then(res => {
        const [data, profile] = res;
        this._onProfileChanged(profile);
        return {
          hasToMigrate: data.hasToMigrate
        };
      });
  }

  _processTokens(data: LoginResponseWithTokens): Promise<SignInResult> {
    // TODO: convert into operator
    const tokens: Tokens = {
      accessToken: data.access_token,
      refreshToken: data.refresh_token
    };
    this._tokensCache = tokens;
    return this.storage.set(KEY_TOKENS, tokens as any)
      .then(() => {
        return this.getProfile(this.config.authentication.clientId);
      })
      .then(profile => {
        this._onProfileChanged(profile);
        return {
          hasToMigrate: data.hasToMigrate,
          aoMigrationAgreement: data.aoMigrationAgreement,
          jwt: data.jwt
        };
      });
  }

  signInSocial(provider: AuthProvider, socialSignInData: SocialSignInData): Promise<SignInResult> {
    this.accessBlockedSubject.next(false);
    const data: Partial<SocialSigninRequest> = {
      profileId: socialSignInData.profile.id,
      appid: this.config.authentication.clientId,
      accessToken: socialSignInData.accessToken
    };
    if ('providerData' in socialSignInData) {
      _.extend(data, socialSignInData.providerData);
    }
    return new Promise((resolve, reject) => {
      if (testMode) { // test
        return reject({
          error: {
            todo: TODO_WAIT_FOR_REGISTRATION
            // todo: 'register-social-account'
          }
        });
      }
      this.httpClient.post<SocialSigninResponse>(this._getSocialUrl(provider), data, { observe: 'response' })
        .pipe(
          map(resp => {
            return resp.body;
          }),
          this.schemaValidator.isValidOperator<SocialSigninResponse>('auth/mi/SocialSigninResponse')
        ).subscribe({
          next: res => resolve(res),
          error: err => {
            console.log(err);
            if (err.status === 400) {
              if (!err.error) {
                err.error = {}
              }
              err.error.todo = TODO_WAIT_FOR_REGISTRATION;
            }
            reject(err);
          }
        });
    }).then((res: SocialSigninResponse) => {
      return this._processTokens(res);
    });
  }

  signInSocialAO(code: string, redirectUri: string): Promise<SignInResult> {
    this.accessBlockedSubject.next(false);

    const data: SocialAOSigninRequest = {
      code: code,
      appId: 'myAO',
      redirectUri: redirectUri
    }

    return new Promise((resolve, reject) => {
      this.httpClient.post<SocialSigninResponse>(this._getSocialUrl(AuthProvider.AO), data, { observe: 'response' })
        .pipe(
          map(resp => {
            return resp.body;
          }),
          this.schemaValidator.isValidOperator<SocialSigninResponse>('auth/mi/SocialSigninResponse')
        ).subscribe({
          next: res => resolve(res),
          error: err => {
            console.log(err);
            // if (err.status === 400) {
            //   if (!err.error) {
            //     err.error = {}
            //   }
            //   err.error.todo = TODO_WAIT_FOR_REGISTRATION;
            // }
            reject(err);
          }
        });
    }).then((res: SocialSigninResponse) => {
      return this._processTokens(res);
    });
  }

  _getSocialUrl(provider: AuthProvider): string {
    return `${this.config.serverUrl}social/login/v2/${provider}`;
  }

  _getSocialSignUpUrl(provider: AuthProvider): string {
    return `${this.config.serverUrl}social/signup/v2/${provider}`;
  }

  signUpSocial(provider: AuthProvider, socialSignInData: SocialSignInData): Promise<SignInResult> {
    this.accessBlockedSubject.next(false);
    const data: SocialSignupRequest = {
      appid: this.config.authentication.clientId,
      accessToken: socialSignInData.accessToken,
      email: socialSignInData.profile.original.email,
      profile: {
        id: socialSignInData.profile.id,
        original: socialSignInData.profile.original
      }
    };
    if (socialSignInData.providerData) {
      _.extend(data, socialSignInData.providerData);
    }

    const url = this._getSocialSignUpUrl(provider);
    return new Promise((resolve, reject) => {
      this.httpClient.post<SocialSignupResponse>(url, data, { observe: 'response' })
        .pipe(
          map(resp => {
            return resp.body;
          }),
          this.schemaValidator.isValidOperator<SocialSignupResponse>('auth/mi/SocialSignupResponse')
        ).subscribe({
          next: res => resolve(res),
          error: err => reject(err)
        });
    }).then((res: SocialSigninResponse) => {
      return this._processTokens(res);
    });
  }

  signOut(): Promise<RedirectResult> {
    return Promise.all([
      this.storage.remove(KEY_TOKENS),
      this.storage.remove(KEY_PROFILE)
    ]).then(() => {
      this._tokensCache = undefined;
      return null;
    });
  }

  // without caching
  fetchProfile(context: string = DEFAULT_CONTEXT): Promise<{ profile: UserProfile, adminGroups: any }> {
    const options = {
      params: {
        appId: context
      }
    };
    return firstValueFrom(this.secureGet<UserProfileResponse>(this.config.serverUrlIonic + 'profile', options).pipe(
      this.schemaValidator.isValidOperator('auth/mi/UserProfileResponse'),
      map((profileData: UserProfileResponse) => {
        if ('profile' in profileData) {
          return {
            profile: profileData.profile,
            adminGroups: profileData.adminGroups
          };
        } else {
          throwError(() => profileData);
        }
      })
    ));
  }

  _fetchProfile(context: string = DEFAULT_CONTEXT): Promise<UserProfile> {
    return this.fetchProfile(context)
      .then(profileData => {

        if (context === DEFAULT_CONTEXT) {
          context = profileData?.profile?.homeDevision;
        }

        return Promise.all([
          profileData.profile,
          this.storage.set(KEY_PROFILE, {
            context,
            profile: profileData.profile,
            adminGroups: profileData.adminGroups,
            date: context === 'test' ? '' : new Date()
          } as any)
        ]);
      })
      .then(res => {
        const [profile] = res;
        this._onProfileChanged(profile);
        return profile;
      });
  }

  getProfile(context: string = DEFAULT_CONTEXT, reload: boolean = false, preventReloadBellowXMs?: number): Promise<UserProfile> {
    return this.asserIsSignedIn()
      .then(() => {
        return this.storage.get(KEY_PROFILE);
      })
      .then(async (data: any) => {

        if (data && data.context === context) {
          if (!reload) {
            return { profile: data.profile, storageData: data };
          } else if (data?.date && preventReloadBellowXMs > 0) { // if time from last fetched profile is less then x ms prevent backend call

            const diffInMs = moment.duration(
              moment().diff(data?.date)
            )?.asMilliseconds();

            if (diffInMs <= preventReloadBellowXMs) {
              return { profile: data.profile, storageData: data };
            } else {

              let profile = null;

              try {
                profile = await this._fetchProfile(context);
              } catch (err) {
                profile = null;
              }

              return { profile: profile, storageData: data };
            }
          } else {

            let profile = null;

            try {
              profile = await this._fetchProfile(context);
            } catch (err) {
              profile = null;
            }

            return { profile: profile, storageData: data };
          }
        } else {

          if (data && context === DEFAULT_CONTEXT) {
            return { profile: data.profile, storageData: data };
          }

          let profile = null;

          try {
            profile = await this._fetchProfile(context);
          } catch (err) {
            profile = null;
          }

          return { profile: profile, storageData: data };
        }
      })
      .then((data: any) => {

        const profile = data?.profile;
        const storageData = data?.storageData;

        if (context === DEFAULT_CONTEXT) {
          const homeDevision = (profile) ? profile.homeDevision : '';
          if (homeDevision && homeDevision !== '' && homeDevision !== context && storageData?.context !== homeDevision) {
            return this._fetchProfile(homeDevision);
          } else {
            return profile;
          }
        } else {
          return profile;
        }
      });
  }

  getAuthenticationHeadersAsObservable(): Observable<AuthenticationHeaders> {
    return from(this.getAuthenticationHeaders());
  }

  getAuthenticationHeaders(): Promise<AuthenticationHeaders> {
    if (this._tokensCache !== undefined) {
      return Promise.resolve({
        access_token: this._tokensCache.accessToken
      });
    }
    return this.storage.get(KEY_TOKENS)
      .then(data => {
        let headers = {};
        if (data) {
          headers = {
            access_token: (data as Tokens).accessToken
          };
        }
        return headers;
      });
  }

  updateProfile(context: string, data: UpdateUserProfileData, withoutCaching = false,
    source: ActionSource = ActionSource.unspecified): Promise<UserProfile> {
    return this.asserIsSignedIn()
      .then(() => {
        (data as UpdateUserProfileRequest).profileVersion = PROFILE_VERSION;
        let url = `${this.config.serverUrlIonic}profile`;
        if (source && source !== ActionSource.unspecified) {
          url += '?source=' + source;
        }
        return firstValueFrom(this.securePost<UpdateUserProfileData, UpdateUserProfileResponse>(url, data, { params: { appId: context } })
          .pipe(
            this.schemaValidator.isValidOperator('auth/mi/UpdateUserProfileResponse'),
          ));
      })
      .then(() => {
        if (withoutCaching) {
          return this.fetchProfile(context)
            .then(res => {
              this._onProfileChanged(res.profile);
              return res.profile;
            });
        }
        return this._fetchProfile(context);
      });
  }

  deleteProfile(context: string): Promise<DeleteProfileResponse> {
    return this.asserIsSignedIn()
      .then(() => {
        const params: DeleteProfileRequestParams = {
          appId: context
        }
        const url = `${this.config.serverUrlIonic}profile`;
        return firstValueFrom(this.secureDelete<any, DeleteProfileResponse>(url, null, { params: toAuthRequestParams(params) })
          .pipe(
            this.schemaValidator.isValidOperator<DeleteProfileResponse>('auth/mi/DeleteProfileResponse'),
          ));
      })
  }

  _onProfileChanged(data: UserProfile): void {
    this.userProfileSubject.next(data);
    this.isSignedInSubject.next(!!data);
    this.getUserAdminGroups()
      .then(adminGroups => {
        this.userAdminGroupsSubject.next(adminGroups);
      });
  }

  resetPassword(email: string): Promise<boolean> {
    const url = this.config.serverUrl + 'password/send';
    const data: ResetPasswordRequest = {
      email,
      appid: this.config.authentication.clientId
    }
    return firstValueFrom(this.httpClient.post<ResetPasswordResponse>(url, data)
      .pipe(
        this.schemaValidator.isValidOperator('auth/mi/ResetPasswordResponse'),
        map(res => {
          return res.success;
        })
      ));
  }

  processServerCall<Response>(performCall: () => Observable<any>, observer: Observer<Response>): void {
    performCall().subscribe({
      next: data => {
        observer.next(data);
        observer.complete();
      },
      error: err => {
        if (err.status === 401) {
          this.refreshTokens()
            .then(() => {
              performCall().subscribe({
                next: data => {
                  observer.next(data);
                  observer.complete();
                },
                error: repeatCallErr => observer.error(repeatCallErr)
              });
            })
            .catch(refreshErr => {
              if (refreshErr?.status >= 400 && refreshErr?.status < 500) {
                this.signOut().then(() => {
                  this._onProfileChanged(null);
                });
              }
              observer.error(refreshErr);
            });
        } else if (err.status === 403) {
          this.accessBlockedSubject.next(true);
          observer.error(err);
        } else if (err.status === 503 || err.status === 0) {
          this.saveLastUrl();
          if (window.location.pathname.indexOf('/maintenance') === -1) {
            this.router.navigateByUrl('/maintenance');
          }
          observer.error(err);
        } else {
          observer.error(err);
        }
      }
    });
  }

  secureGet<Response>(url: string, options?: AuthRequestOptions): Observable<Response> {
    return new Observable((observer: Observer<Response>) => {

      const performCall = (): Observable<any> => {
        return this.getAuthenticationHeadersAsObservable()
          .pipe(
            mergeMap(authHeaders => {
              options = this.addHeaders(authHeaders, options);
              return this.httpClient.get<Response>(url, options);
            })
          );
      };

      this.processServerCall<Response>(performCall, observer);
    });
  }

  securePost<Data, Response>(url: string, postData: Data, options?: AuthRequestOptions): Observable<Response> {
    return new Observable((observer: Observer<Response>) => {

      const performCall = (): Observable<any> => {
        return this.getAuthenticationHeadersAsObservable()
          .pipe(
            mergeMap(authHeaders => {
              options = this.addHeaders(authHeaders, options);
              return this.httpClient.post<Response>(url, postData, options);
            })
          );
      };

      this.processServerCall<Response>(performCall, observer);
    });
  }

  secureDelete<D, Response>(url: string, deleteData: D, options?: AuthRequestOptions): Observable<Response> {
    return new Observable((observer: Observer<Response>) => {

      const performCall = (): Observable<any> => {
        return this.getAuthenticationHeadersAsObservable()
          .pipe(
            mergeMap(authHeaders => {
              options = this.addHeaders(authHeaders, options);

              return this.httpClient.request<Response>('delete', url, {
                headers: options.headers,
                params: options.params,
                body: deleteData
              });
            })
          );
      };

      this.processServerCall<Response>(performCall, observer);
    });
  }

  securePut<D, Response>(url: string, putData: D, options?: AuthRequestOptions): Observable<Response> {
    return new Observable((observer: Observer<Response>) => {

      const performCall = (): Observable<any> => {
        return this.getAuthenticationHeadersAsObservable()
          .pipe(
            mergeMap(authHeaders => {
              options = this.addHeaders(authHeaders, options);
              return this.httpClient.put(url, putData, options);
            })
          );
      };

      this.processServerCall<Response>(performCall, observer);
    });
  }

  getUserAdminGroups(): Promise<Array<string>> {
    return this.storage.get(KEY_PROFILE)
      .then(result => {
        if (result?.adminGroups) {
          return result.adminGroups;
        } else {
          return [];
        }
      });
  }

  private init(): Promise<void> {
    if (!this.initPromise) {
      this.initPromise = this.storage.get(KEY_TOKENS)
        .then((data: Tokens) => {
          if (data?.accessToken && data?.refreshToken) {
            this._tokensCache = data;
            // TODO: I don't see a problem using the current context key, it actually prevents
            // multiple profile calls, if we notice something wrong we can fallback again to the "myAO" context key
            return this.getProfile(this.contextService.currentContext.key);
            // return this.getProfile(this.config.authentication.clientId);
          }
          return null;
        })
        .then((profile: UserProfile) => {
          this._onProfileChanged(profile);
        });
    }
    return this.initPromise;
  }

  private addHeaders(headers: { [header: string]: string; }, options?: AuthRequestOptions): AuthRequestOptions {
    options = options || {};
    if (!options.headers) {
      options.headers = {};
    }
    _.extend(options.headers, headers);
    return options;
  }

  private refreshTokens(): Promise<void> {
    if (this.refreshTokensPromise) {
      return this.refreshTokensPromise;
    }

    this.refreshTokensPromise = this.storage.get(KEY_TOKENS)
      .then(tokensData => {
        if (!tokensData || !tokensData.refreshToken) {
          return Promise.reject('cannot refresh tokens for not logged in user');
        }
        const requestData: RefreshTokenRequest = {
          client_id: this.config.authentication.clientId,
          client_secret: this.config.authentication.clientSecret,
          grant_type: 'refresh_token',
          refresh_token: tokensData.refreshToken,
          redirect_uri: this.config.authenticationAO.redirectUri
        };
        const url = `${this.config.authentication.serverUrl}access_token`;
        return firstValueFrom(this.httpClient.post<RefreshTokensResponse>(url, requestData)
          .pipe(
            this.schemaValidator.isValidOperator('auth/mi/RefreshTokensResponse')
          ))
          .then(data => {
            this._tokensCache = {
              accessToken: data.access_token,
              refreshToken: data.refresh_token
            };
            return this.storage.set(KEY_TOKENS, this._tokensCache);
          })
          .then(() => {
            if (this.refreshOtherTokens) {
              return this.refreshOtherTokens()
                .catch(err => {
                  console.log(err);
                  return Promise.resolve(); // ignore failed external tokens refresh
                });
            }
          })
          .then(() => {
            this.miTokensRefreshSubject.next();
          })
          .finally(() => {
            this.timeoutService.setTimeout(() => {
              this.refreshTokensPromise = null;
            }, REFRESH_TOKEN_PROMISE_CLEANUP_TIMEOUT);
          });
      });

    return this.refreshTokensPromise;
  }

  private asserIsSignedIn(): Promise<void> {
    return this.isSignedIn().then(isSignedIn => {
      if (!isSignedIn) {
        return Promise.reject('user is not logged in');
      }
    });
  }

  private saveLastUrl(): Promise<any> {

    const lastUrl = window.location.pathname;

    if (lastUrl?.indexOf('/maintenance') === -1 &&
      lastUrl?.indexOf('/auth') === -1) {
      return this.storage.set(LAST_URL, lastUrl);
    }
  }

}

export const forTesting = {
  KEY_PROFILE
};
