import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Platform } from 'src/config/config.model';
import { UserProfile } from '../yeti-protocol/auth/mi';

import { AuthService } from '../auth/auth.service';

import appConfig from 'src/config/config';
import { ContextService, CONTEXT_SERVICE } from '../context/context.model';

@Injectable({
  providedIn: 'root'
})
export class QRCodeService implements OnDestroy {

  user: UserProfile;
  appConfig: { platform: Platform} = appConfig;
  sub: Subscription;
  displayQRCode = false;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService
  ){
    this.sub = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    })
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get profileQRCodePageUrl(): string {
    return `${this.contextService.currentContext.homePath}/profile/qr-code`;
  }

  get QRCodeIsAvailable(): boolean {
    return !!(this.user?.isVerified && this.appConfig.platform !== Platform.PWA);
  }

  // methods for QR code used to download the mobile app

  set setDisplayQRCode(value: boolean) {
    this.displayQRCode = value;
  }

  get getDisplayQRCode(): boolean {
    return this.displayQRCode;
  }
}
