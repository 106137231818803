import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { AOEvent } from 'src/app/services/yeti-protocol/event';
import { SelectedEventData } from './events-list-item/events-list-item.component';

export interface MoreEventsRequest {
  page: number;
  itemsPerPage: number;
}

const MAX_SELECTED_EVENTS_COUNT = 20;

@Component({
  selector: 'app-mi-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Output() refreshList: EventEmitter<number> = new EventEmitter();
  @Output() loadMore: EventEmitter<MoreEventsRequest> = new EventEmitter();
  @Output() maxSelectionCountReached: EventEmitter<number> = new EventEmitter();

  events: Array<AOEvent> = [];
  eventsSelected: Array<AOEvent> = [];
  totalItemsCount = -1;
  loading = true;

  constructor(private el:ElementRef){}

  ngOnInit(): void{
    setTimeout(() => {
      this.refreshList.next(this._itemsPerPage);
    }, 0);
  }

  onRefreshList(): void {
    this.events = [];
    this.eventsSelected = [];
    this.totalItemsCount = -1;
    this.loading = true;
    this.refreshList.next(this._itemsPerPage);
  }

  get _itemsPerPage(): number {
    const MIN_ITEM_HEIGHT = 88;
    const listHeight: number = this.el.nativeElement.offsetHeight;
    return Math.ceil(listHeight / MIN_ITEM_HEIGHT) * 2;
  }

  onLoadMoreEvents($event: CustomEvent): void {
    if(this.events.length === this.totalItemsCount){
      const target: any = $event.target;
      target.complete();
      target.disabled = true;
      return;
    }
    this.loading = true;
    const pageSize = this._itemsPerPage;
    const nextPageIndex = Math.floor(this.events.length / pageSize);
    this.loadMore.next({page: nextPageIndex, itemsPerPage: pageSize});
  }

  addEvents(events: Array<AOEvent>, totalItemsCount: number): void {
    this.totalItemsCount = totalItemsCount;
    if(events.length > 0){
      this.events = [...this.events, ...events];
    }
    this.loading = false;
    if(this.infiniteScroll){
      setTimeout(() => {
        this.infiniteScroll.complete();
      });
    }
  }

  onEventSelected(event: SelectedEventData): void {
    if(event.state.selected){
      if(this.eventsSelected.length >= MAX_SELECTED_EVENTS_COUNT){
        this.maxSelectionCountReached.next(MAX_SELECTED_EVENTS_COUNT);
        event.state.selected = false;
      }else{
        this.eventsSelected.push(event.event);
      }
    } else {
      this.eventsSelected = this.eventsSelected.filter((evt) => {
        return event.event._id !== evt._id;
      });
    }
  }


}

