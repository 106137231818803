import { NgModule } from '@angular/core';

import { FromDatePipe } from './from-date.pipe';
import { AddContentLengthUnitPipe } from './add-content-length-unit.pipe';
import { FromSecondsPipe } from './from-seconds.pipe';
import { TruncateTextWithThreeDotsPipe } from './truncate-text-with-three-dots.pipe';
import { AddUrlTimestampPipe } from './add-url-timestamp.pipe';
import { FromSecondsMessengerPipe } from './from-seconds-messenger.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { IsDateOlderThanTodayPipe } from './is-date-older-than-today.pipe';

@NgModule({
  imports: [],
  exports: [
    FromDatePipe,
    AddContentLengthUnitPipe,
    AddUrlTimestampPipe,
    FromSecondsPipe,
    FromSecondsMessengerPipe,
    TruncateTextWithThreeDotsPipe,
    CapitalizePipe,
    IsDateOlderThanTodayPipe
  ],
  declarations: [
    FromDatePipe,
    AddContentLengthUnitPipe,
    AddUrlTimestampPipe,
    FromSecondsPipe,
    FromSecondsMessengerPipe,
    TruncateTextWithThreeDotsPipe,
    CapitalizePipe,
    IsDateOlderThanTodayPipe
  ]
})
export class MIPipesModule { }
