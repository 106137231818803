import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import {
  AOTokens,
  AO_STAGE,
  AOProfile,
  SignInParams,
} from './ao-auth.model';
import { createStandardProfileData, SocialData } from './utils';

import { UrlUtilsService } from '../../utils/url-utils.service';
import { MIAOUtilsService } from './mi-ao-utils.service.service';
import { SchemaValidatorService } from '../../schema-validator.service';
import { StorageService } from '../../storage/storage.service';
import { LocationService } from '../../location.service';

import { AuthProvider } from '../../yeti-protocol/auth/provider';
import {
  AuthenticationAOConfig,
  InAppBrowserColors,
  Platform,
} from 'src/config/config.model';

import appConfig from 'src/config/config';

export enum SESSION_KEY {
  VERIFIER = 'ao-verifier',
  REDIRECT_URI = 'ao-signin-redirect-uri',
}

const TOKENS_STORAGE_KEY = 'ao-tokens';

export interface AOAuthBaseConfig {
  webUrl: string;
  platform: Platform;
  authenticationAO: AuthenticationAOConfig;
  inAppBrowserColors: InAppBrowserColors;
}

export abstract class AOAuthBase {
  // TODO - check with Stojche why the dental build runs unit test for this and fails here
  // config: AOAuthBaseConfig = appConfig;
  config: AOAuthBaseConfig = {
    webUrl: appConfig.webUrl,
    platform: appConfig.platform,
    authenticationAO: appConfig.authenticationAO,
    inAppBrowserColors: appConfig.inAppBrowserColors,
  };

  protected abstract miaoUtilsService: MIAOUtilsService;
  protected abstract urlUtilsService: UrlUtilsService;
  protected abstract sessionStorageService: StorageService;
  protected abstract schemaValidator: SchemaValidatorService;
  protected abstract storage: StorageService;
  protected abstract httpClient: HttpClient;
  protected abstract locationService: LocationService;

  readonly TOKENS_STORAGE_KEY = TOKENS_STORAGE_KEY; // to be accessible in classes that extend this one

  getProfile(): Promise<AOProfile> {
    return this.storage.get(TOKENS_STORAGE_KEY).then((tokens: AOTokens) => {
      if (tokens && tokens.accessToken) {
        const url = `${this.config.authenticationAO.serverUrl}/idp/userinfo.openid`;
        return firstValueFrom(
          this.httpClient.get(url, {
            headers: {
              Authorization: 'Bearer ' + tokens.accessToken,
            },
          })
        )
          .then((profile: SocialData) => {
            return Promise.all([
              profile,
              this.miaoUtilsService.getUserRights(profile, true),
            ]);
          })
          .then(results => {
            const [profile, rightsData] = results;
            if (rightsData) {
              rightsData.aoData = profile;
            }
            return {
              profile: createStandardProfileData(AuthProvider.AO, profile),
              aoProfile: profile,
              rights: rightsData,
            };
          });
      }
      return Promise.reject('no accessToken for profile request');
    });
  }

  _getSignInParams(redirectUrl?: string): Promise<SignInParams> {
    redirectUrl = redirectUrl || this.locationService.href;
    const urlAuth = this._getSignInUrl(
      redirectUrl,
      'code',
      AO_STAGE.S0_AO_SIGNIN
    );
    let verifier: string;
    return Promise.resolve({
      authenticationUrl: urlAuth,
      verifier,
      redirectUrl,
    });
  }

  _saveSignInParams(
    params: SignInParams,
    storage: StorageService
  ): Promise<SignInParams> {
    return Promise.all([
      storage.set(SESSION_KEY.VERIFIER, params.verifier),
      storage.set(SESSION_KEY.REDIRECT_URI, params.redirectUrl),
    ]).then(() => {
      return params;
    });
  }

  _getSignInUrl(
    redirectUri: string,
    responseType: string,
    state?: string
  ): string {
    return (
      this.config.authenticationAO.serverUrl +
      '/authorize?' +
      this.urlUtilsService.object2queryString({
        scope: this._getSignInScope(),
        redirect_uri: redirectUri,
        response_type: responseType,
        client_id: this.config.authenticationAO.clientId,
        state: state || AO_STAGE.S00_DEFAULT,
      })
    );
  }

  _getSignUpUrl(redirectUri: string): string {
    return (
      this.config.authenticationAO.userRegistrationUrl +
      '?' +
      this.urlUtilsService.object2queryString({
        ContextId: redirectUri,
      })
    );
  }

  _getSignOutUrl(redirectUri: string): string {
    if (!this.config.authenticationAO) {
      return null;
    }

    return (
      this.config.authenticationAO.logoutUrl +
      '?' +
      this.urlUtilsService.object2queryString({
        post_logout_redirect_uri: redirectUri,
        clientId: this.config.authenticationAO.clientId,
      })
    );
  }

  _getSignInScope(): string {
    return this.config.authenticationAO.scopes
      .map(s => encodeURIComponent(s))
      .join('+');
  }

  abstract getRedirectUrl(): string;

  abstract isBrowser(): boolean;
}

export const forTesting = {
  TOKENS_STORAGE_KEY,
  SESSION_KEY,
};
