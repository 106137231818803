import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

import { AuthenticatedHomePageGuard } from './services/auth/authenticated-home-page.guard';

import appConfig from 'src/config/config';
import { NonAuthenticatedAccessGuard } from './services/auth/non-authenticated-access.guard';
import { MarketingPageGuard } from './guards/marketing-page.guard';
import { VerifyPageGuard } from './guards/verify-page.guard';
import { EventDetailPageGuard } from './guards/event-detail-page.guard';
import { isMobilePlatform } from './services/utils/utils';
import { contextRootRoutes } from './contexts/context-routes';
import { authenticatedOrcIdPageGuard } from './services/auth/authenticated-orcid-page.guard';
import { authV2AoRedirectPageGuard } from './services/auth/auth-v2-ao-redirect-page.guard';

function _isMobilePlatform(): boolean {
  return isMobilePlatform(appConfig.platform);
}

const routes: Routes = [
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule),
    canActivate: _isMobilePlatform() ? [NonAuthenticatedAccessGuard] : []
  },
  {
    path: 'marketing/:targetPlace',
    canActivate: [MarketingPageGuard]
  },
  {
    path: 'aoevent/:aoEventId',
    canActivate: [EventDetailPageGuard]
  },
  {
    path: 'verify',
    canActivate: [VerifyPageGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthenticatedHomePageGuard],
    children: []
  },
  {
    path: 'yeti-components-overview',
    loadChildren: () => import('./pages/yeti-components-overview/yeti-components-overview.module')
      .then(m => m.YetiComponentsOverviewPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then(m => m.TestPageModule)
  },
  {
    path: 'log',
    loadChildren: () => import('./pages/log/log.module').then(m => m.LogPageModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./pages/groups/groups.module').then(m => m.GroupsPageModule)
  },
  {
    path: 'customize-feed',
    loadChildren: () => import('./pages/customize-feed/customize-feed.module').then(m => m.CustomizeFeedPageModule)
  },
  {
    path: 'auth-v2-ao-redirect',
    loadChildren: () => import('./pages/auth-v2-ao-redirect/auth-v2-ao-redirect.module').then(m => m.AuthV2AORedirectPageModule),
    canActivate: [ authV2AoRedirectPageGuard() ]
  },
  {
    path: 'ai',
    loadChildren: () => import('./modules/ai/ai.module').then( m => m.AIModule)
  },
  {
    path: 'auth-orcid-redirect',
    loadChildren: () => import('./pages/auth-orcid-redirect/auth-orcid-redirect.module').then( m => m.AuthOrcidRedirectPageModule),
    canActivate: [ authenticatedOrcIdPageGuard() ]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },
  {
    path: 'ao-auth-signup',
    loadChildren: () => import('./pages/ao-auth-signup/ao-auth-signup.module').then( m => m.AoAuthSignupPageModule)
  }
];

if (!appConfig.appUpdateDisabled) {
  routes.push({
    path: 'resources-update',
    loadChildren: () => import('./pages/resources-update/resources-update.module')
      .then(m => m.ResourcesUpdatePageModule)
  });
}

routes.push(...contextRootRoutes);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: _isMobilePlatform() ? NoPreloading : NoPreloading })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
