// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --color: var(--mi-primary-color);
  --background-color: var(--mi-light-blue-color);
  --font-size: var(--font-size-small);
  --font-weight: var(--font-weight-regular);
}

.text-chip-container {
  background-color: var(--background-color);
  color: var(--color);
  padding: 0.17em 0.625em;
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}
.text-chip-container .text-chip-text {
  width: 100%;
  margin: 0;
  display: inline-block;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--font-line-height-regular);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
