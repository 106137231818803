// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  color: var(--ion-color-primary);
  background-color: var(--ion-color-light-shade);
  overflow-y: auto;
  --padding: 0px;
  --panel-1-padding-top: 0px;
  --panel-1-padding-bottom: 15px;
  --panel-1-padding-start: 15px;
  --panel-1-padding-end: 0px;
  --panel-2-padding-top: 15px;
  --panel-2-padding-bottom: 15px;
  --panel-2-padding-start: 15px;
  --panel-2-padding-end: 15px;
  --panel-1-row-gap: 20px;
  --title-font-size: var(--font-size-extra-large);
  --panel-2-border-radius: 20px;
  --close-button-icon-size: 24px;
  --migration-dialog-cards-border-radius: 9px;
}
@media all and (min-width: 1000px) {
  :host {
    --padding: 50px;
    --title-font-size: var(--font-size-extra-large-2);
    --panel-1-row-gap: 30px;
    --close-button-icon-size: 30px;
    --panel-1-padding: 0px;
  }
}

.top-image {
  height: 40%;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}

.controls-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--mi-white-color);
}
.controls-area form {
  background-color: var(--mi-white-color);
}

ion-list-header {
  font-size: var(--font-size-extra-large);
  font-weight: 600;
}

app-generic-input {
  width: 100%;
  margin: 4px 0;
}

.controls-area,
ion-list,
form {
  border-top-left-radius: var(--mi-standard-margin);
  border-top-right-radius: var(--mi-standard-margin);
}

.bottom-line {
  font-size: var(--font-size-tiny);
  color: var(--mi-color-text-lite);
}
.bottom-line .line {
  width: 100%;
  text-align: center;
}
.bottom-line .link {
  text-decoration: underline;
  cursor: pointer;
}

.or-icon {
  display: block;
  margin: 0 auto;
}

.auth-button {
  height: 47px;
  margin: 4px 0;
  text-transform: none;
  box-shadow: none;
}

.migration-dialog {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  row-gap: 10px;
  background-color: var(--mi-purple-color-3);
  padding: var(--padding);
}
.migration-dialog .panel-1 {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
  row-gap: var(--panel-1-row-gap);
  padding-top: var(--panel-1-padding-top);
  padding-bottom: var(--panel-1-padding-bottom);
  padding-left: var(--panel-1-padding-start);
  padding-right: var(--panel-1-padding-end);
}
.migration-dialog .panel-1 .title {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-extra-large-2);
  color: var(--mi-grey-color-13);
}
.migration-dialog .panel-1 .title span {
  font-weight: var(--font-weight-bold);
}
.migration-dialog .panel-1 .subtitle {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-large-2);
  color: var(--mi-secondary-color-2);
}
.migration-dialog .panel-2 {
  grid-row: 2;
  grid-column: 1;
  background-color: var(--mi-white-color);
  border-radius: var(--panel-2-border-radius);
  position: relative;
}
@media all and (min-width: 1000px) {
  .migration-dialog {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    -moz-column-gap: 25px;
         column-gap: 25px;
    padding: 50px;
  }
  .migration-dialog .panel-1 {
    grid-row: 1;
    grid-column: 1;
  }
  .migration-dialog .panel-2 {
    grid-row: 1;
    grid-column: 2;
    width: 100%;
  }
}
.migration-dialog.mobile {
  --panel-1-padding-top: 25px;
  --panel-1-padding-bottom: 15px;
  --panel-1-padding-start: 15px;
  --panel-1-padding-end: 15px;
}
@media all and (max-height: 750px) {
  .migration-dialog.mobile .panel-2 {
    row-gap: 12.5px;
  }
}

:host {
  --cards-border-radius: var(--migration-dialog-cards-border-radius);
}

.auth-signup-main-roles-page-container .panel-2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto min-content;
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  padding-bottom: 20px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content {
  grid-row: 1;
  grid-column: 1;
  position: relative;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content {
  --background: transparent;
  --padding-start: var(--panel-2-padding-start);
  --padding-end: var(--panel-2-padding-end);
  position: absolute;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .select-profession-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 14px;
  background-color: var(--mi-light-purple-1-color);
  border-radius: var(--cards-border-radius);
  padding: 15px;
  margin-bottom: 30px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .select-profession-container .card-label-container .card-label {
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-regular);
  color: var(--mi-grey-color-16);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .select-profession-container .professions-container ion-radio-group {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  -moz-column-gap: 12px;
       column-gap: 12px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .select-profession-container .professions-container ion-radio-group .profession {
  background-color: var(--mi-white-color);
  border-radius: var(--cards-border-radius);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto min-content;
  row-gap: 15px;
  justify-content: center;
  justify-items: center;
  padding: 35px 5px 10px 5px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .select-profession-container .professions-container ion-radio-group .profession.selected {
  background-color: var(--mi-blue-color-4);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .select-profession-container .professions-container ion-radio-group .profession .profession-label-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .select-profession-container .professions-container ion-radio-group .profession .profession-label-container .label {
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  color: var(--mi-grey-color-16);
  text-align: center;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .select-profession-container .professions-container ion-radio-group .profession .profession-radio-button {
  grid-row: 2;
  grid-column: 1;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .select-profession-container .professions-container ion-radio-group .profession .profession-radio-button ion-radio {
  --color-checked: var(--mi-primary-color);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 17px;
  padding-bottom: 17px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container {
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: 20px 12px;
  background-color: var(--mi-blue-color-4);
  border-radius: 6px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container.selected-file {
  padding: 10px 12px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .start-icon {
  grid-row: 1;
  grid-column: 1;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .start-icon app-icon {
  color: var(--mi-black-color);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .start-icon .checkmark {
  --icon-size: 20px;
  color: var(--mi-primary-color);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .text-container {
  grid-row: 1;
  grid-column: 2;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 18px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .text-container .wrapper {
  position: absolute;
  width: 100%;
  height: 18px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .text-container .wrapper p {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-regular);
  color: var(--mi-grey-color-13);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .end-icon {
  grid-row: 1;
  grid-column: 3;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .end-icon app-icon {
  color: var(--mi-black-color);
  font-size: 14px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .end-icon .image-preview-container {
  width: 43px;
  height: 43px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .end-icon .image-preview-container ion-img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: fill;
  position: absolute;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .end-icon .image-preview-container .backdrop {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: var(--mi-black-color);
  opacity: 0.2;
  border-radius: 6px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .verify-credentials-button-container .end-icon .image-preview-container app-icon {
  --icon-size: 13px;
  width: 13px;
  color: var(--mi-white-color);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container {
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: 15px 12px;
  border-radius: 6px;
  background-color: var(--mi-inputs-color);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .text-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 7px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .text-container .text-wrapper {
  position: relative;
  height: 12px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .text-container .text-wrapper .text {
  position: absolute;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .text-container .text-wrapper .text.label {
  width: 100%;
  height: 12px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .text-container .text-wrapper .text.label p {
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  color: var(--mi-secondary-color-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .text-container .text-wrapper .text.description {
  width: 100%;
  height: 10px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .text-container .text-wrapper .text.description p {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-tiny);
  color: var(--mi-grey-color-13);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .radio-button-container {
  grid-row: 1;
  grid-column: 2;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .radio-button-container ion-radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .verify-credentials-container .no-credentials-radio-button-container .radio-button-container ion-radio-group ion-radio {
  --color-checked: var(--mi-primary-color);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container {
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: 15px 12px;
  border-radius: 6px;
  background-color: var(--mi-inputs-color);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .text-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 7px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .text-container .text-wrapper {
  position: relative;
  height: 12px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .text-container .text-wrapper .text {
  position: absolute;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .text-container .text-wrapper .text.label {
  width: 100%;
  height: 12px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .text-container .text-wrapper .text.label p {
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  color: var(--mi-secondary-color-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .text-container .text-wrapper .text.description {
  width: 100%;
  height: 10px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .text-container .text-wrapper .text.description p {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-tiny);
  color: var(--mi-grey-color-13);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .radio-button-container {
  grid-row: 1;
  grid-column: 2;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .radio-button-container ion-radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .other-profession-container .other-profession-radio-button-container .radio-button-container ion-radio-group ion-radio {
  --color-checked: var(--mi-primary-color);
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .medical-credentials-info {
  background-color: var(--mi-light-purple-1-color);
  border-radius: 10px;
  padding: 20px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .medical-credentials-info .info-text {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
  text-align: center;
  padding-bottom: 10px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .medical-credentials-info .accepted-medical-credentials-container {
  text-align: center;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .medical-credentials-info .accepted-medical-credentials-container .accepted-medical-credentials-title {
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  color: var(--mi-grey-color-13);
  text-align: center;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .medical-credentials-info .accepted-medical-credentials-container .accepted-medical-credentials-list {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  color: var(--mi-grey-color-13);
  list-style-position: inside;
  padding-left: 0;
  margin: 0;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .medical-credentials-info .accepted-medical-credentials-container .accepted-medical-credentials-list li {
  margin: 10px 0 0 0;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .medical-credentials-provided-container {
  background-color: var(--mi-light-purple-1-color);
  border-radius: 10px;
  padding: 20px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .medical-credentials-provided-container .medical-credentials-provided-title {
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-regular);
  color: var(--mi-grey-color-13);
  text-align: center;
  padding-bottom: 5px;
}
.auth-signup-main-roles-page-container .panel-2 .main-content ion-content .content-wrapper .medical-credentials-provided-container .medical-credentials-provided-text {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
  text-align: center;
}
.auth-signup-main-roles-page-container .panel-2 .button-container {
  grid-row: 2;
  grid-column: 1;
  padding-top: 25px;
  padding-left: var(--panel-2-padding-start);
  padding-right: var(--panel-2-padding-end);
}
.auth-signup-main-roles-page-container .disabled {
  opacity: 0.6;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
