import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-field-limit-counter',
  templateUrl: './text-field-limit-counter.component.html',
  styleUrls: ['./text-field-limit-counter.component.scss'],
})
export class TextFieldLimitCounterComponent {

  @Input() inputText = '';
  @Input() limit: number;

  get isLimitPassed(): boolean {
    return this.inputText && this.limit && this.inputText?.length > this.limit;
  }

}
