import { Component, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-resources-update-popup',
  templateUrl: './resources-update-popup.component.html',
  styleUrls: ['./resources-update-popup.component.scss'],
})
export class ResourcesUpdatePopupComponent implements AfterViewInit {
  @Output() close = new EventEmitter<void>();
  @Output() apply = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef
  ) { }

  ngAfterViewInit(): void{
    (this.elementRef.nativeElement as HTMLElement).classList.add('resource-update-available');
  }
}
