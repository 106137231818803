// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-card {
  background-color: var(--mi-light-blue-color);
  color: var(--mi-secondary-color-1);
  border-radius: 10px;
}
.info-card .info-card-header {
  display: flex;
  justify-content: space-between;
}
.info-card .info-card-header .main-text {
  font-weight: var(--font-weight-medium);
  font-size: var(--mi-font-size-small);
  color: var(--mi-secondary-color-1);
}
.info-card .display-flex {
  display: flex;
}
.info-card .group-additional-info {
  margin: 5px 0;
  justify-content: space-between;
}
.info-card .group-additional-info .additional-info-item {
  background-color: var(--ion-color-secondary-light-blue);
  color: var(--ion-color-secondary-bright-blue);
  border-radius: 12px;
  margin: 5px 5px 5px 0;
}
.info-card .group-additional-info .share-label {
  font-size: var(--mi-font-size-small);
  color: var(--mi-secondary-color-1) !important;
}
.info-card .group-additional-info .label {
  font-size: var(--mi-font-size-small);
  letter-spacing: 0;
  color: var(--mi-secondary-color-2);
}
.info-card .group-additional-info .copy-field {
  color: var(--mi-primary-color) !important;
  --background: var(--mi-inputs-color) !important;
  border: 1px dashed var(--mi-primary-color);
  --border-radius: 4px;
  font-size: var(--mi-font-size-smallest);
  display: flex;
  margin: 5px 0 5px 0;
}
.info-card .group-additional-info .copy-field .link-button-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
}
.info-card .group-additional-info .share-group-button {
  --background: var(--mi-primary-color);
  --border-radius: 4px;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  margin: 5px 0 5px 0;
  display: flex;
  text-transform: none;
}
.info-card .group-additional-info .share-group-button .icon-copy-squares {
  position: absolute;
  right: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
