import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface SharedCaseToGroupsData {
  clinicalCaseId: string;
  numberOfGroupsSharedTo: number;
  groupsSharedTo: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class ClinicalCaseDataService {

  private clinicalCaseShared: Subject<SharedCaseToGroupsData> = new Subject();

  triggerClinicalCaseShare(caseId: string, groupsNumber: number, groupsArray: Array<string>): void {
    this.clinicalCaseShared.next({
      clinicalCaseId: caseId,
      numberOfGroupsSharedTo: groupsNumber,
      groupsSharedTo: groupsArray
    })
  }

  getClinicalCaseSharingChanges(): Observable<SharedCaseToGroupsData> {
    return this.clinicalCaseShared.asObservable();
  }
}
