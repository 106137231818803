import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { File as FilePlugin } from '@awesome-cordova-plugins/file/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import AdvancedImagePicker from 'cordova-plugin-advanced-imagepicker';

import { FileSelectLimits, SelectedFile } from '../file-select.model';
import { FileSelectScope, FileSelectService } from './file-select.service';
import { ImageGalleryService } from './image-gallery.service';
import { GalleryConfig } from './image-gallery.model';
import { Platform } from 'src/config/config.model';
import { Device } from '@awesome-cordova-plugins/device/ngx';

import appConfig from 'src/config/config';

@Injectable({
  providedIn: 'root'
})
export class FileSelectDeviceService {

  constructor(
    private camera: Camera,
    private chooser: Chooser,
    private fileSelectService: FileSelectService,
    private imageGalleryService: ImageGalleryService,
    private filePlugin: FilePlugin,
    private filePath: FilePath,
    private device: Device
  ) { }

  private imageCompressionFinished: Subject<void> = new Subject();
  private imagesSelectedFromGallery: Subject<void> = new Subject();

  selectFile(maxFileSizeMB: number, mimeType: string, scope: FileSelectScope): Promise<Array<SelectedFile> | void> {
    return this.chooser.getFile({
      mimeTypes: mimeType
    })
      .then((result: any) => {

        if (!result) {
          return Promise.reject('Not found');
        }

        const blob = new Blob([result.data], { type: result.mediaType });
        const selectedFile: SelectedFile = {
          file: blob as File,
          url: result.dataURI,
        };

        (selectedFile.file as any).name = result.name; // set correct file name

        return Promise.resolve(selectedFile);
      })
      .then(selectedFile => {
        const selectedDocumentFiles: Array<SelectedFile> = [
          selectedFile
        ];
        return this.fileSelectService.checkMaxFileSizeExceeded(selectedDocumentFiles, maxFileSizeMB, scope);
      })
      .catch((error: any) => {
        console.error(error)
      });
  }

  takeAPhoto(
    maxFileSizeMB: number,
    editImages: boolean,
    skipGallery: boolean,
    scope: FileSelectScope,
    minImageWidth?: number,
    minImageHeight?: number): Promise<Array<SelectedFile> | void> {

    const options: CameraOptions = {
      quality: 100,
      sourceType: this.camera.PictureSourceType.CAMERA,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      cameraDirection: 0, // back
      allowEdit: false
    }

    return this.camera.getPicture(options)
      .then(imageData => {
        return this.readFileFromFileUri(imageData);
      })
      .then(selectedImageFile => {
        if (selectedImageFile) {
          return this.fileSelectService.checkMaxFileSizeExceeded([selectedImageFile], maxFileSizeMB, scope);
        }

        return Promise.reject('No image selected');
      })
      .then(checkedImageFiles => {
        if (checkedImageFiles?.length) {
          return this.fileSelectService.compressImageFile(checkedImageFiles?.[0], maxFileSizeMB);
        }
        return Promise.reject();
      })
      .then(compressedImageFile => {
        this.emitImageCompressionFinished();
        if (compressedImageFile) {
          return this.fileSelectService.checkImageMinDimensions(
            [compressedImageFile],
            minImageWidth,
            minImageHeight,
            scope
          );
        }

        return Promise.reject();
      })
      .then(checkedImageFiles => {
        const selectedImageFile = checkedImageFiles?.length > 0 ? checkedImageFiles[0] : null;

        if (selectedImageFile) {
          if (!skipGallery) {
            const galleryConfig: GalleryConfig = {
              images: this.fileSelectService.selectedFileToImageAttachmentTypeConversion([selectedImageFile]),
              allowImageRemove: true,
              allowImageEdit: editImages,
              reviewMode: true,
              scope: scope,
              imageGalleryStrategy: null
            }
            return this.imageGalleryService.openImageGallery(galleryConfig);
          } else {
            return Promise.resolve({ images: this.fileSelectService.selectedFileToImageAttachmentTypeConversion([selectedImageFile]) });
          }
        }
        return Promise.reject();
      })
      .then(reviewedImages => {

        if (reviewedImages?.images && reviewedImages?.images.length > 0) {
          const selectedImageFile = this.fileSelectService.imageAttachmentToSelectedFileTypeConversion(reviewedImages?.images)?.[0];
          if (!selectedImageFile) {
            return;
          }
          return [selectedImageFile];
        }
        return Promise.reject();
      })
      .catch(err => {
        console.log(err);
        return Promise.reject();
        // Handle error
      });
  }

  selectVideoMobile(maxFileSizeMB: number, scope: FileSelectScope,): Promise<Array<SelectedFile> | void> {

    const options: CameraOptions = {
      quality: 100,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      destinationType: this.camera.DestinationType.FILE_URI,
      // encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.VIDEO,
      correctOrientation: true
    }
    return this.camera.getPicture(options)
      .then(videoData => {
        return this.readFileFromFileUri(videoData);
      })
      .then(selectedVideoFile => {
        if (selectedVideoFile) {
          return this.fileSelectService.checkMaxFileSizeExceeded([selectedVideoFile], maxFileSizeMB, scope);
        }

        return Promise.reject('No video selected');
      })
      .catch(err => {
        console.log(err);
        return Promise.reject();
        // Handle error
      });
  }

  async readFileMobile(file: File, fileUri: string): Promise<SelectedFile> {

    const selectedFile: SelectedFile = {
      file: file
    };

    if (this.shouldUseNewAndroidMediaApi(fileUri)) {
      try {

        const data = await this.getFileNameAndTypeFromImageFileUri(fileUri);
        const blob = new Blob([file], { type: data.type });

        selectedFile.file = blob as File;
        selectedFile.url = URL.createObjectURL(selectedFile.file);
        selectedFile.fileName = data.fileName;
        return Promise.resolve(selectedFile);
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    }

    const reader: FileReader = new FileReader();
    reader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {
      reader.onload = e => {
        const blob = new Blob([e.target.result], { type: selectedFile.file.type });
        const name = selectedFile?.file?.name;

        selectedFile.file = blob as File;
        selectedFile.url = URL.createObjectURL(selectedFile.file);
        selectedFile.fileName = name;
        resolve(selectedFile);
      };

      reader.onerror = (err) => {
        reject(err);
      };
    });
  }

  selectImageFromGallery(
    maxFilesCount: number,
    maxFileSizeMB: number,
    editImages: boolean,
    skipGallery: boolean,
    scope: FileSelectScope,
    multipleSelectionMaxCount = FileSelectLimits.multipleSelectionMaxCount,
    minImageWidth?: number,
    minImageHeight?: number): Promise<Array<SelectedFile> | void> {

    return Promise.resolve()
      .then(() => {
        return new Promise<Array<any>>((resolve, reject) => {
          AdvancedImagePicker.present({
            max: maxFilesCount,
            startOnScreen: 'IMAGE',
            showCameraTile: false
          }, (images) => {
            return resolve(images.map(image => image?.src));
          }, (error) => {
            console.error(error);
            return reject(error);
          });
        });
      })
      .then(async (results) => {

        if (results && results?.length) {
          this.emitImagesSelectedFromGallery();
        }

        results = await this.fileSelectService.checkMultipleSelectionMaxCountExceeded(
          [...results],
          multipleSelectionMaxCount,
          scope
        );

        const promises: Array<Promise<SelectedFile>> = results?.map(image => {
          return this.readFileFromFileUri(image);
        });
        return Promise.all(promises);
      })
      .then((selectedImageFiles: Array<SelectedFile>) => {
        return this.fileSelectService.checkMaxFileSizeExceeded(selectedImageFiles, maxFileSizeMB, scope);
      })
      .then((checkedImageFiles: Array<SelectedFile>) => {
        if (appConfig.platform === Platform.ANDROID) {
          return Promise.resolve(checkedImageFiles);
        } else {
          return this.fileSelectService.compressImageFiles(checkedImageFiles, maxFileSizeMB);
        }
      })
      .then(checkedImageFiles => {
        this.emitImageCompressionFinished();
        if (checkedImageFiles) {
          return this.fileSelectService.checkImageMinDimensions(
            checkedImageFiles,
            minImageWidth,
            minImageHeight,
            scope
          );
        }

        return Promise.reject();
      })
      .then((selectedImageFiles: Array<SelectedFile>) => {
        if (selectedImageFiles && selectedImageFiles?.length > 0) {
          if (!skipGallery) {
            const galleryConfig: GalleryConfig = {
              images: this.fileSelectService.selectedFileToImageAttachmentTypeConversion(selectedImageFiles),
              allowImageRemove: true,
              allowImageEdit: editImages,
              reviewMode: true,
              scope: scope,
              imageGalleryStrategy: null
            }
            return this.imageGalleryService.openImageGallery(galleryConfig);
          } else {
            return Promise.resolve({ images: this.fileSelectService.selectedFileToImageAttachmentTypeConversion(selectedImageFiles) });
          }
        }
        return null;
      })
      .then(reviewedImages => {
        if (reviewedImages?.images && reviewedImages?.images.length > 0) {
          return this.fileSelectService.imageAttachmentToSelectedFileTypeConversion(reviewedImages?.images);
        }
        return Promise.reject();
      })
      .catch(err => {
        console.log(err);
        return Promise.reject();
      });
  }

  async readFileFromFileUri(fileUri: string): Promise<SelectedFile> {
    try {
      const file = await this.resolveLocalFileFromFileUri(fileUri);
      return await this.readFileMobile(file, fileUri);
    } catch (err) {
      return null;
    }
  }

  async resolveLocalFileFromFileUri(fileUri: string,): Promise<any> {

    fileUri = fileUri.startsWith('file://') || fileUri.startsWith('content://') ? fileUri : `file://${fileUri}`;

    if (this.shouldUseNewAndroidMediaApi(fileUri)) {
      return (window as any).cordova.plugins?.safMediastore?.readFile(fileUri);
    }

    if (appConfig.platform === Platform.ANDROID) {
      try {
        fileUri = await this.filePath.resolveNativePath(fileUri);
      } catch (err) {
        console.error(err);
      }
    }

    return new Promise((resolve, reject) => {
      this.filePlugin.resolveLocalFilesystemUrl(fileUri).then((entry: any) => {
        entry.file(file => {

          if (appConfig.platform === Platform.ANDROID) {

            const fileUriArray = fileUri?.split('/');

            if (fileUriArray?.length) {
              file.name = fileUriArray[fileUriArray?.length - 1];
            }
          }

          resolve(file);
        });
      }).catch(err => {
        reject(err);
      })
    });
  }

  get imageCompressionFinishedObservable(): Observable<void> {
    return this.imageCompressionFinished.asObservable();
  }

  get imagesSelectedFromGalleryObservable(): Observable<void> {
    return this.imagesSelectedFromGallery.asObservable();
  }

  private emitImageCompressionFinished(): void {
    this.imageCompressionFinished.next();
  }

  private emitImagesSelectedFromGallery(): void {
    this.imagesSelectedFromGallery.next();
  }

  private shouldUseNewAndroidMediaApi(fileUri: string): boolean {
    if (appConfig.platform === Platform.ANDROID &&
      parseInt(this.device.version, 10) >= 13 &&
      fileUri.indexOf('/media/external') > -1) {
      return true;
    } else {
      return false;
    }
  }

  private async getFileNameAndTypeFromImageFileUri(fileUri: string): Promise<{ fileName: string, type: string }> {
    try {
      const fullFileName = await (window as any).cordova.plugins?.safMediastore?.getFileName(fileUri);

      const lastIndex = fullFileName.lastIndexOf('.');

      if (lastIndex !== -1) {
        const fileName = fullFileName.substring(0, lastIndex);
        const _type = fullFileName.substring(lastIndex + 1);
        const type = `image/${_type === 'jpg' ? 'jpeg' : _type}`;
        return {
          fileName, type
        };
      } else {
        // If there is no dot, return empty strings for fileName and type
        return { fileName: '', type: '' };
      }
    } catch (err) {
      console.error(err);
      return { fileName: '', type: '' };
    }
  }
}
