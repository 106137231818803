// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --image-size: 50px;
}

.clinical-case-shared-with-slider-item-container {
  width: 70px;
  height: 100%;
}
.clinical-case-shared-with-slider-item-container .item {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.clinical-case-shared-with-slider-item-container .item .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  pointer-events: none;
}
.clinical-case-shared-with-slider-item-container .item .image-container app-group-image {
  --group-image-size: var(--image-size);
  --group-image-border-radius: 50%;
}
.clinical-case-shared-with-slider-item-container .item .image-container app-user-profile-image {
  --user-image-size: var(--image-size);
}
.clinical-case-shared-with-slider-item-container .item .text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clinical-case-shared-with-slider-item-container .item .text-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 26px;
}
.clinical-case-shared-with-slider-item-container .item .text-container app-multiline-text-with-ellipsis ::ng-deep {
  color: var(--mi-grey-color-16);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
}
.clinical-case-shared-with-slider-item-container .item .text-container app-multiline-text-with-ellipsis ::ng-deep .multiline-text-with-ellipsis-container .multiline-text-container .text {
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
