// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-btn-web {
  margin-top: 15px;
  background-color: var(--mi-light-purple-1-color) !important;
  width: 100% !important;
}

.manage-btn:hover {
  background-color: var(--mi-light-purple-color) !important;
  --background: var(--mi-light-purple-color) !important;
  --border-color: var(--mi-light-purple-color) !important;
}

.manage-btn {
  width: 91%;
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  background-color: var(--mi-background-color);
  --background: var(--mi-background-color);
  --border-color: var(--mi-background-color);
}
.manage-btn .text-container {
  display: flex;
  align-items: center;
}
.manage-btn .text-container .btn-text {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.manage-btn app-icon {
  margin: 0px 15px 0px 15px;
  color: var(--mi-black-color);
}
.manage-btn app-icon.next-arrow {
  color: var(--mi-black-color);
}

.btn-container {
  background-color: var(--mi-white-color);
  margin: 15px 0px;
  padding: 1px 0px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
