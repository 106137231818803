// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container-web {
  width: unset !important;
  margin: 0 15px;
  border-radius: 10px;
}

.journal-highlights-container {
  background-color: var(--mi-grey-color-5);
}
.journal-highlights-container .title {
  padding: var(--mi-standard-padding);
  padding-bottom: 0px;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  line-height: var(--font-line-height-extra-large);
}
.journal-highlights-container app-journal-highlights-journal-item ::ng-deep ion-item.journal-container {
  --background: var(--mi-grey-color-5);
}
.journal-highlights-container swiper {
  margin-left: 16px;
}
.journal-highlights-container swiper ::ng-deep app-mi-card {
  --mi-card-margin-start: 0;
  --mi-card-margin-end: 0;
}
.journal-highlights-container .card-title {
  text-align: left;
  padding-left: var(--mi-standard-padding);
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  line-height: var(--font-line-height-extra-large);
}
.journal-highlights-container .article-card ::ng-deep .article-content-container .title {
  text-align: left;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
