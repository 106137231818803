// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-settings-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;
}
.user-settings-container app-icon {
  --icon-size: 24px;
}
.user-settings-container .user-settings-card {
  box-shadow: none !important;
  height: 100%;
}
.user-settings-container .user-settings-card ion-card-header {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #EDF0F6 50%, white 50%);
  height: 25%;
}
.user-settings-container .user-settings-card ion-card-content {
  height: 75%;
}
.user-settings-container .user-settings-card ion-card-content form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.user-settings-container ion-content .profile-image {
  border-radius: 50%;
  height: 148px;
  width: 148px;
  border: 4px solid var(--mi-white-color);
  object-fit: fill;
  background-color: var(--mi-inputs-color);
  text-align: center;
}
.user-settings-container ion-content .profile-image app-user-profile-image-upload {
  position: absolute;
  margin-left: 30px;
  --camera-button-size: 30px;
  --camera-icon-size: 18px;
}
.user-settings-container ion-content .profile-image ion-avatar {
  width: 138px;
  height: 138px;
  background-color: var(--mi-inputs-color);
}
.user-settings-container ion-content .profile-image ion-avatar ion-img {
  cursor: pointer;
}
.user-settings-container ion-content .profile-image .profile-image-container {
  height: 148px;
}
.user-settings-container ion-content .profile-image .camera-icon {
  --icon-size: 30px;
}
.user-settings-container ion-content .profile-image .select-image-text {
  font-size: var(--mi-font-size-small);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  position: relative;
  bottom: 80%;
  left: 9%;
  overflow-wrap: normal;
  width: 118px;
  height: 148px;
}
.user-settings-container ion-content .form-item {
  --background: var(--mi-inputs-color);
  --border-radius: 8px;
  margin-bottom: 10px;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}
.user-settings-container ion-content .form-item.errorInvalid {
  border: 1px solid red;
  border-radius: 8px;
}
.user-settings-container ion-content .form-item ion-input {
  pointer-events: none;
}
.user-settings-container ion-content .edit-data-item {
  --min-height: 63px;
  --background: var(--mi-background-color);
  pointer-events: none;
}
.user-settings-container ion-content .edit-data-item .edit-work-details-container {
  font-weight: var(--font-weight-medium);
}
.user-settings-container ion-content .edit-data-item .edit-work-details-container .country-shevron {
  position: absolute;
  bottom: 33%;
  right: 2%;
  color: unset;
  --icon-size: 20px;
}
.user-settings-container ion-content .edit-data-item .details-button {
  width: 100%;
  --background: var(--mi-background-color);
  font-weight: var(--font-weight-medium);
  --box-shadow: none;
  color: var(--mi-secondary-color-2);
}
.user-settings-container ion-content .edit-data-item .details-button .details-button-content {
  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: center;
}
.user-settings-container ion-content .edit-data-item .details-button .details-button-content .item-icon {
  color: var(--mi-color-text-lite);
}
.user-settings-container ion-content .edit-data-item .details-button .details-button-content .chevron {
  --icon-size: 20px;
  color: var(--mi-black-color);
}
.user-settings-container ion-content .edit-data-item .details-button .details-button-content .text-container {
  display: flex;
  flex-direction: column;
}
.user-settings-container ion-content .edit-data-item .details-button .details-button-content .profession,
.user-settings-container ion-content .edit-data-item .details-button .details-button-content .birthday,
.user-settings-container ion-content .edit-data-item .details-button .details-button-content .graduation,
.user-settings-container ion-content .edit-data-item .details-button .details-button-content .translation {
  top: 20px;
}
.user-settings-container ion-content .edit-data-item .details-button .details-button-content .profile-detail-text {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
}
.user-settings-container ion-content .item-icon {
  margin-right: 10px;
  color: var(--mi-color-text-lite);
}
.user-settings-container ion-content ion-select {
  min-width: 100vw;
}
.user-settings-container ion-content .edit-personal-details {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--mi-font-size-default);
  height: 47px;
  --border-radius: 5px;
  text-transform: none;
}
.user-settings-container .display-flex {
  display: flex;
  align-items: center;
}
.user-settings-container .display-flex .input-text {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
  text-transform: none;
}

[trigger=edit-birthday] {
  --min-width: 340px;
  --width: 340px;
  --max-height: 200px;
}

[trigger=edit-graduation] ::ng-deep ion-datetime {
  width: 250px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
