import { Injectable } from '@angular/core';
import { SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { isMobilePlatform } from 'src/app/services/utils/utils';
import appConfig from 'src/config/config';
import { AppTranslationService } from './app-translation.service';
import { DialogsUIService } from './dialogs/dialogs.ui.service';

export type VideoDurationCheckError = 'loading-error' | 'too-long-duration';

export interface VideoDurationCheckResult {
  selectedFile: SelectedFile,
  duration: number,
  error?: VideoDurationCheckError
}

@Injectable({
  providedIn: 'root'
})
export class VideosUiService {

  config = appConfig;

  constructor(
    private appTranslationService: AppTranslationService,
    private dialogs: DialogsUIService
  ) { }

  async getVideoDurations(selectedFiles: Array<SelectedFile>): Promise<Array<VideoDurationCheckResult>> {
    const info: Array<VideoDurationCheckResult> = [];
    for (const selectedFile of selectedFiles) {
      const res = await this.getVideoDuration(selectedFile);
      info.push(res);
    }
    return info;
  }

  getVideoDuration(selectedFile: SelectedFile): Promise<VideoDurationCheckResult> {
    return new Promise(resolve => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        const duration = video.duration;
        window.URL.revokeObjectURL(video.src);
        resolve({ selectedFile, duration });
      }

      video.onerror = err => {
        console.log(err);
        resolve({
          selectedFile,
          duration: -1,
          error: 'loading-error'
        });
      }

      if (isMobilePlatform(this.config.platform)) {

        const reader = new FileReader();
        reader.onload = function () {
          video.src = reader?.result?.toString() || '';
        }

        reader.readAsDataURL(selectedFile.file);
      } else {
        video.src = URL.createObjectURL(selectedFile.file);
      }
    });
  }

}
