// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-participant-card {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 5px;
}
.group-participant-card .image-container {
  grid-row: 1/-1;
  grid-column: 1;
  align-self: center;
}
.group-participant-card .fullname-container {
  grid-row: 1;
  grid-column: 2;
  justify-self: start;
  align-self: end;
}
.group-participant-card .country-container {
  grid-row: 2;
  grid-column: 2;
  justify-self: start;
  align-items: start;
}
.group-participant-card .buttons-container {
  grid-row: 1/-1;
  grid-column: 3;
  align-self: start;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.group-participant-card .buttons-container .follow-unfollow-button {
  grid-row: 1;
  grid-column: 1;
  justify-self: center;
  align-self: center;
}
.group-participant-card .buttons-container .member-actions-button {
  grid-row: 1;
  grid-column: 2;
  justify-self: center;
  align-self: center;
}
.group-participant-card .buttons-container .member-actions-button app-icon {
  color: var(--mi-white-color);
}
.group-participant-card .text {
  margin: 0;
  color: var(--ion-text-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
