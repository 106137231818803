import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

// components
import { SearchTrackingSource } from 'src/app/services/yeti-protocol/search';
import { SearchBarComponent } from '../../search-bar/search-bar.component';
import { WebAutoSuggestPopUpComponent } from '../web-auto-suggest-pop-up/web-auto-suggest-pop-up.component';

// services

import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { SearchDataService } from 'src/app/services/search-data.service';
import { SearchUiService } from 'src/app/services/search-ui-service.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { SearchService } from 'src/app/services/search.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { WebRecentSearchesPopUpComponent } from '../web-recent-searches-pop-up/web-recent-searches-pop-up.component';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() searchOnly: boolean;
  @Input() dialogShowed: boolean;
  @Input() popOverShowed: boolean;
  @Input() mobileDialog: boolean;
  @Input() fixedPlaceholderText?: string;
  @Input() useComponentOnlyAsInput = false;
  @Input() skipMaximize = false;

  @Output() searchFullWidth: EventEmitter<boolean> = new EventEmitter();
  @Output() showSearchDialog: EventEmitter<void> = new EventEmitter();
  @Output() searchTermEmitted: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchBar') searchBar: SearchBarComponent;

  shouldShowSearchField = true;
  isSearchFieldMaximized = false;
  searchTerm: string;
  suggestList: Array<string> = new Array<string>();
  searchClickEvent: Event;
  shouldFocus: boolean;
  searchEnterBtnHit = false;
  preventBlurAction: boolean;
  preventSuggestionsToShow: boolean;

  private webAutoSuggestPopUpShowed: boolean;
  private webRecentSearchesPopUpShowed: boolean;
  private mobileAutoSuggestShowed: boolean;
  private resizeSubscription: Subscription;
  private routeQueryParamsSubscription: Subscription;
  private suggestListFinishedSubscription: Subscription;
  private getSuggestListPromise: any;

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    private platform: Platform,
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private searchUiService: SearchUiService,
    private route: ActivatedRoute,
    private searchDataService: SearchDataService,
    private searchService: SearchService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  ngOnInit(): void {
    this.resizeSubscription = this.responsiveUtilsService?.resize?.subscribe(() => this.screenSizeChanged());

    this.suggestListFinishedSubscription = this.searchUiService.getSuggestListFinishedObservable
      .subscribe(() => this.searchRequestFinished());

    this.routeQueryParamsSubscription = this.route.queryParams.subscribe(queryParams => {

      const searchTerm = queryParams?.searchTerm;

      if (searchTerm && searchTerm !== '') {
        this.searchTerm = searchTerm;
        this.maximizeSearchField();
      } else {
        this.searchTerm = null;
        this.minimizeSearchField();
      }
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.hideWebAutoSuggestPopUp();
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showSearchIconOrFieldBasedOnWidth();
    })
  }

  ngOnDestroy(): void {
    this.resizeSubscription?.unsubscribe();
    this.routeQueryParamsSubscription?.unsubscribe();
    this.suggestListFinishedSubscription?.unsubscribe();
  }

  setEmptySearchState(event: Event): void {

    this.searchTerm = '';
    this.stopEventPropagation(event);
    this.focusSearchField();
    this.maximizeSearchField();
  }

  searchInputFieldBlur(): void {

    if (!this.searchTerm || this.searchTerm === '') {
      this.searchTerm = null;
      this.minimizeSearchField();
    }
  }

  clearSearchField(event: Event): void {

    this.uiUtilsService.stopEventPropagation(event);
    this.setEmptySearchState(event);

    if (this.useComponentOnlyAsInput) {
      return this.searchTermEmitted.emit(this.searchTerm);
    }

    if (this.webAutoSuggestPopUpShowed) {
      this.popoverController.dismiss();
    }

    if (!this.searchClickEvent) {
      this.el.nativeElement.querySelector('.global-search-container').click();
    }
  }

  searchValueChanged(): void {
    this.debounceSuggestions();
  }

  debounceSuggestions = _.debounce(() => {

    if (this.searchTerm?.length) {
      this.hideWebRecentSearchesPopUp();
    }

    if (this.searchTerm &&
      this.searchTerm.trim().length > 2 &&
      !this.searchEnterBtnHit &&
      !this.preventSuggestionsToShow) {
      this.getSuggestList();
    } else {
      this.searchDataService.triggerSearchTermChange([]);
    }

    this.preventSuggestionsToShow = false;
  }, 300);

  searchEnterHit(): void {
    this.searchEnterBtnHit = true;
    this.hideWebRecentSearchesPopUp();
    // this.searchTerm = searchValue;
    if (this.useComponentOnlyAsInput) {
      return this.searchTermEmitted.emit(this.searchTerm);
    }
    if (this.showSearchButton) {
      this.search();
      this.debounceSuggestions.cancel();
    }
  }

  searchFieldClicked(event: Event): void {

    this.searchClickEvent = event;

    if (this.isSearchFieldMaximized) {
      this.focusSearchField();
      return;
    }

    this.searchTerm = this.searchTerm && this.searchTerm.length > 0 ? this.searchTerm : '';
    this.focusSearchField();
    this.maximizeSearchField();

    // this.setEmptySearchState(event);

    // This code is added in order to revert the previuos behaviour that we had for mobile
    // (on click we open the search view where user can type)

    if (this.searchOnly) {
      return;
    }

    if (this.platform.width() < 760) {
      this.showSearchModal();
    } else {
      setTimeout(() => {
        this.showWebRecentSearchesPopUp();
      }, 300);
    }
  }

  get showSearchButton(): boolean {
    if (this.searchTerm && this.searchTerm.trim().length > 2) {
      return true;
    }

    return false;
  }

  async getSuggestList(): Promise<void> {
    const termLowerCase = this.searchTerm.toLowerCase();
    this.getSuggestListPromise = this.searchService.getSuggestList(termLowerCase);
    this.getSuggestListPromise?.then(async (list: Array<string>) => {
      if (list && list.length > 0 && !this.searchEnterBtnHit) {
        this.suggestList = list;
        this.searchDataService.triggerSearchTermChange(this.suggestList);
        return this.displaySuggestionResults();
      }
    })?.catch(err => {
      console.log(err);
    })?.finally(() => {
      this.searchRequestFinished();
    });
  }

  displaySuggestionResults(): Promise<void> {
    if (this.searchOnly) {
      return Promise.resolve();
    }

    if (this.platform.width() < 760 && !this.mobileAutoSuggestShowed) {
      this.showSearchModal();
    } else if (!this.webAutoSuggestPopUpShowed) {
      return this.showWebAutoSuggestPopUp();
    } else if (this.mobileAutoSuggestShowed || this.webAutoSuggestPopUpShowed) {
      this.searchDataService.triggerSearchTermChange(this.suggestList);
    }
    return Promise.resolve();
  }

  showWebAutoSuggestPopUp(): Promise<void> {
    if (this.webAutoSuggestPopUpShowed) {
      return Promise.resolve();
    }
    this.webAutoSuggestPopUpShowed = true;
    this.shouldFocus = true;

    return new Promise(resolve => {

      this.popoverController.create({
        component: WebAutoSuggestPopUpComponent,
        componentProps: {
          suggestList: this.suggestList,
        },
        keyboardClose: false,
        showBackdrop: false,
        cssClass: 'web-auto-suggest-pop-up',
        event: this.searchClickEvent,
        translucent: false
      }).then(popover => {

        const searchFieldsWidth = document.querySelectorAll('.global-search-container') || [];
        let searchFieldWidth = 0;

        searchFieldsWidth.forEach(el => {
          if (el?.clientWidth) {
            searchFieldWidth = el.clientWidth;
          }
        });

        document.querySelector('.web-auto-suggest-pop-up').setAttribute('style', `--width: ${searchFieldWidth}px`);

        popover.present().then(() => {
          resolve();
        });
        popover.onDidDismiss().then(() => {
          this.webAutoSuggestPopUpShowed = false;
        });
      });
    })
  }

  hideWebAutoSuggestPopUp(): void {
    if (this.webAutoSuggestPopUpShowed) {
      this.popoverController.dismiss();
    }
  }

  showWebRecentSearchesPopUp(): Promise<void> {

    if (this.webRecentSearchesPopUpShowed) {
      return Promise.resolve();
    }

    this.webRecentSearchesPopUpShowed = true;
    this.shouldFocus = true;

    return new Promise(resolve => {

      this.popoverController.create({
        component: WebRecentSearchesPopUpComponent,
        componentProps: {
          suggestList: this.suggestList,
        },
        keyboardClose: false,
        showBackdrop: false,
        cssClass: 'web-recent-searches-pop-up',
        event: this.searchClickEvent,
        translucent: false
      }).then(popover => {

        const searchFieldsWidth = document.querySelectorAll('.global-search-container') || [];
        let searchFieldWidth = 0;

        searchFieldsWidth.forEach(el => {
          if (el?.clientWidth) {
            searchFieldWidth = el.clientWidth;
          }
        });

        document.querySelector('.web-recent-searches-pop-up').setAttribute('style', `--width: ${searchFieldWidth}px`);

        popover.present().then(() => {
          resolve();
        });

        popover.onDidDismiss().then(() => {
          this.webRecentSearchesPopUpShowed = false;

          if (!this.searchTerm) {
            this.setEmptySearchState(null);
          }
        });
      });
    })
  }

  hideWebRecentSearchesPopUp(): void {
    if (this.webRecentSearchesPopUpShowed) {
      this.popoverController.dismiss();
    }
  }

  // This code is added in order to revert the previuos behaviour that we had for mobile
  // (on click we open the search view where user can type)
  showSearchModal(): void {
    this.showSearchDialog.next();
  }

  showSearchField(value: boolean): void {
    this.shouldShowSearchField = value;
  }

  showSearchIconOrFieldBasedOnWidth(): void {
    if (this.platform.width() >= 760 && this.platform.width() < 881) {
      this.showSearchField(false);
    } else {
      this.showSearchField(true);
    }
  }

  maximizeSearchField(): void {
    if (!this.skipMaximize) {
      this.setSearchFieldSize(true);
      this.showSearchField(true);
    }
  }

  minimizeSearchField(): void {
    this.setSearchFieldSize(false);
  }

  setSearchFieldSize(fullWidth: boolean): void {
    this.isSearchFieldMaximized = fullWidth;
    this.emitSearchFieldSize();
  }

  emitSearchFieldSize(): void {
    this.searchFullWidth.emit(this.isSearchFieldMaximized);
  }

  searchClicked(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    if (this.useComponentOnlyAsInput) {
      return this.searchTermEmitted.emit(this.searchTerm);
    }
    this.search();
  }

  search(): void {

    if (!this.searchTerm || this.searchTerm === '') {
      return;
    }

    const searchTermEncoded = window.encodeURIComponent(this.searchTerm);
    const url = `${this.contextService?.currentContext?.key}/search?searchTerm=${searchTermEncoded}`;

    if (this.popOverShowed && this.webAutoSuggestPopUpShowed) {
      this.popoverController.dismiss();
    }

    if (this.dialogShowed) {
      this.modalController.dismiss();
    }

    this.router.navigateByUrl(url, { state: { id: 'source', name: SearchTrackingSource.NEW } });
    this.preventSuggestionsToShow = true;

    if (this.getSuggestListPromise) {
      this.getSuggestListPromise.finally(() => {
        this.searchEnterBtnHit = false;
      })
    } else {
      this.searchEnterBtnHit = false;
    }
  }

  stopEventPropagation(event: Event): void {
    if (event) {
      this.uiUtilsService.stopEventPropagation(event);
    }
  }

  focusSearchField(): void {
    setTimeout(() => {
      this.el.nativeElement.querySelector('.search-input-field').focus();
    }, 300);
  }

  private screenSizeChanged() {
    this.showSearchIconOrFieldBasedOnWidth();
  }

  private searchRequestFinished(): void {
    this.shouldFocus = false;
    this.focusSearchField();
  }
}
