import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum IconLabelPosition {
  RIGHT = 'right',
  BOTTOM = 'bottom'
}

@Component({
  selector: 'app-base-button',
  template: ''
})
export class BaseButtonComponent<T> {

  @Input() iconLabelPosition: IconLabelPosition = IconLabelPosition.RIGHT;
  @Output() clicked: EventEmitter<T> = new EventEmitter<T>();

  onClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    const eventData = this.getEventData();
    if(eventData !== undefined){
      this.clicked.emit(eventData);
    }else{
      this.clicked.emit()
    }
  }

  getEventData(): T{
    return undefined;
  }
}
