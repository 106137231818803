import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-state-search',
  templateUrl: './empty-state-search.component.html',
  styleUrls: ['./empty-state-search.component.scss'],
})
export class EmptyStateSearchComponent {
  @Input() title: string;
  @Input() text: string;
}
