// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.empty-state-container app-icon {
  --icon-size: 44px;
}
.empty-state-container .message {
  margin-top: 10px;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}
.empty-state-container .text {
  width: 60%;
  margin-top: 5px;
  text-align: center;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
