import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[app-multi-click]'
})
export class MultiClickDirective {

  @Input() multiClicksDurationMs = 1000;
  @Input('app-multi-click') multiClicks = 3;
  @Output() multiClick:EventEmitter<number> = new EventEmitter<number>();
  lastClickTime = 0;
  clickConter = 0;

  @HostListener('mouseup')
  onClick(): void{
    const t = Date.now();
    if (t - this.lastClickTime < this.multiClicksDurationMs) {
      this.clickConter++;
    } else {
      this.clickConter = 1
      this.lastClickTime = t;
    }
    if (this.clickConter >= this.multiClicks) {
      this.clickConter = 0;
      this.lastClickTime = 0;
      this.multiClick.emit(this.multiClicks);
    }
  }
}
