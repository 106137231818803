import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-group-image-button',
  templateUrl: './group-image-button.component.html',
  styleUrls: ['./group-image-button.component.scss'],
})
export class GroupImageButtonComponent extends BaseButtonComponent<void> {
  @Input() iconOnly: boolean;
  @Input() title: string;
  @Input() buttonText = 'app.common.image';
}
