// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  margin: 15px 0;
}
.header-container .title {
  color: var(--mi-black-color-2);
  font-size: var(--font-size-large-2);
  line-height: var(--font-line-height-large-2);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}
.header-container .add-module {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.header-container .add-module .text {
  color: var(--mi-grey-color-16);
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.header-container .add-module app-icon {
  --icon-size: 25px;
  cursor: pointer;
}

.data-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
