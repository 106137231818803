import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-verification-step-four',
  templateUrl: './verification-step-four.component.html',
  styleUrls: ['./verification-step-four.component.scss'],
})
export class VerificationStepFourComponent {

  @Output() finish: EventEmitter<void> = new EventEmitter();

  letsGo(): void {
    this.finish.emit();
  }

}
