// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --card-width: 192px;
}
:host .event-card {
  min-width: var(--card-width);
  max-width: initial;
  box-shadow: 0px 3px 20px var(--mi-shadow-color);
  margin: 0;
  cursor: pointer;
}
:host .event-card .redirect-icon-container {
  position: absolute;
  top: 12px;
  right: 8px;
  background-color: var(--mi-blue-color-4);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid var(--mi-white-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
:host .event-card .redirect-icon-container app-icon {
  --icon-size: 15px;
  color: var(--mi-grey-color-11);
}
:host .event-card .event-card-header {
  height: 28px;
  background-color: var(--mi-thumbnails-background-color);
}
:host .event-card .event-card-content {
  padding-bottom: 0;
  margin-top: 14px;
}
:host .event-card .event-card-content .secondary-text {
  display: flex;
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
:host .event-card .event-card-content .secondary-text.date {
  color: var(--mi-color-text-lite);
}
:host .event-card .event-card-content .event-title {
  width: 100%;
  height: 100%;
  text-align: left;
}
:host .event-card .event-card-content .event-title app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-height: 58px;
  --multiline-text-with-ellipsis-max-height: 58px;
}
:host .event-card .event-card-content .event-title app-multiline-text-with-ellipsis ::ng-deep p {
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  color: var(--mi-black-color-2);
}
:host .event-card .event-card-content .event-place {
  width: 100%;
  height: 100%;
  text-align: left;
}
:host .event-card .event-card-content .event-place app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-height: 20px;
  --multiline-text-with-ellipsis-max-height: 20px;
}
:host .event-card .event-card-content .event-place app-multiline-text-with-ellipsis ::ng-deep p {
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  color: var(--mi-grey-color-10);
}
:host .event-card .event-card-content .event-additional-info {
  display: flex;
}
:host .event-card .event-card-content .event-additional-info .additional-info-item::first-letter {
  text-transform: uppercase;
}
:host .event-card .event-card-content .event-additional-info .additional-info-item {
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  color: var(--mi-secondary-color-2);
  background-color: var(--mi-blue-color-4);
  border-radius: 8px;
  padding: 4px 8px;
  margin: 5px 5px 5px 0;
  text-transform: lowercase;
}
:host .event-card .event-card-content .event-additional-info .additional-info-item app-user-profile-image {
  --ao-icon-size: 18px;
}
:host .event-card .event-card-content .event-additional-info .additional-info-item app-user-full-name {
  --name-text-font-size: var(--font-size-regular);
  --name-text-font-weight: var(--font-weight-medium);
  --name-text-color: var(--ion-color-light-contrast);
  --country-text-color: var(--mi-color-text-lite);
  --country-text-font-size: var(--font-size-small);
  --country-text-font-weight: var(--font-weight-regular);
}
:host .event-card .event-card-content .event-additional-info .display-flex {
  display: flex;
  align-items: center;
}
:host .event-card .event-card-content .event-option {
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
