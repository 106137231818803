import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent {
  @Input() videoUrl: string;
  @Input() videoMimeType: string;
  @Input() videoThumbnailImageUrl: string;

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private modalController: ModalController
  ) { }

  onClose(): void {
    this.modalController.dismiss();
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

}
