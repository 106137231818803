import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  get href(): string {
    return window.location.href;
  }

  set href(href: string){
    window.location.href = href;
  }

  get pathname(): string {
    return window.location.pathname;
  }

  get origin(): string {
    return window.location.origin;
  }

  reload(): void {
    window.location.reload();
  }
}
