// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stage-of-treatment-chips-container {
  width: 100%;
  height: 100%;
}
.stage-of-treatment-chips-container app-chips ::ng-deep .chips-container {
  text-align: start;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
