import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

// components

// models
import { Platform } from 'src/config/config.model';
import { FileSelectLimits, FileType, SelectedFile } from '../../file-select.model';
import { AttachmentMimeType } from 'src/app/services/attachments.model';
import { GalleryConfig } from '../../services/image-gallery.model';

// services
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { FileSelectScope, FileSelectService } from '../../services/file-select.service';
import { FileSelectDeviceService } from '../../services/file-select.device.service';

import appConfig from 'src/config/config';
import { isMobilePlatform } from 'src/app/services/utils/utils';
import { ImageGalleryService } from '../../services/image-gallery.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import {TranslateService} from '@ngx-translate/core';
import {DialogsUIService} from '../../../../services/dialogs/dialogs.ui.service';

interface FileSelectComponentConfig {
  platform: Platform;
}

@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss'],
})
export class FileSelectComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() fileType: FileType;
  @Input() mimeType: string;
  @Input() allowMultipleFilesSelection: boolean;
  @Input() maxFiles = FileSelectLimits.maxFiles;
  @Input() currentMaxFiles = FileSelectLimits.maxFiles;
  @Input() maxFileSizeMb = FileSelectLimits.imageMaxFileSizeMB;
  @Input() minImageWidth = FileSelectLimits.minImageWidth;
  @Input() minImageHeight = FileSelectLimits.minImageHeight;
  @Input() multipleSelectionMaxCount = FileSelectLimits.multipleSelectionMaxCount;
  @Input() editImages: boolean;
  @Input() skipGallery: boolean;
  @Input() hideVideoSelectOption: boolean;
  @Input() scope: FileSelectScope;

  @Output() selectedFilesChanged: EventEmitter<Array<SelectedFile>> = new EventEmitter();

  config: FileSelectComponentConfig = appConfig;

  imagesCompressing: boolean;
  FileType = FileType;

  private compressingImagesToastContainerClass = '.compressing-images-info-message-container';
  private imageCompressionFinishedSubscription: Subscription;
  private imagesSelectedFromGallerySubscription: Subscription;
  private readonly randomInfoSheetIdNumber = Math.floor(Math.random() * 1000000);

  constructor(
    private infoSheetService: InfoSheetService,
    private fileSelectService: FileSelectService,
    private fileSelectDeviceService: FileSelectDeviceService,
    private imageGalleryService: ImageGalleryService,
    private el: ElementRef,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private translateService: TranslateService,
    private dialogsUIService: DialogsUIService
  ) { }

  ngOnInit(): void {
    this.imageCompressionFinishedSubscription = this.fileSelectDeviceService?.imageCompressionFinishedObservable
      ?.subscribe(() => {
        this.imagesCompressionEnd();
      });

    this.imagesSelectedFromGallerySubscription = this.fileSelectDeviceService?.imagesSelectedFromGalleryObservable
      ?.subscribe(() => {
        this.imagesCompressionStart();
      });
  }

  ngAfterViewInit(): void {
    this.addMessageToBodyElement(this.compressingImagesToastContainerClass);
  }

  ngOnDestroy(): void {
    this.removeMessageFromBodyElement(this.compressingImagesToastContainerClass);
    this.imageCompressionFinishedSubscription?.unsubscribe();
    this.imagesSelectedFromGallerySubscription?.unsubscribe();
  }

  get infoSheetId(): string { // this must be used, otherwise wrong infosheet is showed
    return `file-select-component-info-sheet-${this.fileType}-${this.scope}-${this.randomInfoSheetIdNumber}`;
  }

  get mimeTypeValue(): string {
    if (!this.mimeType) {
      switch (this.fileType) {
        case FileType.IMAGE:
          this.mimeType = 'image/*';
          break;
        case FileType.VIDEO:
          this.mimeType = 'video/*';
          break;
        case FileType.PDF:
          this.mimeType = AttachmentMimeType.PDF;
          break;
        default:
          this.mimeType = '*';
      }
    }
    return this.mimeType;
  }

  async emitSelectedFiles(files: Array<SelectedFile>): Promise<void> {
    files = await this.fileSelectService.checkMaxFileCountExceeded(files, this.maxFiles, this.scope, this.currentMaxFiles);
    this.selectedFilesChanged.emit(files);
  }

  public selectFile(): void {
    if (isMobilePlatform(this.config.platform)) {
      switch (this.fileType) {
        case FileType.IMAGE:
        case FileType.VIDEO:
          this.selectImageMobile();
          break;
        default:
          this.selectFileMobile();
      }
    } else {
      this.selectFileWeb();
    }
  }

  selectFileWeb(): void {
    this.fileInput.nativeElement.click();
  }

  selectImageMobile(): void {
    this.infoSheetService.open(this.infoSheetId);
  }

  selectFileMobile(): void {
    this.fileSelectDeviceService.selectFile(this.maxFileSizeMb, this.mimeTypeValue, this.scope)
      .then((selectedFiles: SelectedFile[]) => {
        const mimeTypes: string[] = this.mimeTypeValue.split(',');
        const allFilesValid = selectedFiles.every(({file}) => mimeTypes.includes(file.type));
        if (allFilesValid) {
          this._notifyFilesSelection(selectedFiles);
        } else {
          const message = this.translateService.instant('app.common.select-input-error').replace('{mimeType}', this.fileType);
          this.dialogsUIService.showErrorDialog(message);
        }
      });
  }

  selectAVideoMobile(): void {

    this.fileSelectDeviceService.selectVideoMobile(this.maxFileSizeMb, this.scope).then(selectedFiles => {
      this._notifyFilesSelection(selectedFiles);
    });

    // because Chooser plugin doesn't allow file count restriction
    // and we need to upload only 1 video at a time, we need to use the Camera plugin for video selection
    // promise = this.fileSelectDeviceService.selectFile(this.maxFileSizeMb, this.mimeTypeValue, this.scope);
  }

  takeAPhotoMobile(): void {

    this.imagesCompressionStart();

    this.fileSelectDeviceService.takeAPhoto(
      this.maxFileSizeMb,
      this.editImages,
      this.skipGallery,
      this.scope,
      this.minImageWidth,
      this.minImageHeight)
      .then(selectedFiles => {
        this._notifyFilesSelection(selectedFiles);
      }).finally(() => {
        this.imagesCompressionEnd();
      });
  }

  selectImageFromGalleryMobile(): void {

    this.fileSelectDeviceService.selectImageFromGallery(
      this.maxFiles,
      this.maxFileSizeMb,
      this.editImages,
      this.skipGallery,
      this.scope,
      this.multipleSelectionMaxCount,
      this.minImageWidth,
      this.minImageHeight)
      .then(selectedFiles => {
        this._notifyFilesSelection(selectedFiles);
      }).finally(() => {
        this.imagesCompressionEnd();
      });
  }

  _notifyFilesSelection(selectedFiles: Array<SelectedFile> | void): void {
    if (selectedFiles && selectedFiles.length > 0) {
      this.emitSelectedFiles(selectedFiles);
    }
  }

  async onFileSelectedWeb(event: Event): Promise<void> {

    const filesInput = event.target as HTMLInputElement;
    const files = filesInput?.files?.length > 0 ? Array.from(filesInput?.files) : null;

    let selectedFiles: Array<SelectedFile> = await this.fileSelectService.readFiles(files);

    selectedFiles = await this.fileSelectService.checkMaxFileSizeExceeded(
      [...selectedFiles],
      this.maxFileSizeMb,
      this.scope
    );

    if (!selectedFiles || selectedFiles?.length === 0) {
      return;
    }

    selectedFiles = await this.fileSelectService.checkMultipleSelectionMaxCountExceeded(
      [...selectedFiles],
      this.multipleSelectionMaxCount,
      this.scope
    );

    const sortedFiles = this.fileSelectService.sortFiles(selectedFiles);

    sortedFiles.imageFiles = await this.fileSelectService.checkImageMinDimensions(
      sortedFiles.imageFiles,
      this.minImageWidth,
      this.minImageHeight,
      this.scope
    );

    this.imagesCompressionStart();
    sortedFiles.imageFiles = await this.fileSelectService.compressImageFiles(sortedFiles.imageFiles, this.maxFileSizeMb);
    this.imagesCompressionEnd();

    if (sortedFiles.imageFiles.length > 0 && !this.skipGallery) {
      const galleryConfig: GalleryConfig = {
        images: this.fileSelectService.selectedFileToImageAttachmentTypeConversion(sortedFiles.imageFiles),
        allowImageRemove: true,
        allowImageEdit: this.editImages,
        reviewMode: true,
        scope: this.scope,
        imageGalleryStrategy: null
      };

      const reviewedImages = await this.imageGalleryService.openImageGallery(galleryConfig);

      if (reviewedImages?.images && reviewedImages?.images.length > 0) {
        sortedFiles.imageFiles = this.fileSelectService.imageAttachmentToSelectedFileTypeConversion(reviewedImages?.images);
      } else {
        sortedFiles.imageFiles = [];
      }
    }

    selectedFiles = [...sortedFiles.imageFiles, ...sortedFiles.otherFiles];
    this._notifyFilesSelection(selectedFiles);
    filesInput.value = '';
  }

  private imagesCompressionStart(): void {
    this.imagesCompressing = true;
  }

  private imagesCompressionEnd(): void {
    this.imagesCompressing = false;
  }

  private addMessageToBodyElement(messageContainerClass: string): void {
    const uploadingMessageEl = this.el.nativeElement.querySelector(messageContainerClass);
    if (uploadingMessageEl) {
      document.body.appendChild(uploadingMessageEl);
    }
  }

  private removeMessageFromBodyElement(messageContainerClass: string): void {
    const uploadingMessageEl = document.querySelector(messageContainerClass);
    document.body.appendChild(uploadingMessageEl);
  }

  get isMobileBrowser(): boolean {

    if (this.uiUtilsService && this.uiUtilsService?.isMobileBrowser) {
      return this.uiUtilsService?.isMobileBrowser();
    }

    return false;
  }

}
