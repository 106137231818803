import { Routes } from '@angular/router';

export const rootRoutes: Routes = [
  {
    path: 'vet',
    loadChildren: () => import('./vet.module').then(m => m.VetModule)
  },
  {
    path: 'vet',
    loadChildren: () => import('./vet-auxiliary.module').then(m => m.VetAuxiliaryModule),
    outlet: 'dialog'
  }
];

export const navigationTabsRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./vet-tabs.module').then(m => m.VetTabsModule)
  }
];
