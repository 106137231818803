import { SafeUrl } from '@angular/platform-browser';

export interface MobileFileData {
  data: Uint8Array;
  dataURI: string;
  mediaType: string;
  name: string;
  uri: string; // local file
}

export interface SelectedFile {
  file: File;
  url?: string;
  safeUrl?: SafeUrl;
  width?: number;
  height?: number;
  fileName?: string;
}

export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  PDF = 'pdf',
  PPT = 'ppt'
}

/* eslint-disable */ // problem: no-duplicate-enum-values
export enum FileSelectLimits {
  maxFiles = 5,
  imageMaxFileSizeMB = 40,
  documentMaxFileSizeMB = 40,
  maxVideoFiles = 5,
  maxVideoFileSizeMb = 40,
  minImageWidth = 200,
  minImageHeight = 200,
  maxImagesCount = 20,
  multipleSelectionMaxCount = 5
}
/* eslint-enable */
