import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';

// components
import { InterestsListComponent } from '../../components/interests-list/interests-list.component';

// models
import { ContextConfigInterface } from '../../../config/config.model';

export interface AddContextComponentInterface {
  contextAdded(success: boolean);
}

export interface AddContextNotifications {
  addContext(contextKey: string, interests: Array<string>, component: AddContextComponentInterface): void;
  showError(errorKey: string): void;
  close(): void;
}

@Component({
  selector: 'app-add-context',
  templateUrl: './add-context.component.html',
  styleUrls: ['./add-context.component.scss'],
})
export class AddContextComponent implements AfterViewInit, AddContextComponentInterface{

  @ViewChild('interestsList', { static: false }) interestsList: InterestsListComponent;

  @Input() availableForAddingContexts: Array<ContextConfigInterface>;
  @Input() selectedContext: ContextConfigInterface = null;
  @Input() interestsSelectionOnly = false;
  @Input() selectedInterestsInput: Array<string>;
  @Input() addContextNotifications: AddContextNotifications;

  selectedInterests: Array<string>;
  interestsListFormValid: boolean;
  addingInterest = false;

  ngAfterViewInit(): void {
    if (this.selectedInterestsInput && this.selectedInterestsInput.length) {
      this.interestsList.selectedInterestsIds = this.selectedInterestsInput;
    }
  }

  addContext(): void {
    if (this.selectedContext && this.selectedInterests && this.selectedInterests.length > 0) {
      this.addingInterest = true;
      this.addContextNotifications.addContext(this.selectedContext.key, this.selectedInterests, this);
    } else {
      this.addContextNotifications.showError('app.common.error-default');
    }
  }

  contextAdded(): void {
    this.addingInterest = false;
  }

  selectContext(context: ContextConfigInterface): void {
    this.selectedContext = context;
  }

  selectedInterestsChanged(selectedInterests: Array<string>): void {
    this.selectedInterests = selectedInterests;
  }

  interestsListFormValidStatusChange(interestsListFormValid: boolean): void {
    this.interestsListFormValid = interestsListFormValid;
    this.interestsListFormValid = !!this.selectedInterests.length;
  }

  selectAllInterests(): void {
    this.interestsList?.selectAllInterests();
  }

  cancel(): void {
    if(this.interestsSelectionOnly){
      this.addContextNotifications.close();
    }else{
      this.selectedContext = null;
    }
  }

  onClose(): void {
    this.addContextNotifications.close();
  }

}
