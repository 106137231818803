import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

@Component({
  selector: 'app-connection-requests-dialog',
  templateUrl: './connection-requests-dialog.component.html',
  styleUrls: ['./connection-requests-dialog.component.scss'],
})
export class ConnectionRequestsDialogComponent {

  constructor(
    private modalController: ModalController,
    private appNavController: AppNavController
  ) { }

  onClose(): void {
    this.modalController.dismiss();
  }

  onOpenMessenger(connectionId: string): void {
    this.onClose();
    this.appNavController.openMessenger(connectionId);
  }
}
