import { EventEmitter} from '@angular/core';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { Message } from 'src/app/services/yeti-protocol/message';
import { MessagePressedEvent } from './messenger-list.model';

export abstract class MessageCardBase {
  userId: string;
  message: Message;
  showCommands: EventEmitter<MessagePressedEvent>;
  responsiveUtilsService: ResponsiveUtilsService;
  logPressHandled = false;

  constructor(responsiveUtilsService: ResponsiveUtilsService){
    this.responsiveUtilsService = responsiveUtilsService;
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  onLongPress(event: Event): void{
    if(this.logPressHandled){
      return;
    }
    this.logPressHandled = true;
    this.showCommands.next({
      messageId: this.message._id,
      event
    });
  }

  onPressEnded(): void{
    this.logPressHandled = false;
  }

  onHoverClick(event: Event): void{
    this.showCommands.next({
      messageId: this.message._id,
      event
    });
  }

  get isOwnMessage(): boolean {
    return this.message.sender.userId === this.userId;
  }
}
