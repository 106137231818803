// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --verification-step-four-padding-top: 0px;
  --verification-step-four-padding-bottom: 16px;
  --verification-step-four-padding-start: 16px;
  --verification-step-four-padding-end: 16px;
  --content-max-width: 100%;
}

.verification-step-four-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  justify-items: center;
}
.verification-step-four-container .header {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  padding-bottom: 30px;
}
.verification-step-four-container .content {
  width: 100%;
  max-width: var(--content-max-width);
  height: 100%;
  padding-top: var(--verification-step-four-padding-top);
  padding-bottom: var(--verification-step-four-padding-bottom);
  grid-row: 2;
  grid-column: 1;
}
.verification-step-four-container .content ion-content {
  --padding-start: var(--verification-step-four-padding-start);
  --padding-end: var(--verification-step-four-padding-end);
}
.verification-step-four-container .content ion-content .title-container {
  padding-bottom: 14px;
}
.verification-step-four-container .content ion-content .title-container .title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: center;
}
.verification-step-four-container .content ion-content .subtitle-container {
  max-width: 315px;
  margin: 0 auto;
}
.verification-step-four-container .content ion-content .subtitle-container .subtitle {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  text-align: center;
}
.verification-step-four-container .action-button-container {
  width: 100%;
  max-width: var(--content-max-width);
  grid-row: 3;
  grid-column: 1;
  padding-top: 25px;
  padding-bottom: 37px;
  padding-left: var(--verification-step-four-padding-start);
  padding-right: var(--verification-step-four-padding-end);
}
.verification-step-four-container .action-button-container ion-button {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
