// random integer [0..topMargin)
export function randomInt(topMargin: number): number {
  const crypto = window.crypto;
  if(crypto){
    const arr = new Uint32Array(1);
    return crypto.getRandomValues(arr)[0] % topMargin;
  }else{ // fallback
    return Math.floor(Math.random() * topMargin) % topMargin;
  }
}

export function generateUUID(): string {
  let d = new Date().getTime();
  if (window.performance && window.performance.now){
      d += window.performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      /* eslint-disable */
      const r = (d + randomInt(16)) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      /* eslint-enable */
  });
}

