import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { PostsFeedStateModel } from './posts-feed.model';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { POSTS_FEED_STATE_KEY } from '../state-constants';
import { ChatterApiService } from 'src/app/services/chatter-api.service';
import {
  PinPostSuccessResponse,
  PostListItem,
  PostsListResponse,
  UnpinPostSuccessResponse
} from 'src/app/services/yeti-protocol/chatter-api';
import { PostsFeed } from './posts-feed.actions';

const POSTS_FEED_STATE_TOKEN = new StateToken<PostsFeedStateModel>(
  POSTS_FEED_STATE_KEY
);

@State({
  name: POSTS_FEED_STATE_TOKEN,
  defaults: {
    allPostsFeedItems: [],
    postsFeedItemsWithoutPinned: [],
    pinnedPostsFeedItems: [],
    totalCount: 0,
    fetchPostsFeedItemsloading: false,
    pinPostsFeedItemloading: false,
    unpinPostsFeedItemloading: false,
    parentType: undefined,
    parentId: undefined
  },
})
@Injectable()
export class PostsFeedState {

  constructor(private chatterApiService: ChatterApiService) { }

  @Selector()
  static state(state: PostsFeedStateModel): PostsFeedStateModel {
    return state;
  }

  @Selector()
  static allPostsFeedItems(state: PostsFeedStateModel): Array<PostListItem> {
    return state.allPostsFeedItems;
  }

  @Selector()
  static postsFeedItemsWithoutPinned(state: PostsFeedStateModel): Array<PostListItem> {
    return state.postsFeedItemsWithoutPinned;
  }

  @Selector()
  static pinnedPostsFeedItems(state: PostsFeedStateModel): Array<PostListItem> {
    return state.pinnedPostsFeedItems;
  }

  @Selector()
  static totalCount(state: PostsFeedStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static fetchPostsFeedItemsloading(state: PostsFeedStateModel): boolean {
    return state.fetchPostsFeedItemsloading;
  }

  @Action(PostsFeed.FetchPostsFeed)
  async fetchPostsFeed(
    ctx: StateContext<PostsFeedStateModel>,
    payload: PostsFeed.FetchPostsFeed,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): Promise<void> {
    try {
      ctx.patchState({
        fetchPostsFeedItemsloading: true,
      });

      const postsFeedRes = (await this.chatterApiService.getPostsV2(
        payload?.payloadParams?.parentId,
        payload?.payloadParams?.parentType,
        payload?.payloadParams?.facultyOnly,
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize
      )) as PostsListResponse;

      const fetchPostsFeedSuccessAction = childActionsNamespace?.FetchPostsFeedSuccess ?
        new childActionsNamespace.FetchPostsFeedSuccess(
          payload?.payloadParams,
          postsFeedRes) :
        new PostsFeed.FetchPostsFeedSuccess(
          payload?.payloadParams,
          postsFeedRes
        );

      ctx.dispatch(
        fetchPostsFeedSuccessAction
      );
    } catch (err) {

      const fetchPostsFeedFailedAction = childActionsNamespace?.FetchPostsFeedFailed ?
        new childActionsNamespace.FetchPostsFeedFailed() :
        new PostsFeed.FetchPostsFeedFailed();

      ctx.dispatch(
        fetchPostsFeedFailedAction
      );

      throw err;
    }
  }

  @Action(PostsFeed.FetchPostsFeedSuccess)
  fetchPostsFeedSuccess(
    ctx: StateContext<PostsFeedStateModel>,
    action: PostsFeed.FetchPostsFeedSuccess,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        allPostsFeedItems: action.response.result,
        totalCount: action.response.totalItemsCount,
        fetchPostsFeedItemsloading: false,
      });
    } else {
      ctx.patchState({
        allPostsFeedItems: [...state.allPostsFeedItems, ...action.response.result],
        totalCount: action.response.totalItemsCount,
        fetchPostsFeedItemsloading: false,
      });
    }

    const setPinnedAndNotPinnedPostsFeedItemsAction = childActionsNamespace?.SetPinnedAndNotPinnedPostsFeedItems ?
      new childActionsNamespace.SetPinnedAndNotPinnedPostsFeedItems() :
      new PostsFeed.SetPinnedAndNotPinnedPostsFeedItems();

    ctx.dispatch(setPinnedAndNotPinnedPostsFeedItemsAction);
  }

  @Action(PostsFeed.FetchPostsFeedFailed)
  fetchPostsFeedFailed(
    ctx: StateContext<PostsFeedStateModel>
  ): void {
    ctx.patchState({
      fetchPostsFeedItemsloading: false,
    });
  }

  @Action(PostsFeed.InsertPostsFeedItemBeforeIndex)
  insertPostsFeedItemBeforeIndex(
    ctx: StateContext<PostsFeedStateModel>,
    action: PostsFeed.InsertPostsFeedItemBeforeIndex,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): void {

    const state = ctx.getState();

    if (state.parentType !== action.item.parentType || state.parentId !== action.item.parentId) {
      return;
    }

    ctx.setState(
      patch<PostsFeedStateModel>({
        allPostsFeedItems: insertItem<PostListItem>(action.item, action.index)
      })
    );

    ctx.patchState({
      totalCount: state.totalCount + 1,
    });

    const setPinnedAndNotPinnedPostsFeedItemsAction = childActionsNamespace?.SetPinnedAndNotPinnedPostsFeedItems ?
      new childActionsNamespace.SetPinnedAndNotPinnedPostsFeedItems() :
      new PostsFeed.SetPinnedAndNotPinnedPostsFeedItems();

    ctx.dispatch(setPinnedAndNotPinnedPostsFeedItemsAction);
  }

  @Action(PostsFeed.RemovePostsFeedItem)
  removePostsFeedItem(
    ctx: StateContext<PostsFeedStateModel>,
    action: PostsFeed.RemovePostsFeedItem,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): void {

    const state = ctx.getState();

    if (state.parentType !== action.item.parentType || state.parentId !== action.item.parentId) {
      return;
    }

    const itemIndex = state.allPostsFeedItems.findIndex(item => item._id === action?.item?._id);

    if (itemIndex === -1) {
      return;
    }

    ctx.setState(
      patch<PostsFeedStateModel>({
        allPostsFeedItems: removeItem<PostListItem>(itemIndex)
      })
    );

    ctx.patchState({
      totalCount: state.totalCount - 1,
    });

    const setPinnedAndNotPinnedPostsFeedItemsAction = childActionsNamespace?.SetPinnedAndNotPinnedPostsFeedItems ?
      new childActionsNamespace.SetPinnedAndNotPinnedPostsFeedItems() :
      new PostsFeed.SetPinnedAndNotPinnedPostsFeedItems();

    ctx.dispatch(setPinnedAndNotPinnedPostsFeedItemsAction);
  }

  @Action(PostsFeed.UpdatePostsFeedItem)
  updatePostsFeedItem(
    ctx: StateContext<PostsFeedStateModel>,
    action: PostsFeed.UpdatePostsFeedItem,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): void {

    const state = ctx.getState();

    if (state.parentType !== action.item.parentType || state.parentId !== action.item.parentId) {
      return;
    }

    ctx.setState(
      patch<PostsFeedStateModel>({
        allPostsFeedItems: updateItem<PostListItem>(item => item._id === action.item._id, action.item)
      })
    );

    const setPinnedAndNotPinnedPostsFeedItemsAction = childActionsNamespace?.SetPinnedAndNotPinnedPostsFeedItems ?
      new childActionsNamespace.SetPinnedAndNotPinnedPostsFeedItems() :
      new PostsFeed.SetPinnedAndNotPinnedPostsFeedItems();

    ctx.dispatch(setPinnedAndNotPinnedPostsFeedItemsAction);
  }

  @Action(PostsFeed.SetPinnedAndNotPinnedPostsFeedItems)
  setPinnedAndNotPinnedPostsFeedItems(ctx: StateContext<PostsFeedStateModel>): void {

    const state = ctx.getState();

    const allPostsFeedItems = [...state.allPostsFeedItems];

    const pinnedItems = [];
    const postsFeedItemsWithoutPinned = allPostsFeedItems?.filter(item => {

      if (item?.isPinned) {
        pinnedItems.push(item);
        return false;
      }

      return true;
    });

    ctx.setState(
      patch<PostsFeedStateModel>({
        postsFeedItemsWithoutPinned: postsFeedItemsWithoutPinned,
        pinnedPostsFeedItems: pinnedItems
      })
    );
  }

  @Action(PostsFeed.PinPostsFeedItem)
  async pinPostsFeedItem(
    ctx: StateContext<PostsFeedStateModel>,
    payload: PostsFeed.PinPostsFeedItem,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): Promise<void> {
    try {
      ctx.patchState({
        pinPostsFeedItemloading: true,
      });

      const pinItemRes = (await this.chatterApiService.pinPost(payload.itemId)) as PinPostSuccessResponse;

      const pinPostsFeedItemSuccessAction = childActionsNamespace?.PinPostsFeedItemSuccess ?
        new childActionsNamespace.PinPostsFeedItemSuccess(pinItemRes) :
        new PostsFeed.PinPostsFeedItemSuccess(pinItemRes);

      ctx.dispatch(
        pinPostsFeedItemSuccessAction
      );
    } catch (err) {

      const pinPostsFeedItemFailedAction = childActionsNamespace?.PinPostsFeedItemFailed ?
        new childActionsNamespace.PinPostsFeedItemFailed() :
        new PostsFeed.PinPostsFeedItemFailed();

      ctx.dispatch(
        pinPostsFeedItemFailedAction
      );

      throw err;
    }
  }

  @Action(PostsFeed.PinPostsFeedItemSuccess)
  pinPostsFeedItemSuccess(
    ctx: StateContext<PostsFeedStateModel>,
    action: PostsFeed.PinPostsFeedItemSuccess,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): void {

    ctx.patchState({
      pinPostsFeedItemloading: false,
    });

    const updatePostsFeedItemAction = childActionsNamespace?.UpdatePostsFeedItem ?
      new childActionsNamespace.UpdatePostsFeedItem(action.pinItemSuccessResponse.result) :
      new PostsFeed.UpdatePostsFeedItem(action.pinItemSuccessResponse.result);

    ctx.dispatch(updatePostsFeedItemAction);
  }

  @Action(PostsFeed.PinPostsFeedItemFailed)
  pinPostsFeedItemFailed(
    ctx: StateContext<PostsFeedStateModel>
  ): void {
    ctx.patchState({
      pinPostsFeedItemloading: false,
    });
  }

  @Action(PostsFeed.UnpinPostsFeedItem)
  async unpinPostsFeedItem(
    ctx: StateContext<PostsFeedStateModel>,
    payload: PostsFeed.UnpinPostsFeedItem,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): Promise<void> {
    try {
      ctx.patchState({
        unpinPostsFeedItemloading: true,
      });

      const unpinItemRes = (await this.chatterApiService.unpinPost(payload.itemId)) as UnpinPostSuccessResponse;

      const unpinPostsFeedItemSuccessAction = childActionsNamespace?.UnpinPostsFeedItemSuccess ?
        new childActionsNamespace.UnpinPostsFeedItemSuccess(unpinItemRes) :
        new PostsFeed.UnpinPostsFeedItemSuccess(unpinItemRes);

      ctx.dispatch(
        unpinPostsFeedItemSuccessAction
      );
    } catch (err) {

      const unpinPostsFeedItemFailedAction = childActionsNamespace?.UnpinPostsFeedItemFailed ?
        new childActionsNamespace.UnpinPostsFeedItemFailed() :
        new PostsFeed.UnpinPostsFeedItemFailed();

      ctx.dispatch(
        unpinPostsFeedItemFailedAction
      );

      throw err;
    }
  }

  @Action(PostsFeed.UnpinPostsFeedItemSuccess)
  unpinPostsFeedItemSuccess(
    ctx: StateContext<PostsFeedStateModel>,
    action: PostsFeed.UnpinPostsFeedItemSuccess,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): void {

    ctx.patchState({
      unpinPostsFeedItemloading: false,
    });

    const updatePostsFeedItemAction = childActionsNamespace?.UpdatePostsFeedItem ?
      new childActionsNamespace.UpdatePostsFeedItem(action.unpinItemSuccessResponse.result) :
      new PostsFeed.UpdatePostsFeedItem(action.unpinItemSuccessResponse.result);

    ctx.dispatch(updatePostsFeedItemAction);
  }

  @Action(PostsFeed.UnpinPostsFeedItemFailed)
  unpinPostsFeedItemFailed(
    ctx: StateContext<PostsFeedStateModel>
  ): void {
    ctx.patchState({
      unpinPostsFeedItemloading: false,
    });
  }

  @Action(PostsFeed.SetParentTypeAndParentId)
  setParentTypeAndParentId(
    ctx: StateContext<PostsFeedStateModel>,
    action: PostsFeed.SetParentTypeAndParentId
  ): void {
    ctx.patchState({
      parentType: action.parentType,
      parentId: action.parentId
    });
  }

  @Action(PostsFeed.UpdatePostsFeedItemsOwnerFollowingStatus)
  updatePostsFeedItemsOwnerFollowingStatus(
    ctx: StateContext<PostsFeedStateModel>,
    action: PostsFeed.UpdatePostsFeedItemsOwnerFollowingStatus,
    // eslint-disable-next-line
    childActionsNamespace: any
  ): void {

    const state = ctx.getState();

    const allPostsFeedItems = [...state.allPostsFeedItems]?.map(item => {

      const itemCopy = { ...item };

      if (item?.owner?.userId === action?.userId) {
        itemCopy.owner.isFollower = action?.isFollower;
        return itemCopy;
      }

      return itemCopy;
    });

    ctx.setState(
      patch<PostsFeedStateModel>({
        allPostsFeedItems: allPostsFeedItems
      })
    );

    const setPinnedAndNotPinnedPostsFeedItemsAction = childActionsNamespace?.SetPinnedAndNotPinnedPostsFeedItems ?
      new childActionsNamespace.SetPinnedAndNotPinnedPostsFeedItems() :
      new PostsFeed.SetPinnedAndNotPinnedPostsFeedItems();

    ctx.dispatch(setPinnedAndNotPinnedPostsFeedItemsAction);
  }
}
