// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verified-badge-container {
  display: flex;
  align-items: center;
}
.verified-badge-container .ao-member-badge-icon {
  color: var(--mi-primary-color);
}
.verified-badge-container app-icon {
  --icon-size: 15px;
  font-size: unset;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
