export interface Socket {
  id: string;
  connected: boolean;
  disconnected: boolean;
  open(): Socket;
  // Manually disconnects the socket. In that case, the socket will not try to reconnect.
  close(): Socket;
  emit(eventName: string, ...data: any[]): Socket;
  on(eventName: string, callback: (data) => void): Socket;
}

export enum MISocketEvent {
  Message = 'message',
  Read = 'read',
  Acknowledge = 'acknowledge',
  ConnectionRequest = 'connection-request',
  ConnectionUpdate = 'connection-update',
  EventStart = 'event-start',
  ActivityNotification = 'notification',
  CaseAccessRequest = 'case-request',
  DocumentUploaded = 'document-uploaded',
  BulkConnectionRequestSuccessful = 'bulk-connection-request-successful',
  BulkConnectionRequestFailed = 'bulk-connection-request-failed',
  SuccessPptExtraction = 'success-ppt-extraction'
}
