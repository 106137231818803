import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

export interface TutorialDialogConfig {
  icon: string;
  title: string;
  subtitle: string;
  cancelButton: string;
  proceedButton: string;
}

@Component({
  selector: 'app-tutorial-dialog',
  templateUrl: './tutorial-dialog.component.html',
  styleUrls: ['./tutorial-dialog.component.scss'],
})
export class TutorialDialogComponent {

  @Input() icon: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() cancelButton: string;
  @Input() proceedButton: string;

  constructor(
    private modalController: ModalController
  ) { }

  cancel(): void {
    this.modalController.dismiss(false);
  }

  proceed(): void {
    this.modalController.dismiss(true);
  }

}
