// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
  --padding: 15px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: var(--padding);
  overflow: hidden;
}
.content-container .info-container {
  grid-row: 1;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  row-gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}
.content-container .info-container .title-container {
  grid-row: 1;
  grid-template-columns: 1/-1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content-container .info-container .title-container .title {
  margin-right: 15px;
}
.content-container .info-container .info-text {
  grid-row: 2;
  grid-column: 1;
}
.content-container .info-container .info-text p {
  margin: 0;
}
.content-container .info-container .button-container {
  grid-row: 2;
  grid-column: 2;
}
@media all and (max-width: 450px) {
  .content-container .info-container {
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
  }
  .content-container .info-container .button-container {
    grid-row: 3;
    grid-column: 1;
  }
}
.content-container .translation-languages-list-container {
  grid-row: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
