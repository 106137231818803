// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-additional-info {
  margin: 5px 0;
}
.profile-additional-info .additional-info-item {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
}
.profile-additional-info .display-flex {
  display: flex;
}
.profile-additional-info .blue-item {
  background-color: var(--mi-background-color-journls);
  color: var(--mi-hover-color);
  font-size: var(--mi-font-size-smaller);
  padding: 3px 10px;
  border-radius: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
