// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-contributor-card-container {
  width: 207px;
  height: 235px;
}
.event-contributor-card-container app-mi-card {
  --mi-card-padding-top: 10px;
  --mi-card-padding-bottom: 20px;
  --mi-card-padding-start: 10px;
  --mi-card-padding-end: 10px;
  --mi-card-margin-top: 0px;
  --mi-card-margin-bottom: 0px;
  --mi-card-margin-start: 0px;
  --mi-card-margin-end: 0px;
}
.event-contributor-card-container app-mi-card ::ng-deep ion-card {
  --background: var(--mi-white-color) !important;
}
.event-contributor-card-container app-mi-card .content-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  justify-content: center;
}
.event-contributor-card-container app-mi-card .content-container .profile-image-container {
  padding-bottom: 8px;
}
.event-contributor-card-container app-mi-card .content-container .profile-image-container app-user-profile-image {
  --user-image-size: 70px;
}
.event-contributor-card-container app-mi-card .content-container .firstname-lastname-container {
  width: 100%;
  overflow: hidden;
  padding-bottom: 5px;
}
.event-contributor-card-container app-mi-card .content-container .firstname-lastname-container .firstname-lastname {
  width: 100%;
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  text-align: center;
  color: var(--mi-black-color);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-contributor-card-container app-mi-card .content-container .country-container {
  width: 100%;
  overflow: hidden;
  padding-bottom: 5px;
}
.event-contributor-card-container app-mi-card .content-container .country-container .country {
  width: 100%;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  text-align: center;
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-contributor-card-container app-mi-card .content-container .role-container {
  padding-bottom: 20px;
}
.event-contributor-card-container app-mi-card .content-container .role-container app-chips {
  --padding-bottom: 0px;
  text-align: center;
}
.event-contributor-card-container app-mi-card .content-container .role-container app-chips ::ng-deep .chips-container {
  padding-bottom: 0;
}
.event-contributor-card-container app-mi-card .content-container .button-container {
  width: 100%;
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
