// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  color: var(--mi-grey-color-13);
}
button .share-button-container {
  display: grid;
}
button .share-button-container app-icon {
  grid-row: 1;
  grid-column: 1;
  --icon-size: 22px;
}
button .share-button-container .text {
  grid-row: 2;
  grid-column: 1;
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
