import { Inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import * as moment from 'moment';

// models
import { Platform } from 'src/config/config.model';
import { ImageMimeTypes } from '../attachments.model';
import { GroupListItem } from '../yeti-protocol/chatter-api';
import { AOVideo, Article, Video } from '../yeti-protocol/article';
import { ArticleSearch, VideoSearch } from '../yeti-protocol/search';
import { PublicProfile } from '../yeti-protocol/public-profile';
import { SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { ActionSource } from '../yeti-protocol/tracking';

// services
import { AppTranslationService } from '../app-translation.service';
import { LinkOpenerService } from '../link-opener.service';
import { GroupedImagesByStageOfTreatment, UIUtilsServiceInterface } from './ui-utils.service.interface';
import { ClinicalCase } from '../yeti-protocol/clinical-case';
import { EditCaseImagesComponent } from 'src/app/dialogs/edit-case-images/edit-case-images.component';
import {
  ShareRecipientsDialogComponent
} from 'src/app/dialogs/share-recipients-dialog/share-recipients-dialog.component';
import { AuthService } from '../auth/auth.service';
import { LocationService } from '../location.service';
import { NavControllerService } from '../nav-controller.service';
import { TutorialDialogComponent, TutorialDialogConfig } from 'src/app/dialogs/tutorial-dialog/tutorial-dialog.component';

import appConfig from 'src/config/config';
import { EditCaseImagesDialogRes, ModalControllerData } from './ui-utils.model';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from '../dialogs/dialogs.ui.service';
import { MAX_NUMBER_OF_SHARES, ObjectForSharing, ShareToObject } from '../sharing/sharing.model';
import { SelectedRecipients } from 'src/app/dialogs/share-recipients-dialog/share-recipients-dialog.model';
import { Observable, Subject } from 'rxjs';
import { ActionOnInit } from 'src/app/components/case-image-upload-edit/case-image-upload-edit.model';
import { Countries, Country } from 'src/app/services/yeti-protocol/countries';
import { AOEvent } from '../yeti-protocol/event';
import { StageOfTreatmentId } from 'src/app/components/stage-of-treatment/stage-of-treatment.model';
import { ReorderCaseMediaDialogComponent } from 'src/app/dialogs/reorder-case-media-dialog/reorder-case-media-dialog.component';
import { TRACKING_SERVICE, TrackingService } from '../tracking/tracking.model';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { ShareRecipientsPeopleComponent } from '../../dialogs/share-recipients-people/share-recipients-people.component';
import { SelectedContacts } from '../../dialogs/share-recipients-people/share-recipients-people.model';

interface UIUtilsServiceConfig {
  isAO: boolean;
  videoChannels: Array<string>;
  platform: Platform
}

@Injectable({
  providedIn: 'root'
})
export class UiUtilsService implements UIUtilsServiceInterface {
  config: UIUtilsServiceConfig = {
    isAO: !!appConfig.authenticationAO,
    videoChannels: appConfig.videoChannels,
    platform: appConfig.platform
  };

  private appClickEvent: Subject<MouseEvent> = new Subject();

  constructor(
    private appTranslationService: AppTranslationService,
    private modalController: ModalController,
    private linkOpenerService: LinkOpenerService,
    private authService: AuthService,
    private dialogs: DialogsUIService,
    private locationService: LocationService,
    private navController: NavControllerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private toast: ToastService,
  ) { }

  stopEventPropagation(event: Event): void {

    if (!event) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
  }

  async mapSecondsToTimeAgo(seconds: number): Promise<string> {

    const minuteInSeconds = 60;
    const hourInSeconds = minuteInSeconds * 60;
    const dayInSeconds = hourInSeconds * 24;
    const weekInSeconds = dayInSeconds * 7;
    const monthInSeconds = weekInSeconds * 4;
    const yearInSeconds = monthInSeconds * 12;

    if (isNaN(seconds)) {
      return '';
    }

    if (seconds > 99 * yearInSeconds) {
      return '99+ ' + await this.appTranslationService.get('app.uiUtils.years');
    }
    if (seconds >= yearInSeconds) { // between 1 year - 99 years
      return Math.round(seconds / yearInSeconds) + await this.appTranslationService.get('app.uiUtils.years');
    }
    if (seconds >= monthInSeconds) { // between 1 month - 12 months
      return Math.round(seconds / monthInSeconds) + await this.appTranslationService.get('app.uiUtils.months');
    }
    if (seconds >= weekInSeconds) { // between 1 week - 4 weeks
      return Math.round(seconds / weekInSeconds) + await this.appTranslationService.get('app.uiUtils.weeks');
    }
    if (seconds >= dayInSeconds) { // between 1 day - 7 days
      return Math.round(seconds / dayInSeconds) + await this.appTranslationService.get('app.uiUtils.days');
    }
    if (seconds >= hourInSeconds) { // between 1 hour - 24 hours
      return Math.round(seconds / hourInSeconds) + await this.appTranslationService.get('app.uiUtils.hours');
    }
    if (seconds > (5 * minuteInSeconds)) { // between 5 min - 1h
      return Math.round(seconds / minuteInSeconds) + await this.appTranslationService.get('app.uiUtils.minutes');
    }
    // between 0 - 4 min
    return this.appTranslationService.get('app.uiUtils.moment-ago');
  }

  async mapSecondsToTimeAgoMessenger(seconds: number, date: string): Promise<string> {

    const minuteInSeconds = 60;
    const hourInSeconds = minuteInSeconds * 60;
    const dayInSeconds = hourInSeconds * 24;
    const weekInSeconds = dayInSeconds * 7;
    const monthInSeconds = weekInSeconds * 4;
    const yearInSeconds = monthInSeconds * 12;

    if (isNaN(seconds)) {
      return '';
    }

    if (seconds >= 1 * yearInSeconds) {
      return moment(date).format('MMM Do YYYY');
    }
    if (seconds >= 1 * dayInSeconds) { // between 1 day - 1 year
      return moment(date).format('MMM Do');
    }
    if (seconds >= 2 * hourInSeconds) { // between 2 hours - 1 day
      return `${Math.round(seconds / hourInSeconds)} ${await this.appTranslationService.get('app.uiUtils.messenger-hours-ago')}`;
    }
    if (seconds >= 2 * minuteInSeconds) { // between 2 min - 1 hour
      return `${Math.round(seconds / minuteInSeconds)} ${await this.appTranslationService.get('app.uiUtils.messenger-mins-ago')}`;
    }
    if (seconds >= 60) { // 1 min ago
      return `1 ${await this.appTranslationService.get('app.uiUtils.messenger-min-ago')}`;
    }
    // just now
    return this.appTranslationService.get('app.uiUtils.messenger-just-now');
  }

  getTimeAgoFromDateInSeconds(date: string): number {
    return moment.duration(moment().diff(moment(date))).asSeconds();
  }

  get isAOApplication(): boolean {
    return this.config.isAO;
  }

  createAuxiliaryRouteDialog(modalController: ModalController, modalControllerData: ModalControllerData): Promise<HTMLIonModalElement> {

    if (!modalController) {
      return;
    }

    return modalController.create({
      component: modalControllerData.component,
      componentProps: modalControllerData.componentProps,
      cssClass: `${modalControllerData?.cssClass ? modalControllerData?.cssClass : ''} auxiliary-route-dialog`,
      backdropDismiss: false,
      showBackdrop: true
    });
  }

  isImageFile(selectedFile: SelectedFile): boolean {
    const mimeTypes: Array<string> = Object.values(ImageMimeTypes);
    return mimeTypes.includes(selectedFile?.file?.type);
  }

  signOut(): Promise<void> {
    return this.authService.signOut().then(signOutRes => {
      if (signOutRes?.redirectUrl) {
        this.locationService.href = signOutRes.redirectUrl;
      } else {
        return this.navController.navigateRoot('/auth').then(() => Promise.resolve());
      }
    });
  }

  isVideoArticle(article: Article | Video | AOVideo | ArticleSearch | VideoSearch): boolean {
    return article && this.config.videoChannels.includes(article?.channel);
  }

  onBecomeAOMember(aoMembershipUrl: string): void {
    if (this.authService && aoMembershipUrl) {
      this.authService.openAoLink(aoMembershipUrl);
    }
    // NYI
    // currentContext.aoMembershipUrl = 'https://aovet.aofoundation.org/membership' -- Veterinary
    // currentContext.aoMembershipUrl = 'https://aospine.aofoundation.org/membership' -- Spine
  }

  isConnectButtonForPublicProfileVisible(publicProfile: PublicProfile): boolean {
    return !publicProfile?.connectionInfo ||
      publicProfile?.connectionInfo?.status === 'none' ||
      publicProfile?.connectionInfo?.status === 'rejected' ||
      publicProfile?.connectionInfo?.status === 'disconnected';
  }

  getNavigationValueBasedOnPlatform(url: string): any {
    const currentPlatform = this.config.platform;

    if (currentPlatform === Platform.BROWSER || currentPlatform === Platform.PWA) {
      return {
        outlets: {
          dialog: url
        }
      }
    } else {
      return url;
    }
  }

  async showBecomeAnAOMemberDialogOnGroupInteraction(): Promise<boolean> {

    const registerActionKey = 'register';
    const closeActionKey = 'close';

    const becomeAnAOMemberConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.confirm.become-an-ao-member-title'
        },
        message: {
          translationKey: 'app.dialogs.confirm.become-an-ao-member-text'
        },
        actions: [
          {
            key: closeActionKey,
            label: {
              translationKey: 'app.dialogs.confirm.become-an-ao-member-button-close',
            },
            className: 'secondary'
          },
          {
            key: registerActionKey,
            label: {
              translationKey: 'app.dialogs.confirm.become-an-ao-member-button-confirm',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(becomeAnAOMemberConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return;
      }

      if (res?.data?.actionKey === registerActionKey) {
        return true;
      } else {
        return;
      }

    } catch (err) {
      console.error(err);
      return;
    }
  }

  async checkAndPromptCanAccessAoMembersOnlyGroup(
    groupAOMembersOnly: boolean,
    isUserAOMember: boolean,
    aoMembershipUrl: string): Promise<boolean> {

    if (groupAOMembersOnly && !isUserAOMember) {
      const shouldRegisterAsAOMEmber = await this.showBecomeAnAOMemberDialogOnGroupInteraction();

      if (shouldRegisterAsAOMEmber) {
        this.onBecomeAOMember(aoMembershipUrl);
      }

      return false;
    } else {
      return true;
    }
  }

  // return confirmation if user accepted to be AO member
  async showBecomeAoMemberDialog(): Promise<boolean> {
    const confirmModal = await this.createBecomeAoMemberDialog();
    const res = await confirmModal.onDidDismiss();
    return res && res?.data.actionKey === 'accept';
  }

  async createBecomeAoMemberDialog(): Promise<HTMLIonModalElement> {
    const title = this.appTranslationService.instant('app.PublicProfilePage.becomeAoDialogTitle');
    const description = this.appTranslationService.instant('app.PublicProfilePage.becomeAoDialogDescription');
    const approveButtonText = this.appTranslationService.instant('app.PublicProfilePage.approve');
    const closeButtonText = this.appTranslationService.instant('app.PublicProfilePage.close');

    const acceptActionKey = 'accept';
    const closeActionKey = 'close';

    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: title
        },
        message: {
          translationKey: description
        },
        actions: [
          {
            key: closeActionKey,
            label: {
              translationKey: closeButtonText
            },
            className: 'secondary'
          },
          {
            key: acceptActionKey,
            label: {
              translationKey: approveButtonText
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    confirmModal.present();
    return confirmModal;
  }

  async showErrorDialogOnCreatePostInAoOnlyGroup(): Promise<boolean> {

    const gotItActionKey = 'got-it';

    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.groups.error-creating-post-non-ao-member-title'
        },
        message: {
          translationKey: 'app.groups.error-creating-post-non-ao-member-text'
        },
        actions: [
          {
            key: gotItActionKey,
            label: {
              translationKey: 'app.groups.error-creating-post-non-ao-member-button-text',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    confirmModal.present();

    try {
      await confirmModal.onDidDismiss();
      return;

    } catch (err) {
      console.error(err);
      return;
    }
  }

  async showEditCaseImagesDialog(
    clinicalCase: ClinicalCase,
    triggerActionOnInit?: ActionOnInit,
    stageOfTreatment?: StageOfTreatmentId): Promise<EditCaseImagesDialogRes> {

    let updatedCase: ClinicalCase = null;

    const dialog = await this.modalController.create({
      component: EditCaseImagesComponent,
      cssClass: 'edit-case-images-dialog',
      backdropDismiss: false,
      componentProps: {
        clinicalCase: clinicalCase,
        triggerActionOnInit: triggerActionOnInit,
        stageOfTreatment: stageOfTreatment,
        propagateClinicalCaseUpdate: (updated: ClinicalCase) => {
          updatedCase = updated;
        }
      }
    });

    dialog.present();

    try {
      await dialog.onDidDismiss();
      return {
        updatedCase: updatedCase
      }
    } catch (err) {
      console.error(err);
    }
  }

  async showShareRecipientsDialog(
    selectedMemberGroups: Array<ShareToObject>,
    selectedEvents: Array<ShareToObject>,
    objectForSharing?: ObjectForSharing
  ): Promise<SelectedRecipients> {

    const dialog = await this.modalController.create({
      component: ShareRecipientsDialogComponent,
      cssClass: 'share-recipients-dialog',
      componentProps: {
        selectedMemberGroups: selectedMemberGroups,
        selectedEvents: selectedEvents,
        objectForSharing: objectForSharing
      }
    });

    dialog.present();

    try {
      const res = await dialog.onDidDismiss();

      if (res) {
        return res.data as SelectedRecipients;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async showShareRecipientsContactsDialog(initialSelectedContacts: Array<ShareToObject>): Promise<SelectedContacts> {
    const dialog = await this.modalController.create({
      component: ShareRecipientsPeopleComponent,
      cssClass: 'share-recipients-dialog',
      componentProps: { initialSelectedContacts }
    });

    dialog.present();

    try {
      const res = await dialog.onDidDismiss();

      if (res) {
        return res.data as SelectedContacts;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async showTutorialDialog(tutorialDialogConfig: TutorialDialogConfig): Promise<boolean> {

    const dialog = await this.modalController.create({
      component: TutorialDialogComponent,
      cssClass: 'tutorial-dialog',
      componentProps: {
        ...tutorialDialogConfig
      },
      backdropDismiss: false
    });

    dialog.present();

    try {
      const res = await dialog.onDidDismiss();

      if (res) {
        return res.data;
      }
    } catch (err) {
      console.error(err);
    }
  }

  disableGroupsSelection(objectForSharing: ObjectForSharing, group: GroupListItem): boolean {
    const maxSharesLimitReached = objectForSharing?.meta?.numberOfGroupsSharedTo >= MAX_NUMBER_OF_SHARES;
    if (maxSharesLimitReached) {
      return maxSharesLimitReached;
    } else if (objectForSharing?.meta?.numberOfGroupsSharedTo > 0) {
      return objectForSharing?.meta?.groupsSharedTo.includes(group?._id);
    }
    return false;
  }

  disableEventsSelection(objectForSharing: ObjectForSharing, event: AOEvent): boolean {
    return objectForSharing?.meta?.eventsSharedTo?.includes(event?._id);
  }

  get appClickEventObservable(): Observable<MouseEvent> {
    return this.appClickEvent.asObservable();
  }

  emitAppClickEvent(event: MouseEvent): void {
    this.appClickEvent.next(event);
  }

  getEventLocation(event: AOEvent, countries: Countries): string {
    if (event) {
      if (event?.eventVenue && event?.country && this.getCountryByISO2(event?.country, countries)) {
        return `${event?.eventVenue}, ${this.getCountryByISO2(event?.country, countries)?.name}`;
      } else if ((event?.country && this.getCountryByISO2(event?.country, countries)) || event?.eventVenue) {
        return `${this.getCountryByISO2(event?.country || '', countries)?.name || event?.eventVenue}`;
      } else {
        return `${event?.eventVenue}`
      }
    }

    return '';
  }

  getCountryByISO2(iso2: string, countries: Countries): Country {
    if (countries && countries?.length) {
      return countries?.find(countryItem => {
        return countryItem.iso2 === iso2;
      });
    }
  }

  async showUnpinPostWarning(): Promise<boolean> {

    const removeActionKey = 'remove';
    const cancelActionKey = 'cancel';

    const unpinPostConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.components.PostsList.remove-pinned-post-warning.title'
        },
        message: {
          translationKey: 'app.components.PostsList.remove-pinned-post-warning.text'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.components.PostsList.remove-pinned-post-warning.btn-cancel',
            },
            className: 'secondary'
          },
          {
            key: removeActionKey,
            label: {
              translationKey: 'app.components.PostsList.remove-pinned-post-warning.btn-confirm',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(unpinPostConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === removeActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async showCaseMediaReorderDialog(groupedImages: Array<GroupedImagesByStageOfTreatment>, scope: string, watermarked?: boolean):
    Promise<Array<GroupedImagesByStageOfTreatment>> {

    this.trackingService.trackGenericClickedAction(
      'reorderStarted',
      'case',
      'imageReorder',
      { source: scope }
    ).catch(err => {
      console.error(err);
    });

    const dialog = await this.modalController.create({
      component: ReorderCaseMediaDialogComponent,
      cssClass: 'reorder-case-media-dialog',
      componentProps: {
        groupedImages: groupedImages,
        watermarked: watermarked
      },
      backdropDismiss: false
    });

    dialog.present();

    try {
      const res = await dialog.onDidDismiss();

      if (res.data) {

        this.trackingService.trackGenericClickedAction(
          'reorderCompleted',
          'case',
          'imageReorder',
          { source: scope }
        ).catch(err => {
          console.error(err);
        });

        return res.data;
      } else {
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  showGenericToastError(duration: number = 2 * 1000): void {
    const title = this.appTranslationService.instant('app.common.toast-error.title');
    const message = this.appTranslationService.instant('app.common.toast-error.message');

    this.toast.showWithMessage(message, title, ToastMode.ERROR, false, duration);
  }

  async showFeatureCasePrompt(): Promise<boolean> {

    const featureActionKey = 'feature';
    const notNowActionKey = 'not-now';

    const featureCaseDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.uiUtils.feature-case.title'
        },
        message: {
          translationKey: 'app.uiUtils.feature-case.text'
        },
        actions: [
          {
            key: featureActionKey,
            label: {
              translationKey: 'app.uiUtils.feature-case.feature-btn'
            },
            className: 'primary'
          },
          {
            key: notNowActionKey,
            label: {
              translationKey: 'app.uiUtils.feature-case.not-now-btn'
            },
            className: 'secondary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(featureCaseDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === featureActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async showUnfeatureCasePrompt(): Promise<boolean> {

    const confirmActionKey = 'confirm';
    const cancelActionKey = 'cancel';

    const unFeatureCaseDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.uiUtils.unfeature-case.title'
        },
        message: {
          translationKey: 'app.uiUtils.unfeature-case.text'
        },
        actions: [
          {
            key: confirmActionKey,
            label: {
              translationKey: 'app.uiUtils.unfeature-case.confirm-btn'
            },
            className: 'primary'
          },
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.uiUtils.unfeature-case.cancel-btn'
            },
            className: 'secondary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(unFeatureCaseDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === confirmActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  showCaseFeatureSuccessToast(): void {

    const message = this.appTranslationService.instant('app.uiUtils.case-feature-toast-message');
    this.toast.showWithMessage(message, null, ToastMode.SUCCESS);
  }

  showCaseUnfeatureSuccessToast(): void {

    const message = this.appTranslationService.instant('app.uiUtils.case-unfeature-toast-message');
    this.toast.showWithMessage(message, null, ToastMode.SUCCESS);
  }

  showPendingStateExplainerDialog(postId?: string, postType?: string, source?: ActionSource): Promise<boolean> {
    const ACTION_KEY = 'got-it';

    const pendingStateConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.dialogs.confirm.pending-state-explainer-dialog-title'
        },
        message: {
          translationKey: 'app.dialogs.confirm.pending-state-explainer-dialog-message'
        },
        actions: [
          {
            key: ACTION_KEY,
            label: {
              translationKey: 'app.common.got-it',
            },
            className: 'primary'
          }
        ]
      }
    }

    return this.dialogs.createConfirmDialog(pendingStateConfirmDialogData)
      .then(dlg => {
        dlg.present();
        this.trackingService.trackGenericClickedAction(
          postId,
          'pendingClick',
          `pendingClick_${postType}`,
          { source: source });
        return dlg.onDidDismiss();
      })
      .then(res => {
        if (res && res?.data.actionKey === ACTION_KEY) {
          this.trackingService.trackGenericClickedAction(
            'explainerModal',
            'pendingClick',
            'pendingCloseModal',
            { source: source });
          return true;
        }
      });
  }

  isTargetHavingAncestorWithOpenVideoPreviewClass(event: Event): boolean {
    let element = event.target as Element | null;
    // Traverse up the DOM tree until reaching element with the 'open-video-preview' class
    while (element && !element.classList.contains('open-video-preview')) {
      element = element.parentElement;
    }
    return !!element
  }

  isMobileBrowser(): boolean {

    if (appConfig.platform === Platform.PWA || appConfig.platform === Platform.BROWSER) {
      const deviceAgent = navigator.userAgent.toLowerCase();

      const isMobileBrowser = deviceAgent.match(/(iphone|ipod|ipad)/) ||
        deviceAgent.match(/(android)/) ||
        deviceAgent.match(/iphone/i) ||
        deviceAgent.match(/ipad/i) ||
        deviceAgent.match(/ipod/i) ||
        deviceAgent.match(/blackberry/i) ||
        deviceAgent.match(/bada/i)
        ? true : false;

      return isMobileBrowser;
    }

    return false;
  }

}
