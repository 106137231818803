// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --connection-requests-list-padding-top: 20px;
  --connection-requests-list-padding-bottom: 15px;
  --connection-requests-list-padding-start: 15px;
  --connection-requests-list-padding-end: 15px;
  --connection-requests-list-item-padding-top: 15px;
  --connection-requests-list-item-padding-bottom: 15px;
  --connection-requests-list-item-padding-start: 15px;
  --connection-requests-list-item-padding-end: 15px;
  --connection-requests-list-background-color: var(--mi-white-color);
}

.connection-requests-list-container {
  width: 100%;
  height: 100%;
}
.connection-requests-list-container .list {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.connection-requests-list-container .list app-connection-request-list-item {
  --connection-request-list-item-padding-top: var(--connection-requests-list-item-padding-top);
  --connection-request-list-item-padding-bottom: var(--connection-requests-list-item-padding-bottom);
  --connection-request-list-item-padding-start: var(--connection-requests-list-item-padding-start);
  --connection-request-list-item-padding-end: var(--connection-requests-list-item-padding-end);
  border-bottom: 1.5px solid var(--mi-background-color);
}
.connection-requests-list-container .list app-connection-request-list-item:last-child {
  border: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
