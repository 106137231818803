// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  margin-top: 20px;
  text-align: center;
  font-size: var(--font-size-small);
  padding: 10px;
}
.footer .google-play-button {
  width: 120px;
  padding: 3px;
}
.footer .app-icon {
  display: block;
  height: auto;
  width: 70px;
  margin: auto;
  margin-top: 10px;
}
.footer .download-row {
  margin-bottom: 10px;
}
.footer hr {
  width: 180px;
  background: var(--mi-black-color);
}
.footer a {
  color: var(--mi-primary-color);
  text-decoration: none;
  cursor: pointer;
}
.footer .give-feedback-button {
  --background: var(--mi-primary-color);
  --border-radius: 4px;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  margin: 15px;
  display: flex;
  text-transform: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
