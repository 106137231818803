export enum ItemType {
  article = 'article',
  video = 'video',
  aoVideo = 'ao-video',
  collection = 'collection', // to be renamed Recommendation List
  unknown = 'unknown',
  group = 'group',
  surgeryReference = 'surgeryReference',
  orcid = 'orcid'
}

export enum StreamItemType {
  article = 'article',
  video = 'video',
  post = 'post',
  case = 'case',
  events = 'events',
  recommendedGroups = 'recommendedGroups',
  recommendedJournals = 'recommendedJournals',
  journalHighlights = 'journalHighlights',
  expertRecommendation = 'expertRecommendation',
  recommendedExpertsToFollow = 'recommendedExpertsToFollow',
  marketingTeaserCard = 'marketingTeaserCard',
  featuredCase = 'featuredCase',
  orcid = 'orcid'
}

export enum StreamStateType {
  network = 'network',
  networkTop = 'networkTop',
  knowledge = 'knowledge'
}

export enum TopRecommendationType {
  article = 'article',
  video = 'video',
  group = 'group',
  event = 'event',
  aoVideo = 'ao-video'
}

export enum ArticleItemType {
  article = 'article',
  video = 'video',
  aoVideo = 'ao-video'
}

export enum ArticleStatus {
  hidden = 'hidden',
  notPublished = 'notPublished',
  published = 'published'
}
