import { ContextConfigInterface } from '../config.model';

export class ContextConfig implements ContextConfigInterface {
  key = 'recon';
  label = 'Arthroplasty and Reconstruction';
  homePath = '/recon';
  logoImageName = 'ao-recon.svg';
  iconName = 'spec-recon';
  aoMembershipUrl = 'https://www.aofoundation.org/trauma/membership'; // TODO: when recon uses membership, update url
  moreEventsLink = 'https://www.aofoundation.org/recon/education/courses-events';
}
