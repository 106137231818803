import { AfterViewInit, Component, ContentChild, Input } from '@angular/core';
import { IonInput } from '@ionic/angular';

export enum InputUsage {
  name = 'name',
  email = 'email',
  password = 'password',
  location = 'location',
  profession = 'profession',
  speciality = 'speciality',
  groupName = 'group-name',
  custom = 'custom'
}

@Component({
  selector: 'app-generic-input',
  templateUrl: './generic-input.component.html',
  styleUrls: ['./generic-input.component.scss'],
})
export class GenericInputComponent implements AfterViewInit {
  @Input() inputUsage: string;
  @Input() valid: boolean;
  @Input() errorMessage = '';
  @Input() successMessage = '';
  @Input() customIcon = '';
  @ContentChild(IonInput) input: IonInput;
  InputUsage = InputUsage;
  showPassword = false;
  clearButton = false;

  ngAfterViewInit(): void {
    if(this.input) {
      this.clearButton = this.input.value ? true : false;
    }
  }

  onToggleShowPassword(): void {
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? 'text' : 'password';
  }

  onClearInput(): void {
    this.input.value = '';
    this.clearButton = false;
  }

  onInputChanged(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.clearButton = inputValue ? true : false;
  }
}
