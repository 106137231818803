import { CoContributorsSuccessResponse } from 'src/app/services/yeti-protocol/connections';
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';

export namespace CoContributors {
  export interface FetchCoContributorsPayload {
    pageIndex?: number;
    pageSize?: number;
  }

  export class FetchCoContributors {
    static readonly type = '[Co-contributors] Fetch co-contributors';
    constructor(public payloadParams: FetchCoContributorsPayload) { }
  }

  export class FetchCoContributorsSuccess {
    static readonly type = '[Co-contributors] Fetch co-contributors success';
    constructor(
      public payloadParams: FetchCoContributorsPayload,
      public response: CoContributorsSuccessResponse
    ) { }
  }

  export class FetchCoContributorsFailed {
    static readonly type = '[Co-contributors] Fetch co-contributors failed';
  }

  export class InsertCoContributorBeforeIndex {
    static readonly type = '[Co-contributors] Insert co-contributor before index';
    constructor(
      public coContributor: PublicProfile,
      public index: number
    ) { }
  }

  export class RemoveCoContributor {
    static readonly type = '[Co-contributors] Remove co-contributor at index';
    constructor(
      public index: number
    ) { }
  }

  export class UpdateCoContributor {
    static readonly type = '[Co-contributors] Update co-contributor';
    constructor(
      public coContributor: PublicProfile
    ) { }
  }
}
