import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-full-name',
  templateUrl: './user-full-name.component.html',
  styleUrls: ['./user-full-name.component.scss'],
})
export class UserFullNameComponent {

  @Input() firstName: string;
  @Input() lastName: string;
  @Input() country: string;
  @Input() organisation: string = null;
  @Input() department: string = null;
  @Input() isVerified: boolean;
  @Input() showCrown = false;
  @Input() showVerifiedBadge: boolean;
  @Input() showModeratorIcon = false;
  @Input() additionalClasses: string[] = [];
  @Input() showOrcIdIcon = false;
  @Input() additionalTextNextToName?: string;

  constructor(
    public changeDetectorRef: ChangeDetectorRef // do not remove, used from rich text document
  ) { }

  get organisationLine(): string {
    const parts = [];
    if (this.organisation && this.organisation.length > 0) {
      parts.push(this.organisation);
    }
    if (this.department && this.department.length > 0) {
      parts.push(this.department);
    }
    if (parts.length > 0) {
      return parts.join(' / ');
    }
    return null;
  }
}
