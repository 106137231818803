import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { AttachmentMimeType } from 'src/app/services/attachments.model';
import { LinkOpenerService } from 'src/app/services/link-opener.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { Message } from 'src/app/services/yeti-protocol/message';
import { MessageCardBase } from '../message-card-base';
import { MessagePressedEvent } from '../messenger-list.model';

@Component({
  selector: 'app-message-card-attachment',
  templateUrl: './message-card-attachment.component.html',
  styleUrls: ['./message-card-attachment.component.scss'],
})
export class MessageCardAttachmentComponent extends MessageCardBase {
  @Input() message: Message;
  @Input() userId: string;

  @Output() showCommands: EventEmitter<MessagePressedEvent> = new EventEmitter<MessagePressedEvent>()

  constructor(
    responsiveUtilsService: ResponsiveUtilsService,
    private linkOpener: LinkOpenerService)
  {
    super(responsiveUtilsService);
  }

  @HostBinding('class') get viewMode(): string {
    if(this.isOwnMessage){
      return 'chat-initiator';
    }
    return '';
  }

  openAttachment(link: string): void {
    this.linkOpener.openDocumentLink(link);
  }

  get hasAttachments(): boolean {
    return this.message?.attachments?.length > 0
      && this.message.attachments[this.message.attachments.length - 1]?.mimeType === AttachmentMimeType.PDF;
  }

}
