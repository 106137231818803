// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --side-padding: 20px;
}

.groups-discover-container {
  width: 100%;
  height: 100%;
}
.groups-discover-container app-vertical-list {
  width: 100%;
  height: 100%;
}
.groups-discover-container app-vertical-list .list-container {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 20px;
}
.groups-discover-container app-vertical-list .list-container app-groups-row-list-item {
  --side-padding: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
