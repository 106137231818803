import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

// services
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {

  @Input() iconName: string;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  buttonClicked(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.buttonClick.emit();
  }

}
