// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clinical-expert-container .clinical-expert-img {
  --border-radius: 50%;
  width: 40px;
  height: 40px;
}
.clinical-expert-container .title-dots-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clinical-expert-container .title-dots-wrap .expert-data {
  width: 85%;
  padding-right: 5px;
  margin: 10px 0px;
}
.clinical-expert-container .title-dots-wrap .expert-data .clinical-expert-title {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
.clinical-expert-container .title-dots-wrap .expert-data .clinical-expert-country {
  color: var(--mi-color-text-lite);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
.clinical-expert-container .title-dots-wrap .icon-options {
  --icon-size: 25px;
  color: var(--mi-color-text-lite);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
