export enum VerificationDocumentType {
  MEDICAL_LICENCE = 'medical-licence',
  MEDICAL_DEGREE = 'medical-degree'
}

export enum VerificationStatus {
  VERIFIED = 'verified',
  PENDING = 'pending',
  NOT_VERIFIED = 'not-verified',
  REJECTED = 'rejected',
  NONE = 'none'
}
