import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-settings-gear-button',
  templateUrl: './settings-gear-button.component.html',
  styleUrls: ['./settings-gear-button.component.scss'],
})
export class SettingsGearButtonComponent extends BaseButtonComponent<void> {
  @Input() showTitle = false;
}
