import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

// models
import { PostOwner } from '../../services/yeti-protocol/chatter-api';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-post-card-header',
  templateUrl: './post-card-header.component.html',
  styleUrls: ['./post-card-header.component.scss'],
})
export class PostCardHeaderComponent implements OnInit, OnDestroy {

  @Input() postOwner: PostOwner;
  @Input() postDate: string;
  @Input() parentName: string;
  @Input() showCrown = false;
  @Input() showPostedVerb = true;
  @Input() isTopContributor = false;
  @Input() additionalTextNextToName?: string;
  @Input() topContributorText = 'app.common.top-group-contributor';
  @Input() source: ActionSource;

  @Output() parentClick: EventEmitter<Event> = new EventEmitter();
  @Output() userFollowed: EventEmitter<PostOwner> = new EventEmitter();

  private userProfile: UserProfile;
  private userProfileSubscription: Subscription;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.userProfile = userProfile;
    })
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  onParentClick(event: Event): void {
    this.parentClick.emit(event);
  }

  get isPostOwnerLoggedInUser(): boolean {
    return this.userProfile?.userId === this.postOwner?.userId;
  }

  get showFollowUserButton(): boolean {
    return this.postOwner?.userId && !this.isPostOwnerLoggedInUser && !this.postOwner?.isFollower ? true : false;
  }

  onUserFollowed(postOwner: PostOwner): void {
    this.userFollowed.emit(postOwner);
  }
}
