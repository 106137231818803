import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-with-badge',
  templateUrl: './image-with-badge.component.html',
  styleUrls: ['./image-with-badge.component.scss'],
})
export class ImageWithBadgeComponent {
  @Input() badgeText: string;

  get styleClassBasedOnBadgeType(): string {
    switch (this.badgeText) {
      case 'Event':
      case 'Webinar':
        return 'event-style';
      case 'Video':
        return 'video-style';
      default:
        return 'article-style';
    }
  }
 }
