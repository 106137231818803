import { Injectable } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

@Injectable()
export class ImageSharingDeviceService {
  constructor(
    private socialSharing: SocialSharing
  ){}

  share(base64Image: string): Promise<boolean> {
    return this.socialSharing.share(null, null, base64Image)
    .then(() => {
      return true;
    }).catch(err => {
      console.log(err);
      return false;
    });
  }
}
