// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-card {
  margin: 0 0 8px 0;
  border-radius: 0;
}
.info-card .info-card-header {
  display: flex;
  justify-content: space-between;
}
.info-card .info-card-header .main-text {
  font-weight: var(--font-weight-medium);
  font-size: var(--mi-font-size-small);
  color: var(--mi-secondary-color-1);
  margin-left: 10px;
}
.info-card .info-card-header .label-see-all {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
}
.info-card .moderators-card-content {
  padding-top: unset;
  padding-bottom: unset;
}
.info-card .group-additional-info {
  display: flex;
  margin: 5px 0;
  justify-content: space-between;
}
.info-card .group-additional-info .icons-container {
  display: flex;
  align-items: center;
}
.info-card .group-additional-info .icons-container .reject-request {
  color: var(--mi-grey-color);
  margin: 5px;
  height: 30px;
  width: 30px;
}
.info-card .group-additional-info .icons-container .accept-request {
  color: var(--mi-primary-color);
  margin: 5px;
  height: 30px;
  width: 30px;
}
.info-card .group-additional-info .action-sheet-trigger-button {
  justify-self: end;
}
.info-card .group-additional-info .additional-info-item {
  font-size: var(--mi-font-size-smaller);
  background-color: var(--ion-color-secondary-light-blue);
  color: var(--ion-color-secondary-bright-blue);
  border-radius: 12px;
  padding: 2px 7px;
  margin: 5px 5px 5px 0;
}
.info-card .group-additional-info .additional-info-item app-user-profile-image {
  --user-image-size: 40px;
}
.info-card .group-additional-info .additional-info-item .fullname {
  margin-left: 15px;
}
.info-card .group-additional-info .additional-info-item .moderator-icon {
  position: absolute;
  left: 190px;
  top: 10px;
  color: var(--ion-color-secondary);
}
.info-card .group-additional-info .display-flex {
  display: flex;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
