import { Component, EventEmitter, OnInit, Input, Output, Inject } from '@angular/core';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { OrcIdArticle } from 'src/app/services/yeti-protocol/article';
import { ResponseStreamItem } from 'src/app/services/yeti-protocol/my-stream';
import { ActionSource, ActionTracked } from 'src/app/services/yeti-protocol/tracking';
import { StreamItemType, StreamStateType, } from 'src/app/services/yeti-protocol/utils/enums';

@Component({
  selector: 'app-network-stream-items',
  templateUrl: './network-stream-items.component.html',
  styleUrls: ['./network-stream-items.component.css']
})
export class NetworkStreamItemsComponent implements OnInit {

  @Input() items: Array<ResponseStreamItem>;
  @Input() loading: boolean;
  @Input() visibilityRootKey: boolean;
  @Input() awsPersonalisationId: string;

  @Output() postDeleted: EventEmitter<string> = new EventEmitter();

  StreamItemType = StreamItemType;

  constructor(
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  ngOnInit(): void { }

  getOrcIdArticleId(orcIdArticle: OrcIdArticle): string {
    return orcIdArticle?._id || orcIdArticle?.id || orcIdArticle?.origin || '';
  }

  trackMarketingTeaserCardClick(): void {
    this.trackingService
      .trackGenericClickedAction(
        ActionTracked.marketingTeaserCard,
        StreamStateType.network,
        ActionTracked.marketingTeaserCard,
        { source: this.source }
      )
      .catch((_err) => {
        console.error('Could not track marketing teaser card clicked action.');
      });
  }

  get source(): ActionSource {
    return ActionSource.socialStream;
  }

}
