import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// components
import { ImageEditorDialogComponent } from 'src/app/modules/image-editor/edit-image-dialog/image-editor-dialog.component';

// models
import { AttachmentMimeType, ImageAttachment, isAnyImage, isVideo } from 'src/app/services/attachments.model';
import {
  PersonalMediaGalleryDocument,
  UpdatePersonalMediaGalleryDocumentSuccessResponse
} from 'src/app/services/yeti-protocol/personal-media-gallery';

// services
import { ImageGallery, ReviewGalleryModeData } from '../../services/image-gallery.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { PersonalMediaGalleryService } from 'src/app/services/personal-media-gallery/personal-media-gallery.service';
import { UploadService } from 'src/app/services/upload.service';
import { UploadFile } from 'src/app/services/yeti-protocol/upload';

import { openVideoDialog } from 'src/app/modules/video-hub/video-hub.utils';

// import Swiper core and required modules
import SwiperCore, { Navigation, Thumbs, Zoom, Swiper } from 'swiper';
import { FileSelectScope } from '../../services/file-select.service';
// install Swiper modules
SwiperCore.use([Navigation, Thumbs, Zoom]);
import { ImageGalleryStrategy } from 'src/app/services/image-gallery/image-gallery';
import { ImageGalleryDefaultStrategy } from 'src/app/services/image-gallery/image-gallery-default-service.service';

function mediaDocumentToImageAttachment(doc: PersonalMediaGalleryDocument): ImageAttachment {
  return {
    _id: doc._id,
    fileName: doc.fileName,
    fullUrl: doc.fullUrl,
    previewUrl: doc.previewUrl,
    mimeType: doc.mimeType,
    contentLength: doc.contentLength,
    watermarkedPreviewUrl: doc.watermarkedPreviewUrl,
    fullWatermarkUrl: doc.fullWatermarkUrl
  };
}

@Component({
  selector: 'app-image-gallery-dialog',
  templateUrl: './image-gallery-dialog.component.html',
  styleUrls: ['./image-gallery-dialog.component.scss'],
})
export class ImageGalleryDialogComponent implements AfterViewInit, OnInit, OnDestroy {

  @Input() images: Array<ImageAttachment> = [];
  @Input() allowImageRemove: boolean;
  @Input() galleryId: string;
  @Input() reviewMode: boolean;
  @Input() allowImageEdit: boolean;
  @Input() imageGalleryService: ImageGallery;
  @Input() scope: FileSelectScope;
  @Input() imageGalleryStrategy: ImageGalleryStrategy;

  title: string;
  thumbsSwiper: any;
  isEditButtonVisible = false;
  protected currentImageIndex = 0;

  private imagesChangedSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private appTranslationService: AppTranslationService,
    private personalMediaGalleryService: PersonalMediaGalleryService,
    private uploadService: UploadService,
    private changeDetector: ChangeDetectorRef,
    private imageGalleryDefaultStrategy: ImageGalleryDefaultStrategy
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setTitleBasedOnImage();
      this._updateEditButtonVisibility();
    }, 0);
  }

  ngOnInit(): void {
    this.imagesChangedSubscription = this.imageGalleryService.imagesStream.subscribe((images: Array<ImageAttachment>) => {
      this.images = images;
      this.closeIfThereIsNoImages();
    });
  }

  ngOnDestroy(): void {
    this.imagesChangedSubscription?.unsubscribe();
  }

  get isReviewMode(): boolean {
    return this.reviewMode;
  }

  async setTitleBasedOnImage(): Promise<void> {

    if (this.currentImage) {
      const translationKey = this.currentImage?._id ? 'app.ImageGallery.uploaded-images-title' : 'app.ImageGallery.attached-images-title';
      this.title = await this.appTranslationService.get(translationKey);
    }
  }

  currentImageIndexChanged(swiper: Array<Swiper>): void {
    if (swiper.length > 0) {
      this.currentImageIndex = swiper[0].activeIndex;
      this.setTitleBasedOnImage();
      this._updateEditButtonVisibility();
    }
  }

  _updateEditButtonVisibility(): void {
    const image = this.currentImage;
    const mimeType = (image && image.mimeType) || (image && image.file && image.file.type);
    this.isEditButtonVisible = this.allowImageEdit && image && isAnyImage(mimeType);
    this.changeDetector.detectChanges();
  }

  closeGallery(): void {

    let data: ReviewGalleryModeData;

    if (this.isReviewMode) {
      data = {
        images: this.images
      }
    }

    this.modalController?.dismiss(data)
      .catch(err => {
        console.error(err);
      });
  }

  async removeImage(): Promise<void> {

    let shouldProceedWithDelete = false;

    if (this.imageGalleryStrategy) {
      try {
        shouldProceedWithDelete = await this.imageGalleryStrategy.shouldProceedWithDelete();
      } catch (err) { }
    } else {
      try {
        shouldProceedWithDelete = await this.imageGalleryDefaultStrategy.shouldProceedWithDelete();
      } catch (err) { }
    }

    if (shouldProceedWithDelete) {
      if (this.isReviewMode) {
        if (this.currentImage) {
          this.images?.splice(this.currentImageIndex, 1);
          this.closeIfThereIsNoImages();
        }
      } else { // edit mode
        const imageId = this.currentImage?._id;
        this.imageGalleryService.removeImage(this.currentImageIndex, imageId, this.galleryId);
      }
    }
  }

  updateImage(image: ImageAttachment): void {
    if (!this.currentImage?._id) {
      return;
    }
    const imageId = this.currentImage?._id;
    const uploadFile: UploadFile = {
      key: 'image',
      file: image.file,
      fileName: image.fileName,
      mimeType: image.mimeType
    }

    this.uploadService.updatePersonalMediaGalleryDocument(uploadFile, imageId, this.scope)
      .then((res: UpdatePersonalMediaGalleryDocumentSuccessResponse) => {
        const updatedImage = mediaDocumentToImageAttachment(res.result);
        this.images[this.currentImageIndex] = updatedImage;
        this.imageGalleryService.triggerImageChanged(updatedImage);
      }).catch(err => {
        console.error(err);
      });
  }

  get currentImage(): ImageAttachment {

    if (!this.images?.length) {
      return null;
    }

    if (this.images?.length > this.currentImageIndex) {
      return this.images[this.currentImageIndex];
    }
    return null;
  }

  closeIfThereIsNoImages(): void {
    if (!this.images || this.images.length < 1) {
      setTimeout(() => {
        this.closeGallery();
      }, 0);
    }
  }

  editImage(): void {

    const image = this.currentImage;
    if (!image) {
      return;
    }

    let promise: Promise<File>;
    if (image.file) {
      promise = Promise.resolve(image.file);
    } else {
      if (image.fullUrl) {
        promise = this.personalMediaGalleryService.downloadDocumentByUrl(image.fullUrl);
      }
    }
    if (!promise) {
      return;
    }

    promise.then(imageFile => {
      if (imageFile?.type && imageFile?.type.indexOf(AttachmentMimeType.GIF) > -1) {
        return;
      }
      return this.modalController.create({
        component: ImageEditorDialogComponent,
        componentProps: {
          imageFile: imageFile
        }
      })
    }).then(dlg => {
      if (!dlg) {
        return;
      }
      dlg.onDidDismiss().then(res => {
        if (!res?.data) {
          return;
        }
        image.file = res.data.file;
        image.fullUrl = res.data.base64;
        image.previewUrl = res.data.base64;
        this.updateImage(image);
      });
      dlg.present();
    }).catch(err => {
      console.log(err);
    });
  }

  isVideo(image: ImageAttachment): boolean {
    return isVideo(image.mimeType);
  }

  onPlayVideo(image: ImageAttachment): void {
    openVideoDialog(image.videoUrl, image.mimeType, image.previewUrl, this.modalController);
  }
}
