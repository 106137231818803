import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import {
  SurgeryReferenceDialogPage,
  SurgeryReferenceUIServiceInterface,
  SURGERY_REFERENCE_UI_SERVICE
} from 'src/app/services/surgery-reference/surgery-reference-ui.service.interface';
import { SurgeryReferenceService } from 'src/app/services/surgery-reference/surgery-reference.service';
import { SURGERY_REFERENCE_SERVICE } from 'src/app/services/surgery-reference/surgery-reference.service.interface';
import {
  Approach,
  ContentType,
  Fracture,
  FractureAndTreatment,
  Preparation,
  Treatment
} from 'src/app/services/yeti-protocol/surgery-reference-schema';
import {
  AddApproachResponse,
  AddPreparationResponse,
  ApproachesResponse,
  ApproachesSuccessResponse,
  PreparationsResponse,
  PreparationsSuccessResponse
} from 'src/app/services/yeti-protocol/surgery-reference';
import { BaseSurgeryReferencePage } from '../surgery-reference-common/base-surgery-reference-page';
import { SurgeryReferenceDialogData } from '../surgery-reference-dialog/surgery-reference-dialog.component';
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';
import { ResponsiveUtilsService } from '../../../services/utils/responsive-utils.service';
import { HorizontalListLoadMoreData } from 'src/app/components/horizontal-list/horizontal-list.component';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { ClinicalCase } from 'src/app/services/yeti-protocol/clinical-case';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.page.html',
  styleUrls: ['./review.page.scss'],
})
export class ReviewPage extends BaseSurgeryReferencePage implements OnInit {

  surgeryReferenceDialogData: SurgeryReferenceDialogData;
  approaches: Array<Approach> = [];
  preparations: Array<Preparation> = [];
  swiperLogicApproaches: SwiperLogic;
  swiperLogicPreparations: SwiperLogic;
  approachesLoading: boolean;
  totalApproachesCount: number;
  preparationsLoading: boolean;
  totalPreparationsCount: number;
  count = 10;

  private addingApproach: boolean;
  private addingPreparation: boolean;
  private removingTreatment: boolean;
  private removingModule: boolean;

  constructor(
    nav: IonNav,
    @Inject(SURGERY_REFERENCE_UI_SERVICE) surgeryReferenceUIService: SurgeryReferenceUIServiceInterface,
    @Inject(SURGERY_REFERENCE_SERVICE) private surgeryReferenceService: SurgeryReferenceService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private clinicalCaseService: ClinicalCaseService,
    private toast: ToastService,
    private authService: AuthService
  ) {
    super(nav, surgeryReferenceUIService);
    this.surgeryReferenceDialogData = this.nav.rootParams as SurgeryReferenceDialogData;
    this.swiperLogicApproaches = new SwiperLogic(271, this.responsiveUtilsService, this.el, 1.6);
    this.swiperLogicPreparations = new SwiperLogic(271, this.responsiveUtilsService, this.el, 1.6);
  }

  ngOnInit(): void {
    this.getApproaches(0);
    this.getPreparations(0);
  }

  get selectableFracture(): FractureAndTreatment {
    if (this.surgeryReferenceDialogData && this.surgeryReferenceDialogData.selectedFractures.length) {
      const lastItemIndex = this.surgeryReferenceDialogData.selectedFractures.length - 1;
      const selectableFracture = this.surgeryReferenceDialogData.selectedFractures[lastItemIndex];
      return selectableFracture;
    }

    return null;
  }

  async getApproaches(start: number = 0, refresh: boolean = false): Promise<void> {

    if (!this.surgeryReferenceDialogData?.clinicalCase?._id) {
      return Promise.resolve();
    }

    if (!this.approaches) {
      this.approaches = [];
    }

    if (refresh) {
      this.approaches = [];
    }

    this.approachesLoading = true;
    this.changeDetectorRef.detectChanges();

    try {
      const approachesResponse = await this._getApproaches(start) as ApproachesSuccessResponse;
      this.approaches = [...this.approaches, ...approachesResponse?.result || []];
      this.totalApproachesCount = approachesResponse.totalItemsCount;
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.approachesLoading = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  _getApproaches(start: number = 0): Promise<ApproachesResponse> {
    return this.surgeryReferenceService.getApproaches(this.surgeryReferenceDialogData?.clinicalCase?._id, start, this.count);
  }

  refreshApproaches(): void {
    this.getApproaches(0, true);
  }

  async loadMoreApproaches(data: HorizontalListLoadMoreData): Promise<void> {
    await this.getApproaches(data.page);
    setTimeout(() => {
      data.swiper.slideTo(data.slideToSlideIndex, 0);
    }, 100);
  }

  async getPreparations(start: number = 0, refresh: boolean = false): Promise<void> {

    if (!this.surgeryReferenceDialogData?.clinicalCase?._id) {
      return Promise.resolve();
    }

    if (!this.preparations) {
      this.preparations = [];
    }

    if (refresh) {
      this.preparations = [];
    }

    this.preparationsLoading = true;
    this.changeDetectorRef.detectChanges();

    try {
      const preparationsResponse = await this._getPreparations(start) as PreparationsSuccessResponse;
      this.preparations = [...this.preparations, ...preparationsResponse?.result || []];
      this.totalPreparationsCount = preparationsResponse.totalItemsCount;
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.preparationsLoading = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  _getPreparations(start: number = 0): Promise<PreparationsResponse> {
    return this.surgeryReferenceService.getPreparations(this.surgeryReferenceDialogData?.clinicalCase?._id, start, this.count);
  }

  refreshPreparations(): void {
    this.getPreparations(0, true);
  }

  async loadMorePreparations(data: HorizontalListLoadMoreData): Promise<void> {
    await this.getPreparations(data.page);
    setTimeout(() => {
      data.swiper.slideTo(data.slideToSlideIndex, 0);
    }, 100);
  }

  async onBackClicked(): Promise<void> {

    if (this.removingTreatment) {
      return;
    }

    if (!this.selectableFracture) {
      this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.TreatmentSelect);
      return;
    }

    this.removingTreatment = true;

    const caseId = this.surgeryReferenceDialogData?.clinicalCase?._id;

    try {
      await this.surgeryReferenceService.deleteTreatment(caseId, this.selectableFracture?.fracture?._id);
      this.selectableFracture.treatment = null;
      this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.TreatmentSelect);
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.removingTreatment = false;
    }
  }

  openLink(obj: Approach | Preparation): void {
    this.authService.openAoLink(obj?.url);
  }

  async addApproachToCase(approach: Approach): Promise<void> {

    if (this.addingApproach) {
      return;
    }

    this.addingApproach = true;

    const caseId = this.surgeryReferenceDialogData?.clinicalCase?._id;

    try {
      await this.surgeryReferenceService.addApproach(caseId, approach?._id)
        .then((response: AddApproachResponse) => {
          this.clinicalCaseService.emitClinicalCaseUpdated(response?.result);
        });
      this.surgeryReferenceDialogData.approach = approach;
      (this.surgeryReferenceDialogData.clinicalCase as ClinicalCase).approach = approach;
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.addingApproach = false;
    }
  }

  async addPreparationToCase(preparation: Preparation): Promise<void> {

    if (this.addingPreparation) {
      return;
    }

    this.addingPreparation = true;

    const caseId = this.surgeryReferenceDialogData?.clinicalCase?._id;

    try {
      await this.surgeryReferenceService.addPreparation(caseId, preparation?._id)
        .then((response: AddPreparationResponse) => {
          this.clinicalCaseService.emitClinicalCaseUpdated(response?.result);
        });
      this.surgeryReferenceDialogData.preparation = preparation;
      (this.surgeryReferenceDialogData.clinicalCase as ClinicalCase).preparation = preparation;
    } catch (err) {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    } finally {
      this.addingPreparation = false;
    }
  }

  /* eslint-disable */
  trackByApproachFn(index: number, item: Approach): string {
    /* eslint-enable */
    return item?._id;
  }

  /* eslint-disable */
  trackByPreparationFn(index: number, item: Preparation): string {
    /* eslint-enable */
    return item?._id;
  }

  save(): void {
    this.surgeryReferenceUIService.closeDialog();
  }

  get showApproaches(): boolean {
    return !this.surgeryReferenceDialogData?.approach &&
      this.approaches?.length ? true : false;
  }

  get showPreparations(): boolean {
    return !this.surgeryReferenceDialogData?.preparation &&
      this.preparations?.length ? true : false;
  }

  get showCaseEnriched(): boolean {
    return !this.approachesLoading && !this.preparationsLoading &&
      !this.showApproaches && !this.showPreparations;
  }

  addNewModule(): void {
    this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.FractureSelect);
  }

  async removeModule(item: Fracture | Treatment | Approach | Preparation | FractureAndTreatment): Promise<void> {

    if (this.removingModule) {
      return;
    }

    this.removingModule = true;

    this.surgeryReferenceUIService.removeModule(item, this.surgeryReferenceDialogData?.clinicalCase?._id).then(() => {

      if ((item as any)?.fracture && (item as any)?.treatment) { // Treatment but also remove fracture
        this._removeTreatment(item as FractureAndTreatment);
      } else {
        switch ((item as any).contentType) {
          case ContentType.DIAGNOSIS: // Fracture
            this._removeFracture(item as Fracture);
            break;
          case ContentType.APPROACH:
            this.surgeryReferenceDialogData.approach = null;
            (this.surgeryReferenceDialogData.clinicalCase as ClinicalCase).approach = null;
            break;
          case ContentType.PREPARATION:
            this.surgeryReferenceDialogData.preparation = null;
            (this.surgeryReferenceDialogData.clinicalCase as ClinicalCase).preparation = null;
            break;
        }
      }
    }).catch(err => {
      console.error(err);
      this.toast.showWithMessage('app.common.something-went-wrong', 'app.common.error-default', ToastMode.ERROR);
    }).finally(() => {
      this.removingModule = false;
    });
  }

  _removeTreatment(item: FractureAndTreatment): void {
    if (this.surgeryReferenceDialogData && this.surgeryReferenceDialogData?.selectedFractures?.length) {

      if (this.selectableFracture?.fracture?._id === (item as FractureAndTreatment)?.fracture?._id &&
        this.selectableFracture?.treatment?._id === (item as FractureAndTreatment)?.treatment?._id
      ) {
        this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.FractureSelect);
      }

      const treatmentIndex = this.surgeryReferenceDialogData?.selectedFractures.findIndex((selectedFractures: FractureAndTreatment) =>
        selectedFractures?.fracture?._id === (item as FractureAndTreatment)?.fracture?._id &&
        selectedFractures?.treatment?._id === (item as FractureAndTreatment)?.treatment?._id);

      if (treatmentIndex > -1) {
        this.surgeryReferenceDialogData?.selectedFractures.splice(treatmentIndex, 1);
      }
    }
  }

  _removeFracture(item: Fracture): void {
    if (this.selectableFracture?.fracture?._id === (item as Fracture)?._id) {
      this.surgeryReferenceUIService.navigate(SurgeryReferenceDialogPage.FractureSelect);
    }

    if (this.surgeryReferenceDialogData && this.surgeryReferenceDialogData?.selectedFractures?.length) {
      const fractureIndex = this.surgeryReferenceDialogData?.selectedFractures
        .findIndex((fractureAndTreatment: FractureAndTreatment) =>
          fractureAndTreatment?.fracture?._id === (item as Fracture)?._id);

      if (fractureIndex > -1) {
        this.surgeryReferenceDialogData?.selectedFractures.splice(fractureIndex, 1);
      }
    }
  }
}
