import {Component, OnInit, ViewChild} from '@angular/core';
import {IonInfiniteScroll, ModalController} from '@ionic/angular';

import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { JournalsDataService } from 'src/app/services/journals/journals-data.service';
import {Journal} from '../../services/yeti-protocol/journal';
import {JournalsService} from '../../services/journals/journals.service';

@Component({
  selector: 'app-recommended-journals-dialog',
  templateUrl: './recommended-journals.dialog.component.html',
  styleUrls: ['./recommended-journals.dialog.component.scss'],
})
export class RecommendedJournalsDialogComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  recommendedJournals: Array<Journal> = new Array<Journal>();
  private readonly start = 0;
  private readonly count = 15;
  private lastFetchLength: number;

  constructor(
    private appNavController: AppNavController,
    private journalsDataService: JournalsDataService,
    private journalsService: JournalsService,
    private modalController: ModalController
  ) { }

  ngOnInit(): void {
    this.getRecommendedJournals(this.start, false);
  }

  loadMoreRecommendedJournals(_event: Event): void {
    if(this.lastFetchLength < this.count) {
      this.disableInfiniteScroll(true);
      return;
    }

    const start = Math.floor(this.recommendedJournals.length / this.count);
    this.getRecommendedJournals(start, true);
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  onOpenJournal(journalId: string): void {
    this.onClose();
    this.appNavController.openJournal(journalId);
  }

  onToggleJournalFollowing(journal: Journal): void {
    this.journalsService.followUnfollowJournal(journal.id, !journal.isFollowed).then(success => {
      if(success) {
        journal.isFollowed = !journal.isFollowed;
        this.journalsDataService.triggerFollowUnfollowJournalAction(journal);
      }
    });
  }

  private getRecommendedJournals(start: number, hideInfiniteScroll: boolean): void {
    this.journalsService.getRecommendedJournals(start, this.count)
      .then((resposneArray: Array<Journal>) => {
        const journals = resposneArray;
        this.lastFetchLength = journals.length;
        this.recommendedJournals = [...this.recommendedJournals, ...journals];
        if(hideInfiniteScroll) {
          this.infiniteScroll.complete();
        }
      })
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

}
