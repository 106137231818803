// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --share-selected-recipients-slider-padding-top: 15px;
  --share-selected-recipients-slider-padding-bottom: 15px;
  --share-selected-recipients-slider-padding-start: 15px;
  --share-selected-recipients-slider-padding-end: 0;
  --share-selected-recipients-slider-background: var(--mi-light-purple-1-color);
  --share-selected-recipients-slider-border-radius: 7px;
}

.share-selected-recipients-slider-container {
  width: 100%;
  padding-top: var(--share-selected-recipients-slider-padding-top);
  padding-bottom: var(--share-selected-recipients-slider-padding-bottom);
  padding-left: var(--share-selected-recipients-slider-padding-start);
  padding-right: var(--share-selected-recipients-slider-padding-end);
  background-color: var(--share-selected-recipients-slider-background);
  border-radius: var(--share-selected-recipients-slider-border-radius);
}
.share-selected-recipients-slider-container.adding-type {
  background-color: var(--mi-white-color);
  padding: var(--share-selected-recipients-slider-padding-top) 0 0 0;
}
.share-selected-recipients-slider-container .selected-recipients-cards {
  width: 100%;
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper ::ng-deep .swiper-button-prev,
.share-selected-recipients-slider-container .selected-recipients-cards swiper ::ng-deep .swiper-button-next {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-size: 100%;
  opacity: 0.6;
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper ::ng-deep .swiper-button-prev.swiper-button-disabled,
.share-selected-recipients-slider-container .selected-recipients-cards swiper ::ng-deep .swiper-button-next.swiper-button-disabled {
  display: none;
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper ::ng-deep .swiper-button-next:after,
.share-selected-recipients-slider-container .selected-recipients-cards swiper ::ng-deep .swiper-button-prev:after {
  content: none;
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper ::ng-deep .swiper-button-next {
  background-image: url("/svg/chevron-forward-circle.svg");
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper ::ng-deep .swiper-button-prev {
  background-image: url("/svg/chevron-back-circle.svg");
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper .add-more-card {
  width: 70px;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper .add-more-card .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper .add-more-card .icon-container .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--mi-blue-color-4);
  cursor: pointer;
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper .add-more-card .icon-container .wrapper app-icon {
  color: var(--mi-primary-color);
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper .add-more-card .text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper .add-more-card .text-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 26px;
}
.share-selected-recipients-slider-container .selected-recipients-cards swiper .add-more-card .text-container app-multiline-text-with-ellipsis ::ng-deep {
  color: var(--mi-black-color);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
