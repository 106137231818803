import {Component, Inject, Input, OnInit} from '@angular/core';
import {Comment} from '../../services/yeti-protocol/comments';
import {UI_UTILS_SERVICE, UIUtilsServiceInterface} from '../../services/utils/ui-utils.service.interface';
import {isImage, isVideo} from '../../services/attachments.model';
import {AppTranslationService} from '../../services/app-translation.service';

@Component({
  selector: 'app-latest-comment-content',
  templateUrl: './latest-comment-content.component.html',
  styleUrls: ['./latest-comment-content.component.scss'],
})
export class LatestCommentContentComponent  implements OnInit {

  @Input() latestComment: Comment;

  constructor(
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface,
    private appTranslationService: AppTranslationService,
  ) {}

  ngOnInit(): void {}

  get latestCommentContent(): string {
    if (!!this.latestComment?.content) {
      return this.latestComment.content;
    } else if (this.latestComment?.attachments?.length > 0) {
      const firstAttachment = this.latestComment?.attachments[0];
      if (isImage(firstAttachment.mimeType)) {
        return this.appTranslationService.instant('app.common.commented-with-an-image');
      } else if (isVideo(firstAttachment.mimeType)) {
        return this.appTranslationService.instant('app.common.commented-with-a-video');
      } else {
        return this.appTranslationService.instant('app.common.commented-with-a-document');
      }
    }
  }

}
