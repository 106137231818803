import { Connection } from 'src/app/services/yeti-protocol/connections';
import { Conversation, ConversationsSuccessResponse } from 'src/app/services/yeti-protocol/conversation';
import { Message } from 'src/app/services/yeti-protocol/message';

export enum ArrayLocation {
  START = 'start',
  END = 'end'
}

export namespace ActiveConversations {
  export interface FetchActiveConversationsPayload {
    pageIndex?: number;
    pageSize?: number;
  }

  export class FetchActiveConversations {
    static readonly type = '[Active Conversations] Fetch active conversations';
    constructor(public payloadParams: FetchActiveConversationsPayload) { }
  }

  export class FetchActiveConversationsSuccess {
    static readonly type = '[Active Conversations] Fetch active conversations success';
    constructor(
      public payloadParams: FetchActiveConversationsPayload,
      public response: ConversationsSuccessResponse
    ) { }
  }

  export class FetchActiveConversationsFailed {
    static readonly type = '[Active Conversations] Fetch active conversations failed';
  }

  export class InsertActiveConversationBeforeIndex {
    static readonly type = '[Active Conversations] Insert active conversation before index';
    constructor(
      public activeConversation: Conversation,
      public index: number
    ) { }
  }

  export class RemoveActiveConversation {
    static readonly type = '[Active Conversations] Remove active conversation at index';
    constructor(
      public activeConversation: Conversation
    ) { }
  }

  export class UpdateActiveConversation {
    static readonly type = '[Active Conversations] Update active conversation';
    constructor(
      public activeConversation: Conversation,
    ) { }
  }

  export class AddNewConversationFromConversationId {
    static readonly type = '[Active Conversations] Add new active conversation from conversation id';
    constructor(
      public conversationId: string,
      public location: ArrayLocation
    ) { }
  }

  export class UpdateActiveConversationsBasedOnNewMessage {
    static readonly type = '[Active Conversations] Update active conversations based on new message';
    constructor(
      public message: Message,
    ) { }
  }

  export class UpdateCurrentActiveConversationId {
    static readonly type = '[Active Conversations] Update current active conversation id';
    constructor(
      public currentActiveConversationId: string,
    ) { }
  }

  export class MarkActiveConversationAsRead {
    static readonly type = '[Active Conversations] Mark active conversation as read';
    constructor(
      public conversationId: string,
    ) { }
  }

  export class UpdateActiveConversationBasedOnConnectionChange {
    static readonly type = '[Active Conversations] Update active conversation based on connection change';
    constructor(
      public connection: Connection,
    ) { }
  }
}
