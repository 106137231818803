// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.clinical-expert-card {
  display: block;
}
.clinical-expert-card ::ng-deep {
  --mi-card-padding-top: 0px;
  --mi-card-padding-bottom: 0px;
  --mi-card-padding-start: 0px;
  --mi-card-padding-end: 0px;
}
.clinical-expert-card ::ng-deep ion-card {
  margin-inline-start: 0;
  margin-inline-end: 0;
  width: 100%;
}
.clinical-expert-card .card-header {
  height: 76px;
  background-color: var(--mi-grey-color-2);
  background: linear-gradient(90deg, var(--mi-grey-color-8) 0%, var(--mi-grey-color-9) 100%);
}
.clinical-expert-card .card-header .expert-image {
  position: absolute;
  top: 26px;
  left: 20px;
  object-fit: fill;
}
.clinical-expert-card .card-header .expert-image ::ng-deep {
  --user-image-size: 79px;
}
.clinical-expert-card .card-header .expert-image ::ng-deep ion-avatar {
  border: 3px solid var(--mi-white-color);
}
.clinical-expert-card .card-header .expert-image ::ng-deep ion-avatar ::ng-deep .ao-member-symbol-component {
  top: 70%;
  left: 30%;
}
.clinical-expert-card .expert-card-content {
  padding-bottom: 0;
  height: 145px;
  margin-top: 40px;
}
.clinical-expert-card .expert-card-content .name-container {
  text-align: left;
}
.clinical-expert-card .expert-card-content .name-container app-user-full-name {
  --name-text-color: var(--mi-secondary-color-2);
  --name-text-font-weight: var(--font-weight-medium);
  --name-text-font-size: var(--font-size-large);
}
.clinical-expert-card .expert-card-content .expert-additional-info {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  height: 36px;
}
.clinical-expert-card .expert-card-content .expert-additional-info .info {
  text-align: left;
  color: var(--mi-color-text-lite);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clinical-expert-card .expert-card-content .follow-button {
  width: 100%;
  margin-top: 10px;
}

app-mi-card {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
