// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --card-width: 302px;
}
:host .group-card {
  min-width: var(--card-width);
  max-width: var(--card-width);
  cursor: pointer;
}
:host .group-card .group-image {
  --group-image-size: 79px;
  --group-image-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  left: 25px;
  border: 4px solid var(--mi-white-color);
}
:host .group-card .group-card-header {
  height: 76px;
  background-color: var(--mi-content-groups-color-1);
  opacity: 100%;
}
:host .group-card .group-card-content {
  padding-bottom: 0;
  margin-top: 40px;
}
:host .group-card .group-card-content .group-title {
  width: 100%;
  height: 100%;
  text-align: left;
}
:host .group-card .group-card-content .group-title app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 47px;
  --multiline-text-with-ellipsis-height: 47px;
}
:host .group-card .group-card-content .group-title app-multiline-text-with-ellipsis ::ng-deep p {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
:host .group-card .group-card-content .group-additional-info {
  display: flex;
  margin: 5px 0;
}
:host .group-card .group-card-content .group-additional-info .additional-info-item {
  font-size: var(--mi-font-size-smaller);
  background-color: var(--ion-color-secondary-light-blue);
  color: var(--ion-color-secondary-bright-blue);
  border-radius: 12px;
  padding: 2px 7px;
  margin: 5px 5px 5px 0;
  width: 100%;
}
:host .group-card .group-card-content .group-additional-info .additional-info-item app-user-profile-image {
  --ao-icon-size: 18px;
}
:host .group-card .group-card-content .group-additional-info .additional-info-item app-user-full-name {
  --name-text-font-size: var(--font-size-regular);
  --name-text-font-weight: var(--font-weight-medium);
  --name-text-color: var(--ion-color-light-contrast);
  --country-text-color: var(--mi-color-text-lite);
  --country-text-font-size: var(--font-size-small);
  --country-text-font-weight: var(--font-weight-regular);
}
:host .group-card .group-card-content .group-additional-info .display-flex {
  display: flex;
  align-items: center;
}
:host .group-card .group-card-content .secondary-text {
  color: var(--mi-color-text-lite);
  font-size: var(--mi-font-size-smaller);
  font-weight: var(--font-weight-regular);
  display: flex;
}
:host .group-card .group-card-content .group-dates {
  display: flex;
}
:host .group-card .group-card-content .go-to-group-btn {
  margin-top: 15px;
  display: flex;
  --background: var(--ion-color-secondary);
  color: var(--mi-white-color);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--mi-font-size-default);
  height: 47px;
  --border-radius: 5px;
}
:host .group-card .group-card-content .group-option {
  text-align: center;
}
:host .group-card .group-card-content .bottom-info-content {
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
  font-weight: var(--font-weight-regular);
  display: flex;
  justify-content: space-between;
  margin: 10px 0 15px 0;
}
:host app-mi-card-actions {
  width: 100%;
  height: 100%;
}
:host app-mi-card-actions ::ng-deep .mi-card-actions-container {
  padding: 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
