import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { CoContributorsStateModel } from './co-contributors.model';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { CO_CONTRIBUTORS_STATE_KEY } from '../state-constants';
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { ConnectionsApiService } from 'src/app/services/messenger/connections-api.service';
import { CoContributors } from './co-contributors.actions';
import { CoContributorsSuccessResponse } from 'src/app/services/yeti-protocol/connections';

const CO_CONTRIBUTORS_STATE_TOKEN = new StateToken<CoContributorsStateModel>(
  CO_CONTRIBUTORS_STATE_KEY
);

@State({
  name: CO_CONTRIBUTORS_STATE_TOKEN,
  defaults: {
    coContributors: [],
    totalCount: 0,
    loading: false,
  },
})
@Injectable()
export class CoContributorsState {
  constructor(private connectionsApiService: ConnectionsApiService) { }

  @Selector()
  static state(state: CoContributorsStateModel): CoContributorsStateModel {
    return state;
  }

  @Selector()
  static coContributors(state: CoContributorsStateModel): Array<PublicProfile> {
    return state.coContributors;
  }

  @Selector()
  static totalCount(state: CoContributorsStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static loading(state: CoContributorsStateModel): boolean {
    return state.loading;
  }

  @Action(CoContributors.FetchCoContributors)
  async fetchCoContributors(
    ctx: StateContext<CoContributorsStateModel>,
    payload: CoContributors.FetchCoContributors
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      const coContributorsData = (await this.connectionsApiService.getCoContributors(
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize
      )) as CoContributorsSuccessResponse;

      ctx.dispatch(
        new CoContributors.FetchCoContributorsSuccess(
          payload?.payloadParams,
          coContributorsData
        )
      );
    } catch (err) {
      ctx.dispatch(
        new CoContributors.FetchCoContributorsFailed()
      );

      throw err;
    }
  }

  @Action(CoContributors.FetchCoContributorsSuccess)
  fetchCoContributorsSuccess(
    ctx: StateContext<CoContributorsStateModel>,
    action: CoContributors.FetchCoContributorsSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        coContributors: action.response.result,
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    } else {
      ctx.patchState({
        coContributors: [...state.coContributors, ...action.response.result],
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    }
  }

  @Action(CoContributors.FetchCoContributorsFailed)
  fetchCoContributorsFailed(
    ctx: StateContext<CoContributorsStateModel>
  ): void {
    ctx.patchState({
      loading: false,
    });
  }

  @Action(CoContributors.InsertCoContributorBeforeIndex)
  insertCoContributorBeforeIndex(ctx: StateContext<CoContributorsStateModel>, action: CoContributors.InsertCoContributorBeforeIndex): void {
    ctx.setState(
      patch<CoContributorsStateModel>({
        coContributors: insertItem<PublicProfile>(action.coContributor, action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount + 1,
    });
  }

  @Action(CoContributors.RemoveCoContributor)
  removeCoContributor(ctx: StateContext<CoContributorsStateModel>, action: CoContributors.RemoveCoContributor): void {
    ctx.setState(
      patch<CoContributorsStateModel>({
        coContributors: removeItem<PublicProfile>(action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount - 1,
    });
  }

  @Action(CoContributors.UpdateCoContributor)
  updateCoContributor(ctx: StateContext<CoContributorsStateModel>, action: CoContributors.UpdateCoContributor): void {
    ctx.setState(
      patch<CoContributorsStateModel>({
        coContributors:
          updateItem<PublicProfile>(coContributor => coContributor.userId === action.coContributor.userId, action.coContributor)
      })
    );
  }
}
