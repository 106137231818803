import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { GroupListItem } from 'src/app/services/yeti-protocol/chatter-api';
import { GroupVisibility } from 'src/app/services/groups/group.model';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { ToastService, ToastMode } from 'src/app/services/toast.service';
import { RemoveGroupEvent } from '../../../services/groups/group.model';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { UI_UTILS_SERVICE, UIUtilsServiceInterface } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-groups-row-list-item',
  templateUrl: './groups-row-list-item.component.html',
  styleUrls: ['./groups-row-list-item.component.scss'],
})
export class GroupsRowListItemComponent {
  @Input() group: GroupListItem;
  @Input() hideBorder = false;
  @Input() withoutOwnerActions: boolean;
  @Input() displayOwnerName = true;
  @Input() displayActionBtns = true;
  @Input() disableGroupOpen = false;
  @Input() showImageBanner = false;
  @Input() source: ActionSource;

  @Output() openGroup: EventEmitter<string> = new EventEmitter();
  @Output() removeGroup: EventEmitter<RemoveGroupEvent> = new EventEmitter<RemoveGroupEvent>();

  GroupVisibility = GroupVisibility;

  protected joiningGroup: boolean;
  protected requestingToJoin: boolean;

  constructor(
    private groupsService: GroupsService,
    private toast: ToastService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
  ) { }

  reloadGroup(): void {
    this.fetchGroup().then(group => {
      this.group = group;
    }).catch(err => {
      if (err?.error?.error?.message?.errfor?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message);
      }
    });
  }

  /* eslint-disable */
  showError(err: any): void {
    /* eslint-enable */
    this.toast.showWithMessage(err, 'app.common.error-default', ToastMode.ERROR);
  }

  fetchGroup(): Promise<GroupListItem> {
    return this.groupsService.getGroupById(this.group?._id)
      .then(res => {
        if ('result' in res) {
          return res.result;
        }
        return Promise.reject('Group is not found');
      });
  }

  onOpenGroup(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.openGroup.emit(this.group._id)
  }

  removed(data: RemoveGroupEvent): void {
    this.removeGroup.emit(data);
  }
}
