import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonInfiniteScroll, ModalController} from '@ionic/angular';
import {ShareToObject, ShareToObjType} from '../../services/sharing/sharing.model';
import {ConnectionsApiService, ConnectionsData} from '../../services/messenger/connections-api.service';
import {ToastMode, ToastService} from '../../services/toast.service';

@Component({
  selector: 'app-share-recipients-people',
  templateUrl: './share-recipients-people.component.html',
  styleUrls: ['./share-recipients-people.component.scss'],
})
export class ShareRecipientsPeopleComponent implements OnInit {

  @ViewChild('infiniteScrollContacts') infiniteScrollContacts: IonInfiniteScroll;

  @Input() initialSelectedContacts: Array<ShareToObject>

  selectedContacts: Array<ShareToObject> = [];
  contactsPageCount = 20;
  contacts: Array<ShareToObject> = [];
  totalContacts: number;
  contactsLoading = true;

  constructor(
    private connectionsApiService: ConnectionsApiService,
    private modalController: ModalController,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {

    this.getContacts()
      .then(contacts => {
        this.contacts = contacts;
      }).finally(() => {
      this.contactsLoading = false;
    });

    if (this.initialSelectedContacts) {
      this.selectedContacts = [...this.initialSelectedContacts];
    }
  }

  getContacts(start: number = 0): Promise<Array<ShareToObject>> {
    return this.connectionsApiService.getConnectionsFilteredByStatus('connected', start, this.contactsPageCount)
      .then((connectionsData: ConnectionsData) => {

        this.totalContacts = connectionsData.total;

        return connectionsData?.connections.map(connection => ({
          object: connection,
          type: ShareToObjType.CONTACT
        }));
      });
  }

  loadMoreContacts(): void {

    if (this.contacts.length >= this.totalContacts) {
      this.disableInfiniteScrollContacts(true);
      return;
    }

    const start = Math.floor(this.contacts.length / this.contactsPageCount);

    this.getContacts(start).then((contacts) => {
      this.contacts = [...this.contacts, ...contacts];
    }).catch(err => {
      this.showError(err.message);
    }).finally(() => {

      // Hide Infinite List Loader on Complete
      this.infiniteScrollContacts.complete();
    });
  }

  isContactSelected(item: ShareToObject): boolean {

    this.selectedContacts = this.selectedContacts || [];

    const index = this.selectedContacts.findIndex(c => c?.object?._id === item?.object?._id);

    return index > -1;
  }

  onContactSelectionChange(
    selected: boolean,
    contact: ShareToObject): void {
    const index = this.selectedContacts.findIndex(r => r?.object?._id === contact?.object?._id);

    if (selected && index === -1) {
      this.selectedContacts.push(contact);
    } else if (!selected && index > -1) {
      this.selectedContacts.splice(index, 1);
    }
  }


  setSelectedRecipients(): void {
    this.modalController.dismiss({
      selectedContacts: this.selectedContacts || [],
    });
  }

  get nextButtonDisabled(): boolean {
    return this.selectedContacts.length < 1;
  }

  private disableInfiniteScrollContacts(value: boolean): void {
    if (this.infiniteScrollContacts) {
      this.infiniteScrollContacts.disabled = value;
    }
  }


  private showError(msg: string, toastHeaderErrorId?: string): void {
    toastHeaderErrorId = toastHeaderErrorId ? toastHeaderErrorId : 'app.common.error-default';
    this.toastService.showWithMessage(msg, toastHeaderErrorId, ToastMode.ERROR);
  }

  onBack(): void {
    this.modalController.dismiss({closed: true});
  }

}
