import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AOEvent } from '../../services/yeti-protocol/event';
import { Countries } from 'src/app/services/yeti-protocol/countries';
import { ActionSource } from '../../services/yeti-protocol/tracking';

// services
import { CountryService } from 'src/app/services/country.service';
import { ResponsiveUtilsService } from '../../services/utils/responsive-utils.service';
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';
import { SwiperOptions } from 'swiper';
import { waitTime } from 'src/app/services/utils/utils';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() eventsList: Array<AOEvent> = new Array<AOEvent>();
  @Input() componentLabel: string;
  @Input() componentHeader: string;
  @Input() slidesHeader: string;
  @Input() cardSource: ActionSource;
  @Output() refreshListEvent: EventEmitter<boolean> = new EventEmitter();
  countries: Countries;
  swiperLogic: SwiperLogic;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private countryService: CountryService,
    private el: ElementRef,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this._fetchCountries();
  }

  ngAfterViewInit(): void {
    this._initSwiperLogic()
  }

  ngOnDestroy(): void {
    this.swiperLogic.destroy();
  }

  get swiperConfig(): SwiperOptions {
    if (this.swiperLogic) {
      return this.swiperLogic.config;
    }
    return null;
  }

  _initSwiperLogic(): void {
    const el = (this.el.nativeElement as HTMLElement).querySelector('.swiper-place-wrapper');
    this.swiperLogic = new SwiperLogic(200, this.responsiveUtilsService, {
      nativeElement: el
    }, 1.6);
    waitTime(500).then(() => {
      this.swiperLogic.afterViewInit();
    });
  }

  seeAllEvents(): void {
    this.trackActionClicked('findMoreEvents', 'findMoreEventsSeeAll');
    this.authService.openAoLink(this.contextService?.currentContext?.moreEventsLink);
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  _fetchCountries(): void {
    this.countryService.countries.subscribe(
      countries => {
        this.countries = countries;
      }
    );
  }

  private trackActionClicked(id: string, title: string): void {
    const trackedActionType = 'event';
    let source = ActionSource.unspecified;
    if (this.cardSource) {
      source = this.cardSource;
    }
    this.trackingService.trackGenericClickedAction(id, trackedActionType, title, { source: source })
      .catch(_err => {
        console.error('Could not track clicked action from events component.');
      });
  }
}
