// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --image-attachment-width: 100%;
  --image-attachment-height: 100%;
  --image-attachment-border-radius: 5px;
  --image-attachment-object-fit: cover;
}

.image-attachment-container {
  width: var(--image-attachment-width);
  height: var(--image-attachment-height);
}
.image-attachment-container .wrapper {
  width: var(--image-attachment-width);
  height: var(--image-attachment-height);
  position: relative;
}
.image-attachment-container .wrapper.with-caption {
  height: calc(var(--image-attachment-height) - 30px);
}
.image-attachment-container .wrapper.with-caption .image-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.image-attachment-container .wrapper.with-caption .image-container ion-thumbnail {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.image-attachment-container .wrapper.with-caption .image-container ion-thumbnail .image ::ng-deep div, .image-attachment-container .wrapper.with-caption .image-container ion-thumbnail .image ::ng-deep div ion-img, .image-attachment-container .wrapper.with-caption .image-container ion-thumbnail .image ::ng-deep div ion-img::part(image) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.image-attachment-container .wrapper.with-caption .image-background-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.image-attachment-container .wrapper .image-container {
  width: var(--image-attachment-width);
  height: var(--image-attachment-height);
  border-radius: var(--image-attachment-border-radius);
}
.image-attachment-container .wrapper .image-container .loading-container {
  width: 100%;
  height: 100%;
  background-color: var(--mi-grey-color-8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-attachment-container .wrapper .image-container .loading-container app-icon {
  --icon-size: 50px;
}
.image-attachment-container .wrapper .image-container ion-thumbnail {
  width: var(--image-attachment-width);
  height: var(--image-attachment-height);
  --border-radius: var(--image-attachment-border-radius);
}
.image-attachment-container .wrapper .image-container ion-thumbnail .image ::ng-deep div, .image-attachment-container .wrapper .image-container ion-thumbnail .image ::ng-deep div ion-img, .image-attachment-container .wrapper .image-container ion-thumbnail .image ::ng-deep div ion-img::part(image) {
  width: var(--image-attachment-width);
  height: var(--image-attachment-height);
  border-radius: var(--image-attachment-border-radius);
  object-fit: var(--image-attachment-object-fit);
}
.image-attachment-container .wrapper .image-container .play {
  position: relative;
  top: -70%;
  width: 68px;
  height: 68px;
  opacity: 0.3;
  display: block;
  margin: 0 auto;
}
.image-attachment-container .wrapper .image-background-container {
  width: var(--image-attachment-width);
  height: var(--image-attachment-height);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: var(--image-attachment-border-radius);
}
.image-attachment-container .wrapper .image-background-container .loading-container {
  width: 100%;
  height: 100%;
  background-color: var(--mi-grey-color-8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-attachment-container .wrapper .image-background-container .loading-container app-icon {
  --icon-size: 220px;
  width: 220px;
  height: 220px;
}
.image-attachment-container .wrapper .image-background-container .background-image ::ng-deep div, .image-attachment-container .wrapper .image-background-container .background-image ::ng-deep div ion-img, .image-attachment-container .wrapper .image-background-container .background-image ::ng-deep div ion-img::part(image) {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 2.5px);
  height: calc(100% + 2.5px);
  object-fit: fill;
  filter: blur(3.75px);
  z-index: 1;
}
.image-attachment-container .wrapper .image-background-container .play-image {
  z-index: 10;
  width: 100%;
}
.image-attachment-container .wrapper .image-background-container .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: var(--mi-grey-color-13);
  z-index: 5;
}
.image-attachment-container .wrapper .image-background-container .image {
  height: var(--image-attachment-height);
}
.image-attachment-container .wrapper .image-background-container .image ::ng-deep div, .image-attachment-container .wrapper .image-background-container .image ::ng-deep ion-img, .image-attachment-container .wrapper .image-background-container .image ::ng-deep div ion-img::part(image) {
  height: var(--image-attachment-height);
}
.image-attachment-container .wrapper .image-background-container .image ::ng-deep div ion-img::part(image) {
  position: relative;
  z-index: 5;
}
.image-attachment-container .wrapper .image-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}
.image-attachment-container .wrapper .image-overlay app-icon ::ng-deep {
  --icon-size: 40px;
  color: var(--mi-white-color);
}

.caption-container {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: var(--font-size-small);
  color: var(--mi-grey-color-33);
  background-color: var(--mi-light-purple-6-color);
  border-bottom-left-radius: var(--image-attachment-border-radius);
  border-bottom-right-radius: var(--image-attachment-border-radius);
}
.caption-container .caption-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
