export enum ActionOnInit {
  ADD_IMAGES = 'add-images',
  ADD_VIDEOS = 'add-videos'
}

export interface VideoToPlay {
  url: string;
  mimeType: string;
  thumbnailImageUrl: string;
}
