import { Component, Inject, Input, OnInit } from '@angular/core';

/* eslint-disable */
import { AOEvent, AOEventParticipant, AOEventParticipantType } from 'src/app/services/yeti-protocol/event';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';
/* eslint-enable */

@Component({
  selector: 'app-event-participants-card',
  templateUrl: './event-participants-card.component.html',
  styleUrls: ['./event-participants-card.component.scss'],
})
export class EventParticipantsCardComponent implements OnInit {

  @Input() aoEvent: AOEvent;
  @Input() aoEventAttendees: Array<AOEventParticipant>;

  totalAoEventAttendees: number;
  AOEventParticipantType = AOEventParticipantType;
  showMaxAttendees = 4;

  constructor(
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
    private appNavController: AppNavController
  ) { }

  ngOnInit(): void {
    if(this.aoEvent || this.aoEvent?.groupInfo) {
      this.aoEvent.groupInfo.imageUrl = this.aoEvent?.groupInfo?.imageUrl || 'assets/images/default-groups-image.svg';
    }
  }

  setTotalParticipantsCount(totalAoEventAttendees: number): void {
    this.totalAoEventAttendees = totalAoEventAttendees;
  }

  seeAllParticipants(): void {
    this.dialogs.showAoEventParticipantsDialog(this.aoEvent?._id, AOEventParticipantType.PARTICIPANT)
      .then(res => {
        if(res.action === 'sendMessageTo'){
          this.appNavController.openMessenger(res.data);
        }
        if(res.action === 'openPublicUserProfile'){
          this.onOpenPublicUserProfile(res.data);
        }
      });
  }

  navigateToGroupPage(): void {
    this.appNavController.openGroup(this.aoEvent?.groupInfo?._id)
  }

  onSendMessage(connectionId: string): void {
    this.appNavController.openMessenger(connectionId);
  }

  onOpenPublicUserProfile(userId: string): void {
    this.appNavController.openPublicUserProfile(userId);
  }
}
