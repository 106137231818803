import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShortPublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { ActionSource } from '../../services/yeti-protocol/tracking';

@Component({
  selector: 'app-my-people-list',
  templateUrl: './my-people-list.component.html',
  styleUrls: ['./my-people-list.component.scss'],
})

export class MyPeopleListComponent {

  @Input() followedPeople: Array<ShortPublicProfile> = new Array<ShortPublicProfile>();
  @Input() source: ActionSource;

  @Output() itemChanged: EventEmitter<void> = new EventEmitter<void>();
}
