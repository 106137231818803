import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../modules/icon/icon.component';

@Component({
  selector: 'app-ai-tag',
  templateUrl: './ai-tag.component.html',
  styleUrls: ['./ai-tag.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    IconComponent
  ]
})
export class AiTagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}
