import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// models
import {
  ActionSheetConfig,
  ActionSheetConfigGroups,
  ActionSheetConfigOrcIdArticle,
  ArticleActions,
  MiCardActionsService
} from '../../services/mi-card-actions.service';

// services
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { Article, Video } from '../../services/yeti-protocol/article';

import { ResponsiveUtilsService } from '../../services/utils/responsive-utils.service';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';

@Component({
  selector: 'app-mi-card-actions',
  templateUrl: './mi-card-actions.component.html',
  styleUrls: ['./mi-card-actions.component.scss'],
})
export class MiCardActionsComponent implements OnInit, OnDestroy {

  @Input() actionSheetConfig: ActionSheetConfig | ActionSheetConfigGroups | ActionSheetConfigOrcIdArticle;
  @Input() infoSheetId: string;
  @Input() articleItem: Article | Video;
  @Input() infoSheetActions: Array<InfoSheetActionItem> = new Array<InfoSheetActionItem>();
  @Input() hideBookmarkOption: boolean;
  @Input() showTranslateOption: boolean;
  @Input() showSaveOption: boolean;
  @Input() showSettingsButton = true;
  @Input() hasBookmarked: boolean;
  @Output() actionSheetActionClick: EventEmitter<string> = new EventEmitter();
  @Output() actionFromPopoverClicked: EventEmitter<InfoSheetActionItem> = new EventEmitter();

  actionSheetActionClickedSubscription: Subscription;
  private miCardActionsService: MiCardActionsService;

  constructor(
    actionSheetController: ActionSheetController,
    private infoSheetService: InfoSheetService,
    private responsiveUtilsService: ResponsiveUtilsService
  ) {
    this.miCardActionsService = new MiCardActionsService(actionSheetController);
  }

  ngOnInit(): void {
    this.actionSheetActionClickedSubscription = this.miCardActionsService.actionClickedSubject.subscribe((action: string) => {
      this.actionSheetActionClick.emit(action);
    });
    setTimeout(() => {
      this.generateActions();
    }, 0);
  }

  generateActions(): void {

    this.infoSheetActions = this.infoSheetActions || [];

    if (this.doesActionExists('bookmark')) { // remove and add action again becasue maybe text or icon should be changed
      this.removeAction('bookmark');
    }

    if (this.showSaveOption && !this.doesActionExists('bookmark')) {
      this.infoSheetActions.push({
        id: 'bookmark',
        icon: this.hasBookmarked ? 'md-icon-remove-bookmark' : 'md-icon-bookmark',
        textKey: this.hasBookmarked ? 'app.common.remove' : 'app.common.save',
        code: 'BOOKMARK',
        handler: () => {
          this.bookmark();
        }
      });
    }
    if (!this.hideBookmarkOption && !this.doesActionExists('bookmark')) {
      this.infoSheetActions.push({
        id: 'bookmark',
        icon: 'md-icon-bookmark',
        textKey: 'app.common.bookmark',
        code: 'BOOKMARK',
        handler: () => {
          this.bookmark();
        }
      });
    }
    if (this.showTranslateOption && !this.doesActionExists('translate')) {
      this.infoSheetActions.push({
        id: 'translate',
        icon: 'md-icon-translate',
        code: 'TRANSLATE',
        textKey: 'app.common.translate',
        handler: () => {
          this.translate();
        }
      });
    }
  }

  doesActionExists(id: string): boolean {

    if (!this.infoSheetActions?.length) {
      return false;
    }

    if (this.infoSheetActions.findIndex(action => action.id === id) !== -1) {
      return true;
    }

    return false;
  }

  removeAction(id: string): void {

    if (!this.infoSheetActions?.length) {
      return;
    }

    const actionIndex = this.infoSheetActions.findIndex(action => action.id === id);

    if (actionIndex !== -1) {
      this.infoSheetActions.splice(actionIndex, 1);
    }
  }

  get showSettings(): boolean {
    return !!(this.actionSheetConfig || this.infoSheetId);
  }

  onShowSettings(event: Event): void {

    this.generateActions();

    if (this.isDesktop) {
      this.showSettingsForWeb(event);
    } else {
      this.showSettingsForMobile();
    }
  }

  showSettingsForMobile(): void {
    if (this.infoSheetId) {
      this.infoSheetService.open(this.infoSheetId);
    } else {
      this.miCardActionsService.showActionSheet(this.actionSheetConfig);
    }
  }

  showSettingsForWeb(event: Event): void {
    if (this.infoSheetActions && this.infoSheetActions.length > 0) {

      this.sortActions();

      this.infoSheetService.openWeb(this.infoSheetActions, event)
        .then(action => {
          if (action && action.code) {
            switch (action.code) {
              case 'BOOKMARK':
              case 'TRANSLATE':
                action.handler();
                break;
              default:
                this.actionFromPopoverClicked.emit(action);
            }
          }

        })
    } else {
      this.showSettingsForMobile();
      console.log('There are not provided any actions for WEB popover!');
    }
  }

  ngOnDestroy(): void {
    if (this.actionSheetActionClickedSubscription) {
      this.actionSheetActionClickedSubscription?.unsubscribe();
    }
  }

  bookmark(): void {
    this.actionSheetActionClick.emit(ArticleActions.bookrmark);
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  translate(): void {
    this.actionSheetActionClick.emit(ArticleActions.translate);
  }

  sortActions(): void {
    this.infoSheetActions = this.infoSheetActions.sort((actionA, actionB) => {

      const actionAValue = this.getSortValue(actionA);
      const actionBValue = this.getSortValue(actionB);

      return actionAValue - actionBValue;
    })
  }

  getSortValue(action: InfoSheetActionItem): number {
    switch (action.code) {
      case 'FEATURE_CASE_BTN':
        return 1;
      case 'ADVICE_NEEDED_BTN':
        return 2;
      case 'EDIT_CASE':
      case 'EDIT_POST':
        return 3;
      case 'TRANSLATE':
      case 'TRANSLATE_CASE':
      case 'TRANSLATE_POST':
        return 4;
      case 'REPORT_POST':
        return 5;
      case 'DELETE_POST':
        return 6;
      default:
        return 1;
    }
  }

  get infoSheetActionsSorted(): Array<InfoSheetActionItem> {
    this.sortActions();
    return this.infoSheetActions;
  }
}
