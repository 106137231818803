import { Injectable } from '@angular/core';
import { template } from 'lodash';
import appConfig from 'src/config/config';
import { DeepLinksConfig } from './deep-links-config.interface';

@Injectable({
  providedIn: 'root'
})
export class DeepLinksRecognizerService {
  appConfig: DeepLinksConfig = appConfig;

  buildRoutesConfig(config: DeepLinksConfig): Record<string, string> {
    const routes: Record<string, string> = {};
    config.deepLinks.forEach(deepLink => {
      routes[deepLink] = deepLink;
    });
    config.contexts.forEach(context => {
      config.contextDeepLinks.forEach(deepLink => {
        const path = template(deepLink)(context);
        routes[path] = path;
      });
      if('homePathAliases' in context){
        context.homePathAliases.forEach(homePathAlias => {
          config.contextDeepLinks.forEach(deepLink => {
            const path = template(deepLink)({
              homePath: homePathAlias
            });
            routes[path] = path;
          });
        })
      }
    });
    return routes;
  }

  isDeepLink(path: string): boolean {
    const route = this.getMatchedRoute(path);
    return !!route;
  }

  getMatchedRoute(path: string): string {
    // extract any query params which might confuse deep link detection
    // we should compare paths only
    const pathPartsWithQueryParams = path.split('?');
    const pathParts = (pathPartsWithQueryParams && pathPartsWithQueryParams.length > 0) ?
      pathPartsWithQueryParams[0].split('/') : path.split('/');
    const routes = this.buildRoutesConfig(this.appConfig);
    let matchedRoute = null;
    for(const key in routes){
      if(routes.hasOwnProperty(key)){
        const parts = key.split('/');
        if(pathParts.length !== parts.length){
          continue;
        }
        if(!this._doesPathPartsMatch(parts, pathParts)){
          continue;
        }
        matchedRoute = key;
        break;
      }
    }
    return matchedRoute;
  }

  translateAlias(path: string, config: DeepLinksConfig): string {
    let translatedPath = path;
    config.contexts.forEach(context => {
      if(context.homePathAliases && context.homePathAliases.length > 0){
        const matchedAliasPath = context.homePathAliases.find(aliasPath => {
          return path.substr(0, aliasPath.length) === aliasPath;
        });
        if(matchedAliasPath){
          translatedPath = context.homePath + path.substr(matchedAliasPath.length);
        }
      }
    });
    return translatedPath;
  }

  _doesPathPartsMatch(parts: Array<string>, pathParts: Array<string>): boolean {
    let partsDoesNotMatch = false;
    for(let i = 0; i < parts.length; ++ i){
      const part = parts[i];
      if(part.length > 0 && part.startsWith(':')){
        continue; // skip variable values
      }else{
        if(part !== pathParts[i]){
          partsDoesNotMatch = true;
          break;
        }
      }
    }
    return !partsDoesNotMatch;
  }
}
