import {Component, Inject, Input} from '@angular/core';
import {TRACKING_SERVICE, TrackingService, TrackingSourceParams} from '../../services/tracking/tracking.model';
import {AuthService} from '../../services/auth/auth.service';
import appConfig from 'src/config/config';

export interface AoMembership {
  title: string;
  context: string;
  endDate: Date;
  privilegesOutlink: string;
  renewOutlink: string;
}

@Component({
  selector: 'app-my-ao-membership',
  templateUrl: './my-ao-membership.component.html',
  styleUrls: ['./my-ao-membership.component.scss'],
})
export class MyAoMembershipComponent {

  @Input() source: string;
  @Input() aoMemberships: AoMembership[];

  readonly aoOutlinks = appConfig.aoOutlinks;

  constructor(
    private authService: AuthService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) {
  }

  redirectToBrowsePrivileges(): void {
    this.trackClickAction('browsePrivileges', 'outlink', 'aoMembership_browsePrivileges');
    this.authService.openAoLink(this.aoOutlinks.browsePrivileges);
  }

  redirectToMoreDetails(): void {
    this.trackClickAction('moreDetails', 'outlink', 'aoMembership_moreDetails');
    this.authService.openAoLink(this.aoOutlinks.myMemberships);
  }

  redirectToPrivileges(membership: AoMembership): void {
    this.trackClickAction(`privileges_${membership.context}`, 'outlink', 'aoMembership_privilege');
    this.authService.openAoLink(membership.privilegesOutlink);
  }

  redirectToRenew(membership: AoMembership): void {
    this.trackClickAction(`privileges_${membership.context}`, 'outlink', 'aoMembership_renew');
    this.authService.openAoLink(membership.renewOutlink);
  }

  trackClickAction(objectId: string, objectType: string, objectTitle: string): void {
    const sourceParams: TrackingSourceParams = {
      source: this.source,
    };
    this.trackingService
      .trackGenericClickedAction(
        objectId,
        objectType,
        objectTitle,
        sourceParams
      )
      .catch(_err => {
        console.error('Could not track ' + objectId + ' clicked action');
      });
  }
}
