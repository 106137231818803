import { Component, Input } from '@angular/core';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Connection } from 'src/app/services/yeti-protocol/connections';

@Component({
  selector: 'app-message-card-connection-request',
  templateUrl: './message-card-connection-request.component.html',
  styleUrls: ['./message-card-connection-request.component.scss'],
})
export class MessageCardConnectionRequestComponent {
  @Input() connection: Connection;
  @Input() userId: string;

  constructor(
    private translationService: AppTranslationService
  ) { }

  get chatInitiator(): string{
    if(this.chatInitiatedByUser){
      return  this.translationService.instant('app.components.MessageCardConnectionRequestComponent.you');
    }else{
      return [
        this.connection.initiator.firstName,
        this.connection.initiator.lastName
      ].join(' ');
    }
  }

  get chatInitiatedByUser(): boolean {
    return this.connection.initiator.userId === this.userId;
  }
}
