import { Component, Input } from '@angular/core';
import { ModalController, ViewDidEnter } from '@ionic/angular';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ArticleService } from 'src/app/services/article/article.service';
import { VideoTranscriptSummary } from 'src/app/services/yeti-protocol/article';

@Component({
  selector: 'app-video-transcript-dialog',
  templateUrl: './video-transcript-summary-dialog.component.html',
  styleUrls: ['./video-transcript-summary-dialog.component.scss'],
})
export class VideoTranscriptSummaryDialogComponent  implements ViewDidEnter {
  @Input() videoId: string;

  transcriptSummary: VideoTranscriptSummary = null;
  error: string = null;

  constructor(
    private modalController: ModalController,
    private articleService: ArticleService,
    private translationService: AppTranslationService) { }


  ionViewDidEnter(): void {
    this._fetchTranscriptSummary();
  }

  onRetry(): void {
    this.transcriptSummary = null;
    this.error = null;
    this._fetchTranscriptSummary();
  }

  _fetchTranscriptSummary(): void {
    this.articleService.getVideoTranscriptSummary(this.videoId)
      .then(transcript => {
        this.transcriptSummary = transcript;
      })
      .catch(err => {
        console.log(err);
        this.translationService.get('app.dialogs.VideoTranscriptSummaryDialogComponent.errorNotAvailable')
          .then(msg => {
            this.error = msg;
          });
      });
  }

  get isSpinnerVisible(): boolean {
    return this.transcriptSummary === null && this.error === null;
  }

  onClose(): void {
    this.modalController.dismiss();
  }

}
