import { Component, EventEmitter, Input, Output } from '@angular/core';

// models
import { AttachmentMimeType } from 'src/app/services/attachments.model';
import { SelectedFile } from 'src/app/modules/file-select/file-select.model';

@Component({
  selector: 'app-verification-step-three',
  templateUrl: './verification-step-three.component.html',
  styleUrls: ['./verification-step-three.component.scss'],
})
export class VerificationStepThreeComponent {

  @Input() selectedFile: SelectedFile;

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() selectFileClicked: EventEmitter<void> = new EventEmitter();
  @Output() submitClicked: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  navigateBack(): void {
    this.back.emit();
  }

  selectNewFile(): void {
    this.selectFileClicked.emit();
  }

  submit(): void {
    this.submitClicked.emit();
  }

  get showImagePreview(): boolean {
    return this.selectedFile?.file?.type === AttachmentMimeType.JPEG ||
      this.selectedFile?.file?.type === AttachmentMimeType.JPG ||
      this.selectedFile?.file?.type === AttachmentMimeType.PNG;
  }

  get showPdfPreview(): boolean {
    return this.selectedFile?.file?.type === AttachmentMimeType.PDF;
  }

  closeButtonClicked(): void {
    this.close.emit();
  }

}
