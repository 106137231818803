import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AOEventParticipant, AOEventParticipantType } from '../../services/yeti-protocol/event';

@Component({
  selector: 'app-ao-event-participant-item',
  templateUrl: './ao-event-participant-item.component.html',
  styleUrls: ['./ao-event-participant-item.component.scss'],
})
export class AoEventParticipantItemComponent {

  @Input() participant: AOEventParticipant;

  @Output() profileImageClicked: EventEmitter<string> = new EventEmitter();
  @Output() sendMessage: EventEmitter<string> = new EventEmitter();

  onProfileImageClicked(userId: string): void {
    if(userId) {
      this.profileImageClicked.emit(userId);
    }
  }

  onSendMessage(connectionId: string): void {
    this.sendMessage.emit(connectionId);
  }

  get isContributor(): boolean {
    return this.participant?.participantType === AOEventParticipantType.CONTRIBUTOR;
  }
}
