import { Component, Input } from '@angular/core';
import { ShortPublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-expert-recommendation-card-header',
  templateUrl: './expert-recommendation-card-header.component.html',
  styleUrls: ['./expert-recommendation-card-header.component.scss'],
})
export class ExpertRecommendationCardHeaderComponent {

  @Input() recommendationOwner: ShortPublicProfile;
  @Input() recommendationCreateDate: string;
  @Input() source: ActionSource;
}
