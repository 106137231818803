import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';

@Component({
  selector: 'app-empty-state-message-card',
  templateUrl: './empty-state-message-card.component.html',
  styleUrls: ['./empty-state-message-card.component.scss'],
})
export class EmptyStateMessageCardComponent {

  @Input()
  title: string;
  @Input()
  message: string;
  @Input()
  firstActionButtonText: string;
  @Input()
  secondActionButtonText: string;
  @Input()
  showButtons = true;
  @Input()
  imageName: string;
  @Input()
  additionalClasses: string[] = [];
  @Output()
  firstActionButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  secondActionButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(private responsiveUtilsService: ResponsiveUtilsService) { }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  firstActionButtonClickedEmit(): void {
    this.firstActionButtonClicked.emit();
  }

  secondActionButtonClickedEmit(): void {
    this.secondActionButtonClicked.emit();
  }

}
