import { Component } from '@angular/core';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';
import {VerificationService} from '../../services/verification.service';

@Component({
  selector: 'app-get-verified-card',
  templateUrl: './get-verified-card.component.html',
  styleUrls: ['./get-verified-card.component.scss'],
})
export class GetVerifiedCardComponent {

  constructor(private responsiveUtilsService: ResponsiveUtilsService,
              private verificationService: VerificationService) { }

  async onGetVerified(): Promise<void> {
    await this.verificationService.verify();
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

}
