import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CustomizeFeedService {

    private customizeFeedDialogClosedSubject: Subject<void> = new Subject();

    triggerCustomizeFeedDialogClosed(): void {
        this.customizeFeedDialogClosedSubject.next();
    }

    get customizeFeedDialogClosedObs(): Observable<void> {
        return this.customizeFeedDialogClosedSubject.asObservable();
    }
}
