// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --icon-color: var(--mi-white-color);
  --icon-font-size: var(--font-size-large);
  --background: none;
  --border-top-left-radius: 0px;
  --border-top-right-radius: 0px;
  --border-bottom-left-radius: 0px;
  --border-bottom-right-radius: 0px;
}

.back-button {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  color: var(--icon-color);
  background: var(--background);
  border-top-left-radius: var(--border-top-left-radius);
  border-top-right-radius: var(--border-top-right-radius);
  border-bottom-left-radius: var(--border-bottom-left-radius);
  border-bottom-right-radius: var(--border-bottom-right-radius);
}
.back-button app-icon {
  --icon-size: var(--icon-font-size);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
