import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

import { Subscription } from 'rxjs';

// models
import { ConnectedUser } from 'src/app/services/yeti-protocol/public-profile';
import { SearchUsersStatus, User } from 'src/app/services/yeti-protocol/messenger';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';

// services
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessengerService } from 'src/app/services/messenger/messenger.service';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

import * as _ from 'lodash';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';

const PAGE_SIZE = 20;

interface FoundUsersInfo {
  connected: Array<User>;
  pending: Array<User>;
  ao: Array<User>;
  total: number;
}

enum DIALOG_MODE {
  CONTACTS,
  SEARCH
}

@Component({
  selector: 'app-new-message-dialog',
  templateUrl: './new-message-dialog.component.html',
  styleUrls: ['./new-message-dialog.component.scss'],
})
export class NewMessageDialogComponent implements OnInit, OnDestroy {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  contacts: Array<User | ConnectedUser> = [];
  pendingContacts: Array<User> = [];
  myAoUsers: Array<User> = [];

  searchTerm: string;
  user: UserProfile;
  userProfileSubscription: Subscription;
  contactsTotalCount: number;
  searchTotalCount: number;
  disableConnectButtonsForUsers = {};
  searching: boolean;
  fetchedContacts = false;
  filteredContacts = false;
  mode = DIALOG_MODE.CONTACTS;
  DIALOG_MODE = DIALOG_MODE;

  public keyPressed = false;

  constructor(
    private authService: AuthService,
    private el: ElementRef,
    private messengerService: MessengerService,
    private modalController: ModalController,
    private appNavController: AppNavController,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI
  ) { }

  ngOnInit(): void {
    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe(profile => {
      this.user = profile;
    });

    this.getContacts('', 0);
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  searchTermChanged(newValue: string): void {
    this.debounceSearch(newValue);
  }

  debounceSearch = _.debounce((newValue: string) => {

    if (this.keyPressed && newValue?.length > 0) {
      return;
    }

    const searchTerm = newValue.trim();
    if (searchTerm && searchTerm.length > 0) {
      if (this.mode === DIALOG_MODE.CONTACTS) {
        setTimeout(() => {
          this.getContacts(searchTerm, 0, true);
        }, 1000);
      } else { // search mode
        setTimeout(() => {
          this.searchUsers(searchTerm, 0);
        }, 1000);
      }
    } else { // reset contacts mode after deletion of search term
      this.mode = DIALOG_MODE.CONTACTS;
      this._resetUsersList();
      this.getContacts('', 0, true);
    }
  }, 300);

  searchUsers(searchTerm: string, start: number): void {
    this.searching = true;
    this._searchUsers(searchTerm, start)
      .then(info => {
        if (start === 0) {
          this._resetUsersList();
        }
        this._updateUsers(info);
        this.searchTotalCount = info.total;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.searching = false;
        this.completeUbfiniteScroll();
      });
  }

  _searchUsers(term: string, pageIndex: number, status: SearchUsersStatus = null): Promise<FoundUsersInfo> {
    return this.messengerService.searchUsers(term, status, pageIndex, PAGE_SIZE)
      .then(found => {
        const users = found.users;
        return {
          connected: users.filter(user => user.connectionStatus === 'connected'),
          pending: users.filter(user => user.connectionStatus === 'pending'),
          ao: users.filter(user => {
            return user.connectionStatus === 'none' ||
              user.connectionStatus === 'disconnected' ||
              user.connectionStatus === 'rejected';
          }),
          total: found.total
        };
      })
  }

  _updateUsers(info: FoundUsersInfo): void {
    if (info.connected.length > 0) {
      this.contacts = [...this.contacts, ...info.connected];
    }

    if (info.pending.length > 0) {
      this.pendingContacts = [...this.pendingContacts, ...info.pending];
    }

    if (info.ao.length > 0) {
      this.myAoUsers = [...this.myAoUsers, ...info.ao];
    }
  }

  showSendNewConnectionRequestDialog(user: User): Promise<void> {

    return this.dialogs.showSendNewConnectionRequestDialog(user)
      .then(sent => {
        if (sent) {
          this.disableConnectButtonsForUsers[user?.userId] = true;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  get hasContacts(): boolean {
    return this.contacts.length > 0;
  }

  get hasPendingContacts(): boolean {
    return this.pendingContacts.length > 0;
  }

  get hasMyAoUsers(): boolean {
    return this.myAoUsers.length > 0;
  }

  get showNoContactsEmptyState(): boolean {
    return this.mode === DIALOG_MODE.CONTACTS && !this.hasContacts && this.fetchedContacts;
  }

  get showEmptySearchResults(): boolean {
    return this.mode === DIALOG_MODE.SEARCH && this.totalLoadedContacts === 0 && !this.searching;
  }

  get isSearchButtonVisible(): boolean {
    return this.searchTerm && this.searchTerm.length > 2;
  }

  loadMore(): void {

    let pageIndex: number;

    if (!this.canLoadMore) {
      this.disableInfiniteScroll(true);
      return;
    }

    if (this.mode === DIALOG_MODE.SEARCH) {

      const totalLoadedItems = this.contacts?.length + this.pendingContacts?.length + this.myAoUsers?.length;

      pageIndex = Math.floor(totalLoadedItems / PAGE_SIZE);
      this.searchUsers(this.searchTerm, pageIndex);
    } else {
      pageIndex = Math.floor(this.contacts?.length / PAGE_SIZE);
      this.getContacts('', pageIndex);

    }
  }

  get canLoadMore(): boolean {
    if (this.mode === DIALOG_MODE.SEARCH) {
      if (this.totalLoadedContacts < this.searchTotalCount) {
        return true;
      }
    } else {
      if (this.contacts?.length < this.contactsTotalCount) {
        return true;
      }
    }
    return false;
  }

  get totalLoadedContacts(): number {
    return this.contacts.length + this.pendingContacts.length + this.myAoUsers.length;
  }

  getContacts(searchTerm: string, start: number, replaceList?: boolean): void {
    this._searchUsers(searchTerm, start, SearchUsersStatus.CONNECTED)
      .then(res => {
        this.fetchedContacts = true;

        if (replaceList) {
          this.contacts = res.connected;
          this.filteredContacts = true;
        } else {
          this.contacts = [...this.contacts, ...res.connected];
        }

        this.focusSearchInput();

        this.contactsTotalCount = res.total;
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.completeUbfiniteScroll();
      });
  }

  completeUbfiniteScroll(): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.complete();
    }
  }

  closeSearchMode(): void {
    this.mode = DIALOG_MODE.CONTACTS;
    this._resetUsersList();
    this.getContacts('', 0);
    this.searchTerm = '';
  }

  performNewSearch(): void {
    this.mode = DIALOG_MODE.SEARCH;
    this._resetUsersList();
    this.searchUsers(this.searchTerm, 0);
  }

  onOpenConversation(conversationId: string): void {
    if (!conversationId) {
      return;
    }
    this.modalController.dismiss();
    this.appNavController.openMessenger(conversationId);
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  _resetUsersList(): void {
    this.contacts = [];
    this.pendingContacts = [];
    this.myAoUsers = [];
    this.searchTotalCount = 0;
  }

  private focusSearchInput(): void {
    setTimeout(() => {
      const searchInput = this.el.nativeElement.querySelector('.search-input');
      searchInput?.focus();
    }, 300);
  }
}
