// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --create-group-case-padding-top: 0px;
  --create-group-case-padding-bottom: 0px;
  --create-group-case-padding-start: 20px;
  --create-group-case-padding-end: 20px;
  --create-group-case-background-color: var(--mi-white-color);
  --create-group-case-section-bottom-padding: 20px;
}

app-icon-button {
  --icon-button-background-width: 20px;
  --icon-button-background-height: 20px;
  --icon-button-background-color: var(--mi-inputs-color);
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 20px;
  --icon-button-icon-color: var(--mi-color-text-lite);
  grid-row: 1;
  grid-column: 1;
  justify-self: start;
}

.create-group-case-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  background-color: var(--create-group-case-background-color);
}
.create-group-case-container .top-bar-container {
  grid-row: 1;
  grid-column: 1;
  align-self: start;
}
.create-group-case-container .top-bar-container app-top-bar {
  --top-bar-padding-start: var(--create-group-case-padding-start);
  --top-bar-padding-end: var(--create-group-case-padding-end);
  --top-bar-column-gap: 20px;
  --top-bar-title-text-align: center;
}
.create-group-case-container .top-bar-container .create-case-button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-group-case-container .top-bar-container .create-case-button .button-text {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-primary-color);
}
.create-group-case-container .top-bar-container .create-case-button.disabled .button-text {
  color: var(--mi-color-text-lite);
}
.create-group-case-container ion-content {
  grid-row: 2;
  grid-column: 1;
  justify-self: start;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  --padding-top: 25px;
}
.create-group-case-container ion-content .content-wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  height: 100%;
}
.create-group-case-container ion-content .content-wrapper .case-info-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.create-group-case-container ion-content .content-wrapper .case-info-container .general-information-section-container {
  padding: 0px var(--create-group-case-padding-end) var(--create-group-case-section-bottom-padding) var(--create-group-case-padding-start);
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
  row-gap: 10px;
}
.create-group-case-container ion-content .content-wrapper .case-info-container .general-information-section-container .label {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.create-group-case-container ion-content .content-wrapper .case-info-container .general-information-section-container .label.error {
  color: var(--mi-red-color-2);
}
.create-group-case-container ion-content .content-wrapper .case-info-container .topic-select-container {
  padding: 0px var(--create-group-case-padding-end) 5px var(--create-group-case-padding-start);
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
  row-gap: 10px;
  z-index: 10;
}
.create-group-case-container ion-content .content-wrapper .case-info-container .topic-select-container .label {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.create-group-case-container ion-content .content-wrapper .case-info-container .topic-select-container .label.error {
  color: var(--mi-red-color-2);
}
.create-group-case-container ion-content .content-wrapper .case-info-container .tip-wrap {
  padding: 0px 20px 15px;
}
.create-group-case-container ion-content .content-wrapper .case-info-container .tip-wrap .tip-container {
  background-color: var(--mi-light-purple-1-color);
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  padding: 5px;
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
}
.create-group-case-container ion-content .content-wrapper .case-info-container .tip-wrap .tip-container .text {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
.create-group-case-container ion-content .content-wrapper .case-info-container .tip-wrap .tip-container .text .bold-text {
  font-weight: bold;
  font-style: normal;
}
@media only screen and (max-width: 600px) {
  .create-group-case-container ion-content .content-wrapper .case-info-container .tip-wrap .tip-container {
    width: 100%;
  }
}
.create-group-case-container ion-content .content-wrapper .case-info-container .case-readiness-container {
  padding: 0px var(--create-group-case-padding-end) 15px var(--create-group-case-padding-start);
  background-color: var(--mi-white-color);
}
.create-group-case-container ion-content .content-wrapper .image-upload-container {
  grid-row: 2;
  grid-column: 1;
  background-color: var(--mi-grey-color-21);
}
.create-group-case-container ion-content .content-wrapper .case-text-details-container {
  padding: 23px var(--create-group-case-padding-end) 35px var(--create-group-case-padding-start);
}
.create-group-case-container ion-content .content-wrapper .case-text-details-container app-case-text-details ::ng-deep .case-text-details-container .section-container .title-container .title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-grey-color-16);
  margin: 0;
  text-align: left;
}
.create-group-case-container ion-content .content-wrapper .documents-container {
  padding: 0px var(--create-group-case-padding-end) 60px var(--create-group-case-padding-start);
}
.create-group-case-container .bottom-bar-container {
  grid-row: 3;
  grid-column: 1;
  padding-top: 3px;
  align-self: end;
  --background: var(--create-group-case-background-color);
}

.tutorial-step-2-custom-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: var(--mi-black-color);
  opacity: 0.44;
}

.hidden {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
