import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// services
import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { ToastMode, ToastService } from 'src/app/services/toast.service';

// models
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { ActionSource } from '../../services/yeti-protocol/tracking';

@Component({
  selector: 'app-settings-interests',
  templateUrl: './settings-interests.component.html',
  styleUrls: ['./settings-interests.component.scss'],
})
export class SettingsInterestsComponent implements OnInit {

  profile: UserProfile;
  interests: Array<string>;
  interestsListFormValid = false;
  saving = false;

  constructor(
    private modalController: ModalController,
    private toast: ToastService,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  ngOnInit(): void {
    this.authService.userProfileAsObservable.subscribe(
      profile => {
        this.profile = profile;
        this.profile.interests = profile.interests || [];
        this.interests = this.profile.interests || [];
      }
    );
  }

  selectedInterestsChanged(selectedInterests: Array<string>): void {
    this.interests = selectedInterests;
  }

  onClose(): void {
    this.modalController.dismiss(null, 'cancel');
  }

  saveInterests(): void {
    this.saving = true;
    this.authService.updateProfile(this.contextService.currentContext.key, { interests: this.interests },
      false, ActionSource.userProfileSettings)
      .then(profile => {
        this.toast.show('app.userProfile.Settings.activity-tracking-updated');
        this.profile = profile;
        this.saving = false;
      })
      .catch(err => {
        console.error(err);
        this.toast.showWithMessage(err, null, ToastMode.ERROR);
        this.saving = false;
      });
  }

  interestsListFormValidStatusChange(interestsListFormValid: boolean): void {
    this.interestsListFormValid = interestsListFormValid;
  }
}
