// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --icon-size: 22px;
  --margin-bottom: 0px;
  font-size: var(--icon-size);
  height: var(--icon-size);
  margin-bottom: var(--margin-bottom);
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-icon {
  height: var(--icon-size) !important;
  width: var(--icon-size) !important;
}

.rounded-edges {
  border-radius: 50%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
