import { InjectionToken } from '@angular/core';
import { JoinMyAOObjectXServiceInterface } from '../join-myao-object-x-service/join-myao-object-x.service.interface';
import { JoinMYAOObjectTypes } from '../join-myao.model';

export interface JoinMyAOObjectServiceInterface extends JoinMyAOObjectXServiceInterface {

  objectType: JoinMYAOObjectTypes;

  setObjectType(objectType: JoinMYAOObjectTypes): void;
}

export const JOIN_MYAO_OBJECT_SERVICE = new InjectionToken<JoinMyAOObjectServiceInterface>('join-myao-object.service');
