import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import appConfig from 'src/config/config';
import { AppTranslationService } from './app-translation.service';

export interface Profession {
  key: string;
  label: string;
  icon: string;
}

export type Professions = Array<Profession>

@Injectable({
  providedIn: 'root'
})
export class ProfessionsService {
  appConfig = appConfig;
  professionsList: Professions = null;
  constructor(private appTranslation: AppTranslationService) { }

  get professions(): Observable<Professions> {
    return from(this.loadProfessions());
  }

  getProfessions(professionKeys: Array<string>): Observable<Professions> {
    return this.professions.pipe(
      map(professions => {
        return professions.filter(profession => {
          return professionKeys.includes(profession.key);
        })
      })
    )
  }

  private loadProfessions(): Promise<Professions>{
    if(this.professionsList){
      return Promise.resolve(this.professionsList);
    }
    const keys = this.appConfig.professions.map(p => {
      return `app.professions.${p.labelKey}`;
    });
    return this.appTranslation.get(keys)
      .then((professions: any) => {

        this.professionsList = this.appConfig.professions.map(p => {
          return {
            key: p.key,
            label: professions[`app.professions.${p.labelKey}`],
            icon: p.icon
          };
        });
        return this.professionsList;
      });
  }
}
