import { Routes } from '@angular/router';

export const rootRoutes: Routes = [
  {
    path: 'spine',
    loadChildren: () => import('./spine.module').then(m => m.SpineModule)
  },
  {
    path: 'spine',
    loadChildren: () => import('./spine-auxiliary.module').then(m => m.SpineAuxiliaryModule),
    outlet: 'dialog'
  }
];

export const navigationTabsRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./spine-tabs.module').then(m => m.SpineTabsModule)
  }
];
