// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --content-padding: 20px;
}

.customize-feed-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  row-gap: 20px;
}
.customize-feed-container .header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  background-color: var(--mi-white-color);
}
.customize-feed-container .header app-top-bar {
  --top-bar-padding-top: 30px;
  --top-bar-padding-bottom: 12px;
  --top-bar-padding-start: 23px;
  --top-bar-padding-end: 23px;
}
.customize-feed-container .header app-top-bar ::ng-deep ion-header::after {
  bottom: -90px;
}
.customize-feed-container .header .tabs-container {
  display: inline-block;
  margin: 0 auto;
}
.customize-feed-container .content {
  grid-row: 2;
  grid-column: 1;
}
.customize-feed-container .content .tab-content {
  width: 100%;
  height: 100%;
  display: none;
}
.customize-feed-container .content .tab-content.active {
  display: block;
}
.customize-feed-container .content .interests-container .interests-list {
  width: 100%;
  height: 100%;
}
.customize-feed-container .content .interests-container .interests-list ion-content .wrapper .select-all-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 15px;
  font-family: Suisse;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  color: var(--mi-primary-color);
}
.customize-feed-container .content .interests-container .interests-list ion-content .wrapper .select-all-container span {
  cursor: pointer;
}
.customize-feed-container .content .interests-container .interests-list ion-content .wrapper ion-list.list {
  padding-top: 0px;
}
.customize-feed-container .content .journals-container .journals-list, .customize-feed-container .content .journals-container .people-list, .customize-feed-container .content .people-container .journals-list, .customize-feed-container .content .people-container .people-list {
  width: 100%;
  height: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
