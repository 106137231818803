import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

// based on https://www.npmjs.com/package/ngx-global-events

export enum GlobalSubjects {
  HIDE_HEADER_AND_TABS = 'hide-header-and-tabs' // true/false
}

@Injectable({
  providedIn: 'root'
})
export class GlobalSubjectsService {

  _subjects: Record<string, Subject<any>> = {};

  emit(eventName: string, data: any = null): void {
    if(eventName in this._subjects){
      this._subjects[eventName].next(data);
    }
  }

  on<T>(eventName: string): Observable<T> {
    if(! (eventName in this._subjects)){
      this._subjects[eventName] = new Subject<T>();
    }
    return this._subjects[eventName];
  }
}
