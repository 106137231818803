import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { DedicatedSearch } from 'src/app/services/dedicated-search/dedicated-search.interface';
import { GroupVisibility } from 'src/app/services/groups/group.model';
import { SearchService } from 'src/app/services/search.service';
import { GroupSearch, SearchResult, SearchSuccessResponse } from 'src/app/services/yeti-protocol/search';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

const PAGE_SIZE = 20;

@Component({
  selector: 'app-dedicated-search-groups',
  templateUrl: './dedicated-search-groups.component.html',
  styleUrls: ['./dedicated-search-groups.component.scss'],
})
export class DedicatedSearchGroupsComponent implements DedicatedSearch {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  searchTerm: string;
  searching: boolean;
  searchResult: Array<SearchResult>;
  searchTotalCount: number;
  GroupVisibility = GroupVisibility;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private modalController: ModalController
  ) { }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.searchGroups(searchTerm, 0);
  }

  searchGroups(searchTerm: string, start: number): void {

    if (start === 0) {
      this.searchResult = null;
    }

    this.searching = true;
    this.searchService.search(searchTerm, null, 'group', start, PAGE_SIZE)
      .then((searchSuccessResponse: SearchSuccessResponse) => {

        if (!this.searchResult || !this.searchResult.length) {
          this.searchResult = searchSuccessResponse?.result;
        } else if (this.searchResult?.length) {
          this.searchResult[0].searchResultItems =
            [...this.searchResult[0].searchResultItems, ...searchSuccessResponse?.result[0].searchResultItems];
        } else {
          this.searchTotalCount = 0;
          this.searchResult = null;
        }

        if (searchSuccessResponse.result.length) {
          this.searchTotalCount = searchSuccessResponse.result?.[0]?.totalCount;
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.searching = false;
        this.completeInfiniteScroll();
      });
  }

  get showStartState(): boolean {
    return !this.searchTerm;
  }

  get showEmptyState(): boolean {
    return !this.searching && this.searchTotalCount === 0;
  }

  get canLoadMore(): boolean {

    if (this.searchResult?.[0]?.searchResultItems?.length < this.searchTotalCount) {
      return true;
    }

    return false;
  }

  loadMore(): void {

    if (!this.canLoadMore) {
      this.disableInfiniteScroll(true);
      return;
    }

    const totalLoadedItems = this.searchResult?.[0]?.searchResultItems?.length || 0;

    const pageIndex = Math.floor(totalLoadedItems / PAGE_SIZE);
    this.searchGroups(this.searchTerm, pageIndex);
  }

  completeInfiniteScroll(): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.complete();
    }
  }

  goToGroup(group: GroupSearch): void {
    if (group?.appId && group?._id) {
      this.modalController.dismiss();
      const url = '/' + [group?.appId, 'group', group?._id].join('/');
      this.router.navigate([url], { queryParams: { source: ActionSource.searchResult } });
    }
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

}
