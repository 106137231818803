import { Component, ElementRef, EventEmitter, Inject, Input, Output } from '@angular/core';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
})
export class SearchFieldComponent {

  @Input() asButton: boolean;
  @Input() placeholder: string;
  @Input() minQueryLength = 1;

  @Output() search: EventEmitter<string> = new EventEmitter();

  searchTerm: string;
  shouldFocus: boolean;
  searchEnterBtnHit = false;
  preventBlurAction: boolean;

  constructor(
    private el: ElementRef,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  setEmptySearchState(event: Event): void {

    this.searchTerm = '';
    this.stopEventPropagation(event);
    this.focusSearchField();
  }

  searchInputFieldBlur(): void {

    if (!this.searchTerm || this.searchTerm === '') {
      this.searchTerm = null;
    }
  }

  clearSearchField(event: Event): void {

    this.uiUtilsService.stopEventPropagation(event);
    this.setEmptySearchState(event);
  }

  searchValueChanged(): void {

  }

  searchEnterHit(): void {
    this.searchEnterBtnHit = true;

    if (this.showSearchButton) {
      this.emitSearch();
    }
  }

  searchFieldClicked(): void {

    if (this.asButton) {
      this.emitSearch();
      return;
    }

    this.focusSearchField();

    this.searchTerm = this.searchTerm && this.searchTerm.length > 0 ? this.searchTerm : '';
    this.focusSearchField();
  }

  get showSearchButton(): boolean {
    if (this.searchTerm && this.searchTerm.trim().length > 0) {
      return true;
    }

    return false;
  }

  searchClicked(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.emitSearch();
  }

  stopEventPropagation(event: Event): void {
    if (event) {
      this.uiUtilsService.stopEventPropagation(event);
    }
  }

  emitSearch(): void {
    this.search.emit(this.searchTerm);
  }

  private focusSearchField(): void {
    setTimeout(() => {
      this.el.nativeElement.querySelector('.search-input-field').focus();
    }, 300);
  }

  get disableSearchClick(): boolean {

    if (!this.minQueryLength) {
      return false;
    }

    if (!this.searchTerm || this.searchTerm?.length < this.minQueryLength) {
      return true;
    }

    return false;
  }

}
