import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import appConfig from 'src/config/config';
import { Platform as ConfigPlatform } from 'src/config/config.model';
import { Responsive3ColumnsMode } from './responsive-3-column-mode.enum';
import { ResponsiveMode } from './responsive-mode.enum';

const LIMITS = {
  MIN_DESKTOP_WIDTH: 760,
  // IMPORTANT: keep in sync with src/app/components/three-column-layout/three-column-layout.component.scss
  MAX_MAIN_COLUMN_WIDTH: 560,
  MAX_WIDTH: 1090
};


interface ResponsiveUtilsServiceConfig {
  platform: ConfigPlatform;
}

@Injectable({
  providedIn: 'root'
})
export class ResponsiveUtilsService {

  config: ResponsiveUtilsServiceConfig = appConfig;

  constructor(private platform: Platform) { }

  get mode(): ResponsiveMode{
    const screenWidth = this.platform.width();
    const isBrowser = this.config.platform === ConfigPlatform.BROWSER || this.config.platform === ConfigPlatform.PWA;
    if(isBrowser && screenWidth >= LIMITS.MIN_DESKTOP_WIDTH){
      return ResponsiveMode.Desktop;
    }
    return ResponsiveMode.Mobile;
  }

  get isDesktop(): boolean {
    return this.mode === ResponsiveMode.Desktop;
  }

  get resize(): Subject<void> {
    return this.platform.resize;
  }

  get threeColumnsMode(): Responsive3ColumnsMode{
    const screenWidth = this.platform.width();
    if(screenWidth >= LIMITS.MAX_WIDTH){
      return Responsive3ColumnsMode.LargeScreen;
    }
    if(screenWidth <= LIMITS.MAX_MAIN_COLUMN_WIDTH){
      return Responsive3ColumnsMode.SmallScreen;
    }
    return Responsive3ColumnsMode.MainColumnOnly;
  }
}

export const forTests = {LIMITS};
