import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {Journal} from 'src/app/services/yeti-protocol/journal';
import appConfig from '../../../../config/config';

// services
import {JournalsService} from 'src/app/services/journals/journals.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-journal-highlights-journal-item',
  templateUrl: './journal-highlights-journal-item.component.html',
  styleUrls: ['./journal-highlights-journal-item.component.scss'],
})
export class JournalHighlightsJournalItemComponent {
  @Input() item: Journal;
  @Output() openJournal: EventEmitter<string> = new EventEmitter();

  constructor(
    private journalsService: JournalsService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) {
  }

  getImageUrl(item: Journal): string {
    return item?.img ? appConfig.webUrl.slice(0, -1) + item?.img : null;
  }

  onFollowUnfollowJournal(journalId: string, follow: boolean, event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.journalsService.updateUserFollowingJournals(journalId, follow).then(() => {
      this.item.isFollowed = follow;
    })
  }

  onOpenJournalPage(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.openJournal.emit(this.item.id);
  }
}
