// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stream-filters-dialog-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 15px;
  padding: 30px;
}
.stream-filters-dialog-container .title-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
}
.stream-filters-dialog-container .title-container .title {
  grid-row: 1;
  grid-column: 1;
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
  font-size: var(--font-size-large);
  color: var(--mi-secondary-color-2);
  margin: 0;
}
.stream-filters-dialog-container .title-container .close-button-container {
  grid-row: 1;
  grid-column: 2;
  justify-self: end;
}
.stream-filters-dialog-container .title-container .close-button-container app-icon-button {
  --icon-button-background-width: 22px;
  --icon-button-background-height: 22px;
  --icon-button-background-color: none;
  --icon-button-background-paddng: 0;
  --icon-button-border-radius: 0%;
  --icon-button-icon-size: 20px;
  --icon-button-icon-color: var(--mi-secondary-color-2);
}
.stream-filters-dialog-container .content-container {
  grid-row: 2;
  grid-column: 1;
}
.stream-filters-dialog-container .content-container ion-content {
  height: 100%;
  min-height: 86px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
