import { Routes } from '@angular/router';

export const rootRoutes: Routes = [
  {
    path: 'recon',
    loadChildren: () => import('./recon.module').then(m => m.ReconModule)
  },
  {
    path: 'recon',
    loadChildren: () => import('./recon-auxiliary.module').then(m => m.ReconAuxiliaryModule),
    outlet: 'dialog'
  }
];

export const navigationTabsRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./recon-tabs.module').then(m => m.ReconTabsModule)
  }
];
