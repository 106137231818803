// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-header {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  justify-items: start;
  align-items: start;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.post-header.without-buttons-container {
  grid-template-columns: min-content auto;
}
.post-header .user-profile-image-container {
  grid-row: 1;
  grid-column: 1;
  justify-self: center;
  align-self: center;
}
.post-header .user-profile-image-container app-user-profile-image {
  --user-image-size: 40px;
}
.post-header .user-profile-image-container .ao-member-symbol-component {
  position: absolute;
  top: 40px;
  left: 20px;
}
.post-header .header-container-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.post-header .header-container-layout .follow-button-container {
  align-self: center;
}
.post-header .header-container-layout .text-container {
  width: 100%;
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
}
.post-header .header-container-layout .text-container .user-fullname-container {
  width: 100%;
  justify-self: start;
  overflow: hidden;
}
.post-header .header-container-layout .text-container .user-fullname-container .user-fullname {
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}
.post-header .header-container-layout .text-container .user-fullname-container .crown-user {
  margin-left: 5px;
}
.post-header .header-container-layout .text-container .user-country-container {
  width: 100%;
  justify-self: start;
  overflow: hidden;
  margin-bottom: 2px;
}
.post-header .header-container-layout .text-container .user-country-container .country {
  grid-row: 1;
  grid-column: 1;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: 1;
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.post-header .header-container-layout .text-container .user-country-container.grid {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.post-header .header-container-layout .text-container .user-country-container.grid .country {
  grid-row: 1;
  grid-column: 1;
}
.post-header .header-container-layout .text-container .post-date-container {
  width: 100%;
  justify-self: start;
  overflow: hidden;
}
.post-header .header-container-layout .text-container .post-date-container .post-date {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: 1;
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.post-header .header-container-layout .text-container .post-date-container .post-date span.parent-name-container .parent-name {
  cursor: pointer;
  color: var(--mi-primary-color);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.post-header .header-container-layout .text-container .top-group-contributor {
  font-size: 10px;
  line-height: 18px;
  color: var(--mi-color-text-lite);
}
.post-header .buttons-container {
  grid-row: 1;
  grid-column: 3;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.post-header .buttons-container .translate-icon-container {
  justify-self: end;
  align-self: center;
  color: var(--mi-grey-color-6);
}
.post-header .buttons-container .translate-icon-container .text {
  margin: 0;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
