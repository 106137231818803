// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --image-width: 80px;
  --image-height: 80px;
}

app-mi-card {
  --mi-card-margin-top: 0;
  --mi-card-margin-bottom: 0;
  --mi-card-margin-start: 0;
  --mi-card-margin-end: 0;
  --mi-card-background-color: var(--mi-light-purple-1-color);
}
app-mi-card ::ng-deep ion-card {
  box-shadow: 0 3px 4px var(--mi-shadow-color);
}
app-mi-card.mobile-view ::ng-deep ion-card {
  padding: 0px;
}

.content-container {
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.content-container .text-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.content-container .text-container .title-wrap {
  display: flex;
  align-items: center;
}
.content-container .text-container .title-wrap span {
  color: var(--mi-grey-color-13);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}
.content-container .text-container .title-wrap span app-icon {
  display: inline;
}
.content-container .text-container .title-wrap app-icon {
  --icon-size: 12px;
  margin-left: 5px;
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.content-container .text-container .code-wrap {
  color: var(--mi-grey-color-16);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.content-container .text-container .chip-wrap {
  padding-bottom: 10px;
  text-align: left;
}
.content-container .text-container .chip-wrap app-text-chip {
  --color: var(--mi-secondary-color-2);
  --background-color: var(--mi-blue-color-4);
  --font-size: var(--font-size-tiny);
}
.content-container .image-container app-surgery-reference-image {
  --surgery-reference-image-size: var(--image-width);
}

.description-container .title {
  color: var(--mi-grey-color-16);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  line-height: var(--font-line-height-regular);
}
.description-container .text {
  margin-top: 12px;
}
.description-container .text app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 54px;
}
.description-container .text app-multiline-text-with-ellipsis ::ng-deep .multiline-text-container .text {
  color: var(--mi-black-color);
  font-size: var(--font-size-small);
}
.description-container .text .full-description {
  color: var(--mi-black-color);
  font-size: var(--font-size-small);
}

.button-container {
  margin-top: 35px;
}
.button-container .add-btn {
  width: 100%;
  --border-radius: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
