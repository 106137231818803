import { ActionSheetController } from '@ionic/angular';
import { Subject } from 'rxjs';

// models
import { Article } from './my-stream.model';
import { GroupListItem } from './yeti-protocol/chatter-api';
import { AOEvent } from './yeti-protocol/event';
import { OrcIdArticle } from './yeti-protocol/article';

export enum MiCardActionsObjectType {
  article = 'article',
  orcIdArticle = 'orcIdArticle',
  group = 'group'
}

export enum ArticleActions {
  bookrmark = 'bookmark',
  translate = 'translate'
}

export enum OrcIdArticleActions {
  translate = 'translate'
}

export type ArticleActionsVisibility = {
  bookmark: boolean,
  translate: boolean
}

export type OrcIdArticleActionsVisibility = {
  translate: boolean
}

export type GroupActionsVisibility = {
  bookmark: boolean,
  translate: boolean
}

export type EventActionsVisibility = {
  bookmark: boolean,
  translate: boolean
}

export interface ActionSheetConfig {
  objectType: string,
  object: Article,
  actionsVisibilityConfig: ArticleActionsVisibility
}

export interface ActionSheetConfigOrcIdArticle {
  objectType: string,
  object: OrcIdArticle,
  actionsVisibilityConfig: OrcIdArticleActionsVisibility
}

export interface ActionSheetConfigGroups {
  objectType: string,
  object: GroupListItem,
  actionsVisibilityConfig: GroupActionsVisibility
}

export interface ActionSheetConfigEvents {
  objectType: string,
  object: AOEvent,
  actionsVisibilityConfig: EventActionsVisibility
}

export interface ActionSheetActionButtonConfig {
  text: string,
  role: string,
  icon: string,
  handler: () => void
}

export interface ActionSheetActionButtonsConfig {
  buttons: Array<ActionSheetActionButtonConfig>
}

export interface ActionSheetActionsConfig extends ActionSheetActionButtonsConfig {
  header: string,
  cssClass: string
}

export class MiCardActionsService {

  actionClickedSubject: Subject<string> = new Subject();

  constructor(
    private actionSheetController: ActionSheetController
  ) { }

  showActionSheet(actionSheetConfig: ActionSheetConfig | ActionSheetConfigGroups | ActionSheetConfigOrcIdArticle): void {
    const actionSheetActionsConfig: ActionSheetActionsConfig = this.createActionSheetConfig(actionSheetConfig);
    this.createAndPresentActionSheet(actionSheetActionsConfig);
  }

  async createAndPresentActionSheet(actionSheetActionsConfig: ActionSheetActionsConfig): Promise<any> {
    const actionSheet = await this.actionSheetController.create({
      header: actionSheetActionsConfig?.header,
      cssClass: actionSheetActionsConfig?.cssClass,
      buttons: actionSheetActionsConfig?.buttons as Array<any>
    });
    await actionSheet.present();
  }

  private createActionSheetConfig(
    actionSheetConfig: ActionSheetConfig |
      ActionSheetConfigGroups |
      ActionSheetConfigOrcIdArticle): ActionSheetActionsConfig {
    switch (actionSheetConfig.objectType) {
      case MiCardActionsObjectType.article:
        return this.createArticleActionSheetConfig();
      case MiCardActionsObjectType.group:
        return this.createArticleActionSheetConfig();
      case MiCardActionsObjectType.orcIdArticle:
        return this.createOrcIdArticleActionSheetConfig();
      default:
        return null;
    }
  }

  private createArticleActionSheetConfig(): ActionSheetActionsConfig {
    return {
      header: 'Actions',
      cssClass: 'article-action-sheet',
      buttons: [
        {
          text: 'Bookmark',
          role: 'destructive',
          icon: 'md-icon-bookmark',
          handler: () => {
            this.actionClickedSubject.next(ArticleActions.bookrmark);
          }
        },
        {
          text: 'Translate',
          role: 'destructive',
          icon: 'md-icon-translate',
          handler: () => {
            this.actionClickedSubject.next(ArticleActions.translate);
          }
        }
      ]
    };
  }

  private createOrcIdArticleActionSheetConfig(): ActionSheetActionsConfig {
    return {
      header: 'Actions',
      cssClass: 'article-action-sheet',
      buttons: [
        {
          text: 'Translate',
          role: 'destructive',
          icon: 'md-icon-translate',
          handler: () => {
            this.actionClickedSubject.next(OrcIdArticleActions.translate);
          }
        }
      ]
    };
  }
}
