import { InjectionToken } from '@angular/core';
import {
  AddApproachResponse,
  AddFractureResponse,
  AddPreparationResponse,
  AddTreatmentResponse,
  ApproachesResponse,
  DeleteApproachResponse,
  DeleteFractureResponse,
  DeletePreparationResponse,
  DeleteTreatmentResponse,
  FracturesResponse,
  PreparationsResponse,
  TreatmentsResponse
} from '../yeti-protocol/surgery-reference';


export interface SurgeryReferenceServiceInterface {
  getFractures(
    caseId: string,
    start?: number,
    count?: number
  ): Promise<FracturesResponse>;
  getTreatments(
    moduleId: string,
    start?: number,
    count?: number
  ): Promise<TreatmentsResponse>;
  getApproaches(
    caseId: string,
    start?: number,
    count?: number
  ): Promise<ApproachesResponse>;
  getPreparations(
    caseId: string,
    start?: number,
    count?: number
  ): Promise<PreparationsResponse>;
  addFracture(caseId: string, fractureId: string): Promise<AddFractureResponse>;
  addTreatment(caseId: string, fractureId: string, treatmentId: string): Promise<AddTreatmentResponse>;
  addApproach(caseId: string, approachId: string): Promise<AddApproachResponse>;
  addPreparation(caseId: string, preparationId: string): Promise<AddPreparationResponse>;
  deleteFracture(caseId: string, fractureId: string): Promise<DeleteFractureResponse>;
  deleteTreatment(caseId: string, fractureId: string): Promise<DeleteTreatmentResponse>;
  deleteApproach(caseId: string): Promise<DeleteApproachResponse>;
  deletePreparation(caseId: string): Promise<DeletePreparationResponse>;
}

export const SURGERY_REFERENCE_SERVICE = new InjectionToken<SurgeryReferenceServiceInterface>('surgery-reference.service');
