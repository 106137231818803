// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --verification-header-padding-top: 0px;
  --verification-header-padding-bottom: 0px;
  --verification-header-padding-start: 0px;
  --verification-header-padding-end: 0px;
  --verification-header-header-image-width: 100%;
  --verification-header-header-image-height: 93px;
  --verification-header-image-width: 105px;
  --verification-header-image-height: 132px;
}

.verification-header-container {
  --row-1-height: calc(var(--verification-header-header-image-height) - calc(var(--verification-header-image-height) / 2));
  --row-2-and-3-height: calc(var(--verification-header-image-height) / 2);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: var(--row-1-height) var(--row-2-and-3-height) var(--row-2-and-3-height);
  position: relative;
  grid-row: 1;
  grid-column: 1;
}
.verification-header-container app-back-button {
  --icon-font-size: 22px;
  position: absolute;
  top: 0;
  left: 26px;
  padding-top: 12px;
}
.verification-header-container app-icon-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: none;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0%;
  --icon-button-icon-size: 14px;
  --icon-button-icon-color: var(--mi-white-color);
  position: absolute;
  top: 0;
  right: 26px;
  padding-top: 12px;
}
.verification-header-container .header-image-container {
  width: 100%;
  height: 93px;
  grid-column: 1;
  grid-row: 1/3;
}
.verification-header-container .header-image-container ion-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.verification-header-container .verification-image-container {
  width: 105px;
  height: 132px;
  grid-column: 1;
  grid-row: 2/4;
  justify-self: center;
}
.verification-header-container .verification-image-container ion-img {
  width: 100%;
  height: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
