import { base64 } from 'rfc4648';

export function addUnitToFileSize(fileSizeInBytes: number): string {
  if (!fileSizeInBytes) {
    return '';
  }

  const contentLengthInKB = Math.floor(fileSizeInBytes / 1000);
  const contentLengthInMB = Math.floor(contentLengthInKB / 1000);

  if (contentLengthInMB >= 1) {
    return `${contentLengthInMB} MB`;
  } else if (contentLengthInKB > 1) {
    return `${contentLengthInKB} KB`;
  } else {
    return `${Math.round(fileSizeInBytes)} B`;
  }
}

export function stripHtmlTagsFromString(html: string = ''): string {
  html = html.replace(/></g, '> <');
  html = html.replace(/<br>&nbsp;/g, ' ');
  html = html.replace(/\/r\/n/g, ' ');
  html = html.replace(/&nbsp;/g, ' ');
  html = html.replace(/<br>/g, ' ');
  return html.replace(/(<([^>]+)>)/ig, '');
}

export function roundTotalCount(count: number): string {
  if (count < 1000) {
    return count.toString();
  } else if (count < 10000) {
    return `${Math.round(count / 1000)}K`;
  } else {
    return '10K';
  }
}

export function ensureString(str: string): string {
  return str || '';
}

export function convertBase64ToBlob(dataURL: string, mimeType: string = 'image/jpeg'): Blob {
  const uint8Array = base64.parse(dataURL.split(',')[1], { loose: false });
  return new Blob([uint8Array], { type: mimeType });
}

export function isEmptyParagraphTagsAndAnchor(text: string | undefined): boolean {
  if (!text) {
    return true;
  }
  const removedAnchorTags: string = text.replace(/<a\b[^>]*>(.*?)<\/a>/g, '');
  return /^<p>\u200B*<\/p>$/.test(removedAnchorTags);
}

export function skipDuplicates(items:Array<string>):Array<string> {
  const lookup:Map<string, boolean> = new Map();
  const res:Array<string> = [];
  items.forEach(s => {
    if(lookup[s]){
      return;
    }
    lookup[s] = true;
    res.push(s);
  })
  return res;
}

export function toCamelCase(str: string): string {
  return str
    .toLowerCase()
    .replace(/[-_ ]+./g, (match) => match.charAt(match.length - 1).toUpperCase());
}
