// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.case-library-filter-dialog-container {
  width: 100%;
  height: 100%;
  padding: 25px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 19px;
}
.case-library-filter-dialog-container .close-button-container {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.case-library-filter-dialog-container .close-button-container .close-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: var(--mi-white-color);
  --icon-button-background-paddng: 0;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 24px;
  --icon-button-icon-color: var(--mi-secondary-color-2);
  outline: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 30px;
  width: 30px;
}
.case-library-filter-dialog-container .close-button-container .content {
  grid-row: 2;
  grid-column: 1;
}
.case-library-filter-dialog-container .close-button-container .content app-case-library-filter-selector ::ng-deep .case-library-filter-selector-container .filters-container .filters-grid ion-content {
  height: 120px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
