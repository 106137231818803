import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

// models
import { Conversation, ConversationType } from 'src/app/services/yeti-protocol/conversation';

// services
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { FromSecondsMessengerPipeData } from 'src/app/pipes/from-seconds-messenger.pipe';
import { ConversationInfo } from '../messenger.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessengerService } from 'src/app/services/messenger/messenger.service';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-active-conversation-list-item',
  templateUrl: './active-conversation-list-item.component.html',
  styleUrls: ['./active-conversation-list-item.component.scss'],
})
export class ActiveConversationListItemComponent implements OnInit, OnDestroy {

  @Input() conversation: Conversation;
  @Input() source: ActionSource;

  @Output() openConversation: EventEmitter<ConversationInfo> = new EventEmitter<ConversationInfo>();

  timeAgo: BehaviorSubject<FromSecondsMessengerPipeData> = new BehaviorSubject({ age: '', date: new Date().toISOString() });

  timerSubscription: Subscription;

  private user: UserProfile;
  private userProfileSubscription: Subscription;

  constructor(
    private messengerService: MessengerService,
    private appTranslationService: AppTranslationService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.user = userProfile;
    });
    this.timerSubscription = timer(0, 1000).subscribe(() => {
      if (this.conversation) {
        this.timeAgo.next({
          age: this.conversation?.age || '',
          date: this.conversation.createdDate || new Date().toISOString()
        })
      }
    });
  }

  ngOnDestroy(): void {
    this.timerSubscription?.unsubscribe();
    this.userProfileSubscription?.unsubscribe();
  }

  get message(): string {

    if (!this.conversation?.message) {
      return '';
    }

    return stripHtmlTagsFromString(this.conversation?.message);
  }

  get showPhotoAttachmentInfo(): boolean {
    return this.conversation?.type === ConversationType.IMAGE;
  }

  get showDocumentAttachmentInfo(): boolean {
    return this.conversation?.type === ConversationType.ATTACHMENT;
  }

  get showSharedCaseInfo(): boolean {
    return this.conversation?.type === ConversationType.CASE;
  }

  get showRequestInfo(): boolean {
    return this.conversation?.type === ConversationType.REUEST;
  }

  get showLastMessage(): boolean {
    return !this.showPhotoAttachmentInfo && !this.showDocumentAttachmentInfo && !this.showRequestInfo;
  }

  onClick(): void {
    return this.openConversation.emit({
      id: this.conversation._id,
      connectionType: this.conversation.connectionType
    });
  }
}
