import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperContentDirective } from '../../directives/swiper-content.directive';
import { Platform as AppPlatform } from 'src/config/config.model';
import appConfig from 'src/config/config';
import { waitTime } from 'src/app/services/utils/utils';

export interface HorizontalListLoadMoreData {
  page: number;
  slideToSlideIndex: number;
  swiper: Swiper;
}

interface HorizontalListComponentConfig {
  platform: AppPlatform;
}

@Component({
  selector: 'app-horizontal-list',
  templateUrl: './horizontal-list.component.html',
  styleUrls: ['./horizontal-list.component.scss'],
})
export class HorizontalListComponent implements AfterViewInit {

  @Input() swiperLogic: SwiperLogic;
  @Input() loading: boolean;
  @Input() itemsCount: number;
  @Input() totalItemsCount: number;
  @Input() pageItemsCount: number;
  @Input() spaceBetween: number;

  @Output() loadMore: EventEmitter<HorizontalListLoadMoreData> = new EventEmitter();
  @Output() refresh: EventEmitter<void> = new EventEmitter();

  config: HorizontalListComponentConfig = appConfig;

  ngAfterViewInit(): void {
    waitTime(500).then(() => {
      if (this.swiperLogic?.afterViewInit) {
        this.swiperLogic?.afterViewInit();
      }
    });
  }

  @ContentChildren(SwiperContentDirective, {
    descendants: true,
  })
  contents!: QueryList<SwiperContentDirective>;

  get swiperConfig(): SwiperOptions {
    return this.swiperLogic?.config;
  }

  onSwiper(swiper: Swiper): void {

    if (this.spaceBetween || this.spaceBetween === 0) {
      swiper.params.spaceBetween = this.spaceBetween;
    }

    swiper.on('touchEnd', (event: any) => {

      if (event.progress > 1.05) {
        this.loadMoreItems({
          page: 0,
          slideToSlideIndex: event.activeIndex,
          swiper: swiper
        });
      }

      if (event.progress < -0.05) {
        this.refreshList();
      }
    });
  }

  loadMoreItems(data: HorizontalListLoadMoreData): void {

    if (this.itemsCount >= this.totalItemsCount) {
      return;
    }

    const page = Math.floor(this.itemsCount / this.pageItemsCount);
    data.page = page;

    this.loadMore.emit(data);
  }

  refreshList(): void {
    this.refresh.emit();
  }

  get isMobile(): boolean {
    return this.config.platform === AppPlatform.ANDROID || this.config.platform === AppPlatform.IOS;
  }
}
