import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IonRefresher } from '@ionic/angular';
import { Group, GroupListItem, GroupListSuccessResponse, GroupsListResponse } from '../../services/yeti-protocol/chatter-api';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { VerticalListLoadMoreData } from 'src/app/components/vertical-list/vertical-list.component';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-groups-discover',
  templateUrl: './groups-discover.component.html',
  styleUrls: ['./groups-discover.component.scss'],
})
export class GroupsDiscoverComponent implements OnInit {

  @Output() hideListTop: EventEmitter<boolean> = new EventEmitter();

  recommendedGroupsToJoinLoading: boolean;
  totalItemsCount: number;
  recommendedGroupsToJoin: Array<Group>;
  readonly start = 0;
  readonly count = 20;

  constructor(
    private groupsService: GroupsService,
    private toast: ToastService,
    private appNavController: AppNavController
  ) { }

  ngOnInit(): void {
    this.getRecommendedGroups().then(res => {
      this.totalItemsCount = res?.total || 0;
      this.recommendedGroupsToJoin = res?.groups || [];
    });
  }

  async refreshRecommendedGroups(refresher: IonRefresher): Promise<void> {
    this.getRecommendedGroups().then(res => {
      this.totalItemsCount = res?.total || 0;
      this.recommendedGroupsToJoin = res?.groups || [];
    }).finally(() => {
      refresher.complete();
    });
  }

  async loadMoreRecommendedGroups(verticalListLoadMoreData: VerticalListLoadMoreData): Promise<void> {

    const start = Math.floor(this.recommendedGroupsToJoin.length / this.count);

    this.getRecommendedGroups(start).then(res => {
      this.totalItemsCount = res?.total || 0;
      this.recommendedGroupsToJoin = [...this.recommendedGroupsToJoin, ...res?.groups || []];
    }).finally(() => {
      verticalListLoadMoreData.infiniteScroll.complete();
    });
  }

  getRecommendedGroups(start: number = this.start): Promise<{ total: number, groups: Array<GroupListItem> }> {
    if (!this.groupsService) {
      return null;
    }
    this.recommendedGroupsToJoinLoading = true;
    return this.groupsService.getRecommendedGroups(start, this.count)
      .then((response: GroupsListResponse) => {
        if (response as GroupListSuccessResponse) {
          return {
            total: (response as GroupListSuccessResponse).totalItemsCount,
            groups: (response as GroupListSuccessResponse).result
          };
        }
        return null;
      }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showError(err?.error?.error?.message?.errfor?.message);
        }
        return null;
      })
      .finally(() => {
        this.recommendedGroupsToJoinLoading = false;
      });
  }

  openGroup(groupId: string): void {
    this.appNavController.openGroup(groupId, this.source);
  }

  onScrolled(scrolled: boolean): void {
    this.hideListTop.emit(scrolled);
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  get source(): ActionSource {
    return ActionSource.groupsPage + ActionSource.discoverTab as ActionSource;
  }

}
