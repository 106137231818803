import { Component, Input } from '@angular/core';

export enum VIEW_TYPE {
  LIMITED = 'limited',
  FULL = 'full'
}

@Component({
  selector: 'app-message-container',
  templateUrl: './message-container.component.html',
  styleUrls: ['./message-container.component.scss'],
})
export class MessageContainerComponent {
  @Input() title;
  @Input() message;
  @Input() viewType: VIEW_TYPE;
  VIEW_TYPE = VIEW_TYPE;
}
