import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CreateContentParentOptionsComponent, SelectableParent }
  from '../../components/create-content-parent-options/create-content-parent-options.component';
import { IconButtonModule } from 'src/app/modules/icon-button/icon-button.module';
import { CreateContentStrategy } from 'src/app/services/create-content/create-content';

@Component({
  selector: 'app-create-content-parent-select-dialog',
  templateUrl: './create-content-parent-select-dialog.component.html',
  styleUrls: ['./create-content-parent-select-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    CreateContentParentOptionsComponent,
    IconButtonModule
  ]
})
export class CreateContentParentSelectDialogComponent implements OnInit {

  @Input() createContentStrategy: CreateContentStrategy;
  @Input() selectedParent: SelectableParent;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit(): void { }

  async close(): Promise<void> {

    try {
      const dialog = await this.modalController.getTop();
      if (dialog) {
        this.modalController.dismiss(this.selectedParent);
      }
    } catch (err) {
      console.error(err);
    }
  }

}
