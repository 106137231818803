import { Component, OnInit, Input, Inject } from '@angular/core';

import { BaseUserProfile, NUMBER_USER_IMG_BUBBLES_EVENT_PAGE } from 'src/app/modules/users/users.model';
import { EventsService } from 'src/app/services/events/events.service';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI } from 'src/app/services/dialogs/dialogs.ui.interface';
import {
  AOEvent,
  AOEventParticipant,
  AOEventParticipantsSuccessResponse,
  AOEventParticipantType
} from 'src/app/services/yeti-protocol/event';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';

@Component({
  selector: 'app-connect-with-ao-event-co-participants-card',
  templateUrl: './connect-with-ao-event-co-participants-card.component.html',
  styleUrls: ['./connect-with-ao-event-co-participants-card.component.scss'],
})
export class ConnectWithAoEventCoParticipantsCardComponent implements OnInit {

  @Input() aoEvent: AOEvent;

  aoEventAttendees: Array<AOEventParticipant> = [];
  totalAoEventAttendees: number;

  constructor(
    private eventsService: EventsService,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
    private navController: NavControllerService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private appNavController: AppNavController
  ) { }

  ngOnInit(): void {
    this.fetchAoEventAttendees();
  }

  get participantsUsers(): Array<BaseUserProfile> {

    if (!this.aoEventAttendees || this.aoEventAttendees?.length === 0) {
      return [];
    }

    return this.aoEventAttendees.slice(0, NUMBER_USER_IMG_BUBBLES_EVENT_PAGE)
      .map((participant: AOEventParticipant) => {
        return {
          userId: participant?.participantInfo?.userId,
          profileImageUrl: participant?.participantInfo?.profileImageUrl
        };
      });
  }

  fetchAoEventAttendees(): void {
    this.eventsService.getAOEventAttendees(this.aoEvent?._id, 0, 5)
      .then((aoEventParticipantsSuccessResponse: AOEventParticipantsSuccessResponse) => {
        this.aoEventAttendees = aoEventParticipantsSuccessResponse?.result;
        this.totalAoEventAttendees = aoEventParticipantsSuccessResponse?.totalItemsCount;
      })
  }

  seeAllParticipants(): void {
    this.dialogs.showAoEventParticipantsDialog(this.aoEvent?._id, AOEventParticipantType.PARTICIPANT)
      .then(res => {
        if (res.action === 'sendMessageTo') {
          const url = `/${this.contextService.currentContext.key}/chat/${res.data}`;
          this.navController.navigateForward(url);
        }
        if (res.action === 'openPublicUserProfile') {
          this.appNavController.openPublicUserProfile(res.data);
        }
      });
  }

}
