import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

// models
import { UserProfile } from '../services/yeti-protocol/auth/mi';
import { ActionTracked, GenericTrackingParam, TrackingRequest } from '../services/yeti-protocol/tracking';

// services
import { AuthService } from '../services/auth/auth.service';
import { TRACKING_SERVICE, TrackingService } from '../services/tracking/tracking.model';

@Injectable({
  providedIn: 'root'
})
export class EventDetailPageGuard  {

  constructor(
    private router: Router,
    private authService: AuthService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) {}

  async canActivate(
    _route: ActivatedRouteSnapshot,
    ): Promise<boolean> {

      const context = await this.getLoggedInUserDefaultContext();
      const aoEventId = _route?.params?.aoEventId;

      if(!context) {
        this.trackPageOpen(null, aoEventId, _route.queryParams);
        return this.router.navigateByUrl('/auth', { state: { preventDeepLinkCheck: true } });
      }

      this.trackPageOpen(context, aoEventId, _route.queryParams);

      if (!aoEventId) {
        return this.router.navigateByUrl(`/${context}/events`);
      } else {
        return this.router.navigateByUrl(`/${context}/event/${aoEventId}`);
      }
  }

  getLoggedInUserDefaultContext(): Promise<string> {
    return new Promise((resolve) => {
      this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {

        if (!userProfile) {
          resolve(null);
        }

        resolve(userProfile?.homeDevision);
      });
    });
  }

  trackPageOpen(context: string, aoEventId: string, queryParams: any = {}): void {

    const paramsToTrack: GenericTrackingParam = {
      objectId: aoEventId,
      objectType: 'eventLink',
      objectTitle: 'eventLinkClicked',
      ...queryParams
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.clicked,
      params: paramsToTrack,
    };

    if (context) {
      trackData.appId = context;
    }

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track event detail page open action');
    });
  }

}
