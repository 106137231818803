// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-header {
  padding-left: 22px;
}
.list-header .list-header-content {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 5px;
  padding: 10px 0;
}
.list-header .list-header-content .title h5 {
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-1);
  font-weight: var(--font-weight-medium);
  margin: 0;
}
.list-header .list-header-content .subtitle p {
  margin: 0;
}

ion-list ion-item ion-thumbnail {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
}
ion-list ion-item ion-thumbnail .context-icon-image {
  width: 20px;
  height: 20px;
}
ion-list ion-item.selected ion-thumbnail {
  opacity: 1;
}
ion-list.not-selectable ion-item ion-thumbnail {
  opacity: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
