import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

// services
import { InterestsService } from '../../services/interests.service';

// models
import { Group } from '../../services/groups/group.model';
import { Interest } from '../../services/interests.model';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import {
  GroupTopContributorsDialogComponent
} from '../../dialogs/group-top-contributors-dialog/group-top-contributors-dialog.component';
import { ActionSource, ActionTracked } from '../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';

@Component({
  selector: 'app-group-public',
  templateUrl: './group-public.component.html',
  styleUrls: ['./group-public.component.scss'],
})
export class GroupPublicComponent implements AfterViewInit {

  @Input() group: Group;
  @Input() showedAsDialog: boolean;

  @Output() refreshGroup: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();

  interests: Array<Interest> = [];
  showTopContributorsList = true;

  readonly TOP_CONTRIBUTORS_MAX_ITEMS_SHOW = 5;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private interestsService: InterestsService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private modalController: ModalController,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  ngAfterViewInit(): void {
    this.mapInterestIdsToInterestNames();
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  onBack(): void {
    if (this.showedAsDialog) {
      this.modalController.dismiss();
    } else {
      this.back.emit();
    }
  }

  onClose(): void {
    this.modalController.dismiss(null, 'cancel');
  }

  onTopContributorsEmptyListEmitted(): void {
    this.showTopContributorsList = false;
  }

  emitRefreshGroup(): void {
    this.refreshGroup.emit();
  }

  async onSeeAllTopContributors(): Promise<void> {
    this.trackAction('see-all', 'see-all', ActionTracked.clicked);
    const dialog = await this.modalController.create({
      component: GroupTopContributorsDialogComponent,
      cssClass: 'top-contributors-dialog',
      componentProps: {
        groupId: this.group?._id,
      }
    });
    this.modalController.dismiss();
    dialog.present();
  }

  get showAOMembersOnlyContainer(): boolean {
    return this.group?.AOMembersOnly;
  }

  private mapInterestIdsToInterestNames(): void {
    this.interestsService.getInterests(this.contextService.currentContext.key).then((interests: Array<Interest>) => {
      const interestsObj = {};

      interests.forEach((interest: Interest) => {
        interestsObj[interest.id] = interest;
      });

      this.group?.interests.forEach(interestId => {
        const interest: Interest = interestsObj[interestId];

        if (interest) {
          this.interests.push(interest);
        }
      });
    });
  }

  trackAction(objectId: string, objectType: string, objectTitle: string): void {
    this.trackingService.trackGenericClickedAction(objectId, objectType, objectTitle,
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error('Could not track click action.');
      });
  }

  get source(): ActionSource {
    return ActionSource.groupPublicDetails;
  }
}
