import { Component, ElementRef, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { ModalController, ViewDidEnter } from '@ionic/angular';

import { ImageEditorComponent } from '../image-editor/image-editor.component';
import { ImageEditorCommand } from '../image-editor/image-editor.model';
import { AttachmentMimeType } from 'src/app/services/attachments.model';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';
import { SwiperOptions } from 'swiper';
import { EditedImage } from './image-editor-dialog.model';
import { ImageCompressService } from 'src/app/modules/file-select/services/image-compress.service';
import { convertBase64ToBlob } from 'src/app/services/utils/string-utils';

export enum TrackImageEditOptions {
  edit = 'edit',
  save = 'save',
  cancel = 'cancel',
  undo = 'undo'
}
@Component({
  selector: 'app-edit-image-dialog',
  templateUrl: './image-editor-dialog.component.html',
  styleUrls: ['./image-editor-dialog.component.scss'],
})
export class ImageEditorDialogComponent implements OnDestroy, ViewDidEnter {
  @ViewChild('imageEditor') imageEditor: ImageEditorComponent;
  @Input() imageFile: File;

  readonly menuButtons = [
    {
      command: 'crop',
      icon: 'img-edit-crop',
      iconActive: 'img-edit-crop-white',
      labelKey: 'app.dialogs.edit-image-dialog.crop',
      trackInfo: 'crop'
    },
    {
      command: 'icon',
      icon: 'img-edit-arrow',
      iconActive: 'img-edit-arrow-white',
      labelKey: 'app.dialogs.edit-image-dialog.arrow',
      trackInfo: 'arrow'
    },
    {
      command: 'draw',
      icon: 'img-edit-draw',
      iconActive: 'img-edit-draw-white',
      labelKey: 'app.dialogs.edit-image-dialog.draw',
      trackInfo: 'draw'
    },
    {
      command: 'shape',
      icon: 'img-edit-shape',
      iconActive: 'img-edit-shape-white',
      labelKey: 'app.dialogs.edit-image-dialog.shape',
      trackInfo: 'shape'
    },
    {
      command: 'text',
      icon: 'img-edit-text',
      iconActive: 'img-edit-text-white',
      labelKey: 'app.dialogs.edit-image-dialog.text',
      trackInfo: 'text'
    },
    {
      command: 'filter',
      icon: 'img-edit-invert-colors',
      iconActive: 'img-edit-invert-colors-white',
      labelKey: 'app.dialogs.edit-image-dialog.invert',
      trackInfo: 'invert'
    }
  ];

 imageEditingActions = TrackImageEditOptions;
  swiperLogic: SwiperLogic;
  initialized = false;
  _canUndo = false;

  currentMenu: ImageEditorCommand = null;
  dialogStateClass: string = null;
  isSaveDisabled = false;

  constructor(
    private modalController: ModalController,
    private responsiveUtilsService: ResponsiveUtilsService,
    private imageCompressService: ImageCompressService,
    private el: ElementRef,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) {
    this.swiperLogic = new SwiperLogic(100, this.responsiveUtilsService, this.el);
  }

  ionViewDidEnter(): void {
    this.swiperLogic.afterViewInit();
    setTimeout(() => {
      this.imageEditor.clearUndoHistory();
      this.initialized = true;
    }, 500);
  }

  ngOnDestroy(): void {
    this.swiperLogic.destroy();
  }

  get swiperConfig(): SwiperOptions {
    return this.swiperLogic.config;
  }

  onImageCropState(): void {
    this.isSaveDisabled = this.imageEditor.isCropActive;
  }

  onBack(): void {
    this.trackClickAction(this.imageEditingActions.cancel, 'cancel-image-editing');
    this.imageEditor.destroyEditor();
    this.modalController.dismiss();
  }

  get canUndo(): boolean {
    return this.initialized && this._canUndo;
  }

  onUndoStackChanged(stackSize: number): void {
    this._canUndo = stackSize > 0;
  }

  onUndo(): void {
    if(this._canUndo){
      this.trackClickAction(this.imageEditingActions.undo, 'undo-image-edit-changes');
      this.imageEditor.undo();
    }
  }

  onSave(): void {
    if(this.imageEditor){
      this.imageEditor.clearUndoHistory();
    }
    this.trackClickAction(this.imageEditingActions.save, 'save-image-edit-changes');
    const dataUrl = this.imageEditor.imageDataURL;
    const blob = convertBase64ToBlob(dataUrl, AttachmentMimeType.JPEG.valueOf());
    this.imageCompressService.compressImage(blob as File).then(compressedFile => {
      this.imageEditor.destroyEditor();
      this.modalController.dismiss({
        file: compressedFile,
        base64: dataUrl
      } as EditedImage, 'save');
    });
  }

  onCommand(command: ImageEditorCommand, trackInfo: string): void {
    if(trackInfo) {
      this.trackClickAction(this.imageEditingActions.edit, trackInfo);
    }
    if(this.currentMenu === command){
      this.currentMenu = null;
      this.dialogStateClass = null;
    }else{
      this.currentMenu = command;
      this.dialogStateClass = `edit-${command}`;
    }
    if(command === 'filter'){
      return this.imageEditor.invertColors();
    }
    this.isSaveDisabled = false;
    this.imageEditor.showMenu(command);
  }

  trackClickAction(trackedObjectType: TrackImageEditOptions, buttonTrackInfo: string): void {
    const trackedObjectId = 'image-editor';
    this.trackingService.trackGenericClickedAction(trackedObjectId, trackedObjectType, buttonTrackInfo)
      .catch(_err => {
        console.error('Could not track image edit option action.');
      });
  }
}
