// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --post-type-info-tags-padding-top: 0px;
  --post-type-info-tags-padding-bottom: 0px;
  --post-type-info-tags-padding-start: 0px;
  --post-type-info-tags-padding-end: 0px;
}

.post-type-info-tags-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: var(--post-type-info-tags-padding-top);
  padding-bottom: var(--post-type-info-tags-padding-bottom);
  padding-left: var(--post-type-info-tags-padding-start);
  padding-right: var(--post-type-info-tags-padding-end);
}
.post-type-info-tags-container .tags-wrap {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 8px;
       column-gap: 8px;
  justify-content: start;
}
.post-type-info-tags-container .tags-wrap .tag-container {
  display: inline-block;
}
.post-type-info-tags-container .tags-wrap .tag-container .tag {
  padding: 3px 10px;
  border-radius: 8px;
  height: 24.07px;
  line-height: 19px;
}
.post-type-info-tags-container .tags-wrap .tag-container .tag .text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.post-type-info-tags-container .tags-wrap .tag-container .post-type-info-tag {
  background-color: var(--mi-dark-blue-color);
}
.post-type-info-tags-container .tags-wrap .tag-container .post-type-info-tag .text {
  color: var(--mi-white-color);
}
.post-type-info-tags-container .tags-wrap .tag-container .need-advice-tag {
  justify-content: center;
  align-items: center;
  background-color: var(--mi-purple-color-8);
  display: inline-block;
  width: 100.5px;
}
.post-type-info-tags-container .tags-wrap .tag-container .need-advice-tag .text {
  color: var(--mi-white-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
