import { Component, ElementRef, EventEmitter, Inject, Input, Output } from '@angular/core';
import { GroupVisibility } from 'src/app/services/groups/group.model';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { GroupListItem } from 'src/app/services/yeti-protocol/chatter-api';

@Component({
  selector: 'app-selectable-group-list-item',
  templateUrl: './selectable-group-list-item.component.html',
  styleUrls: ['./selectable-group-list-item.component.scss'],
})
export class SelectableGroupListItemComponent {

  @Input() group: GroupListItem;
  @Input() selected = false;
  @Input() disableSelection = false;
  @Input() disableItem = false;
  @Output() groupSelectionChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() groupSelectionClickedWhenDisabled: EventEmitter<void> = new EventEmitter();

  GroupVisibility = GroupVisibility;

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private el: ElementRef
  ) { }

  checkBoxClick(event: Event): void {

    if (this.disableSelection && !this.selected) {

      this.uiUtilsService.stopEventPropagation(event);
      this.groupSelectionClickedWhenDisabled.emit();

      this.selected = false;

      setTimeout(() => {
        this.el.nativeElement.querySelector('.checkbox').classList.remove('checkbox-checked');
      }, 250);

      return;
    }

    this.selected = !this.selected;
    this.groupSelectionChanged.emit(this.selected);
  }

}
