// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ao-event-link-button-container .link-button {
  width: 100%;
  padding: 20px 20px 20px 20px;
  background-color: var(--mi-background-color);
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto min-content;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
  cursor: pointer;
}
.ao-event-link-button-container .link-button .start-icon {
  grid-row: 1;
  grid-column: 1;
}
.ao-event-link-button-container .link-button .content {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  align-items: center;
}
.ao-event-link-button-container .link-button .content .action-title-container {
  width: 100%;
  overflow: hidden;
}
.ao-event-link-button-container .link-button .content .action-title-container .action-title {
  width: 100%;
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  text-align: left;
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ao-event-link-button-container .link-button .content .action-subtitle-container {
  width: 100%;
  overflow: hidden;
  padding-top: 5px;
}
.ao-event-link-button-container .link-button .content .action-subtitle-container .action-subtitle {
  width: 100%;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  text-align: left;
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ao-event-link-button-container .link-button .end-icon {
  grid-row: 1;
  grid-column: 3;
}
.ao-event-link-button-container .link-button .icon app-icon {
  --icon-size: 19px;
  color: var(--mi-color-text-lite);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
