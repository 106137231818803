import { NgModule } from '@angular/core';

// modules
import { CommonModule } from '@angular/common';
import { MIButtonsModule } from 'src/app/modules/buttons/buttons.module';

import { MultilineTextWithEllipsisComponent } from './multiline-text-with-ellipsis.component';
import { IconComponent } from 'src/app/modules/icon/icon.component';

@NgModule({
  imports: [
    CommonModule,
    MIButtonsModule,
    IconComponent
  ],
  exports: [
    MultilineTextWithEllipsisComponent
  ],
  declarations: [
    MultilineTextWithEllipsisComponent
  ]
})
export class MultilineTextWithEllipsisModule {
}
