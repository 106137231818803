import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';

import {PublicProfile} from 'src/app/services/yeti-protocol/public-profile';

// services
import {ClinicalExpertsService} from 'src/app/services/clinical-experts.service';
import {ClinicalExpertsDataService} from 'src/app/services/clinical-experts-data.service';
import {UI_UTILS_SERVICE, UIUtilsServiceInterface} from 'src/app/services/utils/ui-utils.service.interface';
import {ActionSource} from '../../services/yeti-protocol/tracking';
import { Store } from '@ngxs/store';
import { Posts } from '../../state/posts/posts.actions';

@Component({
  selector: 'app-clinical-experts-list-item',
  templateUrl: './clinical-experts-list-item.component.html',
  styleUrls: ['./clinical-experts-list-item.component.scss'],
})
export class ClinicalExpertsListItemComponent {
  @Input() item: PublicProfile;
  @Input() source: ActionSource = ActionSource.unspecified;
  @Output() openPublicUserProfile: EventEmitter<string> = new EventEmitter();

  constructor(
    private clinicalExpertService: ClinicalExpertsService,
    private clinicalExpertDataService: ClinicalExpertsDataService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private store: Store
  ) { }

  onFollowUnfollowClinicalExpert(event: Event, clinicalExpertId: string, follow: boolean): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.clinicalExpertService.followUnfollow(clinicalExpertId, follow, this.source)
    .then((result: boolean) => {
      if(result) {
        this.item.isFollower = follow;
        this.clinicalExpertDataService.triggerFollowUnfollowClinicalExpertAction(this.item);
        this.store.dispatch(new Posts.UpdatePostsOwnerFollowingStatus(clinicalExpertId, follow));
      }
    });
  }

  onOpenPublicProfilePage(): void {
    this.openPublicUserProfile.emit(this.item.userId);
  }
}
