import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClinicalExpertsService } from 'src/app/services/clinical-experts.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { PostOwner } from 'src/app/services/yeti-protocol/chatter-api';
import { Store } from '@ngxs/store';
import { Posts } from '../../../state/posts/posts.actions';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-follow-user-button',
  templateUrl: './follow-user-button.component.html',
  styleUrls: ['./follow-user-button.component.scss'],
})
export class FollowUserButtonComponent implements OnInit, OnDestroy {

  @Input() userToFollow: PostOwner;
  @Input() source: ActionSource = ActionSource.unspecified;

  @Output() userFollowed: EventEmitter<PostOwner> = new EventEmitter();

  private userProfileSubscription: Subscription;
  private followPending: boolean;

  constructor(
    private clinicalExpertService: ClinicalExpertsService,
    private toast: ToastService,
    private translationService: AppTranslationService,
    private store: Store,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  async followUser(event: Event): Promise<void> {

    this.uiUtilsService.stopEventPropagation(event);

    if (this.followPending) {
      return;
    }

    if (!this.userToFollow?.userId) {
      return;
    }

    this.followPending = true;

    this.clinicalExpertService.followUnfollow(this.userToFollow?.userId, true, this.source).then(followed => {
      if (followed) {
        this.store.dispatch(new Posts.UpdatePostsOwnerFollowingStatus(this.userToFollow.userId, true));
        this.userToFollow.isFollower = true;
        this.userFollowed.emit(this.userToFollow);

        const message = this.translationService.instant('app.components.FollowUserButtonComponent.user-followed');
        this.toast.showWithMessage(`${message} ${this.userToFollow.firstName}`, null, ToastMode.SUCCESS);
      }
    }).finally(() => {
      this.followPending = false;
    });
  }

}
