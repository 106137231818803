import {AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { VisibilityTrackerService } from './visibility-tracker.service';

@Directive({
  selector: '[app-visibility-tracker-root]',
  exportAs: 'visibilityTracker'
})
export class VisibilityTrackerRootDirective implements AfterViewInit, OnDestroy {

  @Input() visibilityRootKey: string;
  @Input() visibilityTrackerRegistrationDelay = 100;

  constructor(
    private ref: ElementRef,
    private tracker: VisibilityTrackerService
    ) {
  }
  ngOnDestroy(): void {
    this.tracker.unregisterRoot(this.visibilityRootKey);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.tracker.isRegistered(this.visibilityRootKey)) {
        this.tracker.registerRoot(this.visibilityRootKey, this.ref.nativeElement);
      }
    }, 0);
  }
}
