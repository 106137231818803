import { Component, Input } from '@angular/core';

export enum CaseReadinessStageState {
  STATE_1 = 'state-1',
  STATE_2 = 'state-2',
  STATE_3 = 'state-3'
}

export interface CaseReadinessIndicatorState {
  stage1: CaseReadinessStageState;
  stage2: CaseReadinessStageState;
  stage3: CaseReadinessStageState;
}

@Component({
  selector: 'app-case-readiness-indicator',
  templateUrl: './case-readiness-indicator.component.html',
  styleUrls: ['./case-readiness-indicator.component.scss'],
})
export class CaseReadinessIndicatorComponent {
  @Input() state: CaseReadinessIndicatorState;
}
