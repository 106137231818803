import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

// services
import { LinkOpenerService } from 'src/app/services/link-opener.service';

import { MarketingTeaserObject } from 'src/app/services/yeti-protocol/my-stream';

@Component({
  selector: 'app-marketing-teaser-card',
  templateUrl: './marketing-teaser-card.component.html',
  styleUrls: ['./marketing-teaser-card.component.scss'],
})
export class MarketingTeaserCardComponent {
  @Input() marketingTeaserObj: MarketingTeaserObject;
  @Output() marketingTeaserCardClicked: EventEmitter<void> = new EventEmitter();

  constructor(
    private linkOpenerService: LinkOpenerService,
    private router: Router
  ) { }

  onCardClick(): void {
    if(this.marketingTeaserCardClicked) {
      this.marketingTeaserCardClicked.emit();
    }
    if(this.marketingTeaserObj?.url) {
      if (this.marketingTeaserObj?.isLinkExternal) {
        this.linkOpenerService.open(this.marketingTeaserObj?.url);
      } else {
        this.router.navigateByUrl(`${this.marketingTeaserObj?.url}`);
      }
    }
  }

}
