// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.case-documents-upload-edit-preview-container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 10px;
}
.case-documents-upload-edit-preview-container .title-container {
  grid-row: 1;
  grid-column: 1;
}
.case-documents-upload-edit-preview-container .title-container .title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-grey-color-16);
  margin: 0;
  text-align: left;
}
.case-documents-upload-edit-preview-container .title-container.with-actions-container {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: min-content;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
}
.case-documents-upload-edit-preview-container .title-container.with-actions-container .title {
  grid-row: 1;
  grid-column: 1;
}
.case-documents-upload-edit-preview-container .title-container.with-actions-container .actions-container {
  grid-row: 1;
  grid-column: 2;
}
.case-documents-upload-edit-preview-container .title-container.with-actions-container .actions-container app-group-document-button {
  --group-document-button-icon-color: var(--mi-grey-color-13);
}
.case-documents-upload-edit-preview-container .attachments-container {
  grid-row: 2;
  grid-column: 1;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document {
  width: 100%;
  padding: 10px;
  background-color: var(--mi-light-purple-1-color);
  border-radius: 8px;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content;
  -moz-column-gap: 9px;
       column-gap: 9px;
  align-items: center;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document.with-actions-container {
  grid-template-columns: min-content auto min-content;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .icon-container {
  grid-row: 1;
  grid-column: 1;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .icon-container app-icon {
  --icon-size: 25px;
  color: var(--mi-grey-color-13);
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .text-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 2px;
  cursor: pointer;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .text-container .document-name-container {
  position: relative;
  height: 18px;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .text-container .document-name-container .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .text-container .document-name-container .wrapper .document-name {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .text-container .document-size-container {
  position: relative;
  height: 16px;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .text-container .document-size-container .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .text-container .document-size-container .wrapper .document-size {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .actions-container {
  grid-row: 1;
  grid-column: 3;
}
.case-documents-upload-edit-preview-container .attachments-container .documents-list .document .actions-container app-icon-button {
  --icon-button-background-width: 22px;
  --icon-button-background-height: 22px;
  --icon-button-background-color: transparent;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 22px;
  --icon-button-icon-color: var(--mi-grey-color-13);
}
.case-documents-upload-edit-preview-container .empty-state-container .browse-files-btn {
  --background: var(--mi-light-purple-1-color);
  --color: --mi-secondary-color-2;
  --box-shadow: none;
  text-transform: none;
  border-radius: 8px;
  overflow: hidden;
  border: 1px dashed var(--mi-blue-color-17);
}
.case-documents-upload-edit-preview-container .empty-state-container .browse-files-btn .btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
}
.case-documents-upload-edit-preview-container .empty-state-container .browse-files-btn .btn-wrapper app-icon {
  --icon-size: 25px;
  padding-right: 8px;
}
.case-documents-upload-edit-preview-container .empty-state-container .browse-files-btn .btn-wrapper .text-wrap {
  text-align: left;
}
.case-documents-upload-edit-preview-container .empty-state-container .browse-files-btn .btn-wrapper .text-wrap .small {
  margin-top: 3px;
  font-size: var(--font-size-extra-tiny);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
