import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponseStreamItem } from 'src/app/services/yeti-protocol/my-stream';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { StreamItemType } from 'src/app/services/yeti-protocol/utils/enums';

@Component({
  selector: 'app-network-stream-top-items',
  templateUrl: './network-stream-top-items.component.html',
  styleUrls: ['./network-stream-top-items.component.scss'],
})
export class NetworkStreamTopItemsComponent implements OnInit {

  @Input() items: Array<ResponseStreamItem>;
  @Input() loading: boolean;
  @Input() visibilityRootKey: boolean;

  @Output() postDeleted: EventEmitter<string> = new EventEmitter();

  StreamItemType = StreamItemType;

  constructor() { }

  ngOnInit(): void { }

  get source(): ActionSource {
    return ActionSource.socialStreamTop;
  }

}
