import { IonNav } from '@ionic/angular';
import { SurgeryReferenceUIServiceInterface } from 'src/app/services/surgery-reference/surgery-reference-ui.service.interface';

export abstract class BaseSurgeryReferencePage{
  constructor(
    protected nav: IonNav,
    protected surgeryReferenceUIService: SurgeryReferenceUIServiceInterface,
  ) {
    this.surgeryReferenceUIService.nav = this.nav;
  }
}
