import {Component, Inject, Input} from '@angular/core';
import {TRACKING_SERVICE, TrackingService, TrackingSourceParams} from '../../services/tracking/tracking.model';
import {AuthService} from '../../services/auth/auth.service';

export interface FacultyContextData {
  id: number,
  title: string,
  icon: string,
  outlink: string
}

@Component({
  selector: 'app-my-faculty-lounge',
  templateUrl: './my-faculty-lounge.component.html',
  styleUrls: ['./my-faculty-lounge.component.scss'],
})

export class MyFacultyLoungeComponent  {

  @Input() source: string;
  @Input() availableFacultyContexts: FacultyContextData[];

  constructor(
    private authService: AuthService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) { }

  navigateToFacultyLounge(objectId: string, outlink: string): void {
    this.trackClickAction(objectId, 'outlink', 'AOfacultyLounge')
    this.authService.openAoLink(outlink);
  }


  trackClickAction(objectId: string, objectType: string, objectTitle: string): void {
    const sourceParams: TrackingSourceParams = {
      source: this.source,
    };
    this.trackingService
      .trackGenericClickedAction(
        objectId,
        objectType,
        objectTitle,
        sourceParams
      )
      .catch(_err => {
        console.error('Could not track ' + objectId + ' clicked action');
      });
  }

}
