// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orcid-article-list-item-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto min-content;
  row-gap: 10px;
  cursor: pointer;
}
.orcid-article-list-item-container .title-container {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-height: 42px;
}
.orcid-article-list-item-container .title-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 42px;
}
.orcid-article-list-item-container .title-container app-multiline-text-with-ellipsis ::ng-deep {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-black-color-2);
}
.orcid-article-list-item-container .article-additional-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.orcid-article-list-item-container .article-additional-info-container .article-date-claps-container {
  display: flex;
  line-height: 22px;
  gap: 5px;
  font-size: var(--font-size-regular);
}
.orcid-article-list-item-container .article-additional-info-container .article-date-claps-container .clap-container {
  display: flex;
}
.orcid-article-list-item-container .article-additional-info-container .article-date-claps-container .clap-container .clap-icon {
  --icon-size: 15px;
  align-self: center;
  margin-right: 2px;
}
.orcid-article-list-item-container .article-additional-info-container .journal {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  color: var(--mi-black-color);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.orcid-article-list-item-container .article-additional-info-container .date {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  color: var(--mi-black-color);
  margin: 0;
  white-space: nowrap;
}
.orcid-article-list-item-container .article-additional-info-container .link-icon {
  --icon-size: 14px;
  margin-left: 10px;
  align-self: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
