// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --post-card-padding-top: 10px;
  --post-card-padding-bottom: 10px;
  --post-card-padding-start: 20px;
  --post-card-padding-end: 20px;
  --post-card-margin-top: 15px;
  --post-card-margin-bottom: 15px;
  --post-card-margin-start: 15px;
  --post-card-margin-end: 15px;
  --post-card-background-color: var(--mi-white-color);
}

app-mi-card {
  --mi-card-padding-top: var(--post-card-padding-top);
  --mi-card-padding-bottom: var(--post-card-padding-bottom);
  --mi-card-padding-start: var(--post-card-padding-start);
  --mi-card-padding-end: var(--post-card-padding-end);
  --mi-card-margin-top: var(--post-card-margin-top);
  --mi-card-margin-bottom: var(--post-card-margin-bottom);
  --mi-card-margin-start: var(--post-card-margin-start);
  --mi-card-margin-end: var(--post-card-margin-end);
}
app-mi-card .card-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  justify-items: start;
  align-items: start;
  min-height: 150px;
  border-radius: 5px;
  background-color: var(--post-card-background-color);
}
app-mi-card .card-container .header-container {
  width: 100%;
  margin-bottom: 5px;
  padding: var(--post-card-padding-top) var(--post-card-padding-end) 0 var(--post-card-padding-start);
}
app-mi-card .card-container .content-container {
  width: 100%;
  padding: 0 var(--post-card-padding-end) 0 var(--post-card-padding-start);
  cursor: pointer;
  line-height: 1.3;
}
app-mi-card .card-container .content-container app-rich-text-document-editor {
  --rich-text-document-editor-padding-top: 0px;
  --rich-text-document-editor-padding-bottom: 0px;
  --rich-text-document-editor-padding-start: 0px;
  --rich-text-document-editor-padding-end: 0px;
  --rich-text-document-editor-background-color: var(--post-card-background-color);
}
app-mi-card .card-container .attachments-container {
  width: 100%;
  padding: 0 var(--post-card-padding-end) 0 var(--post-card-padding-start);
}
app-mi-card .card-container .attachments-container app-group-post-card-attachments ::ng-deep app-image-attachments-container {
  --image-attachments-container-padding-top: 0px;
  --image-attachments-container-padding-bottom: 0px;
  --image-attachments-container-padding-start: 0;
  --image-attachments-container-padding-end: 0;
}
app-mi-card .card-container .applauds-container {
  padding: 0 var(--post-card-padding-end) 0 var(--post-card-padding-start);
}
app-mi-card .card-container .applauds-container app-user-profile-image-bubbles {
  --bubbles-padding-top: 10px;
}
app-mi-card .card-container .latest-comment-container {
  width: 100%;
  border-top: 1.5px solid var(--mi-background-color);
}
app-mi-card .card-container .latest-comment-container .latest-comment {
  width: 100%;
  padding: 20px var(--post-card-padding-end) 5px var(--post-card-padding-start);
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 5px;
}
app-mi-card .card-container .latest-comment-container .latest-comment .owner-info-container {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 7px;
       column-gap: 7px;
}
app-mi-card .card-container .latest-comment-container .latest-comment .owner-info-container .user-profile-image-container {
  grid-row: 1;
  grid-column: 1;
}
app-mi-card .card-container .latest-comment-container .latest-comment .owner-info-container .user-profile-image-container app-user-profile-image {
  --user-image-size: 35px;
}
app-mi-card .card-container .latest-comment-container .latest-comment .owner-info-container .owner-text-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
app-mi-card .card-container .latest-comment-container .latest-comment .owner-info-container .owner-text-container .fullname-container {
  overflow: hidden;
  width: 100%;
}
app-mi-card .card-container .latest-comment-container .latest-comment .owner-info-container .owner-text-container .country-container {
  overflow: hidden;
  width: 100%;
}
app-mi-card .card-container .latest-comment-container .latest-comment .owner-info-container .owner-text-container .country-container .country {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
app-mi-card .card-container .latest-comment-container .latest-comment .comment-content-container app-rich-text-document-editor {
  --rich-text-document-editor-padding-top: 0px;
  --rich-text-document-editor-padding-bottom: 0px;
  --rich-text-document-editor-padding-start: 0px;
  --rich-text-document-editor-padding-end: 0px;
}
app-mi-card .card-container .view-comments-button-container {
  padding: 0 var(--post-card-padding-end) 10px var(--post-card-padding-start);
}
app-mi-card .card-container .view-comments-button-container button {
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
}
app-mi-card .card-container .view-comments-button-container button span {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-primary-color);
}
app-mi-card .card-container .card-actions-container {
  width: 100%;
  height: 100%;
}
app-mi-card .card-container .card-actions-container app-mi-card-actions {
  --mi-card-actions-height: 63px;
  --mi-card-actions-padding-top: 0;
  --mi-card-actions-padding-bottom: 0;
  --mi-card-actions-padding-start: var(--post-card-padding-start);
  --mi-card-actions-padding-end: var(--post-card-padding-end);
  width: 100%;
  height: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
