// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-field-limit-counter-container .text-length {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  color: var(--mi-green-color);
  margin: 0;
}
.text-field-limit-counter-container .text-length.limit-passed {
  color: var(--mi-red-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
