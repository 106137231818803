import { StorageService } from '../../storage/storage.service';
import { AOAuthBase } from './ao-auth.base';
import { AOSignInResult, SignInParams } from './ao-auth.model';

export abstract class AOAuthDeviceBase extends AOAuthBase {
  signIn(_redirectUrl?: string): Promise<AOSignInResult> {
    const finalRedirectUrl = this.getRedirectUrl();
    return this._getSignInParams(finalRedirectUrl).then(params => {
      return this._signIn(params, this.sessionStorageService);
    });
  }

  abstract _signIn(
    signInParams: SignInParams,
    storage: StorageService
  ): Promise<AOSignInResult>;

  isBrowser(): boolean {
    return false;
  }
}
