import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[app-allow-overflow-toolbar]'
})

// This directive is used to modify shadow element css properties to allow toolbar to be overflowed.
export class AllowOverflowToolbarDirective {

  constructor(el: ElementRef) {
    const toolbar: HTMLElement = el.nativeElement;
    setTimeout(() => {
      const container: HTMLElement = toolbar?.shadowRoot.querySelector('.toolbar-container');
      if (container) {
        (container.style as any).contain = 'none';
        container.style.overflow = 'visible';
      }
    }, 100);
  }

}
