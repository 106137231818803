import { Injectable } from '@angular/core';
import { Observable, Subject, debounceTime, groupBy, pipe, switchMap } from 'rxjs';
import Swiper from 'swiper';

export interface TargetImpression {
  rootKey: string;
  targetKey: string;
}

interface TargetSlidesInfo {
  rootKey: string;
  targetKey: string;
  viewStartTime: number;
  notified: boolean;
}

interface SwiperEventListener {
  eventType: string,
  handler: () => any,
  rootNode: Swiper
}

interface SwiperSlidesChangedData {
  rootKey: string,
  rootNode: Swiper
}

@Injectable()
export class SwiperVisibilityTrackerService {

  swiperTargetSlides: Map<string, Array<TargetSlidesInfo>> = new Map();
  swiperEventListeners: Map<string, SwiperEventListener> = new Map();
  swiperSlidesChanged$: Subject<SwiperSlidesChangedData> = new Subject();

  private impressionsSubject: Subject<TargetImpression> = new Subject();

  readonly minimalImpressionDurationMsec = 1000;

  get impressionsAsObservable(): Observable<TargetImpression> {
    return this.impressionsSubject.asObservable();
  }

  constructor() {
    this.swiperSlidesChanged$.pipe(
      groupBy(slidesChangedData => slidesChangedData.rootKey),
      pipe(
        switchMap(groupedChange => {
          return groupedChange.pipe(
            debounceTime(this.minimalImpressionDurationMsec + 100) // give additional 100 msc for the last slide
          );
        })
      )
    ).subscribe(slidesChangedData => {
      this.checkIsImpression(slidesChangedData);
    });
  }

  registerRoot(rootKey: string, rootNode: Swiper): void {

    if (this.swiperEventListeners.get(rootKey)) {
      throw new Error(`rootKey is already registered: ${rootKey}`);
    }

    const swiperSlidesChanged = () => {

      // wait for all slides to be updated to correct visibility status
      setTimeout(() => {
        this.setVisibleSlidesViewStartTime({
          rootKey: rootKey,
          rootNode: rootNode
        });

        this.swiperSlidesChanged$.next({
          rootKey: rootKey,
          rootNode: rootNode
        });
      }, 100);
    }

    rootNode.on('slideChange', swiperSlidesChanged);

    this.swiperEventListeners.set(rootKey, {
      eventType: 'slideChange',
      handler: swiperSlidesChanged,
      rootNode: rootNode
    });

    // Wait for initial slides load
    setTimeout(() => {
      this.setVisibleSlidesViewStartTime({
        rootKey: rootKey,
        rootNode: rootNode
      });

      this.swiperSlidesChanged$.next({
        rootKey: rootKey,
        rootNode: rootNode
      });
    }, 100);

    console.log(`created swiper visibility tracker: ${rootKey}`);
  }

  unregisterRoot(rootKey: string): void {
    const eventListener = this.swiperEventListeners.get(rootKey);

    if (eventListener) {
      eventListener.rootNode.off(eventListener.eventType as any, eventListener.handler);
      this.swiperEventListeners.delete(rootKey);
      this.swiperTargetSlides.delete(rootKey);
    }
  }

  registerTarget(rootKey: string, targetNode: HTMLElement): void {

    if (!this.swiperEventListeners.get(rootKey)) {
      throw new Error(`rootKey is not registered: ${rootKey}`);
    }

    let slides = this.swiperTargetSlides.get(rootKey);

    if (slides) {
      slides.push({
        rootKey: rootKey,
        targetKey: targetNode.getAttribute('id'),
        viewStartTime: Date.now(),
        notified: false
      });
    } else {
      slides = [{
        rootKey: rootKey,
        targetKey: targetNode.getAttribute('id'),
        viewStartTime: Date.now(),
        notified: false
      }];

      this.swiperTargetSlides.set(rootKey, slides);
    }
  }

  checkIsImpression(slidesChangedData: SwiperSlidesChangedData): void {
    const visibleSlidesIndexes = this._getVisibleSlidesIndexes(slidesChangedData.rootNode);
    const slides = this.swiperTargetSlides.get(slidesChangedData.rootKey);

    if (!slides?.length) {
      throw new Error(`no slides registered for rootKey: ${slidesChangedData.rootKey}`);
    }

    visibleSlidesIndexes.forEach(index => {
      if (index < slides?.length) {
        if (!slides[index].notified && Date.now() - slides[index].viewStartTime > this.minimalImpressionDurationMsec) {

          slides[index].notified = true;

          this.impressionsSubject.next({
            rootKey: slides[index].rootKey,
            targetKey: slides[index].targetKey
          });
        }
      }
    });
  }

  setVisibleSlidesViewStartTime(slidesChangedData: SwiperSlidesChangedData): void {
    const visibleSlidesIndexes = this._getVisibleSlidesIndexes(slidesChangedData.rootNode);
    const slides = this.swiperTargetSlides.get(slidesChangedData.rootKey);

    if (!slides?.length) {
      throw new Error(`no slides registered for rootKey: ${slidesChangedData.rootKey}`);
    }

    visibleSlidesIndexes.forEach(index => {
      if (index < slides?.length) {
        slides[index].viewStartTime = Date.now();
      }
    });
  }

  _getVisibleSlidesIndexes(rootNode: Swiper): Array<number> {

    if (!rootNode || !(rootNode as any)?.visibleSlides?.length) {
      return [];
    }

    const visibleSlides = (rootNode as any)?.visibleSlides;
    const visibleSlidesIndexes = visibleSlides.map(slide => {
      return slide?.attributes['data-swiper-slide-index']?.nodeValue;
    });

    return visibleSlidesIndexes;
  }
}
