import { AfterViewInit, Component, HostListener, Inject, Input } from '@angular/core';
import { SurgeryReference } from 'src/app/services/yeti-protocol/surgery-reference';

// services
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import { LinkOpenerService } from 'src/app/services/link-opener.service';
import { ActionTracked, GenericTrackingParam, TrackingRequest} from 'src/app/services/yeti-protocol/tracking';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import {SurgeryReferenceSearch} from '../../services/yeti-protocol/search';
import { TrackingService, TRACKING_SERVICE } from 'src/app/services/tracking/tracking.model';

@Component({
  selector: 'app-surgery-reference-info-card',
  templateUrl: './surgery-reference-info-card.component.html',
  styleUrls: ['./surgery-reference-info-card.component.scss'],
})
export class SurgeryReferenceInfoCardComponent implements AfterViewInit {
  @Input() surgeryRef: SurgeryReference;

  protected infoSheetId = 'surgery-reference-info-card-infosheet';

  constructor(
    private infoSheetService: InfoSheetService,
    private sharingUIService: SharingUIService,
    private linkOpener: LinkOpenerService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface
  ) { }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.trackSurgeryReferenceLinkClicked(this.surgeryRef);
    this.linkOpener.open(this.surgeryRef?.url);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.infoSheetId += `-${this.surgeryRef?.id}`;
      this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);
    }, 0);
  }

  shareSurgeryRef(event: Event, surgeryRef: SurgeryReference): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.sharingUIService.showShareDialogForObject(surgeryRef);
  }

  trackSurgeryReferenceLinkClicked(surgeryReference: SurgeryReferenceSearch): void {
    if(surgeryReference) {
      const paramsToTrack : GenericTrackingParam = {
        objectId: surgeryReference?.url,
        objectType: 'surgery-reference',
        objectTitle: surgeryReference.title
      };

      const trackData: TrackingRequest = {
        action: ActionTracked.surgeryReferenceLinkOpened,
        params: paramsToTrack
      };
      this.trackingService.track(trackData).catch (_err => {
        console.error('Could not track surgery reference action.');
      });
    }
  }
}
