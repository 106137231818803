import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';
import { ContentType } from '../../../services/yeti-protocol/surgery-reference-schema';

@Component({
  selector: 'app-surgery-reference-select-card',
  templateUrl: './surgery-reference-select-card.component.html',
  styleUrls: ['./surgery-reference-select-card.component.scss'],
})
export class SurgeryReferenceSelectCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() itemId: string;
  @Input() title: string;
  @Input() code: string;
  @Input() chip: string;
  @Input() imageUrl: string;
  @Input() descriptionTitle: string;
  @Input() description: string;
  @Input() contentType: ContentType;

  @Output() openExternalLink: EventEmitter<string> = new EventEmitter();
  @Output() addToCaseClicked: EventEmitter<string> = new EventEmitter();

  stripHtmlTagsFromString = stripHtmlTagsFromString;
  descriptionPrimaryText: string;
  descriptionContainsHtmlTags: boolean;
  showFullDescription: boolean;

  private cardResizeObserver: any;
  private clientHeight: number;
  private clientWidth: number;

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.setResizeObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.description &&
      changes?.description?.currentValue !== changes?.description?.previousValue &&
      this.contentType === ContentType.PROCEDURE) {
      this._calculateDescriptionValues();
    }
  }

  ngOnDestroy(): void {
    this.terminateResizeObserver();
  }

  _calculateDescriptionValues(): void {
    if (this.description?.length > 0) {

      if (this.firstHtmlTagIndex > -1) {
        this.descriptionPrimaryText = this.description.substring(0, this.firstHtmlTagIndex);
        this.descriptionContainsHtmlTags = true;
      } else {
        this.descriptionContainsHtmlTags = false;
      }
    } else {
      this.descriptionPrimaryText = '';
      this.descriptionContainsHtmlTags = false;
    }
  }

  contentClicked(): void {
    this.openExternalLink.emit(this.itemId);
  }

  onAddToCase(): void {
    this.addToCaseClicked.emit(this.itemId);
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  seeMoreClicked(): void {
    this.showFullDescription = true;
  }

  get descriptionWithHtml(): string {

    let textWithHtmlTags = '';

    if (this.firstHtmlTagIndex > -1) {
      textWithHtmlTags = this.description.substring(this.firstHtmlTagIndex);
    }

    return this.descriptionContainsHtmlTags ? stripHtmlTagsFromString(textWithHtmlTags) : '';
  }

  get firstHtmlTagIndex(): number {

    if (!this.description?.length) {
      return -1;
    }

    const re = /(<([^>]+)>)/i;
    const match = re.exec(this.description);
    const firstHtmlTagIndex = match ? match.index : -1;
    return firstHtmlTagIndex;
  }

  setResizeObservable(): void {

    if (!this.cardResizeObserver) {
      this.cardResizeObserver = new ResizeObserver(() => {
        this.zone.run(() => {

          const clientHeight = this.el?.nativeElement?.clientHeight;
          const clientWidth = this.el?.nativeElement?.clientWidth;

          if (this.description &&
            (this.clientHeight !== clientHeight ||
              this.clientWidth !== clientWidth)
          ) {

            this.clientHeight = this.el?.nativeElement?.clientHeight;
            this.clientWidth = this.el?.nativeElement?.clientWidth;

            const description = this.description?.substring(0);
            this.description = '';

            setTimeout(() => {
              this.terminateResizeObserver();
              this.description = description;
            }, 500);
          }
        });
      });
    }

    this.cardResizeObserver?.observe(this.el?.nativeElement);
  }

  private terminateResizeObserver(): void {
    this.cardResizeObserver?.unobserve(this.el?.nativeElement);
    this.cardResizeObserver?.disconnect();
  }
}
