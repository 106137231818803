import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  @Input() errorMessage: string;

  constructor(
    private modalController: ModalController,
    private params: NavParams
  ) { }

  ngOnInit(): void {
    this.errorMessage = this.params.get('errorMessage');
  }

  onClose(): void {
    this.modalController.dismiss();
  }

}
