import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { AdvertisingStatusService } from 'src/app/services/advertising-status.service';
import { AdvertismentField } from '../../services/yeti-protocol/auth/mi';
import { OrcIdAuthService } from '../../services/auth/orcid/orcid-auth.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-link-orcid',
  templateUrl: './link-orcid.component.html',
  styleUrls: ['./link-orcid.component.scss'],
})
export class LinkOrcidComponent implements OnInit, OnDestroy {

  @Input() linkOrcIdTemplate!: TemplateRef<any>;

  readonly LINK_ORCID_BANNER = 'linkOrcidBanner';
  bannerIsHidden: boolean;
  isOrcIdLinked: boolean;

  private gettingBannerHiddenStatus: boolean;
  private orcIdAuthStatusChangedSubscription: Subscription;
  private userProfileSubscription: Subscription;

  constructor(
    private advertisingStatusService: AdvertisingStatusService,
    private orcIdAuthService: OrcIdAuthService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.initIsOrcIdLinked();
    this.orcIdAuthStatusChangedSubscription =
      this.orcIdAuthService.orcIdAuthStatusChangedObs.subscribe(() => this.initIsOrcIdLinked());

    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe(() => {
      this.initBannerHiddenStatus();
    });
  }

  ngOnDestroy(): void {
    this.orcIdAuthStatusChangedSubscription?.unsubscribe();
    this.userProfileSubscription?.unsubscribe();
  }

  initBannerHiddenStatus(): void {

    if (this.gettingBannerHiddenStatus) {
      return;
    }

    this.gettingBannerHiddenStatus = true;

    this.advertisingStatusService.getInfo(this.LINK_ORCID_BANNER).then((field: AdvertismentField) => {
      this.bannerIsHidden = !!field;
    }).finally(() => {
      this.gettingBannerHiddenStatus = false;
    })
  }

  hideBanner(): void {

    const linkOrcId: AdvertismentField = {
      version: 1,
      id: this.LINK_ORCID_BANNER,
      isGeneral: true
    };

    this.advertisingStatusService.setInfo(linkOrcId).then(() => {
      this.initBannerHiddenStatus();
    });
  }

  get showBanner(): boolean {
    return !this.gettingBannerHiddenStatus && !this.bannerIsHidden && !this.isOrcIdLinked;
  }

  async initIsOrcIdLinked(): Promise<void> {
    try {
      this.isOrcIdLinked = await this.orcIdAuthService.isSignedIn();
    } catch (err) {
      console.error(err);
      this.isOrcIdLinked = true; // if we can't determine orcid linked status, hide the card
    }
  }

}
