import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Journal} from 'src/app/services/yeti-protocol/journal';

// services
import {InfoSheetService} from 'src/app/modules/info-sheet/services/info-sheet.service';
import {JournalsService} from 'src/app/services/journals/journals.service';
import {ResponsiveUtilsService} from '../../../services/utils/responsive-utils.service';
import { JournalsDataService } from 'src/app/services/journals/journals-data.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';

@Component({
  selector: 'app-my-journals-list-item',
  templateUrl: './my-journals-list-item.component.html',
  styleUrls: ['./my-journals-list-item.component.scss'],
})
export class MyJournalsListItemComponent implements OnInit {
  @Input() journal: Journal;
  @Output() itemChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() openJournal: EventEmitter<string> = new EventEmitter();
  infoSheetId = 'my-journals-list-item-info-sheet';

  constructor(
    private infoSheetService: InfoSheetService,
    private journalsService: JournalsService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private journalsDataService: JournalsDataService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) {
  }

  ngOnInit(): void {
    this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);
  }

  openJournalsSettings(journal: Journal, event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    if (this.isDesktop) {
      this.infoSheetService.openWeb(this.infoSheetActions, event)
        .then(action => {
          if (action && action.code) {
            action.handler();
          }
        });
    } else {
      this.infoSheetService.open(this.infoSheetId);
    }
  }

  closeSettings(): void {
    this.infoSheetService.close(this.infoSheetId);
  }

  followUnfollowJournal(journalId: string, follow: boolean): void {
    this.journalsService.followUnfollowJournal(journalId, follow).then((response: boolean) => {
      if(response) {
        this.journal.isFollowed = follow;
        this.journalsDataService.triggerFollowUnfollowJournalAction(this.journal);
        this.itemChanged.emit();
      }
    });
  }

  openJournalPage(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.openJournal.emit(this.journal.id);
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    const actions: Array<InfoSheetActionItem> = [];
    if (this.journal?.isFollowed) {
      actions.push({
        id: 'my_journals_item_unfollow_btn',
        icon: 'md-icon-clear',
        textKey: 'app.common.unfollow',
        code: 'UNFOLLOW_JOURNAL',
        handler: () => {
          this.followUnfollowJournal(this.journal.id, false);
        }
      });
    } else {
      actions.push({
        id: 'my_journals_item_follow_btn',
        icon: 'md-icon-user-plus',
        textKey: 'app.common.follow',
        code: 'FOLLOW_JOURNAL',
        handler: () => {
          this.followUnfollowJournal(this.journal.id, true);
        }
      })
    }
    return actions;
  }
}
