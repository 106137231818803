import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';

import { SwiperOptions } from 'swiper';
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';

import { GroupListItem } from '../../services/yeti-protocol/chatter-api';
import { ResponsiveUtilsService } from '../../services/utils/responsive-utils.service';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-recommended-groups-list',
  templateUrl: './recommended-groups-list.component.html',
  styleUrls: ['./recommended-groups-list.component.scss'],
})
export class RecommendedGroupsListComponent implements OnInit, OnDestroy, AfterContentInit {

  @ViewChild(SwiperComponent) swiperComponent: SwiperComponent;

  @Input() recommendedGroups: Array<GroupListItem>;
  @Input() recommendedGroupsToJoinLoading: boolean;
  @Input() totalGroupsCount: number;
  @Input() source: ActionSource;
  @Output() groupClick: EventEmitter<GroupListItem> = new EventEmitter();

  swiperLogic: SwiperLogic;
  protected defaultGroupCards = 9;

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.swiperLogic = new SwiperLogic(312, this.responsiveUtilsService, this.el, 1.6);
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.swiperLogic.destroy();
  }

  ngAfterContentInit(): void {
    this.swiperLogic.afterViewInit();
  }

  get swiperConfig(): SwiperOptions {
    return this.swiperLogic.config;
  }

  showSeeAll(): boolean {
    if (this.totalGroupsCount) {
      return this.totalGroupsCount > this.defaultGroupCards;
    } else {
      return true;
    }
  }

  groupClicked(group: GroupListItem): void {
    this.groupClick.emit(group);
  }

  goToNetwork(): void {
    this.router.navigateByUrl([this.contextService.currentContext.homePath, 'network'].join('/'));
  }

  async openSeeAllGroups(): Promise<void> {

    let url = [this.contextService.currentContext.homePath,
      'groups?tab=discover'].join('/');

    if (this.source) {
      url = `${url}&source=${this.source}`;
    }

    await this.router.navigateByUrl(url);
  }

  refreshRecommendedGroups($event: string): void {
    const removeIndex = this.recommendedGroups.map(group => group._id).indexOf($event);
    if (removeIndex >= 0) {
      this.recommendedGroups.splice(removeIndex, 1);
      const groups = [...this.recommendedGroups];
      this.recommendedGroups = [];
      this.updateSlides();
      this.changeDetectorRef.detectChanges();
      this.recommendedGroups = groups;
    }
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  updateSlides(): void {
    this.swiperComponent?.swiperRef?.updateSlides();
  }
}
