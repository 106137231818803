import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EVENT_FEED_WITH_PARTICIPANTS_STATE_KEY } from '../state-constants';
import { ChatterApiService } from 'src/app/services/chatter-api.service';
import { PostsFeedState } from '../posts-feed/posts-feed.state';
import { EventFeedWithParticipants } from './event-feed-with-participants.actions';
import { PostsFeedStateModel } from '../posts-feed/posts-feed.model';
import { EventFeedWithParticipantsStateModel } from './event-feed-with-participants.model';
import { PostListItem } from 'src/app/services/yeti-protocol/chatter-api';

const EVENT_FEED_WITH_PARTICIPANTS_STATE_TOKEN = new StateToken<EventFeedWithParticipantsStateModel>(
  EVENT_FEED_WITH_PARTICIPANTS_STATE_KEY
);

@State({
  name: EVENT_FEED_WITH_PARTICIPANTS_STATE_TOKEN,
})
@Injectable()
export class EventFeedWithParticipantsState extends PostsFeedState {

  constructor(chatterApiService: ChatterApiService) {
    super(chatterApiService);
  }

  @Selector()
  static state(state: EventFeedWithParticipantsStateModel): EventFeedWithParticipantsStateModel {
    return state;
  }

  @Selector()
  static allPostsFeedItems(state: PostsFeedStateModel): Array<PostListItem> {
    return state.allPostsFeedItems;
  }

  @Selector()
  static postsFeedItemsWithoutPinned(state: PostsFeedStateModel): Array<PostListItem> {
    return state.postsFeedItemsWithoutPinned;
  }

  @Selector()
  static pinnedPostsFeedItems(state: PostsFeedStateModel): Array<PostListItem> {
    return state.pinnedPostsFeedItems;
  }

  @Selector()
  static totalCount(state: PostsFeedStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static fetchPostsFeedItemsloading(state: PostsFeedStateModel): boolean {
    return state.fetchPostsFeedItemsloading;
  }

  @Action(EventFeedWithParticipants.FetchPostsFeed)
  async fetchEventFeedWithParticipants(
    ctx: StateContext<EventFeedWithParticipantsStateModel>,
    payload: EventFeedWithParticipants.FetchPostsFeed
  ): Promise<void> {
    await this.fetchPostsFeed(ctx, payload, EventFeedWithParticipants);
  }

  @Action(EventFeedWithParticipants.FetchPostsFeedSuccess)
  fetchEventFeedWithParticipantsSuccess(
    ctx: StateContext<EventFeedWithParticipantsStateModel>,
    action: EventFeedWithParticipants.FetchPostsFeedSuccess
  ): void {
    this.fetchPostsFeedSuccess(ctx, action, EventFeedWithParticipants);
  }

  @Action(EventFeedWithParticipants.FetchPostsFeedFailed)
  fetchEventFeedWithParticipantsFailed(
    ctx: StateContext<EventFeedWithParticipantsStateModel>
  ): void {
    this.fetchPostsFeedFailed(ctx);
  }

  @Action(EventFeedWithParticipants.InsertPostsFeedItemBeforeIndex)
  insertEventFeedWithParticipantsItemBeforeIndex(
    ctx: StateContext<EventFeedWithParticipantsStateModel>,
    action: EventFeedWithParticipants.InsertPostsFeedItemBeforeIndex): void {
    this.insertPostsFeedItemBeforeIndex(ctx, action, EventFeedWithParticipants);
  }

  @Action(EventFeedWithParticipants.RemovePostsFeedItem)
  removeEventFeedWithParticipantsItem(
    ctx: StateContext<EventFeedWithParticipantsStateModel>,
    action: EventFeedWithParticipants.RemovePostsFeedItem): void {
    this.removePostsFeedItem(ctx, action, EventFeedWithParticipants);
  }

  @Action(EventFeedWithParticipants.UpdatePostsFeedItem)
  updateEventFeedWithParticipantsItem(
    ctx: StateContext<EventFeedWithParticipantsStateModel>,
    action: EventFeedWithParticipants.UpdatePostsFeedItem): void {
    this.updatePostsFeedItem(ctx, action, EventFeedWithParticipants);
  }

  @Action(EventFeedWithParticipants.SetPinnedAndNotPinnedPostsFeedItems)
  setPinnedAndNotPinnedEventFeedWithParticipantsItems(ctx: StateContext<EventFeedWithParticipantsStateModel>): void {
    this.setPinnedAndNotPinnedPostsFeedItems(ctx);
  }

  @Action(EventFeedWithParticipants.PinPostsFeedItem)
  async pinFeedItem(
    ctx: StateContext<EventFeedWithParticipantsStateModel>,
    payload: EventFeedWithParticipants.PinPostsFeedItem
  ): Promise<void> {
    this.pinPostsFeedItem(ctx, payload, EventFeedWithParticipants);
  }

  @Action(EventFeedWithParticipants.PinPostsFeedItemSuccess)
  pinFeedItemSuccess(
    ctx: StateContext<EventFeedWithParticipantsStateModel>,
    action: EventFeedWithParticipants.PinPostsFeedItemSuccess
  ): void {
    this.pinPostsFeedItemSuccess(ctx, action, EventFeedWithParticipants);
  }

  @Action(EventFeedWithParticipants.PinPostsFeedItemFailed)
  pinFeedItemFailed(
    ctx: StateContext<EventFeedWithParticipantsStateModel>
  ): void {
    this.pinPostsFeedItemFailed(ctx);
  }

  @Action(EventFeedWithParticipants.UnpinPostsFeedItem)
  async unpinFeedItem(
    ctx: StateContext<EventFeedWithParticipantsStateModel>,
    payload: EventFeedWithParticipants.UnpinPostsFeedItem
  ): Promise<void> {
    this.unpinPostsFeedItem(ctx, payload, EventFeedWithParticipants);
  }

  @Action(EventFeedWithParticipants.UnpinPostsFeedItemSuccess)
  unpinFeedItemSuccess(
    ctx: StateContext<EventFeedWithParticipantsStateModel>,
    action: EventFeedWithParticipants.UnpinPostsFeedItemSuccess
  ): void {
    this.unpinPostsFeedItemSuccess(ctx, action, EventFeedWithParticipants);
  }

  @Action(EventFeedWithParticipants.UnpinPostsFeedItemFailed)
  unpinFeedItemFailed(
    ctx: StateContext<EventFeedWithParticipantsStateModel>
  ): void {
    this.unpinPostsFeedItemFailed(ctx);
  }

  @Action(EventFeedWithParticipants.SetParentTypeAndParentId)
  setFeedParentTypeAndParentId(
    ctx: StateContext<PostsFeedStateModel>,
    action: EventFeedWithParticipants.SetParentTypeAndParentId
  ): void {
    this.setParentTypeAndParentId(ctx, action);
  }

  @Action(EventFeedWithParticipants.UpdatePostsFeedItemsOwnerFollowingStatus)
  updateFeedItemsOwnerFollowingStatus(
    ctx: StateContext<PostsFeedStateModel>,
    action: EventFeedWithParticipants.UpdatePostsFeedItemsOwnerFollowingStatus): void {
    this.updatePostsFeedItemsOwnerFollowingStatus(ctx, action, EventFeedWithParticipants);
  }
}
