import {Component, HostBinding, Input} from '@angular/core';

export type BETA_CHIP_STYLE = 'light' | 'dark';

@Component({
  selector: 'app-beta-chip',
  styleUrls: ['./beta-chip.component.scss'],
  template: 'beta'
})
export class BetaChipComponent {
  @Input()chipStyle:BETA_CHIP_STYLE = 'light';

  @HostBinding('class')
  get class(): string {
    return this.chipStyle;
  }
}
