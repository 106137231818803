import { NgModule } from '@angular/core';

// modules
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MIButtonsModule } from '../buttons/buttons.module';

// components
import { TopBarComponent } from './top-bar.component';
import { IconComponent } from '../icon/icon.component';


@NgModule({
  declarations: [
    TopBarComponent,
  ],
  exports: [
    TopBarComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MIButtonsModule,
    IconComponent
  ]
})
export class TopBarModule { }
