import { Injectable } from '@angular/core';
import { RecommendedPeopleStrategy } from './recommended-people';
import { ClinicalExpertsService } from 'src/app/services/clinical-experts.service';
import { PeopleListSuccessResponse } from '../yeti-protocol/clinical-expert';

@Injectable({
    providedIn: 'any',
})
export class RecommendedContactsStrategy extends RecommendedPeopleStrategy {

    constructor(
        private clinicalExpertsService: ClinicalExpertsService,
    ) {
        super();
    }

    getRecommendedPeople(start?: number, count?: number, checkVisibility?: boolean): Promise<PeopleListSuccessResponse> {
        return this.clinicalExpertsService.getRecommendedContacts(start, count, checkVisibility);
    }
}
