import { ImageType } from '../components/image-attachments-container/image-attachments-container.model';

export enum AttachmentMimeType {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  GIF = 'image/gif',
  MP4 = 'video/mp4',
  QUICKTIME = 'video/quicktime',
  WMV = 'video/x-ms-wmv',
  PDF = 'application/pdf',
  PPT = '.ppt,.pptx,.pps,.ppsx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'
}

export const ImageMimeTypes = [
  AttachmentMimeType.JPEG,
  AttachmentMimeType.JPG,
  AttachmentMimeType.PNG,
  AttachmentMimeType.GIF
];

export const DocumentMimeTypes = [AttachmentMimeType.PDF];

export const VideoMimeTypes = [
  AttachmentMimeType.MP4,
  AttachmentMimeType.QUICKTIME,
  AttachmentMimeType.WMV
]

export function isImage(mimeType: string): boolean {
  const mt = (mimeType || '').toLowerCase();
  return !!ImageMimeTypes.find((type => {
    return mt === type.valueOf();
  }));
}

export function isAnyImage(mimeType: string): boolean {
  return (mimeType || '').toLowerCase().startsWith('image');
}


export function isVideo(mimeType: string): boolean {
  const mt = (mimeType || '').toLowerCase();
  return !!VideoMimeTypes.find((type => {
    return mt === type.valueOf();
  }));
}

export interface ImageAttachment {
  _id: string;
  fileName: string;
  fullUrl?: string; // for videos
  previewUrl?: string;
  mimeType: string;
  contentLength?: number;
  file?: File;
  watermarkedPreviewUrl?: string;
  fullWatermarkUrl?: string;
  videoUrl?: string;
  type?: ImageType;
  description?: string;
}

export interface DocumentAttachment {
  _id: string;
  fileName: string;
  url: string;
  fullUrl?: string;
  mimeType: string;
  contentLength?: number;
  file?: File;
  previewUrl?: string;
}
