import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StageOfTreatmentId } from '../stage-of-treatment.model';

@Component({
  selector: 'app-stage-of-treatment-chip',
  templateUrl: './stage-of-treatment-chip.component.html',
  styleUrls: ['./stage-of-treatment-chip.component.scss'],
})
export class StageOfTreatmentChipComponent {

  @Input() text: string;
  @Input() id: StageOfTreatmentId;
  @Input() selected: boolean;
  @Input() disabled: boolean;

  @Output() chipClicked: EventEmitter<StageOfTreatmentId> = new EventEmitter();

  onChipClick(): void {
    if (this.disabled) {
      return;
    }
    this.chipClicked.emit(this.id);
  }
}
