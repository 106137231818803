// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --new-message-dialog-padding-top: 0px;
  --new-message-dialog-padding-bottom: 0px;
  --new-message-dialog-padding-start: 15px;
  --new-message-dialog-padding-end: 15px;
}

.new-message-dialog-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
}
.new-message-dialog-container .header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
}
.new-message-dialog-container .header .search-input-container {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: var(--new-message-dialog-padding-start);
  padding-right: var(--new-message-dialog-padding-end);
  border-bottom: 1.5px solid var(--mi-background-color);
}
.new-message-dialog-container .header .search-input-container.search-mode {
  grid-template-columns: auto min-content min-content;
}
.new-message-dialog-container .header .search-input-container .field-wrapper {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 2.5px;
       column-gap: 2.5px;
}
.new-message-dialog-container .header .search-input-container .field-wrapper .label {
  grid-row: 1;
  grid-column: 1;
  align-self: end;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-secondary-color-2);
  margin: 0;
}
.new-message-dialog-container .header .search-input-container .field-wrapper .search-input {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  border: none;
  outline: none;
  background: none;
  align-self: end;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-secondary-color-2);
  padding: 0;
}
.new-message-dialog-container .header .search-input-container .field-wrapper .search-input::placeholder {
  color: var(--mi-secondary-color-2);
}
.new-message-dialog-container .header .search-input-container .search-button {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease;
}
.new-message-dialog-container .header .search-input-container .search-button.show {
  opacity: 1;
  pointer-events: all;
}
.new-message-dialog-container .header .search-input-container .close-search-mode-btn {
  grid-row: 1;
  grid-column: 3;
  align-self: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease;
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: none;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0%;
  --icon-button-icon-size: 18px;
  --icon-button-icon-color: var(--mi-secondary-color-2);
}
.new-message-dialog-container .header .search-input-container .close-search-mode-btn.show {
  opacity: 1;
  pointer-events: all;
}
.new-message-dialog-container .content-container {
  grid-row: 2;
  grid-column: 1;
}
.new-message-dialog-container .content-container .content {
  width: 100%;
  height: 100%;
}
.new-message-dialog-container .content-container .content .empty-results-container {
  margin-top: 85px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content;
  row-gap: 10px;
  justify-items: center;
  align-items: start;
}
.new-message-dialog-container .content-container .content .empty-results-container .icon-container .circle {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--mi-primary-color);
}
.new-message-dialog-container .content-container .content .empty-results-container .icon-container .circle app-icon {
  --icon-size: 30px;
  color: var(--mi-white-color);
}
.new-message-dialog-container .content-container .content .empty-results-container .text-container p {
  max-width: 175px;
  font-weight: bold;
  font-style: normal;
  font-size: var(--font-size-large);
  color: var(--mi-empty-state-header-color);
  margin: 0;
  text-align: center;
}
.new-message-dialog-container .content-container .content .list-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 27px;
}
.new-message-dialog-container .content-container .content .list-container .container-title {
  padding-top: 35px;
  padding-left: var(--new-message-dialog-padding-start);
  padding-right: var(--new-message-dialog-padding-end);
}
.new-message-dialog-container .content-container .content .list-container .container-title .title {
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
  font-size: var(--font-size-large);
  color: var(--mi-secondary-color-1);
  margin: 0;
}
.new-message-dialog-container .content-container .content .list-container .list .item {
  padding-left: var(--new-message-dialog-padding-start);
  padding-right: var(--new-message-dialog-padding-end);
  padding-bottom: 18px;
  padding-top: 18px;
  border-bottom: 1.5px solid var(--mi-background-color);
}
.new-message-dialog-container .content-container .content .list-container .list .item:first-child {
  padding-top: 0;
}
.new-message-dialog-container .content-container .content .list-container .list .item.contact {
  cursor: pointer;
}
.new-message-dialog-container .content-container .content .list-container.myao-users-container .list .item {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 18px;
       column-gap: 18px;
}
.new-message-dialog-container .content-container .content .list-container.myao-users-container .list .item app-user-info {
  grid-row: 1;
  grid-column: 1;
}
.new-message-dialog-container .content-container .content .list-container.myao-users-container .list .item .actions-container {
  grid-row: 1;
  grid-column: 2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
