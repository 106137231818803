import { Injectable } from '@angular/core';
import { ImageGalleryStrategy } from './image-gallery';
import { ConfirmDialogData } from '../dialogs/dialogs.ui.interface';
import { DialogsUIService } from '../dialogs/dialogs.ui.service';

@Injectable({
  providedIn: 'any',
})
export class ImageGalleryDefaultStrategy extends ImageGalleryStrategy {

  constructor(
    private dialogs: DialogsUIService
  ) {
    super();
  }

  async shouldProceedWithDelete(): Promise<boolean> {

    try {
      return await this.presentRemoveImageAlert();
    } catch (err) {
      return false;
    }
  }

  private async presentRemoveImageAlert(): Promise<boolean> {

    const removeActionKey = 'remove';
    const cancelActionKey = 'cancel';

    const changeProfessionConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.ImageGallery.delete-image-warning-title'
        },
        message: {
          translationKey: 'app.ImageGallery.delete-image-warning-text'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.cancel',
            },
            className: 'secondary'
          },
          {
            key: removeActionKey,
            label: {
              translationKey: 'app.common.remove',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(changeProfessionConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === removeActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
