// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --active-conversations-list-padding-top: 20px;
  --active-conversations-list-padding-bottom: 15px;
  --active-conversations-list-padding-start: 15px;
  --active-conversations-list-padding-end: 15px;
  --active-conversations-list-item-padding-top: 15px;
  --active-conversations-list-item-padding-bottom: 15px;
  --active-conversations-list-item-padding-start: 15px;
  --active-conversations-list-item-padding-end: 15px;
  --active-conversations-list-background-color: var(--mi-white-color);
}

.active-conversations-list-container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.active-conversations-list-container app-active-conversation-list-item {
  --active-conversation-list-item-padding-top: var(--active-conversations-list-item-padding-top);
  --active-conversation-list-item-padding-bottom: var(--active-conversations-list-item-padding-bottom);
  --active-conversation-list-item-padding-start: var(--active-conversations-list-item-padding-start);
  --active-conversation-list-item-padding-end: var(--active-conversations-list-item-padding-end);
  border-bottom: 1.5px solid var(--mi-background-color);
}
.active-conversations-list-container app-active-conversation-list-item:last-child {
  border: none;
}
.active-conversations-list-container app-active-conversation-list-item.selected {
  background-color: var(--mi-background-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
