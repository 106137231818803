import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalSearchComponent } from 'src/app/components/search/global-search/global-search.component';
import { SearchSuggestListComponent } from 'src/app/components/search/search-suggest-list/search-suggest-list.component';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
})
export class SearchDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('globalSearchComponent') globalSearchComponent: GlobalSearchComponent;
  @ViewChild('searchSuggestListComponent') searchSuggestListComponent: SearchSuggestListComponent;

  constructor(
    private modalController: ModalController,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.globalSearchComponent?.searchFieldClicked) {
        this.globalSearchComponent?.searchFieldClicked(null);
      }
    }, 250);
  }

  backButtonClicked(): void {
    this.modalController.dismiss();
  }

  get suggestList(): Array<string> {

    if (!this.searchSuggestListComponent) {
      return [];
    }

    return this.searchSuggestListComponent?.suggestList;
  }

}
