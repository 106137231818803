// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-ppt-flow-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 38px 32px;
  align-items: center;
}
.upload-ppt-flow-container .title {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-extra-large);
  color: var(--mi-black-color);
  margin-bottom: 20px;
  width: 450px;
  text-align: center;
}
.upload-ppt-flow-container app-drag-and-drop-upload {
  width: 100%;
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container {
  width: 100%;
  height: 310px;
  border: 1px dashed var(--mi-blue-color-17);
  background-color: var(--mi-light-purple-1-color);
  border-radius: 8px;
  padding: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container.solid-border {
  border: 1px solid var(--mi-blue-color-17);
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container .progress-bar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container .progress-bar-container .percentage-progress {
  color: var(--mi-primary-color);
  font-size: var(--font-size-extra-large-5);
  font-weight: var(--font-weight-medium);
  background-color: var(--mi-white-color);
  border-radius: 50%;
  height: 117px;
  width: 117px;
  align-content: center;
  text-align: center;
  margin-bottom: 23px;
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container .progress-bar-container ion-progress-bar {
  --progress-background: var(--mi-primary-color);
  height: 10px;
  width: 440px;
  margin-bottom: 45px;
  border-radius: 8px;
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container .progress-bar-container .text-inside-drag-and-drop {
  width: 350px;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  text-align: center;
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container app-icon {
  --icon-size: 80px;
  height: 150px;
  width: 150px;
  background-color: var(--mi-white-color);
  border-radius: 50%;
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container .text-inside-drag-and-drop {
  width: 350px;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  text-align: center;
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container .text-inside-drag-and-drop .browse-link {
  color: var(--mi-blue-color-17);
  text-decoration: none;
}
.upload-ppt-flow-container app-drag-and-drop-upload .drag-and-drop-container .text-inside-drag-and-drop .browse-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.upload-ppt-flow-container .file-information {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--mi-grey-color-24);
  font-size: var(--font-size-small);
  margin-top: 10px;
}
.upload-ppt-flow-container .attached-file-container {
  width: 100%;
  margin-top: 22px;
}
.upload-ppt-flow-container .dialog-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.upload-ppt-flow-container .dialog-footer .horizontal-line {
  border: 0;
  height: 1.5px;
  background-color: var(--mi-light-purple-1-color);
  margin: 10px -32px;
  width: calc(100% + 64px);
}
.upload-ppt-flow-container .dialog-footer .actions-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.upload-ppt-flow-container .dialog-footer .actions-container .additional-info-container {
  display: flex;
  padding: 8px 10px;
  background-color: var(--mi-blue-color-19);
  border-radius: 8px;
  font-size: var(--font-size-small);
  color: var(--mi-grey-color-13);
}
.upload-ppt-flow-container .dialog-footer .actions-container .additional-info-container app-icon {
  color: var(--mi-blue-color-20);
  margin-right: 5px;
}
.upload-ppt-flow-container .dialog-footer .actions-container .additional-info-container .additional-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.upload-ppt-flow-container .dialog-footer .actions-container ion-button {
  --background: var(--mi-primary-color);
  --box-shadow: none;
  border-radius: 4px;
  width: 116px;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  margin: 5px 0 5px 0;
  display: flex;
  text-transform: none;
}
.upload-ppt-flow-container .dialog-footer .actions-container ion-button.button-disabled {
  --background: var(--mi-grey-color-31);
}
.upload-ppt-flow-container .dialog-footer .actions-container ion-button.cancel {
  --background: var(--mi-white-color);
  color: var(--mi-black-color);
  border: 1px solid var(--mi-grey-color-15);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
