// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recommendation-items {
  padding: 15px;
}
.recommendation-items app-top-recommended-article-video::ng-deep .item-label-grid {
  height: 68px;
  display: grid;
  grid-template-rows: 43px 25px;
}
.recommendation-items app-top-recommended-article-video::ng-deep .recommendation-item-members {
  line-height: 2;
}
.recommendation-items .recommendation-item {
  margin-left: -18px;
  margin-bottom: 20px;
}
.recommendation-items .recommendation-item .recommendation-item-image-container app-article-image {
  --article-image-size: 68px;
  --article-image-border-radius: 0px;
}
.recommendation-items .recommendation-item .recommendation-item-image-container app-group-image {
  --group-image-size: 68px;
  --group-image-border-radius: 0px;
}
.recommendation-items .recommendation-item .item-label {
  margin-left: 10px;
}
.recommendation-items .recommendation-item .show-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.recommendation-items .recommendation-item .recommendation-item-name {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.recommendation-items .recommendation-item .recommendation-item-members {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
}
.recommendation-items .recommendation-item .recommendation-item-type {
  position: absolute;
  top: 62%;
  color: var(--mi-primary-color);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  background: var(--mi-background-color-journls);
  width: 68px;
  text-align: center;
  text-transform: capitalize;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
