// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --active-conversation-list-item-padding-top: 15px;
  --active-conversation-list-item-padding-bottom: 15px;
  --active-conversation-list-item-padding-start: 15px;
  --active-conversation-list-item-padding-end: 15px;
  --active-conversation-list-item-background-color: var(--mi-white-color);
}

.active-conversation-list-item-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 18px;
       column-gap: 18px;
  align-items: start;
  padding-top: var(--active-conversation-list-item-padding-top);
  padding-bottom: var(--active-conversation-list-item-padding-bottom);
  padding-left: var(--active-conversation-list-item-padding-start);
  padding-right: var(--active-conversation-list-item-padding-end);
  cursor: pointer;
}
.active-conversation-list-item-container .user-profile-image-container {
  grid-row: 1;
  grid-column: 1;
}
.active-conversation-list-item-container .user-profile-image-container app-user-profile-image {
  --user-image-size: 40px;
}
.active-conversation-list-item-container .user-profile-image-container ion-img {
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.active-conversation-list-item-container .conversation-info-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content;
  row-gap: 5px;
  align-items: start;
  overflow: hidden;
}
.active-conversation-list-item-container .conversation-info-container .name-and-status-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  align-items: center;
  -moz-column-gap: 18px;
       column-gap: 18px;
}
.active-conversation-list-item-container .conversation-info-container .name-and-status-container .full-name-container,
.active-conversation-list-item-container .conversation-info-container .name-and-status-container .event-name-container {
  grid-row: 1;
  grid-column: 1;
}
.active-conversation-list-item-container .conversation-info-container .name-and-status-container .full-name-container {
  position: relative;
  height: 20px;
}
.active-conversation-list-item-container .conversation-info-container .name-and-status-container .full-name-container app-user-full-name {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.active-conversation-list-item-container .conversation-info-container .name-and-status-container .event-name-container {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  display: inline-block;
  color: var(--ion-color-light-contrast);
}
.active-conversation-list-item-container .conversation-info-container .name-and-status-container .status-container {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.active-conversation-list-item-container .conversation-info-container .name-and-status-container .status-container .unread-message-flag {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--mi-primary-color);
  margin-right: 7px;
}
.active-conversation-list-item-container .conversation-info-container .name-and-status-container .status-container .last-active-info .active-ago {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
}
.active-conversation-list-item-container .message-or-status-container {
  grid-row: 2;
  grid-column: 1;
}
.active-conversation-list-item-container .message-or-status-container .status-info-container.attachment {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 3px;
       column-gap: 3px;
  align-items: center;
}
.active-conversation-list-item-container .message-or-status-container .status-info-container.attachment p {
  grid-row: 1;
  grid-column: 2;
}
.active-conversation-list-item-container .message-or-status-container .status-info-container.deleted {
  display: block;
}
.active-conversation-list-item-container .message-or-status-container .status-info-container app-icon {
  grid-row: 1;
  grid-column: 1;
  --icon-size: 15px;
  color: var(--mi-color-text-lite);
}
.active-conversation-list-item-container .message-or-status-container .status-info-container p {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: 1;
  color: var(--mi-secondary-color-2);
  margin: 0;
}
.active-conversation-list-item-container .message-or-status-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 36px;
  overflow: hidden;
}
.active-conversation-list-item-container .message-or-status-container app-multiline-text-with-ellipsis ::ng-deep {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
