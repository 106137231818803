export enum ImageType {
  IMAGE = 'image',
  VIDEO = 'video'
}

export interface ImageObject {
  _id: string;
  url: string;
  type: ImageType;
}
