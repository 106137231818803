import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonInfiniteScroll, IonRefresher } from '@ionic/angular';

// models
import { GroupMembersResponse } from '../../../services/yeti-protocol/chatter-api';
import { GroupMember } from '../../../services/groups/group.model';

// services
import { GroupsService } from '../../../services/groups/groups.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-group-participants-list',
  templateUrl: './group-participants-list.component.html',
  styleUrls: ['./group-participants-list.component.scss'],
})
export class GroupParticipantsListComponent implements AfterViewInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonRefresher) listRefresher: IonRefresher;

  @Input() groupId: string;
  @Input() groupMembersMaxCount: number;
  @Input() listTitle: string;

  @Output() openPublicUserProfile: EventEmitter<string> = new EventEmitter();

  groupMembers: Array<GroupMember> = [];
  private readonly start = 0;
  private count = 15;

  constructor(
    private groupsService: GroupsService,
    private toast: ToastService
  ) { }

  ngAfterViewInit(): void {
    if(this.groupMembersMaxCount) {
      this.count = this.groupMembersMaxCount;
    }

    if(this.groupId) {
      this.getGroupMembers().catch(err => {
      this.showError(err);
    });
    }
  }

  loadMoreUpdates(_event: Event): void {

    if (this.groupMembers.length < this.count) {
      this.disableInfiniteScroll(true);
      return;
    }

    const start = Math.floor(this.groupMembers.length / this.count);
    this.getGroupMembers(start, this.count).then(() => {
      // TODO: after total count is returned from server check if list is at the end and disable it
    }).catch(err => {
      this.showError(err.message);
    }).finally(() => {

      // Hide Infinite List Loader on Complete
      this.infiniteScroll.complete();
    });
  }

  getGroupMembers(start: number = this.start, count: number = this.count): Promise<Array<GroupMember>> {

    return this.groupsService.getGroupMembers(this.groupId, start, count).then((groupMembersResponse: GroupMembersResponse) => {
      const groupMembers = groupMembersResponse.result;

      this.groupMembers = [...this.groupMembers, ...groupMembers];
      return Promise.resolve(groupMembers);
    })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  refreshList(): void {
    this.groupMembers = [];
    this.getGroupMembers(this.start, this.count).catch(err => {
      this.showError(err.message);
    }).finally(() => {
      this.listRefresher.complete();
      this.disableInfiniteScroll(false);
    });
  }

  onOpenPublicUserProfile(userId: string): void {
    this.openPublicUserProfile.emit(userId);
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

}
