import { Component, Input, OnDestroy } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnDestroy {

  @Input() videoUrl: string;
  @Input() videoMimeType: string;
  @Input() videoThumbnailImageUrl: string;

  api: VgApiService;

  private loadedMetaDataSubscription: Subscription;

  ngOnDestroy(): void {
    this.loadedMetaDataSubscription?.unsubscribe();
  }

  onPlayerReady(source: VgApiService): void {
    this.api = source;

    this.loadedMetaDataSubscription = this.api.getDefaultMedia().subscriptions.loadedMetadata.pipe(
      debounceTime(300),
      distinctUntilChanged()
    )
      .subscribe(() => {
        setTimeout(() => {
          this.play();
        }, 50);
      });
  }

  play(): void {
    if (this.api?.isPlayerReady && this.api?.isMetadataLoaded) {
      this.api.play();
    }
  }
}
