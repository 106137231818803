// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-container {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
}
.review-container .image-container {
  position: absolute;
  top: 31px;
  left: -8px;
  width: 26px;
}
.review-container .treatment-container {
  padding-left: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
