import { Component, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { ShortPublicProfile } from '../../services/yeti-protocol/public-profile';

@Component({
  selector: 'app-myao-authors-dialog',
  templateUrl: './myao-authors-dialog.component.html',
  styleUrls: ['./myao-authors-dialog.component.scss'],
})
export class MyAoAuthorsDialogComponent {

  @Input() myAoAuthors: Array<ShortPublicProfile>;

  constructor(
    private router: Router,
    private modalController: ModalController,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
  ) { }

  async goToUserProfile(profile: ShortPublicProfile): Promise<void> {
    this.modalController.dismiss();
    await this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/public/profile/${profile.userId}`);
  }

  onClose(): void {
    this.modalController.dismiss();
  }

}
