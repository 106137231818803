import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-ao-event-link-button',
  templateUrl: './ao-event-link-button.component.html',
  styleUrls: ['./ao-event-link-button.component.scss'],
})
export class AoEventLinkButtonComponent extends BaseButtonComponent<void> {
  @Input() icon: string;
  @Input() actionTitle: string;
  @Input() actionSubtitle: string;
  @Input() showIcons = true;
}
