// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --message-container-padding-top-bottom: 12px;
  --message-container-padding-start-end: 14px;
  --message-container-border-radius: 8px;
  --message-container-background-color: var(--mi-purple-color-3);
}

.app-message-container {
  background-color: var(--message-container-background-color);
  padding: var(--message-container-padding-top-bottom) var(--message-container-padding-start-end);
  border-radius: var(--message-container-border-radius);
}
.app-message-container .title {
  color: var(--mi-secondary-color-1);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  line-height: var(--font-line-height-regular);
}
.app-message-container .text {
  color: var(--mi-grey-color-13);
  font-family: Suisse;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
}
.app-message-container .text .multiline-text-container {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.app-message-container .text ::ng-deep p {
  margin: 0px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
