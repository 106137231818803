// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --event-image-size: 68px;
  --event-image-border-radius: 10px;
}

.image-container {
  width: var(--event-image-size);
  height: var(--event-image-size);
  display: flex;
  flex-direction: column;
}
.image-container .event-image.with-banner {
  height: calc(var(--event-image-size) - 20px);
}
.image-container .event-image ion-thumbnail {
  width: 100%;
  height: 100%;
}
.image-container .event-image ion-thumbnail app-image ::ng-deep div,
.image-container .event-image ion-thumbnail app-image ::ng-deep div ion-img,
.image-container .event-image ion-thumbnail app-image ::ng-deep div ion-img::part(image) {
  height: 100%;
  width: 100%;
  border-top-left-radius: var(--event-image-border-radius);
  border-top-right-radius: var(--event-image-border-radius);
  object-fit: cover;
}
.image-container .event-image ion-thumbnail.default app-image ::ng-deep div,
.image-container .event-image ion-thumbnail.default app-image ::ng-deep div ion-img,
.image-container .event-image ion-thumbnail.default app-image ::ng-deep div ion-img::part(image) {
  object-fit: fill;
  background-color: var(--mi-thumbnails-background-color);
}
.image-container .banner {
  color: var(--mi-white-color);
  font-size: 11px;
  font-weight: var(--font-weight-semi-bold);
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin: 0;
  border-bottom-left-radius: var(--event-image-border-radius);
  border-bottom-right-radius: var(--event-image-border-radius);
  text-transform: capitalize;
  background-color: var(--mi-thumbnails-event-color);
}
.image-container .banner.meeting {
  background-color: var(--mi-blue-color-14);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
