import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// services
import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { ToastMode, ToastService } from 'src/app/services/toast.service';


// models
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';

@Component({
  selector: 'app-settings-translation-languages',
  templateUrl: './settings-translation-languages.component.html',
  styleUrls: ['./settings-translation-languages.component.scss'],
})
export class SettingsTranslationLanguagesComponent implements OnInit {

  profile: UserProfile;
  translationLanguagesListFormValid = false;
  saving = false;
  translationLanguage: string;

  constructor(
    private modalController: ModalController,
    private toast: ToastService,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  ngOnInit(): void {
    this.authService.userProfileAsObservable.subscribe(
      profile => {
        this.profile = profile;
        this.profile.translationLanguage = profile.translationLanguage || '';
        this.translationLanguage = this.profile.translationLanguage || '';
      }
    );
  }

  translationLanguageChanged(translationLanguage: string): void {
    this.translationLanguage = translationLanguage;
  }

  translationLanguagesListFormValidStatusChange(translationLanguagesListFormValid: boolean): void {
    this.translationLanguagesListFormValid = translationLanguagesListFormValid;
  }

  onClose(): void {
    this.modalController.dismiss(null, 'cancel');
  }

  saveTranslationLanguage(): void {
    this.saving = true;
    const interestsUpdate = { translationLanguage: this.translationLanguage };
    this.authService.updateProfile(this.contextService.currentContext.key, interestsUpdate)
      .then(profile => {
        this.toast.show('app.TranslationSettings.translation-language-saved');
        this.profile = profile;
        this.saving = false;
      })
      .catch(err => {
        console.error(err);
        this.toast.showWithMessage(err, null, ToastMode.ERROR);
        this.saving = false;
      });
  }

}
