import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import appConfig from 'src/config/config';

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.scss'],
})
export class QrCodeDialogComponent implements OnInit {
  qrCodeUrl: string;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit(): void {
    this.qrCodeUrl = appConfig.link.qrCodeButton;
  }

  close(): void {
    this.modalController.dismiss();
  }
}
