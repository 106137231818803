import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

export enum VerificationSteps {
  STEP_1 = 'step-1',
  STEP_2 = 'step-2',
  STEP_3 = 'step-3',
  STEP_4 = 'step-4',
}

// component
import { FileSelectComponent } from 'src/app/modules/file-select/components/file-select/file-select.component';

// models
import { VerificationDocumentType } from 'src/app/services/verification.model';
import appConfig from 'src/config/config';
import { Platform } from 'src/config/config.model';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';

// services
import { AuthService } from 'src/app/services/auth/auth.service';
import { UploadService } from 'src/app/services/upload.service';
import { VerificationRequest } from 'src/app/services/yeti-protocol/verification';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { AttachmentMimeType } from 'src/app/services/attachments.model';
import { FileSelectService } from 'src/app/modules/file-select/services/file-select.service';
import { SelectedFile, FileType, FileSelectLimits } from 'src/app/modules/file-select/file-select.model';
import { FileSelectScope } from '../../../modules/file-select/services/file-select.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit, OnDestroy {

  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() finish: EventEmitter<void> = new EventEmitter();

  @ViewChild('fileSelect') fileSelectEl: FileSelectComponent;

  VerificationSteps = VerificationSteps;

  currentStep: VerificationSteps = VerificationSteps.STEP_1;
  currentStepIndex = 0;
  documentType: VerificationDocumentType;
  selectedFile: SelectedFile;
  mimeType = '';
  fileType: FileType;
  maxImageFileSizeMb = FileSelectLimits.imageMaxFileSizeMB;
  user: UserProfile;
  verifying: boolean;
  FileSelectScope = FileSelectScope;

  private userSubscription: Subscription;
  private steps = [VerificationSteps.STEP_1, VerificationSteps.STEP_2, VerificationSteps.STEP_3, VerificationSteps.STEP_4];

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private uploadService: UploadService,
    private toast: ToastService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private fileSelectService: FileSelectService
  ) { }

  ngOnInit(): void {
    this.initFileTypeBasedOnDevice();

    this.userSubscription = this.authService?.userProfileAsObservable?.subscribe(userProfile => {
      this.user = userProfile;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  goToStepTwo(documentType: VerificationDocumentType): void {
    this.documentType = documentType;
    this.goToNextStep();
  }

  goToNextStep(): void {
    if (this.currentStepIndex + 1 >= this.steps.length) {
      this.closeModal();
      return;
    }

    this.currentStepIndex += 1;
    this.currentStep = this.steps[this.currentStepIndex];
  }

  goToPrevStep(): void {
    if (this.currentStepIndex - 1 < 0) {
      this.closeModal();
      return;
    }

    this.currentStepIndex -= 1;
    this.currentStep = this.steps[this.currentStepIndex];
  }

  initFileTypeBasedOnDevice(): void {
    switch (appConfig.platform) {
      case Platform.BROWSER:
      case Platform.PWA:
        this.mimeType = [AttachmentMimeType.JPEG, AttachmentMimeType.JPG, AttachmentMimeType.PNG, AttachmentMimeType.PDF].join(',');
        this.fileType = null;
        break;
      case Platform.ANDROID:
      case Platform.IOS:
      default:
        this.mimeType = this.fileSelectService?.defaultImageMimeTypes.join(',');
        this.fileType = FileType.IMAGE;
        break;
    }
  }

  fileSelected(selectedFiles: Array<SelectedFile>): void {

    if (!selectedFiles || !selectedFiles?.length || selectedFiles?.length < 1) {
      return;
    }

    this.selectedFile = selectedFiles[0];

    if (this.currentStep === VerificationSteps.STEP_2) {
      this.goToNextStep();
    }
  }

  selectFile(): void {
    this.fileSelectEl.selectFile();
  }

  closeModal(): void {
    this.modalController.dismiss();
  }

  submit(): void {

    if (this.verifying) {
      return;
    }

    const verificationData: VerificationRequest = {
      image: {
        key: 'image',
        file: this.selectedFile.file,
        mimeType: this.selectedFile.file.type,
        fileName: this.selectedFile.file.name
      },
      university: '',
      graduate: '',
      professional: 'true'
    }

    this.verifying = true;

    this.uploadService.uploadVerificationFile(verificationData).then(() => {
      this.getProfile().finally(() => {
        this.goToNextStep();
      });
    }).catch(errResponse => {
      this.toast.showWithMessage(errResponse, null, ToastMode.ERROR);
    }).finally(() => {
      this.verifying = false;
    });
  }

  getProfile(): Promise<UserProfile> {
    return this.authService.reloadProfile(this.contextService.currentContext.key);
  }

  closeButtonClicked(): void {
    this.close.emit();
  }

  finishClicked(): void {
    this.finish.emit();
  }

}
