// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --surgery-reference-padding-start: 15px;
  --surgery-reference-padding-end: 15px;
  --surgery-reference-padding-top: 0px;
  --surgery-reference-padding-bottom: 45px;
}

.surgery-reference-dialog {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
}
.surgery-reference-dialog .header {
  grid-row: 1;
  grid-column: 1;
}
.surgery-reference-dialog .header app-top-bar .action-button {
  background: none;
  outline: none;
  cursor: pointer;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-13);
}
.surgery-reference-dialog .content {
  grid-row: 2;
  grid-column: 1;
  padding-top: var(--surgery-reference-padding-top);
}
.surgery-reference-dialog .content ion-content {
  --padding-bottom: var(--surgery-reference-padding-bottom);
}

.fracture-select-page-container .content ion-content {
  --padding-bottom: 0;
}
.fracture-select-page-container .content ion-content .wrapper {
  height: 100%;
  padding-top: 39px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 26px;
}
.fracture-select-page-container .content ion-content .wrapper .info-container {
  grid-row: 1;
  grid-column: 1;
  padding-left: var(--surgery-reference-padding-start);
  padding-right: var(--surgery-reference-padding-end);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content;
  row-gap: 8.5px;
}
.fracture-select-page-container .content ion-content .wrapper .info-container .info {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 11px;
       column-gap: 11px;
  align-items: start;
}
.fracture-select-page-container .content ion-content .wrapper .info-container .info .icon-container {
  grid-row: 1;
  grid-column: 1;
}
.fracture-select-page-container .content ion-content .wrapper .info-container .info .icon-container ion-img {
  width: 37px;
  height: 37px;
}
.fracture-select-page-container .content ion-content .wrapper .info-container .info .info-text-wrapper {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  overflow-x: auto;
}
.fracture-select-page-container .content ion-content .wrapper .info-container .info .info-text-wrapper .text-container p {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-large);
  color: var(--mi-grey-color-16);
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
  display: block;
}
.fracture-select-page-container .content ion-content .wrapper .info-container .info .info-text-wrapper .text-container p span {
  font-weight: var(--font-weight-medium);
}
.fracture-select-page-container .content ion-content .wrapper .info-container .info .info-text-wrapper .selected-topics-container app-chips .classification-level-one {
  --case-classification-chip-selected-background-color: var(--mi-blue-color-4);
  --case-classification-chip-selected-color: var(--mi-grey-color-13);
}
.fracture-select-page-container .content ion-content .wrapper .info-container .info .info-text-wrapper .selected-topics-container app-chips .classification-level-two {
  --case-classification-chip-selected-background-color: var(--mi-content-groups-color-9);
  --case-classification-chip-selected-color: var(--mi-grey-color-13);
}
.fracture-select-page-container .content ion-content .wrapper .main-content {
  height: 100%;
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 34px;
}
.fracture-select-page-container .content ion-content .wrapper .main-content .list-title-container {
  grid-row: 1;
  grid-column: 1;
  padding-left: var(--surgery-reference-padding-start);
  padding-right: var(--surgery-reference-padding-end);
}
.fracture-select-page-container .content ion-content .wrapper .main-content .list-title-container .list-title {
  font-size: var(--font-size-large-1);
  font-weight: var(--font-weight-semi-bold);
  color: var(--mi-grey-color-16);
  margin: 0;
}
.fracture-select-page-container .content ion-content .wrapper .main-content .list-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  row-gap: 17px;
  padding-left: var(--surgery-reference-padding-start);
  padding-right: var(--surgery-reference-padding-end);
}
.fracture-select-page-container .content ion-content .wrapper .main-content .list-container app-surgery-reference-select-card:last-child {
  padding-bottom: var(--surgery-reference-padding-bottom);
}
.fracture-select-page-container .content ion-content .empty-state-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fracture-select-page-container .content ion-content .empty-state-container app-generic-empty-state {
  --image-size: 50px;
}
.fracture-select-page-container .content ion-content .empty-state-container app-generic-empty-state ::ng-deep .empty-state-container .icon app-icon {
  color: var(--mi-primary-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
