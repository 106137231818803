import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom, Observable, of } from 'rxjs';
import { SchemaValidator } from './yeti-protocol/tools/schema-validator';
import { catchError } from 'rxjs/operators';

export interface SchemaValidatorServiceConfig {
  schemasLocation: string;
  enabled: boolean;
}

export const SCHEMA_VALIDATOR_SERVICE_CONFIG = new InjectionToken<SchemaValidatorServiceConfig>('schema-validator.service.config');

@Injectable()
export class SchemaValidatorService {
  private validator: SchemaValidator;

  constructor(
    @Inject(SCHEMA_VALIDATOR_SERVICE_CONFIG) private config: SchemaValidatorServiceConfig,
    private httpClient: HttpClient
    ) {
    if (this.config.enabled) {
      this.validator = new SchemaValidator(this.config.schemasLocation, path => this.loadSchema(path));
    }else{
      console.log('schema validation disabled');
    }
  }

  isValid<T>(data: T, schemaName: string): Promise<T> {
    if (this.validator) {
      return this.validator.isValid<T>(data, schemaName);
    }
    return Promise.resolve(data);
  }

  // observable operator for object validation with json schema
  isValidOperator<T>(schemaName: string): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => {
      return new Observable(observer => {
        source.subscribe({
          next: (data: T) => {
            this.isValid(data, schemaName)
              .then(() => {
                observer.next(data);
              })
              .catch(err => {
                observer.error(err);
              });
          },
          error: (err: any) => {
            observer.error(err);
          }
        });
      });
    };
  }

  private loadSchema(schemaUrl: string): Promise<any> {
    return firstValueFrom(this.httpClient.get(schemaUrl).pipe(
      catchError(() => {
        return of();
      })
    ));
  }
}
