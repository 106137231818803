import { GroupListItem } from '../yeti-protocol/chatter-api';
import { Connection } from '../yeti-protocol/connections';
import { AOEvent } from '../yeti-protocol/event';

export const MAX_NUMBER_OF_SHARES = 3;

export enum SocialSharingApp {
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  EMAIL = 'email',
  WHATSAPP = 'whatsapp',
  OS_SHARING_OPTIONS = 'os-sharing-options',
  LINK_COPIED = 'linkCopied'
}

export enum ObjectForSharingType {
  ARTICLE = 'Article',
  GROUP = 'group',
  SURGERY_REFERENCE = 'surgery-reference',
  EVENT = 'event',
  CASE = 'case',
  PROFILE = 'profile',
  ORCID_ARTICLE = 'orcid-article'
}
export interface ObjectForSharingMeta {
  numberOfGroupsSharedTo?: number;
  numberOfEventsSharedTo?: number;
  groupsSharedTo?: Array<string>;
  eventsSharedTo?: Array<string>;
  profileFirstName?: string;
  profileLastName?: string;
  profileIsVerified?: boolean;
  profileOrganization?: string;
  profileDepartment?: string;
  profileCountry?: string;
}

export interface ObjectForSharing {
  _id: string;
  title: string;
  image?: string;
  defaultImage?: string;
  url: string;
  type: ObjectForSharingType;
  meta?: ObjectForSharingMeta; // needed if we want to send additional data to the share dialog
  wrapperUrl: string; // needed if we want to share internal link and we need rendering of meta data
}

// Share to objects: group, contact, event
export enum ShareToObjType {
  GROUP = 'group',
  CONTACT = 'contact',
  EVENT = 'event'
}

export interface ShareToObject {
  object: GroupListItem | Connection | AOEvent;
  type: ShareToObjType;
}
