import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { AuthService } from '../auth/auth.service';

import appConfig from 'src/config/config';

export interface AoSearchServiceConfig {
  searchAOServer: string;
}

@Injectable()
export class AoSearchService {

  config: AoSearchServiceConfig = appConfig;

  constructor(private authService: AuthService) { }

  searchAONetwork(searchTerm: string, start: number, count: number): Promise<any> {
    const params = {
      query: searchTerm,
      areas: 'ao-contact-search',
      channels: 'ao-contact-search',
      start,
      count
    };
    return firstValueFrom(this.authService.secureGet<any>(this.config.searchAOServer,
      {params: toAuthRequestParams(params)}));
  }
}
