import { Component, ElementRef, EventEmitter, Inject, Output } from '@angular/core';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-swipe-button',
  templateUrl: './swipe-button.component.html',
  styleUrls: ['./swipe-button.component.scss'],
})
export class SwipeButtonComponent {

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter();

  swipeableElementStyle = '';
  beginningColorStyle = '';
  endColorStyle = '';

  private swiperButtonClass = '.swipe-button-container';
  private mouseDown: boolean;
  private buttonEndPosition: number;
  private swipePercentage = 0;
  private offsetPercents = 0;

  constructor(
    private el: ElementRef,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  setStartPosition(event: MouseEvent): void {

    this.uiUtilsService.stopEventPropagation(event);
    const swiperButton = this.el.nativeElement.querySelector(this.swiperButtonClass);
    this.buttonEndPosition = swiperButton.offsetLeft + swiperButton?.clientWidth;
    this.offsetPercents = (85 / this.buttonEndPosition) * 100;

    if (this.swipePercentage < this.offsetPercents) {
      this.swipePercentage = this.offsetPercents;
    }

    this.mouseDown = true
  }

  mouseUp(): void {
    this.mouseDown = false;

    if (this.swipePercentage >= 90) {
      this.swipePercentage = 150;
      this.calculateStyles();
      this.buttonClicked.emit();
    } else {
      this.swipePercentage = this.offsetPercents;
      this.swipeableElementStyle = '';
      this.beginningColorStyle = '';
      this.endColorStyle = '';
    }
  }

  mouseMove(event: Event): void {

    if (!this.mouseDown) {
      return;
    }

    const eventXPosition = (event as any)?.center?.x;
    this.swipePercentage = (((eventXPosition) / this.buttonEndPosition) * 100);
    this.calculateStyles();
  }

  calculateStyles(): void {

    let translateXValue;

    if (this.swipePercentage >= this.offsetPercents) {
      translateXValue = this.swipePercentage - 100;
    }

    if (translateXValue > 0) {
      translateXValue = 0;
    }

    this.swipeableElementStyle = `transform: translateX(calc(${translateXValue}%))`;
    this.beginningColorStyle = `opacity: ${(100 - (this.swipePercentage - this.offsetPercents)) / 100}`;
    this.endColorStyle = `opacity: ${(this.swipePercentage - this.offsetPercents) / 100}`;
  }

}
