import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { EventFeedFacultyOnlyStateModel } from './event-feed-faculty-only.model';
import { Injectable } from '@angular/core';
import { EVENT_FEED_FACULTY_ONLY_STATE_KEY } from '../state-constants';
import { ChatterApiService } from 'src/app/services/chatter-api.service';
import { PostsFeedState } from '../posts-feed/posts-feed.state';
import { PostsFeedStateModel } from '../posts-feed/posts-feed.model';
import { PostListItem } from 'src/app/services/yeti-protocol/chatter-api';
import { EventFeedFacultyOnly } from './event-feed-faculty-only.actions';

const EVENT_FEED_FACULTY_ONLY_STATE_TOKEN = new StateToken<EventFeedFacultyOnlyStateModel>(
  EVENT_FEED_FACULTY_ONLY_STATE_KEY
);

@State({
  name: EVENT_FEED_FACULTY_ONLY_STATE_TOKEN
})
@Injectable()
export class EventFeedFacultyOnlyState extends PostsFeedState {

  constructor(chatterApiService: ChatterApiService) {
    super(chatterApiService)
  }

  @Selector()
  static state(state: EventFeedFacultyOnlyStateModel): EventFeedFacultyOnlyStateModel {
    return state;
  }

  @Selector()
  static allPostsFeedItems(state: PostsFeedStateModel): Array<PostListItem> {
    return state.allPostsFeedItems;
  }

  @Selector()
  static postsFeedItemsWithoutPinned(state: PostsFeedStateModel): Array<PostListItem> {
    return state.postsFeedItemsWithoutPinned;
  }

  @Selector()
  static pinnedPostsFeedItems(state: PostsFeedStateModel): Array<PostListItem> {
    return state.pinnedPostsFeedItems;
  }

  @Selector()
  static totalCount(state: PostsFeedStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static fetchPostsFeedItemsloading(state: PostsFeedStateModel): boolean {
    return state.fetchPostsFeedItemsloading;
  }

  @Action(EventFeedFacultyOnly.FetchPostsFeed)
  async fetchEventFeedFacultyOnly(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>,
    payload: EventFeedFacultyOnly.FetchPostsFeed
  ): Promise<void> {
    await this.fetchPostsFeed(ctx, payload, EventFeedFacultyOnly);
  }

  @Action(EventFeedFacultyOnly.FetchPostsFeedSuccess)
  fetchEventFeedFacultyOnlySuccess(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>,
    action: EventFeedFacultyOnly.FetchPostsFeedSuccess
  ): void {
    this.fetchPostsFeedSuccess(ctx, action, EventFeedFacultyOnly);
  }

  @Action(EventFeedFacultyOnly.FetchPostsFeedFailed)
  fetchEventFeedFacultyOnlyFailed(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>
  ): void {
    this.fetchPostsFeedFailed(ctx);
  }

  @Action(EventFeedFacultyOnly.InsertPostsFeedItemBeforeIndex)
  insertEventFeedFacultyOnlyItemBeforeIndex(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>,
    action: EventFeedFacultyOnly.InsertPostsFeedItemBeforeIndex): void {
    this.insertPostsFeedItemBeforeIndex(ctx, action, EventFeedFacultyOnly);
  }

  @Action(EventFeedFacultyOnly.RemovePostsFeedItem)
  removeEventFeedFacultyOnlyItem(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>,
    action: EventFeedFacultyOnly.RemovePostsFeedItem): void {
    this.removePostsFeedItem(ctx, action, EventFeedFacultyOnly);
  }

  @Action(EventFeedFacultyOnly.UpdatePostsFeedItem)
  updateEventFeedFacultyOnlyItem(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>,
    action: EventFeedFacultyOnly.UpdatePostsFeedItem): void {
    this.updatePostsFeedItem(ctx, action, EventFeedFacultyOnly);
  }

  @Action(EventFeedFacultyOnly.SetPinnedAndNotPinnedPostsFeedItems)
  setPinnedAndNotPinnedEventFeedFacultyOnlyItems(ctx: StateContext<EventFeedFacultyOnlyStateModel>): void {
    this.setPinnedAndNotPinnedPostsFeedItems(ctx);
  }

  @Action(EventFeedFacultyOnly.PinPostsFeedItem)
  async pinFeedItem(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>,
    payload: EventFeedFacultyOnly.PinPostsFeedItem
  ): Promise<void> {
    this.pinPostsFeedItem(ctx, payload, EventFeedFacultyOnly);
  }

  @Action(EventFeedFacultyOnly.PinPostsFeedItemSuccess)
  pinFeedItemSuccess(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>,
    action: EventFeedFacultyOnly.PinPostsFeedItemSuccess
  ): void {
    this.pinPostsFeedItemSuccess(ctx, action, EventFeedFacultyOnly);
  }

  @Action(EventFeedFacultyOnly.PinPostsFeedItemFailed)
  pinFeedItemFailed(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>
  ): void {
    this.pinPostsFeedItemFailed(ctx);
  }

  @Action(EventFeedFacultyOnly.UnpinPostsFeedItem)
  async unpinFeedItem(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>,
    payload: EventFeedFacultyOnly.UnpinPostsFeedItem
  ): Promise<void> {
    this.unpinPostsFeedItem(ctx, payload, EventFeedFacultyOnly);
  }

  @Action(EventFeedFacultyOnly.UnpinPostsFeedItemSuccess)
  unpinFeedItemSuccess(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>,
    action: EventFeedFacultyOnly.UnpinPostsFeedItemSuccess
  ): void {
    this.unpinPostsFeedItemSuccess(ctx, action, EventFeedFacultyOnly);
  }

  @Action(EventFeedFacultyOnly.UnpinPostsFeedItemFailed)
  unpinFeedItemFailed(
    ctx: StateContext<EventFeedFacultyOnlyStateModel>
  ): void {
    this.unpinPostsFeedItemFailed(ctx);
  }

  @Action(EventFeedFacultyOnly.SetParentTypeAndParentId)
  setFeedParentTypeAndParentId(
    ctx: StateContext<PostsFeedStateModel>,
    action: EventFeedFacultyOnly.SetParentTypeAndParentId
  ): void {
    this.setParentTypeAndParentId(ctx, action);
  }

  @Action(EventFeedFacultyOnly.UpdatePostsFeedItemsOwnerFollowingStatus)
  updateFeedItemsOwnerFollowingStatus(
    ctx: StateContext<PostsFeedStateModel>,
    action: EventFeedFacultyOnly.UpdatePostsFeedItemsOwnerFollowingStatus): void {
    this.updatePostsFeedItemsOwnerFollowingStatus(ctx, action, EventFeedFacultyOnly);
  }
}
