// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-img {
  width: 68px;
  height: 68px;
}
.post-img app-article-image {
  --article-image-size: 68px;
  --article-image-border-radius: 10px;
}

.post-text {
  margin: var(--mi-standard-margin) 0px;
}
.post-text .article-title {
  height: 42px;
  margin-bottom: var(--mi-standard-margin);
  overflow: hidden;
  white-space: normal;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  line-height: var(--font-line-height-large);
}
.post-text .post-category {
  background: transparent;
  color: var(--mi-blue-color-2);
  border: 1px solid var(--mi-blue-color-2);
  border-radius: 10px;
  padding: 3px 10px;
}
.post-text .journal {
  font-weight: bold;
}
.post-text .likes-num {
  display: flex;
  padding-top: 10px;
}
.post-text .likes-num .like-icon {
  color: var(--mi-blue-color-2);
}
.post-text .likes-num .number {
  padding-left: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
