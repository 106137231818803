// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --case-image-upload-edit-side-padding: 15px;
}

.case-image-upload-edit-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto min-content;
}
.case-image-upload-edit-container .gallery-container {
  grid-row: 1;
  grid-column: 1;
  padding: var(--case-image-upload-edit-side-padding);
  background-color: var(--mi-grey-color-21);
}
.case-image-upload-edit-container .gallery-container swiper .image-card {
  --mi-card-margin-top: 0px;
  --mi-card-margin-bottom: 0px;
  --mi-card-margin-start: 0px;
  --mi-card-margin-end: 0px;
  --mi-card-padding-bottom: 0px;
  --mi-card-padding-start: 0px;
  --mi-card-padding-end: 0px;
  width: 330px;
  min-height: 440px;
}
.case-image-upload-edit-container .gallery-container swiper .image-card.valid ::ng-deep ion-card {
  border: 1px solid var(--mi-success);
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  row-gap: 10px;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  row-gap: 10px;
  align-items: center;
  padding-left: var(--case-image-upload-edit-side-padding);
  padding-right: var(--case-image-upload-edit-side-padding);
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .header .text-container {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: min-content;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .header .text-container .stage-of-treatment-label-container {
  grid-row: 1;
  grid-column: 1;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .header .text-container .stage-of-treatment-label-container .label {
  margin: 0;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  text-align: start;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .header .text-container .stage-of-treatment-label-container .label.error {
  color: var(--mi-red-color-2);
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .header .text-container .image-number-and-count-container {
  grid-row: 1;
  grid-column: 2;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .header .text-container .image-number-and-count-container .image-number-and-count {
  margin: 0;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-grey-color-11);
  text-align: start;
  white-space: nowrap;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container {
  grid-row: 2;
  grid-column: 1;
  padding-left: var(--case-image-upload-edit-side-padding);
  padding-right: var(--case-image-upload-edit-side-padding);
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .image-background-container {
  width: 100%;
  height: 296px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .image-background-container .loading-container {
  width: 100%;
  height: 100%;
  background-color: var(--mi-grey-color-8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .image-background-container .loading-container app-icon {
  --icon-size: 220px;
  width: 220px;
  height: 220px;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .image-background-container .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 2.5px);
  height: calc(100% + 2.5px);
  object-fit: fill;
  filter: blur(3.75px);
  z-index: 1;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .image-background-container .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: var(--mi-grey-color-13);
  z-index: 2;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .image-background-container .image {
  width: 100%;
  height: 100%;
  z-index: 3;
  object-fit: contain;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .image-background-container .image.full-size-image.loading {
  opacity: 0;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .play-image {
  position: relative;
  z-index: 3;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .play-image .play-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .image-container .play-image .play {
  position: relative;
  top: -34px;
  left: -34px;
  width: 68px;
  height: 68px;
  --background: black;
  display: block;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .action-buttons-container {
  grid-row: 3;
  grid-column: 1;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  justify-content: start;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: var(--case-image-upload-edit-side-padding);
  background-color: var(--mi-light-purple-1-color);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .action-buttons-container .btn {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  align-items: center;
  background: none;
  outline: none;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .action-buttons-container .btn.disabled {
  opacity: 0.3;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .action-buttons-container .btn app-icon-button {
  --icon-button-background-width: 35px;
  --icon-button-background-height: 35px;
  --icon-button-background-color: transparent;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 22px;
  --icon-button-icon-color: var(--mi-grey-color-13);
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .action-buttons-container .btn .edit-button {
  --icon-button-icon-size: 19px;
}
.case-image-upload-edit-container .gallery-container swiper .image-card .card-content .action-buttons-container .btn .btn-label {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
  white-space: nowrap;
}
.case-image-upload-edit-container .bottom-bar-container {
  grid-row: 2;
  grid-column: 1;
  height: 100%;
  background-color: var(--mi-white-color);
  padding-top: 3px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
