import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

export interface AcceptRejectItemData {
  id: string;
  imageUrl: string;
  userId: string;
  isAOMember: string;
  firstName: string;
  lastName: string;
  isVerified: string;
  orcid: string;
  entityTitle: string;
  infoBubbleMessage?: string;
}

@Component({
  selector: 'app-accept-reject-list',
  templateUrl: './accept-reject-list.component.html',
  styleUrls: ['./accept-reject-list.component.scss'],
})
export class AcceptRejectListComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() items: Array<AcceptRejectItemData> = [];
  @Input() showSeeAll: boolean;
  @Input() count: number;
  @Input() parentSource = ActionSource.unspecified;
  @Input() source: ActionSource | string;

  @Output() accepted: EventEmitter<{ id: string, userId: string }> = new EventEmitter();
  @Output() rejected: EventEmitter<{ id: string, userId: string }> = new EventEmitter();
  @Output() imageClicked: EventEmitter<string> = new EventEmitter();
  @Output() seeAll: EventEmitter<void> = new EventEmitter();
  @Output() infoBubbleMessageClicked: EventEmitter<{ id: string, userId: string }> = new EventEmitter();

  ngOnInit(): void {
    this.source = this.source || this._source;
  }

  onAccepted(data: { id: string, userId: string }): void {
    this.accepted.emit(data);
  }

  onRejected(data: { id: string, userId: string }): void {
    this.rejected.emit(data);
  }

  onImageClicked(userId: string): void {
    this.imageClicked.emit(userId);
  }

  seeAllClicked(): void {
    this.seeAll.emit();
  }

  onInfoBubbleMessageClicked(data: { id: string, userId: string }): void {
    this.infoBubbleMessageClicked.emit(data);
  }

  get _source(): string {
    const componentSource = ActionSource.acceptRejectList;

    return this.parentSource && this.parentSource !== ActionSource.unspecified ?
      `${this.parentSource}-${componentSource}` : componentSource;
  }

}
