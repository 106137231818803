import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {GroupMember} from '../../services/groups/group.model';
import {GroupMembersResponse} from '../../services/yeti-protocol/chatter-api';
import {GroupsService} from '../../services/groups/groups.service';
import {IonInfiniteScroll, ModalController} from '@ionic/angular';
import {AppTranslationService} from '../../services/app-translation.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';

const ITEMS_PER_PAGE = 15;

@Component({
  selector: 'app-all-pending-requests',
  templateUrl: './see-all-pending-requests-pending-invitations.component.html',
  styleUrls: ['./see-all-pending-requests-pending-invitations.component.scss'],
})
export class SeeAllPendingRequestsPendingInvitationsComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Input() groupId: string;
  @Input() status: string;
  memberRequestsList: Array<GroupMember> = [];
  totalItemsCount: number;
  constructor(
    private groupsService: GroupsService,
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    private appTranslationService: AppTranslationService,
    private toast: ToastService,
) { }

  ngOnInit(): void {
    if (this.groupId) {
      this._getPendingRequests(0, ITEMS_PER_PAGE).then(res => {
        this.memberRequestsList = [...this.memberRequestsList, ...res.items];
        this.totalItemsCount = res.total;
      });
    }
  }

  acceptOrDeclineRequest(participant: GroupMember, value: string): void {
    this.groupsService.updateMemberRequests(participant.memberRequestId, value, participant.userId, this.groupId)
      .then(_result => {
        this.memberRequestsList.splice(this.memberRequestsList.findIndex(function(i){
          return i.userId === participant.userId;
        }), 1);
      })
      .catch(async (_err) => {
        this.dialogs.showErrorDialog(await this.appTranslationService.get('app.common.something-went-wrong'));
      })
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  loadMore(): void {

    if (!this.memberRequestsList) {
      return;
    }

    if (this.memberRequestsList.length >= this.totalItemsCount) {
      this.disableInfiniteScroll(true);
      return;
    }

    const pageIndex = Math.floor(this.memberRequestsList.length / ITEMS_PER_PAGE);

    this._getPendingRequests(pageIndex, ITEMS_PER_PAGE).then(res => {
      this.memberRequestsList = [...this.memberRequestsList, ...res.items];
      this.totalItemsCount = res.total;
    }).catch(err => {
        this.toast.showWithMessage(err, null, ToastMode.ERROR);
    }).finally(() => {
      // Hide Infinite List Loader on Complete
      this.infiniteScroll?.complete();
    });
  }

  _getPendingRequests(pageIndex: number, pageSize: number): Promise<{items: Array<GroupMember>, total: number}> {
    return this.groupsService.getMemberRequests(this.groupId, pageIndex, pageSize, this.status)
      .then((response: GroupMembersResponse) => {
        if (response) {
          return {
            items:  response.result,
            total: response.totalItemsCount
          }
        }
        return {items: [], total: 0};
      });
  }


  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }
}
