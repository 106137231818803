// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --rich-text-document-editor-padding-top: 0px;
  --rich-text-document-editor-padding-bottom: 0px;
  --rich-text-document-editor-padding-start: 20px;
  --rich-text-document-editor-padding-end: 20px;
  --rich-text-document-editor-background-color: var(--mi-white-color);
  --rich-text-document-editor-font-size: var(--font-size-regular);
  --rich-text-document-editor-font-weight: var(--font-weight-regular);
  --rich-text-document-editor-font-color: var(--mi-secondary-color-2);
  --rich-text-document-editor-placeholder-font-color: var(--mi-color-text-lite);
  --rich-text-document-editor-spacing-unit: 0.3em;
}

.rich-text-document-editor-container ckeditor {
  --ck-spacing-unit: var(--rich-text-document-editor-spacing-unit);
  --ck-color-base-background: var(--rich-text-document-editor-background-color);
}
.rich-text-document-editor-container ckeditor.hide-toolbar ::ng-deep .ck-editor {
  max-height: 100%;
}
.rich-text-document-editor-container ckeditor.hide-toolbar ::ng-deep .ck-editor .ck-editor__top {
  display: none;
}
.rich-text-document-editor-container ckeditor ::ng-deep .ck-content {
  border: none;
  box-shadow: none;
  padding: var(--rich-text-document-editor-padding-top) var(--rich-text-document-editor-padding-end) var(--rich-text-document-editor-padding-bottom) var(--rich-text-document-editor-padding-start);
}
.rich-text-document-editor-container ckeditor ::ng-deep .ck-content .mention {
  --ck-color-mention-background: none;
  --ck-color-mention-text: var(--mi-secondary-color-2);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
}
.rich-text-document-editor-container ckeditor ::ng-deep .ck-editor {
  font-size: var(--rich-text-document-editor-font-size);
  font-weight: var(--rich-text-document-editor-font-weight);
  color: var(--rich-text-document-editor-font-color);
}
.rich-text-document-editor-container ckeditor ::ng-deep .ck-editor .ck-editor__editable_inline :first-child,
.rich-text-document-editor-container ckeditor ::ng-deep .ck-editor .ck-editor__editable_inline :last-child,
.rich-text-document-editor-container ckeditor ::ng-deep .ck-editor .ck-editor__editable_inline p {
  margin: 0;
}
.rich-text-document-editor-container ckeditor ::ng-deep .ck-editor .ck-editor__editable_inline p {
  white-space: pre-wrap;
}
.rich-text-document-editor-container ckeditor ::ng-deep .ck-editor .ck-editor__editable_inline .ck-placeholder {
  color: var(--rich-text-document-editor-placeholder-font-color);
}
.rich-text-document-editor-container ckeditor ::ng-deep .ck-editor .ck-editor__editable_inline a {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
.rich-text-document-editor-container ckeditor ::ng-deep .ck-editor .ck-editor__editable_inline.overflow {
  overflow: hidden;
}
.rich-text-document-editor-container ckeditor ::ng-deep .ck-link_selected {
  background: none;
}
.rich-text-document-editor-container ckeditor:not(.disabled) ::ng-deep .ck-editor .ck-editor__editable_inline {
  -webkit-user-select: text;
  user-select: text;
  -webkit-user-modify: read-write;
}
.rich-text-document-editor-container .see-full-post-text {
  color: var(--mi-primary-color);
  cursor: pointer;
}
.rich-text-document-editor-container.overflow ckeditor ::ng-deep .ck-editor .ck-editor__editable_inline {
  overflow: hidden;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
