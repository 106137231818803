import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-verification-header',
  templateUrl: './verification-header.component.html',
  styleUrls: ['./verification-header.component.scss'],
})
export class VerificationHeaderComponent {

  @Input() hideBackButton: boolean;
  @Input() hideCloseButton: boolean;

  @Output() goBack: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter(); // if opened from a page and not dialog, should trigger history back

  backButtonClicked(): void {
    this.goBack.emit();
  }

  closeButtonClicked(): void {
    this.close.emit();
  }

}
