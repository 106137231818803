import {
  AfterViewInit, Component, ElementRef, EventEmitter,
  Inject, Input, NgZone, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { FileSelectComponent } from 'src/app/modules/file-select/components/file-select/file-select.component';
import { FileSelectLimits, FileType, SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { AttachmentMimeType, ImageAttachment, isVideo } from 'src/app/services/attachments.model';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { DocumentUploadedNotification, UploadFile } from 'src/app/services/yeti-protocol/upload';
import { UploadService } from 'src/app/services/upload.service';
import {
  PersonalMediaGalleryDocument,
  UploadPersonalMediaGalleryDocumentsResponse,
  UploadPersonalMediaGalleryDocumentsSuccessResponse
} from 'src/app/services/yeti-protocol/personal-media-gallery';
import appConfig from 'src/config/config';
import { Platform } from 'src/config/config.model';
import { VideosUiService } from 'src/app/services/videos-ui.service';
import { FileSelectService } from 'src/app/modules/file-select/services/file-select.service';
import { ActionContent, GalleryConfig, ImageGalleryActions } from 'src/app/modules/file-select/services/image-gallery.model';
import { ImageGalleryService } from 'src/app/modules/file-select/services/image-gallery.service';
import { Subscription } from 'rxjs';
import { Post } from 'src/app/services/groups/group.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AlertController } from '@ionic/angular';
import { SocketService } from 'src/app/services/socket.service';
import { FileSelectScope } from '../../modules/file-select/services/file-select.service';
import { ActionTracked, TrackingRequest, UploadMediaTrackingParam } from '../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';

interface PostAttachmentsUploadEditComponentConfig {
  platform: Platform
}

@Component({
  selector: 'app-post-attachments-upload-edit',
  templateUrl: './post-attachments-upload-edit.component.html',
  styleUrls: ['./post-attachments-upload-edit.component.scss'],
})
export class PostAttachmentsUploadEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('fileSelectImage') fileSelectImage: FileSelectComponent;
  @ViewChild('fileSelectDocument') fileSelectDocument: FileSelectComponent;
  @ViewChild('fileSelectVideo') fileSelectVideo: FileSelectComponent;

  @Input() post: Post;
  @Input() images: Array<PersonalMediaGalleryDocument> = [];
  @Input() documents: Array<PersonalMediaGalleryDocument> = [];
  @Input() isAnonymousOwner: boolean;
  @Input() watermarked: boolean;

  @Output() imagesChanged: EventEmitter<Array<PersonalMediaGalleryDocument>> = new EventEmitter();
  @Output() documentsChanged: EventEmitter<Array<PersonalMediaGalleryDocument>> = new EventEmitter();
  @Output() closeSettings: EventEmitter<void> = new EventEmitter();

  config: PostAttachmentsUploadEditComponentConfig = appConfig;
  maxImagesCount = 5;
  maxImageFileSizeMb = FileSelectLimits.imageMaxFileSizeMB;
  maxFileSizePdfMb = FileSelectLimits.documentMaxFileSizeMB;
  maxVideosCount = 1;
  maxVideoFileSizeMb = FileSelectLimits.maxVideoFileSizeMb;
  FileType = FileType;
  AttachmentMimeType = AttachmentMimeType;
  postImagesUploading = 0;
  postImagesProcessing = 0;
  postDocumentsUploading = 0;
  postDocumentsProcessing = 0;
  FileSelectScope = FileSelectScope;

  private uploadingAttachmentsMessageContainerClass = '.uploading-attachments-info-message-container';
  private processingAttachmentsMessageContainerClass = '.processing-attachments-info-message-container';
  private performingImageGalleryAction: boolean;
  private imageGalleryActionSubscription: Subscription;
  private documentUploadedSubscription: Subscription;

  constructor(
    private uploadService: UploadService,
    private toast: ToastService,
    private videosUiService: VideosUiService,
    private fileSelectService: FileSelectService,
    private imageGalleryService: ImageGalleryService,
    private appTranslationService: AppTranslationService,
    private alertController: AlertController,
    private socketService: SocketService,
    private zone: NgZone,
    private el: ElementRef,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  ngOnInit(): void {
    this.imageGalleryActionSubscription = this.imageGalleryService?.imageActionStream?.subscribe((action: ActionContent) =>
      this.executeImageGalleryAction(action));

    this.documentUploadedSubscription = this.socketService?.documentUploaded?.subscribe((doc: DocumentUploadedNotification) => {

      this.zone.run(() => {

        this.postImagesProcessing -= 1;

        if (!isVideo(doc.mimeType)) {
          return;
        }

        const videoImage = this.images.find(img => img.fileName === doc.fileName);

        if (videoImage) {
          videoImage.previewUrl = doc.previewUrl;
          videoImage.fullUrl = doc.fullUrl;
          videoImage._id = doc._id;
          videoImage.blurredUrl = doc.blurredUrl;
          videoImage.deleted = doc.deleted;
          videoImage.watermarkedPreviewUrl = doc.watermarkedPreviewUrl;
          videoImage.contentLength = doc.contentLength;
          videoImage.createdDate = doc.createdDate;
        }
      });
    })
  }

  ngAfterViewInit(): void {
    this.addMessageToBodyElement(this.uploadingAttachmentsMessageContainerClass);
    this.addMessageToBodyElement(this.processingAttachmentsMessageContainerClass);
  }

  ngOnDestroy(): void {
    this.removeMessageFromBodyElement(this.uploadingAttachmentsMessageContainerClass);
    this.removeMessageFromBodyElement(this.processingAttachmentsMessageContainerClass);
    this.imageGalleryActionSubscription?.unsubscribe();
    this.documentUploadedSubscription.unsubscribe();
  }

  addImages(): void {

    if (this.checkImagesVideosLimitReached()) {
      return;
    }

    this.fileSelectImage.selectFile();
  }

  imagesSelected(imageFiles: Array<SelectedFile>): void {

    this.documents = [];
    this.emitDocumentsChanged();

    this.fileSelectService.checkMaxFileCountExceeded(
      [...this.images, ...imageFiles],
      this.maxImagesCount,
      this.FileSelectScope.POST)
      .then(checkedImageFiles => {

        checkedImageFiles = checkedImageFiles.filter(imageFile => !imageFile?._id);

        this._addImageFiles(checkedImageFiles);
        this.uploadPersonalMediaGalleryImages(checkedImageFiles);
      });
  }

  addVideos(): void {

    if (this.checkImagesVideosLimitReached()) {
      return;
    }

    this.documents = [];
    this.emitDocumentsChanged();

    this.fileSelectVideo.selectFile();
  }

  checkImagesVideosLimitReached(): boolean {

    if (this.currentMaxImagesVideosCount === 0) {

      this.toast.show(
        'app.components.PostAttachmentsUploadEditComponent.images-videos-limit-reached-error-text',
        'app.components.PostAttachmentsUploadEditComponent.images-videos-limit-reached-error-title',
        ToastMode.ERROR);

      return true;
    }

    return false;
  }

  videosSelected(videoFiles: Array<SelectedFile>): void {
    this._addImageFiles(videoFiles);
    this.uploadPersonalMediaGalleryImages(videoFiles);
  }

  isVideo(image: PersonalMediaGalleryDocument): boolean {
    return isVideo(image.mimeType);
  }

  get currentMaxImagesVideosCount(): number {
    const imagesVideosCount = (this.images || [])?.length || 0;
    const maxCount = this.maxImagesCount - imagesVideosCount;
    return maxCount < 0 ? 0 : maxCount;
  }

  removeAttachmentFiles(): void {
    this.images = [];
    this.documents = [];
    this.emitImagesChanged();
    this.emitDocumentsChanged();
  }

  openGalleryModal(): void {
    const galleryConfig: GalleryConfig = {
      images: this.imageAndVideoAttachments,
      galleryId: null,
      allowImageRemove: true,
      allowImageEdit: true,
      scope: FileSelectScope.POST,
      imageGalleryStrategy: null
    };

    this.closeSettings.emit();
    this.imageGalleryService.openImageGallery(galleryConfig);
  }

  get imageAndVideoAttachments(): Array<ImageAttachment> {

    let attachments: Array<ImageAttachment>;

    if (this.images) {
      attachments = this.images.map(attachment => {
        return {
          ...attachment,
          url: attachment.previewUrl,
          fullImageUrl: attachment?.fullUrl,
          videoUrl: isVideo(attachment.mimeType) ? attachment?.fullUrl : null,
          mimeType: isVideo(attachment.mimeType) ? AttachmentMimeType.MP4 : attachment.mimeType
        }
      });

      if (!this.isAnonymousOwner && this.watermarked) {
        return attachments.map(img => {
          img.videoUrl = isVideo(img.mimeType) ? img?.fullUrl : null
          img.fullUrl = img?.fullWatermarkUrl;
          img.previewUrl = img?.watermarkedPreviewUrl;
          return img;
        })
      }

      return attachments;
    }

    return [];
  }

  async executeImageGalleryAction(action: ActionContent): Promise<any> {

    if (this.performingImageGalleryAction) {
      return;
    }

    const index = action?.index;
    const imageId = action?._id;

    this.performingImageGalleryAction = true;

    switch (action.action) {
      case ImageGalleryActions.REMOVE_IMAGE:
        try {
          await this.removeGroupPostImageAttachment(index, imageId);
          this.performingImageGalleryAction = false;
        } catch (err) {
          this.performingImageGalleryAction = false;
        }
        break;
      default:
        this.performingImageGalleryAction = false;
        break;
    }
  }

  async removeGroupPostImageAttachment(index: number, imageId: string): Promise<any> {

    if (!imageId) {
      return;
    }

    if (this.post && this.images?.length === 1) {

      const title = await this.appTranslationService.get('app.components.CreatePost.one-image-required-warning-title');
      const text = await this.appTranslationService.get('app.components.CreatePost.one-image-required-warning-text');
      const buttonText = await this.appTranslationService.get('app.components.CreatePost.one-image-required-warning-button-text');

      const alert = await this.alertController.create({
        header: title,
        message: text,
        buttons: [buttonText]
      });

      alert.present();
      return;
    }

    this.images.splice(index, 1);
    this.imageGalleryService.updateGalleryImages(this.imageAndVideoAttachments);
    this.emitImagesChanged();
  }

  emitImagesChanged(): void {
    this.imagesChanged.emit(this.images);
  }

  _addImageFiles(files: Array<SelectedFile>): void {
    if (!this.images) {
      this.images = [];
    }

    this.images = [...files.map(item => {
      return {
        _id: '',
        deleted: false,
        createdDate: '',
        previewUrl: '',
        blurredUrl: '',
        fullUrl: '',
        mimeType: item.file.type,
        fileName: this.getFileName(item),
        contentLength: item.file.size
      }
    }), ...this.images];
  }

  uploadPersonalMediaGalleryImages(attachmentFiles: Array<SelectedFile>): void {
    this.postImagesUploading += 1;
    const uploadData: Array<UploadFile> = [];

    attachmentFiles.forEach(image => {

      uploadData.push({
        key: 'image',
        file: image.file,
        fileName: this.getFileName(image),
        mimeType: image.file.type
      })
    });

    let isVideoUpload = false;
    this.uploadPersonalMediaGalleryFiles(uploadData)
      .then((response: UploadPersonalMediaGalleryDocumentsSuccessResponse) => {

        this.images = this.images.map(image => {
          if (response?.result.length && (!image?._id || image?._id?.length === 0)) {
            const galleryImage = response?.result[0];
            response?.result.splice(0, 1);
            if (isVideo(galleryImage.mimeType)) {
              isVideoUpload = true;
              return image; // will be updated with socket notification
            }
            return galleryImage;
          } else {
            return image;
          }
        });

        this.emitImagesChanged();

      }).catch(error => {
        this.showError(error?.message);
      }).finally(() => {
        this.postImagesUploading -= 1;
        if (isVideoUpload) {
          this.postImagesProcessing += 1;
        }
      });
  }

  attachDocument(): void {
    this.fileSelectDocument.selectFile();
  }

  documentsSelected(files: Array<SelectedFile>): void {
    const documents = files?.length > 0 ? files : null;

    if (documents) {
      this.removeAttachmentFiles();
      this.uploadPersonalMediaGalleryDocuments(documents);
    }
  }

  emitDocumentsChanged(): void {
    this.documentsChanged.emit(this.documents);
  }

  uploadPersonalMediaGalleryDocuments(attachmentFiles: Array<SelectedFile>): void {
    this.postDocumentsUploading += 1;
    const uploadData: Array<UploadFile> = [];

    attachmentFiles.forEach(image => {

      uploadData.push({
        key: 'document',
        file: image.file,
        fileName: this.getFileName(image),
        mimeType: image.file.type
      })
    });

    this.uploadService.uploadPersonalMediaGalleryDocuments(uploadData, this.FileSelectScope.POST)
      .then((response: UploadPersonalMediaGalleryDocumentsSuccessResponse) => {

        this.documents = response?.result;
        this.emitDocumentsChanged();

      }).catch(error => {
        this.showError(error?.message);
      }).finally(() => {
        this.postDocumentsUploading -= 1;
      });
  }

  uploadPersonalMediaGalleryFiles(uploadData: Array<UploadFile>): Promise<UploadPersonalMediaGalleryDocumentsResponse> {
    return this.uploadService.uploadPersonalMediaGalleryDocuments(uploadData, this.FileSelectScope.POST);
  }

  getFileName(selectedFile: SelectedFile): string {

    const fileName = (appConfig.platform === Platform.IOS || appConfig.platform === Platform.ANDROID) &&
      isVideo(selectedFile.file.type) ? selectedFile?.fileName : selectedFile.file.name;

    if (!fileName) {
      return selectedFile?.fileName || selectedFile.file.name;
    }

    return fileName;
  }

  get attachmentsUploading(): boolean {
    return this.postImagesUploading > 0 || this.postDocumentsUploading > 0;
  }

  get attachmentsProcessing(): boolean {
    return this.postImagesProcessing > 0 || this.postDocumentsProcessing > 0;
  }

  trackUploadLimitationsFail(fileName: string, mimeType: string, scope: FileSelectScope, reason: string): Promise<void> {
    const paramsToTrack: UploadMediaTrackingParam = {
      objectId: fileName,
      objectType: mimeType,
      scope: scope,
      uploadFailed: true, // dont think we need this ... since we send this only if upload fails
      reason: reason
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.uploaded,
      params: paramsToTrack
    };

    return this.trackingService.track(trackData).catch(_err => {
      console.error('Something went wrong on upload action: ' + _err);
    });
  }

  private getTrackingParamFileName(selectedFiles: Array<SelectedFile>): string {

    if (!selectedFiles || !selectedFiles?.length) {
      return '';
    }

    const fileNames: Array<string> = [];
    let fileName = '';

    selectedFiles.forEach((selectedFile: SelectedFile) => {
      fileNames.push(selectedFile.file?.name || selectedFile?.fileName);
    });

    fileName = fileNames.length > 1 ? fileNames.join(',') : fileNames[0];
    return fileName;
  }

  private getTrackingParamFileMimeType(selectedFiles: Array<SelectedFile>): string {

    if (!selectedFiles || !selectedFiles?.length) {
      return '';
    }

    const fileMimeTypes: Array<string> = [];
    let fileMimeType = '';

    selectedFiles.forEach((selectedFile: SelectedFile) => {
      fileMimeTypes.push(selectedFile?.file?.type);
    });

    fileMimeType = fileMimeTypes.length > 1 ? fileMimeTypes.join(',') : fileMimeTypes[0];
    return fileMimeType;
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  private addMessageToBodyElement(messageContainerClass: string): void {
    const uploadingMessageEl = this.el.nativeElement.querySelector(messageContainerClass);

    if (uploadingMessageEl) {
      document.body.appendChild(uploadingMessageEl);
    }
  }

  private removeMessageFromBodyElement(messageContainerClass: string): void {
    const uploadingMessageElements = document.querySelectorAll(messageContainerClass);

    if (uploadingMessageElements?.length) {
      uploadingMessageElements.forEach(el => document.body.removeChild(el));
    }
  }

}
