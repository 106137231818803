import { Inject, Injectable } from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {toAuthRequestParams} from '../auth/logic/auth-logic.utils';

import {UserProfile} from '../yeti-protocol/auth/mi';

import {ActionSource, ActionTracked, GenericTrackingParam, TrackingRequest} from '../yeti-protocol/tracking';
import {
  Journal,
  JournalByIdRequestParams,
  JournalByIdResponse,
  JournalListRequestParams,
  JournalListResponse,
  JournalListSuccessResponse,
  JournalsLatestPublicationsRequestParams,
  JournalsLatestPublicationsResponse
} from '../yeti-protocol/journal';

// services
import {SchemaValidatorService} from '../schema-validator.service';
import {AuthService} from '../auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import appConfig from 'src/config/config';
import { TRACKING_SERVICE, TrackingService } from '../tracking/tracking.model';

export interface JournalsServiceConfig {
  serverUrl: string;
}

export enum JournalsFilter {
  ALL = 'all',
  FOLLOWED = 'followed',
  NOT_FOLLOWED = 'not-followed'
}

@Injectable({
  providedIn: 'root'
})
export class JournalsService {
  config: JournalsServiceConfig = {
    serverUrl: `${appConfig.backendUrlIonic}journals`,
  }
  protected userProfile: UserProfile;
  private defaultStart = 0;
  private defaultCount = 10;

  constructor(
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private schemaValidator: SchemaValidatorService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) {
    this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.userProfile = userProfile;
    });
  }

  getJournalById(journalId: string, start: number = this.defaultStart, count: number = this.defaultCount): Promise<JournalByIdResponse> {
    const getRequestUrl = `${this.config.serverUrl}/${journalId}`;

    const params: JournalByIdRequestParams = {
      appId: this._appId,
      start: start,
      count: count
    }

    return firstValueFrom(this.authService.secureGet<JournalByIdResponse>(getRequestUrl, {params: toAuthRequestParams(params)}).pipe(
      this.schemaValidator.isValidOperator('JournalByIdResponse')
    ));
  }

  followUnfollowJournal(journalId: string, follow: boolean, source: ActionSource = ActionSource.unspecified): Promise<boolean> {
    return this.updateUserFollowingJournals(journalId, follow, source)
      .then(() => {
        return true;
      }).catch(error => {
        console.log(error);
        return false;
      });
  }

  updateUserFollowingJournals(journalId: string, follow: boolean, source: ActionSource = ActionSource.unspecified): Promise<UserProfile> {
    if(!this.userProfile) {
      return;
    }
    if (typeof this.userProfile.followingJournals === 'string') {
      // TODO: after server will return array in all cases
      this.userProfile.followingJournals = [];
    }

    let followingJournals = [...this.userProfile.followingJournals];
    if(follow) {
      if (!followingJournals.includes(journalId)) {
        followingJournals.push(journalId);
      }
    } else {
      followingJournals = followingJournals.filter(followingJournalId => {
        return followingJournalId !== journalId;
      })
    }
    return this.authService.updateProfile(this.contextService.currentContext.key, {followingJournals})
      .then(updatedUserProfile => {
        this.userProfile = updatedUserProfile;
        this.trackFollowUnFollowJournalAction(journalId, follow, source);
        return updatedUserProfile;
      });
  }

  getRecommendedJournals(start: number = this.defaultStart, count: number = this.defaultCount): Promise<Array<Journal>> {
    const params: JournalListRequestParams = {
      recommended: true,
      appId: this._appId,
      start,
      count
    }

    return this.fetchJournals(params)
      .then(response => {
        return this._processJournalsResponse(response);
      });
    }

  getFollowingJournals(start: number = this.defaultStart, count: number = this.defaultCount): Promise<Array<Journal>> {
    const params: JournalListRequestParams = {
      following: true,
      appId: this._appId,
      start,
      count
    };

    return this.fetchJournals(params)
      .then(response => {
        return this._processJournalsResponse(response);
      });
  }

  getMixedJournals(start: number = this.defaultStart, count: number = this.defaultCount): Promise<Array<Journal>> {
    const params: JournalListRequestParams = {
      appId: this._appId,
      start,
      count
    }

    return this.fetchJournals(params)
      .then(response => {
        return this._processJournalsResponse(response);
      });
  }

  getJournalsLatestPublications(start: number = this.defaultStart, count: number = this.defaultCount):
  Promise<JournalsLatestPublicationsResponse> {
    const params: JournalsLatestPublicationsRequestParams = {
      appId: this._appId,
      start,
      count
    }

    return this.authService.asserIsSignedIn()
      .then(() => {
        const getRequestUrl = `${this.config.serverUrl}/latestPublications`;

        return firstValueFrom(this.authService.secureGet<JournalsLatestPublicationsResponse>(getRequestUrl,
          {params: toAuthRequestParams(params)}).pipe(
            this.schemaValidator.isValidOperator('JournalsLatestPublicationsResponse')
        ));
      });
  }

  _processJournalsResponse(response: JournalListResponse): Array<Journal> {
    if(response as JournalListSuccessResponse) {
      return (response as JournalListSuccessResponse).result;
    }
    return [];
  }

  private fetchJournals(params: JournalListRequestParams): Promise<JournalListResponse> {
    return this.authService.asserIsSignedIn()
      .then(() => {
        const getRequestUrl = this.config.serverUrl;
        return firstValueFrom(this.authService.secureGet<JournalListResponse>(getRequestUrl, {params: toAuthRequestParams(params)})
          .pipe(
            this.schemaValidator.isValidOperator('JournalListResponse')
        ));
      });
  }

  private trackFollowUnFollowJournalAction(journalId: string, follow: boolean, source: ActionSource = ActionSource.unspecified): void {
    const paramsToTrack: GenericTrackingParam = {
      objectId: journalId,
      objectType: 'journal'
    }
    if(source && source !== ActionSource.unspecified) {
      paramsToTrack.source = source;
    }

    const trackData: TrackingRequest = {
      action: (follow) ? ActionTracked.journalFollowed : ActionTracked.journalUnFollowed,
      params: paramsToTrack
    };

    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track journal followed or unfollowed action');
    });
  }

  get _appId(): string {
    return this.contextService.currentContext.key;
  }
}
