import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrcIdArticle } from '../../services/yeti-protocol/article';

@Component({
  selector: 'app-orcid-article-list-item',
  templateUrl: './orcid-article-list-item.component.html',
  styleUrls: ['./orcid-article-list-item.component.scss'],
})
export class OrcidArticleListItemComponent {

  @Input() orcIdArticle: OrcIdArticle;

  @Output() orcIdArticleClick: EventEmitter<OrcIdArticle> = new EventEmitter();

  constructor() { }

  orcIdArticleClicked(): void {
    this.orcIdArticleClick.emit(this.orcIdArticle);
  }

}
