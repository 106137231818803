import { ContextConfigInterface } from '../config.model';

export class ContextConfig implements ContextConfigInterface {
  key = 'spine';
  label = 'Spinal Orthopedic and Neuro Surgery';
  homePath = '/spine';
  logoImageName = 'ao-spine.svg';
  iconName = 'spec-spine';
  aoMembershipUrl = 'https://www.aofoundation.org/spine/membership';
  moreEventsLink = 'https://www.aofoundation.org/spine/education/courses-events';
}
