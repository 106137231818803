import { Injectable } from '@angular/core';

// models
import { Translator, TranslatorLanguage, Translators} from './translation.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  getLanguages(translator: Translator): Array<TranslatorLanguage> {
    return Translators && Translators[translator] ? Translators[translator].langs : [];
  }

  getLanguageCode(translator: Translator, languageName: string): string {
    const languages = this.getLanguages(translator);
    const language = languages.find((languageObj: TranslatorLanguage) => languageObj.name === languageName);
    return language ? language.key : null;
  }

  getLanguageName(translator: Translator, languageCode: string): string {
    const languages = this.getLanguages(translator);
    const language = languages.find((languageObj: TranslatorLanguage) => languageObj.key === languageCode);
    return language ? language.name : null;
  }

}
