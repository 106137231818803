// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.line-separator {
  flex-grow: 1;
  border-bottom: solid 1px var(--mi-grey-color-23);
  height: calc(var(--font-line-height-small) / 2);
}

.date-value {
  flex-grow: 0;
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  margin: 0 20px;
  color: var(--mi-color-text-lite);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
