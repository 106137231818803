// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-mi-card {
  --mi-card-padding-start: 20px;
  --mi-card-padding-end: 20px;
}
app-mi-card ion-card {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
}
app-mi-card .card-title {
  text-align: left;
  padding-left: var(--mi-standard-padding);
  margin-top: 15px;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  line-height: var(--font-line-height-extra-large);
}
app-mi-card ion-card-content {
  padding: 0;
}
app-mi-card ion-card-content .title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
}
app-mi-card ion-card-content.article-content-container {
  padding: var(--mi-card-padding-top) var(--mi-card-padding-end) 0 var(--mi-card-padding-start);
}
app-mi-card ion-card-content.article-content-container .article-image {
  width: 100%;
  height: 150px;
  padding-bottom: 5px;
}
app-mi-card ion-card-content.article-content-container .title-container {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}
app-mi-card ion-card-content.article-content-container .title-container .title {
  height: 62px;
  overflow: hidden;
}
app-mi-card ion-card-content.article-content-container app-chips {
  --padding-bottom: 10px;
}
app-mi-card ion-card-content.article-content-container .authors-container {
  padding-bottom: 15px;
}
app-mi-card ion-card-content.article-content-container .authors-container .authors {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  text-align: left;
  color: var(--mi-grey-color);
  margin: 0;
}
app-mi-card ion-card-content.article-content-container .journal-and-pubdate-container {
  max-width: 370px;
  padding-bottom: 10px;
}
app-mi-card ion-card-content.article-content-container .journal-and-pubdate-container .journal-and-pubdate {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: var(--mi-primary-color);
  margin: 0;
}
@media all and (max-width: 480px) {
  app-mi-card ion-card-content.article-content-container .journal-and-pubdate-container {
    max-width: 260px;
  }
}
app-mi-card ion-card-content.video-content-container .video-container {
  width: 100%;
  height: 165px;
  padding-bottom: 10px;
}
app-mi-card ion-card-content.video-content-container .video-container .video {
  width: 100%;
  height: 100%;
}
app-mi-card ion-card-content.video-content-container .video-info-container {
  padding: 0 var(--mi-card-padding-end) 0 var(--mi-card-padding-start);
}
app-mi-card ion-card-content.video-content-container .video-info-container .title-container {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}
app-mi-card ion-card-content.video-content-container .video-info-container .date-container {
  width: 100%;
  height: 100%;
  padding-bottom: 7px;
}
app-mi-card ion-card-content.video-content-container .video-info-container .date-container .date {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  text-align: left;
  color: var(--mi-color-text-lite);
  margin: 0;
}
app-mi-card ion-card-content.video-content-container .video-info-container app-chips {
  --padding-bottom: 10px;
}
app-mi-card app-mi-card-actions {
  --mi-card-actions-padding-top: 0;
  --mi-card-actions-padding-bottom: 0;
  --mi-card-actions-padding-start: var(--mi-card-padding-start);
  --mi-card-actions-padding-end: var(--mi-card-padding-end);
  width: 100%;
  height: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
