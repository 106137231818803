import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { FileSelectComponent } from 'src/app/modules/file-select/components/file-select/file-select.component';
import { FileSelectLimits, FileType, SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { AttachmentMimeType } from 'src/app/services/attachments.model';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { UploadFile } from 'src/app/services/yeti-protocol/upload';
import { UploadService } from 'src/app/services/upload.service';
import { ClinicalCase } from 'src/app/services/yeti-protocol/clinical-case';
import {
  PersonalMediaGalleryDocument,
  UploadPersonalMediaGalleryDocumentsSuccessResponse
} from 'src/app/services/yeti-protocol/personal-media-gallery';
import { addUnitToFileSize } from 'src/app/services/utils/string-utils';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { isMobilePlatform } from 'src/app/services/utils/utils';

import { Platform } from 'src/config/config.model';

import appConfig from 'src/config/config';
import { MobilePdfViewComponent } from 'src/app/dialogs/mobile-pdf-view/mobile-pdf-view.component';
import { ModalController } from '@ionic/angular';
import { LinkOpenerService } from 'src/app/services/link-opener.service';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { FileSelectScope } from '../../modules/file-select/services/file-select.service';

interface CaseDocumentsUploadEditPreviewComponentConfig {
  platform: Platform;
}

@Component({
  selector: 'app-case-documents-upload-edit-preview',
  templateUrl: './case-documents-upload-edit-preview.component.html',
  styleUrls: ['./case-documents-upload-edit-preview.component.scss'],
})
export class CaseDocumentsUploadEditPreviewComponent implements AfterViewInit, OnDestroy {

  @ViewChild('fileSelectDocument') fileSelectDocument: FileSelectComponent;

  @Input() clinicalCase: ClinicalCase;
  @Input() documents: Array<PersonalMediaGalleryDocument> = [];
  @Input() isCaseOwner: false;
  @Input() updatingClinicalCase: false;
  @Input() hideAddDocumentsButton: false;
  @Input() showEmptyStateBtn: boolean;

  @Output() documentsChanged: EventEmitter<Array<PersonalMediaGalleryDocument>> = new EventEmitter();

  config: CaseDocumentsUploadEditPreviewComponentConfig = appConfig;

  FileType = FileType;
  AttachmentMimeType = AttachmentMimeType;
  maxDocumentsCount = FileSelectLimits.maxFiles;
  maxDocumentFileSizeMb = FileSelectLimits.documentMaxFileSizeMB;
  documentsUploading = false;
  FileSelectScope = FileSelectScope;

  private uploadingDocumentsMessageContainerClass = '.uploading-case-documents-info-message-container';

  constructor(
    private toast: ToastService,
    private uploadService: UploadService,
    private el: ElementRef,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private modalController: ModalController,
    private linkOpener: LinkOpenerService,
    private dialogs: DialogsUIService
  ) { }

  ngAfterViewInit(): void {
    this.addMessageToBodyElement(this.uploadingDocumentsMessageContainerClass);
  }

  ngOnDestroy(): void {
    this.removeMessageFromBodyElement(this.uploadingDocumentsMessageContainerClass);
  }

  get currentMaxDocumentsCount(): number {
    const maxCount = this.maxDocumentsCount - (this.documents?.length || 0);
    return maxCount < 0 ? 0 : maxCount;
  }

  addDocuments(): void {

    if (this.currentMaxDocumentsCount === 0) {

      return this.toast.show(
        'app.components.CaseDocumentsUploadEditPreviewComponent.documents-limit-reached-error-text',
        'app.components.CaseDocumentsUploadEditPreviewComponent.documents-limit-reached-error-title',
        ToastMode.ERROR);
    }

    this.fileSelectDocument.selectFile();
  }

  documentsSelected(imageFiles: Array<SelectedFile>): void {
    this.uploadPersonalMediaGalleryDocuments(imageFiles);
  }

  uploadPersonalMediaGalleryDocuments(documentFiles: Array<SelectedFile>): void {

    this.documentsUploading = true;
    const uploadData: Array<UploadFile> = [];

    documentFiles.forEach(document => {

      uploadData.push({
        key: 'document',
        file: document.file,
        fileName: document?.file?.name,
        mimeType: document.file.type
      })
    });

    this.uploadService.uploadPersonalMediaGalleryDocuments(uploadData, this.FileSelectScope.CASE)
      .then((response: UploadPersonalMediaGalleryDocumentsSuccessResponse) => {
        const uploadedDocs = response?.result || [];
        this.documents = [...this.documents || [], ...uploadedDocs];

        this.emitDocumentsChanged();

      }).catch(error => {
        this.showError(error?.message);
      }).finally(() => {
        this.documentsUploading = false;
      });
  }

  get showActionsContainer(): boolean {
    if(this.clinicalCase){
      return this.isCaseOwner;
     }
     return true;
  }

  get showSkeleton(): boolean {
    return this.documentsUploading || this.updatingClinicalCase;
  }

  getFileSize(document: PersonalMediaGalleryDocument): string {
    return addUnitToFileSize(document?.contentLength || 0);
  }

  async removeDocument(index: number): Promise<void> {

    let shouldRemove = false;

    try {
      shouldRemove = await this.showShouldRemoveDocumentDialog();
    } catch (err) {
      console.log(err);
      return;
    }

    if (shouldRemove && this.documents?.length > 0) {
      this.documents.splice(index, 1);
      this.emitDocumentsChanged();
    }
  }

  onDocumentClick(event: Event, document: PersonalMediaGalleryDocument): void {

    this.uiUtilsService.stopEventPropagation(event);

    // added this because on many places fullUrl was missing
    if (!document?.fullUrl) {
      document.fullUrl = (document as any)?.url;
    }

    if (isMobilePlatform(this.config.platform)) {
      this.displyMobilePdfViewDialog(document);
    } else {
      this.linkOpener.openDocumentLink(document.fullUrl);
    }
  }

  async displyMobilePdfViewDialog(document: PersonalMediaGalleryDocument): Promise<void> {
    const pdfViewDialog = await this.modalController.create({
      component: MobilePdfViewComponent,
      cssClass: 'mobile-pdf-view-dialog',
      componentProps: {
        documentInfo: document
      }
    });
    await pdfViewDialog.present();
  }

  private emitDocumentsChanged(): void {
    this.documentsChanged.emit(this.documents);
  }

  private addMessageToBodyElement(messageContainerClass: string): void {
    const uploadingMessageEl = this.el.nativeElement.querySelector(messageContainerClass);

    if (uploadingMessageEl) {
      document.body.appendChild(uploadingMessageEl);
    }
  }

  private removeMessageFromBodyElement(messageContainerClass: string): void {
    const uploadingMessageElements = document.querySelectorAll(messageContainerClass);

    if (uploadingMessageElements?.length) {
      uploadingMessageElements.forEach(el => document.body.removeChild(el));
    }
  }

  private showError(msg: string): void {
    this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
  }

  private async showShouldRemoveDocumentDialog(): Promise<boolean> {

    const removeActionKey = 'remove';
    const cancelActionKey = 'cancel';

    const showShouldRemoveDocumentDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.components.CaseDocumentsUploadEditPreviewComponent.delete-document-warning-title'
        },
        message: {
          translationKey: 'app.components.CaseDocumentsUploadEditPreviewComponent.delete-document-warning-text'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.cancel',
            },
            className: 'secondary'
          },
          {
            key: removeActionKey,
            label: {
              translationKey: 'app.common.remove',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(showShouldRemoveDocumentDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === removeActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

}
