import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { VerificationService } from '../../services/verification.service';
import { UserImageService } from 'src/app/services/user-image-service/user-image.service';
import { Subscription } from 'rxjs';

// models
import { VerificationStatus } from 'src/app/services/verification.model';
import { isNumber } from 'lodash';

@Component({
  selector: 'app-profile-settings-get-verified-card',
  templateUrl: './profile-settings-get-verified-card.component.html',
  styleUrls: ['./profile-settings-get-verified-card.component.scss'],
})
export class ProfileSettingsGetVerifiedCardComponent implements OnInit, OnDestroy, OnChanges {

  @Input() profile: UserProfile;
  @Input() showChangeProfileImageButton = false;
  @Output() editProfile: EventEmitter<UserProfile> = new EventEmitter();

  imageUrl: string;
  userImageSubscription: Subscription;
  VerificationStatus = VerificationStatus;


  constructor(
    private verificationService: VerificationService,
    private userImageService: UserImageService
  ) { }

  ngOnChanges(): void {
    this.userImageSubscription = this.userImageService.userImageUrl$.subscribe(imageUrl => {
      if (imageUrl !== '')
        this.imageUrl = imageUrl;
    })
  }

  ngOnInit(): void {
    if (this.profile) {
      this.imageUrl = this.profile.profileImageUrl;
    }
  }

  ngOnDestroy(): void {
    this.userImageSubscription?.unsubscribe();
  }

  async editPersonalSettings(): Promise<void> {
    this.editProfile.emit(this.profile);
  }

  async getVerified(): Promise<void> {
    try {
      const verificationStatus = await this.verificationService.verify();
      const verificationStatusesArray = Object.values(VerificationStatus)?.filter(item => !isNumber(item));

      if (verificationStatusesArray?.indexOf(verificationStatus as VerificationStatus) > -1) {
        this.profile.verificationStatus = verificationStatus;
      }

    } catch (err) {
      console.log(err);
    }
  }
}
