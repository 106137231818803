import {Component, Input, OnInit} from '@angular/core';
import {
  Mention
} from '../../modules/rich-text-document-editor/rich-text-document-editor.model';

@Component({
  selector: 'app-user-mention-item',
  templateUrl: './user-mention-item.component.html',
  styleUrls: ['./user-mention-item.component.scss'],
})
export class UserMentionItemComponent implements OnInit {

  @Input() userMention: Mention;

  constructor() { }

  ngOnInit(): void {}

}
