import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SavedKnowledgeStateModel } from './saved-knowledge.model';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { SAVED_KNOWLEDGE_STATE_KEY } from '../state-constants';
import { SavedKnowledge } from './saved-knowledge.actions';
import { ItemTypes, RecommendationsBookmarksService } from 'src/app/services/recommendations-service/recommendations-bookmarks.service';
import {
  RecommendationsBookmarksResponsePayload,
  RecommendationsBookmarksResponseResultObject,
  SavedKnowledgeObject
} from 'src/app/services/yeti-protocol/recommendations-bookmarks';

const SAVED_KNOWLEDGE_STATE_TOKEN = new StateToken<SavedKnowledgeStateModel>(
  SAVED_KNOWLEDGE_STATE_KEY
);

@State({
  name: SAVED_KNOWLEDGE_STATE_TOKEN,
  defaults: {
    savedKnowledge: [],
    totalCount: 0,
    loading: false,
    meta: {}
  },
})
@Injectable()
export class SavedKnowledgeState {
  constructor(private recommendationsBookmarksService: RecommendationsBookmarksService) { }

  @Selector()
  static state(state: SavedKnowledgeStateModel): SavedKnowledgeStateModel {
    return state;
  }

  @Selector()
  static savedKnowledge(state: SavedKnowledgeStateModel): Array<RecommendationsBookmarksResponseResultObject> {
    return state.savedKnowledge;
  }

  @Selector()
  static totalCount(state: SavedKnowledgeStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static loading(state: SavedKnowledgeStateModel): boolean {
    return state.loading;
  }

  @Action(SavedKnowledge.FetchSavedKnowledge)
  async fetchSavedKnowledge(
    ctx: StateContext<SavedKnowledgeStateModel>,
    payload: SavedKnowledge.FetchSavedKnowledge
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      const savedKnowledgeData = (await this.recommendationsBookmarksService.getItemsList(
        ItemTypes.BOOKMARK,
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize
      )) as RecommendationsBookmarksResponsePayload;

      ctx.dispatch(
        new SavedKnowledge.FetchSavedKnowledgeSuccess(
          payload?.payloadParams,
          savedKnowledgeData
        )
      );
    } catch (err) {
      ctx.dispatch(
        new SavedKnowledge.FetchSavedKnowledgeFailed()
      );

      throw err;
    }
  }

  @Action(SavedKnowledge.FetchSavedKnowledgeSuccess)
  fetchSavedKnowledgeSuccess(
    ctx: StateContext<SavedKnowledgeStateModel>,
    action: SavedKnowledge.FetchSavedKnowledgeSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        savedKnowledge: action.response.result,
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    } else {
      ctx.patchState({
        savedKnowledge: [...state.savedKnowledge, ...action.response.result],
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    }
  }

  @Action(SavedKnowledge.FetchSavedKnowledgeFailed)
  fetchSavedKnowledgeFailed(
    ctx: StateContext<SavedKnowledgeStateModel>
  ): void {
    ctx.patchState({
      loading: false,
    });
  }

  @Action(SavedKnowledge.InsertSavedKnowledgeBeforeIndex)
  insertSavedKnowledgeBeforeIndex(
    ctx: StateContext<SavedKnowledgeStateModel>,
    action: SavedKnowledge.InsertSavedKnowledgeBeforeIndex): void {
    ctx.setState(
      patch<SavedKnowledgeStateModel>({
        savedKnowledge: insertItem<SavedKnowledgeObject>(action.savedKnowledgeObject, action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount + 1,
    });
  }

  @Action(SavedKnowledge.RemoveSavedKnowledge)
  removeSavedKnowledge(ctx: StateContext<SavedKnowledgeStateModel>, action: SavedKnowledge.RemoveSavedKnowledge): void {
    ctx.setState(
      patch<SavedKnowledgeStateModel>({
        savedKnowledge: removeItem<SavedKnowledgeObject>(action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount - 1,
    });
  }

  @Action(SavedKnowledge.UpdateSavedKnowledge)
  updateSavedKnowledge(ctx: StateContext<SavedKnowledgeStateModel>, action: SavedKnowledge.UpdateSavedKnowledge): void {
    ctx.setState(
      patch<SavedKnowledgeStateModel>({
        savedKnowledge:
          updateItem<SavedKnowledgeObject>(savedKnowledge =>
            savedKnowledge.item._id === action.savedKnowledgeObject.item._id, action.savedKnowledgeObject)
      })
    );
  }
}
