import { Component, Inject, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { AuthDialogPage, AuthUIServiceInterface, AUTH_UI_SERVICE } from 'src/app/services/auth/auth-ui.service.interface';
import appConfig from 'src/config/config';
import { BaseAuthPage } from '../auth-common/base-auth-page';
import { ResponsiveUtilsService } from '../../../services/utils/responsive-utils.service';

// models
import { VerificationStatus } from 'src/app/services/verification.model';

interface ContextInfo {
  key: string;
  title: string;
  icon: string;
  selected: boolean;
}

@Component({
  selector: 'app-auth-signup-context',
  templateUrl: './auth-signup-context.page.html',
  styleUrls: ['./auth-signup-context.page.scss'],
})
export class AuthSignUpContextPage extends BaseAuthPage implements OnInit {
  errorMessage = '';
  contexts: Array<ContextInfo> = [];
  appConfig = appConfig;
  VerificationStatus = VerificationStatus;

  constructor(
    nav: IonNav,
    @Inject(AUTH_UI_SERVICE) authUIService: AuthUIServiceInterface,
    private responsiveUtilsService: ResponsiveUtilsService
  ) {
    super(nav, authUIService);
  }

  ngOnInit(): void {
    this.authUIService.nav = this.nav;
    this.authUIService.getSignUpContexts()
      .then(contexts => {
        this.contexts = contexts.map(context => {
          return {
            key: context.key,
            title: context.label,
            icon: context.iconName,
            selected: false
          };
        });
      });
  }

  get hasSelection(): boolean {
    return !!this.contexts.find(item => {
      return item.selected;
    });
  }

  select(contextKey: string): void {
    this.contexts.forEach(context => {
      context.selected = context.key === contextKey;
    })
  }

  confirmSelection(): void {
    const context = this.contexts.find(item => {
      return item.selected;
    });
    if (!context) {
      return;
    }
    this.authUIService.updateUserProfile({homeDevision: context.key, defaultAllInterests: true})
      .then(() => {
        this.openNextPage();
      })
      .catch(err => {
        this.errorMessage = err.message;
      });
  }

  openNextPage(): Promise<any> {

    let nextPage: AuthDialogPage;

    return this.authUIService.getUserProfile().then(profile => {

      if (profile?.verificationStatus === VerificationStatus.VERIFIED) {
        nextPage = AuthDialogPage.SignUp06Confirm;
      } else {
        if (this.appConfig.authMainProfessionKeys) {
          nextPage = AuthDialogPage.SignUp03ChooseMainRoles;
        } else {
          nextPage = AuthDialogPage.SignUp04ChooseOtherRoles;
        }
      }

      return this.authUIService.navigate(nextPage);
    }).catch(err => {
      this.errorMessage = err?.message;
      return Promise.reject();
    });
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }
}

