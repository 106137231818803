import { Injectable } from '@angular/core';
import { ObjectRequestAccessStatus, ObjectRequestAccessType, ProfilePublicDataResponse } from '../../yeti-protocol/join-myao';
import { JoinMyAOObjectXServiceInterface } from './join-myao-object-x.service.interface';

@Injectable({
  providedIn: 'root'
})
export class JoinMyAOObjectProfileService implements JoinMyAOObjectXServiceInterface {

  getObjectPublicData(objectId: string): Promise<ProfilePublicDataResponse> {
    // NYI
    console.log(objectId);
    return Promise.resolve({ result: null });
  }

  getObjectRequestAccessStatus(objectId: string, type: ObjectRequestAccessType): Promise<ObjectRequestAccessStatus> {
    // NYI
    console.log(objectId);
    console.log(type);
    return Promise.resolve(ObjectRequestAccessStatus.NONE);
  }

  requestAccess(objectId: string): Promise<any> {
    // NYI
    console.log(objectId);
    return Promise.resolve();
  }


}
