import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { PEOPLE_YOU_MAY_KNOW_STATE_KEY } from '../state-constants';
import { PeopleYouMayKnow } from './people-you-may-know.actions';
import { PeopleYouMayKnowStateModel } from './people-you-may-know.model';
import { RecommendedContactsStrategy } from 'src/app/services/recommended-people/recommended-contacts-strategy';
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { IgnoreRecommendedContactSuccessResponse, PeopleListSuccessResponse } from 'src/app/services/yeti-protocol/clinical-expert';
import { ClinicalExpertsService } from 'src/app/services/clinical-experts.service';

const PEOPLE_YOU_MAY_KNOW_STATE_TOKEN = new StateToken<PeopleYouMayKnowStateModel>(
  PEOPLE_YOU_MAY_KNOW_STATE_KEY
);

@State({
  name: PEOPLE_YOU_MAY_KNOW_STATE_TOKEN,
  defaults: {
    peopleYouMayKnow: [],
    totalCount: 0,
    loading: false,
    ignorePersonLoading: false,
    disableConnectButtonForUsersObject: {}
  },
})
@Injectable()
export class PeopleYouMayKnowState {

  constructor(
    private recommendedContactsStrategy: RecommendedContactsStrategy,
    private clinicalExpertsService: ClinicalExpertsService,
  ) { }

  @Selector()
  static state(state: PeopleYouMayKnowStateModel): PeopleYouMayKnowStateModel {
    return state;
  }

  @Selector()
  static peopleYouMayKnow(state: PeopleYouMayKnowStateModel): Array<PublicProfile> {
    return state.peopleYouMayKnow;
  }

  @Selector()
  static totalCount(state: PeopleYouMayKnowStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static loading(state: PeopleYouMayKnowStateModel): boolean {
    return state.loading;
  }

  @Action(PeopleYouMayKnow.FetchPeopleYouMayKnow)
  async fetchPeopleYouMayKnow(
    ctx: StateContext<PeopleYouMayKnowStateModel>,
    payload: PeopleYouMayKnow.FetchPeopleYouMayKnow
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      const peopleYouMayKnowRes = await this.recommendedContactsStrategy.getRecommendedPeople(
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize,
        true
      ) as PeopleListSuccessResponse;


      ctx.dispatch(
        new PeopleYouMayKnow.FetchPeopleYouMayKnowSuccess(
          payload?.payloadParams,
          peopleYouMayKnowRes
        )
      );
    } catch (err) {

      ctx.dispatch(
        new PeopleYouMayKnow.FetchPeopleYouMayKnowFailed()
      );

      throw err;
    }
  }

  @Action(PeopleYouMayKnow.FetchPeopleYouMayKnowSuccess)
  fetchPeopleYouMayKnowSuccess(
    ctx: StateContext<PeopleYouMayKnowStateModel>,
    action: PeopleYouMayKnow.FetchPeopleYouMayKnowSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        peopleYouMayKnow: action.response.result,
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    } else {
      ctx.patchState({
        peopleYouMayKnow: [...state.peopleYouMayKnow, ...action.response.result],
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    }
  }

  @Action(PeopleYouMayKnow.FetchPeopleYouMayKnowFailed)
  fetchPeopleYouMayKnowFailed(
    ctx: StateContext<PeopleYouMayKnowStateModel>
  ): void {
    ctx.patchState({
      loading: false,
    });
  }

  @Action(PeopleYouMayKnow.InsertPersonBeforeIndex)
  insertPersonBeforeIndex(
    ctx: StateContext<PeopleYouMayKnowStateModel>,
    action: PeopleYouMayKnow.InsertPersonBeforeIndex
  ): void {

    ctx.setState(
      patch<PeopleYouMayKnowStateModel>({
        peopleYouMayKnow: insertItem<PublicProfile>(action.person, action.index)
      })
    );
  }

  @Action(PeopleYouMayKnow.RemovePerson)
  removePerson(
    ctx: StateContext<PeopleYouMayKnowStateModel>,
    action: PeopleYouMayKnow.RemovePerson
  ): void {

    const state = ctx.getState();

    const itemIndex = state.peopleYouMayKnow.findIndex(item =>
      item?.userId === action?.person?.userId);

    if (itemIndex === -1) {
      return;
    }

    ctx.setState(
      patch<PeopleYouMayKnowStateModel>({
        peopleYouMayKnow: removeItem<PublicProfile>(itemIndex)
      })
    );
  }

  @Action(PeopleYouMayKnow.UpdatePerson)
  updatePerson(
    ctx: StateContext<PeopleYouMayKnowStateModel>,
    action: PeopleYouMayKnow.UpdatePerson
  ): void {

    ctx.setState(
      patch<PeopleYouMayKnowStateModel>({
        peopleYouMayKnow: updateItem<PublicProfile>(item =>
          item?.userId === action?.person?.userId,
          action.person)
      })
    );
  }

  @Action(PeopleYouMayKnow.IgnorePerson)
  async ignorePerson(
    ctx: StateContext<PeopleYouMayKnowStateModel>,
    action: PeopleYouMayKnow.IgnorePerson
  ): Promise<void> {

    try {

      ctx.patchState({
        ignorePersonLoading: true,
      });

      const ignoreRes =
        await this.clinicalExpertsService.ignoreRecommendedContact(
          action.person.userId,
          action.source) as IgnoreRecommendedContactSuccessResponse;

      ctx.dispatch(
        new PeopleYouMayKnow.IgnorePersonSuccess(
          action.person,
          ignoreRes
        )
      );

    } catch (err) {
      ctx.dispatch(
        new PeopleYouMayKnow.IgnorePersonFailed()
      );
    }
  }

  @Action(PeopleYouMayKnow.IgnorePersonSuccess)
  async ignorePersonSuccess(
    ctx: StateContext<PeopleYouMayKnowStateModel>,
    action: PeopleYouMayKnow.IgnorePersonSuccess
  ): Promise<void> {

    const state = ctx.getState();

    const index = state.peopleYouMayKnow?.findIndex(person => person?.userId === action?.person?.userId);

    if (index !== -1) {
      ctx.dispatch(
        new PeopleYouMayKnow.RemovePerson(
          action.person
        )
      );
    }

    ctx.patchState({
      ignorePersonLoading: false,
    });
  }

  @Action(PeopleYouMayKnow.IgnorePersonFailed)
  async ignorePersonFailed(
    ctx: StateContext<PeopleYouMayKnowStateModel>
  ): Promise<void> {
    ctx.patchState({
      ignorePersonLoading: false,
    });
  }

  @Action(PeopleYouMayKnow.DisableConnectButtonForUser)
  async disableConnectButtonForUser(
    ctx: StateContext<PeopleYouMayKnowStateModel>,
    action: PeopleYouMayKnow.DisableConnectButtonForUser
  ): Promise<void> {

    const state = ctx.getState();

    const _disableConnectButtonForUsersObject = { ...state.disableConnectButtonForUsersObject || {} };

    if (action?.userId) {
      _disableConnectButtonForUsersObject[action?.userId] = true;

      ctx.patchState({
        ignorePersonLoading: false,
        disableConnectButtonForUsersObject: _disableConnectButtonForUsersObject
      });
    } else {
      ctx.patchState({
        ignorePersonLoading: false,
      });
    }
  }
}
