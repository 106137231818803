import { Component, Input, OnInit } from '@angular/core';
import { StreamItemExpertRecommendation } from 'src/app/services/yeti-protocol/my-stream';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { TopRecommendationType } from 'src/app/services/yeti-protocol/utils/enums';

@Component({
  selector: 'app-expert-recommendation',
  templateUrl: './expert-recommendation.component.html',
  styleUrls: ['./expert-recommendation.component.scss'],
})
export class ExpertRecommendationComponent implements OnInit {
  @Input() expertRecommendation: StreamItemExpertRecommendation;
  @Input() source: ActionSource;

  TopRecommendationType = TopRecommendationType;

  ngOnInit(): void { }

}
