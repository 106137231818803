// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --accept-reject-list-padding-start: 20px;
  --accept-reject-list-padding-end: 20px;
  --accept-reject-list-padding-top: 15px;
  --accept-reject-list-padding-bottom: 15px;
}

.accept-reject-list-container {
  width: 100%;
  height: 100%;
  padding-top: var(--accept-reject-list-padding-top);
  padding-bottom: var(--accept-reject-list-padding-bottom);
  background-color: var(--mi-white-color);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 30px;
}
.accept-reject-list-container .list-header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 5px;
  padding-left: var(--accept-reject-list-padding-start);
  padding-right: var(--accept-reject-list-padding-end);
}
.accept-reject-list-container .list-header .title-container {
  display: flex;
  justify-items: start;
  align-items: center;
}
.accept-reject-list-container .list-header .title-container .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accept-reject-list-container .list-header .title-container .count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mi-red-color-3);
  border-radius: 50%;
  margin-left: 5px;
  min-width: 18.5px;
  min-height: 18.5px;
}
.accept-reject-list-container .list-header .title-container .count-container .count {
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  color: var(--mi-white-color);
  margin: 0;
  padding: 3.5px 3.5px;
}
.accept-reject-list-container .list-header .subtitle-and-action-container {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
}
.accept-reject-list-container .list-header .subtitle-and-action-container .subtitle-container {
  grid-row: 1;
  grid-column: 1;
  position: relative;
  overflow: hidden;
  height: 12px;
}
.accept-reject-list-container .list-header .subtitle-and-action-container .subtitle-container .wrapper {
  position: absolute;
  height: 12px;
  width: 100%;
}
.accept-reject-list-container .list-header .subtitle-and-action-container .subtitle-container .wrapper .subtitle {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  width: 100%;
}
.accept-reject-list-container .list-header .subtitle-and-action-container .action-container {
  grid-row: 1;
  grid-column: 2;
}
.accept-reject-list-container .list-header .subtitle-and-action-container .action-container .see-all-button {
  background: none;
  outline: none;
}
.accept-reject-list-container .list-header .subtitle-and-action-container .action-container .see-all-button .button-text {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
}
.accept-reject-list-container .list-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.accept-reject-list-container .list-container .list-item {
  --accept-reject-card-padding-top: 1px;
  --accept-reject-card-padding-bottom: 10px;
  --accept-reject-card-padding-start: var(--accept-reject-list-padding-start);
  --accept-reject-card-padding-end: var(--accept-reject-list-padding-end);
}
.accept-reject-list-container .list-container .list-item:last-child app-accept-reject-card ::ng-deep .accept-reject-card {
  border-bottom: none;
}
.accept-reject-list-container .list-container .list-item:first-child app-accept-reject-card ::ng-deep .accept-reject-card {
  padding-top: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
