import { InjectionToken } from '@angular/core';
import {
  CasePublicDataResponse,
  GroupPublicDataResponse,
  ObjectRequestAccessStatus,
  ObjectRequestAccessType,
  ProfilePublicDataResponse
} from '../../yeti-protocol/join-myao';

export interface JoinMyAOObjectXServiceInterface {
  getObjectPublicData(objectId: string): Promise<CasePublicDataResponse | GroupPublicDataResponse | ProfilePublicDataResponse>;
  getObjectRequestAccessStatus(objectId: string, type?: ObjectRequestAccessType): Promise<ObjectRequestAccessStatus>;
  requestAccess(objectId: string): Promise<any>
}

export const JOIN_MYAO_OBJECT_CASE_SERVICE = new InjectionToken<JoinMyAOObjectXServiceInterface>('join-myao-object-case.service');

export const JOIN_MYAO_OBJECT_GROUP_SERVICE = new InjectionToken<JoinMyAOObjectXServiceInterface>('join-myao-object-group.service');

export const JOIN_MYAO_OBJECT_PROFILE_SERVICE =
  new InjectionToken<JoinMyAOObjectXServiceInterface>('join-myao-object-profile.service');
