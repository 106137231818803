import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONTEXT_SERVICE, ContextService } from '../../context/context.model';
// import { AlertController } from '@ionic/angular';
// import { AppTranslationService } from '../../app-translation.service';
// import { DialogsUIService } from '../../dialogs/dialogs.ui.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from '../../utils/ui-utils.service.interface';
import { ClinicalCase } from '../../yeti-protocol/clinical-case';
import { CreatePostAction } from '../create-content';

export enum Status {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root'
})
export class CreateEventContentService {

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    // private appTranslationService: AppTranslationService,
    // private alertController: AlertController,
    // private dialogs: DialogsUIService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
  ) { }

  // async promptUserAboutNotSavedState(): Promise<any> {
  //   const title = await this.appTranslationService.get('app.groups.CreateGroupContent.unsaved-changes-warning-title');
  //   const text = await this.appTranslationService.get('app.groups.CreateGroupContent.unsaved-changes-warning-text');
  //   const confirmButtonText =
  //     await this.appTranslationService.get('app.groups.CreateGroupContent.unsaved-changes-warning-confirm-button-text');
  //   const rejectButtonText =
  //     await this.appTranslationService.get('app.groups.CreateGroupContent.unsaved-changes-warning-reject-button-text');

  //   const alert = await this.alertController.create({
  //     header: title,
  //     message: text,
  //     buttons: [{
  //       text: confirmButtonText,
  //       role: 'confirm'
  //     },
  //     {
  //       text: rejectButtonText,
  //       role: 'cancel',
  //       cssClass: 'secondary'
  //     }]
  //   });

  //   alert.present();
  //   return alert.onDidDismiss();
  // }

  async openCreateEventPostPage(
    eventId?: string,
    postId?: string,
    action?: CreatePostAction,
    postContentText?: string,
    clinicalCase?: ClinicalCase,
    replaceUrl?: boolean,
    facultyOnly?: boolean
  ): Promise<void> {

    if (!eventId) {
      console.error('eventId is mandatory');
      return;
    }

    const eventIdUrlPart = eventId ? `${eventId}/` : '';
    const postIdUrlPart = postId ? `/${postId}` : '';
    const url = [this.contextService.currentContext.key, `event/${eventIdUrlPart}create/post${postIdUrlPart}`].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {
        action: action,
        postContentText: postContentText,
        clinicalCase: clinicalCase,
        facultyOnly: facultyOnly
      },
      replaceUrl: replaceUrl ? true : false
    });
  }

  openCreateEventCasePage(eventId?: string, caseId?: string, replaceUrl?: boolean, facultyOnly?: boolean, shareCaseInPost?: boolean): void {

    if (caseId && !eventId) {
      console.error('if caseId is present, eventId is mandatory');
      return;
    }

    const eventIdUrlPart = eventId ? `${eventId}/` : '';
    const caseIdUrlPart = caseId ? `/${caseId}` : '';
    const url = [this.contextService.currentContext.key, `event/${eventIdUrlPart}create/case${caseIdUrlPart}`].join('/');
    const navigation = this.uiUtilsService.getNavigationValueBasedOnPlatform(url);

    this.router.navigate([navigation], {
      state: {
        facultyOnly: facultyOnly,
        shareCaseInPost: shareCaseInPost
      },
      replaceUrl: replaceUrl ? true : false
    });
  }

  // private async showNoGroupsConfirm(): Promise<boolean> {

  //   const gotItActionKey = 'got-it';

  //   const changeProfessionConfirmDialogData: ConfirmDialogData = {
  //     componentProps: {
  //       title: {
  //         translationKey: 'app.dialogs.confirm.create-a-post-no-groups-title'
  //       },
  //       message: {
  //         translationKey: 'app.dialogs.confirm.create-a-post-no-groups-message'
  //       },
  //       actions: [
  //         {
  //           key: gotItActionKey,
  //           label: {
  //             translationKey: 'app.dialogs.confirm.create-a-post-no-groups-button-1',
  //           },
  //           className: 'primary'
  //         }
  //       ]
  //     }
  //   }

  //   const confirmModal = await this.dialogs.createConfirmDialog(changeProfessionConfirmDialogData);
  //   confirmModal.present();

  //   try {
  //     const res = await confirmModal.onDidDismiss();

  //     if (!res?.data?.actionKey) {
  //       return false;
  //     }

  //     if (res?.data?.actionKey === gotItActionKey) {
  //       this.router.navigateByUrl(`${this.contextService.currentContext.key}/network`);
  //       return true;
  //     } else {
  //       return false;
  //     }

  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   }
  // }
}
