// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --article-image-size: 55px;
  --article-image-border-radius: 5px;
}

.image-container {
  width: var(--article-image-size);
  height: var(--article-image-size);
  display: flex;
  flex-direction: column;
}
.image-container .article-image ion-thumbnail {
  width: 100%;
  height: 100%;
}
.image-container .article-image ion-thumbnail app-image ::ng-deep div, .image-container .article-image ion-thumbnail app-image ::ng-deep div ion-img, .image-container .article-image ion-thumbnail app-image ::ng-deep div ion-img::part(image) {
  height: 100%;
  width: 100%;
  border-radius: var(--article-image-border-radius);
  object-fit: cover;
}
.image-container .article-image ion-thumbnail.default app-image ::ng-deep div, .image-container .article-image ion-thumbnail.default app-image ::ng-deep div ion-img, .image-container .article-image ion-thumbnail.default app-image ::ng-deep div ion-img::part(image) {
  object-fit: fill;
  background-color: var(--mi-thumbnails-background-color);
}
.image-container .article-image.with-banner {
  height: calc(var(--article-image-size) - 20px);
}
.image-container .article-image.with-banner ion-thumbnail app-image ::ng-deep div, .image-container .article-image.with-banner ion-thumbnail app-image ::ng-deep div ion-img, .image-container .article-image.with-banner ion-thumbnail app-image ::ng-deep div ion-img::part(image) {
  border-radius: var(--article-image-border-radius) var(--article-image-border-radius) 0 0;
}
.image-container .banner {
  color: var(--mi-white-color);
  font-size: 11px;
  font-weight: var(--font-weight-semi-bold);
  width: 68px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin: 0;
  border-bottom-left-radius: var(--article-image-border-radius);
  border-bottom-right-radius: var(--article-image-border-radius);
  text-transform: capitalize;
}

.article-style {
  background-color: var(--mi-thumbnails-article-color);
}

.video-style {
  background-color: var(--mi-thumbnails-video-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
