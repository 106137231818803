// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-menu ion-content {
  position: absolute;
  --padding-top: var(--ion-safe-area-top) !important;
}
ion-menu ion-text.title {
  font-size: 24px;
  font-weight: 600;
  color: var(--mi-secondary-color-2);
  padding: 30px 22px 10px;
  line-height: 42px;
}
ion-menu ion-text.subtitle {
  font-size: var(--mi-font-size-small);
  font-weight: 500;
  color: var(--mi-color-text-lite);
  padding: 0 22px 12px;
}
ion-menu ion-text.title,
ion-menu ion-text.subtitle {
  display: block;
}
ion-menu .close-menu {
  position: absolute;
  top: calc(12px + var(--ion-safe-area-top));
  right: 12px;
  --icon-size: 24px;
  width: 30px;
  height: 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
