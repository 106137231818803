import { ConnectionType } from 'src/app/services/messenger/connections-api.service';
import { Connection, ConnectionsSuccessResponse } from 'src/app/services/yeti-protocol/connections';

export namespace PendingConnectionRequests {

    export interface FetchPendingConnectionRequestsPagingPayload {
        pageIndex?: number;
        pageSize?: number;
    }

    export interface FetchPendingConnectionRequestsPayload extends FetchPendingConnectionRequestsPagingPayload {
        connectionType: ConnectionType
    }

    export class FetchPendingConnectionRequests {
        static readonly type = '[Pending Connection Requests] Fetch pending connection requests';
        constructor(public payloadParams: FetchPendingConnectionRequestsPayload) { }
    }

    export class FetchPendingConnectionRequestsSuccess {
        static readonly type = '[Pending Connection Requests] Fetch pending connection requests success';
        constructor(
            public payloadParams: FetchPendingConnectionRequestsPayload,
            public response: ConnectionsSuccessResponse
        ) { }
    }

    export class FetchPendingConnectionRequestsFailed {
        static readonly type = '[Pending Connection Requests] Fetch pending connection requests failed';
        constructor(
            public payloadParams: FetchPendingConnectionRequestsPayload,
        ) { }
    }

    export class FetchIncomingPendingConnectionRequests {
        static readonly type = '[Pending Connection Requests] Fetch incoming pending connection requests';
        constructor(public payloadParams: FetchPendingConnectionRequestsPagingPayload) { }
    }

    export class FetchSentPendingConnectionRequests {
        static readonly type = '[Pending Connection Requests] Fetch sent pending connection requests';
        constructor(public payloadParams: FetchPendingConnectionRequestsPagingPayload) { }
    }

    export class InsertPendingConnectionRequestBeforeIndex {
        static readonly type = '[Pending Connection Requests] Insert pending connection request before index';
        constructor(
            public connectionType: ConnectionType,
            public pendingConnectionRequest: Connection,
            public index: number
        ) { }
    }

    export class InsertIncomingPendingConnectionRequestBeforeIndex {
        static readonly type = '[Pending Connection Requests] Insert incoming pending connection request before index';
        constructor(
            public pendingConnectionRequest: Connection,
            public index: number
        ) { }
    }

    export class InsertSentPendingConnectionRequestBeforeIndex {
        static readonly type = '[Pending Connection Requests] Insert sent pending connection request before index';
        constructor(
            public pendingConnectionRequest: Connection,
            public index: number
        ) { }
    }

    export class RemovePendingConnectionRequest {
        static readonly type = '[Pending Connection Requests] Remove pending connection request at index';
        constructor(
            public connectionType: ConnectionType,
            public index: number
        ) { }
    }

    export class RemoveIncomingPendingConnectionRequest {
        static readonly type = '[Pending Connection Requests] Remove incoming pending connection request at index';
        constructor(
            public index: number
        ) { }
    }

    export class RemoveSentPendingConnectionRequest {
        static readonly type = '[Pending Connection Requests] Remove sent pending connection request at index';
        constructor(
            public index: number
        ) { }
    }

    export class UpdatePendingConnectionRequest {
        static readonly type = '[Pending Connection Requests] Update pending connection request';
        constructor(
            public connectionType: ConnectionType,
            public pendingConnectionRequest: Connection,
        ) { }
    }

    export class UpdateIncomingPendingConnectionRequest {
        static readonly type = '[Pending Connection Requests] Update incoming pending connection request at index';
        constructor(
            public pendingConnectionRequest: Connection,
        ) { }
    }

    export class UpdateSentPendingConnectionRequest {
        static readonly type = '[Pending Connection Requests] Update sent pending connection request at index';
        constructor(
            public pendingConnectionRequest: Connection,
        ) { }
    }
}