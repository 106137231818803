// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --group-image-size: 30px;
  --group-image-border-radius: 50%;
  --group-image-border-color: var(--mi-white-color);
  --group-image-border-width: 0px;
  --group-image-border-style: solid;
  --ao-icon-size: 22px;
  --ao-icon-overlap: calc(calc(var(--ao-icon-size) * 1) * -1);
}

.image-container {
  width: var(--group-image-size);
  height: var(--group-image-size);
  display: flex;
  flex-direction: column;
}
.image-container .group-image ion-thumbnail {
  width: 100%;
  height: 100%;
}
.image-container .group-image ion-thumbnail app-image {
  height: 100%;
  width: 100%;
}
.image-container .group-image ion-thumbnail app-image ::ng-deep div {
  height: 100%;
  width: 100%;
  border-color: var(--group-image-border-color);
  border-width: var(--group-image-border-width);
  border-style: var(--group-image-border-style);
}
.image-container .group-image ion-thumbnail app-image ::ng-deep div, .image-container .group-image ion-thumbnail app-image ::ng-deep div ion-img, .image-container .group-image ion-thumbnail app-image ::ng-deep div ion-img::part(image) {
  height: 100%;
  width: 100%;
  border-radius: var(--group-image-border-radius);
  object-fit: cover;
}
.image-container .group-image ion-thumbnail.default app-image ::ng-deep div, .image-container .group-image ion-thumbnail.default app-image ::ng-deep div ion-img, .image-container .group-image ion-thumbnail.default app-image ::ng-deep div ion-img::part(image) {
  object-fit: fill;
  background-color: var(--mi-thumbnails-background-color);
}
.image-container .group-image.with-banner {
  height: calc(var(--group-image-size) - 20px) !important;
}
.image-container .group-image.with-banner ion-thumbnail app-image ::ng-deep div, .image-container .group-image.with-banner ion-thumbnail app-image ::ng-deep div ion-img, .image-container .group-image.with-banner ion-thumbnail app-image ::ng-deep div ion-img::part(image) {
  border-radius: var(--group-image-border-radius) var(--group-image-border-radius) 0 0;
}
.image-container .banner {
  color: var(--mi-white-color);
  font-size: 11px;
  font-weight: var(--font-weight-semi-bold);
  width: 68px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin: 0;
  border-bottom-left-radius: var(--group-image-border-radius);
  border-bottom-right-radius: var(--group-image-border-radius);
  text-transform: capitalize;
  background-color: var(--mi-thumbnails-group-color);
}

.ao-members-only-icon-container {
  margin-top: var(--ao-icon-overlap);
  z-index: 1;
}
.ao-members-only-icon-container app-ao-member-symbol {
  --ao-member-symbol-icon-size: var(--ao-icon-size);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
