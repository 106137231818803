// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --stage-of-treatment-chip-width: 100%;
  --stage-of-treatment-chip-height: 100%;
  --stage-of-treatment-chip-padding-top: 5px;
  --stage-of-treatment-chip-padding-bottom: 5px;
  --stage-of-treatment-chip-padding-start: 10px;
  --stage-of-treatment-chip-padding-end: 10px;
  --stage-of-treatment-chip-background-color: var(--mi-white-color);
  --stage-of-treatment-chip-color: var(--mi-blue-color-4);
  --stage-of-treatment-chip-selected-background-color: var(--mi-blue-color-4);
  --stage-of-treatment-chip-selected-color: var(--mi-secondary-color-2);
  --stage-of-treatment-chip-icon-size: 17px;
  --stage-of-treatment-column-gap: 6px;
  --stage-of-treatment-text-color: var(--mi-secondary-color-2);
}

.stage-of-treatment-chip-container {
  background: var(--stage-of-treatment-chip-background-color);
  color: var(--stage-of-treatment-text-color);
  box-shadow: 0px 0px 0px 1px var(--stage-of-treatment-chip-color);
  padding-top: var(--stage-of-treatment-chip-padding-top);
  padding-bottom: var(--stage-of-treatment-chip-padding-bottom);
  padding-left: var(--stage-of-treatment-chip-padding-start);
  padding-right: var(--stage-of-treatment-chip-padding-end);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  margin: 1px;
  cursor: pointer;
  justify-content: flex-start;
  transition: background 0.25s ease, box-shadow 0.25s ease, opacity 0.25s ease;
}
.stage-of-treatment-chip-container.selected {
  background: var(--stage-of-treatment-chip-selected-background-color);
  color: var(--stage-of-treatment-chip-selected-color);
  box-shadow: 0px 0px 0px 0px var(--stage-of-treatment-chip-color);
}
.stage-of-treatment-chip-container.selected .chip-text {
  font-weight: var(--font-weight-medium);
}
.stage-of-treatment-chip-container.disabled {
  opacity: 0.5;
}
.stage-of-treatment-chip-container .chip-text {
  width: 100%;
  margin: 0;
  display: inline-block;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
