// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --case-tags-padding-top: 5px;
  --case-tags-padding-bottom: 0px;
  --case-tags-padding-start: 20px;
  --case-tags-padding-end: 20px;
  --case-tags-background-color: var(--mi-white-color);
  --case-tags-title-color: var(--mi-secondary-color-1);
  --case-tags-title-font-size: var(--font-size-regular);
  --level-one-chip-selected-background-color: var(--mi-light-purple-3-color);
  --level-two-chip-selected-background-color: var(--mi-light-green-color);
}

.case-tags-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
  row-gap: 12px;
  padding-top: var(--case-tags-padding-top);
  padding-bottom: var(--case-tags-padding-bottom);
  padding-left: var(--case-tags-padding-start);
  padding-right: var(--case-tags-padding-end);
}
.case-tags-container .title-container {
  grid-row: 1;
  grid-column: 1;
  overflow: hidden;
}
.case-tags-container .title-container .title {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--case-tags-title-font-size);
  color: var(--case-tags-title-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.case-tags-container app-chips {
  grid-row: 2;
  grid-column: 1;
}
.case-tags-container app-chips app-case-classification-chip {
  --chips-margin: 7px;
  --rows-margin: 8px;
  --case-classification-chip-padding-top: 3px;
  --case-classification-chip-padding-bottom: 3px;
  --case-classification-chip-padding-start: 10px;
  --case-classification-chip-padding-end: 10px;
}
.case-tags-container app-chips app-case-classification-chip.full-width {
  max-width: 100%;
}
.case-tags-container app-chips .classification-level-one {
  --case-classification-chip-color: var(--mi-primary-color);
  --case-classification-chip-selected-background-color: var(--level-one-chip-selected-background-color);
  --case-classification-chip-selected-color: var(--mi-primary-color);
}
.case-tags-container app-chips .classification-level-two {
  --case-classification-chip-color: var(--mi-green-color);
  --case-classification-chip-selected-background-color: var(--level-two-chip-selected-background-color);
  --case-classification-chip-selected-color: var(--mi-green-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
