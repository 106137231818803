// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  app-top-bar {
    display: block !important;
  }
}
app-top-bar .icon-size-24 {
  height: 24px;
  width: 24px;
}
app-top-bar .toolbar {
  --background: var(--mi-white-color);
  height: 65px;
  display: flex;
}
app-top-bar .toolbar .back-button {
  background-color: var(--mi-white-color);
  margin-left: 10px;
}

.container {
  padding-top: 2px;
  background-color: var(--mi-grey-background);
}
.container .card-wrapper .blue-info-card {
  background-color: var(--mi-light-blue-color);
  color: var(--mi-secondary-color-1);
}
.container .card-wrapper .pending-invites {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  border-radius: 0 !important;
}
.container .card-wrapper .info-card {
  border-radius: 10px;
  box-shadow: none;
}
.container .card-wrapper .info-card .info-card-header {
  display: flex;
  justify-content: space-between;
}
.container .card-wrapper .info-card .info-card-header .main-text {
  font-weight: var(--font-weight-medium);
  font-size: var(--mi-font-size-small);
  color: var(--mi-secondary-color-1);
}
.container .card-wrapper .info-card .display-flex {
  display: flex;
  align-items: center;
}
.container .card-wrapper .info-card .group-additional-info {
  justify-content: space-between;
  gap: 10px;
}
.container .card-wrapper .info-card .group-additional-info .send-reminder-button {
  --background: var(--mi-primary-color);
  --border-radius: 4px;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  margin: 5px 0 5px 0;
  display: flex;
  text-transform: none;
  height: 30px;
}
.container .card-wrapper .info-card .group-additional-info .send-reminder-button.button-disabled {
  --background: var(--mi-color-text-lite);
}
.container .card-wrapper .info-card .group-additional-info .email-error {
  color: var(--mi-danger);
}
.container .card-wrapper .info-card .group-additional-info .email-search-icon {
  position: absolute;
  left: 80%;
  --icon-size: 30px;
  top: 53%;
  background: var(--mi-inputs-color);
}
.container .card-wrapper .info-card .group-additional-info .email-search-icon.error {
  top: 44%;
}
.container .card-wrapper .info-card .group-additional-info #emailField {
  --background: var(--mi-inputs-color);
}
.container .card-wrapper .info-card .group-additional-info #emailField::ng-deep .searchbar-input {
  box-shadow: none;
  padding-right: 50px;
}
.container .card-wrapper .info-card .group-additional-info .additional-info-item {
  background-color: var(--ion-color-secondary-light-blue);
  color: var(--ion-color-secondary-bright-blue);
  border-radius: 12px;
  margin: 5px 0;
}
.container .card-wrapper .info-card .group-additional-info .additional-info-item app-user-profile-image {
  margin-right: 10px;
}
.container .card-wrapper .info-card .group-additional-info .action-sheet-trigger-button {
  justify-self: end;
  outline: none;
  border: none;
  background: none;
  font-size: 25px;
  color: var(--mi-color-text-lite);
}
.container .card-wrapper .info-card .group-additional-info .share-label {
  font-size: var(--mi-font-size-small);
  color: var(--mi-secondary-color-1) !important;
}
.container .card-wrapper .info-card .group-additional-info .label {
  font-size: var(--mi-font-size-default);
  letter-spacing: 0;
  color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
