// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generic-input-container ion-item {
  --background: var(--mi-inputs-color);
  border-radius: 3px;
}
.generic-input-container ion-item ::ng-deep input {
  padding: 13px 13px 13px 0px;
}
.generic-input-container ion-item .icon {
  width: 24px;
  height: 24px;
}
.generic-input-container ion-item .icon.icon-start {
  margin-right: 15px;
}
.generic-input-container .validation-msg {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 24px;
  display: none;
}
.generic-input-container.success ion-item {
  border: 1px solid var(--mi-success);
}
.generic-input-container.success .validation-msg {
  display: block;
  color: var(--mi-success);
}
.generic-input-container.error ion-item {
  border: 1px solid var(--mi-danger);
}
.generic-input-container.error .validation-msg {
  display: block;
  color: var(--mi-danger);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
