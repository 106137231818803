import { ConnectionStatus } from './connection';
import { ContextRequest } from './context-request';
import { ErrorResponse } from './error';
import { Message, MessageType } from './message';
import { PaginationRequest } from './pagination-request';

export interface User {
  connectionStatus: ConnectionStatus,
  country?: string,
  department?: string,
  firstName: string,
  fullName: string,
  imageUrl: string,
  isAOFaculty: boolean,
  isAOMember: boolean,
  isVerified: boolean,
  lastName: string,
  organization?: string,
  profession?: string,
  profileImageUrl: string,
  unit?: string,
  userId: string,
  connectionInfo?: {
    id: string,
    status: ConnectionStatus
  }
}

export enum SearchUsersStatus {
  NONE = 'none',
  PENDING = 'pending',
  CONNECTED = 'connected'
}

export type SearchUsersResponse = SearchUsersSuccessResponse | ErrorResponse;

export interface SearchUsersRequestParams extends ContextRequest, PaginationRequest {
  query: string;
  status?: SearchUsersStatus
}

export interface SearchUsersSuccessResponse {
  _schemaPath?: 'chatter/ionic/messenger/search';
  _schemaMethod?: 'get';
  totalItemsCount: number;
  result: Array<User>;
}

export interface SendMessageRequest {
  _schemaPath?: 'chatter/ionic/messenger/message/:chatId';
  _schemaMethod?: 'post';
  message: string;
  type: MessageType;
  url?: string;
}

export interface SendMessageResponse {
  _schemaPath?: 'chatter/ionic/messenger/message/:chatId';
  _schemaMethod?: 'post';
  result: Message;
}

export interface DeleteMessageRequest {
  _schemaPath?: 'chatter/ionic/messenger/message/:messageId';
  _schemaMethod?: 'delete';
}

export interface DeleteMessageResponse {
  _schemaPath?: 'chatter/ionic/messenger/message/:messageId';
  _schemaMethod?: 'delete';
  result: Message;
}

export interface SendMessageWithAttachmentRequest {
  _schemaPath?: 'chatter/ionic/messenger/message/:chatId';
  _schemaMethod?: 'post';
  // NOTE: fields are added through FormData
  message: string;
  type: MessageType;
  file: File;
}

export interface SendMessageWithAttachmentSuccessResponse {
  _schemaPath?: 'chatter/ionic/messenger/message/:chatId';
  _schemaMethod?: 'post';
  result: Message;
  success: boolean;
}

export type SendMessageWithAttachmentResponse = SendMessageWithAttachmentSuccessResponse | ErrorResponse;

export interface MessageCreateRequestParam {
  message?: string,
  type?: string,
  url?: string,
  sharedObjectId?: string,
  awsPersonalisationId?: string
}
