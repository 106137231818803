import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface SearchTermContent {
  suggestList: Array<string>
}

@Injectable({
  providedIn: 'root'
})
export class SearchDataService {

  private searchTermChanged: Subject<SearchTermContent> = new Subject();

  triggerSearchTermChange(suggestList: Array<string>): void {
    this.searchTermChanged.next({
      suggestList: suggestList
    });
  }

  getSearchTermChanges(): Observable<SearchTermContent> {
    return this.searchTermChanged.asObservable();
  }
}
