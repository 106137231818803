import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { ImageAttachment } from '../attachments.model';
import { Connection } from '../yeti-protocol/connections';
import { AOEvent } from '../yeti-protocol/event';
import { AOEventMessage, GetMessagesResponse, Message, MessageType } from '../yeti-protocol/message';
import {
  DeleteMessageResponse,
  SearchUsersResponse,
  SearchUsersRequestParams,
  SendMessageResponse,
  SearchUsersStatus,
  SendMessageWithAttachmentResponse,
  User, MessageCreateRequestParam
} from '../yeti-protocol/messenger';

import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { AuthService } from '../auth/auth.service';
import { ConnectionsApiService } from './connections-api.service';
import { SchemaValidatorService } from '../schema-validator.service';
import { EventsService } from '../events/events.service';

import appConfig from 'src/config/config';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import { ConnectionType } from '../yeti-protocol/connection';
import { ConnectionRequestsService } from './connection-requests.service';

interface MessengerServiceConfig {
  chatterUrl: string;
}

export interface Messages {
  messages: Array<Message | AOEventMessage>;
  total: number;
}

interface Result<T> {
  result?: T;
  error?: any;
}

function extractResult<T>(res: Result<T>): T {
  if ('error' in res) {
    throwError(() => res.error);
    return;
  }
  if ('result' in res) {
    return res.result;
  }
}

export interface FoundUsers {
  users: Array<User>,
  total: number;
}

// NOTE: in original 1-to-1 chats chatId is the connectionId
@Injectable({
  providedIn: 'root'
})
export class MessengerService {
  appConfig: MessengerServiceConfig = appConfig;


  constructor(
    private authService: AuthService,
    private connectionsApiService: ConnectionsApiService,
    private eventsService: EventsService,
    private schemaValidator: SchemaValidatorService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private connectionRequestsService: ConnectionRequestsService
  ) { }

  // chat info
  getChat(chatId: string, chatType: ConnectionType): Promise<Connection | AOEvent> {
    if (chatType === ConnectionType.EVENT) {
      return this.eventsService.getEvent(chatId);
    } else {
      return this.connectionsApiService.getConnection(chatId);
    }
  }

  // messages of the chat
  getMessages(chatId: string, chatType: ConnectionType, start: number = 0, count: number = 20): Promise<Messages> {
    const url = `${this.appConfig.chatterUrl}messenger/message/${chatId}/${chatType.valueOf()}`;
    return firstValueFrom(this.authService.secureGet(url, {
      params: toAuthRequestParams({ start, count })
    }).pipe(
      this.schemaValidator.isValidOperator<GetMessagesResponse>('GetMessagesResponse'),
      take(1),
      map(res => {
        if ('result' in res && 'totalItemsCount' in res) {
          return {
            messages: res.result,
            total: res.totalItemsCount
          };
        } else {
          return { messages: [], total: 0 };
        }
      })
    ));
  }

  acceptChatRequest(chatId: string): Promise<Connection> {

    const promise = this.connectionsApiService.updateConnection(chatId, 'connected');

    promise.then(updatedConnection => {
      this.connectionRequestsService.propagateConnectionRequestUpdate(updatedConnection);
    });

    return promise;
  }

  rejectChatRequest(chatId: string): Promise<Connection> {

    const promise = this.connectionsApiService.updateConnection(chatId, 'rejected');

    promise.then(updatedConnection => {
      this.connectionRequestsService.propagateConnectionRequestUpdate(updatedConnection);
    });

    return promise;
  }

  sendMessage(chatId: string, type: MessageType, message: string, linkUrl: string, attachments: Array<ImageAttachment>): Promise<Message> {
    const url = `${this.appConfig.chatterUrl}messenger/message/${chatId}`;
    if (type === 'attachment' || type === 'image') {
      return this._sendMessageWithAttachments(url, type, message, attachments);
    }
    return firstValueFrom(this.authService.securePost(url, toAuthRequestParams({
      message,
      type,
      url: linkUrl
    })).pipe(
      this.schemaValidator.isValidOperator<SendMessageResponse>('SendMessageResponse'),
      map(res => {
        return extractResult(res);
      })
    ));
  }

  deleteMessage(messageId: string): Promise<Message> {
    const url = `${this.appConfig.chatterUrl}messenger/message/${messageId}`;
    return firstValueFrom(this.authService.secureDelete(url, {}).pipe(
      this.schemaValidator.isValidOperator<DeleteMessageResponse>('DeleteMessageResponse'),
      map(res => {
        return extractResult(res);
      })
    ));
  }

  searchUsers(
    searchTerm: string,
    status?: SearchUsersStatus,
    start: number = 0,
    count: number = 15): Promise<FoundUsers> {

    const getRequestUrl = `${this.appConfig.chatterUrl}messenger/search`;

    const params: SearchUsersRequestParams = {
      appId: this.contextService?.currentContext?.key,
      start: start,
      count: count,
      query: searchTerm
    }

    if (status) {
      params.status = status;
    }

    return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<SearchUsersResponse>('SearchUsersResponse'),
      map(res => {
        if ('result' in res) {
          return {
            users: res.result,
            total: res.totalItemsCount
          }
        }
        throwError(() => {
          return new Error('search users failed');
        });
      })
    ));
  }

  sendMessageToEventParticipants(eventId: string | Array<string>, type: MessageType, message: string,
    linkUrl: string, attachments: Array<ImageAttachment>): Promise<Message> {
    if (Array.isArray(eventId)) {
      eventId = eventId.join(',');
    }
    const url = `${this.appConfig.chatterUrl}messenger/message/event/${eventId}`;
    if (type === 'attachment' || type === 'image') {
      return this._sendMessageWithAttachments(url, type, message, attachments);
    }
    return firstValueFrom(this.authService.securePost<any, SendMessageResponse>(url, toAuthRequestParams({
      message,
      type,
      url: linkUrl
    })).pipe(
      // this.schemaValidator.isValidOperator<SendMessageResponse>('SendMessageResponse'),
      map(res => {
        return extractResult(res);
      }),
      catchError((err: HttpErrorResponse) => {
        return this._processHttpError(err);
      })
    ));
  }

  sendMessageToMultipleParticipants(connectionIds: Array<string>, type: MessageType, message: string,
    linkUrl: string, attachments: Array<ImageAttachment>,
    sharedObjectId?: string, awsPersonalisationId?: string): Promise<Message> {

    if (!connectionIds || !connectionIds.length) {
      return Promise.reject();
    }

    const connectionIdsString = connectionIds.join(',');
    const url = `${this.appConfig.chatterUrl}messenger/message/connection/${connectionIdsString}`;

    const params: MessageCreateRequestParam = {};
    if (type === 'case') {
      params.message = message;
      params.type = type;
      params.sharedObjectId = sharedObjectId;
    } else {
      params.message = message;
      params.type = type;
      params.url = linkUrl;
    }
    if (awsPersonalisationId) {
      params.awsPersonalisationId = awsPersonalisationId;
    }

    if (type === 'attachment' || type === 'image') {
      return this._sendMessageWithAttachments(url, type, message, attachments);
    }

    return firstValueFrom(this.authService.securePost<any, SendMessageResponse>(url, toAuthRequestParams(params))
      .pipe(
        // this.schemaValidator.isValidOperator<SendMessageResponse>('SendMessageResponse'),
        map(res => {
          return extractResult(res);
        }),
        catchError((err: HttpErrorResponse) => {
          return this._processHttpError(err);
        })
      ));
  }

  _processHttpError(err: HttpErrorResponse): Observable<never> {
    if (err.status === 400) {
      if (err?.error?.error?.info) {
        try {
          const jsonData = JSON.parse(err.error.error.info);
          let msg = jsonData?.chat;
          if (!msg) {
            msg = err.error.error?.message;
          }
          if (!msg) {
            msg = 'Fail to send message';
          }
          return throwError(() => new Error(msg));
        } catch (ex) {/* ignore */ }
      }
    }
    console.log(err);
    return throwError(() => err);
  }

  _sendMessageWithAttachments(url: string, type: MessageType, message: string, files: Array<ImageAttachment>): Promise<any> {

    const formData = new FormData();

    files.forEach((file: ImageAttachment) => {
      formData.append('file', file.file, file.fileName);
    });

    formData.append('message', message || '');
    formData.append('type', type);

    const options = {
      reportProgress: true,
    };

    return firstValueFrom(this.authService.securePost(url, formData, options).pipe(
      this.schemaValidator.isValidOperator<SendMessageWithAttachmentResponse>('SendMessageWithAttachmentResponse'),
      switchMap(res => {
        if ('error' in res) {
          return throwError(() => res.error);
        }
        return of(extractResult(res));
      })
    ));
  }

}
