// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  color: var(--ion-color-primary);
  background-color: var(--ion-color-light-shade);
  overflow-y: auto;
  --padding: 0px;
  --panel-1-padding-top: 0px;
  --panel-1-padding-bottom: 15px;
  --panel-1-padding-start: 15px;
  --panel-1-padding-end: 0px;
  --panel-2-padding-top: 15px;
  --panel-2-padding-bottom: 15px;
  --panel-2-padding-start: 15px;
  --panel-2-padding-end: 15px;
  --panel-1-row-gap: 20px;
  --title-font-size: var(--font-size-extra-large);
  --panel-2-border-radius: 20px;
  --close-button-icon-size: 24px;
  --migration-dialog-cards-border-radius: 9px;
}
@media all and (min-width: 1000px) {
  :host {
    --padding: 50px;
    --title-font-size: var(--font-size-extra-large-2);
    --panel-1-row-gap: 30px;
    --close-button-icon-size: 30px;
    --panel-1-padding: 0px;
  }
}

.top-image {
  height: 40%;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}

.controls-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--mi-white-color);
}
.controls-area form {
  background-color: var(--mi-white-color);
}

ion-list-header {
  font-size: var(--font-size-extra-large);
  font-weight: 600;
}

app-generic-input {
  width: 100%;
  margin: 4px 0;
}

.controls-area,
ion-list,
form {
  border-top-left-radius: var(--mi-standard-margin);
  border-top-right-radius: var(--mi-standard-margin);
}

.bottom-line {
  font-size: var(--font-size-tiny);
  color: var(--mi-color-text-lite);
}
.bottom-line .line {
  width: 100%;
  text-align: center;
}
.bottom-line .link {
  text-decoration: underline;
  cursor: pointer;
}

.or-icon {
  display: block;
  margin: 0 auto;
}

.auth-button {
  height: 47px;
  margin: 4px 0;
  text-transform: none;
  box-shadow: none;
}

.migration-dialog {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr;
  row-gap: 10px;
  background-color: var(--mi-purple-color-3);
  padding: var(--padding);
}
.migration-dialog .panel-1 {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
  row-gap: var(--panel-1-row-gap);
  padding-top: var(--panel-1-padding-top);
  padding-bottom: var(--panel-1-padding-bottom);
  padding-left: var(--panel-1-padding-start);
  padding-right: var(--panel-1-padding-end);
}
.migration-dialog .panel-1 .title {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-extra-large-2);
  color: var(--mi-grey-color-13);
}
.migration-dialog .panel-1 .title span {
  font-weight: var(--font-weight-bold);
}
.migration-dialog .panel-1 .subtitle {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-large-2);
  color: var(--mi-secondary-color-2);
}
.migration-dialog .panel-2 {
  grid-row: 2;
  grid-column: 1;
  background-color: var(--mi-white-color);
  border-radius: var(--panel-2-border-radius);
  position: relative;
}
@media all and (min-width: 1000px) {
  .migration-dialog {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    -moz-column-gap: 25px;
         column-gap: 25px;
    padding: 50px;
  }
  .migration-dialog .panel-1 {
    grid-row: 1;
    grid-column: 1;
  }
  .migration-dialog .panel-2 {
    grid-row: 1;
    grid-column: 2;
    width: 100%;
  }
}
.migration-dialog.mobile {
  --panel-1-padding-top: 25px;
  --panel-1-padding-bottom: 15px;
  --panel-1-padding-start: 15px;
  --panel-1-padding-end: 15px;
}
@media all and (max-height: 750px) {
  .migration-dialog.mobile .panel-2 {
    row-gap: 12.5px;
  }
}

.top-image {
  height: 35%;
}

.controls-area {
  height: 66%;
  background-color: var(--ion-color-primary-contrast);
}

ion-list {
  margin: 0 var(--mi-standard-padding) 0;
}
ion-list ion-list-header {
  font-size: var(--font-size-extra-large);
}
ion-list ion-button[expand=block] {
  flex-grow: 1;
}
ion-list ion-item.link-container {
  align-items: flex-end;
}

.auth-button {
  --box-shadow: none;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}

@media only screen and (max-width: 345px) {
  .auth-button {
    font-size: var(--font-size-regular);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
