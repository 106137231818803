import { Component } from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-top-contributor-badge',
  templateUrl: './top-contributor-badge.component.html',
  styleUrls: ['./top-contributor-badge.component.scss'],
  imports: [
    TranslateModule
  ],
  standalone: true
})
export class TopContributorBadgeComponent {

  constructor() { }

}
