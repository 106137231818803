import { ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
// import Swiper core and required modules
import SwiperCore, { Virtual, Navigation, SwiperOptions } from 'swiper';
// install Swiper modules
SwiperCore.use([Virtual, Navigation]);

import { ResponsiveUtilsService } from './responsive-utils.service';

const DEFAULT_SLIDES_PER_VIEW = 2;
const MAX_SWIPER_WIDTH_CHECKS = 3;

export class SwiperLogic {

  swiperConfig: SwiperOptions = {
    spaceBetween: 10,
    virtual: true,
    navigation: false
  }
  resizeSub: Subscription;
  slideWidthDiv10: number;
  swiperWidthCheckCount = 0;

  constructor(
    slideWidth: number,
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef,
    private defaultSlidesPerView = DEFAULT_SLIDES_PER_VIEW,
    private debugSize: boolean = false,
    private virtual: boolean = true
  ) {
    this.swiperConfig.slidesPerView = defaultSlidesPerView;
    this.swiperConfig.virtual = this.virtual;
    this.slideWidthDiv10 = slideWidth / 10;
    this.resizeSub = this.responsiveUtilsService?.resize?.pipe(
      debounceTime(500),
    ).subscribe(() => {
      this._updateSwiperConfig();
    });
  }

  // must be created inside ngAfterViewInit()
  afterViewInit(): void {
    setTimeout(() => {
      this._updateSwiperConfig();
    });
  }

  // to be called in ngOnDestroy
  destroy(): void {
    this.resizeSub.unsubscribe();
  }

  get config(): SwiperOptions {
    return this.swiperConfig;
  }

  _updateSwiperConfig(): void {
    this.swiperConfig = {
      ...this.swiperConfig,
      slidesPerView: this._slidesPerView,
      navigation: this.responsiveUtilsService.isDesktop
    };
  }

  get _slidesPerView(): number {
    if (this.el.nativeElement) {
      const width = this.el.nativeElement.offsetWidth;
      if (this.debugSize) {
        console.log(`swiperWidth: ${width}, swiperSlideWidth: ${this.slideWidthDiv10 * 10}`);
      }
      if (width > 0) {
        this.swiperWidthCheckCount = 0;
        return Math.floor(width / this.slideWidthDiv10) / 10;
      } else {
        if (this.swiperWidthCheckCount < MAX_SWIPER_WIDTH_CHECKS) {

          this.swiperWidthCheckCount += 1;

          setTimeout(() => {
            this._updateSwiperConfig();
          }, 300);
        } else {
          this.swiperWidthCheckCount = 0;
        }
      }
    }
    return this.defaultSlidesPerView;
  }
}
