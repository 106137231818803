// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-tag-container {
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
  border-radius: 20px;
  padding: 5px 10px;
}
.ai-tag-container .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background: transparent linear-gradient(270deg, var(--mi-blue-color-16) 0%, var(--mi-primary-color) 100%) 0% 0% no-repeat padding-box;
  opacity: 0.31;
  z-index: 1;
}
.ai-tag-container .icon-container {
  grid-row: 1;
  grid-column: 1;
  z-index: 2;
}
.ai-tag-container .icon-container app-icon {
  --icon-size: 13px;
}
.ai-tag-container .text-container {
  grid-row: 1;
  grid-column: 2;
  z-index: 2;
}
.ai-tag-container .text-container .text {
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
