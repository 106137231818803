import { Component, EventEmitter, OnInit, Input, Output, Inject } from '@angular/core';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { ResponseStreamItem } from 'src/app/services/yeti-protocol/my-stream';
import { ActionSource, ActionTracked } from 'src/app/services/yeti-protocol/tracking';
import { StreamItemType, StreamStateType } from 'src/app/services/yeti-protocol/utils/enums';

@Component({
  selector: 'app-knowledge-stream-items',
  templateUrl: './knowledge-stream-items.component.html',
  styleUrls: ['./knowledge-stream-items.component.css']
})
export class KnowledgeStreamItemsComponent implements OnInit {

  @Input() items: Array<ResponseStreamItem>;
  @Input() loading: boolean;
  @Input() visibilityRootKey: boolean;
  @Input() awsPersonalisationId: string;

  @Output() openJournal: EventEmitter<string> = new EventEmitter();

  StreamItemType = StreamItemType;

  constructor(
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  ngOnInit(): void { }

  trackMarketingTeaserCardClick(): void {
    this.trackingService
      .trackGenericClickedAction(
        ActionTracked.marketingTeaserCard,
        StreamStateType.knowledge,
        ActionTracked.marketingTeaserCard,
        { source: this.source }
      )
      .catch((_err) => {
        console.error('Could not track marketing teaser card clicked action.');
      });
  }

  get source(): ActionSource {
    return ActionSource.smartStream;
  }

}
