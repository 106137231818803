import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchUiService {

  private recentSearchTermsRequestFinishedSubject: Subject<void> = new Subject();
  private finishedGetSuggestList: Subject<void> = new Subject();

  emitRecentSearchTermsRequestFinished(): void {
    this.recentSearchTermsRequestFinishedSubject.next();
  }

  get recentSearchTermsRequestFinishedObservable(): Observable<void> {
    return this.recentSearchTermsRequestFinishedSubject.asObservable();
  }

  triggerFinishedGetSuggestList(): void {
    this.finishedGetSuggestList.next();
  }

  get getSuggestListFinishedObservable(): Observable<void> {
    return this.finishedGetSuggestList.asObservable();
  }
}
