import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { StreamItemJournalHighlights } from 'src/app/services/yeti-protocol/my-stream';
import { ResponsiveUtilsService } from '../../services/utils/responsive-utils.service';
import { ActionSource } from '../../services/yeti-protocol/tracking';
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';
import { SwiperOptions } from 'swiper';
import { waitTime } from 'src/app/services/utils/utils';

@Component({
  selector: 'app-journal-highlights',
  templateUrl: './journal-highlights.component.html',
  styleUrls: ['./journal-highlights.component.scss'],
})
export class JournalHighlightsComponent implements OnDestroy, AfterViewInit {
  @Input() journalHighlights: StreamItemJournalHighlights;
  @Output() openJournal: EventEmitter<string> = new EventEmitter();
  ActionSource = ActionSource

  swiperLogic: SwiperLogic;

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private el: ElementRef
  ) {
    this.swiperLogic = new SwiperLogic(302, this.responsiveUtilsService, this.el, 1.2);
  }

  ngOnDestroy(): void {
    this.swiperLogic.destroy();
  }

  ngAfterViewInit(): void {
    waitTime(500).then(() => {
      this.swiperLogic.afterViewInit();
    });
  }

  get swiperConfig(): SwiperOptions {
    return this.swiperLogic.config;
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }
}
