// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stream-filters-tabs-container app-chips {
  --chips-margin: 23px;
  --rows-margin: 10px;
}
.stream-filters-tabs-container app-chips app-text-chip {
  --color: var(--mi-white-color);
  --background-color: none;
  padding: 2.5px 16.5px;
  border: 1px solid var(--mi-color-text-lite);
  border-radius: 8px;
  background-color: var(--mi-primary-color);
  cursor: pointer;
}
.stream-filters-tabs-container app-chips app-text-chip:not(.selected) {
  --color: var(--mi-color-text-lite);
  background: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
