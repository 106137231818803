// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --mi-card-padding-top: 15px;
  --mi-card-padding-bottom: 15px;
  --mi-card-padding-start: 15px;
  --mi-card-padding-end: 15px;
  --mi-card-margin-top: 15px;
  --mi-card-margin-bottom: 15px;
  --mi-card-margin-start: 15px;
  --mi-card-margin-end: 15px;
  --mi-card-border-top-left-radius: 8px;
  --mi-card-border-top-right-radius: 8px;
  --mi-card-border-bottom-left-radius: 8px;
  --mi-card-border-bottom-right-radius: 8px;
  --mi-card-border-style: none;
  --mi-card-border-width: 0;
  --mi-card-border-color: var(--mi-white-color);
  --mi-card-background-color: var(--mi-white-color);
}

ion-card {
  width: calc(100% - (var(--mi-card-margin-start) + var(--mi-card-margin-end)));
  height: calc(100% - (var(--mi-card-margin-top) + var(--mi-card-margin-bottom)));
  --background: var(--mi-white-color);
  box-shadow: 0px 3px 20px var(--mi-shadow-color);
  border-top-left-radius: var(--mi-card-border-top-left-radius);
  border-top-right-radius: var(--mi-card-border-top-right-radius);
  border-bottom-left-radius: var(--mi-card-border-bottom-left-radius);
  border-bottom-right-radius: var(--mi-card-border-bottom-right-radius);
  padding: var(--mi-card-padding-top) var(--mi-card-padding-end) var(--mi-card-padding-bottom) var(--mi-card-padding-start);
  margin: var(--mi-card-margin-top) var(--mi-card-margin-end) var(--mi-card-margin-bottom) var(--mi-card-margin-start);
  border: var(--mi-card-border-style) var(--mi-card-border-width) var(--mi-card-border-color);
  background-color: var(--mi-card-background-color);
}
ion-card.no-padding {
  padding: 0;
}
ion-card.no-margin {
  --mi-card-margin-top: 3px;
  --mi-card-margin-bottom: 3px;
  --mi-card-margin-start: 3px;
  --mi-card-margin-end: 3px;
  box-shadow: 0px 1px 8px var(var(--mi-shadow-color));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
