import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {IonNav} from '@ionic/angular';
import {AppTranslationService} from 'src/app/services/app-translation.service';
import { AUTH_UI_SERVICE, AuthUIServiceInterface } from 'src/app/services/auth/auth-ui.service.interface';
import {LastEmailService} from 'src/app/services/last-email.service';
import {AuthProvider} from 'src/app/services/yeti-protocol/auth/provider';
import {isFieldValid} from '../auth-common/auth-common';
import {BaseAuthPage} from '../auth-common/base-auth-page';
import {ActionSource, ActionTracked, SignInSignUpTrackingParam, TrackingRequest} from '../../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';

@Component({
  selector: 'app-auth-signin',
  templateUrl: './auth-signin.page.html',
  styleUrls: ['./auth-signin.page.scss'],
})
export class AuthSignInPage extends BaseAuthPage implements OnInit {
  @ViewChild('f', {static: true}) form: NgForm;
  errorMessage: string;
  AuthProvider = AuthProvider;

  constructor(
    nav: IonNav,
    @Inject(AUTH_UI_SERVICE) authUIService: AuthUIServiceInterface,
    private appTranslationService: AppTranslationService,
    private lastEmailService: LastEmailService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService) {
      super(nav, authUIService);
    }

  ngOnInit(): void {
    this.lastEmailService.getEmail().then(email => {
      this.form.form.patchValue({email});
    });
  }

  isFieldValid(fieldName: string): boolean {
    return isFieldValid(this.form, fieldName);
  }

  onSignIn(form: NgForm): void {
    if (!form.valid) {
      return;
    }
    this.errorMessage = '';
    this.lastEmailService.setEmail(form.value.email)
      .then(() => {
        this.trackSignInAction(AuthProvider.MI);
        return this.authUIService.signIn(AuthProvider.MI, {
          username: form.value.email,
          password: form.value.password
        });
      })
      .catch(async (_err) => {
        this.errorMessage = await this.appTranslationService.get('app.dialogs.auth.AuthSigninPage.signInError');
      });
  }

  signin(provider: AuthProvider): void{
    this.trackSignInAction(AuthProvider.MI);
    this.errorMessage = '';
    this.authUIService.signIn(provider)
      .catch(async (err) => {
        console.log(err);
        this.errorMessage = await this.appTranslationService.get('app.common.not-logged-in')
      });
  }

  trackSignInAction(provider: string): void {
    const paramsToTrack: SignInSignUpTrackingParam = {
      provider: provider,
      source: ActionSource.myAoLoginPage
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.signInStart,
      params: paramsToTrack
    };
    this.trackingService.track(trackData).catch(_err => {
      console.error('Could not track sign in action');
    });
  }
}
