// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-state-container {
  padding: 0px 35px;
  margin-bottom: 30px;
}
.empty-state-container .text-container {
  display: flex;
  flex-direction: column;
}
.empty-state-container .text-container .title {
  text-align: center;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-extra-large);
  line-height: var(--font-line-height-extra-large);
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
}
.empty-state-container .text-container .description {
  margin: 15px 0px 20px;
  text-align: center;
  color: var(--mi-secondary-color-2);
  font-family: Suisse;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
}
.empty-state-container .buttons-container {
  display: grid;
  row-gap: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
