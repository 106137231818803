// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --bookmark-button-icon-size: 19px;
}

.bookmark-button-container {
  display: flex;
  justify-content: flex-end;
}
.bookmark-button-container button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  line-height: 1;
}
.bookmark-button-container button .button-wrapper-container {
  cursor: pointer;
}
.bookmark-button-container button .button-wrapper-container app-icon {
  color: var(--mi-black-color);
}
.bookmark-button-container button .button-wrapper-container app-icon.bookmark {
  --icon-size: var(--bookmark-button-icon-size);
}
.bookmark-button-container button .button-wrapper-container .text {
  margin: 0;
  margin-top: 2px;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
.bookmark-button-container button .button-wrapper-container .text.bookmark {
  margin-top: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
