// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --camera-button-size: 24px;
  --camera-icon-size: 12px;
  --camera-button-color: #EBEEF5;
}

.change-profile-image-icon-container {
  height: var(--camera-button-size);
  width: var(--camera-button-size);
  border-radius: 50%;
  background-color: var(--camera-button-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  color: var(--mi-black-color);
  cursor: pointer;
}
.change-profile-image-icon-container .camera-icon {
  --icon-size: var(--camera-icon-size);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
