import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// models
import { AllInterestsData, Interest } from './interests.model';

import appConfig from 'src/config/config';

@Injectable({
  providedIn: 'root'
})
export class InterestsService {

  config = appConfig;
  private interestsPromise: Promise<AllInterestsData> = null;

  constructor(
    private httpClient: HttpClient
  ) { }

  getInterests(contextKey: string): Promise<Array<Interest>> {

    if(this.interestsPromise === null){
      this.interestsPromise = this._fetchInterests();
    }

    return this.interestsPromise.then(interestsData => {
      const contextInterestsData = interestsData[contextKey];
      if(contextInterestsData && contextInterestsData.items){
        return contextInterestsData.items;
      }
      return Promise.reject(`interests for context are not found: ${contextKey}`);
    });
  }

  _fetchInterests(): Promise<AllInterestsData> {

    return new Promise((resolve, reject) => {
      this.httpClient.get(`${this.config.backendUrl}interests`).subscribe({
        next: (interests: AllInterestsData) => resolve(interests),
        error: err => reject(err)
      });
    });
  }
}
