// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.journal-container {
  padding: 5px 0px;
}
.journal-container .journal-img {
  width: 36px;
  height: 36px;
  margin-right: 15px;
}
.journal-container .journal-img app-article-image {
  --article-image-size: 36px;
  --article-image-border-radius: 50%;
}
.journal-container .content-container {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
}
.journal-container .content-container app-multiline-text-with-ellipsis {
  --multiline-text-with-ellipsis-max-height: 47px;
}
.journal-container .content-container app-multiline-text-with-ellipsis ::ng-deep {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-small);
  color: var(--mi-black-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
