// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content .container .followers-list {
  padding: 30px 10px 10px;
}
ion-content .container .followers-list .follower-item {
  padding-right: 5px;
  padding-bottom: 10px;
}
ion-content .container .followers-list app-icon {
  color: var(--mi-color-text-lite);
}
ion-content .container app-user-profile-image {
  --user-image-size: 40px !important;
  margin-right: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
