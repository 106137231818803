import { Component } from '@angular/core';
import { Case } from 'src/app/services/yeti-protocol/chatter-api';
import { ClinicalCase } from 'src/app/services/yeti-protocol/clinical-case';
import { Approach, FractureAndTreatment, Preparation } from 'src/app/services/yeti-protocol/surgery-reference-schema';

export interface SurgeryReferenceDialogData {
  clinicalCase: ClinicalCase | Case,
  selectedFractures?: Array<FractureAndTreatment>;
  approach?: Approach,
  preparation?: Preparation
}

@Component({
  selector: 'app-surgery-reference-dialog',
  templateUrl: './surgery-reference-dialog.component.html',
  styleUrls: ['./surgery-reference-dialog.component.scss'],
})
export class SurgeryReferenceDialogComponent {
  rootPage: any;
  rootParams: SurgeryReferenceDialogData;
}
