import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GroupsService} from '../../../services/groups/groups.service';
import {Router} from '@angular/router';
import {GroupMembersResponse} from 'src/app/services/yeti-protocol/chatter-api';
import {GroupMember} from 'src/app/services/groups/group.model';
import {ModalController} from '@ionic/angular';
import {SeeAllPendingRequestsPendingInvitationsComponent}
  from '../../../dialogs/all-pending-requests/see-all-pending-requests-pending-invitations.component';
import {AppTranslationService} from '../../../services/app-translation.service';
import {ResponsiveUtilsService} from '../../../services/utils/responsive-utils.service';
import { SwiperLogic } from 'src/app/services/utils/swiper-logic';
import { SwiperOptions } from 'swiper';
import { waitTime } from 'src/app/services/utils/utils';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';

@Component({
  selector: 'app-pending-requests',
  templateUrl: './pending-requests.component.html',
  styleUrls: ['./pending-requests.component.scss'],
})
export class PendingRequestsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() groupId: string;
  @Output() pendingRequestOutcome: EventEmitter<boolean> = new EventEmitter();
  swiperLogic: SwiperLogic;
  pendingRequests: Array<GroupMember> = [];
  private readonly start = 0;
  private readonly count = 15;

  constructor(
    private groupsService: GroupsService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private modalController: ModalController,
    private appTranslationService: AppTranslationService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private dialogs: DialogsUIService,
    private el: ElementRef
  ) {
    this.swiperLogic = new SwiperLogic(230, this.responsiveUtilsService, this.el, 1.6);
  }

  ngOnInit(): void {
    this.fetchPendingRequests();
  }

  ngOnDestroy(): void {
    this.swiperLogic.destroy();
  }

  ngAfterViewInit(): void {
    waitTime(500).then(() => {
      this.swiperLogic.afterViewInit();
    });
  }

  get swiperConfig(): SwiperOptions {
    return this.swiperLogic.config;
  }

  fetchPendingRequests(): void {
    this.groupsService.getMemberRequests(this.groupId, this.start, this.count, 'requests').then((response: GroupMembersResponse) => {
      if (response) {
        this.pendingRequests = response.result.filter(i => i.status === 'pending-request');
      }
    })
  }

  goToNetwork(): void {
    this.router.navigateByUrl([this.contextService.currentContext.homePath, 'network'].join('/'));
  }

  acceptOrDeclineRequest(participant: GroupMember, value: string): void {
    this.groupsService.updateMemberRequests(participant.memberRequestId, value, participant.userId, this.groupId)
      .then(_result => {
        this.pendingRequests.splice(this.pendingRequests.findIndex(function (i) {
          return i.userId === participant.userId;
        }), 1);
        this.pendingRequestOutcome.emit();
      })
      .catch(async _err => {
        this.dialogs.showErrorDialog(await this.appTranslationService.get('app.common.something-went-wrong'));
      })
  }

  async showAllPendingRequests(): Promise<void> {
    const pendingRequests = await this.modalController.create({
      component: SeeAllPendingRequestsPendingInvitationsComponent,
      cssClass: 'all-pending-requests-dialog',
      componentProps: {
        groupId: this.groupId,
        status: 'requests'
      }
    });
    pendingRequests.present();
    pendingRequests.onDidDismiss().then(() => {
      this.fetchPendingRequests();
      this.pendingRequestOutcome.emit();
    })
  }
}
