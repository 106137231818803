import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Params } from '@angular/router';
import { Observable } from 'rxjs';

import { contextForClient } from 'src/app/contexts/utils';
import { ContextConfigType } from 'src/config/config.model';
import { AuthService } from './auth.service';
import { SignUpStatus } from './logic/auth-logic.service.interface';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import { GlobalSubjectsService, GlobalSubjects } from '../utils/global-subjects.service';
import { TRACKING_SERVICE, TrackingService, TrackingSourceParams } from '../tracking/tracking.model';

import appConfig from 'src/config/config';
import { ModalController } from '@ionic/angular';
import { DeepLinksStorageService } from '../deep-links/deep-links-storage.service';
import { NavControllerService } from '../nav-controller.service';
import { PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF } from './mi/mi-auth.service';

interface Config {
  clientId: string;
  contexts: ContextConfigType[];
}

// NOTE: used only on mobile platform
@Injectable({
  providedIn: 'root'
})
export class NonAuthenticatedAccessGuard {
  config: Config = {
    clientId: appConfig.authentication.clientId,
    contexts: appConfig.contexts
  };
  constructor(
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private router: Router,
    private globalSubjects: GlobalSubjectsService,
    private modalController: ModalController,
    private deepLinksStorage: DeepLinksStorageService,
    private navController: NavControllerService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.contextService.getCurrentContext().then(context => {
      return Promise.resolve(context?.key);
    }).catch(() => {
      return Promise.resolve(this.contextService?.currentContext?.key);
    }).then(currentContext => {

      if (!currentContext) {
        currentContext = this.contextService?.currentContext?.key;
      }

      return this.authService.getSignupStatus(currentContext)
        .then(status => {
          if (status !== SignUpStatus.FINISHED) {
            return true;
          }
          this.trackAuthMobilePageRedirect(route.queryParams); // track here since auth page is not opened
          return this.getHomeContextPath()
            .then(async homeContextPath => {
              const query: Array<string> = [];
              const params = route.queryParams;
              for (const key in params) {
                if (key in params) {
                  query.push(`${key}=${params[key]}`);
                }
              }

              const deepLink = await this.deepLinksStorage.getSavedDeepLink();

              let deepLinkWithoutQueryParams = deepLink;

              if (deepLinkWithoutQueryParams?.length && deepLinkWithoutQueryParams?.includes('?')) {
                deepLinkWithoutQueryParams = deepLinkWithoutQueryParams.split('?')?.[0];
              }

              if (deepLinkWithoutQueryParams &&
                deepLinkWithoutQueryParams !== '/auth' &&
                deepLinkWithoutQueryParams !== '/ao-auth-signup') {
                this.globalSubjects.emit(GlobalSubjects.HIDE_HEADER_AND_TABS, false);
                return this.navController.navigateRoot(deepLink);
              }

              if (query.length > 0) {
                homeContextPath = `${homeContextPath}?${query.join('&')}`;
              }

              const isModalOpened = await this.modalController.getTop();

              if (isModalOpened) {
                this.modalController.dismiss();
              }

              this.globalSubjects.emit(GlobalSubjects.HIDE_HEADER_AND_TABS, false);
              return this.router.parseUrl(homeContextPath);
            });
        });
    });
  }

  getHomeContextPath(): Promise<string> {
    if (this.config.contexts.length === 1) {
      // single specialty app
      return Promise.resolve(this.config.contexts[0].homePath);
    }
    return this.authService.getProfile(this.config.clientId, true, PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF)
      .then(profile => {
        const contextKey = contextForClient(profile.homeDevision);
        const context = this.contextService.getContext(contextKey);
        return context.homePath;
      });
  }

  private trackAuthMobilePageRedirect(queryParams: Params): void {
    this.trackingService.trackGenericClickedAction('authPage', 'authPage', 'authPageLoaded', queryParams as TrackingSourceParams)
      .catch(_err => {
        console.error('Could not track auth page opened action.');
      });
  }
}
