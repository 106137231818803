import { Component } from '@angular/core';

@Component({
  selector: 'app-crown-badge',
  templateUrl: './crown-badge.component.html',
  styleUrls: ['./crown-badge.component.scss']
})
export class CrownBadgeComponent {

}
