// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --accept-reject-card-padding-top: 15px;
  --accept-reject-card-padding-bottom: 15px;
  --accept-reject-card-padding-start: 15px;
  --accept-reject-card-padding-end: 15px;
  --accept-reject-card-background-color: var(--mi-white-color);
}

.accept-reject-card {
  padding-top: var(--accept-reject-card-padding-top);
  padding-bottom: var(--accept-reject-card-padding-bottom);
  padding-left: var(--accept-reject-card-padding-start);
  padding-right: var(--accept-reject-card-padding-end);
  border-bottom: 1.5px solid var(--mi-background-color);
}
.accept-reject-card.with-info-bubble-message {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 15px;
}
.accept-reject-card .user-info-container {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.accept-reject-card .user-info-container .profile-info-container {
  grid-row: 1;
  grid-column: 1;
}
.accept-reject-card .user-info-container .profile-info-container app-user-info {
  --user-info-user-profile-image-size: 40px;
  --user-info-column-gap: 18px;
}
.accept-reject-card .user-info-container .action-buttons-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.accept-reject-card .user-info-container .action-buttons-container app-icon-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: none;
  --icon-button-background-paddng: 0px;
  --icon-button-border-radius: 0%;
  --icon-button-icon-size: 30px;
  --icon-button-icon-color: var(--mi-color-text-lite);
}
.accept-reject-card .user-info-container .action-buttons-container app-icon-button.accept {
  --icon-button-icon-color: var(--mi-primary-color);
}
.accept-reject-card .info-bubble-message-container {
  width: 100%;
  height: 46px;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  background-color: var(--mi-background-color);
  border-radius: 12px;
  cursor: pointer;
}
.accept-reject-card .info-bubble-message-container .message {
  width: 100%;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
