import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser as InAppBrowserPlugin } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { DeepLinksRecognizerService } from './deep-links/deep-links-recognizer.service';

import appConfig from 'src/config/config';
import { Platform } from 'src/config/config.model';
import {
  ActionTracked,
  ExternalLinkTrackingParam,
  TrackingRequest
} from '../services/yeti-protocol/tracking';
import { AppTranslationService } from './app-translation.service';
import { TrackingService } from './tracking/tracking.model';

/* eslint-disable */ // problem: no-duplicate-enum-values
export enum TargetOfTheLink {
  Application = 0,
  SystemBrowser = 1,
  InAppBrowser = 2,
  Default = 1
}
/* eslint-enable */

interface LinkOpenerServiceConfig {
  platform: Platform;
  webUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class LinkOpenerService {
  config: LinkOpenerServiceConfig = appConfig;
  private _trackingService: TrackingService;

  constructor(
    private router: Router,
    private deepsLinksRecognizerService: DeepLinksRecognizerService,
    @Optional() private inAppBrowser: InAppBrowserPlugin,
    private appTranslationService: AppTranslationService
  ) { }

  open(url: string, targetHint: TargetOfTheLink = TargetOfTheLink.Default, skipDeepLinkCheck = false): TargetOfTheLink {
    if (!url || !url?.length) {
      throw new Error('url is not provided: ' + url);
    }

    if (!skipDeepLinkCheck && this.isDeepLink(url)) {
      this._openDeepLink(url);
      return TargetOfTheLink.Application;
    }

    if (targetHint === TargetOfTheLink.Default && this._isMobile) {
      targetHint = TargetOfTheLink.InAppBrowser;
    }

    switch (targetHint) {
      case TargetOfTheLink.InAppBrowser:
        if (this.inAppBrowser) {
          this._openInAppBrowser(url);
          return TargetOfTheLink.InAppBrowser;
        } else {
          this._openBrowser(url);
          return TargetOfTheLink.SystemBrowser;
        }
      case TargetOfTheLink.Application:
        this._openDeepLink(url);
        return TargetOfTheLink.Application;
      default:
        this._openBrowser(url);
        return TargetOfTheLink.SystemBrowser;
    }
  }

  // TODO: remove dependency
  set trackingService(trackingService: TrackingService) {
    this._trackingService = trackingService;
  }

  openIfDeepLinkOnly(url: string): void {
    if (!url) {
      return;
    }

    if (this.isDeepLink(url)) {
      this._openDeepLink(url);
    }
  }

  get _isMobile(): boolean {
    return this._isAndroid || this.config.platform === Platform.IOS;
  }

  get _isAndroid(): boolean {
    return this.config.platform === Platform.ANDROID;
  }

  _openDeepLink(url: string): void {
    const extractedDeepLinkPath = this.extractDeepLinkPath(url);
    this.router.navigateByUrl(extractedDeepLinkPath);
  }

  _openBrowser(url: string): void {
    if (!this._isInternalUrl(url)) {
      this.trackExternalLinkOpenedAction(url);
    }
    this._openWindow(url, '_blank');
  }

  _openInAppBrowser(url: string): void {
    if (!this._isInternalUrl(url)) {
      this.trackExternalLinkOpenedAction(url);
    }

    const location = this._isAndroid ? 'yes' : 'no';

    this.inAppBrowser.create(url, '_blank', {
      location: location,
      toolbarcolor: appConfig.inAppBrowserColors.toolbarcolor,
      closebuttoncaption: this.appTranslationService.instant('app.common.close'),
      closebuttoncolor: appConfig.inAppBrowserColors.closebuttoncolor,
      hideurlbar: 'yes',
      zoom: 'no'
    });
  }

  _openWindow(url: string, location: '_blank' | 'system'): void {
    window.open(url, location);
  }

  isDeepLink(url: string): boolean {
    const extractedDeepLinkPath = this.extractDeepLinkPath(url);

    if (extractedDeepLinkPath) {
      return this.deepsLinksRecognizerService.isDeepLink(extractedDeepLinkPath);
    }
    return false;
  }

  // return path or undefined if is not deep link
  extractDeepLinkPath(url: string): string {

    const dialogAuxiliaryRouterName = '(dialog:';
    let path;

    if (url.indexOf(dialogAuxiliaryRouterName) > -1) {
      path = `/${url.split(dialogAuxiliaryRouterName)?.[1]?.replace(')', '')}`;
    }

    const urlWithoutResourcesPart = url?.replace('resources.', '');

    if (this.config.webUrl === url.substr(0, this.config.webUrl.length)) {

      if (path) {
        return path;
      }

      return url.substr(this.config.webUrl.length - 1);
    }

    if (this.config.webUrl === urlWithoutResourcesPart.substr(0, this.config.webUrl.length)) {

      if (path) {
        return path;
      }

      return urlWithoutResourcesPart.substr(this.config.webUrl.length - 1);
    }
  }

  _isInternalUrl(url: string): boolean {
    return (this.config.webUrl === url.substr(0, this.config.webUrl.length));
  }

  openDocumentLink(link: string): void {
    // in app browser for android does not have PDF viewer
    if (this._isAndroid) {
      window.open(link, '_system', 'location=yes,enableViewportScale=yes,hidden=no');
    } else {
      this.open(link);
    }
  }

  private trackExternalLinkOpenedAction(url: string): void {
    const paramsToTrack: ExternalLinkTrackingParam = {
      url: url
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.externalLinkOpened,
      params: paramsToTrack
    };

    // TODO: if this is undefined link is not opened because it throws error
    if (!this._trackingService) {
      return;
    }

    this._trackingService.track(trackData).catch(_err => {
      console.error('Could not track external-link-opened action');
    });
  }
}
