import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

// components
import { RichTextDocumentEditorComponent } from
  'src/app/modules/rich-text-document-editor/components/rich-text-document-editor/rich-text-document-editor.component';

@Component({
  selector: 'app-create-group-case-text-input-section',
  templateUrl: './create-group-case-text-input-section.component.html',
  styleUrls: ['./create-group-case-text-input-section.component.scss'],
})
export class CreateGroupCaseTextInputSectionComponent implements AfterViewInit {

  @ViewChild('richTextDocumentEditor') richTextDocumentEditor: RichTextDocumentEditorComponent;

  @Input() content = '';
  @Input() placeholder: string;

  @Output() contentChanged: EventEmitter<string> = new EventEmitter();

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setRichTextEditorHeightValues();
    }, 0);
  }

  postContentChanged(content: string): void {
    this.content = content;
    this.emitContentChanged(this.content);
  }

  setRichTextEditorHeightValues(): void {
    if (this.richTextDocumentEditor && this.richTextDocumentEditor.setEditorHeight) {
      this.richTextDocumentEditor?.setEditorHeight('105px');
    }
  }

  emitContentChanged(content: string): void {
    this.contentChanged.emit(content);
  }

}
