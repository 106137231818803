// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orcid-article-card-header-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  justify-items: start;
  align-items: start;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.orcid-article-card-header-container .user-profile-image-container {
  grid-row: 1;
  grid-column: 1;
  justify-self: center;
  align-self: center;
}
.orcid-article-card-header-container .user-profile-image-container app-user-profile-image {
  --user-image-size: 40px;
}
.orcid-article-card-header-container .user-profile-image-container .ao-member-symbol-component {
  position: absolute;
  top: 40px;
  left: 20px;
}
.orcid-article-card-header-container .text-container {
  width: 100%;
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  row-gap: 2.5px;
}
.orcid-article-card-header-container .text-container .user-fullname-container {
  width: 100%;
  justify-self: start;
  overflow: hidden;
  display: grid;
  grid-template-rows: min-content;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  align-items: center;
  max-width: 100%;
}
.orcid-article-card-header-container .text-container .user-fullname-container .text {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: 1;
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  justify-self: start;
  overflow: hidden;
  display: grid;
  grid-template-rows: min-content;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  align-items: center;
  max-width: 100%;
}
.orcid-article-card-header-container .text-container .user-fullname-container .user-fullname {
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
  width: 100%;
  justify-self: start;
  overflow: hidden;
  display: grid;
  grid-template-rows: min-content;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  align-items: center;
  max-width: 100%;
}
.orcid-article-card-header-container .text-container .user-fullname-container .user-fullname span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.orcid-article-card-header-container .text-container .user-fullname-container .orc-id-icon {
  --icon-size: 16px;
  margin-left: 3px;
  margin-right: 3px;
}
.orcid-article-card-header-container .text-container .user-country-container {
  width: 100%;
  justify-self: start;
  overflow: hidden;
}
.orcid-article-card-header-container .text-container .user-country-container .country {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: 1;
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
