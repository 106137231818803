import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AOAuthServiceInterface } from './ao-auth.service.interface';

import { MIAOUtilsService } from './mi-ao-utils.service.service';
import { UrlUtilsService } from '../../utils/url-utils.service';
import { SchemaValidatorService } from '../../schema-validator.service';
import { LOCAL_STORAGE_SERVICE, SESSION_STORAGE_SERVICE, StorageService } from '../../storage/storage.service';
import { AOAuthBase } from './ao-auth.base';

import { LocationService } from '../../location.service';
import { AOSignInResult } from './ao-auth.model';
import { LinkOpenerService } from '../../link-opener.service';
import { Platform } from 'src/config/config.model';
import { RedirectResult } from '../logic/auth-logic.service.interface';

@Injectable()
export class AOAuthBrowserService extends AOAuthBase implements AOAuthServiceInterface {

  constructor(
    protected miaoUtilsService: MIAOUtilsService,
    protected httpClient: HttpClient,
    protected schemaValidator: SchemaValidatorService,
    @Inject(LOCAL_STORAGE_SERVICE) protected storage: StorageService,
    @Inject(SESSION_STORAGE_SERVICE) protected sessionStorageService: StorageService,
    protected urlUtilsService: UrlUtilsService,
    protected locationService: LocationService,
    private linkOpenerService: LinkOpenerService
  ) {
    super();
  }

  // returns url for redirect
  signIn(finalRedirectUrl?: string): Promise<AOSignInResult> {

    finalRedirectUrl = this.getRedirectUrl();

    return this._getSignInParams(finalRedirectUrl)
      .then(params => {
        return this._saveSignInParams(params, this.sessionStorageService);
      })
      .then(params => {
        return { redirectUrl: params.authenticationUrl };
      });
  }

  signUp(): Promise<RedirectResult> {
    const redirectUrl = this._getSignUpUrl(this.getSignupRedirectUrl());
    return Promise.resolve({ redirectUrl: redirectUrl });
  }

  signOut(): Promise<RedirectResult> {
    return Promise.all([
      this.miaoUtilsService.signOut(),
      this.storage.remove(this.TOKENS_STORAGE_KEY)
    ]).then(() => {
      const logoutUrl = this._getSignOutUrl(this.getLogoutRedirectUrl());
      if (logoutUrl) {
        this.locationService.href = logoutUrl;
        return { redirectUrl: logoutUrl };
      }
    });
  }

  getRedirectUrl(): string {

    if (this.config.platform === Platform.BROWSER) {
      return 'http://localhost:8000/auth-v2-ao-redirect';
    }

    return this.config.authenticationAO.redirectUri;
  }

  getSignupRedirectUrl(): string {

    if (this.config.platform === Platform.BROWSER) {
      return 'http://localhost:8000/ao-auth-signup';
    }

    return this.config.authenticationAO.signUpRedirectUri;
  }

  getLogoutRedirectUrl(): string {

    if (this.config.platform === Platform.BROWSER) {
      return 'http://localhost:8000/auth';
    }

    return this.config.authenticationAO.logoutRedirectUri;
  }

  isBrowser(): boolean {
    return true;
  }

  openAoLink(url: string): Promise<void> {

    if (url && url.length > 0) {
      this.linkOpenerService.open(url);
      return Promise.resolve();
    }

    return Promise.reject();
  }
}
