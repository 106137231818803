import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

// services
import { TranslationService } from '../../services/translation.service';

// models
import { Translator, TranslatorLanguage } from '../../services/translation.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-translation-languages-list',
  templateUrl: './translation-languages-list.component.html',
  styleUrls: ['./translation-languages-list.component.scss'],
})
export class TranslationLanguagesListComponent implements OnInit, OnChanges {

  @Input() selectedTranslationLanguageKey = ''; // always equal to the translation language key in the db on the server
  @Output() translationLanguageSelectionChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() translationLanguageFormValidStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('f', { static: false }) translationLanguageForm: NgForm;

  translator = Translator;
  translationLanguageKey: string;
  translationLanguages: Array<TranslatorLanguage>;

  constructor(private translationService: TranslationService) { }

  ngOnInit(): void {
    this.translationLanguages = this.translationService.getLanguages(this.translator.google);
    this.translationLanguageKey = this.selectedTranslationLanguageKey;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTranslationLanguageKey && !changes.selectedTranslationLanguageKey.firstChange) {
      this.translationLanguageFormValidStatusChange.emit(this.formValidStatus);
    }
  }

  onChange(_event: Event): void {
    this.translationLanguageSelectionChange.emit(this.translationLanguageKey);
    this.translationLanguageFormValidStatusChange.emit(this.formValidStatus);
  }

  get formValidStatus(): boolean {
    return this.translationLanguageForm.valid && this.translationLanguageKey !== this.selectedTranslationLanguageKey;
  }

}
