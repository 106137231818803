// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --gallery-image-max-width: 400px;
  --gallery-image-max-height: 400px;
  --gallery-thumbs-width: 120px;
  --gallery-thumbs-height: 90px;
  --gallery-row-gap: 3px;
}

.image-gallery-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
}
.image-gallery-container .toolbar-container {
  grid-column: 1;
  grid-row: 1;
}
.image-gallery-container .toolbar-container .action-buttons-container {
  display: grid;
  grid-template-rows: min-content;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  justify-content: end;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.image-gallery-container .toolbar-container .action-buttons-container .edit {
  background: none;
  margin: 0;
  padding: 0;
  outline: none;
}
.image-gallery-container .toolbar-container .action-buttons-container .edit app-icon {
  --icon-size: 22px;
  color: var(--mi-black-color);
}
.image-gallery-container .toolbar-container .action-buttons-container .remove-attachment {
  background: none;
  margin: 0;
  padding: 0;
  outline: none;
}
.image-gallery-container .toolbar-container .action-buttons-container .remove-attachment app-icon {
  --icon-size: 22px;
  color: var(--mi-black-color);
}
.image-gallery-container .gallery-container {
  grid-column: 1;
  grid-row: 2;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--mi-black-color);
}
.image-gallery-container .gallery-container .wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: calc(100% - var(--gallery-thumbs-height)) min-content;
  row-gap: var(--gallery-row-gap);
  position: absolute;
}
.image-gallery-container .gallery-container .wrapper .images-container {
  grid-row: 1;
  grid-column: 1;
}
.image-gallery-container .gallery-container .wrapper .thumbs-container {
  grid-row: 2;
  grid-column: 1;
  width: 100%;
  height: var(--gallery-thumbs-height);
}
.image-gallery-container .gallery-container .wrapper .thumbs-container swiper app-image ::ng-deep div ion-img {
  width: 100%;
  height: var(--gallery-thumbs-height);
  object-fit: cover;
}
.image-gallery-container .gallery-container .wrapper swiper {
  --swiper-navigation-color: var(--mi-white-color);
  --swiper-pagination-color: var(--mi-white-color);
  width: 100%;
  height: 100%;
}
.image-gallery-container ::ng-deep .swiper-zoom-container {
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
.image-gallery-container ::ng-deep .swiper-zoom-container .video-image {
  position: relative;
}
.image-gallery-container ::ng-deep .swiper-zoom-container .video-image .play {
  position: absolute;
  top: 40%;
  width: 100%;
  height: 68px;
  opacity: 0.3;
  display: block;
  margin: 0 auto;
}
.image-gallery-container ::ng-deep .swiper-zoom-container .swiper-zoom-target {
  width: 100%;
  height: 100%;
}
.image-gallery-container ::ng-deep .swiper-zoom-container .swiper-zoom-target.with-caption {
  height: calc(100% - 70px);
  flex-grow: 1;
}
.image-gallery-container ::ng-deep .swiper-zoom-container .swiper-zoom-target ion-img {
  height: 100%;
  object-fit: contain;
}
.image-gallery-container ::ng-deep .thumbs-container .video-image {
  position: relative;
  height: 100%;
}
.image-gallery-container ::ng-deep .thumbs-container .video-image .play {
  position: absolute;
  top: 40%;
  width: 100%;
  height: 34px;
  opacity: 0.3;
  display: block;
  margin: 0 auto;
}

.caption-container {
  background-color: var(--mi-light-purple-6-color);
  color: var(--mi-black-color);
  font-size: var(--font-size-regular);
  padding: 5px 15px;
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
