import { InjectionToken } from '@angular/core';
import { KeyValueStorage, ReadyKeyValueStorage, STORAGE_MODE } from './storage.model';
import { BrowserStorage } from './browser.storage';

export class StorageServiceConfig {
  mode: STORAGE_MODE = STORAGE_MODE.localstorage;
}

export const LOCAL_STORAGE_SERVICE = new InjectionToken<StorageService>('local.storage.service');
export const SESSION_STORAGE_SERVICE = new InjectionToken<StorageService>('session.storage.service');

interface StorageSource {
  localStorage: any;
  sessionStorage: any;
}

export const LAST_URL = 'last-url';

export class StorageService implements KeyValueStorage {
  private storage: ReadyKeyValueStorage;

  constructor(config: StorageServiceConfig, windowObject?: StorageSource) {
    windowObject = windowObject || window;
    switch (config.mode) {
      case STORAGE_MODE.localstorage:
        this.storage = new BrowserStorage(windowObject.localStorage);
        break;
      case STORAGE_MODE.sessionstorage:
        this.storage = new BrowserStorage(windowObject.sessionStorage);
        break;
      default:
        throw new Error('not recognised storage mode: ' + config.mode);
    }
  }

  get(key: string): Promise<any> {
    return this.storage.ready().then(() => this.storage.get(key));
  }

  set<T>(key: string, value: T): Promise<any> {
    return this.storage.ready().then(() => this.storage.set(key, value));
  }

  remove(key: string): Promise<any> {
    return this.storage.ready().then(() => this.storage.remove(key));
  }

  removaAll(): Promise<any> {
    return this.storage.ready().then(() => this.storage.removaAll());
  }

  size(): Promise<number> {
    return this.storage.ready().then(() => this.storage.size());
  }

  key(idx: number): Promise<string> {
    return this.storage.ready().then(() => this.storage.key(idx));
  }

}
