import { Injectable } from '@angular/core';
import { ImageGalleryStrategy } from './image-gallery';
import { DialogsUIService } from '../dialogs/dialogs.ui.service';
import { ConfirmDialogData } from '../dialogs/dialogs.ui.interface';
import { ClinicalCase } from '../yeti-protocol/clinical-case';

@Injectable({
  providedIn: 'any',
})
export class ImageGalleryCaseStrategy extends ImageGalleryStrategy {

  constructor(
    private dialogs: DialogsUIService
  ) {
    super();
  }

  async shouldProceedWithDelete(): Promise<boolean> {

    if (this.clinicalCaseImagesCount === 1) {
      await this.presentMinImageVideoCountWarning();
      return false;
    }

    try {
      return await this.presentRemoveImageAlert();
    } catch (err) {
      return false;
    }
  }

  async presentRemoveImageAlert(): Promise<boolean> {

    const removeActionKey = 'remove';
    const cancelActionKey = 'cancel';

    const changeProfessionConfirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.ImageGallery.delete-image-warning-title'
        },
        message: {
          translationKey: 'app.ImageGallery.case-delete-image-video-warning'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.cancel',
            },
            className: 'secondary'
          },
          {
            key: removeActionKey,
            label: {
              translationKey: 'app.common.remove',
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(changeProfessionConfirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === removeActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  private async presentMinImageVideoCountWarning(): Promise<void> {

    const minImageVideoCountWarningDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.ImageGallery.case-last-media-object-remove-title'
        },
        message: {
          translationKey: 'app.ImageGallery.case-last-media-object-remove-message'
        },
        actions: [
          {
            key: 'got-it',
            label: {
              translationKey: 'app.common.got-it',
            },
            className: 'primary'
          }
        ]
      }
    }

    const minImageVideoCountWarningDialog = await this.dialogs.createConfirmDialog(minImageVideoCountWarningDialogData);
    return minImageVideoCountWarningDialog.present();
  }

  get clinicalCaseImagesCount(): number {

    const preOpDocumentsCount = (this.parentObject as ClinicalCase)?.preOpDocuments?.length || 0;
    const postOpDocumentsCount = (this.parentObject as ClinicalCase)?.postOpDocuments?.length || 0;
    const intraOpDocumentsCount = (this.parentObject as ClinicalCase)?.intraOpDocuments?.length || 0;
    const clinicalFollowUpDocumentsCount = (this.parentObject as ClinicalCase)?.clinicalFollowUpDocuments?.length || 0;

    return preOpDocumentsCount + postOpDocumentsCount + intraOpDocumentsCount + clinicalFollowUpDocumentsCount;
  }
}
