// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.case-classification-tag-selection-container .topics-container {
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
  row-gap: 10px;
}
.case-classification-tag-selection-container .topics-container .title-container {
  grid-row: 1;
  grid-column: 1;
  overflow: hidden;
}
.case-classification-tag-selection-container .topics-container .title-container .title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-classification-tag-selection-container .topics-container .title-container .title span {
  color: var(--mi-secondary-color-2);
}
.case-classification-tag-selection-container .topics-container .title-container .title.error {
  color: var(--mi-red-color-2);
}
.case-classification-tag-selection-container .topics-container .title-container .title.error span {
  color: var(--mi-red-color-2);
}
.case-classification-tag-selection-container .topics-container .title-container.dynamic {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: min-content;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
.case-classification-tag-selection-container .topics-container .title-container.dynamic .title {
  grid-row: 1;
  grid-column: 1;
}
.case-classification-tag-selection-container .topics-container .title-container.dynamic .progress-count-badge-container {
  grid-row: 1;
  grid-column: 2;
}
.case-classification-tag-selection-container .topics-container .title-container.dynamic .progress-count-badge-container .progress-count-badge {
  margin: 0;
  white-space: nowrap;
  background-color: var(--mi-light-blue-color);
  border-radius: 20px;
  padding: 4px 15px;
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
}
.case-classification-tag-selection-container .topics-container app-chips {
  grid-row: 2;
  grid-column: 1;
}
.case-classification-tag-selection-container .topics-container app-chips app-case-classification-chip {
  --chips-margin: 7px;
  --rows-margin: 8px;
}
.case-classification-tag-selection-container .topics-container app-chips app-case-classification-chip.with-close-icon {
  --case-classification-chip-icon-size: 10px;
  --case-classification-column-gap: 10px;
}
.case-classification-tag-selection-container .topics-container.primary-topics-container app-chips app-case-classification-chip {
  --case-classification-chip-color: var(--mi-blue-color-4);
  --case-classification-chip-selected-background-color: var(--mi-blue-color-4);
  --case-classification-chip-selected-color: var(--mi-secondary-color-2);
  --case-classification-chip-background-color: var(--mi-white-color);
  margin-right: 8px !important;
}
.case-classification-tag-selection-container .topics-container.secondary-topics-container {
  margin-top: 20px;
}
.case-classification-tag-selection-container .topics-container.secondary-topics-container app-chips app-case-classification-chip {
  margin-right: 8px !important;
  --case-classification-chip-background-color: var(--mi-white-color);
  --case-classification-chip-color: var(--mi-green-color);
  --case-classification-chip-selected-background-color: var(--mi-light-green-color);
  --case-classification-chip-selected-color: var(--mi-green-color);
}
.case-classification-tag-selection-container .topics-container .selected-topic-container.secondary app-case-classification-chip {
  --case-classification-chip-selected-background-color: var(--mi-light-green-color);
}
.case-classification-tag-selection-container app-case-classification-chip {
  display: inline-block;
  --case-classification-text-color: var(--mi-grey-color-13);
  --case-classification-text-size: var(--font-size-small);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
