import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

// services
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { ClinicalExpertsService } from 'src/app/services/clinical-experts.service';
import { DomUtilsService } from 'src/app/services/utils/dom-utils.service';

import { ResponsiveUtilsService } from '../../../services/utils/responsive-utils.service';
import { ShortPublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { Posts } from '../../../state/posts/posts.actions';
import { Store } from '@ngxs/store';
import { ActionSource } from '../../../services/yeti-protocol/tracking';

@Component({
  selector: 'app-my-people-list-item',
  templateUrl: './my-people-list-item.component.html',
  styleUrls: ['./my-people-list-item.component.scss'],
})
export class MyPeopleListItemComponent implements OnInit {

  @Input() userFollower: ShortPublicProfile;
  @Input() source: ActionSource;

  @Output() itemChanged: EventEmitter<void> = new EventEmitter<void>();
  infoSheetId = 'my-people-list-item-info-sheet';

  constructor(
    private infoSheetService: InfoSheetService,
    private clinicalExpertService: ClinicalExpertsService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private router: Router,
    private domUtils: DomUtilsService,
    private responsiveUtilsService: ResponsiveUtilsService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);
  }

  async openPeopleSettings(event: Event, _userFollower: ShortPublicProfile): Promise<void> {
    this.uiUtilsService.stopEventPropagation(event);
    if (this.isDesktop) {
      this.infoSheetService.openWeb(this.infoSheetActions, event)
        .then(action => {
          if (action && action.code) {
            action.handler();
          }
        })
    } else {
      this.infoSheetService.open(this.infoSheetId);
    }
  }

  closeSettings(): void {
    this.infoSheetService.close(this.infoSheetId);
  }

  followUnfollowClinicalExpert(expertUserId: string, follow: boolean): void {
    this.clinicalExpertService.updateClinicalExpertFollow(expertUserId, follow, this.source)
      .then(response => {
        if (response && response.success) {
          this.store.dispatch(new Posts.UpdatePostsOwnerFollowingStatus(expertUserId, follow));
          this.domUtils.getElementById(`${this.userFollower.userId}`).style.display = 'none';
          this.itemChanged.emit();
        }
      });
  }

  openPublicProfilePage(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    const homePath = this.contextService.currentContext.homePath;
    this.router.navigateByUrl(`${homePath}/public/profile/${this.userFollower.userId}`);
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    const actions: Array<InfoSheetActionItem> = [];
    if (this.userFollower) {
      if (this.userFollower.isFollower) {
        actions.push({
          id: 'my_people_item_unfollow_btn',
          icon: 'md-icon-clear',
          textKey: 'app.common.unfollow',
          code: 'UNFOLLOW_USER',
          handler: () => {
            this.followUnfollowClinicalExpert(this.userFollower.userId, false);
          }
        });
      } else if (!this.userFollower.isFollower) {
        actions.push({
          id: 'my_people_item_follow_btn',
          icon: 'md-icon-user-plus',
          textKey: 'app.common.follow',
          code: 'FOLLOW_USER',
          handler: () => {
            this.followUnfollowClinicalExpert(this.userFollower.userId, true)
          }
        });
      }
    }
    return actions;
  }
}
