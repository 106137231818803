import { AppEnvironmentConfigInterface, Environment } from '../config.model';
import ApplicationConfig from './myao.config';

const DRYWALL_SERVER = 'https://logindev.insights.md';
const WEB_SERVER_HOST = 'int2.myao.app';
const WEB_SERVER = `https://${WEB_SERVER_HOST}`;

export default class EnvironmentConfig extends ApplicationConfig implements AppEnvironmentConfigInterface {
  environment: Environment = 'dev';
  authentication = {
    serverUrl: DRYWALL_SERVER + '/mi/oauth2/',
    clientId: 'myAO',
    clientSecret: 'G3QbTGCN'
  };

  authenticationAO = {
    clientId: '9raI4QenZn7odzXhDHZmv9rlaTHX4qQA',
    serverUrl: 'https://int-aofoundation.eu.auth0.com',
    redirectUri: WEB_SERVER + '/auth-v2-ao-redirect',
    signUpRedirectUri: WEB_SERVER + '/ao-auth-signup',
    signUpRedirectUriIOS: 'myao://' + WEB_SERVER_HOST + '/ao-auth-signup',
    version: 2,
    scopes: ['openid', 'profile', 'name', 'given_name', 'familiy_name', 'identities', 'address', 'email', 'profile'],
    updateEmailAddressAOLink: 'https://aofoundation2023--int.sandbox.my.site.com/ao/s/profile/home',
    updateUserDataAoLink: 'https://aofoundation2023--int.sandbox.my.site.com/ao/s/profile/home',
    userRegistrationUrl: 'https://aofoundation2023--int.sandbox.my.site.com/evt/s/user-registration',
    logoutUrl: 'https://int-aofoundation.eu.auth0.com/oidc/logout',
    logoutRedirectUri: WEB_SERVER + '/auth'
  };

  authenticationOrcId = {
    clientId: 'APP-0LQHL4SNK9JRLKBJ',
    serverUrl: 'https://sandbox.orcid.org',
    redirectUri: WEB_SERVER + '/auth-orcid-redirect',
    clientSecret: '0d3b1350-b12d-4023-aecf-698323804e0c'
  };

  aoOutlinks = {
    browsePrivileges: 'https://int.aofoundation.org/our-community',
    myMemberships: 'https://aofoundation2023--int.sandbox.my.site.com/ao/s/my-memberships',
    membershipPrivileges: {
      spine: 'https://int.aofoundation.org/spine/membership/membership-privileges',
      trauma: 'https://int.aofoundation.org/trauma/membership/membership-privileges',
      recon: 'https://int.aofoundation.org/trauma/membership/membership-privileges',
      cmf: 'https://int.aofoundation.org/cmf/membership/membership-privileges',
      vet: 'https://int.aofoundation.org/vet/membership/membership-privileges',
    },
    renewMembership: {
      spine: 'https://aofoundation2023--int.sandbox.my.site.com/ao/s/membership-purchase?ContextId=AO%20Spine',
      trauma: 'https://aofoundation2023--int.sandbox.my.site.com/ao/s/membership-purchase?ContextId=AO%20Trauma',
      recon: 'https://aofoundation2023--int.sandbox.my.site.com/ao/s/membership-purchase?ContextId=AO%20Trauma',
      cmf: 'https://aofoundation2023--int.sandbox.my.site.com/ao/s/membership-purchase?ContextId=AO%20CMF',
      vet: 'https://aofoundation2023--int.sandbox.my.site.com/ao/s/membership-purchase?ContextId=AO%20VET',
    },
    aoFacultyLounge: {
      spine: 'https://int.aofoundation.org/spine/education/faculty/faculty-lounge',
      recon: 'https://int.aofoundation.org/recon/education/faculty/faculty-lounge',
      cmf: 'https://int.aofoundation.org/cmf/education/faculty-area/faculty-lounge',
      trauma: 'https://int.aofoundation.org/trauma/education/faculty/faculty-center',
      vet: 'https://int.aofoundation.org/vet/education/faculty/faculty-lounge'
    }
  }

  serverUrl = DRYWALL_SERVER + '/api/v2/';
  serverUrlIonic = DRYWALL_SERVER + '/api/ionic/';
  chatterUrl = DRYWALL_SERVER + '/chatter/ionic/';
  eventUrl = DRYWALL_SERVER + '/api/ionic/events/events';
  eventGroupsUrl = DRYWALL_SERVER + '/chatter/ionic/groups/events';
  sharedGroupsUrl = DRYWALL_SERVER + '/chatter/ionic/groups/shared';
  sharedEventsUrl = DRYWALL_SERVER + '/api/ionic/events/shared';

  backendUrl = DRYWALL_SERVER + '/backend/';
  backendUrlIonic = DRYWALL_SERVER + '/backend/ionic/';
  appUpdateUrl = WEB_SERVER + '/resource-update/status.json';
  imagesProxyServerUrl = WEB_SERVER_HOST + '/proxy/';
  webUrl = WEB_SERVER + '/';
  drywallServer = DRYWALL_SERVER + '/';

  searchAOServer = DRYWALL_SERVER + '/chatter/groups/search';
  webSocketServer = DRYWALL_SERVER;
  aiUrl = DRYWALL_SERVER + '/ai';
  recommendationsUrl = DRYWALL_SERVER + '/api/recommendations';
  showResourceUpdateInstallPrompt = true;
  scripts = [
    {
      name: 'cookieProScript',
      src: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
      dataDomainScript: '93abae32-75b2-485e-b6df-92afde7b32fb-test',
      enabled: true
    }
  ];
  clientConfigurationsUrl = WEB_SERVER + '/assets/client-config.json';
}
