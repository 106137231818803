// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --popup-height: 190px;
  height: var(--popup-height);
  width: 100%;
  bottom: -190px;
  position: absolute;
  transform: var(--popup-height);
  text-align: center;
  opacity: 0;
  transition: bottom 2s, opacity 1s;
  background-color: transparent;
}
:host.resource-update-available {
  bottom: 0;
  opacity: 1;
  z-index: 10;
}

ion-list {
  max-width: 300px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 0;
  font-size: var(--font-size-small);
}
ion-list ion-item {
  --background: var(--ion-color-primary-shade);
}
ion-list ion-label {
  margin: 0;
  padding-top: 6px;
  color: var(--ion-color-primary-contrast);
}
ion-list ion-button {
  margin: 0 auto;
}
ion-list ::ng-deep app-icon {
  --icon-size: 14px;
  color: var(--ion-color-primary-contrast);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
