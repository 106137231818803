export enum JoinMYAOObjectTypes {
  'CASE' = 'case',
  'GROUP' = 'group',
  'USER' = 'user'
}

export enum JoinMYAOSteps {
  'AUTH' = 'auth',
  'REQUEST_ACCESS' = 'request-access',
  'REQUEST_SENT' = 'request-sent'
}
