import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-event-image',
  templateUrl: './event-image.component.html',
  styleUrls: ['./event-image.component.scss'],
})
export class EventImageComponent {

  @Input() eventImageUrl?: string;
  @Input() text?: string;
  @Input() withoutBanner?: boolean;
  @Output() imageClick: EventEmitter<any> = new EventEmitter();

  onClick(): void {
    this.imageClick.emit();
  }

  get imageSrc(): string {
    switch (this.text?.toLowerCase()) {
      case 'webinar':
        return '/assets/images/default-webinars-image.svg';
      case 'meeting':
        return '/assets/images/default-meetings-image.svg';
      default:
        return '/assets/images/default-events-image.svg';
    }
  }

  get banner(): string {
    switch (this.text?.toLowerCase()) {
      case 'webinar':
        return 'app.common.webinar';
      case 'meeting':
        return 'app.common.meeting';
      default:
        return 'app.common.event';
    }
  }

}
