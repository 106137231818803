import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-group-case-button',
  templateUrl: './group-case-button.component.html',
  styleUrls: ['./group-case-button.component.scss'],
})
export class GroupCaseButtonComponent extends BaseButtonComponent<void> {
  @Input() iconOnly: boolean;
  @Input() buttonText = 'app.groups.case';
}
