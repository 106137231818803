// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container-web {
  width: unset !important;
  margin: 0 15px;
  border-radius: 10px;
}

.list-container {
  width: 100%;
  padding: 0px 16px;
  background: var(--mi-light-purple-1-color);
}
.list-container .header-container-events {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 10px;
}
.list-container .header-container-events .titles-container {
  display: grid;
  grid-row-gap: 5px;
  row-gap: 5px;
  margin-top: 15px;
}
.list-container .list-item {
  width: 100%;
}
.list-container .events-list-label.header {
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  color: var(--mi-secondary-color-2);
}
.list-container .events-list-label.label {
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  color: var(--mi-color-text-lite);
}
.list-container .see-all-text {
  padding-right: 14px;
}
.list-container .slides-header {
  margin-left: 16px;
  color: var(--mi-black-color);
  font-size: var(--font-line-height-small);
  font-weight: var(--font-weight-semi-bold);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
