import {
  RecommendationsBookmarksResponsePayload,
  SavedKnowledgeObject
} from 'src/app/services/yeti-protocol/recommendations-bookmarks';

export namespace SavedKnowledge {
  export interface FetchSavedKnowledgePayload {
    pageIndex?: number;
    pageSize?: number;
  }

  export class FetchSavedKnowledge {
    static readonly type = '[Saved knowledge] Fetch saved knowledge';
    constructor(public payloadParams: FetchSavedKnowledgePayload) { }
  }

  export class FetchSavedKnowledgeSuccess {
    static readonly type = '[Saved knowledge] Fetch saved knowledge success';
    constructor(
      public payloadParams: FetchSavedKnowledgePayload,
      public response: RecommendationsBookmarksResponsePayload
    ) { }
  }

  export class FetchSavedKnowledgeFailed {
    static readonly type = '[Saved knowledge] Fetch saved knowledge failed';
  }

  export class InsertSavedKnowledgeBeforeIndex {
    static readonly type = '[Saved knowledge] Insert saved knowledge before index';
    constructor(
      public savedKnowledgeObject: SavedKnowledgeObject,
      public index: number
    ) { }
  }

  export class RemoveSavedKnowledge {
    static readonly type = '[Saved knowledge] Remove saved knowledge at index';
    constructor(
      public index: number
    ) { }
  }

  export class UpdateSavedKnowledge {
    static readonly type = '[Saved knowledge] Update saved knowledge';
    constructor(
      public savedKnowledgeObject: SavedKnowledgeObject
    ) { }
  }
}
