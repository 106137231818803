import { Component } from '@angular/core';

@Component({
  selector: 'app-items-list-skeleton',
  templateUrl: './items-list-skeleton.component.html',
  styleUrls: ['./items-list-skeleton.component.scss'],
})
export class ItemsListSkeletonComponent {
  skeletonList: Array<any> = new Array(5);
}
