import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ao-member-symbol',
  templateUrl: './ao-member-symbol.component.html',
  styleUrls: ['./ao-member-symbol.component.scss'],
})
export class AoMemberSymbolComponent {

  @Input() isAOMember: boolean;

}
