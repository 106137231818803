import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

import { ImageEditorComponent } from './image-editor/image-editor.component';
import { ImageEditorDialogComponent } from './edit-image-dialog/image-editor-dialog.component';
import {ImageComponent} from './image/image.component';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SwiperModule,
    TranslateModule,
    IconComponent
  ],
  declarations: [
    ImageEditorComponent,
    ImageEditorDialogComponent,
    ImageComponent
  ],
  exports: [
    ImageEditorDialogComponent,
    ImageComponent
  ]
})
export class ImageEditorModule {}
