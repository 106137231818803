import { Inject, Injectable } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import appConfig from 'src/config/config';
import { SharingAbstractService } from './sharing.abstract.service';
import { ObjectForSharing, ObjectForSharingType, SocialSharingApp } from './sharing.model';
import { SharingService } from './sharing.service.interface';
import { UrlUtilsService } from '../utils/url-utils.service';
import { ContextService, CONTEXT_SERVICE } from '../context/context.model';

const PACKAGE_ID = {
  ios : {
    facebook: 'com.apple.social.facebook',
    linkedin: 'com.apple.social.linkedin',
    twitter: 'com.apple.social.twitter'
  },
  android: {
    facebook: 'com.facebook.katana',
    linkedin: 'com.linkedin.android',
    twitter: 'com.twitter.android'
  }
}

@Injectable({
  providedIn: 'root'
})
export class SharingDeviceService extends SharingAbstractService implements SharingService {
  constructor(
    private socialSharing: SocialSharing,
    @Inject(CONTEXT_SERVICE) contextService: ContextService,
    urlUtilsService: UrlUtilsService
  ){
    super(contextService, urlUtilsService);
  }

  share(target: SocialSharingApp, objectForSharing: ObjectForSharing): Promise<boolean> {
    return this.canShareWithApp(target)
      .then(canShare => {
        if (canShare) {
          this._share(target, objectForSharing);
        }
        return canShare;
      });
  }

  _share(target: SocialSharingApp, objectForSharing: ObjectForSharing): void {
    let url;
    const imageUrl = this.getShareObjectImage(objectForSharing);
    const baseParams = `?source=${target}&campaign=shared${objectForSharing?.type}`;

    if (objectForSharing?.type === ObjectForSharingType.SURGERY_REFERENCE || objectForSharing?.type === ObjectForSharingType.EVENT) {
      url = objectForSharing?.url;
    } else if (objectForSharing?.type === ObjectForSharingType.PROFILE) {
      url = `${objectForSharing?.wrapperUrl}${baseParams}_${objectForSharing?._id}`;
    } else {
      url = `${objectForSharing?.wrapperUrl}${baseParams}_${target}`;
    }

    switch(target){
      case SocialSharingApp.EMAIL:
        this.socialSharing.shareViaEmail(url, objectForSharing?.title, ['']);
        break;
      case SocialSharingApp.FACEBOOK:
        this.socialSharing.shareViaFacebook(objectForSharing?.title, imageUrl, url);
        break;
      case SocialSharingApp.LINKEDIN:
        this.socialSharing.shareVia('linkedin', objectForSharing?.title, null, imageUrl, url);
        break;
      case SocialSharingApp.TWITTER:
        this.socialSharing.shareViaTwitter(url, imageUrl, objectForSharing?.title);
        break;
      case SocialSharingApp.WHATSAPP:
        // the plugin as on 2022.06 accepts only imageURL OR (text + link).
        // decided that link is more important then image
        this.socialSharing.shareViaWhatsApp(objectForSharing?.title, null, url);
        break;
      case SocialSharingApp.OS_SHARING_OPTIONS:
        this.socialSharing.shareWithOptions({
          message: objectForSharing?.title,
          subject: objectForSharing?.title,
          url: url
        });
        break;
    }
  }

  getShareObjectImage(objectForSharing: ObjectForSharing): string {
    return objectForSharing?.image || objectForSharing?.defaultImage;
  }

  private canShareWithApp(appName: SocialSharingApp): Promise<boolean> {

    let promise = Promise.resolve(false);

    switch (appName) {
      case SocialSharingApp.EMAIL:
        promise = this.socialSharing.canShareViaEmail();
        break;
      case SocialSharingApp.FACEBOOK:
      case SocialSharingApp.TWITTER:
      case SocialSharingApp.LINKEDIN:
        const appPackageName = PACKAGE_ID[appConfig.platform][appName];
        promise = this.socialSharing.canShareVia(appPackageName);
        break;
    }
    return promise.then(() => {
      return true;
    }).catch(err => {
      console.log(err);
      return false;
    });
  }

}
