import { InjectionToken } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ActionOnInit } from 'src/app/components/case-image-upload-edit/case-image-upload-edit.model';
import { SelectedRecipients } from 'src/app/dialogs/share-recipients-dialog/share-recipients-dialog.model';
import { TutorialDialogConfig } from 'src/app/dialogs/tutorial-dialog/tutorial-dialog.component';
import { SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { ObjectForSharing, ShareToObject } from '../sharing/sharing.model';
import { AOVideo, Article, Video } from '../yeti-protocol/article';
import { GroupListItem } from '../yeti-protocol/chatter-api';
import { ClinicalCase } from '../yeti-protocol/clinical-case';
import { PublicProfile } from '../yeti-protocol/public-profile';
import { ArticleSearch, VideoSearch } from '../yeti-protocol/search';
import { EditCaseImagesDialogRes, ModalControllerData } from './ui-utils.model';
import { AOEvent } from '../yeti-protocol/event';
import { Countries, Country } from 'src/app/services/yeti-protocol/countries';
import {
  StageOfTreatmentId,
  PersonalMediaGalleryDocumentWithStageOfTreatment
} from 'src/app/components/stage-of-treatment/stage-of-treatment.model';
import { ActionSource } from '../yeti-protocol/tracking';
import {SelectedContacts} from '../../dialogs/share-recipients-people/share-recipients-people.model';

export interface GroupedImagesByStageOfTreatment {
  title: string;
  id: StageOfTreatmentId;
  items: Array<PersonalMediaGalleryDocumentWithStageOfTreatment>;
}

export interface UIUtilsServiceInterface {
  readonly isAOApplication: boolean;
  stopEventPropagation(event: Event): void;
  mapSecondsToTimeAgo(seconds: number): Promise<string>;
  mapSecondsToTimeAgoMessenger(seconds: number, date: string): Promise<string>;
  getTimeAgoFromDateInSeconds(date: string): number;
  createAuxiliaryRouteDialog(modalController: ModalController, modalControllerData: ModalControllerData): Promise<HTMLIonModalElement>;
  isImageFile(selectedFile: SelectedFile): boolean;
  isVideoArticle(article: Article | Video | AOVideo | ArticleSearch | VideoSearch): boolean
  onBecomeAOMember(aoMembershipUrl: string): void;
  showBecomeAoMemberDialog(): Promise<boolean>;
  isConnectButtonForPublicProfileVisible(publicProfile: PublicProfile): boolean;
  getNavigationValueBasedOnPlatform(url: string): any;
  checkAndPromptCanAccessAoMembersOnlyGroup(groupAOMembersOnly: boolean,
    isUserAOMember: boolean, aoMembershipUrl: string): Promise<boolean>;
  showErrorDialogOnCreatePostInAoOnlyGroup(): Promise<boolean>;
  showEditCaseImagesDialog(
    clinicalCase: ClinicalCase,
    triggerActionOnInit?: ActionOnInit,
    stageOfTreatment?: StageOfTreatmentId): Promise<EditCaseImagesDialogRes>;
  signOut(): Promise<void>;
  showTutorialDialog(tutorialDialogConfig: TutorialDialogConfig): Promise<boolean>;
  disableGroupsSelection(objectForSharing: ObjectForSharing, group: GroupListItem): boolean;
  disableEventsSelection(objectForSharing: ObjectForSharing, event: AOEvent): boolean;
  showShareRecipientsDialog(
    selectedMemberGroups: Array<ShareToObject>,
    selectedEvents: Array<ShareToObject>,
    objectForSharing?: ObjectForSharing
  ): Promise<SelectedRecipients>;
  showShareRecipientsContactsDialog(selectedContacts: Array<ShareToObject>): Promise<SelectedContacts>;
  get appClickEventObservable(): Observable<MouseEvent>;
  emitAppClickEvent(event: MouseEvent): void;
  getEventLocation(event: AOEvent, countries: Countries): string;
  getCountryByISO2(iso2: string, countries: Countries): Country;
  showUnpinPostWarning(): Promise<boolean>;
  showCaseMediaReorderDialog(groupedImages: Array<GroupedImagesByStageOfTreatment>,
    scope: string,
    watermarked?: boolean): Promise<Array<GroupedImagesByStageOfTreatment>>;
  showFeatureCasePrompt(): Promise<boolean>;
  showUnfeatureCasePrompt(): Promise<boolean>;
  showCaseFeatureSuccessToast(): void;
  showCaseUnfeatureSuccessToast(): void;
  showPendingStateExplainerDialog(postId?: string, postType?: string, source?: ActionSource): Promise<boolean>;
  isTargetHavingAncestorWithOpenVideoPreviewClass(event: Event): boolean;
  isMobileBrowser(): boolean;
}

export const UI_UTILS_SERVICE = new InjectionToken<UIUtilsServiceInterface>('ui-utils.service');
