import { CaseAccessRequest, GetPendingCasesRequestsSuccessResponse } from '../../services/yeti-protocol/clinical-case';

export namespace ClinicalCasesAccessRequests {
    export interface FetchClinicalCasesAccessRequestsPayload {
        pageIndex?: number;
        pageSize?: number;
    }

    export class FetchClinicalCasesAccessRequests {
        static readonly type = '[Clinical Cases Access Requests] Fetch clinical cases access requests';
        constructor(public payloadParams: FetchClinicalCasesAccessRequestsPayload) { }
    }

    export class FetchClinicalCasesAccessRequestsSuccess {
        static readonly type = '[Clinical Cases Access Requests] Fetch clinical cases access requests success';
        constructor(
            public payloadParams: FetchClinicalCasesAccessRequestsPayload,
            public response: GetPendingCasesRequestsSuccessResponse
        ) { }
    }

    export class FetchClinicalCasesAccessRequestsFailed {
        static readonly type = '[Clinical Cases Access Requests] Fetch clinical cases access requests failed';
    }

    export class InsertClinicalCaseAccessRequestBeforeIndex {
        static readonly type = '[Clinical Cases Access Requests] Insert clinical case access request before index';
        constructor(
            public clinicalCaseAccessRequest: CaseAccessRequest,
            public index: number
        ) { }
    }

    export class RemoveClinicalCaseAccessRequest {
        static readonly type = '[Clinical Cases Access Requests] Remove clinical case access request at index';
        constructor(
            public index: number
        ) { }
    }

    export class UpdateClinicalCaseAccessRequest {
        static readonly type = '[Clinical Cases Access Requests] Update clinical case access request';
        constructor(
            public clinicalCaseAccessRequest: CaseAccessRequest,
        ) { }
    }
}