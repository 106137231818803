// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clinical-expert-container {
  cursor: pointer;
}
.clinical-expert-container .clinical-expert-img {
  --border-radius: 50%;
  --user-image-size: 51px;
  margin: 8px 16px 8px 0;
}
.clinical-expert-container .clinical-expert-title {
  color: var(--mi-secondary-color-2);
  font-family: Suisse;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
}
.clinical-expert-container .clinical-expert-country {
  color: var(--mi-grey-color-11);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
