// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
  display: flex;
  flex-direction: row;
  line-height: 30px;
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
}
.info .dot {
  display: inline-block;
  margin: 0 4px;
  font-weight: bold;
}
.info app-user-profile-image {
  margin-right: 10px;
}

:host {
  display: block;
  padding: 16px 16px 15px;
}
:host.chat-initiator .info {
  flex-direction: row-reverse;
}
:host.chat-initiator .info app-user-profile-image {
  margin-right: 0;
  margin-left: 10px;
}
:host.chat-initiator .info .time {
  order: 2;
}
:host.chat-initiator .info .dot {
  order: 3;
}
:host.chat-initiator .info .user {
  order: 4;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
