import { Component, Input } from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-reorder-button',
  templateUrl: './reorder-button.component.html',
  styleUrls: ['./reorder-button.component.scss'],
})
export class ReorderButtonComponent extends BaseButtonComponent<void> {
  @Input() iconOnly: boolean;
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() showBadge: boolean;
}
