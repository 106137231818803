import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// services
import { FruitSaladObjectTypes, FruitSaladService } from 'src/app/services/fruit-salad.service';

@Component({
  selector: 'app-fruit-salad',
  templateUrl: './fruit-salad.component.html',
  styleUrls: ['./fruit-salad.component.scss'],
})
export class FruitSaladComponent implements OnInit, OnDestroy {

  @Input() fruitSaladForObjectType: FruitSaladObjectTypes;

  @ViewChild('tagsInput', { static: false }) tagsInput: ElementRef<HTMLInputElement>;

  tag: string;
  suggestions: Array<string> = [];
  readonly maxCharsCount = 30;

  private tagChanged: Subject<string> = new Subject<string>();
  private tagChangedSubscription: Subscription;
  private tagSuggestionsChangesSubscription: Subscription;

  constructor(
    private fruitSaladService: FruitSaladService,
    private modalController: ModalController,
  ) { }

  ngOnInit(): void {
    this.subscribeToTagTextChanges();
    this.subscribeToTagSuggestionsChanges();
  }

  ngOnDestroy(): void {
    this.tagChangedSubscription?.unsubscribe();
    this.tagSuggestionsChangesSubscription?.unsubscribe();
  }

  subscribeToTagTextChanges(): void {
    this.tagChangedSubscription = this.tagChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => {
      if (this.tag) {
        this.fruitSaladService.triggerTagTextChange(this.fruitSaladForObjectType, this.tag);
      } else {
        this.suggestions = [];
      }
    });
  }

  subscribeToTagSuggestionsChanges(): void {
    this.tagSuggestionsChangesSubscription = this.fruitSaladService.getTagtagSuggestionsStream().subscribe((suggestions: Array<string>) => {
      this.suggestions = suggestions;

      setTimeout(() => {
        this.focusInputField();
      }, 300); // need to wait loading dialog to close than to focus the field
    });
  }

  tagTextChanged(): void {

    if (this.tag && this.maxCharsCount > 0 && this.tag?.length > this.maxCharsCount) {
      this.tag = this.tag.substring(0, this.maxCharsCount);
      this.tagsInput.nativeElement.value = this.tag;
    }

    this.tagChanged.next(this.tag);
  }

  returnTagAndClose(tag?: string): void {
    if (tag) {
      this.modalController.dismiss(tag);
    } else {
      this.modalController.dismiss(this.tag);
    }
  }

  focusInputField(): void {
    this.tagsInput.nativeElement.focus();
  }

}
