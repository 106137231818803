import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AoSearchService } from '../../services/ao-search-service/ao-search.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import { GroupsService } from '../../services/groups/groups.service';
import { AOSearchResultsObject, AOSearchUser } from '../../services/yeti-protocol/ao-search';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { AppTranslationService } from '../../services/app-translation.service';
import { DomUtilsService } from 'src/app/services/utils/dom-utils.service';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';

const PAGE_SIZE = 100;

@Component({
  selector: 'app-group-invitations-ao-search',
  templateUrl: './group-invitations-ao-search.component.html',
  styleUrls: ['./group-invitations-ao-search.component.scss'],
})
export class GroupInvitationsAoSearchComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Input() groupId: string;
  searchTerm: string;
  foundUsers: Array<AOSearchUser> = [];
  showSearchButton: boolean;
  title: string;
  canLoadMore = true;

  constructor(
    private aoSearchService: AoSearchService,
    private groupsService: GroupsService,
    private toast: ToastService,
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    private appTranslationService: AppTranslationService,
    private domUtils: DomUtilsService
  ) { }

  ngOnInit(): void {
    this.appTranslationService.get('app.groups.GroupsInvitations.AOSearchPage.invite-from-ao-network').then(title => {
      this.title = title;
    });
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  get isSearchButtonVisible(): boolean {
    return (this.showSearchButton && this.searchTerm && this.searchTerm.length > 2);
  }

  get hasFoundUsers(): boolean {
    let showList = false;
    if (this.foundUsers.length > 0) {
      showList = !showList;
    }
    return showList;
  }

  searchAOMember(start = 0): void {
    if (start === 0) {
      this.foundUsers = [];
      this.canLoadMore = true;
      this.disableInfiniteScroll(false);
    }
    this.showSearchButton = false;
    this._searchAOMembers(this.searchTerm, start).then(result => {
      if (!result) {
        this.appTranslationService.get('app.groups.GroupsInvitations.AOSearchPage.no-results-found')
          .then(message => {
            this.dialogs.showErrorDialog(message);
          });
      } else {
        this.foundUsers = this.foundUsers.concat(result.records);
        this.appTranslationService.get('app.groups.GroupsInvitations.AOSearchPage.results-found')
          .then(title => {
            this.title = `${result.totalCount} ${title}`;
          });
        this.infiniteScroll.complete();
        if (result.records.length < PAGE_SIZE) {
          this.disableInfiniteScroll(true);
          this.canLoadMore = false;
          return;
        }
      }
    })
  }

  _searchAOMembers(searchTerm: string, start: number = 0, count = PAGE_SIZE): Promise<AOSearchResultsObject> {
    return this.aoSearchService.searchAONetwork(searchTerm, start, count)
      .then(response => {
        if (response) {
          const temp: Array<AOSearchResultsObject> = response.filter(r => r.resultName === 'ao-contact-search');
          return temp[0];
        }
        return null;
      });
  }

  loadMoreUpdates(): void {
    const start = Math.floor(this.foundUsers.length / PAGE_SIZE);
    this.searchAOMember(start);
  }

  inviteAoMember(user: AOSearchUser): void {
    this.groupsService.inviteToGroup(this.groupId, user.email, user.id, true).then(async (response) => {
      if (response && response.success) {
        (this.domUtils.getElementById(user.id) as HTMLButtonElement).disabled = true;
        this.toast.show(
          'app.groups.GroupsInvitations.StartPage.member-has-been-invited',
          'app.common.success',
          ToastMode.SUCCESS
        );
      } else {
        const myRegexp = /Exception:.(.*?)"/g;
        const match = myRegexp.exec(response.error.info);
        const errorMessage = match[1];
        this.dialogs.showErrorDialog(errorMessage);
      }
    })
      .catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.dialogs.showErrorDialog(err?.error?.error?.message?.errfor?.message);
        }
      })
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }
}
