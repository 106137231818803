import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: unknown, ..._args: unknown[]): unknown {
    const parts = (value as string).split(' ');
    const resParts = parts.map(part => {
      if(part.length > 0){
        return part[0].toUpperCase() + part.substr(1).toLocaleLowerCase();
      }
      return part;
    })
    return resParts.join(' ');
  }

}
