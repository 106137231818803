import { NotificationsSuccessResponse, Notification } from 'src/app/services/yeti-protocol/notifications';

export namespace Activities {
  export interface FetchActivitiesPayload {
    pageIndex?: number;
    pageSize?: number;
  }

  export interface MarkActivitiesAsReadPayload {
    ids: Array<string>,
    pageIndex: number,
    pageSize: number,
    delete?: boolean
  }

  export class FetchActivities {
    static readonly type = '[Activities] Fetch activities';
    constructor(public payloadParams: FetchActivitiesPayload) { }
  }

  export class FetchActivitiesSuccess {
    static readonly type = '[Activities] Fetch activities success';
    constructor(
      public payloadParams: FetchActivitiesPayload,
      public response: NotificationsSuccessResponse
    ) { }
  }

  export class FetchActivitiesFailed {
    static readonly type = '[Activities] Fetch activities failed';
  }

  export class InsertActivityBeforeIndex {
    static readonly type = '[Activities] Insert activity before index';
    constructor(
      public activity: Notification,
      public index: number
    ) { }
  }

  export class RemoveActivity {
    static readonly type = '[Activities] Remove activity at index';
    constructor(
      public activity: Notification,
    ) { }
  }

  export class RemoveActivities {
    static readonly type = '[Activities] Remove activities';
    constructor(
      public ids: Array<string>,
    ) { }
  }

  export class UpdateActivity {
    static readonly type = '[Activities] Update activity';
    constructor(
      public activity: Notification
    ) { }
  }

  export class MarkActivitiesAsRead {
    static readonly type = '[Activities] Mark activities as read';
    constructor(
      public payloadParams: MarkActivitiesAsReadPayload
    ) { }
  }

  export class MarkActivitiesAsReadSuccess {
    static readonly type = '[Activities] Mark activities as read success';
    constructor(
      public payloadParams: MarkActivitiesAsReadPayload,
      public response: NotificationsSuccessResponse
    ) { }
  }

  export class MarkActivitiesAsReadFailed {
    static readonly type = '[Activities] Mark activities as read failed';
  }
}
