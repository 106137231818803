import { IonNav } from '@ionic/angular';
import { AuthUIServiceInterface } from 'src/app/services/auth/auth-ui.service.interface';

export abstract class BaseAuthPage{
  constructor(
    protected nav: IonNav,
    protected authUIService: AuthUIServiceInterface,
  ) {
    this.authUIService.nav = this.nav;
  }
}
