import { BehaviorSubject } from 'rxjs';

// models
import { Group, GroupVisibility, GroupUserScopes, GroupUserPermissions } from '../../services/groups/group.model';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';

export class GroupsUIService {
  groupUserPermissions: BehaviorSubject<GroupUserPermissions>;
  private group: Group;
  private permissions: GroupUserPermissions;

  constructor(
    group?: Group
  ) {
    this.permissions = {
      settings: false,
      invite: false,
      share: false,
      seeParticipantsList: false,
      seeDetails: false,
      editGroup: false,
      deleteGroup: false,
      reportGroup: false,
      leaveGroup: false,
      recommend: false
    };

    this.groupUserPermissions = new BehaviorSubject<GroupUserPermissions>(this.permissions);

    if (group) {
      this.setGroup(group);
    }
  }

  setGroup(group: Group): void {
    this.group = group;
    this.calculateAndEmitGroupUserPermissions();
  }

  getGroup(): Group {
    return this.group ? { ...this.group } : null; // prevents user from directly modifying the group object
  }

  getGroupPermissions(): GroupUserPermissions {
    return this.permissions ? { ...this.permissions } : null; // prevents user from directly modifying the group permissions object
  }


  showGroupPublicView(): boolean {

    if (!this.group) {
      return false;
    }

    if (this.isPublicGroup && this.group.status !== GroupUserScopes.NONE
      && this.group.status !== GroupUserScopes.PENDING_INVITATION) {
      return false;
    }

    if (this.isUnlistedGroup && this.group.status === GroupUserScopes.PENDING_REQUEST) {
      return false;
    }

    const publicOrPrivateGroupNotAParticipant: boolean = this.group?.status === GroupUserScopes.NONE
      && (this.isPublicGroup || this.isPrivateGroup);
    const pendingParticipant: boolean = this.group?.status === GroupUserScopes.PENDING_INVITATION
      || this.group?.status === GroupUserScopes.PENDING_REQUEST;
    return publicOrPrivateGroupNotAParticipant || pendingParticipant;
  }

  showGroupVerificationPending(): boolean {
    return this.group ? this.group?.status === GroupUserScopes.PENDING_VERIFICATION_INVITATION
      || this.group?.status === GroupUserScopes.PENDING_VERIFICATION_REQUEST : false;
  }

  showUnlistedGroup(): boolean {
    return this.group ? this.group?.status === GroupUserScopes.NONE && this.isUnlistedGroup : false;
  }

  showJoinButton(): boolean {
    return this.group ? this.group.status === GroupUserScopes.NONE && this.isPublicGroup : false;
  }

  showRequestToJoinButton(): boolean {
    return this.group ? this.group.status === GroupUserScopes.NONE && this.isPrivateGroup : false;
  }

  showRequestToJoinSentButton(): boolean {
    return this.group ? this.isPrivateGroup && (
      this.group.status === GroupUserScopes.PENDING_REQUEST ||
      this.group.status === GroupUserScopes.PENDING_VERIFICATION_REQUEST) : false;
  }

  showAcceptDeclineInvitationButtons(): boolean {
    return this.group ? this.group.status === GroupUserScopes.PENDING_INVITATION : false;
  }

  showGroupPendingRequestsNotification(): boolean {
    return this.group ? this.group.status === GroupUserScopes.OWNER : false;
  }

  isUserGroupOwner(user: UserProfile, group: Group): boolean {

    if (!group?.owner?.userId || !user?.id) {
      return false;
    }

    return group && user?.id === group?.owner?.userId;
  }

  isUserGroupModerator(user: UserProfile, group: Group): boolean {

    const userIndexInModeratorsArray = group?.moderators.findIndex(moderator => moderator?.userId === user?.id);

    return group && userIndexInModeratorsArray > -1;
  }

  private calculateAndEmitGroupUserPermissions(): void {

    this.permissions.settings = this.calculateSettingsPermissions();
    this.permissions.invite = this.calculateInvitePermissions();
    this.permissions.share = this.isShareableGroup;
    this.permissions.seeParticipantsList = this.isAccessibleGroup;
    this.permissions.editGroup = this.calculateEditGroupPermissions();
    this.permissions.deleteGroup = this.calculateDeleteGroupPermissions();
    this.permissions.reportGroup = this.calculateReportGroupPermissions();
    this.permissions.leaveGroup = this.calculateLeaveGroupPermissions();
    this.permissions.recommend = this.calculateRecommendGroupPermissions();
    this.permissions.seeDetails = this.calculateSeeDetailsGroupPermissions();

    this.groupUserPermissions.next(this.permissions);
  }

  private calculateSettingsPermissions(): boolean {
    // members, moderators and owners can see settings button
    return this.isAccessibleGroup;
  }

  private calculateInvitePermissions(): boolean {

    if (this.group) {
      if (this.isPublicGroup && this.isAccessibleGroup) {
        // if public group, members, moderators and owners can invite
        return true;
      } else if (
        this.group.status === GroupUserScopes.OWNER ||
        this.group.status === GroupUserScopes.MODERATOR) { // if user is owner or moderator, it can invite
        return true;
      } else {
        return false;
      }

    } else {
      return false;
    }
  }

  private get isShareableGroup(): boolean {
    // public and RTJ groups are shareable
    return (this.isPublicGroup || this.isPrivateGroup) && this.isAccessibleGroup;
  }

  private get isUnlistedGroup(): boolean {
    return this.group && this.group.visibility === GroupVisibility.UNLISTED;
  }

  private get isPublicGroup(): boolean {
    return this.group && this.group.visibility === GroupVisibility.PUBLIC;
  }

  private get isPrivateGroup(): boolean {
    return this.group && this.group.visibility === GroupVisibility.PRIVATE;
  }
  private get isAccessibleGroup(): boolean {
    return this.group && (
      this.group.status === GroupUserScopes.OWNER ||
      this.group.status === GroupUserScopes.MEMBER ||
      this.group.status === GroupUserScopes.MODERATOR);
  }

  private calculateEditGroupPermissions(): boolean { // owners and moderators can edit group
    return this.group ? (this.group.status === GroupUserScopes.OWNER || this.group.status === GroupUserScopes.MODERATOR) : false;
  }

  private calculateDeleteGroupPermissions(): boolean {
    return this.group ? this.group.status === GroupUserScopes.OWNER : false; // only owners can delete group
  }

  private calculateReportGroupPermissions(): boolean {
    return this.group ?
      this.group.status === GroupUserScopes.MEMBER ||
      this.group.status === GroupUserScopes.MODERATOR : false; // only members and moderators can report group
  }

  private calculateLeaveGroupPermissions(): boolean { // moderators or members can leave group
    return this.group ? (this.group.status === GroupUserScopes.MODERATOR || this.group.status === GroupUserScopes.MEMBER) : false;
  }

  private calculateRecommendGroupPermissions(): boolean { // owners, moderators or members can recommend only public or RTJ groups
    return this.isAccessibleGroup && (this.isPublicGroup || this.isPrivateGroup);
  }

  private calculateSeeDetailsGroupPermissions(): boolean {

    if (this.group) {
      if (this.group.status === GroupUserScopes.PENDING_VERIFICATION_REQUEST
        || this.group.status === GroupUserScopes.PENDING_VERIFICATION_INVITATION) {
        return false;
      } else if (this.isUnlistedGroup && this.group.status === GroupUserScopes.NONE) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
