// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tutorial-bubble-container {
  max-width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 901;
}
.tutorial-bubble-container .content-container {
  width: 100%;
  height: 100%;
  padding: 16px 13px;
  border-radius: 10px;
  background-color: var(--mi-white-color);
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  justify-items: left;
  align-items: center;
  background-color: var(--mi-white-color);
}
.tutorial-bubble-container .content-container:after, .tutorial-bubble-container .content-container:before {
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  display: none;
}
.tutorial-bubble-container .content-container:before {
  bottom: 100%;
  left: 50%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 17px solid var(--mi-white-color);
}
.tutorial-bubble-container .content-container:after {
  top: 100%;
  left: 50%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 17px solid var(--mi-white-color);
}
.tutorial-bubble-container .content-container.bellow::before {
  display: block;
}
.tutorial-bubble-container .content-container.above::after {
  display: block;
}
.tutorial-bubble-container .content-container.arrow-left-aligned:after, .tutorial-bubble-container .content-container.arrow-left-aligned:before {
  left: 13px;
}
.tutorial-bubble-container .content-container.arrow-right-aligned:after, .tutorial-bubble-container .content-container.arrow-right-aligned:before {
  left: calc(100% - 33px);
}
.tutorial-bubble-container .content-container.arrow-custom-align:after, .tutorial-bubble-container .content-container.arrow-custom-align:before {
  left: var(--arrow-left-position) !important;
}
.tutorial-bubble-container .content-container .title-container {
  width: 100%;
  padding-bottom: 11px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  align-items: center;
}
.tutorial-bubble-container .content-container .title-container .title-wrapper {
  grid-row: 1;
  grid-column: 1;
  position: relative;
  height: 18px;
}
.tutorial-bubble-container .content-container .title-container .title-wrapper .container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 18px;
}
.tutorial-bubble-container .content-container .title-container .title-wrapper .title {
  margin: 0;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-color-text-lite);
  text-align: left;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tutorial-bubble-container .content-container .title-container .steps-count {
  grid-row: 1;
  grid-column: 2;
  padding-left: 10px;
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  color: var(--mi-primary-color);
  text-align: right;
  white-space: nowrap;
}
.tutorial-bubble-container .content-container .text-container {
  padding-bottom: 17px;
}
.tutorial-bubble-container .content-container .text-container .text {
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  text-align: left;
}
.tutorial-bubble-container .content-container .buttons-container {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  justify-items: left;
  align-items: center;
  -moz-column-gap: 30px;
       column-gap: 30px;
}
.tutorial-bubble-container .content-container .buttons-container .cancel-button-container button {
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
}
.tutorial-bubble-container .content-container .buttons-container .cancel-button-container button span {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  text-align: center;
  white-space: nowrap;
}

.tutorial-bubble-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: var(--mi-black-color);
  opacity: 0.44;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
