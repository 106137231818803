// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-mi-card {
  --mi-card-padding-top: 16px;
  --mi-card-padding-start: 10px;
  --mi-card-padding-end: 14px;
  --mi-action-bar-padding-start: 18px;
  --mi-action-bar-padding-end: 30px;
  cursor: pointer;
}
app-mi-card ion-card-header .admin-action-list {
  display: flex;
}
app-mi-card ion-card-header .admin-action-list .radio-group-article,
app-mi-card ion-card-header .admin-action-list .check-box-article {
  margin: 10px;
}
app-mi-card ion-card-header .admin-action-list .radio-group-article ion-radio,
app-mi-card ion-card-header .admin-action-list .check-box-article ion-radio {
  margin: 5px;
}
app-mi-card ion-card-header .admin-action-list .radio-group-article .radio-danger,
app-mi-card ion-card-header .admin-action-list .check-box-article .radio-danger {
  --color: var(--mi-danger);
}
app-mi-card ion-card-header .admin-action-list .radio-group-article .radio-warning,
app-mi-card ion-card-header .admin-action-list .check-box-article .radio-warning {
  --color: var(--mi-orange-color);
}
app-mi-card ion-card-header .admin-action-list .radio-group-article .radio-success,
app-mi-card ion-card-header .admin-action-list .check-box-article .radio-success {
  --color: var(--mi-success);
}
app-mi-card ion-card-header .admin-action-list .channel-chip {
  margin-top: 8px;
}
app-mi-card ion-card-header .admin-action-list .space {
  flex-grow: 1;
}
app-mi-card ion-card {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-template-columns: 100%;
}
app-mi-card ion-card-content {
  padding: 0;
}
app-mi-card ion-card-content .title {
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  color: var(--mi-secondary-color-2);
  margin: 0;
}
app-mi-card ion-card-content .recommendations-container {
  margin-bottom: 15px;
}
app-mi-card ion-card-content app-chips {
  --padding-bottom: 10px;
}
app-mi-card ion-card-content app-chips app-text-chip {
  --color: var(--mi-secondary-color-2);
  --background-color: var(--mi-blue-color-4);
  --font-size: var(--font-size-tiny);
}
app-mi-card ion-card-content.article-content-container {
  padding: var(--mi-card-padding-top) var(--mi-card-padding-end) 0 var(--mi-card-padding-start);
}
app-mi-card ion-card-content.article-content-container .title-container {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  display: grid;
  grid-column-gap: 10px;
  justify-content: space-between;
}
app-mi-card ion-card-content.article-content-container .title-container .with-placeholder {
  grid-row: 1;
  grid-column: 1;
}
app-mi-card ion-card-content.article-content-container .title-container .article-image-container {
  grid-row: 1;
  grid-column: 2;
}
app-mi-card ion-card-content.article-content-container .title-container.with-image {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: flex-start;
}
app-mi-card ion-card-content.article-content-container .title-container.with-image .title {
  grid-row: 1;
  grid-column: 1;
}
app-mi-card ion-card-content.article-content-container .title-container.with-image .article-image-container {
  grid-row: 1;
  grid-column: 2;
  width: 55px;
  height: 55px;
}
app-mi-card ion-card-content.article-content-container .title-container.with-image .article-image-container app-article-image {
  --article-image-size: 55px;
  --article-image-border-radius: 5px;
}
app-mi-card ion-card-content.article-content-container .authors-container {
  padding-bottom: 14px;
}
app-mi-card ion-card-content.article-content-container .authors-container .authors {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  text-align: left;
  color: var(--mi-grey-color);
  margin: 0;
}
app-mi-card ion-card-content.article-content-container .journal-and-pubdate-container {
  padding-bottom: 14px;
}
app-mi-card ion-card-content.article-content-container .journal-and-pubdate-container .journal-and-pubdate {
  font-size: var(--font-size-tiny);
  line-height: var(--font-line-height-tiny);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: left;
  color: var(--mi-color-text-lite);
  margin: 0;
}
app-mi-card ion-card-content.video-content-container .image-container {
  height: 170px;
}
app-mi-card ion-card-content.video-content-container .image-container app-video-image {
  --video-image-size: 100%;
}
app-mi-card ion-card-content.video-content-container .video-info-container {
  padding: 0 var(--mi-card-padding-end) 0 var(--mi-card-padding-start);
}
app-mi-card ion-card-content.video-content-container .video-info-container .title-container {
  width: 100%;
  height: 100%;
  padding: 10px 0px;
}
app-mi-card ion-card-content.video-content-container .video-info-container .date-container {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}
app-mi-card ion-card-content.video-content-container .video-info-container .date-container .date {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  text-align: left;
  color: var(--mi-color-text-lite);
  margin: 0;
}
app-mi-card app-mi-card-actions {
  --mi-card-actions-padding-top: 0;
  --mi-card-actions-padding-bottom: 0;
  --mi-card-actions-padding-start: var(--mi-action-bar-padding-start);
  --mi-card-actions-padding-end: var(--mi-action-bar-padding-end);
  width: 100%;
  height: 100%;
}
app-mi-card app-mi-card-actions app-recommend-button ::ng-deep button .recommend-button-container .text {
  color: var(--mi-black-color);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
}
app-mi-card app-mi-card-actions app-share-button ::ng-deep button .share-button-container app-icon {
  --icon-size: 18px;
}
app-mi-card app-mi-card-actions app-share-button ::ng-deep button .share-button-container .text {
  color: var(--mi-black-color);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  margin-top: 2px;
}
app-mi-card app-mi-card-actions app-save-button ::ng-deep .save-button-container button .button-wrapper-container app-icon {
  --icon-size: 17px !important;
}
app-mi-card app-mi-card-actions app-save-button ::ng-deep .save-button-container button .button-wrapper-container app-icon.saved {
  --icon-size: 18px !important;
}
app-mi-card app-mi-card-actions app-save-button ::ng-deep .save-button-container button .button-wrapper-container .text {
  color: var(--mi-black-color) !important;
  font-size: var(--font-size-tiny) !important;
  font-weight: var(--font-weight-regular) !important;
}
app-mi-card app-mi-card-actions ::ng-deep .mi-card-actions-container .custom-button-actions app-settings-button ::ng-deep .settings-button-container button .settings-button-wrap-vertical app-icon {
  --icon-size: 17px !important;
}
app-mi-card app-mi-card-actions ::ng-deep .mi-card-actions-container .custom-button-actions app-settings-button ::ng-deep .settings-button-container button .settings-button-wrap-vertical .btn-text {
  color: var(--mi-black-color) !important;
  font-size: var(--font-size-tiny) !important;
  font-weight: var(--font-weight-regular) !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
