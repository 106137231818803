import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDateOlderThanToday'
})
export class IsDateOlderThanTodayPipe implements PipeTransform {

  transform(value: Date | string): unknown {
    const inputDate = new Date(value);
    const today = new Date();

    inputDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return inputDate.getTime() < today.getTime();
  }

}
