// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --post-owner-container-padding-left-right: 15px;
  --post-owner-container-padding-top-bottom: 17px;
}

.post-toggle-action-container {
  display: grid;
  border-radius: 3px;
  background-color: var(--mi-inputs-color);
}
.post-toggle-action-container .toggle-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  padding: var(--post-owner-container-padding-top-bottom) var(--post-owner-container-padding-left-right);
  border-bottom: 1px solid var(--mi-background-color);
}
.post-toggle-action-container .toggle-container .text-content-container {
  display: grid;
  grid-row-gap: 8px;
  color: var(--mi-secondary-color-2);
}
.post-toggle-action-container .toggle-container .text-content-container .title {
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.post-toggle-action-container .toggle-container .text-content-container .text {
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
.post-toggle-action-container .toggle-container .toggle-icon-container {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  justify-self: end;
  align-items: center;
}
.post-toggle-action-container .toggle-container .toggle-icon-container ion-toggle {
  --background-checked: var(--mi-primary-color);
}

app-info-sheet {
  --info-sheet-title-color: var(--mi-secondary-color-1);
}
app-info-sheet app-info-sheet-actions-container ::ng-deep .info-sheet-actions-container {
  font-family: Suisse;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  justify-items: start;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
