import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-case-access-requests-dialog',
  templateUrl: './case-access-requests-dialog.component.html',
  styleUrls: ['./case-access-requests-dialog.component.scss'],
})
export class CaseAccessRequestsDialogComponent {

  constructor(
    private modalController: ModalController
  ) { }

  onClose(): void {
    this.modalController.dismiss();
  }

  onProfileImageClick(userId: string): void {
    this.modalController.dismiss({userId}, 'command');
  }
}
