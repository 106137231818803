import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import appConfig from 'src/config/config';

import { AuthService } from './auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';
import { TranslatorData, TranslatorObject, TranslatorRecord } from './translator.model';

export interface TranslatorServiceConfig {
  drywallServer: string;
  environment: string
}

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  config: TranslatorServiceConfig = appConfig;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService
  ) { }

  getItemHash(item: TranslatorObject): string {
    return this.buildHash(item.origin, item.title, item.text);
  }

  buildHash(origin: string, title: string, text: string): string {
    return [origin || '', (title || '').length, (text || '').length].join('-');
  }

  translateBatch(translator: string, toLanguage: string, dataToTranslateArray: Array<TranslatorObject>): Promise<TranslatorData> {

    const records: Array<TranslatorRecord> = _.map(dataToTranslateArray, (item: TranslatorObject) => {
      return {
        id: this.getItemHash(item),
        title: item.title,
        text: item.text
      };
    });

    const data: TranslatorData = {
      records,
      to: toLanguage,
      translator
    };


    const contextKey = this.contextService.currentContext.key;
    const serverUrl = `${this.config.drywallServer}insights-${contextKey}-${this.config.environment}/translate`
    return firstValueFrom(this.authService.securePost<TranslatorData, TranslatorData>(serverUrl, data));
  }
}
