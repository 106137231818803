import { Directive, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import { SwiperVisibilityTrackerService } from './swiper-visibility-tracker.service';

@Directive({
  selector: '[app-swiper-visibility-tracker-root]',
  exportAs: 'swiperVisibilityTracker'
})
export class SwiperVisibilityTrackerRootDirective implements OnInit, OnDestroy {

  @ViewChild(SwiperComponent, { static: false }) swiperComponent: SwiperComponent;

  @Input() visibilityRootKey: string;
  @Input() visibilityTrackerRegistrationDelay = 100;

  constructor(
    private ref: ElementRef,
    private swiperVisibilityTrackerService: SwiperVisibilityTrackerService
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      if (this.visibilityRootKey) {
        this.swiperVisibilityTrackerService.registerRoot(this.visibilityRootKey, this.ref?.nativeElement?.swiper);
      }
    }, this.visibilityTrackerRegistrationDelay);
  }

  ngOnDestroy(): void {
    if (this.visibilityRootKey) {
      this.swiperVisibilityTrackerService.unregisterRoot(this.visibilityRootKey);
    }
  }
}
