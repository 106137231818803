// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search {
  --background: var(--mi-primary-color);
  text-transform: none;
}

ion-input {
  z-index: 1;
}

.input-container {
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  padding-left: 30px;
  padding-right: 15px;
  border-bottom: solid 1px var(--mi-grey-color-17);
  padding-bottom: 5px;
}
.input-container .to-label {
  grid-row: 1;
  grid-column: 1;
  padding: 0;
  color: var(--mi-secondary-color-2);
}
.input-container .input {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: 2.5px;
       column-gap: 2.5px;
  background: var(--mi-inputs-color);
}
.input-container .input ion-input {
  grid-row: 1;
  grid-column: 1;
  font-size: var(--font-weight-regular);
}
.input-container .input .clear-input {
  grid-row: 1;
  grid-column: 2;
  width: 50px;
}
.input-container .search {
  grid-row: 1;
  grid-column: 3;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
