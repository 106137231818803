import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DocumentAttachment } from '../../services/attachments.model';

@Component({
  selector: 'app-mobile-pdf-view',
  templateUrl: './mobile-pdf-view.component.html',
  styleUrls: ['./mobile-pdf-view.component.scss'],
})
export class MobilePdfViewComponent {

  @Input() documentInfo: DocumentAttachment;

  currentZoom = 1;

  constructor(
    private modalController: ModalController
  ) { }

  async onBack(): Promise<void> {

    const modalShowing = await this.modalController.getTop();

    if (modalShowing) {
      this.modalController.dismiss();
    }

  }

  zoomIn(): void {
    this.currentZoom += 0.5;
  }

  zoomOut(): void {

    if (this.currentZoom === 1) {
      return;
    }

    this.currentZoom -= 0.5;
  }

}
