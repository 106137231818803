// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --user-info-padding-top: 0px;
  --user-info-padding-bottom: 0px;
  --user-info-padding-start: 0px;
  --user-info-padding-end: 0px;
  --user-info-column-gap: 10px;
  --user-info-row-gap: 5px;
  --user-info-user-profile-image-size: 40px;
}

.user-info-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  align-items: center;
  -moz-column-gap: var(--user-info-column-gap);
       column-gap: var(--user-info-column-gap);
  padding-top: var(--user-info-padding-top);
  padding-bottom: var(--user-info-padding-bottom);
  padding-left: var(--user-info-padding-start);
  padding-right: var(--user-info-padding-end);
}
.user-info-container .user-image-container {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-info-container .user-image-container app-user-profile-image {
  --user-image-size: var(--user-info-user-profile-image-size);
}
.user-info-container .user-info-container {
  grid-row: 1;
  grid-column: 2;
  position: relative;
}
.user-info-container .user-info-container .wrapper {
  position: absolute;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  row-gap: var(--user-info-row-gap);
}
.user-info-container .user-info-container .wrapper .user-full-name-container {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  cursor: pointer;
}
.user-info-container .user-info-container .wrapper .user-full-name-container ::ng-deep app-user-full-name {
  width: 100%;
  overflow: hidden;
}
.user-info-container .user-info-container .wrapper .user-country-container {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}
.user-info-container .user-info-container .wrapper .user-country-container .user-country {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: 1;
  color: var(--mi-color-text-lite);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
