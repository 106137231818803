import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';

// models
import {
  OtherActiveSpecialtiesResponse,
  ProfileAnalyticsResponse,
  PublicProfile,
  PublicProfileRequestParams,
  PublicProfileResponse
} from '../services/yeti-protocol/public-profile';
import { SharedGroupsResponse } from './yeti-protocol/chatter-api';
import { SharedEventsResponse } from './yeti-protocol/event';

// services
import { AuthService } from './auth/auth.service';
import { SchemaValidatorService } from './schema-validator.service';
import { toAuthRequestParams } from './auth/logic/auth-logic.utils';
import { ContextService, CONTEXT_SERVICE } from './context/context.model';

import appConfig from 'src/config/config';

export interface PublicProfileServiceConfig {
  serverUrlIonic: string;
  sharedGroupsUrl: string;
  sharedEventsUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class PublicProfileService {
  config: PublicProfileServiceConfig = appConfig;

  constructor(
    private httpClient: HttpClient,
    private schemaValidator: SchemaValidatorService,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  get(userId: string): Promise<PublicProfile> {

    const params: PublicProfileRequestParams = {
      appId: this.appId,
      ids: [userId]
    };

    return new Promise((resolve, reject) => {
      return this.fetchBatchProfiles(params).then((publicProfilesResponse: PublicProfileResponse) => {
        if(publicProfilesResponse.profiles && publicProfilesResponse.profiles[userId]) {
          return resolve(publicProfilesResponse.profiles[userId]);
        } else {
          return reject('No profile found for this user id');
        }
      }).catch(err => {
        return reject(err);
      })
    });
  }

  getBatch(userIds: Array<string>): Promise<any> {
    const params: PublicProfileRequestParams = {
      appId: this.appId,
      ids: userIds
    };

    return new Promise((resolve, reject) => {
      return this.fetchBatchProfiles(params).then((publicProfilesResponse: PublicProfileResponse) => {
        if(publicProfilesResponse.profiles) {
          return resolve(publicProfilesResponse.profiles);
        } else {
          return reject('No profiles found for this user ids');
        }
      }).catch(err => {
        return reject(err);
      })
    });
  }

  // TODO: add type definition
  getPublicProfile(userId: string): Promise<any> {
    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.serverUrlIonic}profile/public/${this.appId}/${userId}`;
      return firstValueFrom(this.authService.secureGet(getRequestUrl));
    });
  }

  getSharedGroups(publicProfileUserId: string, start: number = 0, count: number = 10): Promise<SharedGroupsResponse> {
    return this.authService.asserIsSignedIn().then(() => {

      const getRequestUrl =
      `${this.config.sharedGroupsUrl}/${publicProfileUserId}?appId=${this.appId}&start=${start}&count=${count}`;

      return firstValueFrom(this.authService.secureGet(getRequestUrl).pipe(
        this.schemaValidator.isValidOperator<SharedGroupsResponse>('SharedGroupsResponse')
      ));
    })
  }

  getSharedEvents(publicProfileUserId: string, past: boolean, start: number = 0, count: number = 10): Promise<SharedEventsResponse> {

    return this.authService.asserIsSignedIn().then(() => {

      const getRequestUrl =
        `${this.config.sharedEventsUrl}/${publicProfileUserId}?appId=${this.appId}&past=${past}&start=${start}&count=${count}`;

      return firstValueFrom(this.authService.secureGet(getRequestUrl).pipe(
        this.schemaValidator.isValidOperator<SharedEventsResponse>('SharedEventsResponse')
      ));
    });
  }

  getProfileAnalytics(): Promise<ProfileAnalyticsResponse> {
    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.serverUrlIonic}profile/metrics`;

      return firstValueFrom(this.authService.secureGet(getRequestUrl).pipe(
        this.schemaValidator.isValidOperator<ProfileAnalyticsResponse>('ProfileAnalyticsResponse')
      ));
    });
  }

  getOtherActiveSpecialities(userId: string): Promise<OtherActiveSpecialtiesResponse> {
    return this.authService.asserIsSignedIn().then(() => {
      const getRequestUrl = `${this.config.serverUrlIonic}profile/otherActiveSpecialities/${userId}?appId=${this.appId}`;
      return firstValueFrom(this.authService.secureGet(getRequestUrl));
    });
  }

  private fetchBatchProfiles(params: PublicProfileRequestParams): Promise<PublicProfileResponse> {

    return this.authService.isSignedIn().then(isSignedIn => {
      let getPublicProfilesObservable: Observable<any>;
      const getRequestUrl = `${this.config.serverUrlIonic}profile/public/${this.appId}`;

      if(isSignedIn){
        getPublicProfilesObservable = this.authService.secureGet(getRequestUrl, {params: toAuthRequestParams(params)});
      } else {
        getPublicProfilesObservable = this.httpClient.get(getRequestUrl, params as any);
      }
      return firstValueFrom(getPublicProfilesObservable.pipe(
        this.schemaValidator.isValidOperator<PublicProfileResponse>('PublicProfileResponse')
      ));
    });
  }

  private get appId(): string {
    return this.contextService.currentContext.key;
  }
}
