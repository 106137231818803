import { ContextConfigInterface } from '../config.model';

export class ContextConfig implements ContextConfigInterface {
  key = 'vet';
  label = 'Veterinary';
  homePath = '/vet';
  logoImageName = 'ao-vet.svg';
  iconName = 'spec-vet';
  aoMembershipUrl = 'https://www.aofoundation.org/vet/membership';
  moreEventsLink = 'https://www.aofoundation.org/vet/education/courses-events';
}
