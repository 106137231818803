import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
  ]
})
export class IconComponent {
  @Input() name: string;
  @Input() slot: 'start' | 'end' | 'icon-only';
  @Input() src: string;
  @Input() additionalClasses?: string[];

  get imageSrc(): string {
    if(this.src){
      return this.src;
    }
    if(this.name){
      return `/svg/${this.name}.svg`;
    }
    return '';
  }
}
