// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-info-sheet {
  --info-sheet-padding-top: 20px;
  --info-sheet-padding-bottom: 50px;
  --info-sheet-padding-start: 20px;
  --info-sheet-padding-end: 20px;
  --info-sheet-border-top-left-radius: 12px;
  --info-sheet-border-top-right-radius: 12px;
  --info-sheet-border-bottom-left-radius: 0px;
  --info-sheet-border-bottom-right-radius: 0px;
  --info-sheet-content-max-height: 80%;
  --info-sheet-content-grid-row-gap: 10px;
  --info-sheet-background-transition-time: 0.3s;
  --info-sheet-transition-time: 0.3s;
  --info-sheet-title-color: var(--mi-secondary-color-2);
  overflow: hidden;
}
app-info-sheet .info-sheet-container {
  overflow: hidden;
}
app-info-sheet .info-sheet-container .info-sheet {
  width: 100%;
  max-height: var(--info-sheet-content-max-height);
  background-color: var(--mi-white-color);
  padding: var(--info-sheet-padding-top) 0 var(--info-sheet-padding-bottom) 0;
  border-top-left-radius: var(--info-sheet-border-top-left-radius);
  border-top-right-radius: var(--info-sheet-border-top-right-radius);
  border-bottom-left-radius: var(--info-sheet-border-bottom-left-radius);
  border-bottom-right-radius: var(--info-sheet-border-bottom-right-radius);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  outline: none;
  z-index: 1000;
  overflow: hidden;
  transform: translateY(100%);
  transition: transform var(--info-sheet-transition-time) cubic-bezier(0.17, 0.73, 0.32, 0.95);
  transition-delay: 0.1s;
}
@media all and (min-width: 600px) {
  app-info-sheet .info-sheet-container .info-sheet {
    margin: 0 auto;
    max-width: 430px;
  }
}
app-info-sheet .info-sheet-container .info-sheet .info-sheet-content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 100%;
  row-gap: var(--info-sheet-content-grid-row-gap);
}
app-info-sheet .info-sheet-container .info-sheet .info-sheet-content .header-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  justify-items: start;
  align-items: center;
  padding: 0 var(--info-sheet-padding-end) 0 var(--info-sheet-padding-start);
}
app-info-sheet .info-sheet-container .info-sheet .info-sheet-content .header-container .title-container {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
app-info-sheet .info-sheet-container .info-sheet .info-sheet-content .header-container .title-container .title {
  font-weight: var(--font-weight-semi-bold);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  width: 100%;
  color: var(--info-sheet-title-color);
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
app-info-sheet .info-sheet-container .info-sheet .info-sheet-content .header-container .button-container {
  grid-row: 1;
  grid-column: 2;
}
app-info-sheet .info-sheet-container .info-sheet .info-sheet-content .header-container .button-container .close-button {
  --icon-button-background-width: 100%;
  --icon-button-background-height: 100%;
  --icon-button-background-color: var(--mi-white-color);
  --icon-button-background-paddng: 0;
  --icon-button-border-radius: 0;
  --icon-button-icon-size: 24px;
  --icon-button-icon-color: var(--mi-secondary-color-2);
  outline: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 30px;
  width: 30px;
}
app-info-sheet .info-sheet-container .info-sheet .info-sheet-content .content-container {
  grid-row: 2;
  grid-column: 1;
  overflow-y: auto !important;
  padding: 0 var(--info-sheet-padding-end) 0 var(--info-sheet-padding-start);
}
app-info-sheet .info-sheet-container .info-sheet-background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 900;
  background-color: var(--mi-overlays-color);
  opacity: 0;
  transition: opacity var(--info-sheet-background-transition-time) ease;
  pointer-events: none;
}
app-info-sheet .info-sheet-container .info-sheet-background.transparent {
  background-color: transparent;
}
app-info-sheet.show .info-sheet-container .info-sheet-background {
  opacity: 0.85;
  pointer-events: all;
}
app-info-sheet.show .info-sheet-container .info-sheet {
  transform: translateY(0);
}

body.info-sheet-open {
  /* body overflow is hidden to hide main scrollbar when action sheet open */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
