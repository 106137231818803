// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content .interests-selection-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  row-gap: 20px;
}
ion-content .interests-selection-container .list-container {
  grid-row: 1;
  grid-column: 1;
}
ion-content .interests-selection-container .buttons-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  -moz-column-gap: 20px;
       column-gap: 20px;
  padding: 0 15px 15px 15px;
  align-items: center;
}
ion-content .interests-selection-container .buttons-container ion-spinner {
  justify-self: end;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
