// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --dedicated-search-groups-padding-top: 0px;
  --dedicated-search-groups-padding-bottom: 0px;
  --dedicated-search-groups-padding-start: 18px;
  --dedicated-search-groups-padding-end: 18px;
}

.dedicated-search-groups-container {
  width: 100%;
  height: 100%;
}
.dedicated-search-groups-container .content-container {
  width: 100%;
  height: 100%;
}
.dedicated-search-groups-container .content-container .content {
  width: 100%;
  height: 100%;
}
.dedicated-search-groups-container .content-container .content .start-state-container {
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dedicated-search-groups-container .content-container .content .start-state-container .start-state {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 15px;
  justify-content: center;
  align-items: center;
  max-width: 285px;
}
.dedicated-search-groups-container .content-container .content .start-state-container .start-state .icon-container {
  grid-row: 1;
  grid-column: 1;
}
.dedicated-search-groups-container .content-container .content .start-state-container .start-state .icon-container app-icon {
  --icon-size: 97px;
}
.dedicated-search-groups-container .content-container .content .start-state-container .start-state .text-container {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
}
.dedicated-search-groups-container .content-container .content .start-state-container .start-state .text-container p {
  margin: 0;
  text-align: center;
}
.dedicated-search-groups-container .content-container .content .start-state-container .start-state .text-container .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  color: var(---mi-black-color-2);
}
.dedicated-search-groups-container .content-container .content .start-state-container .start-state .text-container .subtitle {
  font-size: var(--font-size-extra-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.dedicated-search-groups-container .content-container .content .groups-list {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 40px;
  justify-content: center;
  align-items: center;
  padding-left: var(--dedicated-search-groups-padding-start);
  padding-right: var(--dedicated-search-groups-padding-end);
  padding-bottom: 15px;
  width: 100%;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 40px;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: min-content;
  -moz-column-gap: 6px;
       column-gap: 6px;
  align-items: center;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group .group-image-container {
  grid-row: 1;
  grid-column: 1;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group .group-image-container app-group-image {
  --group-image-size: 40px;
  --group-image-border-radius: 4px;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group .group-text-container {
  grid-row: 1;
  grid-column: 2;
  position: relative;
  height: 36.5px;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group .group-text-container .wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 2px;
  justify-content: start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 36.5px;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group .group-text-container .wrapper .group-title-container {
  position: relative;
  height: 18.5px;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group .group-text-container .wrapper .group-title-container .wrapper {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  height: 18.5px;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group .group-text-container .wrapper .group-title-container .wrapper p {
  margin: 0;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-black-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group .group-text-container .wrapper .group-visibility-container p {
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
}
.dedicated-search-groups-container .content-container .content .groups-list .group-wrapper .group .group-action-container {
  grid-row: 1;
  grid-column: 3;
}
.dedicated-search-groups-container .content-container .content app-skeleton-loader ::ng-deep .skeleton {
  padding: 10px var(--dedicated-search-groups-padding-end) 10px var(--dedicated-search-groups-padding-start);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
