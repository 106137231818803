import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

// components
import { IconButtonComponent } from './icon-button.component';
import { IconComponent } from '../icon/icon.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    IconComponent
  ],
  declarations: [
    IconButtonComponent
  ],
  exports: [
    IconButtonComponent
  ]
})
export class IconButtonModule { }
