import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import { SignUpStatus } from './logic/auth-logic.service.interface';
import { NavControllerService } from '../nav-controller.service';

export function authV2AoRedirectPageGuard(): CanActivateFn {

  return () => {

    const authService: AuthService = inject(AuthService);
    const contextService: ContextService = inject(CONTEXT_SERVICE);
    const navController: NavControllerService = inject(NavControllerService);

    return contextService.getCurrentContext().then(context => {
      return Promise.resolve(context?.key);
    }).catch(() => {
      return Promise.resolve(contextService?.currentContext?.key);
    }).then(currentContext => {

      if (!currentContext) {
        currentContext = contextService?.currentContext?.key;
      }

      return authService.getSignupStatus(currentContext)
        .then(async status => {
          if (status === SignUpStatus.FINISHED) {
            return navController.navigateRoot(contextService.currentContext.homePath);
          }

          return true;
        });
    })
  };
}
