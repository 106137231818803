import { Component, OnInit, Input } from '@angular/core';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { SharedWith, SharedWithContact, SharedWithGroup, SharedWithType } from 'src/app/services/yeti-protocol/clinical-case';
import {ActionSource} from '../../services/yeti-protocol/tracking';

@Component({
  selector: 'app-clinical-case-shared-with-slider-item',
  templateUrl: './clinical-case-shared-with-slider-item.component.html',
  styleUrls: ['./clinical-case-shared-with-slider-item.component.scss'],
})
export class ClinicalCaseSharedWithSliderItemComponent implements OnInit {

  @Input() sharedWith: SharedWith;
  @Input() source: ActionSource;

  constructor(
    private appNavController: AppNavController
  ) { }

  ngOnInit(): void { }

  get isGroup(): boolean {
    return this.sharedWith?.type === SharedWithType.GROUP;
  }

  get isContact(): boolean {
    return this.sharedWith?.type === SharedWithType.CONTACT;
  }

  get sharedWithGroup(): SharedWithGroup {
    return this.sharedWith as SharedWithGroup;
  }

  get sharedWithContact(): SharedWithContact {
    return this.sharedWith as SharedWithContact;
  }

  get groupImageUrl(): string {
    return this.sharedWithGroup?.group?.imageUrl;
  }

  get contactImageUrl(): string {
    return this.sharedWithContact?.chatMemberPublicProfile?.profileImageUrl;
  }

  itemClicked(): void {
    if (this.isGroup) {
      this.groupClicked();
    } else if (this.isContact) {
      this.contactClicked();
    }
  }

  groupClicked(): void {
    this.appNavController.openGroupCase(this.sharedWithGroup.postId, this.source);
  }

  contactClicked(): void {
    this.appNavController.openMessenger(this.sharedWithContact.connectionId);
  }

}
