import { Component, Input, ElementRef, AfterViewInit, OnDestroy, NgZone, Output, EventEmitter } from '@angular/core';

const bublleContainerClass = '.tutorial-bubble-container';
const overlayClass = '.tutorial-bubble-overlay';

@Component({
  selector: 'app-tutorial-bubble',
  templateUrl: './tutorial-bubble.component.html',
  styleUrls: ['./tutorial-bubble.component.scss'],
})
export class TutorialBubbleComponent implements AfterViewInit, OnDestroy {

  @Input() title: string;
  @Input() text: string;
  @Input() cancelButton: string;
  @Input() proceedButton: string;
  @Input() overlay: boolean;
  @Input() relativeToElement: HTMLElement;
  @Input() displayBellow: boolean;
  @Input() displayAbove: boolean;
  @Input() arrowLeftAligned: boolean;
  @Input() arrowRightAligned: boolean;
  @Input() customClass: boolean;
  @Input() totalSteps: number;
  @Input() currentStep: number;
  @Input() arrowCustomAlign: boolean;
  @Input() arrowLeftPosition = '0px';

  @Output() proceedClicked: EventEmitter<void> = new EventEmitter();
  @Output() cancelClicked: EventEmitter<void> = new EventEmitter();

  tutorialContainerTop = '0px';
  relativeToElementResizeObserver: any;
  bubbleElementResizeObserver: any;
  tutorialBubbleElement: HTMLElement;

  constructor(
    private el: ElementRef,
    private zone: NgZone
  ) { }

  ngAfterViewInit(): void {

    this.tutorialBubbleElement = this.el.nativeElement.querySelector('.tutorial-bubble-container');

    if (this.overlay) {
      this.addElementToBodyElement(overlayClass);
    }

    this.addElementToBodyElement(bublleContainerClass);

    this.setRelativeToElementResizeObserver();
    this.setBubbleElementResizeObserver();
  }

  ngOnDestroy(): void {

    if (this.overlay) {
      this.removeElementFromBodyElement(overlayClass);
    }

    this.removeElementFromBodyElement(bublleContainerClass);

    this.terminateRelativeToElementResizeResizeObserver();
    this.terminateBubbleElementResizeObserver();
  }

  proceed(): void {
    this.proceedClicked.emit();
  }

  cancel(): void {
    this.cancelClicked.emit();
  }

  calculateBubbleTopPosition(): void {

    if (!this.relativeToElement) {
      return;
    }

    let top = 0;
    const padding = 25;
    const relativeToElementTopPosition = this.relativeToElement.getBoundingClientRect().top;
    const bubbleHeight = this.tutorialBubbleElement.clientHeight;
    let relativeToElementHeight = this.relativeToElement?.clientHeight;
    const relativeToElementPaddingBottom = parseInt(window.getComputedStyle(this.relativeToElement)?.paddingBottom, 10);
    const relativeToElementMarginBottom = parseInt(window.getComputedStyle(this.relativeToElement)?.marginBottom, 10);

    if (this.displayAbove) {
      top = relativeToElementTopPosition - (bubbleHeight + padding);
      this.tutorialContainerTop = `${top || 0}px`;
    } else {
      this.displayBellow = true;
      relativeToElementHeight -= relativeToElementPaddingBottom;
      relativeToElementHeight -= relativeToElementMarginBottom;
      top = relativeToElementTopPosition + relativeToElementHeight + padding;
      this.tutorialContainerTop = `${top || 0}px`;
    }
  }

  setRelativeToElementResizeObserver(): void {

    if (!this.relativeToElement) {
      return;
    }

    if (!this.relativeToElementResizeObserver) {
      this.relativeToElementResizeObserver = new ResizeObserver(() => {
        this.zone.run(() => {
          if (this.relativeToElement?.clientHeight > 0) {
            setTimeout(() => {
              this.calculateBubbleTopPosition();
            }, 300);
          }
        });
      });
    }

    this.relativeToElementResizeObserver?.observe(this.relativeToElement);
  }

  terminateRelativeToElementResizeResizeObserver(): void {

    if (!this.relativeToElement) {
      return;
    }

    this.relativeToElementResizeObserver?.unobserve(this.relativeToElement);
    this.relativeToElementResizeObserver?.disconnect();
  }

  setBubbleElementResizeObserver(): void {

    if (!this.bubbleElementResizeObserver) {
      this.bubbleElementResizeObserver = new ResizeObserver(() => {
        this.zone.run(() => {
          if (this.tutorialBubbleElement?.clientHeight > 0) {
            this.calculateBubbleTopPosition();
          }
        });
      });
    }

    this.bubbleElementResizeObserver?.observe(this.tutorialBubbleElement);
  }

  terminateBubbleElementResizeObserver(): void {
    this.bubbleElementResizeObserver?.unobserve(this.tutorialBubbleElement);
    this.bubbleElementResizeObserver?.disconnect();
  }

  private addElementToBodyElement(elementClass: string): void {
    const uploadingMessageEl = this.el.nativeElement.querySelector(elementClass);
    document.body.appendChild(uploadingMessageEl);
  }

  private removeElementFromBodyElement(elementClass: string): void {
    const uploadingMessageEl = document.querySelector(elementClass);
    document.body.removeChild(uploadingMessageEl);
  }

}
