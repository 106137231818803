import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClinicalCasesAccessRequestsStateModel } from './clinical-cases-access-requests.model';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { CaseAccessRequest, GetPendingCasesRequestsSuccessResponse } from 'src/app/services/yeti-protocol/clinical-case';
import { ClinicalCasesAccessRequests } from './clinical-cases-access-requests.actions'
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { CLINICAL_CASES_ACCESS_REQUESTS_STATE_KEY } from '../state-constants';

const CLINICAL_CASES_ACCESS_REQUESTS_STATE_TOKEN = new StateToken<ClinicalCasesAccessRequestsStateModel>(
  CLINICAL_CASES_ACCESS_REQUESTS_STATE_KEY
);

@State({
  name: CLINICAL_CASES_ACCESS_REQUESTS_STATE_TOKEN,
  defaults: {
    clinicalCasesAccessRequests: [],
    totalCount: 0,
    loading: false,
  },
})
@Injectable()
export class ClinicalCasesAccessRequestsState {
  constructor(private clinicalCaseService: ClinicalCaseService) { }

  @Selector()
  static state(state: ClinicalCasesAccessRequestsStateModel): ClinicalCasesAccessRequestsStateModel {
    return state;
  }

  @Selector()
  static clinicalCasesAccessRequests(state: ClinicalCasesAccessRequestsStateModel): Array<CaseAccessRequest> {
    return state.clinicalCasesAccessRequests;
  }

  @Selector()
  static totalCount(state: ClinicalCasesAccessRequestsStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static loading(state: ClinicalCasesAccessRequestsStateModel): boolean {
    return state.loading;
  }

  @Action(ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequests)
  async fetchClinicalCasesAccessRequests(
    ctx: StateContext<ClinicalCasesAccessRequestsStateModel>,
    payload: ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequests
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      const clinicalCasesAccessRequests = (await this.clinicalCaseService.fetchCaseAccessRequests(
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize,
      )) as GetPendingCasesRequestsSuccessResponse;

      ctx.dispatch(
        new ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequestsSuccess(
          payload?.payloadParams,
          clinicalCasesAccessRequests
        )
      );
    } catch (err) {
      ctx.dispatch(
        new ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequestsFailed()
      );

      throw err;
    }
  }

  @Action(ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequestsSuccess)
  fetchClinicalCasesAccessRequestsSuccess(
    ctx: StateContext<ClinicalCasesAccessRequestsStateModel>,
    action: ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequestsSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        clinicalCasesAccessRequests: action.response.result,
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    } else {
      ctx.patchState({
        clinicalCasesAccessRequests: [...state.clinicalCasesAccessRequests, ...action.response.result],
        totalCount: action.response.totalItemsCount,
        loading: false,
      });
    }
  }

  @Action(ClinicalCasesAccessRequests.FetchClinicalCasesAccessRequestsFailed)
  fetchClinicalCasesAccessRequestsFailed(
    ctx: StateContext<ClinicalCasesAccessRequestsStateModel>
  ): void {
    ctx.patchState({
      loading: false,
    });
  }

  @Action(ClinicalCasesAccessRequests.InsertClinicalCaseAccessRequestBeforeIndex)
  insertClinicalCaseAccessRequestBeforeIndex(
    ctx: StateContext<ClinicalCasesAccessRequestsStateModel>,
    action: ClinicalCasesAccessRequests.InsertClinicalCaseAccessRequestBeforeIndex): void {
    ctx.setState(
      patch<ClinicalCasesAccessRequestsStateModel>({
        clinicalCasesAccessRequests: insertItem<CaseAccessRequest>(action.clinicalCaseAccessRequest, action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount + 1,
    });
  }

  @Action(ClinicalCasesAccessRequests.RemoveClinicalCaseAccessRequest)
  removeClinicalCaseAccessRequest(
    ctx: StateContext<ClinicalCasesAccessRequestsStateModel>,
    action: ClinicalCasesAccessRequests.RemoveClinicalCaseAccessRequest): void {
    ctx.setState(
      patch<ClinicalCasesAccessRequestsStateModel>({
        clinicalCasesAccessRequests: removeItem<CaseAccessRequest>(action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount - 1,
    });
  }

  @Action(ClinicalCasesAccessRequests.UpdateClinicalCaseAccessRequest)
  updateClinicalCaseAccessRequest(
    ctx: StateContext<ClinicalCasesAccessRequestsStateModel>,
    action: ClinicalCasesAccessRequests.UpdateClinicalCaseAccessRequest): void {
    ctx.setState(
      patch<ClinicalCasesAccessRequestsStateModel>({
        clinicalCasesAccessRequests: updateItem<CaseAccessRequest>(
          clinicalCaseAccessRequest =>
            clinicalCaseAccessRequest._id === action.clinicalCaseAccessRequest._id, action.clinicalCaseAccessRequest)
      })
    );
  }
}
