import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { PersonalMediaGalleryDocumentWithStageOfTreatment, StageOfTreatment, StageOfTreatmentId } from '../stage-of-treatment.model';


@Component({
  selector: 'app-stage-of-treatment-chips',
  templateUrl: './stage-of-treatment-chips.component.html',
  styleUrls: ['./stage-of-treatment-chips.component.scss'],
})
export class StageOfTreatmentChipsComponent implements OnInit {

  @Input() image: PersonalMediaGalleryDocumentWithStageOfTreatment;
  @Output() selectedStageOfTreatmentChanged: EventEmitter<string> = new EventEmitter();

  stageOfTreatmentChips: Array<StageOfTreatment> = [];

  constructor(
    private appTranslationService: AppTranslationService
  ) { }

  ngOnInit(): void {
    this.initChips();
  }

  initChips(): void {
    this.stageOfTreatmentChips = [
      {
        label: this.appTranslationService.instant('app.groups.CreateGroupCase.preOp'),
        id: StageOfTreatmentId.PRE_OP
      },
      {
        label: this.appTranslationService.instant('app.groups.CreateGroupCase.intraOp'),
        id: StageOfTreatmentId.INTRA_OP
      },
      {
        label: this.appTranslationService.instant('app.groups.CreateGroupCase.postOp'),
        id: StageOfTreatmentId.POST_OP
      },
      {
        label: this.appTranslationService.instant('app.groups.CreateGroupCase.followUp'),
        id: StageOfTreatmentId.FOLLOW_UP
      }
    ];
  }

  primaryChipClicked(chipId: StageOfTreatmentId): void {

    if (this.image?.stageOfTreatmentId === chipId) {
      this.image.stageOfTreatmentId = null;
    } else {
      this.image.stageOfTreatmentId = chipId;
    }

    this.selectedStageOfTreatmentChanged.emit(this.image?.stageOfTreatmentId);
  }

}
