// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-filters-tabs-container app-chips {
  --chips-margin: 23px;
  --rows-margin: 10px;
}
.search-filters-tabs-container app-chips app-text-chip {
  --color: var(--mi-white-color);
  --background-color: none;
  max-width: calc(100% - var(--chips-margin));
  padding: 2.5px 16.5px;
  border: 1px solid var(--mi-color-text-lite);
  border-radius: 8px;
  background-color: var(--mi-primary-color);
  cursor: pointer;
}
.search-filters-tabs-container app-chips app-text-chip:not(.selected) {
  --color: var(--mi-color-text-lite);
  background: none;
}
.search-filters-tabs-container app-chips app-text-chip ::ng-deep .text-chip-container .text-chip-text {
  text-overflow: unset;
  overflow: unset;
}
.search-filters-tabs-container app-chips app-text-chip ::ng-deep .text-chip-container .text-chip-text .text-container {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.search-filters-tabs-container app-chips app-text-chip ::ng-deep .text-chip-container .text-chip-text .text-container .title-container {
  grid-row: 1;
  grid-column: 1;
  overflow: hidden;
}
.search-filters-tabs-container app-chips app-text-chip ::ng-deep .text-chip-container .text-chip-text .text-container .title-container .title-wrapper {
  width: 100%;
}
.search-filters-tabs-container app-chips app-text-chip ::ng-deep .text-chip-container .text-chip-text .text-container .title-container .title-wrapper .title {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.search-filters-tabs-container app-chips app-text-chip ::ng-deep .text-chip-container .text-chip-text .text-container .count-container {
  grid-row: 1;
  grid-column: 2;
}
.search-filters-tabs-container app-chips app-text-chip ::ng-deep .text-chip-container .text-chip-text .text-container .count-container .count {
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
