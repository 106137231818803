import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Group, GroupByIdSuccessResponse } from 'src/app/services/yeti-protocol/chatter-api';
import { GroupMember } from 'src/app/services/groups/group.model';

// services
import { GroupsService } from 'src/app/services/groups/groups.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';

import appConfig from 'src/config/config';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';

@Component({
  selector: 'app-see-all-group-moderators',
  templateUrl: './see-all-group-moderators.component.html',
  styleUrls: ['./see-all-group-moderators.component.scss'],
})
export class SeeAllGroupModeratorsComponent implements OnInit {

  @Input() groupId: string;
  @Input() groupOwnerOrModerator: boolean;
  selectedMemberInOptions: GroupMember;
  group: Group
  groupModerators: Array<GroupMember> = new Array<GroupMember>();
  infoSheetId = 'group-moderators-info-card';

  constructor(
    private groupsService: GroupsService,
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    private infoSheetService: InfoSheetService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    if(this.groupId) {
      this.getGroupById(this.groupId);
    }
  }

  onClose(): void {
    this.modalController.dismiss(null, 'cancel');
  }

  onProfileImageClicked(userId: string): void {
    this.modalController.dismiss({
      actionKey: 'open-user-profile',
      userId}, 'cancel');
  }

  getGroupById(groupId: string): void {
    this.groupsService.getGroupById(groupId).then(response => {
      if (response) {
        this.group = (response as GroupByIdSuccessResponse).result;
        if(this.group?.moderators) {
          this.groupModerators = this.group?.moderators;
          this.groupModerators.forEach(moderator => {
            moderator.role = 'moderator';
          });
        }
      }
    });
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  async presentActionSheet(member: GroupMember, event: Event): Promise<void> {
    if (this.isDesktop) {
      this.selectedMemberInOptions = member;
      this.infoSheetService.openWeb(this.infoSheetActions, event)
        .then(action => {
          if (action && action.code) {
            const newRole = this._actionCodeToRole(action.code, member.role);
            this.updateGroupMember(this.groupId, member, newRole, newRole === 'removed' ? 'removed' : null);
          }
        })
    } else {
      this.selectedMemberInOptions = member;
      this.infoSheetService.open(this.infoSheetId);
    }
  }

  _actionCodeToRole(actionCode: string, defaultRole: string): string {
    if(actionCode === 'ASSIGN_MODERATOR'){
      return 'moderator';
    }
    if(actionCode === 'REMOVE_MODERATOR'){
      return 'member';
    }
    if(actionCode === 'REMOVE_FROM_GROUP'){
      return 'removed';
    }
    return defaultRole;
  }

  updateGroupMember(groupId: string, member: GroupMember, role: string, status?: string): void {
    this.groupsService.updateGroupMember(member.userId, role, groupId, status,).then(response => {
      if (response) {
        member.role = role;
        this.updateGroupMembers();
      }
    })
      .catch(() => {
        this.dialogs.showErrorDialogById('app.common.something-went-wrong');
      })
  }

  updateGroupMembers(): void {
    this.getGroupById(this.groupId);
  }

  assignModerator(): void {
    if (this.groupModerators && this.groupModerators.length === appConfig.groupModeratorsMaxNum) {
      this.toast.show('app.groups.SeeAllParticipants.maximum-moderators', 'app.common.error-default', ToastMode.ERROR);
    } else {
      this.updateGroupMember(this.groupId, this.selectedMemberInOptions, 'moderator');
    }
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    const actions: Array<InfoSheetActionItem> = [];
    if (this.selectedMemberInOptions){
      if (this.selectedMemberInOptions.role !== 'moderator') {
        actions.push({
          id: 'assign-moderator',
          icon: 'md-icon-crown-plus',
          textKey: 'app.groups.GroupParticipants.assign-moderator',
          code: 'ASSIGN_MODERATOR',
          handler: () => {
            this.assignModerator();
          }
        });
      }
      if (this.selectedMemberInOptions.role === 'moderator') {
        actions.push({
          id: 'unassign-moderator',
          icon: 'md-icon-crown-minus',
          textKey: 'app.groups.GroupParticipants.remove-moderator',
          code: 'REMOVE_MODERATOR',
          handler: () => {
            this.updateGroupMember(this.groupId, this.selectedMemberInOptions, 'member');
          }
        });
      }
      actions.push({
        id: 'remove-user',
        icon: 'md-icon-user-minus',
        textKey: 'app.groups.GroupParticipants.remove-from-group',
        code: 'REMOVE_FROM_GROUP',
        handler: () => {
          this.updateGroupMember(this.groupId, this.selectedMemberInOptions, this.selectedMemberInOptions.role,'removed');
        }
      });
    }
    return actions;
  }
}
