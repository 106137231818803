import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import appConfig from 'src/config/config';

// services
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { ContextSwitchService, CONTEXT_SWITCH_SERVICE } from 'src/app/services/context/context-switch.model';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';

// models
import { ContextConfigInterface } from 'src/config/config.model';

@Component({
  selector: 'app-context-switch-button',
  templateUrl: './context-switch-button.component.html',
  styleUrls: ['./context-switch-button.component.scss'],
})
export class ContextSwitchButtonComponent implements OnInit, OnDestroy {

  contexts: ContextConfigInterface[] = appConfig.contexts;
  iconName: string;
  private routeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    @Inject(CONTEXT_SWITCH_SERVICE) private contextSwitchService: ContextSwitchService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.data.subscribe(_data => {
      this.updateContextIconImgSrc();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }

  updateContextIconImgSrc(): void {
    const ctx = this.contextService.currentContext;
    this.iconName = ctx.iconName ? ctx.iconName : null;
  }

  onOpenContextSwitchMenu(): void {
    this.contextSwitchService.openContextSwitchMenu();
    this.trackOpenContextSelectionClicked();
  }

  trackOpenContextSelectionClicked(): Promise<void> {
    const objId = 'context';
    const objType = 'context';
    const objectTitle = 'openContextSelection';

    return this.trackingService.trackGenericClickedAction(objId, objType, objectTitle)
      .catch(_err => {
        console.error('Could not track openContextSelection click action.');
      });
  }

  get isMultipleContexts(): boolean {
    return this.contexts && this.contexts.length > 1;
  }
}
