// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  --background: var(--mi-primary-color);
  --border-radius: 4px;
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 11px;
  --padding-end: 11px;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  margin: 10px auto;
  text-transform: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
