// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --image-width: 20px;
  --image-height: 20px;
  --arrow-color: var(--mi-white-color);
}

.context-switch-button {
  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -moz-column-gap: 6px;
       column-gap: 6px;
  justify-items: center;
  align-items: center;
  border-radius: 15px;
  background-color: var(--mi-background-color);
  padding: 4px 7px;
}
.context-switch-button .context-icon-image {
  width: var(--image-width);
  height: var(--image-height);
}
.context-switch-button .circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--mi-white-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
