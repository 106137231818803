import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// models
import { ParentType } from '../../services/yeti-protocol/chatter-api-schema';
import {
  PostListItem,
  Post,
  PostType,
  PostOwner, LikeUnlikeSuccessResponse
} from '../../services/yeti-protocol/chatter-api';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { TranslatorData, TranslatorObject } from 'src/app/services/translator.model';
import { RichTextDocumentEditorComponent }
  from 'src/app/modules/rich-text-document-editor/components/rich-text-document-editor/rich-text-document-editor.component';
import { BaseUserProfile, NUMBER_USER_IMG_BUBBLES_CARDS } from 'src/app/modules/users/users.model';
import { VerificationStatus } from 'src/app/services/verification.model';

// services
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import {
  CreateGroupContentService
} from 'src/app/services/create-content/group/create-group-content-service.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { TranslatorService } from 'src/app/services/translator.service';
import { ActionSource, TranslationAction } from 'src/app/services/yeti-protocol/tracking';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI, ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { PostActionContent, PostsActions, PostsDataService } from '../../services/posts/posts-data.service';
import { CreateEventContentService } from '../../services/create-content/event/create-event-content-service.service';
import { ChatterApiService } from '../../services/chatter-api.service';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { CreateContactsFollowersContentService } from
  'src/app/services/create-content/contacts-followers/create-contacts-followers-content-service.service';
import { IconLabelPosition } from '../../modules/buttons/base-button/base-button.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { VerificationService } from 'src/app/services/verification.service';

@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
})
export class PostCardComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('richTextDocumentEditor') richTextDocumentEditor: RichTextDocumentEditorComponent;

  @Input() post: PostListItem;
  @Input() isModerator: boolean;
  @Input() source: ActionSource;
  @Input() displayPostLastModifed = false;
  @Input() allowPin = false;
  @Output() postClick: EventEmitter<PostListItem> = new EventEmitter();
  @Output() postDeleted: EventEmitter<string> = new EventEmitter();
  @Output() pin: EventEmitter<string> = new EventEmitter();
  @Output() unpin: EventEmitter<string> = new EventEmitter();
  @Output() userFollowed: EventEmitter<PostOwner> = new EventEmitter();

  IconLabelPosition = IconLabelPosition;
  hideAlsoDiscussedIn = false;

  protected applauding: boolean;
  protected deletingPost: boolean;
  protected reportingPost: boolean;
  infoSheetId = 'post-infosheet';
  protected user: UserProfile;
  protected isTranslated = false;
  protected originalDescription: string;
  private userSubscription: Subscription;
  private postChangesSubscription: Subscription;

  constructor(
    private infoSheetService: InfoSheetService,
    private createGroupContentService: CreateGroupContentService,
    private createEventContentService: CreateEventContentService,
    private createContactsFollowersContentService: CreateContactsFollowersContentService,
    private authService: AuthService,
    private toast: ToastService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private translatorService: TranslatorService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    @Inject(CONTEXT_DIALOGS_UI) private ContextDialogs: ContextDialogsUI,
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface,
    private postsDataService: PostsDataService,
    private chatterApiService: ChatterApiService,
    private appNavController: AppNavController,
    private appTranslationService: AppTranslationService,
    private dialogs: DialogsUIService,
    private verificationService: VerificationService
  ) {
  }

  ngOnInit(): void {

    this.userSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });

    this.postChangesSubscription = this.postsDataService.getPostsChangesStream().subscribe((postsActionContent: PostActionContent) =>
      this.executePostChange(postsActionContent));
  }

  ngAfterViewInit(): void {
    setTimeout(() => { // throws error if this is not put in timeout
      this.infoSheetId += `-${this.post?._id}`;
      this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);
    }, 0);
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.postChangesSubscription?.unsubscribe();
  }

  stopEventPropagation(event: Event): void {
    if (event) {
      this.uiUtilsService.stopEventPropagation(event);
    }
  }

  async openPost(event: MouseEvent): Promise<void> {
    const target = event?.target as Element;
    if (!target?.closest('.play-image')) {

      this.stopEventPropagation(event);

      try {
        await this.verificationService.checkAndReloadCachedUserProfile();
      } catch (err) {
        console.error(err);
      }

      if (this.user?.verificationStatus === VerificationStatus.PENDING) {
        this.uiUtilsService.showPendingStateExplainerDialog(this.post?._id, PostType.post, this.source);
        return;
      }

      let url = '';

      switch (this.post?.parentType) {
        case ParentType.CHAT:
        case ParentType.USER:
          if (this.post?.type === PostType.case) {
            this.appNavController.openPrivatePublicSharedCase(this.post?._id, this.source);
            return;
          } else {
            this.appNavController.openPrivatePublicPost(this.post?._id, this.source);
            return;
          }
        default:
          url = '/' + [this.contextService.currentContext.key, `${this.post?.parentType?.toLowerCase()}/post`, this.post?._id].join('/');
          break;
      }

      if (this.source) {
        this.router.navigate([url], { queryParams: { source: this.source } })
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }

  pinPost(): void {
    this.pin.emit(this.post?._id);
  }

  unpinPost(): void {
    this.unpin.emit(this.post?._id);
  }

  editPost(): void {
    switch (this.post.parentType) {
      case ParentType.GROUP:
        this.createGroupContentService.openCreateGroupPostPage(this.post?.parentId, this.post?._id, undefined, this.post?.description);
        break;
      case ParentType.EVENT:
        this.createEventContentService.openCreateEventPostPage(this.post?.parentId, this.post?._id, undefined, this.post?.description);
        break;
      case ParentType.CHAT:
      case ParentType.USER:
        this.createContactsFollowersContentService.openCreateContactsFollowersPostPage(this.post?._id, undefined, this.post?.description);
        break;
    }
  }

  async deletePost(): Promise<void> {

    if (this.deletingPost) {
      return;
    }

    this.deletingPost = true;

    try {
      const shouldProceedWithDelete = await this.showDeletePostConfirmDialog();

      if (!shouldProceedWithDelete) {
        this.deletingPost = false;
        return;
      }

      this.chatterApiService.deletePost(this.post?._id).then(() => {
        this.postDeleted.emit(this.post?._id);
        this.postsDataService.triggerPostDeletedAction(this.post);
        const message = this.appTranslationService.instant('app.common.post-successfully-deleted');
        this.toast.showWithMessage(message, undefined, ToastMode.SUCCESS);
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.deletingPost = false;
      });
    } catch (err) {
      this.deletingPost = false;
    }
  }

  get isPostOwner(): boolean {
    return this.post?.owner?.userId === this.user?.id || this.post?.isOwnPost;
  }

  async applaudClicked(applaud: boolean): Promise<void> {

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.post?._id, PostType.post, this.source);
      return;
    }

    if (this.applauding) {
      return;
    }

    this.applauding = true;
    const postId = this.post?._id;
    (applaud
      ? this.chatterApiService.likeOrUnlikeObject(postId, 'post', true)
      : this.chatterApiService.likeOrUnlikeObject(postId, 'post', false)
    ).then((postResponse: LikeUnlikeSuccessResponse) => {
      const post = postResponse.result as Post;
      this.post.hasLiked = post?.hasLiked;
      this.post.numberOfLikes = post?.numberOfLikes;
      this.post.usersThatApplauded = post?.usersThatApplauded;
    })
      .finally(() => {
        this.applauding = false;
      });
  }

  async openApplaudsList(): Promise<void> {

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.post?._id, PostType.post, this.source);
      return;
    }

    this.ContextDialogs.showApplaudsList(this.post._id, 'post');
  }

  onTranslate(): void {
    const translationLanguage = this.user.translationLanguage || '';
    if (translationLanguage) {
      this.translatePost(translationLanguage)
    } else {
      this.ContextDialogs.showSelectLanguageDialog();
    }
  }

  translatePost(translationLanguage: string): void {
    if (!this.isTranslated) {
      this.originalDescription = this.post?.description;

      const dataToTranslateArray: Array<TranslatorObject> = new Array<TranslatorObject>();
      dataToTranslateArray.push({
        origin: '',
        title: '',
        text: this.post?.description
      });

      this.trackTranslationAction(this.post?._id, TranslationAction.translate, translationLanguage);
      this.translatorService.translateBatch('google', translationLanguage, dataToTranslateArray)
        .then((data: TranslatorData) => {
          if (data.records.length === 1) {
            this.post.description = data.records[0].text;
            this.isTranslated = true;
          }
        }).catch(err => {
          const toastMessage = err.error.error.message.errfor.message;
          this.toast.showWithMessage(toastMessage, 'app.common.error-default', ToastMode.ERROR);
        });
    } else {
      this.post.description = this.originalDescription;
      this.isTranslated = false;
      this.trackTranslationAction(this.post?._id, TranslationAction.backToOriginal, translationLanguage);
    }
  }

  reportPost(): void {
    if (this.reportingPost || !this.post?._id) {
      return;
    }

    this.reportingPost = true;

    this.chatterApiService.reportPostAsAbuse(this.post?._id).then(() => {
      const message = this.appTranslationService.instant('app.groups.post-reported-message');
      this.toast.showWithMessage(message, undefined, ToastMode.SUCCESS);
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.reportingPost = false;
    })
  }

  async openParentDetailsPage(event: Event): Promise<void> {
    this.stopEventPropagation(event);

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.post?._id, PostType.post, this.source);
      return;
    }

    let url = '';

    if (this.post?.parentType === ParentType.GROUP && this.post?.parentId) {
      url = `${this.contextService.currentContext.homePath}/group/${this.post?.parentId}`;
    }

    if (this.post?.parentType === ParentType.EVENT && this.post?.parentId) {
      url = `${this.contextService.currentContext.homePath}/event/${this.post?.parentId}`;
    }

    if (!url?.length) {
      return;
    }

    if (this.source && this.source !== ActionSource.unspecified) {
      url = `${url}?source=${this.source}`;
    }

    this.router.navigateByUrl(url);

  }

  get usersThatApplauded(): Array<BaseUserProfile> {
    if (!this.post || !this.post?.usersThatApplauded?.length) {
      return [];
    }

    return this.post?.usersThatApplauded.slice(0, NUMBER_USER_IMG_BUBBLES_CARDS).map(userThatApplauded => {
      return {
        userId: userThatApplauded.userId,
        profileImageUrl: userThatApplauded.profileImageUrl
      }
    });
  }

  get isCrownUser(): boolean {
    return this.post?.createdByModerator && !this.post?.isAnonymous ? true : false;
  }

  get isVerificationPendingUser(): boolean {
    return this.user?.verificationStatus === VerificationStatus.PENDING;
  }

  get displayProfileImageBubbles(): boolean {
    return this.post?.numberOfLikes && this.post?.numberOfLikes > 0 ? true : false;
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    const actions: Array<InfoSheetActionItem> = [];

    if (this.allowPin && this.isModerator && !this.post?.isPinned) {
      actions.push({
        id: 'pin_post_btn',
        icon: 'md-icon-pin',
        textKey: 'app.common.pin-to-top',
        code: 'PIN_POST',
        handler: () => {
          this.pinPost();
        }
      });
    }

    if (this.allowPin && this.isModerator && this.post?.isPinned) {
      actions.push({
        id: 'unpin_post_btn',
        icon: 'md-icon-pin',
        textKey: 'app.common.unpin',
        code: 'UNPIN_POST',
        handler: () => {
          this.unpinPost();
        }
      });
    }

    if (this.isPostOwner) {
      actions.push({
        id: 'edit_post_btn',
        icon: 'md-icon-pencil',
        textKey: 'app.common.edit',
        code: 'EDIT_POST',
        handler: () => {
          this.editPost();
        }
      });
    }
    if (this.showDeleteButton) {
      actions.push({
        id: 'delete_post_btn',
        icon: 'md-icon-bin',
        textKey: 'app.common.delete',
        code: 'DELETE_POST',
        handler: () => {
          this.deletePost();
        }
      });
    }
    if (this.post?.description?.length) {
      actions.push({
        id: 'translate_post_btn',
        icon: 'md-icon-translate',
        textKey: 'app.common.translate',
        code: 'TRANSLATE_CASE',
        handler: () => {
          this.onTranslate();
        }
      });
    }
    if (!this.isPostOwner && !this.isModerator) {
      actions.push({
        id: 'report_post_btn',
        icon: 'md-icon-alert',
        textKey: 'app.common.flag-irrelevant',
        code: 'REPORT_POST',
        handler: () => {
          this.reportPost();
        }
      })
    }
    return actions;
  }

  get showDeleteButton(): boolean {
    return this.isPostOwner || this.isModerator || this.post?.isGroupOwnerOrModerator ? true : false;
  }

  executePostChange(postsActionContent: PostActionContent): void {

    if (postsActionContent?.post?.parentId !== this.post?.parentId || postsActionContent?.post._id !== this.post._id) {
      return;
    }

    switch (postsActionContent.action) {
      case PostsActions.POST_EDITED:
        this.post = postsActionContent?.post as Post;
        break;
    }
  }

  get showLatestComment(): boolean {
    return this.post?.latestComment?.content?.length > 0 || this.post?.latestComment?.attachments?.length > 0;
  }

  get postDate(): string {
    return this.displayPostLastModifed ? this.post?.lastModified : this.post?.createdDate;
  }

  // if lastModified date is displayed insted of createdDate, the 'posted' verb should be removed
  get postedVerb(): boolean {
    return !this.displayPostLastModifed;
  }

  async handleActionFromPopover(action: InfoSheetActionItem): Promise<void> {

    try {
      await this.verificationService.checkAndReloadCachedUserProfile();
    } catch (err) {
      console.error(err);
    }

    if (this.user?.verificationStatus === VerificationStatus.PENDING) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.post?._id, PostType.post, this.source);
      return;
    }

    switch (action.code) {
      case 'PIN_POST': {
        this.pinPost();
        break;
      }
      case 'UNPIN_POST': {
        this.unpinPost();
        break;
      }
      case 'EDIT_POST': {
        this.editPost();
        break;
      }
      case 'DELETE_POST': {
        this.deletePost();
        break;
      }
      case 'TRANSLATE_CASE': {
        this.onTranslate();
        break;
      }
      case 'REPORT_POST': {
        this.reportPost();
        break;
      }
      default:
        throw new Error('Invalid action code!')
    }
  }

  private trackTranslationAction(postId: string, action: string, language: string): void {
    this.trackingService.trackTranslateAction(postId, 'post', '', language, action, undefined, { source: this.source })
      .catch(_err => {
        console.error('Could not track translation click action.');
      });
  }

  onUserFollowed(postOwner: PostOwner): void {
    this.userFollowed.emit(postOwner);
  }

  async showDeletePostConfirmDialog(): Promise<boolean> {
    const deleteActionKey = 'delete';
    const cancelActionKey = 'cancel';

    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: this.appTranslationService.instant('app.components.Post.delete-post-confirm.title')
        },
        message: {
          translationKey: this.appTranslationService.instant('app.components.Post.delete-post-confirm.text')
        },
        actions: [
          {
            key: deleteActionKey,
            label: {
              translationKey: this.appTranslationService.instant('app.components.Post.delete-post-confirm.confirm-btn')
            },
            className: 'primary'
          },
          {
            key: cancelActionKey,
            label: {
              translationKey: this.appTranslationService.instant('app.components.Post.delete-post-confirm.cancel-btn')
            },
            className: 'secondary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === deleteActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
