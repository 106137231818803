// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.case-text-details-button-container {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: min-content;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
  border-radius: 10px;
  background-color: var(--mi-background-color);
  padding: 15px 15px 15px 20px;
  cursor: pointer;
}
.case-text-details-button-container.with-text {
  background-color: var(--mi-blue-color-4);
}
.case-text-details-button-container .start-icon-container {
  grid-row: 1;
  grid-column: 1;
  margin-right: 10px;
}
.case-text-details-button-container .start-icon-container app-icon {
  --icon-size: 34px;
  color: var(--mi-grey-color-13);
}
.case-text-details-button-container .text-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  row-gap: 6px;
  align-items: center;
}
.case-text-details-button-container .text-container .title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  margin: 0;
}
.case-text-details-button-container .text-container .text {
  position: relative;
  height: 13px;
}
.case-text-details-button-container .text-container .text .text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 13px;
}
.case-text-details-button-container .text-container .text .text-wrapper p {
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-text-details-button-container .end-icon-container {
  grid-row: 1;
  grid-column: 3;
}
.case-text-details-button-container .end-icon-container app-icon {
  --icon-size: 16px;
  color: var(--mi-grey-color-13);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
