import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// services
import {
  ClientConfigServiceInterface,
  ClientConfigServiceConfig,
  ClientConfigurationInfo,
  DEFAULT_DISABLED,
  UNKNOWN_VERSION
} from './client-config.service.interface';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';

import config from '../../../config/config';

@Injectable({
  providedIn: 'root'
})
export class ClientConfigurationService implements ClientConfigServiceInterface {
  config: ClientConfigServiceConfig = {
    timeout: config.requestTimeout,
    clientConfigUrl: config.clientConfigurationsUrl
  };

  constructor(
    private httpClient: HttpClient,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) {
  }

  getRemoteVersion(): Promise<string> {
    return this._fetchClientConfiguration().then(info => {
      return info.version.toString();
    });
  }

  getClientConfiguration(): Promise<ClientConfigurationInfo> {
    return this._fetchClientConfiguration().then(info => {
      return info;
    });
  }

  _fetchClientConfiguration(): Promise<ClientConfigurationInfo> {
    const context: string = this._getCurrentContext();
    return new Promise<ClientConfigurationInfo>((resolve) => {
      this.httpClient.get<any>(`${this.config.clientConfigUrl}`)
        .subscribe({
          next: res => {
            if (res) {
              try {
                const version = res.version;
                const showFeedSmartSwitch = res[`${context}`].showFeedSmartSwitch;
                const showVideoTranscriptSummary = res[`${context}`].showVideoTranscriptSummary;
                resolve({
                  version: version ? version : UNKNOWN_VERSION,
                  showFeedSmartSwitch,
                  showVideoTranscriptSummary
                });
              } catch (err) {
                console.log(err);
                resolve(DEFAULT_DISABLED);
              }
            } else {
              resolve(DEFAULT_DISABLED);
            }
          },
          error: err => {
            console.log(err);
            resolve(DEFAULT_DISABLED);
          }
        });
    });
  }

  private _getCurrentContext(): string {
    return this.contextService.currentContext.key;
  }
}
