import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

// models
import {
  CaseClassificationsResponse,
  CaseClassificationsSuccessResponse,
  CaseClassificationTagsObject
} from 'src/app/services/yeti-protocol/chatter-api';
import { CaseClassificationClickedObject } from '../case-classification-chip/case-classification-chip.component';
import { CaseClassificationFields } from './case-classification-tags-selection.model';

// services
import { GroupsService } from 'src/app/services/groups/groups.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { CaseClassificationTagsUiService } from 'src/app/services/case-classification-tags-ui-service.service';
import { FruitSaladUiService } from 'src/app/services/fruit-salad-ui-service.service';
import { CaseFruitSaladaSuggestionType } from 'src/app/services/yeti-protocol/fruit-salad';

interface CaseClassificationTagsObjectExtended extends CaseClassificationTagsObject {
  custom?: boolean;
}

@Component({
  selector: 'app-case-classification-tags-selection',
  templateUrl: './case-classification-tags-selection.component.html',
  styleUrls: ['./case-classification-tags-selection.component.scss'],
})
export class CaseClassificationTagsSelectionComponent implements OnInit, OnChanges {

  @Input() primaryTopic: string;
  @Input() secondaryTopic: string;
  @Input() primaryTopicFilter: string;
  @Input() secondaryTopicFilter: string;
  @Input() disableSecondaryTopicSelection: boolean;
  @Input() showErrorIfPrimaryTopicIsEmpty: boolean;
  @Input() editCase = true;
  @Input() preventTextOverflow = true;

  @Output() caseClassificationTagsChanged: EventEmitter<CaseClassificationFields> = new EventEmitter();

  caseClassificationTags: Array<CaseClassificationTagsObjectExtended>;
  secondaryTopics: Array<string>;
  selectedCustomPrimaryTopic: boolean;
  selectedCustomSecondaryTopic: boolean;
  selectedPrimaryTopic: string;
  selectedSecondaryTopic: string;
  primaryTagsGroupId = 'primary-case-tags';
  secondaryTagsGroupId = 'secondary-case-tags';
  private caseClassificationTagsServerValue: Array<CaseClassificationTagsObject>;
  private primaryTagsRendered: boolean;
  private secondaryTagsRendered: boolean;

  constructor(
    private groupsService: GroupsService,
    private appTranslationService: AppTranslationService,
    private caseClassificationTagsUiService: CaseClassificationTagsUiService,
    private fruitSaladUiService: FruitSaladUiService
  ) { }

  ngOnInit(): void {
    this.fetchCaseClassificationTags();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.primaryTopic &&
      changes?.primaryTopic?.currentValue &&
      changes?.primaryTopic?.currentValue !== this.selectedPrimaryTopic) {
      this.initPrimaryTopic(changes?.primaryTopic?.currentValue);
    }
  }

  get filteredCaseClassificationTags(): Array<CaseClassificationTagsObjectExtended> {

    if (!this.caseClassificationTags || !this.caseClassificationTags?.length) {
      return [];
    }

    if (!this.primaryTopicFilter || !this.primaryTopicFilter.length) {
      return this.caseClassificationTags;
    }

    return this.caseClassificationTags.filter((tag: CaseClassificationTagsObjectExtended) =>
      tag.primaryTopicName?.toLowerCase()?.startsWith(this.primaryTopicFilter?.toLocaleLowerCase()));
  }

  get filteredSecondaryTopics(): Array<string> {

    if (!this.secondaryTopics || !this.secondaryTopics?.length) {
      return [];
    }

    if (!this.secondaryTopicFilter || !this.secondaryTopicFilter.length) {
      return this.secondaryTopics;
    }

    const regex = new RegExp(this.secondaryTopicFilter, 'i');
    return this.secondaryTopics.filter(tag => regex.test(tag));
  }

  fetchCaseClassificationTags(): Promise<CaseClassificationsResponse> {

    const promise = this.groupsService.getCaseClassifications();

    promise.then((caseClassificationsSuccessResponse: CaseClassificationsSuccessResponse) => {
      this.caseClassificationTagsServerValue = caseClassificationsSuccessResponse.result;
      this.setCaseClassificationTags(this.caseClassificationTagsServerValue);
    }).catch(err => {
      console.error(err);
    });

    return promise;
  }

  setCaseClassificationTags(caseClassificationTags: Array<CaseClassificationTagsObjectExtended>): void {
    let newArray: Array<CaseClassificationTagsObjectExtended>;

    if (caseClassificationTags?.length) {
      newArray = caseClassificationTags.map(obj => ({ ...obj })); // make a deep copy from array
    }

    this.caseClassificationTags = newArray;
  }

  async primaryChipClicked(caseClassificationClickedObject: CaseClassificationClickedObject): Promise<void> {

    // click was performed on selected primary tag
    // tag should be deselected or if tag is custom classification should restart
    if (!caseClassificationClickedObject?.text || caseClassificationClickedObject?.text === this.selectedPrimaryTopic) {

      const selectedCaseClassificationTagObjectServerValue = this.caseClassificationTagsServerValue
        .find(tagObj => tagObj.primaryTopicName === this.selectedPrimaryTopic);

      if (!this.selectedCustomPrimaryTopic) {
        this.caseClassificationTags = this.caseClassificationTags
          .map(tagObj => {
            if (tagObj.primaryTopicName === this.selectedPrimaryTopic) {
              tagObj.secondaryTopics = { ...selectedCaseClassificationTagObjectServerValue }.secondaryTopics;
            }
            return tagObj;
          });
      } else if (this.selectedCustomPrimaryTopic) {

        this.primaryTopic = '';
        this.secondaryTopic = '';

        this.setCaseClassificationTags(this.caseClassificationTagsServerValue);
      }

      this.caseClassificationTagsUiService.deselectTag(this.primaryTagsGroupId, caseClassificationClickedObject?.text);

      this.setPrimaryTopic('');
      this.setSecondaryTopic('');
      this.setSecondaryTopics([]);
      this.selectedCustomSecondaryTopic = false;
      this.selectedCustomPrimaryTopic = false;

      return;
    }

    const customTagText = await this.getCustomTagText();

    // click was performed on not selected primary tag
    // tag should become selected
    if (caseClassificationClickedObject?.text !== customTagText) {

      this.primaryTopic = caseClassificationClickedObject?.text;
      this.setPrimaryTopic(caseClassificationClickedObject?.text);
      this.caseClassificationTagsUiService.selectTag(this.primaryTagsGroupId, caseClassificationClickedObject?.text);

      if (!this.disableSecondaryTopicSelection) {
        this.setSecondaryTopics(caseClassificationClickedObject?.secondaryTopics);
      }
    } else { // clicked on other
      const customPrimaryTopic = await this.fruitSaladUiService.openCaseClassificationFruitSalad(CaseFruitSaladaSuggestionType.PRIMARY);

      if (customPrimaryTopic) {
        this.selectedCustomPrimaryTopic = true;
        this.changeCaseClassificationTagsObject(customPrimaryTopic, '');
        this.setPrimaryTopic(customPrimaryTopic);
        this.primaryTopic = customPrimaryTopic;
      }
    }
  }

  async secondaryChipClicked(caseClassificationClickedObject: CaseClassificationClickedObject): Promise<void> {

    // click was performed on selected secondary tag
    // tag should be deselected or if tag is custom classification should restart
    if (!caseClassificationClickedObject?.text || caseClassificationClickedObject?.text === this.selectedSecondaryTopic) {
      this.setSecondaryTopic('');

      if (this.selectedCustomSecondaryTopic) {

        this.secondaryTopic = '';
        this.selectedCustomSecondaryTopic = false;

        const tagsObject = this.caseClassificationTagsServerValue.find(tagObj => tagObj.primaryTopicName === this.selectedPrimaryTopic);

        if (tagsObject) {
          this.setSecondaryTopics(tagsObject.secondaryTopics);
        }
      } else {
        this.caseClassificationTagsUiService.deselectTag(this.secondaryTagsGroupId, caseClassificationClickedObject?.text);
      }
      return;
    }

    const customTagText = await this.getCustomTagText();

    // click was performed on not selected secondary tag
    // tag should become selected
    if (caseClassificationClickedObject?.text !== customTagText) {
      this.setSecondaryTopic(caseClassificationClickedObject?.text);
      this.caseClassificationTagsUiService.selectTag(this.secondaryTagsGroupId, caseClassificationClickedObject?.text);
    } else { // clicked on other
      const customSecondaryTopic = await this.fruitSaladUiService.
        openCaseClassificationFruitSalad(CaseFruitSaladaSuggestionType.SECONDARY, this.selectedPrimaryTopic);

      if (customSecondaryTopic) {
        this.selectedCustomSecondaryTopic = true;
        this.changeCaseClassificationTagsObject('', customSecondaryTopic);
        this.setSecondaryTopic(customSecondaryTopic);
        this.secondaryTopic = customSecondaryTopic;
        this.caseClassificationTagsUiService.selectTag(this.secondaryTagsGroupId, customSecondaryTopic);
      }
    }
  }

  changeCaseClassificationTagsObject(newPrimaryTopic: string, newSecondaryTopic: string): void {
    if (newPrimaryTopic) {
      this.setCaseClassificationTags([{
        primaryTopicName: newPrimaryTopic,
        secondaryTopics: null,
        custom: true
      }]);

    } else if (newSecondaryTopic) {

      if (!this.selectedPrimaryTopic) {
        console.error('There is no primary topic selected');
        return;
      }

      const caseClassificationTagObject = this.caseClassificationTags.find(obj => obj.primaryTopicName === this.selectedPrimaryTopic);

      if (!caseClassificationTagObject) {
        console.error('There is no object for selected topic');
        return;
      }

      setTimeout(() => {
        caseClassificationTagObject.secondaryTopics = [];
        caseClassificationTagObject.secondaryTopics.push(newSecondaryTopic);
        this.setSecondaryTopics(caseClassificationTagObject.secondaryTopics);
      }, 0);
    }
  }

  setPrimaryTopic(topic: string): void {

    if (this.selectedPrimaryTopic === topic) {
      return;
    }

    this.selectedPrimaryTopic = topic;
    this.emitCaseClassificationTagsChanged();
  }

  initPrimaryTopic(topic: string): void {

    if (!topic) {
      return;
    }

    setTimeout(() => {

      let promise: Promise<any> = Promise.resolve();

      if (!this.caseClassificationTags) {
        promise = this.fetchCaseClassificationTags();
      }

      promise.then(() => {
        const caseClassificationTagObject: CaseClassificationTagsObject = this.caseClassificationTags
          .find(classificationObject => classificationObject.primaryTopicName === topic);

        if (!caseClassificationTagObject) {
          this.selectedCustomPrimaryTopic = true;
          this.changeCaseClassificationTagsObject(topic, '');
        }

        this.selectedPrimaryTopic = topic;
        this.caseClassificationTagsUiService.selectTag(this.primaryTagsGroupId, this.selectedPrimaryTopic);
        this.setSecondaryTopics(caseClassificationTagObject?.secondaryTopics);
        this.initSecondaryTopic(this.secondaryTopic);
      });
    }, 0);
  }

  setSecondaryTopic(topic: string): void {

    if (this.selectedSecondaryTopic === topic) {
      return;
    }

    this.selectedSecondaryTopic = topic;
    this.emitCaseClassificationTagsChanged();
  }

  initSecondaryTopic(topic: string): void {

    setTimeout(() => {

      let secondaryTopicIndex: number;

      if (this.secondaryTopics && topic) {
        secondaryTopicIndex = this.secondaryTopics.findIndex(secondaryTopic => secondaryTopic === topic);
      }

      if (secondaryTopicIndex < 0) {
        this.selectedCustomSecondaryTopic = true;
      }

      if (!topic || !this.secondaryTagsGroupId || !this.secondaryTagsRendered || !this.secondaryTopics) {

        if (topic) {
          this.selectedSecondaryTopic = topic;
        }

        return;
      }

      if (secondaryTopicIndex < 0) {
        this.changeCaseClassificationTagsObject('', topic);
      }

      this.setSecondaryTopic(topic);
      this.caseClassificationTagsUiService.selectTag(this.secondaryTagsGroupId, this.selectedSecondaryTopic);
    }, 0);
  }

  setSecondaryTopics(topics: Array<string>): void {
    this.secondaryTopics = topics;
  }

  emitCaseClassificationTagsChanged(): void {
    this.caseClassificationTagsChanged.emit({
      classificationLevelOne: this.selectedPrimaryTopic,
      classificationLevelTwo: this.selectedSecondaryTopic
    });
  }

  primaryTopicChipsRenderedCount(primaryTopicsChipsRenderedCount: number): void {
    if (
      this.caseClassificationTags?.length &&
      primaryTopicsChipsRenderedCount > 0 &&
      primaryTopicsChipsRenderedCount === this.caseClassificationTags?.length + (this.selectedCustomPrimaryTopic ? 0 : 1)
    ) {
      this.primaryTagsRendered = true;
      this.initPrimaryTopic(this.primaryTopic);
    }
  }

  secondaryTopicChipsRenderedCount(secondaryTopicsChipsRenderedCount: number): void {

    const secondaryTopicsLength = this.secondaryTopics?.length || 0;

    if (
      this.caseClassificationTags?.length &&
      secondaryTopicsLength &&
      secondaryTopicsChipsRenderedCount > 0 &&
      secondaryTopicsChipsRenderedCount === secondaryTopicsLength + (this.selectedCustomSecondaryTopic ? 0 : 1) ||
      (this.selectedCustomSecondaryTopic && secondaryTopicsChipsRenderedCount === 0)
    ) {
      this.secondaryTagsRendered = true;
      this.initSecondaryTopic(this.secondaryTopic);
    }
  }

  get primaryTopicSelected(): boolean {
    return this.primaryTopic && this.primaryTopic?.length > 0;
  }

  get secondaryTopicSelected(): boolean {
    return this.secondaryTopic && this.secondaryTopic?.length > 0;
  }

  get showProgressCountBadge(): boolean {
    return !this.secondaryTopicSelected;
  }

  private async getCustomTagText(): Promise<string> {
    return this.appTranslationService.get('app.CaseClassificationTags.other');
  }

}
