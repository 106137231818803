import {Component, Input} from '@angular/core';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-translate-button',
  templateUrl: './translate-button.component.html',
  styleUrls: ['./translate-button.component.scss'],
})
export class TranslateButtonComponent extends BaseButtonComponent<void> {

  @Input() isTranslated: boolean;
  @Input() isPostButton = false;
  @Input() showInlineButton = true;
}
