export enum ParentType {
  GROUP = 'Group',
  EVENT = 'Event',
  CHAT = 'Chat', // private
  USER = 'User', // public

}

export interface GroupShort {
  title: string;
  appId?: string;
  visibility: string;
  imageUrl?: string;
  totalMembersCount: number;
  _id: string;
  deleted?: boolean;
  createdDate?: string;
  description?: string;
  status?: string;
}
