// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --qr-code-dialog-padding-top: 30px;
  --qr-code-dialog-padding-left: 30px;
  --qr-code-dialog-padding-right: 69px;
  --qr-code-dialog-padding-bottom: 20px;
  --qr-code-dialog-border-radius: 8px;
}

.qr-code-wrapper {
  background-color: var(--mi-white-color);
  border-radius: var(--qr-code-dialog-border-radius);
  margin-left: 20px;
}
.qr-code-wrapper app-icon {
  --icon-size: 16px;
  padding-top: 18px;
  padding-right: 12px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.qr-code-wrapper .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--qr-code-dialog-padding-top) var(--qr-code-dialog-padding-left);
  padding-bottom: var(--qr-code-dialog-padding-bottom);
  padding-right: var(--qr-code-dialog-padding-right);
}
.qr-code-wrapper .content .title {
  color: var(--mi-primary-color);
  font-size: var(--font-size-large);
  font-weight: bold;
  font-style: normal;
  line-height: var(--font-line-height-regular);
}
.qr-code-wrapper .content .description {
  padding: 20px 0px;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  line-height: var(--font-line-height-regular);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
