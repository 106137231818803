/**
 * taken from: https://github.com/wbhob/ionic-long-press/blob/master/src/directives/long-press.directive.ts
 */
import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core'
import * as Hammer from 'hammerjs'

const LONG_PRESS_DEFAULT_TIMEOUT = 500;

const MIN_LONG_PRESS_TIMEOUT = 40;

@Directive({
  /* eslint-disable */
  selector: '[ion-long-press]',
  /* eslint-enable */
})
export class LongPressDirective implements OnInit, OnDestroy {
  @Input() interval: number

  @Output() pressed: EventEmitter<any> = new EventEmitter()
  @Output() longPressed: EventEmitter<any> = new EventEmitter()
  @Output() pressEnded: EventEmitter<any> = new EventEmitter()

  private readonly el: HTMLElement
  private _hammer: HammerManager
  private int: number

  constructor(public zone: NgZone, el: ElementRef) {
    this.el = el.nativeElement
  }

  ngOnInit(): void {
    if (!this.interval){
      this.interval = LONG_PRESS_DEFAULT_TIMEOUT;
    }
    if (this.interval < MIN_LONG_PRESS_TIMEOUT) {
      throw new Error('A limit of 40ms is imposed so you don\'t destroy device performance.')
    }

    this._hammer = new Hammer.Manager(this.el, {
      recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_VERTICAL }], [Hammer.Press], [Hammer.Tap]],
    })

    this._hammer.on('pan', (_e: any) => {
      this.onPressEnd()
    })

    this._hammer.on('press', (e: any) => {
      this.pressed.emit(e)
      this.clearInt()
      this.int = setInterval(() => {
        this.longPressed.emit()
      }, this.interval) as any
    })

    this._hammer.on('pressup', (_e: any) => {
      this.onPressEnd()
    })

    this._hammer.on('release', (_e: any) => {
      this.onPressEnd()
    })

    this.el.addEventListener('mouseleave', (_e: any) => {
      this.onPressEnd()
    })

    this.el.addEventListener('mouseout', (_e: any) => {
      this.onPressEnd()
    })
  }

  clearInt(): void {
    if (this.int !== undefined) {
      clearInterval(this.int)
      this.int = undefined
    }
  }

  onPressEnd(): void {
    this.clearInt()
    this.pressEnded.emit()
  }

  ngOnDestroy(): void {
    this.onPressEnd()
    if (this._hammer) {
      this._hammer.destroy()
      this._hammer = null
    }
  }
}
