// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --icon-button-background-width: 22px;
  --icon-button-background-height: 22px;
  --icon-button-background-color: var(--mi-color-text-lite);
  --icon-button-background-paddng: 5px;
  --icon-button-border-radius: 50%;
  --icon-button-icon-size: 12px;
  --icon-button-icon-color: var(--mi-white-color);
}

.icon-button-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
}
.icon-button-container button span {
  width: var(--icon-button-background-width);
  height: var(--icon-button-background-height);
  padding: var(--icon-button-background-paddng);
  background-color: var(--icon-button-background-color);
  border-radius: var(--icon-button-border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-button-container button span app-icon {
  --icon-size: var(--icon-button-icon-size);
  color: var(--icon-button-icon-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
