// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectable-group-list-item-container .group-item {
  width: 100%;
  display: grid;
  grid-template-columns: min-content minmax(0, 100%) min-content;
  grid-template-rows: auto;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
}
.selectable-group-list-item-container .group-item .group-image-container {
  grid-row: 1;
  grid-column: 1;
}
.selectable-group-list-item-container .group-item .group-image-container app-group-image {
  --group-image-size: 40px;
  --group-image-border-radius: 7px;
}
.selectable-group-list-item-container .group-item .group-info-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  align-items: center;
}
.selectable-group-list-item-container .group-item .group-info-container .group-title-container {
  width: 100%;
  height: 18.5px;
  position: relative;
}
.selectable-group-list-item-container .group-item .group-info-container .group-title-container .wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.selectable-group-list-item-container .group-item .group-info-container .group-title-container .wrapper .group-title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-black-color);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectable-group-list-item-container .group-item .group-info-container .group-visibility-container .group-visibility {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selectable-group-list-item-container .group-item .checkbox-container {
  grid-row: 1;
  grid-column: 3;
}
.selectable-group-list-item-container .group-item .checkbox-container ion-checkbox {
  --background-checked: var(--mi-primary-color);
  --border-color-checked: var(--mi-primary-color);
  --checkmark-color: var(--mi-white-color);
}
.selectable-group-list-item-container .group-item.disable-item {
  pointer-events: none;
  opacity: 0.5;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
