import { AOVideo, Article, Video } from 'src/app/services/yeti-protocol/article';
import { MyStreamSuccessResponse, ResponseStreamItem } from 'src/app/services/yeti-protocol/my-stream';

export namespace MyKnowledgeStream {
  export interface FetchStreamItemsPayload {
    filter?: string;
    pageIndex?: number;
    pageSize?: number;
    published?: boolean;
    loadSmartStream?: boolean;
  }

  export class FetchMyKnowledgeStreamItems {
    static readonly type = '[My Knowledge Stream] Fetch my knowledge stream items';
    constructor(public payloadParams: FetchStreamItemsPayload) { }
  }

  export class FetchMyKnowledgeStreamItemsSuccess {
    static readonly type = '[My Knowledge Stream] Fetch my knowledge stream items success';
    constructor(
      public payloadParams: FetchStreamItemsPayload,
      public response: MyStreamSuccessResponse
    ) { }
  }

  export class FetchMyKnowledgeStreamItemsFailed {
    static readonly type = '[My Knowledge Stream] Fetch my knowledge stream items failed';
  }

  export class InsertMyKnowledgeStreamItemBeforeIndex {
    static readonly type = '[My Knowledge Stream] Insert my knowledge stream item before index';
    constructor(
      public item: ResponseStreamItem,
      public index: number
    ) { }
  }

  export class RemoveMyKnowledgeStreamItem {
    static readonly type = '[My Knowledge Stream] Remove my knowledge stream item at index';
    constructor(
      public item: ResponseStreamItem
    ) { }
  }

  export class UpdateMyKnowledgeStreamItem {
    static readonly type = '[My Knowledge Stream] Update my knowledge stream item';
    constructor(
      public item: Article | Video | AOVideo
    ) { }
  }
}
