import { Component } from '@angular/core';

@Component({
  selector: 'app-events-list-skeleton',
  templateUrl: './events-list-skeleton.component.html',
  styleUrls: ['./events-list-skeleton.component.scss'],
})
export class EventsListSkeletonComponent {
  skeletonList: Array<any> = new Array(5);
}
