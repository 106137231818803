import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewEncapsulation,
  OnDestroy,
  Output,
  EventEmitter,
  NgZone
} from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subscription, debounceTime, filter } from 'rxjs';

export enum InfoSheetVisibilityStatus {
  CLOSED = 'closed',
  OPEN = 'OPEN'
}

export interface InfoSheetVisibilityStatusChange {
  infoSheetId: string;
  status: InfoSheetVisibilityStatus;
}

// models
import { InfoSheet } from '../../models/info-sheet.model';

// services
import {
  InfoSheetService
} from '../../services/info-sheet.service';

@Component({
  selector: 'app-info-sheet',
  templateUrl: './info-sheet.component.html',
  styleUrls: ['./info-sheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoSheetComponent implements OnInit, OnDestroy, InfoSheet {

  @Input() id: string;
  @Input() preventClickTapOutsideToClose: boolean;
  @Input() preventEscapeToClose: boolean;
  @Input() title: string;
  @Input() closeOnActionClick: boolean;
  @Input() hideOverlay: boolean;
  @Input() customTitleButton: boolean;
  @Input() customClass;
  @Input() preventOnNavClose: boolean;
  @Input() transparentOverlay: boolean;

  @Output() infoSheetVisibilityStatusChanged: EventEmitter<InfoSheetVisibilityStatusChange> = new EventEmitter();

  renderElement: boolean;

  private routerEventsSubscription: Subscription;
  private element: any;
  private prevUrl: string;

  constructor(
    private infoSheetService: InfoSheetService,
    private el: ElementRef,
    private router: Router,
    private zone: NgZone
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('info sheet must have an id');
      return;
    }

    // add self (this action sheet instance) to the action sheet service so it's accessible from controllers
    this.infoSheetService.add(this);

    this.setupClickListenerForClosingDialog();

    this.routerEventsSubscription = this.router.events.pipe(
      filter((event: Event | RouterEvent | any) => event instanceof NavigationStart),
      debounceTime(300),
    ).subscribe((event: Event | RouterEvent | any) => {
      this.zone.run(() => {

        if (!this.prevUrl) {
          this.prevUrl = event?.url;
        }

        this.onRouteChange(event);
      });
    });
  }

  // remove self from action sheet service when component is destroyed
  ngOnDestroy(): void {
    this.infoSheetService.remove(this.id);
    this.element.remove();
    this.routerEventsSubscription?.unsubscribe();
  }

  // open action sheet
  open(): void {
    this.renderElement = true;
  }

  _open(): void {
    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    this.changeElementMaxHeight();
    this.element.classList.add('show');
    document.body.classList.add('info-sheet-open');

    if (this.element?.firstChild?.focus) {
      this.element?.firstChild?.focus();
    }

    this.emitVisibilityStatusChange(true);
  }

  // close action sheet
  close(): void {
    this.element.classList.remove('show');
    document.body.classList.remove('info-sheet-open');

    setTimeout(() => {
      this.renderElement = false;
      this.element?.parentNode?.removeChild(this.element);
      this.emitVisibilityStatusChange();
    }, 300);
  }

  _close(): void {
    this.infoSheetService.close(this.id);
  }

  setupClickListenerForClosingDialog(): void {

    if (this.preventClickTapOutsideToClose) {
      return;
    }

    // close action sheet on background click
    this.element.addEventListener('click', el => {
      if (el.target.className === 'info-sheet-background' ||
        el.target.className === 'info-sheet-background transparent') {
        this._close();
      }
    });
  }

  escapePressed(): void {
    if (this.preventEscapeToClose) {
      return;
    }

    this._close();
  }

  getCloseOnActionClick(): boolean {
    return this.closeOnActionClick;
  }

  emitVisibilityStatusChange(open?: boolean): void {
    const status: InfoSheetVisibilityStatus = open ?
      InfoSheetVisibilityStatus.OPEN :
      InfoSheetVisibilityStatus.CLOSED;

    const infoSheetVisibilityStatus: InfoSheetVisibilityStatusChange = {
      infoSheetId: this.id,
      status: status
    }

    this.infoSheetVisibilityStatusChanged.emit(infoSheetVisibilityStatus);
  }

  async onRouteChange(event: Event | RouterEvent | any): Promise<void> {

    if (!event?.url) {
      return;
    }

    if (!this.preventOnNavClose) {

      if (event?.url?.split('?')?.[1] === 'tab=my-groups-feed' && this.prevUrl?.split('?')?.[0] === event?.url?.split('?')?.[0]) {

        if (event?.url) {
          this.prevUrl = event?.url;
        }

        return;
      }

      this._close();
    }

    if (event?.url) {
      this.prevUrl = event?.url;
    }
  }

  public changeElementMaxHeight(): void {
    const titleContainer = this.el.nativeElement.querySelector('.title-container');
    const contentContainer = this.el.nativeElement.querySelector('.content-container');
    const infoSheet = this.el.nativeElement.querySelector('.info-sheet');

    if (titleContainer && contentContainer && infoSheet) {
      contentContainer.style = [
        `max-height: calc(${document.body.clientHeight * 0.8}px - calc(var(--info-sheet-padding-top)`,
        ` + var(--info-sheet-padding-bottom) + var(--info-sheet-content-grid-row-gap) + ${titleContainer.clientHeight}px));`
      ].join('');
    }
  }

}
