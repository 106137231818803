// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --section-title-font-size: var(--font-size-regular);
  --section-title-font-weight: var(--font-weight-medium);
  --section-title-font-color: var(--mi-secondary-color-1);
  --section-title-bottom-padding: 10px;
}

.event-participants-card-container {
  width: 100%;
  height: 100%;
}
.event-participants-card-container .section-title {
  font-size: var(--section-title-font-size);
  font-weight: var(--section-title-font-weight);
  color: var(--section-title-font-color);
  padding-bottom: var(--section-title-bottom-padding);
  text-align: left;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-participants-card-container .event-participants-card .card-content {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.event-participants-card-container .event-participants-card .card-content .section-title-container {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
  padding-bottom: var(--section-title-bottom-padding);
}
.event-participants-card-container .event-participants-card .card-content .section-title-container.with-action {
  grid-template-columns: auto min-content;
}
.event-participants-card-container .event-participants-card .card-content .section-title-container .title-wrapper {
  grid-row: 1;
  grid-column: 1;
}
.event-participants-card-container .event-participants-card .card-content .section-title-container .title-wrapper .section-title {
  padding-bottom: 0;
}
.event-participants-card-container .event-participants-card .card-content .section-title-container .actions-container {
  grid-row: 1;
  grid-column: 2;
}
.event-participants-card-container .event-participants-card .card-content .section-title-container .actions-container .see-all-button {
  background: none;
  border: none;
  outline: none;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-color-text-lite);
  white-space: nowrap;
}
.event-participants-card-container .event-participants-card .card-content .participants-list-container {
  padding-bottom: 30px;
}
.event-participants-card-container .event-participants-card .card-content .related-group-container {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  padding: 17px;
  background-color: var(--mi-light-purple-1-color);
  margin-bottom: 22px;
}
.event-participants-card-container .event-participants-card .card-content .related-group-container .group-image-container {
  grid-row: 1;
  grid-column: 1;
}
.event-participants-card-container .event-participants-card .card-content .related-group-container .group-image-container app-group-image {
  --group-image-size: 75px;
  --group-image-border-radius: 7px;
}
.event-participants-card-container .event-participants-card .card-content .related-group-container .group-info-container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content auto;
  align-items: start;
  height: 100%;
}
.event-participants-card-container .event-participants-card .card-content .related-group-container .group-info-container .group-title-container {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  overflow: hidden;
}
.event-participants-card-container .event-participants-card .card-content .related-group-container .group-info-container .group-title-container .group-title {
  width: 100%;
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-regular);
  text-align: left;
  color: var(--mi-secondary-color-2);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-participants-card-container .event-participants-card .card-content .related-group-container .group-info-container .group-participants-count-container {
  grid-row: 2;
  grid-column: 1;
  width: 100%;
  overflow: hidden;
}
.event-participants-card-container .event-participants-card .card-content .related-group-container .group-info-container .group-participants-count-container .group-participants-count {
  width: 100%;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  text-align: left;
  color: var(--mi-color-text-lite);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-participants-card-container .event-participants-card .card-content .related-group-container .group-info-container .group-action-container {
  grid-row: 3;
  grid-column: 1;
  align-self: end;
}
.event-participants-card-container .event-participants-card .card-content .documents-list-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
