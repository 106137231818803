import {ImageAttachment, isVideo} from '../attachments.model';

export function getImagesWithCorrectUrlBasedOnWatermark(images: Array<ImageAttachment>, isWatermarked: boolean): Array<ImageAttachment> {
  if (!images?.length) {
    return [];
  }

  if (isWatermarked) {
    return images.map(img => {
      return {
        ...img,
        videoUrl: isVideo(img.mimeType) ? img?.fullUrl : null,
        fullUrl: isVideo(img.mimeType) ? img.watermarkedPreviewUrl : img?.fullWatermarkUrl,
        previewUrl: img?.watermarkedPreviewUrl,
      };
    })
  } else {
    return images.map(img => ({
      ...img,
      videoUrl: isVideo(img.mimeType) ? img?.fullUrl : null
    }))
  }
}
