import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ClinicalCasesStateModel } from './clinical-cases.model';
import { Injectable } from '@angular/core';
import { ClinicalCases } from './clinical-cases.actions';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import {
  CasesType,
  ClinicalCase,
  GetClinicalCasesResponse,
  GetClinicalCasesSuccessResponse
} from 'src/app/services/yeti-protocol/clinical-case';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { CLINICAL_CASES_STATE_KEY } from '../state-constants';

const CLINICAL_CASES_STATE_TOKEN = new StateToken<ClinicalCasesStateModel>(
  CLINICAL_CASES_STATE_KEY
);

@State({
  name: CLINICAL_CASES_STATE_TOKEN,
  defaults: {
    clinicalCases: [],
    filters: [],
    totalCount: 0,
    totalInReviewCount: 0,
    loading: false,
    initialState: true,
    inReviewInitialState: true
  },
})
@Injectable()
export class ClinicalCasesState {
  constructor(private clinicalCaseService: ClinicalCaseService) { }

  @Selector()
  static state(state: ClinicalCasesStateModel): ClinicalCasesStateModel {
    return state;
  }

  @Selector()
  static clinicalCases(state: ClinicalCasesStateModel): Array<ClinicalCase> {
    return state.clinicalCases;
  }

  @Selector()
  static filters(state: ClinicalCasesStateModel): Array<string> {
    return state.filters;
  }

  @Selector()
  static totalCount(state: ClinicalCasesStateModel): number {
    return state.totalCount;
  }

  @Selector()
  static totalInReviewCount(state: ClinicalCasesStateModel): number {
    return state.totalInReviewCount;
  }

  @Selector()
  static loading(state: ClinicalCasesStateModel): boolean {
    return state.loading;
  }

  @Action(ClinicalCases.FetchClinicalCases)
  async fetchClinicalCases(
    ctx: StateContext<ClinicalCasesStateModel>,
    payload: ClinicalCases.FetchClinicalCases
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      let clinicalCases: GetClinicalCasesResponse;

      if (payload?.payloadParams?.type === CasesType.TO_REVIEW) {
        clinicalCases = (await this.clinicalCaseService.getCasesToReview(
          payload?.payloadParams?.pageIndex,
          payload?.payloadParams?.pageSize,
        )) as GetClinicalCasesSuccessResponse;
      } else {
        clinicalCases = (await this.clinicalCaseService.getCases(
          payload?.payloadParams?.pageIndex,
          payload?.payloadParams?.pageSize,
          payload?.payloadParams?.type,
          payload?.payloadParams?.topicFilter
        )) as GetClinicalCasesSuccessResponse;
      }

      ctx.dispatch(
        new ClinicalCases.FetchClinicalCasesSuccess(
          payload?.payloadParams,
          clinicalCases
        )
      );
    } catch (err) {
      ctx.dispatch(
        new ClinicalCases.FetchClinicalCasesFailed()
      );

      throw err;
    }
  }

  @Action(ClinicalCases.FetchClinicalCasesSuccess)
  fetchClinicalCasesSuccess(
    ctx: StateContext<ClinicalCasesStateModel>,
    action: ClinicalCases.FetchClinicalCasesSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.type === CasesType.TO_REVIEW) {
      if (action.payloadParams.pageIndex === 0) {
        ctx.patchState({
          clinicalCases: action.response.result,
          filters: action.response.meta?.topicFilter,
          totalCount: action.response.totalItemsCount,
          totalInReviewCount: action.response.totalItemsCount,
          loading: false,
          inReviewInitialState: false
        });
      } else {
        ctx.patchState({
          clinicalCases: [...state.clinicalCases, ...action.response.result],
          filters: action.response.meta?.topicFilter,
          totalCount: action.response.totalItemsCount,
          totalInReviewCount: action.response.totalItemsCount,
          loading: false,
          inReviewInitialState: false
        });
      }
    } else {
      if (action.payloadParams.pageIndex === 0) {
        ctx.patchState({
          clinicalCases: action.response.result,
          filters: action.response.meta?.topicFilter,
          totalCount: action.response.totalItemsCount,
          loading: false,
          initialState: false
        });
      } else {
        ctx.patchState({
          clinicalCases: [...state.clinicalCases, ...action.response.result],
          filters: action.response.meta?.topicFilter,
          totalCount: action.response.totalItemsCount,
          loading: false,
          initialState: false
        });
      }
    }
  }

  @Action(ClinicalCases.FetchClinicalCasesFailed)
  fetchClinicalCasesFailed(
    ctx: StateContext<ClinicalCasesStateModel>
  ): void {
    ctx.patchState({
      loading: false,
    });
  }

  @Action(ClinicalCases.InsertClinicalCaseBeforeIndex)
  insertClinicalCaseBeforeIndex(ctx: StateContext<ClinicalCasesStateModel>, action: ClinicalCases.InsertClinicalCaseBeforeIndex): void {
    ctx.setState(
      patch<ClinicalCasesStateModel>({
        clinicalCases: insertItem<ClinicalCase>(action.clinicalCase, action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount + 1,
    });
  }

  @Action(ClinicalCases.RemoveClinicalCase)
  removeClinicalCase(ctx: StateContext<ClinicalCasesStateModel>, action: ClinicalCases.RemoveClinicalCase): void {
    ctx.setState(
      patch<ClinicalCasesStateModel>({
        clinicalCases: removeItem<ClinicalCase>(action.index)
      })
    );

    const state = ctx.getState();

    ctx.patchState({
      totalCount: state.totalCount - 1,
    });
  }

  @Action(ClinicalCases.UpdateClinicalCase)
  updateClinicalCase(ctx: StateContext<ClinicalCasesStateModel>, action: ClinicalCases.UpdateClinicalCase): void {
    ctx.setState(
      patch<ClinicalCasesStateModel>({
        clinicalCases: updateItem<ClinicalCase>(clinicalCase => clinicalCase._id === action.clinicalCase._id, action.clinicalCase)
      })
    );
  }

  @Action(ClinicalCases.UpdateTotalInReviewCount)
  updateTotalInReviewCount(ctx: StateContext<ClinicalCasesStateModel>, action: ClinicalCases.UpdateTotalInReviewCount): void {
    ctx.setState(
      patch<ClinicalCasesStateModel>({
        totalInReviewCount: action.totalInReviewCount
      })
    );
  }

  @Action(ClinicalCases.FetchInReviewClinicalCasesTotalCount)
  async fetchInReviewClinicalCasesTotalCount(
    ctx: StateContext<ClinicalCasesStateModel>
  ): Promise<void> {
    try {
      const clinicalCases = (await this.clinicalCaseService.getCasesToReview(
        0,
        1,
      )) as GetClinicalCasesSuccessResponse;

      ctx.dispatch(
        new ClinicalCases.FetchInReviewClinicalCasesTotalCountSuccess(
          clinicalCases
        )
      );
    } catch (err) {
      ctx.dispatch(
        new ClinicalCases.FetchClinicalCasesFailed()
      );

      throw err;
    }
  }

  @Action(ClinicalCases.FetchInReviewClinicalCasesTotalCountSuccess)
  fetchInReviewClinicalCasesTotalCountSuccess(
    ctx: StateContext<ClinicalCasesStateModel>,
    action: ClinicalCases.FetchClinicalCasesSuccess
  ): void {
    ctx.patchState({
      totalInReviewCount: action.response.totalItemsCount
    });
  }
}
