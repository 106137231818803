import { Inject, Pipe, PipeTransform } from '@angular/core';

// services
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from '../services/utils/ui-utils.service.interface';

export interface FromSecondsMessengerPipeData {
  age: string;
  date: string;
}

@Pipe({
  name: 'fromSecondsMessenger'
})
export class FromSecondsMessengerPipe implements PipeTransform {

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  async transform(fromSecondsMessengerPipeData: FromSecondsMessengerPipeData, ..._args: unknown[]): Promise<string> {

    if (!fromSecondsMessengerPipeData) {
      return '';
    }

    const seconds = this.uiUtilsService.getTimeAgoFromDateInSeconds(fromSecondsMessengerPipeData.date);
    return this.uiUtilsService.mapSecondsToTimeAgoMessenger(seconds, fromSecondsMessengerPipeData.date);
  }
}
