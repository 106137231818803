import {Component, Inject, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TRACKING_SERVICE, TrackingService} from '../../services/tracking/tracking.model';

@Component({
  selector: 'app-upload-ppt-response-state-dialog',
  templateUrl: './upload-ppt-response-state-dialog.component.html',
  styleUrls: ['./upload-ppt-response-state-dialog.component.scss'],
})
export class UploadPptResponseStateDialogComponent implements OnInit {

  @Input() isSuccess: boolean;

  constructor(
    private modalController: ModalController,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) {
  }

  ngOnInit(): void {
    this.trackResponseStateDialogOpened();
  }

  closeDialog(): void {
    this.trackActionClicks('closeModal', 'modalLoaded', this.source);
    this.modalController.dismiss(null, 'cancel');
  }

  trackActionClicks(objectId: string, objectType: string, objectTitle: string): void {
    this.trackingService
      .trackGenericClickedAction(objectId, objectType, objectTitle, {
        source: this.source,
      })
      .catch(_err => {
        console.error('Could not track content created click action.');
      });
  }

  private trackResponseStateDialogOpened(): void {
    this.trackingService
      .trackGenericModalOpenedAction('pptUploaded', this.source)
      .catch(_err => {
        console.error('Could not track ppt response state dialog loaded action.');
      });
  }

  get source(): string {
    return this.isSuccess ? 'pptUploadedSuccess' : 'pptUploadedFailed';
  }

}
