// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  box-shadow: unset;
}

.first-card {
  height: 180px;
}
.first-card .first-card-content {
  padding: 15px 10px 10px 0px;
}
.first-card .first-card-content .ao-member-symbol-component {
  position: absolute;
  top: 70px;
  left: 40px;
  z-index: 2;
}
.first-card .first-card-content .profile-image {
  height: 80px;
  width: 70px;
  margin-right: 10px;
}
.first-card .first-card-content app-user-profile-image {
  --user-image-size: 70px;
  --ao-icon-size: 30px;
}
.first-card .first-card-content .first-last-name {
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.first-card .buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 8px;
}
.first-card .buttons-container ion-button.ion-activated {
  --background-activated: transparent;
}
.first-card .buttons-container .button {
  width: 146px;
  height: 47px;
  --border-radius: 5px;
}
.first-card .buttons-container .edit-button {
  --background: var(--mi-white-color);
  color: var(--mi-primary-color);
  border: 1px var(--mi-primary-color) solid;
  text-transform: none;
  border-radius: 5px;
}
.first-card .buttons-container .verify-button {
  --background: var(--mi-primary-color);
  text-transform: none;
}
.first-card .buttons-container .verified-button {
  --background: var(--mi-success);
  opacity: 100%;
  text-transform: none;
}
.first-card .buttons-container .verified-button .verified-button-text {
  margin-right: 5px;
  margin-top: unset;
  margin-left: unset;
  margin-bottom: unset;
  font-size: unset;
}
.first-card .buttons-container .verification-pending {
  width: 164px;
  color: var(--mi-secondary-color-2);
  --background: var(--mi-inputs-color);
  text-transform: none;
}
.first-card ion-label .email {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
