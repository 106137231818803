import { ContentType } from 'src/app/services/yeti-protocol/surgery-reference-schema';


export function cardType(type: ContentType): string {
  switch(type) {
    case ContentType.DIAGNOSIS:
      return 'Fracture';
    case ContentType.PROCEDURE:
      return 'Treatment';
    case ContentType.APPROACH:
      return ContentType.APPROACH;
    case ContentType.PREPARATION:
      return ContentType.PREPARATION;
  }
}
