import { Component, EventEmitter, Input, Output } from '@angular/core';

// models
import { VerificationDocumentType } from 'src/app/services/verification.model';

@Component({
  selector: 'app-verification-step-two',
  templateUrl: './verification-step-two.component.html',
  styleUrls: ['./verification-step-two.component.scss'],
})
export class VerificationStepTwoComponent {

  @Input() verificationDocumentType: VerificationDocumentType;

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() selectFileClicked: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  VerificationDocumentType = VerificationDocumentType;

  navigateBack(): void {
    this.back.emit();
  }

  triggerSelectFile(): void {
    this.selectFileClicked.emit();
  }

  closeButtonClicked(): void {
    this.close.emit();
  }

}
