import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

// models
import { Group } from 'src/app/services/groups/group.model';

// services
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import {ActionSource} from '../../services/yeti-protocol/tracking';

@Component({
  selector: 'app-top-recommended-group',
  templateUrl: './top-recommended-group.component.html',
  styleUrls: ['./top-recommended-group.component.scss'],
})
export class TopRecommendedGroupComponent {

  @Input() group: Group;
  @Input() recommendedBy: string;
  @Input() source: ActionSource | string;

  @Output() groupOpenedEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  openGroupPage(): void {
    if(!this.group._id) {
      return;
    }
    let linkToRedirect = '';
    linkToRedirect = [this.contextService.currentContext.homePath, 'group', this.group._id].join('/');
    linkToRedirect = linkToRedirect + '?source=' + this.source || ActionSource.recommendations;
    if(this.recommendedBy) {
      linkToRedirect = linkToRedirect + '&recommendedBy=' + this.recommendedBy;
    }
    this.router.navigateByUrl('/' + linkToRedirect);
    // this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/group/${this.group._id}`);
    this.groupOpenedEvent.emit();
  }
}
