import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContextConfigInterface } from '../../../config/config.model';

@Component({
  selector: 'app-contexts-list',
  templateUrl: './contexts-list.component.html',
  styleUrls: ['./contexts-list.component.scss'],
})
export class ContextsListComponent {

  @Input() contexts: Array<ContextConfigInterface> = new Array<ContextConfigInterface>();
  @Input() selectedContextKey: string = null;
  @Input() title: string = null;
  @Input() subtitle: string = null;
  @Input() iconAtEnd: string = null;

  @Output() contextClick: EventEmitter<ContextConfigInterface> = new EventEmitter();

  contextClicked(contextKey: string): void {
    const context = this.contexts.find(ctx => ctx.key === contextKey);

    if(context) {
      this.contextClick.emit(context);
    }
  }
}
