import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Journal } from 'src/app/services/yeti-protocol/journal';

// services
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';

@Component({
  selector: 'app-journals-list-item',
  templateUrl: './journals-list-item.component.html',
  styleUrls: ['./journals-list-item.component.scss'],
})
export class JournalsListItemComponent {
  @Input() item: Journal;
  @Output() openJournal: EventEmitter<string> = new EventEmitter();
  @Output() toggleJournalFollowing: EventEmitter<Journal> = new EventEmitter();

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  onToggleJournalFollowing(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.toggleJournalFollowing.emit(this.item);
  }

  onOpenJournalPage(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.openJournal.emit(this.item.id);
  }

}
