import { Inject, Pipe, PipeTransform } from '@angular/core';

// services
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from '../services/utils/ui-utils.service.interface';

@Pipe({
  name: 'fromSeconds'
})
export class FromSecondsPipe implements PipeTransform {

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface
  ) { }

  async transform(value: string, ..._args: unknown[]): Promise<string> {

    let seconds = 0;

    try {
      seconds = parseInt(value, 10);
    } catch (err) {
      console.error(err);
    }

    if (this.uiUtilsService?.mapSecondsToTimeAgo) {
      return this.uiUtilsService?.mapSecondsToTimeAgo(seconds);
    }

    return '';
  }
}
