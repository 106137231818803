import { Inject, Injectable } from '@angular/core';
import appConfig from 'src/config/config';
import { SurgeryReferenceServiceInterface } from './surgery-reference.service.interface';
import {
  AddApproachParams,
  AddApproachRequest,
  AddApproachResponse,
  AddFractureParams,
  AddFractureRequest,
  AddFractureResponse,
  AddPreparationParams,
  AddPreparationRequest,
  AddPreparationResponse,
  AddTreatmentParams,
  AddTreatmentRequest,
  AddTreatmentResponse,
  ApproachesRequestParams,
  ApproachesResponse,
  DeleteApproachParams,
  DeleteApproachResponse,
  DeleteFractureParams,
  DeleteFractureRequest,
  DeleteFractureResponse,
  DeletePreparationParams,
  DeletePreparationResponse,
  DeleteTreatmentParams,
  DeleteTreatmentRequest,
  DeleteTreatmentResponse,
  FracturesRequestParams,
  FracturesResponse,
  PreparationsRequestParams,
  PreparationsResponse,
  TreatmentsRequestParams,
  TreatmentsResponse
} from 'src/app/services/yeti-protocol/surgery-reference';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { SchemaValidatorService } from '../schema-validator.service';
import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';

export interface SurgeryReferenceServiceConfig {
  serverUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class SurgeryReferenceService implements SurgeryReferenceServiceInterface {

  config: SurgeryReferenceServiceConfig = {
    serverUrl: appConfig.chatterUrl
  }

  private defaultStart = 0;
  private defaultCount = 10;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService,
  ) { }

  getFractures(
    caseId: string,
    start: number = this.defaultStart,
    count: number = this.defaultCount
  ): Promise<FracturesResponse> {
    const getRequestUrl = `${this.config.serverUrl}surgeryReference/fractures/${caseId}`;

    const params: FracturesRequestParams = {
      appId: this._appId,
      start,
      count
    }

    return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<FracturesResponse>('FracturesResponse')
    )) as Promise<FracturesResponse>;
  }

  getTreatments(
    moduleId: string,
    start: number = this.defaultStart,
    count: number = this.defaultCount
  ): Promise<TreatmentsResponse> {
    const getRequestUrl = `${this.config.serverUrl}surgeryReference/treatments/${moduleId}`;

    const params: TreatmentsRequestParams = {
      appId: this._appId,
      start,
      count
    }

    return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<TreatmentsResponse>('TreatmentsResponse')
    )) as Promise<TreatmentsResponse>;
  }

  getApproaches(
    caseId: string,
    start: number = this.defaultStart,
    count: number = this.defaultCount
  ): Promise<ApproachesResponse> {
    const getRequestUrl = `${this.config.serverUrl}surgeryReference/approaches/${caseId}`;

    const params: ApproachesRequestParams = {
      appId: this._appId,
      start,
      count
    }

    return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<ApproachesResponse>('ApproachesResponse')
    )) as Promise<ApproachesResponse>;
  }

  getPreparations(
    caseId: string,
    start: number = this.defaultStart,
    count: number = this.defaultCount
  ): Promise<PreparationsResponse> {
    const getRequestUrl = `${this.config.serverUrl}surgeryReference/preparations/${caseId}`;

    const params: PreparationsRequestParams = {
      appId: this._appId,
      start,
      count
    }

    return firstValueFrom(this.authService.secureGet(getRequestUrl, { params: toAuthRequestParams(params) }).pipe(
      this.schemaValidator.isValidOperator<PreparationsResponse>('PreparationsResponse')
    )) as Promise<PreparationsResponse>;
  }

  addFracture(caseId: string, fractureId: string): Promise<AddFractureResponse> {

    const requestParams: AddFractureParams = {
      appId: this._appId
    }

    const requestData: AddFractureRequest = { fractureId };

    const postRequestUrl = `${this.config.serverUrl}cases/fracture/${caseId}`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(
        this.authService.securePost<AddFractureRequest, AddFractureResponse>(postRequestUrl, requestData, {
          params: toAuthRequestParams(requestParams)
        }).pipe(
          this.schemaValidator.isValidOperator<AddFractureResponse>('AddFractureResponse')
        )
      );
    });
  }

  addTreatment(caseId: string, fractureId: string, treatmentId: string): Promise<AddTreatmentResponse> {

    const requestParams: AddTreatmentParams = {
      appId: this._appId
    }

    const requestData: AddTreatmentRequest = { fractureId, treatmentId };

    const postRequestUrl = `${this.config.serverUrl}cases/treatment/${caseId}`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(
        this.authService.securePost<AddTreatmentRequest, AddTreatmentResponse>(postRequestUrl, requestData, {
          params: toAuthRequestParams(requestParams)
        }).pipe(
          this.schemaValidator.isValidOperator<AddTreatmentResponse>('AddTreatmentResponse')
        )
      );
    });
  }

  addApproach(caseId: string, approachId: string): Promise<AddApproachResponse> {

    const requestParams: AddApproachParams = {
      appId: this._appId
    }

    const requestData: AddApproachRequest = { approachId };

    const postRequestUrl = `${this.config.serverUrl}cases/approach/${caseId}`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(
        this.authService.securePost<AddApproachRequest, AddApproachResponse>(postRequestUrl, requestData, {
          params: toAuthRequestParams(requestParams)
        }).pipe(
          this.schemaValidator.isValidOperator<AddApproachResponse>('AddApproachResponse')
        )
      );
    });
  }

  addPreparation(caseId: string, preparationId: string): Promise<AddPreparationResponse> {

    const requestParams: AddPreparationParams = {
      appId: this._appId
    }

    const requestData: AddPreparationRequest = { preparationId };

    const postRequestUrl = `${this.config.serverUrl}cases/preparation/${caseId}`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(
        this.authService.securePost<AddPreparationRequest, AddPreparationResponse>(postRequestUrl, requestData, {
          params: toAuthRequestParams(requestParams)
        }).pipe(
          this.schemaValidator.isValidOperator<AddPreparationResponse>('AddPreparationResponse')
        )
      );
    });
  }

  deleteFracture(caseId: string, fractureId: string): Promise<DeleteFractureResponse> {

    const requestParams: DeleteFractureParams = {
      appId: this._appId
    }

    const requestData: DeleteFractureRequest = { fractureId };

    const postRequestUrl = `${this.config.serverUrl}cases/fracture/${caseId}`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(
        this.authService.secureDelete<DeleteFractureRequest, DeleteFractureResponse>(postRequestUrl, requestData, {
          params: toAuthRequestParams(requestParams)
        }).pipe(
          this.schemaValidator.isValidOperator<DeleteFractureResponse>('DeleteFractureResponse')
        )
      );
    });
  }

  deleteTreatment(caseId: string, fractureId: string): Promise<DeleteTreatmentResponse> {

    const requestParams: DeleteTreatmentParams = {
      appId: this._appId
    }

    const requestData: DeleteTreatmentRequest = { fractureId };

    const postRequestUrl = `${this.config.serverUrl}cases/treatment/${caseId}`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(
        this.authService.secureDelete<DeleteTreatmentRequest, DeleteTreatmentResponse>(postRequestUrl, requestData, {
          params: toAuthRequestParams(requestParams)
        }).pipe(
          this.schemaValidator.isValidOperator<DeleteTreatmentResponse>('DeleteTreatmentResponse')
        )
      );
    });
  }

  deleteApproach(caseId: string): Promise<DeleteApproachResponse> {

    const requestParams: DeleteApproachParams = {
      appId: this._appId
    }

    const postRequestUrl = `${this.config.serverUrl}cases/approach/${caseId}`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(
        this.authService.secureDelete<null, DeleteApproachResponse>(postRequestUrl, null, {
          params: toAuthRequestParams(requestParams)
        }).pipe(
          this.schemaValidator.isValidOperator<DeleteApproachResponse>('DeleteApproachResponse')
        )
      );
    });
  }

  deletePreparation(caseId: string): Promise<DeletePreparationResponse> {

    const requestParams: DeletePreparationParams = {
      appId: this._appId
    }

    const postRequestUrl = `${this.config.serverUrl}cases/preparation/${caseId}`;

    return this.authService.asserIsSignedIn().then(() => {
      return firstValueFrom(
        this.authService.secureDelete<null, DeletePreparationResponse>(postRequestUrl, null, {
          params: toAuthRequestParams(requestParams)
        }).pipe(
          this.schemaValidator.isValidOperator<DeletePreparationResponse>('DeletePreparationResponse')
        )
      );
    });
  }

  get _appId(): string {
    return this.contextService.currentContext.key;
  }
}

