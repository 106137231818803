import { Component, Inject, Input } from '@angular/core';
import { EditTextDialogComponent } from '../../dialogs/edit-text-dialog/edit-text-dialog.component'
import { ModalController } from '@ionic/angular';

// mocks
import { UpdateUserProfileData } from 'src/app/services/yeti-protocol/auth/mi';
import { ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';

// services
import { AppTranslationService } from '../../services/app-translation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';

@Component({
  selector: 'app-about-me-card',
  templateUrl: './about-me-card.component.html',
  styleUrls: ['./about-me-card.component.scss'],
})

export class AboutMeCardComponent {
  @Input() text: string;
  @Input() profileOwner: boolean;
  @Input() source: ActionSource;

  readonly textLimit = 1000;
  readonly preventSaveWhenEmpty = false;
  readonly simpleTextArea = true;

  constructor(
    private appTranslationService: AppTranslationService,
    private modalController: ModalController,
    private authService: AuthService,
    private dialogs: DialogsUIService,
    private responsiveUtilsService: ResponsiveUtilsService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  openAboutMeModal(titleResourceId: string): void {
    this.showEditTextDialog(titleResourceId, this.text).then(result => {
      if(result === undefined) {
        return;
      }
      this.updateAboutMe(result as string);
    }).catch(err => {
      console.error(err);
    });
  }

  updateAboutMe(aboutMeText: string): void {
    const updateUserData: UpdateUserProfileData = {
      biography: aboutMeText
    }

    this.authService.updateProfile(this.contextService.currentContext.key,
      updateUserData, false, this.source).then(profile => {
        this.text = profile?.biography;
      }).catch(err => {
        console.error(err);
      });
  }

  showEditTextDialog(resourceId: string, text?: string): Promise<string | null | void> {
    return new Promise(resolve => {

      return this.modalController.create({
        component: EditTextDialogComponent,
        componentProps: {
          headerResourceId: resourceId,
          text: text || '',
          textLimit: this.textLimit,
          preventSaveWhenEmpty: this.preventSaveWhenEmpty,
          simpleTextArea: this.simpleTextArea
        },
        cssClass: 'rounded-modal small-modal'
      }).then(modalEl => {
        modalEl.onWillDismiss()
          .then(res => {
            if (res.role === 'cancel') {
              return resolve();
            }
            if (res.role === 'save') {
              return resolve(res.data);
            }
            resolve();
          });
        return modalEl.present();
      });
    });
  }

  async openDeleteConfirmationDialog(): Promise<void> {
    const deleteAboutMe = await this.showConfirmDialog();
    if(deleteAboutMe) {
      this.updateAboutMe('');
    }
  }

  async showConfirmDialog(): Promise<boolean> {
    const acceptActionKey = 'yes';
    const goBackActionKey = 'no';

    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: this.appTranslationService.instant('app.ProfilePage.about-me.delete-title')
        },
        message: {
          translationKey: this.appTranslationService.instant('app.ProfilePage.about-me.delete-message')
        },
        actions: [
          {
            key: goBackActionKey,
            label: {
              translationKey: this.appTranslationService.instant('app.ProfilePage.about-me.delete-cancel-btn')
            },
            className: 'secondary'
          },
          {
            key: acceptActionKey,
            label: {
              translationKey: this.appTranslationService.instant('app.ProfilePage.about-me.delete-confirm-btn')
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === acceptActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  get emptyState(): boolean {
    return this.text ? false : true;
  }

  get showActionBtns(): boolean {
    return this.profileOwner && this.text !== '' && this.text !== undefined;
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }
}
