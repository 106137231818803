// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container .filter-btn {
  outline: none;
  border: none;
  margin: 0;
  background: none;
  background-color: var(--mi-light-purple-1-color);
  border-radius: 8px;
  padding: 7.5px 12px;
}
.button-container .filter-btn .filter-btn-wrapper {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
}
.button-container .filter-btn .filter-btn-wrapper .icon {
  grid-row: 1;
  grid-column: 1;
  --icon-size: 16px;
  color: var(--mi-grey-color-16);
}
.button-container .filter-btn .filter-btn-wrapper .btn-text {
  grid-row: 1;
  grid-column: 2;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  color: var(--mi-grey-color-16);
  text-align: left;
  margin: 0;
}
.button-container .filter-btn .filter-btn-wrapper.active .icon {
  color: var(--mi-primary-color);
}
.button-container .filter-btn .filter-btn-wrapper.active .btn-text {
  color: var(--mi-primary-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
