import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CONTENT_TYPE } from './utils';

import { KEY_PROFILE, MIAuthService } from '../mi/mi-auth.service';
import { SchemaValidatorService } from '../../schema-validator.service';
import { UrlUtilsService } from '../../utils/url-utils.service';
import { LOCAL_STORAGE_SERVICE, StorageService } from '../../storage/storage.service';

import appConfig from '../../../../config/config';
import { AuthenticationConfig } from 'src/config/config.model';
import { CONTEXT_SERVICE, ContextService } from '../../context/context.model';

const USER_RIGHTS_KEY = 'mi-ao-user-right';

export interface UserRightsData {
  hasToSelectHome: boolean;
  hasToSelectInterests: boolean;
  specialties: Array<string>;
  memberDirAccess: boolean;
  fullTextAccess: boolean;
  isFaculty: boolean;
  hasToMigrate: boolean;
  aoMigrationAgreement: boolean;
  userPrincipalName?: string;
  aoEmail?: string;
  homespecialty?: string;
  aoData?: any;
  isMember: boolean;
}

@Injectable()
export class MIAOUtilsService {
  config: AuthenticationConfig = appConfig.authentication;

  constructor(
    private miAuthService: MIAuthService,
    private schemaValidator: SchemaValidatorService,
    private urlUtilsService: UrlUtilsService,
    @Inject(LOCAL_STORAGE_SERVICE) private storage: StorageService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService
  ) { }

  /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
  _fetchUserRights(aoProfileData?: any, queryParams?: Record<string, string>): Promise<UserRightsData> {
    const data = aoProfileData ? aoProfileData : '';
    queryParams = queryParams || {};
    const queryStr = this.urlUtilsService.object2queryString(queryParams);
    const url = this.config.serverUrl + 'accessrights' + (queryStr ? ('?' + queryStr) : '');
    // TODO: add request respone types definition
    return firstValueFrom(this.miAuthService.securePost<any, UserRightsData>(url, data, {
      headers: {
        'content-type': CONTENT_TYPE.JSON
      }
    })); // TODO: response structure validation
  }

  /** @method
   * @desc get AO migrarion flags and fields
   * @param aoProfileData - optional aoProfileData
   * @param reloadData - force data reload from server
   * @returns user rights object
   */
  getUserRights(aoProfileData: any, reloadData: boolean, queryParams?: Record<string, string>): Promise<UserRightsData> {
    const _fetchRights = (): Promise<any> => {
      return this._fetchUserRights(aoProfileData, queryParams || {
        appId: this.config.clientId
      }).then(rightsData => {
          return Promise.all([
            rightsData,
            this.storage.set(USER_RIGHTS_KEY, rightsData)
          ]);
        })
        .then(res => {
          this.updateLocalStorageProfileAccessRights(res[0]);
          return res[0];
        });
    }

    return this.miAuthService.isSignedIn()
      .then((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          return null;
        }
        if (aoProfileData || reloadData) {
          return _fetchRights();
        }
        return this.storage.get(USER_RIGHTS_KEY)
          .then(data => {
            if (data) {
              return data;
            }
            return _fetchRights();
          });
      });
  }

  signOut(): Promise<void> {
    return this.storage.remove(USER_RIGHTS_KEY);
  }

  private updateLocalStorageProfileAccessRights(userRightsData: UserRightsData): void {

    const context = this.contextService.currentContext.key;

    this.storage.get(KEY_PROFILE).then((data: any) => {
      if (data && data.context === context && data.profile) {

        data.profile.isAOMember = userRightsData.isMember;

        this.storage.set(KEY_PROFILE, data);
      }
    });
  }
}

export const forTesting = {
  USER_RIGHTS_KEY
};
