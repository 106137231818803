import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { MyNetworkStreamStateModel } from './my-network-stream.model';
import { Injectable } from '@angular/core';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { MY_NETWORK_STREAM_STATE_KEY } from '../state-constants';
import { MyStreamService } from 'src/app/services/my-stream.service';
import {
  CaseInResponse,
  MyStreamSuccessResponse,
  PostInResponse,
  ResponseStreamItem,
  StreamFilter
} from 'src/app/services/yeti-protocol/my-stream';
import { MyNetworkStream } from './my-network-stream.actions';
import { StreamStateType } from 'src/app/services/yeti-protocol/utils/enums';
import { firstValueFrom } from 'rxjs';

const MY_NETWORK_STREAM_STATE_TOKEN = new StateToken<MyNetworkStreamStateModel>(
  MY_NETWORK_STREAM_STATE_KEY
);

@State({
  name: MY_NETWORK_STREAM_STATE_TOKEN,
  defaults: {
    streamItems: [],
    streamFilters: [],
    loading: false,
    lastFetchLength: 0
  },
})
@Injectable()
export class MyNetworkStreamState {

  constructor(private myStreamService: MyStreamService) { }

  @Selector()
  static state(state: MyNetworkStreamStateModel): MyNetworkStreamStateModel {
    return state;
  }

  @Selector()
  static streamItems(state: MyNetworkStreamStateModel): Array<ResponseStreamItem> {
    return state.streamItems;
  }

  @Selector()
  static streamFilters(state: MyNetworkStreamStateModel): Array<StreamFilter> {
    return state.streamFilters;
  }

  @Selector()
  static loading(state: MyNetworkStreamStateModel): boolean {
    return state.loading;
  }

  @Action(MyNetworkStream.FetchMyNetworkStreamItems)
  async fetchMyNetworkStreamItems(
    ctx: StateContext<MyNetworkStreamStateModel>,
    payload: MyNetworkStream.FetchMyNetworkStreamItems
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });

      const myNetworkStreamRes = (await firstValueFrom(this.myStreamService.fetchStream(
        payload?.payloadParams?.pageIndex,
        payload?.payloadParams?.pageSize,
        StreamStateType.network,
        false,
        payload?.payloadParams?.filter,
        false
      ))) as MyStreamSuccessResponse;


      ctx.dispatch(
        new MyNetworkStream.FetchMyNetworkStreamItemsSuccess(
          payload?.payloadParams,
          myNetworkStreamRes
        )
      );
    } catch (err) {

      ctx.dispatch(
        new MyNetworkStream.FetchMyNetworkStreamItemsFailed()
      );

      throw err;
    }
  }

  @Action(MyNetworkStream.FetchMyNetworkStreamItemsSuccess)
  fetchMyNetworkStreamItemsSuccess(
    ctx: StateContext<MyNetworkStreamStateModel>,
    action: MyNetworkStream.FetchMyNetworkStreamItemsSuccess
  ): void {
    const state = ctx.getState();

    if (action.payloadParams.pageIndex === 0) {
      ctx.patchState({
        streamItems: action.response.result,
        streamFilters: action.response.meta.filters,
        lastFetchLength: action.response.result?.length,
        loading: false,
      });
    } else {
      ctx.patchState({
        streamItems: [...state.streamItems, ...action.response.result],
        streamFilters: action.response.meta.filters,
        lastFetchLength: action.response.result?.length,
        loading: false,
      });
    }
  }

  @Action(MyNetworkStream.FetchMyNetworkStreamItemsFailed)
  fetchMyNetworkStreamItemsFailed(
    ctx: StateContext<MyNetworkStreamStateModel>
  ): void {
    ctx.patchState({
      streamItems: [],
      streamFilters: [],
      lastFetchLength: 0,
      loading: false,
    });
  }

  @Action(MyNetworkStream.InsertMyNetworkStreamItemBeforeIndex)
  insertMyNetworkStreamItemBeforeIndex(
    ctx: StateContext<MyNetworkStreamStateModel>,
    action: MyNetworkStream.InsertMyNetworkStreamItemBeforeIndex
  ): void {

    ctx.setState(
      patch<MyNetworkStreamStateModel>({
        streamItems: insertItem<ResponseStreamItem>(action.item, action.index)
      })
    );
  }

  @Action(MyNetworkStream.RemoveMyNetworkStreamItem)
  removeMyNetworkStreamItem(
    ctx: StateContext<MyNetworkStreamStateModel>,
    action: MyNetworkStream.RemoveMyNetworkStreamItem
  ): void {

    const state = ctx.getState();

    const itemIndex = state.streamItems.findIndex(item =>
      (item?.streamItem as any)?._id === (action?.item?.streamItem as any)?._id &&
      (item?.streamItem as any)?.parentType === (action?.item?.streamItem as any)?.parentType &&
      (item?.streamItem as any)?.parentId === (action?.item?.streamItem as any)?.parentId);

    if (itemIndex === -1) {
      return;
    }

    ctx.setState(
      patch<MyNetworkStreamStateModel>({
        streamItems: removeItem<ResponseStreamItem>(itemIndex)
      })
    );
  }

  @Action(MyNetworkStream.UpdateMyNetworkStreamItem)
  updateMyNetworkStreamItem(
    ctx: StateContext<MyNetworkStreamStateModel>,
    action: MyNetworkStream.UpdateMyNetworkStreamItem
  ): void {

    ctx.setState(
      patch<MyNetworkStreamStateModel>({
        streamItems: updateItem<ResponseStreamItem>(item =>
          (item?.streamItem as any)?._id === (action?.item?.streamItem as any)?._id &&
          (item?.streamItem as any)?.parentType === (action?.item?.streamItem as any)?.parentType &&
          (item?.streamItem as any)?.parentId === (action?.item?.streamItem as any)?.parentId,
          action.item)
      })
    );
  }

  @Action(MyNetworkStream.UpdateMyNetworkStreamItemsOwnerFollowingStatus)
  updateGroupsPostsOwnerFollowingStatus(
    ctx: StateContext<MyNetworkStreamStateModel>,
    action: MyNetworkStream.UpdateMyNetworkStreamItemsOwnerFollowingStatus): void {

    const state = ctx.getState();

    const streamItems = [...state.streamItems]?.map(item => {

      const itemCopy = { ...item };

      if ((item as PostInResponse | CaseInResponse)?.streamItem?.owner?.userId === action?.userId) {
        (itemCopy as PostInResponse | CaseInResponse).streamItem.owner.isFollower = action?.isFollower;
        return itemCopy;
      }

      return itemCopy;
    });

    ctx.setState(
      patch<MyNetworkStreamStateModel>({
        streamItems: streamItems
      })
    );
  }
}
