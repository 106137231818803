// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.journal-container {
  padding: 5px 0px;
  cursor: pointer;
}
.journal-container .journal-img {
  --border-radius: 5px;
  width: 68px;
  height: 68px;
}
.journal-container .title-dots-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.journal-container .title-dots-wrap .journal-data {
  width: 85%;
  padding-right: 5px;
}
.journal-container .title-dots-wrap .journal-data .journal-title {
  height: 44px;
  white-space: break-spaces;
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.journal-container .title-dots-wrap .journal-data .tag-container {
  display: inline-block;
}
.journal-container .title-dots-wrap .journal-data .tag-container .need-advice-tag {
  justify-content: center;
  align-items: center;
  background-color: var(--mi-light-blue-color);
  padding: 1px 10px;
  border-radius: 8px;
  display: inline-block;
}
.journal-container .title-dots-wrap .journal-data .tag-container .need-advice-tag .text {
  color: var(--mi-primary-color);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
.journal-container .title-dots-wrap .icon-options {
  --icon-size: 25px;
  color: var(--mi-color-text-lite);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
