import { Component, EventEmitter, Input, Output } from '@angular/core';

// models
import { InterestListItem } from 'src/app/services/interests.model';

@Component({
  selector: 'app-interest-item',
  templateUrl: './interest-item.component.html',
  styleUrls: ['./interest-item.component.scss'],
})
export class InterestItemComponent {
  @Input() interestItem: InterestListItem;
  @Input() totalSelections: number;
  @Output() interestSelectionChange: EventEmitter<void> = new EventEmitter<void>();

  onCheckboxChange(): void {
    this.interestSelectionChange.emit();
  }

  get isDisabled(): boolean {
    return this.totalSelections && this.totalSelections === 1 && this.interestItem.checked ? true : false;
  }
}
