// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --settings-button-icon-color: var(--mi-black-color);
  --settings-button-icon-font-size: 26px;
}

.settings-button {
  outline: none;
  background: none;
  color: var(--settings-button-icon-color);
  padding: 0;
  margin: 0;
}
.settings-button .settings-button-wrap app-icon {
  grid-row: 1;
  grid-column: 1;
  --icon-size: var(--settings-button-icon-font-size);
}
.settings-button .settings-button-wrap-vertical {
  --settings-button-icon-font-size: 20px;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 5px;
  justify-content: center;
}
.settings-button .settings-button-wrap-vertical app-icon {
  --icon-size: var(--settings-button-icon-font-size);
}
.settings-button .settings-button-wrap-vertical .btn-text {
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
  color: var(--settings-button-icon-color);
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
