import {Injectable} from '@angular/core';
import {ToastOptions} from '@ionic/core';
import {ToastController} from '@ionic/angular';
import { AppTranslationService } from './app-translation.service';

export enum ToastMode {
  SUCCESS = 'success',
  ERROR = 'danger'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  visible = false;

  constructor(
    private toastController: ToastController,
    private appTranslationService: AppTranslationService
  ) {}

  _show(
    message: string, header: string = null, mode: ToastMode = ToastMode.SUCCESS,
    animated?: boolean, duration = 3000, customClass?: string
  ): void {
    const options: ToastOptions = {
      message,
      duration: duration,
      color: mode,
      buttons: duration ? [] : [
        {icon: 'close-outline', role: 'cancel'}
      ],
      cssClass: customClass
    };
    if (header) {
      options.header = header;
    }
    if (animated) {
      options.animated = animated;
    }
    this.toastController.create(options)
      .then(toastEl => {
        toastEl.onDidDismiss().then(() => {
          this.visible = false;
        })
        toastEl.present();
      });
  }

  show(
    messageId: string, headerId: string = null, mode: ToastMode = ToastMode.SUCCESS,
    animated?: boolean, duration = 3000, customClass?: string
  ): void {
    this.appTranslationService.get([messageId, headerId]).then(res => {
      let message = res[messageId];
      let header = res[headerId];
      message = message ? message : messageId;
      header = header ? header : headerId;
      this._show(message, header, mode, animated, duration, customClass);
    });
  }

  showWithMessage(
    message: string, headerId: string = null, mode: ToastMode = ToastMode.SUCCESS,
    animated?: boolean, duration = 3000, customClass?: string
  ): void {
    const promise = headerId ? this.appTranslationService.get(headerId): Promise.resolve(null);
    promise.then(header => {
      header = header ? header : headerId;
      this.show(message, header, mode, animated, duration, customClass);
    });
  }

  hide(): void {
    if(this.visible){
      this.toastController.dismiss();
    }
  }
}
