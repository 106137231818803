import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { Connection } from 'src/app/services/yeti-protocol/connections';
import { ConnectedUser } from 'src/app/services/yeti-protocol/public-profile';

@Component({
  selector: 'app-selectable-contact-list-item',
  templateUrl: './selectable-contact-list-item.component.html',
  styleUrls: ['./selectable-contact-list-item.component.scss'],
})
export class SelectableContactListItemComponent implements OnInit, OnDestroy {

  @Input() connection: Connection;
  @Input() selected = false;
  @Input() disableSelection = false;

  @Output() contactSelectionChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() contactSelectionClickedWhenDisabled: EventEmitter<void> = new EventEmitter();

  private userProfileSubscription: Subscription;
  private userProfile: UserProfile;

  constructor(
    private authService: AuthService,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  get contact(): ConnectedUser {
    if (this.connection?.initiator?.userId !== this.userProfile?.userId) {
      return this.connection?.initiator;
    } else {
      return this.connection?.partner;
    }
  }

  checkBoxClick(event: Event): void {

    if (this.disableSelection && !this.selected) {

      this.uiUtilsService.stopEventPropagation(event);
      this.contactSelectionClickedWhenDisabled.emit();

      this.selected = false;

      setTimeout(() => {
        this.el.nativeElement.querySelector('.checkbox').classList.remove('checkbox-checked');
      }, 250);

      return;
    }

    this.selected = !this.selected;
    this.contactSelectionChanged.emit(this.selected);
  }

}
