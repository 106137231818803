declare const cryptojs: any;

import { SocialProfile } from '../../yeti-protocol/auth/mi';
import { AuthProvider } from '../../yeti-protocol/auth/provider';

export enum CONTENT_TYPE{
  JSON = 'application/json',
  PDF = 'application/pdf',
  FORM = 'application/x-www-form-urlencoded'
}

export interface SocialData {
  id: string;
  given_name?: string;
  family_name?: string;
  sub: string;
  email: string;
  url?: string;
  userPrincipalName?: string;
}

export function createStandardProfileData(provider: AuthProvider, socialData: SocialData): SocialProfile{
  let givenName = '';
  if(socialData.given_name){
    givenName = socialData.given_name;
  }
  let familyName = '';
  if(socialData.family_name){
    familyName = socialData.family_name;
  }
  return {
    provider,
    id : socialData.sub,
    original : {
      providerName : provider,
      name : {
        formatted : [ givenName, familyName ].join(' '),
        givenName,
        familyName
      },
      identifier : socialData.id,
      email : socialData.email,
      providerSpecifier : provider,
      url : socialData.url || 'unknown',
      userPrincipalName : socialData.userPrincipalName
    }
  };
}

/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
export function base64URLEncode(str: any): string {
  return str.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export function sha256(buffer: string): string {
  return cryptojs.createHash('sha256').update(buffer).digest();
}

export function randomBytes(size: number): any{
  return cryptojs.randomBytes(size);
}
