// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recommendation-item {
  margin-bottom: 15px;
}
.recommendation-item .recommendation-item-image {
  margin: unset;
  width: 68px;
  height: 68px;
}
.recommendation-item .recommendation-item-image app-article-image {
  --article-image-size: 68px;
  --article-image-border-radius: 5px;
}
.recommendation-item .item-label {
  margin-left: 10px;
}
@media (min-width: 961px) {
  .recommendation-item .item-label {
    height: 68px;
  }
}
.recommendation-item .show-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.recommendation-item .recommendation-item-name {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
}
.recommendation-item .recommendation-item-members {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: 1.1;
}

ion-item {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
