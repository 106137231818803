import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-generic-empty-state',
  templateUrl: './generic-empty-state.component.html',
  styleUrls: ['./generic-empty-state.component.scss'],
})
export class GenericEmptyStateComponent {
  @Input() imageUrl: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() message: string;
}
