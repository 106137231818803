import { Pipe, PipeTransform } from '@angular/core';
import { addUnitToFileSize } from '../services/utils/string-utils';

@Pipe({
  name: 'addContentLengthUnit'
})
export class AddContentLengthUnitPipe implements PipeTransform {

  transform(contentLength: number, ..._args: unknown[]): string {
    return addUnitToFileSize(contentLength);
  }

}
