// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --surgery-reference-padding-start: 15px;
  --surgery-reference-padding-end: 15px;
  --surgery-reference-padding-top: 0px;
  --surgery-reference-padding-bottom: 45px;
}

.surgery-reference-dialog {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
}
.surgery-reference-dialog .header {
  grid-row: 1;
  grid-column: 1;
}
.surgery-reference-dialog .header app-top-bar .action-button {
  background: none;
  outline: none;
  cursor: pointer;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-13);
}
.surgery-reference-dialog .content {
  grid-row: 2;
  grid-column: 1;
  padding-top: var(--surgery-reference-padding-top);
}
.surgery-reference-dialog .content ion-content {
  --padding-bottom: var(--surgery-reference-padding-bottom);
}

.review-page-container .header app-top-bar .action-button {
  font-weight: var(--font-weight-medium);
  color: var(--mi-primary-color);
}
.review-page-container .content ion-content .wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 26px;
}
.review-page-container .content ion-content .wrapper .added-modules-container {
  grid-row: 1;
  grid-column: 1;
  padding-left: var(--surgery-reference-padding-start);
  padding-right: var(--surgery-reference-padding-end);
  padding-bottom: 20px;
  padding-top: 36px;
  background-color: var(--mi-light-purple-1-color);
}
.review-page-container .content ion-content .wrapper .main-content {
  height: 100%;
  grid-row: 2;
  grid-column: 1;
}
.review-page-container .content ion-content .wrapper .main-content .selection-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 20px;
  padding-left: var(--surgery-reference-padding-start);
  padding-right: var(--surgery-reference-padding-end);
}
.review-page-container .content ion-content .wrapper .main-content .selection-container .info-message-container {
  grid-row: 1;
  grid-column: 1;
}
.review-page-container .content ion-content .wrapper .main-content .selection-container .info-message-container .info-message {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-13);
  margin: 0;
}
.review-page-container .content ion-content .wrapper .main-content .selection-container .info-message-container .info-message span {
  font-weight: var(--font-weight-semi-bold);
}
.review-page-container .content ion-content .wrapper .main-content .selection-container .cards-container {
  grid-row: 2;
  grid-column: 1;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 37px;
  padding-bottom: 25px;
}
.review-page-container .content ion-content .wrapper .main-content .selection-container .cards-container .horizontal-list-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 12px;
}
.review-page-container .content ion-content .wrapper .main-content .selection-container .cards-container .horizontal-list-container .list-title {
  grid-row: 1;
  grid-column: 1;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-grey-color-16);
  margin: 0;
}
.review-page-container .content ion-content .wrapper .main-content .selection-container .cards-container .horizontal-list-container app-horizontal-list {
  --horizontal-list-min-height: 244px;
  grid-row: 2;
  grid-column: 1;
  min-width: 100%;
  display: block;
}
.review-page-container .content ion-content .wrapper .main-content .case-enriched-container {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 10px;
  justify-content: center;
  align-content: center;
  max-width: 285px;
  margin: 0 auto;
  height: 100%;
  padding-left: var(--surgery-reference-padding-start);
  padding-right: var(--surgery-reference-padding-end);
  padding-bottom: var(--surgery-reference-padding-bottom);
}
.review-page-container .content ion-content .wrapper .main-content .case-enriched-container .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}
.review-page-container .content ion-content .wrapper .main-content .case-enriched-container .image-container ion-img {
  width: 125px;
}
.review-page-container .content ion-content .wrapper .main-content .case-enriched-container .title-container .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  color: var(--mi-black-color-2);
  margin: 0;
  text-align: center;
}
.review-page-container .content ion-content .wrapper .main-content .case-enriched-container .subtitle-container .subtitle {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  margin: 0;
  text-align: center;
}
.review-page-container app-surgery-reference-select-card ::ng-deep app-mi-card ion-card {
  min-height: 285px;
  max-height: 285px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
