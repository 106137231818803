// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --about-me-padding-top: 20px;
  --about-me-padding-end: 15px;
  --about-me-padding-bottom: 20px;
  --about-me-padding-start: 15px;
  --about-me-border-radius: 12px;
  --about-me-background-color: var(--mi-white-color);
}

.about-me-card-container {
  width: 100%;
  padding-top: var(--about-me-padding-top);
  padding-right: var(--about-me-padding-end);
  padding-bottom: var(--about-me-padding-bottom);
  padding-left: var(--about-me-padding-start);
  border-radius: var(--about-me-border-radius);
  background-color: var(--about-me-background-color);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  row-gap: 15px;
}
.about-me-card-container .card-header {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}
.about-me-card-container .card-header .title {
  color: var(--mi-secondary-color-2);
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}
.about-me-card-container .card-content {
  grid-row: 2;
  grid-column: 1;
}
.about-me-card-container .card-content .text {
  color: var(--mi-grey-color-13);
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
.about-me-card-container.desktop {
  max-height: 300px;
  overflow-y: auto;
}

.actions {
  padding-left: 10px;
}
.actions ion-button {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --color: var(--mi-color-text-lite);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  text-transform: none;
  --background-hover: none;
}
.actions ion-button.btn-edit {
  padding-right: 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
