import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import { InfoSheetService } from 'src/app/modules/info-sheet/services/info-sheet.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { CreateGroupService } from 'src/app/services/create-content/group/create-group.service';
import { GroupUserPermissions, GroupUserScopes, RemoveGroupEvent } from 'src/app/services/groups/group.model';
import { GroupsDataService } from 'src/app/services/groups/groups-data.service';
import { GroupsUIService } from 'src/app/services/groups/groups-ui.service';
import { GroupsService } from 'src/app/services/groups/groups.service';
import { ItemTypes, RecommendationsBookmarksService } from 'src/app/services/recommendations-service/recommendations-bookmarks.service';
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';
import { ToastMode, ToastService } from 'src/app/services/toast.service';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { UI_UTILS_SERVICE, UIUtilsServiceInterface } from 'src/app/services/utils/ui-utils.service.interface';
import { VerificationStatus } from 'src/app/services/verification.model';
import { VerificationService } from 'src/app/services/verification.service';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { Group, LeaveGroupMemberStatus } from 'src/app/services/yeti-protocol/chatter-api';
import { InfoSheetModule } from 'src/app/modules/info-sheet/info-sheet.module';
import { IconComponent } from 'src/app/modules/icon/icon.component';

enum GroupActions {
  EDIT_GROUP = 'EDIT_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  LEAVE_GROUP = 'LEAVE_GROUP',
  SHARE_GROUP = 'SHARE_GROUP',
  RECOMMEND = 'RECOMMEND'
}

@Component({
  selector: 'app-group-action-button',
  templateUrl: './group-action-button.component.html',
  styleUrls: ['./group-action-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    InfoSheetModule,
    IconComponent
  ]
})
export class GroupActionButtonComponent implements OnInit, OnChanges, OnDestroy {

  @Input() group: Group;
  @Input() withoutOwnerActions: boolean;

  @Output() joined: EventEmitter<void> = new EventEmitter();
  @Output() requestToJoinSent: EventEmitter<void> = new EventEmitter();
  @Output() openGroup: EventEmitter<string> = new EventEmitter();
  @Output() removeGroup: EventEmitter<RemoveGroupEvent> = new EventEmitter<RemoveGroupEvent>();

  groupsUiService: GroupsUIService;
  groupUserPermissions: GroupUserPermissions;
  infoSheetId = `group-list-item-info-sheet-${Math.floor(Math.random() * 1000000)}`;

  protected joiningGroup: boolean;
  protected requestingToJoin: boolean;

  private groupUserPermissionsSubscription: Subscription;
  private userProfile: UserProfile;
  private userProfileSubscription: Subscription;

  constructor(
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private groupsService: GroupsService,
    private toast: ToastService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private infoSheetService: InfoSheetService,
    private verificationService: VerificationService,
    private createGroupService: CreateGroupService,
    private groupsDataService: GroupsDataService,
    private recommendationsService: RecommendationsBookmarksService,
    private sharingUIService: SharingUIService,
  ) {
    this.groupsUiService = new GroupsUIService();
  }

  ngOnInit(): void {

    this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);

    this.groupUserPermissionsSubscription = this.groupsUiService.groupUserPermissions.subscribe((permissions: GroupUserPermissions) => {
      this.groupUserPermissions = permissions;
    });

    this.userProfileSubscription = this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group) {
      this.groupsUiService.setGroup(changes.group.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.groupUserPermissionsSubscription?.unsubscribe();
    this.userProfileSubscription?.unsubscribe();
  }

  get showOpenButton(): boolean {
    if (this.group?.visibility === 'Public') {
      return this.group?.status === GroupUserScopes.OWNER ||
        this.group?.status === GroupUserScopes.MEMBER ||
        this.group?.status === GroupUserScopes.MODERATOR ||
        this.group?.status === GroupUserScopes.PENDING_VERIFICATION_REQUEST;
    } else {
      return this.group?.status === GroupUserScopes.OWNER ||
        this.group?.status === GroupUserScopes.MEMBER ||
        this.group?.status === GroupUserScopes.MODERATOR;
    }
  }

  async joinGroup(event: Event): Promise<void> {

    this.uiUtilsService.stopEventPropagation(event);

    if (this.joiningGroup) {
      return;
    }

    const canAccess = await this.checkAndPromptCanAccessAoMembersOnlyGroup();
    if (!canAccess) {
      return;
    }

    this.joiningGroup = true;

    this.groupsService.joinGroup(this.group?._id, this.userProfile?.email, this.userProfile?.id)
      .then(() => {
        this.joined.emit();
      }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showError(err?.error?.error?.message?.errfor?.message);
        }
      })
      .finally(() => {
        this.joiningGroup = false;
      });
  }

  async requestToJoin(event: Event): Promise<void> {

    this.uiUtilsService.stopEventPropagation(event);

    if (this.requestingToJoin) {
      return;
    }

    const canAccess = await this.checkAndPromptCanAccessAoMembersOnlyGroup();
    if (!canAccess) {
      return;
    }

    this.requestingToJoin = true;

    this.groupsService.requestToJoinGroup(this.group?._id, this.userProfile?.email, this.userProfile?.id)
      .then(() => {
        this.requestToJoinSent.emit();
      }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showError(err?.error?.error?.message?.errfor?.message);
        }
      }).finally(() => {
        this.requestingToJoin = false;
      });
  }

  requestToJoinIsSent(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
  }

  onOpenGroup(event: Event): void {
    this.uiUtilsService.stopEventPropagation(event);
    this.openGroup.emit(this.group._id);
  }

  async openGroupSettings(event: Event): Promise<void> {
    this.uiUtilsService.stopEventPropagation(event);
    if (this.responsiveUtilsService.isDesktop) {
      this.infoSheetService.openWeb(this.infoSheetActions, event)
        .then(action => {
          if (action && action.code) {
            action.handler();
          }
        });
    } else {
      if (this.groupUserPermissions) {
        this.infoSheetService.open(this.infoSheetId);
      }
    }
  }

  checkAndPromptCanAccessAoMembersOnlyGroup(): Promise<boolean> {
    return this.uiUtilsService.checkAndPromptCanAccessAoMembersOnlyGroup(
      this.group?.AOMembersOnly,
      this.userProfile?.isAOMember,
      this.contextService?.currentContext?.aoMembershipUrl
    );
  }

  /* eslint-disable */
  showError(err: any): void {
    /* eslint-enable */
    this.toast.showWithMessage(err, 'app.common.error-default', ToastMode.ERROR);
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    const actions: Array<InfoSheetActionItem> = [];
    if (this.groupUserPermissions.editGroup) {
      actions.push({
        id: 'list_item_edit_group_btn',
        icon: 'md-icon-pencil',
        textKey: 'app.groups.edit-group',
        code: GroupActions.EDIT_GROUP,
        handler: () => {
          this.editGroup();
        }
      });
    }
    if (this.groupUserPermissions.recommend) {
      actions.push({
        id: 'list_item_recommend_group_btn',
        icon: this.group.hasRecommended ? 'md-icon-recommend-filled' : 'md-icon-recommend',
        textKey: this.group.hasRecommended ? 'app.groups.recommended' : 'app.groups.recommend',
        code: GroupActions.RECOMMEND,
        handler: () => {
          this.onRecommendButtonClick();
        }
      });
    }
    if (this.groupUserPermissions.share) {
      actions.push({
        id: 'list_item_share_group_btn',
        icon: 'md-icon-share',
        textKey: 'app.groups.share-group',
        code: GroupActions.SHARE_GROUP,
        handler: () => {
          this.presentShareDialog(this.group);
        }
      });
    }
    if (this.groupUserPermissions.leaveGroup) {
      actions.push({
        id: 'list_item_leave_group_btn',
        icon: 'md-icon-leave',
        textKey: 'app.groups.leave-group',
        code: GroupActions.LEAVE_GROUP,
        handler: () => {
          this.presentLeaveGroupConfirm();
        }
      })
    }
    if (this.groupUserPermissions.deleteGroup) {
      actions.push({
        id: 'list_item_delete_group_btn',
        icon: 'md-icon-bin',
        textKey: 'app.groups.delete-group',
        code: GroupActions.DELETE_GROUP,
        handler: () => {
          this.presentDeleteGroupConfirm();
        }
      });
    }
    return actions;
  }

  private async editGroup(): Promise<void> {
    const userVerificationStatus = await this.verificationService.verify();
    if (userVerificationStatus === VerificationStatus.VERIFIED)
      this.createGroupService.showCreateEditGroupDialog(this.group);
  }

  private async presentDeleteGroupConfirm(): Promise<void> {
    await this.groupsService.presentDeleteGroupConfirm()
      .then((confirm: boolean) => {
        if (confirm) {
          this.deleteGroup();
        }
      });
  }

  private deleteGroup(): void {
    this.groupsService.deleteGroup(this.group?._id).then(() => {
      this.groupsDataService.triggerGroupDeletedAction(this.group);
      this.removeGroup.emit({ groupId: this.group._id });
    }).catch(err => {
      if (err?.error?.error?.message?.errfor?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message);
      }
    });
  }

  private onRecommendButtonClick(): void {
    if (this.group.hasRecommended === true) {
      this.removeRecommendGroup();
    } else {
      this.recommendGroup();
    }
  }

  private recommendGroup(): void {
    this.recommendationsService.addRecommendationBookmarkItem('group', ItemTypes.RECOMMENDATIONS, this.group._id)
      .then(response => {
        if (response && response.success)
          this.group.hasRecommended = true;
        this.toast.show('app.recommendedItems.group-recommend');
      })
  }

  private removeRecommendGroup(): void {
    this.recommendationsService.removeRecommendationBookmarkItem('group', ItemTypes.RECOMMENDATIONS, this.group._id)
      .then(response => {
        if (response && response.success)
          this.group.hasRecommended = false;
        this.toast.show('app.recommendedItems.remove-recommended-group');
      })
  }

  private presentShareDialog(group: Group): void {
    this.sharingUIService.showShareDialogForObject(group);
  }

  private async presentLeaveGroupConfirm(): Promise<void> {
    await this.groupsService.presentLeaveGroupConfirm()
      .then((confirm: boolean) => {
        if (confirm) {
          this.leaveGroup();
        }
      });
  }

  private async leaveGroup(): Promise<void> {
    this.groupsService.leaveGroup(this.group?._id, this.userProfile?.id, LeaveGroupMemberStatus.LEFT).then(() => {
      this.toast.show('app.groups.group-left-message', null, ToastMode.SUCCESS);
      this.groupsDataService.triggerGroupLeftAction(this.group);
      this.removeGroup.emit({ groupId: this.group._id });
    }).catch(err => {
      if (err?.error?.error?.message?.errfor?.message) {
        this.showError(err?.error?.error?.message?.errfor?.message);
      }
    });
  }
}
